import errors from "@/utils/errors";
import {saveAs} from 'file-saver';

export default {
    data() {
        return {
            agendaAtendimentos : [],
        }
    },
    methods : {
        carregarAgendaAtendimentosPorData(tenant, profissionalId, unidadeId, datInicial) {
            this.loading = true;
            this.loaded = false;

            this.$http.get(`/v1.0/agendaatendimentos/${tenant}/profissional/${profissionalId}/unidade/${unidadeId}/data/${datInicial}`)
            .then(resp => {
                this.agendaAtendimentos = resp.data;
                this.erro = "";
                this.loaded = true;
                this.destacarLinhasAgenda();
            })
            .catch(error => {
                this.erro = errors.tratarErros(error);
            })
            .finally(() => {
                this.loading = false;
            })
        },
        carregarAgendaAtendimentosHorariosLivres(tenant, profissionalId, unidadeId, datInicial, datFinal, 
                                                 diaSemana1, diaSemana2, diaSemana3, diaSemana4, horaInicial, horaFinal,
                                                 destinado, preferencial, adicionarDiaSemana) {

            // [HttpGet("{tenant}/livres/{profissionalId}/{unidadeId}/{datInicial}/{datFinal}/{diaSemana1}/{diaSemana2}/{diaSemana3}/{diaSemana4}/{horarioInicial}/{horarioFinal}/{destinado}/{preferencial}")]

            diaSemana1 = diaSemana1 != "" ? diaSemana1 : "99";
            diaSemana2 = diaSemana2 != "" ? diaSemana2 : "99";
            diaSemana3 = diaSemana3 != "" ? diaSemana3 : "99";
            diaSemana4 = diaSemana4 != "" ? diaSemana4 : "99";

            this.$http.get(`/v1.0/agendaatendimentos/${tenant}/livres/${profissionalId}/${unidadeId}/${datInicial}/${datFinal}/${diaSemana1}/${diaSemana2}/${diaSemana3}/${diaSemana4}/${horaInicial}/${horaFinal}/${destinado}/${preferencial}`)
            .then(resp => {
                this.agendaAtendimentos = resp.data;
                this.erro = "";

                if (adicionarDiaSemana) {
                    const constantes = require('../utils/constantes.js');

                    let agendaComDiaSemana = [...this.agendaAtendimentos];

                    this.agendaAtendimentos = agendaComDiaSemana.map(agenda => {
                        let dataJS = new Date(agenda.data)

                        let diaSemana = dataJS.getDay();
                        let diaSemanaAbrev = constantes.default.diasSemana.find(dia => dia.opcao == diaSemana).abrev;

                        return {...agenda, diaSemana, diaSemanaAbrev}
                    })
                }

                this.destacarLinhasAgenda();
            })
            .catch(error => {
                this.erro = errors.tratarErros(error);
            })
            .finally(() => {
                this.loading = false;
                this.loaded = true;
            })
        },
        carregarAgendaPacienteProfissional(tenant, pacienteId, profissionalId, operadoraId) {
            this.$http.get(`/v1.0/agendaatendimentos/${tenant}/paciente/${pacienteId}/profissional/${profissionalId}`)
            .then(resp => {
                this.agendaAtendimentos = resp.data;
                this.erro = "";

                if (operadoraId!=undefined) {
                   this.agendaAtendimentos = this.agendaAtendimentos.filter(a => a.operadoraId == operadoraId);
                }

                this.destacarLinhasAgenda();
            })
            .catch(error => {
                this.erro = errors.tratarErros(error);
            })
            .finally(() => {
                this.loading = false;
                this.loaded = true;
            })
        },
        async buscarNaAgendaParaSessoes(tenant, pacienteId, profissionalId, unidadeId, datInicial, datFinal, adicionarDiaSemana) {
            await this.$http.get(`/v1.0/agendaatendimentos/${tenant}/paciente/${pacienteId}/profissional/${profissionalId}/unidade/${unidadeId}/periodo/${datInicial}/${datFinal}`)
                    .then(resp => {
                        this.agendaAtendimentos = resp.data;
                        this.erro = "";

                        if (adicionarDiaSemana) {
                            //const funcoes = require('../utils/funcoes.js');
                            const constantes = require('../utils/constantes.js');
        
                            let agendaComDiaSemana = [...this.agendaAtendimentos];
        
                            this.agendaAtendimentos = agendaComDiaSemana.map(agenda => {
                                let dataJS = new Date(agenda.data)
        
                                let diaSemana = dataJS.getDay();
                                let diaSemanaAbrev = constantes.default.diasSemana.find(dia => dia.opcao == diaSemana).abrev;
        
                                return {...agenda, diaSemana, diaSemanaAbrev}
                            })
                        }
        
                    })
                    .catch(error => {
                        this.erro = errors.tratarErros(error);
                    })
                    .finally(() => {
                        this.loading = false;
                        this.loaded = true;
                    })
                },
        carregarAgendaPaciente(tenant, pacienteId) {
            this.$http.get(`/v1.0/agendaatendimentos/${tenant}/Paciente/${pacienteId}`)
            .then(resp => {
                this.agendaAtendimentos = resp.data;
                this.erro = "";
            })
            .catch(error => {
                this.erro = errors.tratarErros(error);
            })
            .finally(() => {
                this.loading = false;
                this.loaded = true;
            })
        },
        carregarAgendaSessoes(tenant, guiaId) {
            this.$http.get(`/v1.0/agendaatendimentos/${tenant}/Guia/${guiaId}`)
            .then(resp => {
                this.agendaAtendimentos = resp.data;
                this.erro = "";
            })
            .catch(error => {
                this.erro = errors.tratarErros(error);
            })
            .finally(() => {
                this.loading = false;
                this.loaded = true;
            })
        },
        destacarLinhasAgenda() {
            for (let i=0; i<this.agendaAtendimentos.length; i++) {
                if (this.agendaAtendimentos[i].status=='E') {
                    this.agendaAtendimentos[i]._rowVariant = 'danger';
                } else if (this.agendaAtendimentos[i].status=='C') {
                    this.agendaAtendimentos[i]._rowVariant = 'success';
                } else if (this.agendaAtendimentos[i].status=='H') {
                    this.agendaAtendimentos[i]._rowVariant = 'primary';
                } else {
                    this.agendaAtendimentos[i]._rowVariant = null;
                }
            }
        },
        async incluirPacienteGuiaSessoes(tenant, datasSessoes) {
            let sessoes = this.dtoSessoes(datasSessoes, false);

            await this.$http.put(`/v1.0/agendaAtendimentos/${tenant}/incluirPacienteGuiaSessoes/${sessoes[0].id}`, sessoes)
                    .then(() => {
                        this.erro = "";
                        this.$toasted.global.defaultSuccess();
                    })
                    .catch(error => this.erro = errors.tratarErros(error))
        },
        async removerPacienteGuiaSessoes(tenant, datasSessoes) {
            let sessoes = this.dtoSessoes(datasSessoes, true);

            await this.$http.put(`/v1.0/agendaAtendimentos/${tenant}/removerPacienteGuiaSessoes/${sessoes[0].id}`, sessoes)
                    .then(() => {
                        this.erro = "";
                        this.$toasted.global.defaultSuccess();
                    })
                    .catch(error => this.erro = errors.tratarErros(error))
        },
        async relacionarGuiaSessoes(tenant, datasSessoes) {
            let sessoes = this.dtoSessoes(datasSessoes, false);

            await this.$http.put(`/v1.0/agendaAtendimentos/${tenant}/registrarGuiaSessoes/${sessoes[0].id}`, sessoes)
                    .then(() => {
                        this.erro = "";
                        this.$toasted.global.defaultSuccess();
                    })
                    .catch(error => this.erro = errors.tratarErros(error))
        },
        async transferirSessoesDaGuia(tenant, datasSessoes) {
            let sessoes = this.dtoSessoes(datasSessoes, true);

            await this.$http.put(`/v1.0/agendaAtendimentos/${tenant}/registrarGuiaSessoes/${sessoes[0].id}`, sessoes)
                    .then(() => {
                        this.erro = "";
                        this.$toasted.global.defaultSuccess();
                    })
                    .catch(error => this.erro = errors.tratarErros(error))
        },
        async desatarGuiaSessoes(tenant, datasSessoes) {
            let sessoes = this.dtoSessoes(datasSessoes, true);

            await this.$http.put(`/v1.0/agendaAtendimentos/${tenant}/desatarGuiaSessoes/${sessoes[0].id}`, sessoes)
                    .then(() => {
                        this.erro = "";
                        this.$toasted.global.defaultSuccess();
                    })
                    .catch(error => this.erro = errors.tratarErros(error))
        },
        dtoSessoes(datasSessoes, comGuiaId) {
            let sessoes = [];

            datasSessoes.forEach(agenda => {
                if (comGuiaId || agenda.guiaId==null || agenda.guiaId==0) {
                    let sessao = {
                        id : agenda.id,
                        pacienteId : this.guiaSelecionada.pacienteId,
                        planoSaudeId : this.guiaSelecionada.planoSaudeId,
                        guiaId : this.guiaSelecionada.id,
                        datGuia : agenda.data,
                        livroFisioterapiaId : 0,
                        operadorAlteracaoId : this.usuarioLogadoDados.profissionalId,
                    }
                    sessoes.push(sessao)
                }
            });

            return sessoes;
        },
        async imprimirAgenda01(tenant, profissionalId, unidadeId, dataInicial, dataFinal) {
            /*await this.$http.get(`/v1.0/relatoriosAgendamentos/${tenant}/profissional/${profissionalId}/unidade/${unidadeId}/periodo/${dataInicial}/${dataFinal}`)
                .then(response => {
                    const blob = new Blob([response.data], { type: 'application/pdf' })
                    const link = document.createElement('a')
                    link.href = URL.createObjectURL(blob)
                    link.download = 'relatorio.pdf'
                    link.click()
                    URL.revokeObjectURL(link.href)               
                })
                .catch(error => errors.tratarErros(error))*/

                await this.$http.get(`/v1.0/relatoriosAgendamentos/${tenant}/profissional/${profissionalId}/unidade/${unidadeId}/periodo/${dataInicial}/${dataFinal}`, {responseType: 'blob'})
                .then(response => {
                    saveAs(response.data, 'relatorio-AgendaAtendimentos.pdf')
                })
                .catch(error => errors.tratarErros(error))
        }
    }
}
