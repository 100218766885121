import errors from "@/utils/errors";

export default {
    data() {
        return {
            pendencias : [],
        }
    },
    methods: {
        carregarPendencias(tenant, pacienteSelecionadoId) {
            this.$http
              .get(`/v1.0/pendencias/${tenant}/Paciente/${pacienteSelecionadoId}`)
              .then(resp => {
                this.pendencias = resp.data;
                this.erro = "";
                this.loading = false;
              })
              .catch(error => {
                this.erro = errors.tratarErros(error);
              })
              .finally(() => {
              });
          },
      
    }
}
