<template>
  <div>
    <div class="cabecalho">
      <div class="coluna1">
        <button @click="retornar" class="btn btn-sm btn-primary mr-10"><i class="fa fa-arrow-left"/></button>
      </div>
      <div class="coluna2">
        <span>{{ operadoraSelecionada.fantasia }}  - Plano: {{planoSaudeSelecionado.plano}} - Kits</span>
      </div>
      <div class="coluna3"/>
    </div>  

    <div class="tituloPagina">
      <span>{{kitSelecionado.descricao}}</span>
      <hr />
    </div>

    <div class="container-fluid">
      <div class="row" v-if="modo==''">
        <div class="col-12">
          <button
            class="btn btn-sm btn-info"
            @click="incluirRegistro()"
          >Incluir Novo Ítem ao Kit</button>
          <hr/>
        </div>
      </div>

      <div v-if="modo!=''" class="registro">
        <div class="row" v-if="modo=='Incluir'">
          <div class="col ml-2">
            <h4 style="text-align:left">Incluir Novo Ítem ao Kit</h4>
            <hr />
          </div>
        </div>

        <div class="row" v-if="modo=='Incluir'">
          <!-- Radio Group para Selecionar o que deseja Incluir -->
          <div class="col-lg-3 ml-2 mr-2">
            <label class="control-label">Deseja Incluir</label>
            <div class="form-group">
              <input
                type="radio"
                name="opcao"
                id="procedimento"
                value="Procedimento"
                v-model="opcaoAdicionar"
              />
              <label for="procedimento" class="control-label ml-2 mr-2">Procedimento</label>
              <input
                type="radio"
                name="opcao"
                id="medicamento"
                value="Medicamento"
                v-model="opcaoAdicionar"
              />
              <label for="medicamento" class="control-label ml-2 mr-2">Medicamento</label>
              <input
                type="radio"
                name="opcao"
                id="material"
                value="Material"
                v-model="opcaoAdicionar"
              />
              <label for="material" class="control-label ml-2 mr-2">Material</label>
              <input type="radio" name="opcao" id="taxa" value="Taxa" v-model="opcaoAdicionar" />
              <label for="taxa" class="control-label ml-2 mr-2">Taxa</label>
            </div>
          </div>

          <!-- Radio Group para Selecionar por qual Campo Buscar as informações -->
          <div class="col-lg-4">
            <!-- Procedimento -->
            <template v-if="opcaoAdicionar=='Procedimento'">
              <label class="control-label">Buscar Procedimento Coberto por:</label>
              <div class="form-group">
                <input
                  type="radio"
                  name="opcaoBusca"
                  id="codPro"
                  value="CodPro"
                  v-model="campoBusca"
                />
                <label for="codPro" class="control-label ml-2 mr-2">Código</label>
                <input
                  type="radio"
                  name="opcaoBusca"
                  id="codTussProcedimento"
                  value="CodTuss"
                  v-model="campoBusca"
                />
                <label for="codTussProcedimento" class="control-label ml-2 mr-2">Cód. TUSS</label>
                <input
                  type="radio"
                  id="descricaoProcedimento"
                  value="Descricao"
                  v-model="campoBusca"
                />
                <label for="descricaoProcedimento" class="control-label ml-2 mr-2">Descrição</label>
                <input type="radio" name="opcaoBusca" id="descricaoTussProcedimento" value="DescricaoTuss" v-model="campoBusca" />
                <label for="descricaoTussProcedimento" class="control-label ml-2 mr-2">Descr. TUSS</label>
              </div>
            </template>
            <!-- Medicamento/Material -->
            <template v-else-if="opcaoAdicionar=='Medicamento' || opcaoAdicionar=='Material'">
              <label v-if="opcaoAdicionar=='Medicamento'" class="control-label">Buscar Medicamento por:</label>
              <label v-else class="control-label">Buscar Material por:</label>
              <div class="form-group">
                <input
                  type="radio"
                  name="opcaoBusca"
                  id="descricao"
                  value="Descricao"
                  v-model="campoBusca"
                />
                <label for="descricao" class="control-label ml-2 mr-2">Descrição</label>
                <input
                  type="radio"
                  name="opcaoBusca"
                  id="descricaoGenerico"
                  value="DescricaoGenerico"
                  v-model="campoBusca"
                />
                <label for="descricaoGenerico" class="control-label ml-2 mr-2">Genérico</label>
              </div>
            </template>
            <!-- Taxa -->
            <template v-else-if="opcaoAdicionar=='Taxa'">
              <label class="control-label">Buscar Taxa por:</label>
              <div class="form-group">
                <input
                  type="radio"
                  name="opcaoBusca"
                  id="codTISSTaxa"
                  value="Codigo"
                  v-model="campoBusca"
                />
                <label for="codMatTaxa" class="control-label ml-2 mr-2">Código</label>
                <input
                  type="radio"
                  name="opcaoBusca"
                  id="codTUSSTaxa"
                  value="CodTuss"
                  v-model="campoBusca"
                />
                <label for="codMatTaxa" class="control-label ml-2 mr-2">TUSS</label>
                <input
                  type="radio"
                  name="opcaoBusca"
                  id="descricaoTaxa"
                  value="Descricao"
                  v-model="campoBusca"
                />
                <label for="descricaoTaxa" class="control-label ml-2 mr-2">Descrição</label>
              </div>
            </template>
          </div>
          <!-- Campo para digitação do que se deseja Buscar e Botão para realizar a busca -->
          <template v-if="campoBusca!=''" >
            <div class="col-lg-2">
              <div class="form-group" style="padding-top:16px">
                <input type="text" class="form-control" id="buscar" v-model="filtrar" placeholder="Digite o que deseja localizar..."/>
              </div>
            </div>
            <div class="col-lg-1" >
              <div class="form-group" style="width:80px;margin-left:0px;padding-top:16px">
                <button id="btnFiltrar" class="form-control" @click="carregarOpcoes()">
                  <i class="fa fa-search"/>
                </button>
              </div>
            </div>
          </template>
        </div>

        <!-- Select com o resultado da Busca após clicar no botão de Busca -->
        <div class="row" v-if="modo=='Incluir' && opcaoAdicionar!=''">
          <div class="col-lg-10 ml-2">
            <div class="form-group">
              <label for="selecionarItem" class="control-label">{{opcaoAdicionar}}</label>
              <select
                v-if="opcaoAdicionar=='Procedimento'"
                id="selecionarItem"
                class="form-control"
                v-model="kitItemSelecionado.descricao"
              >
                <option
                  v-for="procedimento in procedimentosCobertosDaOperadora"
                  :key="procedimento.id"
                  :value="procedimento.id+'-'+procedimento.codigoProcedimento+'*'+procedimento.descricao"
                >{{procedimento.descricao}}</option>
              </select>
              <select
                v-else-if="opcaoAdicionar=='Material'"
                id="selecionarItem"
                class="form-control"
                v-model="kitItemSelecionado.descricao"
              >
                <option
                  v-for="material in listaMateriais"
                  :key="material.id"
                  :value="material.id+'-'+material.descricao"
                >{{material.descricao}}</option>
              </select>
              <select
                v-else-if="opcaoAdicionar=='Medicamento'"
                id="selecionarItem"
                class="form-control"
                v-model="kitItemSelecionado.descricao"
              >
                <option
                  v-for="medicamento in listaMedicamentos"
                  :key="medicamento.id"
                  :value="medicamento.id+'-'+medicamento.descricao"
                >{{medicamento.descricao}}</option>
              </select>
              <select
                v-else-if="opcaoAdicionar=='Taxa'"
                id="selecionarItem"
                class="form-control"
                v-model="kitItemSelecionado.descricao"
              >
                <option
                  v-for="despesaCoberta in despesasCobertas"
                  :key="despesaCoberta.id"
                  :value="despesaCoberta.id+'-'+despesaCoberta.descricao"
                >{{despesaCoberta.descricao}}</option>
              </select>
            </div>
          </div>
        </div>

        <div class="row" v-if="opcaoAdicionar!=''">
          <div class="col-2 ml-2">
            <div class="form-group">
              <label for="unidade" class="control-label">Unidade: {{kitSelecionado.unidade}}</label>
              <select
                class="form-control"
                id="unidade"
                v-model="kitItemSelecionado.unidade"
                selected
              >
                <option
                  v-for="unidademedida in unidadesmedidas"
                  :key="unidademedida.id"
                  :value="unidademedida.termo"
                >{{unidademedida.descricao}} ({{unidademedida.termo}})</option>
              </select>
            </div>
          </div>
          <div class="col-1">
            <div class="form-group">
              <label for="qtde" class="control-label">Qtde:</label>
              <input type="number" class="form-control" id="qtde" v-model.number="kitItemSelecionado.qtde" />
            </div>
          </div>
          <div class="col-1">
            <div class="form-group">
              <label for="valor" class="control-label">Valor R$:</label>
              <input type="number" class="form-control" id="valor" v-model.number="kitItemSelecionado.valor" />
            </div>
          </div>

          <div class="col-3" v-if="opcaoAdicionar!=''" style="margin-top:28px">
            <button class="btn btn-sm btn-warning mr-2" @click="cancelarOperacao">Cancelar</button>
            <button class="btn btn-sm btn-info" @click="adicionarItem">Adicionar {{opcaoAdicionar}}</button>
          </div>
        </div>
      </div>

      <template v-if="this.erro!=''">
        <p class="msgError">Erro durante Operação: {{ this.erro }}</p>
      </template>

      <div style="display:flex; flex-direction:row; justify-content:center;max-height:400px;overflow:auto">
        <b-table vif="!loading" class="col-lg-9" hover striped small sort-icon-left :items="kitItens" :fields="camposTabela">
          <template v-slot:cell(actions)="row">
            <b-button variant="warning" size="sm" @click="selecionouKitItem(row.item)">
              <i class="fa fa-pencil"/>
            </b-button>
          </template>

          <template v-slot:cell(tipo)="tipo">
            {{tipo.value==1 ? "Proc" : tipo.value==2 ? "Med" : tipo.value==3 ? "Mat" : tipo.value == 4 ? "Taxa" : "Kit"}}
          </template>

          <template v-slot:cell(qtde)="qtde">
            {{qtde.value | formatValor}}
          </template>

          <template v-slot:cell(valor)="valor">
            {{valor.value | formatValor}}
          </template>
        </b-table>
      </div>
    </div>
    <div class="Mensagem">
      <b-modal id="modal-mensagem" centered title="DoctorOn">
        <p class="my-4">{{mensagem}}</p>
      </b-modal>
    </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import kitItens from "@/mixins/kitItens";
import unidadesMedidas from "@/mixins/unidadesMedidas"
import procedimentosCobertos from "@/mixins/procedimentosCobertos"
import despesasCobertas from "@/mixins/despesasCobertas";
import despesas from "@/mixins/despesas";
import global from "@/mixins/global";
import errors from "@/utils/errors";

export default {
  mixins : [kitItens, procedimentosCobertos, despesasCobertas, despesas, unidadesMedidas, global],
  computed: {
    ...mapGetters({
      operadoraSelecionada: "operadoras/getOperadoraSelecionada",
      planoSaudeSelecionado: "planosSaude/getPlanoSaudeSelecionado",
      kitSelecionado: "kits/getKitSelecionado",
      usuarioLogadoDados: "global/getUsuarioLogadoDados"
    })
  },
  data() {
    return {
      loading: true,
      loadedProcedimentosCobertos: false,
      erro: "",
      modo: "",
      retornando: false,
      opcaoAdicionar: "",
      campoBusca: "",
      filtrar: "",
      mensagem : "",
      //unidadesmedidas: null,

      //listaProcedimentos: null,
      listaMateriais: null,
      listaMedicamentos: null,
      //listadespesasCobertas: null,
      exibirAdicaoItem: false,
      kitItemSelecionado : {},

      camposTabela : [
        {key : "tipo", label : "Tipo", sortable : true},
        {key : "descricao", label : "Descrição", sortable : true},
        {key : "ativo", label : "Ativo"},
        {key : "qtde", label : "Qtde"},
        {key : "unidade", label : "Unidade"},
        {key : "valor", label : "Valor"},
        {key : "codReferencia", label : "Cod.Ref"},
        {key : "actions", label : "Ações"},
      ],

      depesaId: 0,
      procedimentoCobertoId: 0,
      kitId: 0
    }
  },
  watch: {
    opcaoAdicionar() {
      if (this.opcaoAdicionar=="Procedimento") {
        this.campoBusca="CodPro"
      } else if (this.opcaoAdicionar=="Material") {
        this.campoBusca="Descricao"
      } else if (this.opcaoAdicionar=="Medicamento") {
        this.campoBusca="Descricao"
      } else if (this.opcaoAdicionar=="Taxa") {
        this.campoBusca="Descricao"
      }
    }
  },
  methods: {
    ...mapActions({
      setKitItemSelecionado: "kitItens/setKitItemSelecionado",
      limparRegistroSelecionado: "kitItens/limparRegistroSelecionado",
      formLeave: "kitItens/formLeave"
    }),
    retornar() {
      this.retornando = true;
      this.$router.go(-1);
    },
    incluirRegistro() {
      if (this.modo == "" && !this.verificarPermissao('Alterar')) {
        return
      }
      
      this.kitItemSelecionado = {},
      this.modo = this.modo == "" ? "Incluir" : "";
    },
    selecionouKitItem(kitItem) {
      //this.kitItemSelecionado = {...kitItem}
      //this.modo = "Registro";

      this.setKitItemSelecionado(kitItem);
      this.$router.push('/cadKitItem');
    },
    //carregarUnidadesMedidas() {
    //  this.$http
    //    .get("/unidademedidas")
    //    .then(resp => {
    //      this.unidadesmedidas = resp.data;
    //      this.erro = "";
    //    })
    //    .catch(error => {
    //      this.erro = errors.tratarErros(error);
    //    });
    //},
    adicionarItem() {
      if (this.kitItemSelecionado.qtde == 0) {
        this.$toasted.show("Quantidade deve ser maior que zero.", {type: "info", icon : "times"});
        return;
      }

      if (this.kitItemSelecionado.unidade == "") {
        this.$toasted.show("Favor informar a Unidade.", {type: "info", icon : "times"});
        return;
      }

      //------------------ Tipos (Despesa.CodMatTiss)
      // 1 = Procedimento
      // 2 = Medicamentos
      // 3 = Materiais
      // 7 = Taxas ------------- Era 4 na versão Desktop
      //------------------------

      this.kitItemSelecionado.ativo = 'S';
      this.kitItemSelecionado.kitId = this.kitSelecionado.id;
      this.kitItemSelecionado.operadorCadastroId = this.usuarioLogadoDados.profissionalId;

      var posicao = this.kitItemSelecionado.descricao.indexOf("-");
      var id = this.kitItemSelecionado.descricao.substring(0, posicao);

      this.kitItemSelecionado.descricao = this.kitItemSelecionado.descricao.slice(
        posicao + 1
      );

      if (this.kitItemSelecionado.tipo == 1) {
        //this.kitItemSelecionado.despesaId = 0;
        this.kitItemSelecionado.procedimentoCobertoId = parseInt(id);

        posicao = this.kitItemSelecionado.descricao.indexOf("*");
        this.kitItemSelecionado.codReferencia = this.kitItemSelecionado.descricao.substring(
          0,
          posicao
        );

        this.kitItemSelecionado.descricao = this.kitItemSelecionado.descricao.slice(
          posicao + 1
        );
      } else {
        //this.kitItemSelecionado.procedimentoCobertoId = 0;
        this.kitItemSelecionado.despesaId = parseInt(id);
      }

      this.gravarRegistro();
    },
    gravarRegistro() {
      this.$http
        .post(`/v1.0/kititens/${this.usuarioLogadoDados.tenant}`, this.kitItemSelecionado)
        .then(() => {
          this.erro = "";
          this.modo = "Incluir";
          this.limparCampos();
          this.$toasted.global.defaultSuccess();
          this.carregarKitItens(this.usuarioLogadoDados.tenant, this.kitSelecionado.id);
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        })
        .finally(() => {});
    },
    limparCampos() {
      this.kitItemSelecionado = {}

      if (this.modo!="Incluir") {
        this.modo = "";
      }
    },
    cancelarOperacao() {
      this.kitItemSelecionado = {}
      this.modo = "";
      this.erro = "";
    },
    carregarOpcoes() {
      if (this.filtrar.length < 4) {
        this.$toasted.show("Digite pelo menos 4 dígitos para realizar a busca.", {type: "info", icon: "times"})
        return;
      }
      if (this.opcaoAdicionar == "Procedimento") {
        this.kitItemSelecionado.tipo = 1;
        this.carregarProcedimentosCobertos(this.usuarioLogadoDados.tenant, this.planoSaudeSelecionado.id, this.campoBusca, this.filtrar, "N", 0);
      } else if (this.opcaoAdicionar == "Material") {
        this.kitItemSelecionado.tipo = 3;
        this.carregarMateriaisPeloCodMatTiss(this.usuarioLogadoDados.tenant, this.kitItemSelecionado.tipo, this.campoBusca, this.filtrar, 0);
      } else if (this.opcaoAdicionar == "Medicamento") {
        this.kitItemSelecionado.tipo = 2;
        this.carregarMateriaisPeloCodMatTiss(this.usuarioLogadoDados.tenant, this.kitItemSelecionado.tipo, this.campoBusca, this.filtrar, 0);
      } else if (this.opcaoAdicionar == "Taxa") {
        this.kitItemSelecionado.tipo = 4;
        this.carregarDespesasCobertas(this.usuarioLogadoDados.tenant, this.planoSaudeSelecionado.operadoraId, this.campoBusca, this.filtrar, 0);
      }
    },
    verificarPermissao(operacao) {
      if (!errors.verificarPermissao(this.usuarioLogadoDados, this.$store.state.global.grauPermissaoMinimo, operacao)) {
        let descricaoOperacao = operacao == "Incluir" ? "Inclusão" : operacao == "Alterar" ? "Alteração" : "Exclusão";

        this.mensagem = `${this.usuarioLogadoDados.profissional.nome}, você não possui permissão para realizar ${descricaoOperacao} com esse Perfil.`;
        this.$bvModal.show("modal-mensagem");

        return false;
      }

      return true;
    }    
  },
  created() {
    this.showMenu(false);
    this.carregarKitItens(this.usuarioLogadoDados.tenant, this.kitSelecionado.id);
    this.carregarUnidadesMedidas(this.usuarioLogadoDados.tenant);
  },
  beforeRouteLeave(to, from, next) {
    if (this.retornando) {
      this.unidadesmedidas = null;
      this.procedimentosCobertosDaOperadora = null;
      this.listaMateriais = null;
      this.listaMedicamentos = null;
      this.despesasCobertas = null;

      this.formLeave();
    }
    next(true);
  }
}
</script>

<style scoped>
  label {
    font-weight: 100%;
    font-size: 0.8rem;
    margin-top: 3px;
    margin-bottom: 1px;
  }
</style>
