import { render, staticRenderFns } from "./frmConsultorioItens.vue?vue&type=template&id=3720a6ea&scoped=true&"
import script from "./frmConsultorioItens.vue?vue&type=script&lang=js&"
export * from "./frmConsultorioItens.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3720a6ea",
  null
  
)

export default component.exports