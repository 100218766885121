<template>
    <div>
      <div class="cabecalho">
        <div class="coluna1">
          <button @click="retornar" class="btn btn-sm btn-primary mr-10"><i class="fa fa-arrow-left"/></button>
        </div>
        <div class="coluna2">
          <span>Recebimento - Nº {{recebimentoSelecionado.id}}</span>
        </div>
        <div class="coluna3"/>
      </div>  

      <!-- <template v-if="operacao=='ExibirGuias' || guiasPendentes.length>0"> -->
      <template>
        <!-- Tabela com Guias Pendentes para Recebimento -->
        <div class="row">
            <div class="col-12">
              <h3 class="text-center my-2">Atendendimentos Pendentes</h3>
            </div>
        </div>

        <div style="display:flex; flex-direction:row; justify-content:center;">
          <b-spinner v-if="loadingGuiasPedendentes" label="carregando..."/>
          <b-table
            v-if="loadedGuiasPedendentes"
            class="col-lg-10"
            hover
            striped
            small
            sort-icon-left
            :items="guiasPendentes"
            :fields="camposTabelaGuias"
          >
            <template v-slot:cell(actions)="row">
              <b-button variant="warning" size="sm" @click="selecionouGuia(row.item)" class="mr-2">
                <i class="fa fa-pencil"/>
              </b-button>
              <b-button v-if="recebimentoSelecionado.datRecebimento==null" variant="info" size="sm" @click="adicionarGuiaPendente(row.item, true)" class="mr-2">
                <i class="fa fa-plus"/>
              </b-button>
            </template>
            <template v-slot:cell(datAtendimento)="datAtendimento">
               {{datAtendimento.value | formatDate}}
             </template>

            <template v-slot:cell(valTratamentos)="valTratamentos">
               {{valTratamentos.value | formatValor}}
            </template>

            <template v-slot:cell(valMateriais)="valMateriais">
               {{valMateriais.value | formatValor}}
            </template>

            <template v-slot:cell(valMedicamentos)="valMedicamentos">
               {{valMedicamentos.value | formatValor}}
            </template>

            <template v-slot:cell(valTaxas)="valTaxas">
               {{valTaxas.value | formatValor}}
            </template>

            <template v-slot:cell(valDescontosTratamentos)="valDescontosTratamentos">
               {{valDescontosTratamentos.value | formatValor}}
            </template>

            <template v-slot:cell(valDescontosDespesas)="valDescontosDespesas">
               {{valDescontosDespesas.value | formatValor}}
            </template>

            <template v-slot:cell(valTotal)="valTotal">
               {{valTotal.value | formatValor}}
            </template>
          </b-table>
        </div>

        <div class="row justify-content-center">
          <div class="col-10"  >
            <button v-if="recebimentoSelecionado.datRecebimento==null"
              id="Guias"
              @click="adicionarTodasGuiasPendentes()"
              class="btn btn-sm btn-info mt-2 mb-2" style="width: 100%"
            >Adicionar Todos os Atendimentos</button>
          </div>
        </div>
      </template>

      <div class="col-lg-12">
        <div class="row justify-content-center">
          <div class="col-10">
            <h3 class="text-center">Registro de Recebimento</h3>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="form-group col-lg-2">
            <label for="DatAtendimento" class="control-label">Data do Atendimento</label>
            <input
              type="date"
              class="form-control"
              :disabled="recebimentoSelecionado.datRecebimento!=null"
              id="DatAtendimento"
              v-model="recebimentoSelecionado.datAtendimento"
            />
          </div>

          <div class="form-group col-lg-2">
            <label for="DatRecebimento" class="control-label">Data do Recebimento</label>
            <input
              type="date"
              class="form-control"
              disabled
              id="DatRecebimento"
              v-model="recebimentoSelecionado.datRecebimento"
            />
          </div>

          <div class="form-group col-lg-1">
            <label for="notaFiscalNumero" class="control-label">Nota Fiscal</label>
            <input
              type="text"
              class="form-control"
              id="notaFiscalNumero"
              v-model="recebimentoSelecionado.notaFiscalNumero"
            />
          </div>
          <div class="form-group col-lg-1">
            <label for="reciboNumero" class="control-label">Recibo</label>
            <input
              type="text"
              class="form-control"
              id="reciboNumero"
              v-model="recebimentoSelecionado.reciboNumero"
            />
          </div>
          <div class="form-group col-lg-1">
            <label for="ValTratamentos" class="control-label">Tratamentos R$</label>
            <input
              type="number"
              class="form-control"
              disabled
              id="valTratamentos"
              v-model="recebimentoSelecionado.valTratamentos"
            />
          </div>

          <div class="form-group col-lg-1">
            <label for="ValDespesas" class="control-label">Despesas R$</label>
            <input
              type="number"
              class="form-control"
              disabled
              id="valDespesas"
              v-model="recebimentoSelecionado.valDespesas"
            />
          </div>

          <div class="form-group col-lg-1">
            <label for="ValPacotes" class="control-label">Pacotes R$</label>
            <input
              type="number"
              class="form-control"
              disabled
              id="valPacotes"
              v-model="recebimentoSelecionado.valPacotes"
            />
          </div>

          <div class="form-group col-lg-1">
            <label for="total" class="control-label">Total R$</label>
            <input
              type="number"
              class="form-control"
              disabled
              id="total"
              v-model="totalReceber"
            />
          </div>
        </div>

        <!-- Guias Relacionadas ao Recebimento -->
        <template v-if="guiasRelacionadas.length>0">
          <!-- <div style="display:flex; flex-direction:row; justify-content:center; height:110px;overflow:auto"> -->
          <div style="display:flex; flex-direction:row; justify-content:center; height:110px">
            <b-spinner v-if="loadingGuiasRelacionadas" label="carregando..."/>
            <b-table
              v-if="loadedGuiasRelacionadas"
              class="col-lg-10"
              hover
              striped
              small
              sort-icon-left
              :items="guiasRelacionadas"
              :fields="camposTabelaGuias"
            >
              <template v-if="recebimentoSelecionado.datRecebimento==null"  v-slot:cell(actions)="row">
                <b-button variant="warning" size="sm" @click="selecionouGuia(row.item)" class="mr-2">
                  <i class="fa fa-pencil"/>
                </b-button>
                <b-button variant="danger" size="sm" @click="removerGuiaPendente(row.item)" class="mr-2">
                  <i class="fa fa-minus"/>
                </b-button>
              </template>

              <template v-slot:cell(datAtendimento)="datAtendimento">
                {{datAtendimento.value | formatDate}}
              </template>

              <template v-slot:cell(valTratamentos)="valTratamentos">
                {{valTratamentos.value | formatValor}}
              </template>

              <template v-slot:cell(valMateriais)="valMateriais">
                {{valMateriais.value | formatValor}}
              </template>

              <template v-slot:cell(valMedicamentos)="valMedicamentos">
                {{valMedicamentos.value | formatValor}}
              </template>

              <template v-slot:cell(valDescontosTratamentos)="valDescontosTratamentos">
                {{valDescontosTratamentos.value | formatValor}}
              </template>

              <template v-slot:cell(valDescontosDespesas)="valDescontosDespesas">
                {{valDescontosDespesas.value | formatValor}}
              </template>

              <template v-slot:cell(valTotal)="valTotal">
                {{valTotal.value | formatValor}}
              </template>
            </b-table>
          </div>
          <strong><p style="text-align:center">{{this.guiasRelacionadas.length}} Atendimento(s) para Recebimento</p></strong>
        </template>
        <!-- ---------------------------------------------------------------------------------- -->
        <br>
        <div class="row justify-content-center">
          <div class="form-group col-sm-2 col-md-5 col-lg-10">
            <label for="observacao" class="control-label">Observações sobre o Recebimento</label>
            <textarea
              class="form-control"
              rows="2"
              cols="600"
              id="observacao"
              v-model="recebimentoSelecionado.observacao"
            />
          </div>
        </div>

        <div id="erro">
          <p v-if="erro" style="background-color:red; color:white; text-align:center">{{erro}}</p>
        </div>

        <template v-if="recebimentoSelecionado.datRecebimento!=null">
          <hr />
          <div class="row justify-content-center mb-2">
            <div class="col-10">
              <button
                id="Gravar"
                @click="gravarRegistroRecebimento()"
                class="btn btn-sm btn-info" style="width: 100%"
              >Gravar Alterações do Registro Recebimento</button>
            </div>
          </div>
        </template>
      </div>

      <!-- Realizar Recebimento -->
      <template v-if="guiasRelacionadas.length>0 && recebimentoSelecionado.datRecebimento==null">
        <div class="col-lg-12">
          <div class="row justify-content-center">
            <div class="form-group col-lg-2">
              <label for="Descricao" class="control-label">Descrição</label>
              <input
                type="text"
                class="form-control"
                id="Descricao"
                v-model="recebimentoParcelaSelecionada.descricao"
              />
            </div>
            <div class="form-group col-lg-2">
                <label for="tipoLancamentos" class="control-label">Em</label>
                <select id="tipoLancamentos" class="form-control" v-model="recebimentoParcelaSelecionada.tipoLancamentoId" @change="selecionouTipoLancamento()" selected>
                    <option v-for="tipoLancamento in tipoLancamentos" :key="tipoLancamento.id" :value="tipoLancamento.id">
                        {{tipoLancamento.descricao}}
                    </option>
                </select>
            </div>
            <div class="form-group col-lg-2">
              <label for="datPredatado" class="control-label">Bom para</label>
              <input
                type="date"
                class="form-control"
                id="datPredatado"
                v-model="recebimentoParcelaSelecionada.datPredatado"
              />
            </div>
            <div class="form-group col-lg-1">
              <label for="valDevido" class="control-label">Valor R$</label>
              <input
                type="number"
                class="form-control"
                id="valDevido"
                v-model.number="recebimentoParcelaSelecionada.valDevido"
              />
            </div>
            <template v-if="modoParcela!='Alteracao'">
              <div class="form-group col-1">
                <label for="numeroDeParcelas" class="control-label">No. Parcelas</label>
                <input
                  type="number"
                  class="form-control"
                  id="numeroDeParcelas"
                  v-model.number="numeroDeParcelas"
                />
              </div>
            </template>
            <div class="form-group col-lg-2">
              <button v-if="recebimentoSelecionado.datRecebimento==null" class="btn btn-info btn-sm" style="margin-top:30px" @click="lancarParcela()">Lançar Parcela</button>
            </div>
          </div>
          <template v-if="tipoLancamento.toUpperCase()=='CHEQUE'">
            <div class="row">
              <div class="form-group col-lg-1">
                <label for="banco" class="control-label">Banco</label>
                <input
                  type="text"
                  class="form-control"
                  id="banco"
                  v-model="recebimentoParcelaSelecionada.banco"
                />
              </div>
              <div class="form-group col-lg-1">
                <label for="agencia" class="control-label">Agência</label>
                <input
                  type="text"
                  class="form-control"
                  id="agencia"
                  v-model="recebimentoParcelaSelecionada.agencia"
                />
              </div>
              <div class="form-group col-lg-1">
                <label for="conta" class="control-label">Conta</label>
                <input
                  type="text"
                  class="form-control"
                  id="conta"
                  v-model="recebimentoParcelaSelecionada.conta"
                />
              </div>
              <div class="form-group col-lg-2">
                <label for="cheque" class="control-label">Cheque Nº</label>
                <input
                  type="text"
                  class="form-control"
                  id="cheque"
                  v-model="recebimentoParcelaSelecionada.cheque"
                />
              </div>
              <div class="form-group col-lg-2">
                <label for="portador" class="control-label">Portador</label>
                <input
                  type="text"
                  class="form-control"
                  id="portador"
                  v-model="recebimentoParcelaSelecionada.portador"
                />
              </div>
              <div class="form-group col-lg-2">
                <label for="portadorCpfNumero" class="control-label">CPF Portador</label>
                <input
                  type="text"
                  class="form-control"
                  id="portadorCpfNumero"
                  v-model="recebimentoParcelaSelecionada.portadorCpfNumero"
                />
              </div>
            </div>
          </template>
        </div>
      </template>

      <template v-if="recebimentoParcelas.length>0">
        <!-- Tabela de Parcelas -->
        <!-- <div style="display:flex; flex-direction:row; justify-content:center; height:110px;overflow:auto"> -->
        <div style="display:flex; flex-direction:row; justify-content:center; height:110px">
          <b-table
            v-if="loadedRecebimentoParcelas"
            class="col-lg-10"
            hover
            striped
            small
            sort-icon-left
            :items="recebimentoParcelas"
            :fields="camposTabelaParcelas"
          >
            <template v-if="recebimentoSelecionado.datRecebimento==null" v-slot:cell(actions)="row">
              <b-button variant="warning" size="sm" @click="selecionouParcela(row.item)" class="mr-2">
                <i class="fa fa-pencil"/>
              </b-button>
              <b-button variant="danger" size="sm" @click="removerParcela(row.item)" class="mr-2">
                <i class="fa fa-minus"/>
              </b-button>
            </template>
            <template v-slot:cell(datPredatado)="datPredatado">
               {{datPredatado.value | formatDate}}
            </template>

            <template v-slot:cell(valDevido)="valDevido">
               {{valDevido.value | formatValor}}
            </template>
          </b-table>
        </div>
        <b-spinner v-if="loadingRecebimentoParcelas" label="carregando..."/>
        <strong><p style="text-align:center">{{this.recebimentoParcelas.length}} Parcelas cadastradas, totalizando R$ {{this.totalParcelas | formatValor}}</p></strong>
      </template>

      <div class="row justify-content-center">
        <template v-if="recebimentoSelecionado.datRecebimento==null">
          <div class="col-5">
              <button 
                id="Receber"
                @click="finalizarRecebimento()"
                class="btn btn-sm btn-info" style="width: 100%"
              >Realizar Recebimento</button>
          </div>
        </template>
        <template v-else>
          <div class="col-5">
              <button 
                id="Estornar"
                @click="estornarRecebimento()"
                class="btn btn-sm btn-danger" style="width: 100%"
              >Estornar Recebimento</button>
          </div>
        </template>
      </div>
    </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import errors from "@/utils/errors";
import funcoes from "@/utils/funcoes";
import guias from "@/mixins/guias";
import recebimentoParcelas from "@/mixins/recebimentoParcelas"
import tipoLancamentos from "@/mixins/tipoLancamentos"
import global from "@/mixins/global";

export default {
    mixins : [guias, recebimentoParcelas, tipoLancamentos, global],
    computed : {
        ...mapGetters({
            recebimentoSelecionado : "recebimentos/getRecebimentoSelecionado",
            usuarioLogadoDados : "global/getUsuarioLogadoDados",
            pacienteSelecionado : "pacientes/getPacienteSelecionado",
            parametrosLanctoGuia : "guias/getParametrosLanctoGuia",
            origemRecebimento : "recebimentos/getOrigemRecebimento",
        }),
    },
    data() {
        return {
            modo : "",
            erro : "",
            totalReceber : 0,
            totalParcelas: 0,
            guiasPendentes : [],
            guiasRelacionadas : [],
            guiasOriginais : [],
            recebimentoParcelas : [],
            operacao : "",
            recebimentoOriginal : {},
            recebimentoParcelaSelecionada : {},
            tipoLancamento : "",
            modoParcela : "",
            numeroDeParcelas : 1,

            loadingGuiasPedendentes : false,
            loadedGuiasPedendentes : false,

            loadingGuiasRelacionadas : false,
            loadedGuiasRelacionadas : false,

            loadingRecebimentoParcelas : false,
            loadedRecebimentoParcelas : false,

            camposTabelaGuias : [
              {key : "id", label : "Id"},
              {key : "datAtendimento", label : "Atendimento", sortable : true},
              {key : "valTratamentos", label : "Tratamentos R$"},
              {key : "valMateriais", label : "Materiais R$"},
              {key : "valMedicamentos", label : "Medicamentos R$"},
              {key : "valTaxas", label : "Taxas R$"},
              {key : "valDescontosTratamentos", label : "Desc. Trat."},
              {key : "valDescontosDespesas", label : "Desc. Desp."},
              {key : "valTotal", label : "Total R$"},
              {key : "actions", label : "Ações"}
            ],

            camposTabelaParcelas : [
              {key : "descricao", label : "Descrição"},
              {key : "valDevido", label : "Valor R$"},
              {key : "datPredatado", label : "Bom Para"},

              {key : "banco", label : "Banco"},
              {key : "agencia", label : "Agência"},
              {key : "conta", label : "Conta"},
              {key : "cheque", label : "Cheque"},
              {key : "portador", label : "Portador"},
              {key : "portadorCpfNumero", label : "CPF"},
              {key : "actions", label : "Ações"}
            ],
        }
    },
    methods : {
      ...mapActions({
        setGuiaSelecionada : "guias/setGuiaSelecionada",
        setPlanoSaudeSelecionado: "planosSaude/setPlanoSaudeSelecionado",
        setParametrosLanctoGuia : "guias/setParametrosLanctoGuia",
        setGuiaChamadoPor : "guias/setChamadoPor",
      }),
      retornar() {
        this.$router.go(-1);
      },
      async gravarRegistroRecebimento() {
        //-------------------------------- Gravar Registro de Recebimentos
        if (this.recebimentoSelecionado.observacao != this.recebimentoOriginal.observacao ||
            this.recebimentoSelecionado.notaFiscalNumero != this.recebimentoOriginal.notaFiscalNumero ||
            this.recebimentoSelecionado.reciboNumero != this.recebimentoOriginal.reciboNumero) {

            this.recebimentoSelecionado.operadorAlteracaoId = this.usuarioLogadoDados.profissionalId;

            await this.$http.put(`/v1.0/recebimentos/${this.usuarioLogadoDados.tenant}/alterarCamposEspecificos/${this.recebimentoSelecionado.id}`, this.recebimentoSelecionado)
            .then(() => {
              this.erro = "";
              this.$toasted.global.defaultSuccess();
            })
            .catch(error => this.erro = errors.tratarErros(error));
        } else {
          this.$toasted.show("Operação não necessária. Nenhum registro foi alterado no Registro de Recebimento.", {type: warning, icon: info});
        }
      },
      async gravarGuiasAdicionadas(guiasAdicionadas) {
        await this.$http.put(`/v1.0/recebimentos/${this.usuarioLogadoDados.tenant}/adicionarGuiasAoRecebimento/${this.recebimentoSelecionado.id}`, guiasAdicionadas)
        .then(() => {
          })
        .catch(error => this.erro = errors.tratarErros(error))
      },
      async gravarGuiasRemovidas(guiasRemovidas) {
        await this.$http.put(`/v1.0/recebimentos/${this.usuarioLogadoDados.tenant}/removerGuiasDoRecebimento/${this.recebimentoSelecionado.id}`, guiasRemovidas)
        .then(() => {
        })
        .catch(error => this.erro = errors.tratarErros(error))
      },
      async iniciarRecebimento() {
        if (this.tipoLancamentos.length == 0) {
          this.carregarTipoLancamentos(this.usuarioLogadoDados.tenant);
        }

        this.operacao = "Recebimento";
        this.zerarRecebimentoParcela();
      },
      totalizarParcelas() {
        let valorParcelas = 0;

        this.recebimentoParcelas.forEach(parcela => {
          valorParcelas += parcela.valDevido;
        })

        this.totalParcelas = valorParcelas;
      },
      zerarRecebimentoParcela() {
        this.recebimentoParcelaSelecionada = {
          id : 0, recebimentoId : this.recebimentoSelecionado.id, tipoLancamentoId : 0, 
          datRecebimento : null, datPredatado : null, datGerouCr : null,
          banco : null, agencia : null, conta : null, cheque : null, portador : null, portadorCpfNumero : null,
          descricao : null, valDevido : 0, valRecebido : 0,
          operadorId : this.usuarioLogadoDados.profissionalId
        }
      },
      finalizarRecebimento() {
        if (this.totalReceber != this.totalParcelas) {
          this.$toasted.show(`Valor Total da(s) Parcela(s) (R$ ${this.totalParcelas}) é diferente do Valor Total à Receber (R$ ${this.totalReceber}).`, {type : "warning", icon : "times"});
          return;
        }

        if (this.guiasRelacionadas.length==0) {
          this.$toasted.show('Não foram relacionadas Atendimentos para o recebimento', {type : "warning", icon : "times"});
          return;
        }

        let guiasId = [];
        this.guiasRelacionadas.forEach(guia => {
          guiasId.push(guia.id);
        })

        let recebimento = {
          id : this.recebimentoSelecionado.id,
          operadorId : this.usuarioLogadoDados.profissionalId,
          reciboNumero : this.recebimentoSelecionado.reciboNumero,
          notaFiscalNumero : this.recebimentoSelecionado.notaFiscalNumero,
          observacao : this.recebimentoSelecionado.observacao,
          guiasIds : [... guiasId],
          recebimentoParcelas : [...this.recebimentoParcelas]
        }

        this.$http.put(`/v1.0/recebimentos/${this.usuarioLogadoDados.tenant}/realizarRecebimento/${recebimento.id}`, recebimento)
        .then(() => {
          this.erro = "";
          this.recebimentoSelecionado.datRecebimento = funcoes.mySqlToJSDateFormat(Date());
          this.operacao = "";

          this.$toasted.global.defaultSuccess();
        })
        .catch(error => this.erro = errors.tratarErros(error));
      },
      estornarRecebimento() {
        this.$bvModal
          .msgBoxConfirm("Estornar esse Recebimento?", {
            title: "Atenção",
            size: "sm",
            buttonSize: "sm",
            okTitle: "Sim",
            cancelTitle: "Não",
            footerClass: "p-2",
            hideHeaderClose: true,
            centered: true
          })
          .then(value => {
            if (value) {
              let estornar = {
                id : this.recebimentoSelecionado.id, operadorId : this.usuarioLogadoDados.profissionalId,
                reciboNumero : "", notaFiscalNumero : ""
              }

              this.$http.put(`/v1.0/recebimentos/${this.usuarioLogadoDados.tenant}/estornarRecebimento/${this.recebimentoSelecionado.id}`, estornar)
              .then(() => {
                this.erro = "";
                this.$toasted.global.defaultSuccess();
                this.recebimentoSelecionado.datRecebimento = null;

                this.operacao = "";
              })
              .catch(error => this.erro = errors.tratarErros(error));
            }
          })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          });
      },
      async ExibirGuiasPendentes() {
        await this.carregarGuiasParticularesPendentes(this.usuarioLogadoDados.tenant, this.origemRecebimento.pacienteId);

        this.guias.forEach(element => {
          element.valTotal = (element.valTratamentos + element.valMateriais + element.valMedicamentos + element.valTaxas) - 
                             (element.valDescontosDespesas + element.valDescontosTratamentos)
        })

        if (this.guiasRelacionadas.length < 1) {
          this.guiasPendentes = [...this.guias];
        } else {
          let indice = 0;

          this.guias.forEach(i => {
            indice = this.guiasRelacionadas.findIndex(ir => ir.id == i.id);

            if (indice == -1) {
              this.guiasPendentes.push(i);
            }
          })
        }

        this.guias = [];
      },
      selecionouGuia(guia) {
        let parametros = {
          paciente : {id : this.pacienteSelecionado.id, nome : this.pacienteSelecionado.nome},
                          profissionalExecutanteId : 0, 
                          profissionalSolicitanteId : 0, 
                          operadoraId : 0,
                          planoSaudeId : 0,
                          tipoLote : -1, guiaOperadora : null,
                          datAutorizacao : null, senhaAutorizacao : null, datValidadeSenha : null,

                          datAtendimento : null
                         }

       this.setGuiaSelecionada(guia);
       this.setParametrosLanctoGuia(parametros);
       this.setGuiaChamadoPor("Recebimento");

        this.$router.push('/frmLanctoGuiaItens');
      },
      async adicionarTodasGuiasPendentes() {
       // await this.carregarGuiasParticularesPendentes(this.usuarioLogadoDados.tenant, this.origemRecebimento.pacienteId);
            
        let indice = 0;
        for(indice = 0; indice < this.guiasPendentes.length; indice++ ) {
           //this.guiasRelacionadas.push({...this.guiasPendentes[indice]});
           this.adicionarGuiaPendente(this.guiasPendentes[indice], false);
        }
    
        this.guiasPendentes = [];
        this.recalcularTotais();
      },
      adicionarGuiaPendente(guia, individual) {
        this.guiasRelacionadas.push(guia);

        if (individual)  {
          this.guiasPendentes.splice(this.guiasPendentes.findIndex(i => i.id == guia.id),1);
          this.recalcularTotais();
        }
      },
      recalcularTotais() {
        this.zerarTotaisParaRecebimento();
        this.calcularTotaisParaRecebimento();

        this.iniciarRecebimento();
      },
      async removerGuiaPendente(guia) {
        if (this.guiasPendentes.length < 1) {
          await this.iniciarAdicionarRemoverGuias();
        }

        this.guiasPendentes.push(guia);
        this.guiasRelacionadas.splice(this.guiasRelacionadas.findIndex(i => i.id == guia.id),1);

        this.zerarTotaisParaRecebimento();
        this.calcularTotaisParaRecebimento();
      },
      async iniciarAdicionarRemoverGuias() {
        this.operacao = "ExibirGuias";
        await this.ExibirGuiasPendentes();
      },
      zerarTotaisParaRecebimento() {
        this.recebimentoSelecionado.valTratamentos = 0;
        this.recebimentoSelecionado.valDespesas = 0;
        this.recebimentoSelecionado.valPacotes = 0;
        this.totalReceber = 0;
      },
      calcularTotaisParaRecebimento() {
        this.guiasRelacionadas.forEach(guiaAdicionada => {
          let valTratamentos = guiaAdicionada.valTratamentos;
          let valDespesas = guiaAdicionada.valMateriais + guiaAdicionada.valMedicamentos + guiaAdicionada.valTaxas;
          let valPacotes = guiaAdicionada.valPacotes;

          let valDescontosTratamentos = guiaAdicionada.valDescontosTratamentos;
          let valDescontosDespesas = guiaAdicionada.valDescontosDespesas;

          this.recebimentoSelecionado.valTratamentos += valTratamentos;
          this.recebimentoSelecionado.valDespesas += valDespesas;
          this.recebimentoSelecionado.valPacotes += valPacotes;

          this.totalReceber += ((valTratamentos + valDespesas + valPacotes) - (valDescontosTratamentos + valDescontosDespesas));
        })
      },
      selecionouTipoLancamento() {
        this.tipoLancamento = this.tipoLancamentos.find(tipo => tipo.id == this.recebimentoParcelaSelecionada.tipoLancamentoId).descricao.toUpperCase();
      },
      lancarParcela() {
        this.erro == "";
        let valorParcela = parseFloat((this.recebimentoParcelaSelecionada.valDevido / this.numeroDeParcelas).toFixed(2));

        let centavos = 0.00;
        if (valorParcela > this.recebimentoSelecionado.valDevido) {
          centavos = -0.01
        } else {
          centavos = 0.01
        }

        for(let indice = 1;  indice <= this.numeroDeParcelas; indice++) {
          let descricao = '';
          if (this.recebimentoParcelaSelecionada.descricao != null) {
            descricao = this.recebimentoParcelaSelecionada.descricao;
          } else {
            descricao = 'Parcela'
          }
  
          if (this.modoParcela != 'Alteracao' && this.numeroDeParcelas > 1) {
            descricao += " " + indice.toString()
          } else {
            if (this.modoParcela != 'Alteracao') {
              descricao += " Única"
            }
          }

          let novaParcela = {
            recebimentoId : this.recebimentoSelecionado.id,
            descricao : descricao,
            valDevido : valorParcela,
            valRecebido : valorParcela,
            datPredatado : this.recebimentoParcelaSelecionada.datPredatado,
            banco : this.recebimentoParcelaSelecionada.banco,
            agencia : this.recebimentoParcelaSelecionada.agencia,
            conta : this.recebimentoParcelaSelecionada.conta,
            cheque : this.recebimentoParcelaSelecionada.cheque,
            portador : this.recebimentoParcelaSelecionada.portador,
            portadorCpfNumero : this.recebimentoParcelaSelecionada.portadorCpfNumero,
            tipoLancamentoId : this.recebimentoParcelaSelecionada.tipoLancamentoId,
            operadorCadastroId : this.usuarioLogadoDados.profissionalId,
            operadorAlteracaoId : this.usuarioLogadoDados.profissionalId, 
          }

          if (indice == 1 && this.numeroDeParcelas > 1) {
            novaParcela.valDevido = valorParcela + centavos;
            novaParcela.valRecebido = valorParcela + centavos;
          }

          if (this.modoParcela != 'Alteracao') {
            novaParcela.randomico = Math.random();
            this.recebimentoParcelas.push(novaParcela);
          } else {
            let indiceParcela = this.recebimentoParcelas.findIndex(p => p.randomico == this.recebimentoParcelaSelecionada.randomico);
            if (indiceParcela != -1) {
              this.recebimentoParcelas[indiceParcela] = {...novaParcela};
            } else {
              this.recebimentoParcelas.push(novaParcela);
            }
          }
        }

        this.loadedRecebimentoParcelas = true;
        this.loadingRecebimentoParcelas = false;

        this.totalizarParcelas();
        this.zerarRecebimentoParcela();

        this.modoParcela = "";
        this.numeroDeParcelas = 1;
      },
      removerParcela(recebimentoParcela) {
        this.$bvModal
          .msgBoxConfirm("Excluir a Parcela selecionada?", {
            title: "Atenção",
            size: "md",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "Sim",
            cancelTitle: "Não",
            footerClass: "p-2",
            hideHeaderClose: true,
            centered: true
          })
          .then(value => {
            if (value) {
              let indice = this.recebimentoParcelas.findIndex(p => p.descricao == recebimentoParcela.descricao && p.datPredatado == recebimentoParcela.datPredatado);
              if (indice != -1) {
                this.recebimentoParcelas.splice(indice, 1);
              }
            }
          })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          });
      },
      selecionouParcela(recebimentoParcela) {
        this.recebimentoParcelaSelecionada = {
          randomico : recebimentoParcela.randomico,
          id : recebimentoParcela.id, recebimentoId : this.recebimentoSelecionado.id, tipoLancamentoId : recebimentoParcela.tipoLancamentoId, 
          datRecebimento : funcoes.mySqlToJSDateFormat(recebimentoParcela.datRecebimento), 
          datPredatado : funcoes.mySqlToJSDateFormat(recebimentoParcela.datPredatado), 
          datGerouCr : funcoes.mySqlToJSDateFormat(recebimentoParcela.datGerouCr),
          banco : recebimentoParcela.banco, agencia : recebimentoParcela.agencia, conta : recebimentoParcela.conta, cheque : recebimentoParcela.cheque, 
          portador : recebimentoParcela.portador, portadorCpfNumero : recebimentoParcela.portadorCpfNumero,
          descricao : recebimentoParcela.descricao, 
          valDevido : recebimentoParcela.valDevido, valRecebido : recebimentoParcela.valRecebido,
          operadorId : this.usuarioLogadoDados.profissionalId
        },

        this.modoParcela = 'Alteracao';
        this.numeroDeParcelas = 1;
      },
    },
    async created() {
        this.showMenu(false);
        this.totalReceber = this.recebimentoSelecionado.valTratamentos + 
                            this.recebimentoSelecionado.valDespesas +  
                            this.recebimentoSelecionado.valPacotes;

        this.recebimentoSelecionado.datAtendimento = funcoes.mySqlToJSDateFormat(this.recebimentoSelecionado.datAtendimento);
        this.recebimentoSelecionado.datRecebimento = funcoes.mySqlToJSDateFormat(this.recebimentoSelecionado.datRecebimento);

        await this.carregarGuiasRelacionadasAoRecebimento(this.usuarioLogadoDados.tenant, this.recebimentoSelecionado.id);

        this.guias.forEach(element => {
          element.valTotal = (element.valTratamentos + element.valMateriais + element.valMedicamentos + element.valTaxas) - 
                             (element.valDescontosDespesas + element.valDescontosTratamentos)
        });

        this.guiasRelacionadas = [...this.guias];

        this.guias.forEach(element => {
          this.guiasOriginais.push(element.id);
        });

        this.recebimentoOriginal = {
          datAtendimento : this.recebimentoSelecionado.datAtendimento,
          observacao : this.recebimentoSelecionado.observacao,
          notaFiscalNumero : this.recebimentoSelecionado.notaFiscalNumero,
          reciboNumero : this.recebimentoSelecionado.reciboNumero,
          valTratamentos : this.recebimentoSelecionado.valTratamentos,
          valDespesas : this.recebimentoSelecionado.valDespesas,
          valPacotes : this.recebimentoSelecionado.valPacotes
        };

        this.guias = [];

        await this.carregarRecebimentoParcelas(this.usuarioLogadoDados.tenant, this.recebimentoSelecionado.id);
        this.totalizarParcelas();

        if (this.recebimentoParcelaSelecionada.datRecebimento == null) {
          this.iniciarAdicionarRemoverGuias();
        }
    }
}
</script>

<style scoped>
    label, p {
      font-weight: 100%;
      font-size: 0.8rem;
      margin-top: 3px;
      margin-bottom: 1px;
    }
</style>>
