const state = {
    recado : {}
}

const getters = {
    getRecado(state) {
        return state.recado;
    }
}

const mutations = {
    setRecado(state, recado) {
        state.recado = {...recado};
    }
}

const actions = {
    setRecado({commit}, recado) {
        commit("setRecado", recado);
    },
    
    formLeave({commit}) {
        commit("setRecado", null);
    }
}

export default {
    namespaced : true,
    state,
    getters,
    mutations,
    actions
}