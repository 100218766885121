<template>
    <div>
        <div class="cabecalho">
            <div class="coluna1">
                <button @click="retornar" class="btn btn-sm btn-primary mr-10"><i class="fa fa-arrow-left"/></button>
            </div>
            <div class="coluna2">
                <span>Foto do Paciente</span>
            </div>
            <div class="coluna3"/>
        </div>

        <div class="container-fluid justify-content-center">
            <div class="row">
                <div class="col-lg-6">
                    <video autoplay></video>
                </div>
                <div class="col-lg-6">
                    <canvas></canvas>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <button id="capturarFoto" class="btn btn-info btn-sm" style="width: 88%" @click="capturarFotoPaciente()">Capturar</button>
                </div>
                <div v-if="fotoCapturada" class="col-lg-6">
                    <button id="capturarFoto" class="btn btn-info btn-sm" style="width: 88%" @click="downLoadFotoPaciente()">Salvar</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import global from "@/mixins/global";
import fotos from "@/utils/fotos";

export default {
    mixins : [global],
    data()  {
        return {
            fotoCapturada : false
        }
    },
    computed : {
        ...mapGetters({
          pacienteSelecionado: "pacientes/getPacienteSelecionado",
        }),
    },
    methods : {
        retornar() {
            this.$router.go(-1);
        },
        capturarFotoPaciente() {
            fotos.capturarFoto();
            this.fotoCapturada = true;
        },
        async downLoadFotoPaciente() {
            await fotos.downloadFoto(this.pacienteSelecionado.id);
            this.uploadFoto()
        },
        uploadFoto() {
            fotos.uploadToAwsS3(this.pacienteSelecionado.id)
        }
    },
    created() {
        this.showMenu(false);
    },
    mounted() {
        fotos.iniciarWebCam();
    }
}
</script>


<style scoped>

</style>