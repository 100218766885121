import errors from "@/utils/errors";

export default {
    data() {
        return {
            solicitacoesInternas : [],
        }
    },
    methods : {
        carregarSolicitacoesInternas(tenant, pacienteId, profissionalId) {
            this.loadingSolicitacoesInternas = true;
            this.loadedSolicitacoesInternas = false;

            this.$http.get(`/v1.0/solicitacoesinternas/${tenant}/Paciente/${pacienteId}/profissional/${profissionalId}`)
            .then(resp => {
                this.solicitacoesInternas = resp.data;
                this.erro = "";
                this.loadedSolicitacoesInternas = true;
            })
            .catch(error => this.erro = errors.tratarErros(error))
            .finally(() => {this.loadingSolicitacoesInternas = false});
        },
        carregarSolicitacaoInterna(tenant, id) {
            this.loadingSolicitacoesInternas = true;
            this.loadedSolicitacoesInternas = false;

            this.$http.get(`/v1.0/solicitacoesinternas/${tenant}/${id}`)
            .then(resp => {
                this.solicitacaoInternaSelecionada = resp.data;
                this.erro = "";
                this.loadedSolicitacoesInternas = true;
            })
            .catch(error => this.erro = errors.tratarErros(error))
            .finally(() => {this.loadingSolicitacoesInternas = false});
        },
    }
}
