import { render, staticRenderFns } from "./frmProntuarioPermitidos.vue?vue&type=template&id=393ec983&scoped=true&"
import script from "./frmProntuarioPermitidos.vue?vue&type=script&lang=js&"
export * from "./frmProntuarioPermitidos.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "393ec983",
  null
  
)

export default component.exports