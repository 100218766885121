import errors from "@/utils/errors";
import {saveAs} from 'file-saver';

export default {
    data() {
        return {
        }
    },
    methods: {
      carregarRelatorioPorOperadora(tenant, operadoraId, datInicial, datFinal, modelo, orientacao) {
        if (operadoraId == 0) {
          this.erro = "Selecione a Operadora";
          return;
        }

        if (datInicial == null || datFinal == null) {
          this.erro = "Informe a Data Inicial e a Data Final";
          return;
        }

        this.loadingRelatorio = true;

        this.$http.get(`/v1.0/relatoriosFaturamentos/${tenant}/operadoraPorPeriodo/${operadoraId}/${datInicial}/${datFinal}/${modelo}/${orientacao}`, {responseType: 'blob'})
          .then(response => {
            saveAs(response.data, 'RelatorioFaturamentoPorOperadora.pdf');
            this.erro = "";
          })
          .catch(error => errors.tratarErros(error))
          .finally(() => this.loadingRelatorio = false)
      },
    }
}
