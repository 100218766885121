<template>
  <div>
    <div class="cabecalho">
      <div class="coluna1">
        <button @click="retornar" class="btn btn-sm btn-primary mr-10"><i class="fa fa-arrow-left"/></button>
      </div>
      <div class="coluna2">
        <span>Tipos de Despesas</span>
      </div>
      <div class="coluna3"/>
    </div>  

    <div class="container-fluid">
      <div class="row" v-if="modo==''">
        <div class="col-12">
          <button
            class="btn btn-sm btn-info"
            @click="incluirRegistro()"
          >Incluir Novo Tipo de Despesa</button>
          <hr />
        </div>
      </div>

      <div class="registro" v-if="modo!=''">
        <div class="row">
          <div class="form-group col-lg-1">
            <label for="Id" class="control-label">Id:</label>
            <input
              type="text"
              class="form-control"
              disabled
              id="Id"
              name="Id"
              :value="despesaTipo.id"
            />
          </div>

          <div class="form-group col-lg-5">
            <label for="Descricao" class="control-label">Descrição:</label>
            <input
              type="text"
              class="form-control"
              :disabled="!liberarCampos"
              id="Descricao"
              name="Descricao"
              v-model="despesaTipo.descricao"
            />
          </div>

          <div class="form-group col-lg-2">
            <label for="CodCentroCusto" class="control-label">Centro Custo:</label>
            <input
              type="text"
              class="form-control"
              :disabled="!liberarCampos"
              id="CodCentroCusto"
              name="CodCentroCusto"
              v-model="despesaTipo.codCentroCusto"
            />
          </div>

          <div class="form-group col-lg-1">
            <label for="CalculoCoeficientes" class="control-label">Coef.:</label>
            <select
              class="form-control"
              :disabled="!liberarCampos"
              id="CalculoCoeficientes"
              name="CalculoCoeficientes"
              selected
              v-model="despesaTipo.calculoCoeficientes"
            >
              <option
                v-for="confirma in sim_nao"
                :key="confirma.opcao"
                :value="confirma.opcao"
              >{{ confirma.valor }}</option>
            </select>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-lg-1"></div>

          <div class="form-group col-lg-2">
            <label for="ControlePorLote" class="control-label">Controlar Lote:</label>
            <select
              class="form-control"
              :disabled="!liberarCampos"
              id="ControlePorLote"
              name="ControlePorLote"
              selected
              v-model="despesaTipo.controlePorLote"
            >
              <option
                v-for="confirma in sim_nao"
                :key="confirma.opcao"
                :value="confirma.opcao"
              >{{ confirma.valor }}</option>
            </select>
          </div>

          <div class="form-group col-lg-2">
            <label for="CodigoTISSDespesa" class="control-label">TISS:</label>
            <select
              class="form-control"
              :disabled="!liberarCampos"
              id="CodigoTISSDespesa"
              name="CodigoTISSDespesa"
              selected
              v-model="despesaTipo.codMatTiss"
            >
              <option
                v-for="tissCodigoDespesa in tissCodigosDespesas"
                :key="tissCodigoDespesa.opcao"
                :value="tissCodigoDespesa.opcao"
              >{{ tissCodigoDespesa.valor }}</option>
            </select>
          </div>

          <div class="form-group col-lg-2">
            <label for="DespesaCentroEntradaId" class="control-label">Centro Entrada:</label>
            <select
              class="form-control"
              :disabled="!liberarCampos"
              id="DespesaCentroEntradaId"
              name="DespesaCentroEntradaId"
              selected
              v-model="despesaTipo.despesaCentroEntradaId"
            >
              <option
                v-for="despesaCentro in despesaCentros"
                :key="despesaCentro.id"
                :value="despesaCentro.id"
              >{{ despesaCentro.descricao }}</option>
            </select>
          </div>

          <div class="form-group col-lg-2">
            <label for="DespesaCentroSaidaId" class="control-label">Centro Saída:</label>
            <select
              class="form-control"
              :disabled="!liberarCampos"
              id="DespesaCentroSaidaId"
              name="DespesaCentroSaidaId"
              selected
              v-model="despesaTipo.despesaCentroSaidaId"
            >
              <option
                v-for="despesaCentro in despesaCentros"
                :key="despesaCentro.id"
                :value="despesaCentro.id"
              >{{ despesaCentro.descricao }}</option>
            </select>
          </div>
        </div>

        <hr />
        <div class="barraopcoescadastro">
          <button
            id="Excluir"
            :disabled="modo=='Incluir'"
            @click="excluirRegistro()"
            class="btn btn-sm btn-danger mr-2"
          >Excluir</button>
          <button
            id="Alterar"
            :disabled="modo=='Incluir'"
            @click="modo='Alterar'"
            class="btn btn-sm btn-secondary mr-2"
          >Alterar</button>
          <button
            id="Cancelar"
            @click="cancelarOperacao()"
            class="btn btn-sm btn-secondary mr-2"
          >Cancelar</button>
          <button
            id="Gravar"
            :disabled="!liberarCampos"
            @click="gravarRegistro()"
            class="btn btn-sm btn-secondary mr-2"
          >Gravar</button>
        </div>
      </div>

      <div id="erro">
        <p v-if="erro" style="background-color:red; color:white; text-align:center">{{erro}}</p>
      </div>

      <div style="display:flex; flex-direction:row; justify-content:center; height:520px">
        <b-spinner v-if="loadingDespesaTipos" label="carregando..."/>
        <b-table
          v-if="loadedDespesaTipos"
          class="col-lg-6"
          hover
          striped
          small
          sort-icon-left
          :items="despesaTipos"
          :fields="camposTabela"
        >
          <template v-slot:cell(actions)="row">
            <b-button
              variant="warning"
              size="sm"
              @click="selecionouDespesaTipo(row.item)"
              class="mr-2"
            >
              <i class="fa fa-pencil" />
            </b-button>
            <b-button variant="info" size="sm" @click="selecionouDespesas(row.item)">Despesas</b-button>
          </template>
        </b-table>
      </div>
      <b-pagination v-if="loadedDespesaTipos" class="col-lg-12 justify-content-center" v-model="page" :total-rows="count" :per-page="limit"/>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import constantes from "@/utils/constantes";
import despesaTipos from "@/mixins/despesaTipos";
import despesaCentros from "@/mixins/despesaCentros";
import global from "@/mixins/global";
import errors from "@/utils/errors";

export default {
  mixins: [despesaTipos, despesaCentros, global],
  computed: {
    ...mapGetters({
      usuarioLogadoDados : "global/getUsuarioLogadoDados"
    }),
    liberarCampos() {
      return this.modo == "Incluir" || this.modo == "Alterar" ? true : false;
    }
  },
  data() {
    return {
      page : 1,
      despesaCentros: [],
      despesaTipo: {},
      loadingDespesaTipos : false,
      loadedDespesaTipos : false,
      loadingDespesaCentros: false,
      loadedDespesaCentros: false,
      erro: "",
      modo: "",
      camposTabela: [
        { key: "id", label: "Código" },
        { key: "descricao", label: "Descrição", sortable: true },
        { key: "controlePorLote", label: "Controle por Lote" },
        { key: "calculoCoeficientes", label: "Calc. Coeficiente" },
        { key: "actions", label: "Ações" }
      ],
      sim_nao : constantes.sim_nao,
      tissCodigosDespesas: constantes.tissCodigosDespesas
    }
  },
  watch : {
    page() {
      this.carregarDespesaTipos(this.usuarioLogadoDados.tenant, this.page);
    }
  },
  methods: {
    ...mapActions({
      setDespesaTipoSelecionado: "despesaTipos/setDespesaTipoSelecionado"
    }),
    retornar() {
      this.$router.go(-1);
    },
    selecionouDespesaTipo(despesaTipo) {
      this.despesaTipo = { ...despesaTipo }
      this.modo = "Registro";
    },
    incluirRegistro() {
      this.despesaTipo = {}
      this.modo = this.modo == "" ? "Incluir" : "";
    },
    cancelarOperacao() {
      this.despesaTipo = {}
      this.modo = "";
      this.erro = "";
    },
    limparCampos() {
      this.despesaTipo = {}

      if (this.modo != "Incluir") {
        this.modo = "";
      }
    },
    selecionouDespesas(despesaTipo) {
      this.setDespesaTipoSelecionado(despesaTipo);
      this.$router.push("/frmDespesas");
    },
    gravarRegistro() {
      if (!this.verificarCamposPreenchidos()) {
        return;
      }

      if (this.modo == "Alterar") {
        this.despesaTipo.operadorAlteracaoId = this.usuarioLogadoDados.profissionalId;

        this.$http
          .put(`/v1.0/despesatipos/${this.usuarioLogadoDados.tenant}/${this.despesaTipo.id}`, this.despesaTipo)
          .then(() => {
            this.erro = "";
            this.limparCampos();
            this.carregarDespesaTipos(this.usuarioLogadoDados.tenant, this.page);
            this.$toasted.global.defaultSuccess();
          })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          })
          .finally(() => {});
      } else {
        this.despesaTipo.operadorCadastroId = this.usuarioLogadoDados.profissionalId;
        this.despesaTipo.id = 0;

        this.$http
          .post(`/v1.0/despesatipos/${this.usuarioLogadoDados.tenant}`, this.despesaTipo)
          .then(() => {
            this.erro = "";
            this.limparCampos();
            this.carregarDespesaTipos(this.usuarioLogadoDados.tenant, this.page);
            this.$toasted.global.defaultSuccess();
          })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          })
          .finally(() => {});
      }
    },
    excluirRegistro() {
      this.$bvModal
        .msgBoxConfirm("Excluir Registro Selecionado?", {
          title: "Atenção",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            this.$http
              .delete(`/v1.0/despesatipos/${this.usuarioLogadoDados.tenant}/${this.despesaTipo.id}/${this.usuarioLogadoDados.profissionalId}`)
              .then(() => {
                this.erro = "";

                this.limparCampos();
                this.carregarDespesaTipos(this.usuarioLogadoDados.tenant, this.page);
                this.$toasted.global.defaultSuccess();
              })
              .catch(error => {
                this.erro = errors.tratarErros(error);
              })
              .finally(() => {});
          }
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        });
    },
    verificarCamposPreenchidos() {
      if (this.despesaTipo.descricao == "" || this.despesaTipo.descricao == undefined) {
        this.$toasted.show("Digite a Descrição", {type : 'info', icon : 'times'});
        return false;
      }

      if (this.despesaTipo.codCentroCusto == 0 || this.despesaTipo.codCentroCusto == undefined) {
        this.$toasted.show("Digite Centro Custo", {type : 'info', icon : 'times'});
        return false;
      }

      if (this.despesaTipo.calculoCoeficientes == "" || this.despesaTipo.calculoCoeficientes == undefined) {
        this.$toasted.show("Selecione Cálculo de Coeficiente", {type : 'info', icon : 'times'});
        return false;
      }

      if (this.despesaTipo.controlePorLote == "" || this.despesaTipo.controlePorLote == undefined) {
        this.$toasted.show("Selecione Controlar por Lote", {type : 'info', icon : 'times'});
        return false;
      }

      if (this.despesaTipo.codMatTiss == 0 || this.despesaTipo.codMatTiss == undefined) {
        this.$toasted.show("Selecione TISS", {type : 'info', icon : 'times'});
        return false;
      }

      if (this.despesaTipo.despesaCentroEntradaId == 0 || this.despesaTipo.despesaCentroEntradaId == undefined) {
        this.$toasted.show("Selecione Centro de Entrada", {type : 'info', icon : 'times'});
        return false;
      }

      if (this.despesaTipo.despesaCentroSaidaId == 0 || this.despesaTipo.despesaCentroSaidaId == undefined) {
        this.$toasted.show("Selecione Centro de Saída", {type : 'info', icon : 'times'});
        return false;
      }

      return true;
    }
  },
  created() {
    this.showMenu(false);
    this.carregarDespesaTipos(this.usuarioLogadoDados.tenant, this.page);
    this.carregarDespesaCentros(this.usuarioLogadoDados.tenant);
  },
  beforeRouteLeave(to, from, next) {
    if (this.modo=="Incluir" || this.modo=="Alterar") {
      this.$bvModal
        .msgBoxConfirm("Tem certeza que deseja sair sem Gravar os Dados?", {
          title: "Atenção",
          size: "sm",
          buttonSize: "sm",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            next(true);
          } else {
            next(false);
          }
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        });
    } else {
      next(true);
    }
  }
}
</script>

<style scoped>
</style>
