<template>
  <div>
    <div class="cabecalho">
      <div class="coluna1">
        <button @click="retornar" class="btn btn-sm btn-primary mr-2"><i class="fa fa-arrow-left"/></button>
      </div>
      <div class="coluna2">
        <span>{{operadoraSelecionada.fantasia}}- Consultas Diferenciadas</span>
      </div>
      <div class="coluna3" />
    </div>

    <div class="container-fluid">
      <div class="row" v-if="modo==''">
        <div class="col-12" style="margin-bottom:10px">
          <button
            class="btn btn-sm btn-info mr-1"
            @click="incluirRegistro()"
          >Adicionar Registro</button>
        </div>
      </div>

      <div class="registro" v-if="modo!=''">
        <div class="row">
          <div class="form-group col-lg-3">
            <label for="Profissionais" class="control-label">Profissionais</label>
            <select
              id="Profissionais"
              class="form-control"
              :disabled="!liberarCampos || modo!='Incluir'"
              selected
              v-model="profissionalConsultaDiferenciada.profissionalId"
            >
              <option v-for="profissional in profissionais" :key="profissional.id" :value="profissional.id">
                {{profissional.nome}}
              </option>
            </select>
          </div>

          <div class="form-group col-lg-1">
            <label for="PrazoRetorno" class="control-label">Prazo Retorno</label>
            <input type="number" id="PrazoRetorno" class="form-control" :disabled="!liberarCampos"  v-model.number="profissionalConsultaDiferenciada.prazoRetorno">
          </div>

          <div class="form-group col-lg-1">
            <label for="Valor" class="control-label">Valor</label>
            <input type="number" id="Valor" class="form-control" :disabled="!liberarCampos"  v-model.number="profissionalConsultaDiferenciada.valor">
          </div>
        </div>
        <div class="row justify-content-center">
          <button class="btn btn-sm btn-warning" style="margin-bottom:10px;"
            @click="cancelarOperacao()">Cancelar
          </button>
          <button class="btn btn-sm btn-info ml-1" style="margin-bottom:10px;"
            @click="gravar()">Confirmar
          </button>
        </div>
      </div>

      <!-- Exibição de Erros -->
      <div id="erro">
        <p v-if="erro" style="background-color:red; color:white; text-align:center">{{erro}}</p>
      </div>
      <hr style="margin:0px"/>
      <!-- Tabela de contratadosOperadoras -->
      <div style="display:flex; flex-direction:row; justify-content:center">
        <b-spinner v-if="loading" label="carregando..."/>
        <b-table v-if="loaded" class="col-lg-8" hover striped small sort-icon-left :items="profissionaisConsultasDiferenciadas" :fields="camposTabela">
          <template v-slot:cell(actions)="row">
            <b-button variant="warning" size="sm" @click="editarProfissional(row.item)" class="mr-2">
              <i class="fa fa-pencil"/> 
            </b-button>  
            <b-button variant="danger" size="sm" @click="removerProfissional(row.item)" class="mr-2">
              <i class="fa fa-minus"/> 
            </b-button>  
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import global from "@/mixins/global";
import operadoras from "@/mixins/operadoras";
import profissionais from "@/mixins/profissionais";
import errors from "@/utils/errors";

export default {
    mixins : [operadoras, profissionais, global],
    computed: {
      ...mapGetters({
        operadoraSelecionada : "operadoras/getOperadoraSelecionada",
        usuarioLogadoDados : "global/getUsuarioLogadoDados"
      }),
      liberarCampos() {
        return this.modo == 'Incluir' || this.modo == 'Alterar' ? true : false;
      }
    },
    data() {
      return {
        erro : "",
        loading : false,
        loaded : false,
        modo : "",
        profissionalConsultaDiferenciada : {},
        profissionaisConsultasDiferenciadas : [],
        camposTabela : [
          {key : "profissional", label : "Profissional", sortable : true},
          {key : "prazoRetorno", label : "Prazo Retorno"},
          {key : "valor", label : "Valor"},
          {key : "actions", label : "Ações"},
        ],
      }
    },
    methods: {
      retornar() {
        this.$router.go(-1);
      },
      cancelarOperacao() {
        this.profissionalConsultaDiferenciada = {}
        this.modo = "";
        this.erro = "";
      },
      editarProfissional(profissionalConsultaDiferenciada) {
        this.modo = "Alterar";
        this.profissionalConsultaDiferenciada = {...profissionalConsultaDiferenciada}

        if (this.profissionais.length==0) {
          this.carregarRealizaAtendimento(this.usuarioLogadoDados.tenant, "S", "T", 0);
        }
      },
      incluirRegistro() {
        this.profissionalConsultaDiferenciada = {}
        this.modo = this.modo == "" ? "Incluir" : "";

        if (this.profissionais.length==0) {
          this.carregarRealizaAtendimento(this.usuarioLogadoDados.tenant, "S", "T", 0);
        }
      },
      gravar() {
        if (!this.verificarCampos()) {
          return;
        }

        if (this.modo=="Alterar") {
          this.profissionalConsultaDiferenciada.operadorAlteracaoId = this.usuarioLogadoDados.profissionalId;
         
          this.$http.put(`/v1.0/profissionalConsultasDiferenciadas/${this.usuarioLogadoDados.tenant}/${this.profissionalConsultaDiferenciada.id}`, this.profissionalConsultaDiferenciada)
          .then(() => {
            this.erro = "";
            this.modo = "";
            this.profissionalConsultaDiferenciada = {}
            this.carregarConsultasDiferenciadas(this.usuarioLogadoDados.tenant, this.operadoraSelecionada.id);
            this.$toasted.global.defaultSuccess();
          })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          })
        } else {
          if (!this.verificarDuplicidade()) {
            return;
          }

          this.profissionalConsultaDiferenciada.operadorCadastroId = this.usuarioLogadoDados.profissionalId;
          this.profissionalConsultaDiferenciada.operadoraId = this.operadoraSelecionada.id;

          this.$http.post(`/v1.0/profissionalConsultasDiferenciadas/${this.usuarioLogadoDados.tenant}`, this.profissionalConsultaDiferenciada)
          .then(() => {
            this.erro = "";
            this.profissionalConsultaDiferenciada = {}
            this.carregarConsultasDiferenciadas(this.usuarioLogadoDados.tenant, this.operadoraSelecionada.id);
            this.$toasted.global.defaultSuccess();
          })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          })
        }
      },
      removerProfissional(profissionalConsultaDiferenciada) {
        this.$bvModal
          .msgBoxConfirm("Remover Registro Selecionado?", {
            title: "Atenção",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "Sim",
            cancelTitle: "Não",
            footerClass: "p-2",
            hideHeaderClose: true,
            centered: true
          })
          .then(value => {
            if (value) {
              this.$http
                .delete(`v1.0/profissionalConsultasDiferenciadas/${this.usuarioLogadoDados.tenant}/${profissionalConsultaDiferenciada.id}/${this.usuarioLogadoDados.profissionalId}`)
                .then(() => {
                  this.erro = "";
                  this.$toasted.global.defaultSuccess();
                  this.carregarConsultasDiferenciadas(this.usuarioLogadoDados.tenant,this.operadoraSelecionada.id);
                })
                .catch(error => {
                  this.erro = errors.tratarErros(error);
                })
                .finally(() => {});
            }
          })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          });
      },
      verificarCampos() {
        if (this.profissionalConsultaDiferenciada.profissionalId==undefined) {
          this.$toasted.show("Selecione um(a) Profissional", {type : "info", icon : "times"});
          return false;
        }

        if (this.profissionalConsultaDiferenciada.prazoRetorno==undefined) {
          this.$toasted.show("Digite o Prazo de Retorno", {type : "info", icon : "times"});
          return false;
        }

        if (this.profissionalConsultaDiferenciada.valor==undefined) {
          this.$toasted.show("Digíte o Valor R$ da Consulta", {type : "info", icon : "times"});
          return false;
        }

        return true;
      },
      verificarDuplicidade() {
        let cadastrados = this.profissionaisConsultasDiferenciadas.filter(co => co.profissionalId == this.profissionalConsultaDiferenciada.profissionalId);

        if (cadastrados.length>0) {
          this.$toasted.show("Profissional já foi Adicionada anteriormente.", {type : "info", icon : "times"});
          return false;
        }

        return true;
      }
    },
    async created() {
        this.showMenu(false);

        this.loading = true;
        this.loaded = false;
        await this.carregarConsultasDiferenciadas(this.usuarioLogadoDados.tenant, this.operadoraSelecionada.id);
        await this.carregarProfissionaisExecutantes(this.usuarioLogadoDados.tenant, 0);
        this.loading = false;
        this.loaded = true;
    }
}
</script>

<style scoped>
    label {
      font-weight: 100%;
      font-size: 0.8rem;
      margin-top: 3px;
      margin-bottom: 1px;
    }
</style>
