<template>
    <div>
        <p>Componente B</p>        
        <div class="row">
            <div style="width:33%">
                <p style="text-align:center">Coluna1</p>
            </div>
            <div style="width:33%">
                <p style="text-align:center">Coluna2</p>
            </div>
            <div style="width:33%">
                <p style="text-align:center">Coluna3</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name : "componenteB"
}
</script>

<style scoped>

</style>