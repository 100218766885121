import errors from "@/utils/errors";

export default {
    data() {
        return {
          procedimentosCobertosDaOperadora : [],
          countProcedimentoCoberto : 0,
          limitProcedimentoCoberto : 0,
        }
    },
    methods: {
        async carregarProcedimentosCobertos(tenant, planoSaudeId, campoFiltro, filtro, lancamento, page) {
          this.loading = true;
          this.loadedProcedimentosCobertos = false;

          await this.$http
            .get(`/v1.0/procedimentoscobertos/${tenant}/PlanoSaude/${planoSaudeId}/${campoFiltro}/${filtro}/${lancamento}/${page}`)
            .then(resp => {
              if (lancamento == 'S') {
                this.procedimentosCobertosDaOperadora = resp.data;
              } else {
                this.procedimentosCobertosDaOperadora = resp.data.registros;
                this.countProcedimentoCoberto = resp.data.count;
                this.limitProcedimentoCoberto = resp.data.limit;
              }
              this.erro = "";
              this.loadedProcedimentosCobertos = true;
            })
            .catch(error => {
              this.erro = errors.tratarErros(error);
            })
            .finally(() => {
              this.loading = false;
            });
        },

        async carregarProcedimentosCobertosPelaFaixaCodigos(tenant, planoSaudeId, codigoInicial, codigoFinal, page) {
          this.loading = true;
          this.loadedProcedimentosCobertos = false;

          await this.$http
            .get(`/v1.0/procedimentoscobertos/${tenant}/faixaCodigos/${planoSaudeId}/${codigoInicial}/${codigoFinal}/${page}`)
            .then(resp => {
              this.procedimentosCobertosDaOperadora = resp.data.registros;
              this.countProcedimentoCoberto = resp.data.count;
              this.limitProcedimentoCoberto = resp.data.limit;
              
              this.erro = "";
              this.loadedProcedimentosCobertos = true;
            })
            .catch(error => {
              this.erro = errors.tratarErros(error);
            })
            .finally(() => {
              this.loading = false;
            });
        },
    }
}
