import errors from  "@/utils/errors"

export default {
    data() {
        return {
            reagendamentos : []
        }
    },
    methods : {
        async carregarReagendamentosPeloProfissional(tenant, profissionalId) {
            this.loadingReagendamentos = true;
            this.loadedReagendamentos = false;

            await this.$http.get(`/v1.0/reagendar/${tenant}/profissional/${profissionalId}`)
            .then(resp => {
                this.reagendamentos = resp.data;
                this.loadedReagendamentos = true;
            })
            .catch(error => this.erro = errors.tratarErros(error))
            .finally(() => {
                this.loadingReagendamentos = false;
            })
        }
    }
}