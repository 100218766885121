<template>
    <div>
        <div class="cabecalho">
          <div class="coluna1">
            <button @click="retornar" class="btn btn-sm btn-primary mr-10"><i class="fa fa-arrow-left"/></button>
          </div>
          <div class="coluna2">
            <span>Simpro - {{registroSelecionado.descricao}}</span>
          </div>
          <div class="coluna3"/>
        </div>  

        <div class="container-fluid">
            <div class="row">
                <div class="form-group col-lg-2">
                    <label for="codigoSimpro" class="control-label">Cód Simpro</label>
                    <input type="text"  id="codigoSimpro" class="form-control" readonly :value="registroSelecionado.codigoSimpro"/>
                </div>
                <div class="form-group col-lg-5">
                    <label for="descricao" class="control-label">Descrição Comercial</label>
                    <input type="text"  id="descricao" class="form-control" readonly :value="registroSelecionado.descricao"/>
                </div>
                <div class="form-group col-lg-1">
                    <label for="referente" class="control-label">Referência</label>
                    <input type="text"  id="referente" class="form-control" readonly :value="registroSelecionado.referente"/>
                </div>
                <div class="form-group col-lg-1">
                    <label for="generico" class="control-label">Genérico</label>
                    <select id="generico" class="form-control" disabled :value="registroSelecionado.generico" selected>
                        <option v-for="resposta in sim_nao" :key="resposta.opcao" :value="resposta.opcao">
                            {{resposta.valor}}
                        </option>
                    </select>
                </div>
                <div class="form-group col-lg-1">
                    <label for="diversos" class="control-label">Diversos</label>
                    <select id="diversos" class="form-control" disabled :value="registroSelecionado.diversos" selected>
                        <option v-for="resposta in sim_nao" :key="resposta.opcao" :value="resposta.opcao">
                            {{resposta.valor}}
                        </option>
                    </select>
                </div>
            </div>

            <div class="row">
                <div class="form-group col-lg-2">
                    <label for="codigoTuss" class="control-label">Cód Tuss</label>
                    <input type="text"  id="codigoTuss" class="form-control" readonly :value="registroSelecionado.codigoTuss"/>
                </div>
                <div class="form-group col-lg-2">
                    <label for="fabrica" class="control-label">Fábrica</label>
                    <input type="text"  id="fabrica" class="form-control" readonly :value="registroSelecionado.fabrica"/>
                </div>
                <div class="form-group col-lg-1">
                    <label for="classificacao" class="control-label">Classificação</label>
                    <input type="text"  id="classificacao" class="form-control" readonly :value="registroSelecionado.classificacao"/>
                </div>
                <div class="form-group col-lg-1">
                    <label for="codigoMercado" class="control-label">Mercado</label>
                    <input type="text"  id="codigoMercado" class="form-control" readonly :value="registroSelecionado.codigoMercado"/>
                </div>

                <div class="form-group col-lg-2">
                    <label for="codigoUsuario" class="control-label">Cód Usuário</label>
                    <input type="text"  id="codigoUsuario" class="form-control" readonly :value="registroSelecionado.codigoUsuario"/>
                </div>
                <div class="form-group col-lg-1">
                    <label for="tipoLista" class="control-label">Tipo Lista</label>
                    <input type="text"  id="tipoLista" class="form-control" readonly :value="registroSelecionado.tipoLista"/>
                </div>
            </div>

            <div class="row">
                <div class="form-group col-lg-2">
                    <label for="codigoFracao" class="control-label">Cód Fração</label>
                    <input type="text"  id="codigoFracao" class="form-control" readonly :value="registroSelecionado.codigoFracao"/>
                </div>
                <div class="form-group col-lg-2">
                    <label for="codigoBarras" class="control-label">Cód Barras</label>
                    <input type="text"  id="codigoBarras" class="form-control" readonly :value="registroSelecionado.codigoBarras"/>
                </div>
                <div class="form-group col-lg-1">
                    <label for="identif" class="control-label">Identificação</label>
                    <input type="text"  id="identif" class="form-control" readonly :value="registroSelecionado.identif"/>
                </div>
                <div class="form-group col-lg-2">
                    <label for="registroAnvisaNumero" class="control-label">Registro Anvisa</label>
                    <input type="text"  id="registroAnvisaNumero" class="form-control" readonly :value="registroSelecionado.registroAnvisaNumero"/>
                </div>
                <div class="form-group col-lg-2">
                    <label for="registroAnvisaValidade" class="control-label">Validade Registro</label>
                    <input type="text"  id="registroAnvisaValidade" class="form-control" readonly :value="registroSelecionado.registroAnvisaValidade"/>
                </div>
            </div>

            <div class="row">
                <div class="form-group col-lg-2">
                    <label for="vigencia" class="control-label">Vigência</label>
                    <input type="text"  id="vigencia" class="form-control" readonly :value="registroSelecionado.vigencia"/>
                </div>
                <div class="form-group col-lg-1">
                    <label for="IPI" class="control-label">IPI</label>
                    <input type="text"  id="IPI" class="form-control" readonly :value="registroSelecionado.ipi"/>
                </div>
                <div class="form-group col-lg-1">
                    <label for="precoEmbalagemFabrica" class="control-label">Preço Mercado</label>
                    <input type="text"  id="precoEmbalagemFabrica" class="form-control" readonly :value="registroSelecionado.precoEmbalagemFabrica"/>
                </div>
                <div class="form-group col-lg-1">
                    <label for="precoEmbalagemVenda" class="control-label">Preço Venda</label>
                    <input type="text"  id="precoEmbalagemVenda" class="form-control" readonly :value="registroSelecionado.precoEmbalagemVenda"/>
                </div>
                <div class="form-group col-lg-1">
                    <label for="tipoEmbalagem" class="control-label">Unid. Embal.</label>
                    <input type="text"  id="tipoEmbalagem" class="form-control" readonly :value="registroSelecionado.tipoEmbalagem"/>
                </div>
                <div class="form-group col-lg-1">
                    <label for="qtdeEmbalagem" class="control-label">Qtde Embal.</label>
                    <input type="text"  id="qtdeEmbalagem" class="form-control" readonly :value="registroSelecionado.qtdeEmbalagem"/>
                </div>
                <div class="form-group col-lg-1">
                    <label for="unidade" class="control-label">Unid. Fração</label>
                    <input type="text"  id="unidade" class="form-control" readonly :value="registroSelecionado.tipoFracao"/>
                </div>
                <div class="form-group col-lg-1">
                    <label for="qtdeFracao" class="control-label">Qtde Fração</label>
                    <input type="text"  id="qtdeFracao" class="form-control" readonly :value="registroSelecionado.qtdeFracao"/>
                </div>
            </div>

            <div class="row">
                <div class="form-group col-lg-1">
                    <label for="porcLucro" class="control-label">% Lucro</label>
                    <input type="text"  id="porcLucro" class="form-control" readonly :value="registroSelecionado.porcLucro"/>
                </div>
                <div class="form-group col-lg-1">
                    <label for="porcDesconto" class="control-label">% Desconto</label>
                    <input type="text"  id="porcDesconto" class="form-control" readonly :value="registroSelecionado.porcDesconto"/>
                </div>
                <div class="form-group col-lg-1">
                    <label for="precoEmbalagemUsuario" class="control-label">Preço Uso</label>
                    <input type="text"  id="precoEmbalagemUsuario" class="form-control" readonly :value="registroSelecionado.precoEmbalagemUso"/>
                </div>
                <div class="form-group col-lg-1">
                    <label for="precoFracaoFabrica" class="control-label">Preço Mercado</label>
                    <input type="text"  id="precoFracaoFabrica" class="form-control" readonly :value="registroSelecionado.precoFracaoFabrica"/>
                </div>
                <div class="form-group col-lg-1">
                    <label for="precoFracaoVenda" class="control-label">Preço Máx.Cons.</label>
                    <input type="text"  id="precoFracaoVenda" class="form-control" readonly :value="registroSelecionado.precoFracaoVenda"/>
                </div>
                <div class="form-group col-lg-1">
                    <label for="precoFracaoUso" class="control-label">Preço Uso</label>
                    <input type="text"  id="precoFracaoUso" class="form-control" readonly :value="registroSelecionado.precoFracaoUso"/>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="form-group col-lg-1">
                    <label for="edicao" class="control-label">Edição</label>
                    <input type="text"  id="edicao" class="form-control" readonly :value="registroSelecionado.edicao"/>
                </div>
                <div class="form-group col-lg-2">
                    <label for="datCadastro" class="control-label">Cadastro</label>
                    <input type="text"  id="datCadastro" class="form-control" readonly :value="registroSelecionado.datCadastro"/>
                </div>
                <div class="form-group col-lg-2">
                    <label for="datAlteracao" class="control-label">Atualização</label>
                    <input type="text"  id="datAlteracao" class="form-control" readonly :value="registroSelecionado.datAlteracao"/>
                </div>
                <div class="form-group col-lg-1">
                    <label for="tipoAtualizacao" class="control-label">Tipo</label>
                    <input type="text"  id="tipoAtualizacao" class="form-control" readonly :value="registroSelecionado.tipoAtualizacao"/>
                </div>
                <div class="form-group col-lg-1">
                    <label for="operacao" class="control-label">Operação</label>
                    <input type="text"  id="operacao" class="form-control" readonly :value="registroSelecionado.operacao"/>
                </div>
                <div class="form-group col-lg-1">
                    <label for="codigoTxt" class="control-label">Cód Txt</label>
                    <input type="text"  id="codigoTxt" class="form-control" readonly :value="registroSelecionado.codigoTXT"/>
                </div>
                <div class="form-group col-lg-1">
                    <label for="numeroLinha" class="control-label">Nº Linha</label>
                    <input type="text"  id="numeroLinha" class="form-control" readonly :value="registroSelecionado.numeroLinha"/>
                </div>
            </div>
        </div>
    </div>    
</template>

<script>
import {mapGetters, mapActions} from "vuex"
import constantes from "@/utils/constantes"
import funcoes from "@/utils/funcoes"
import global from "@/mixins/global"

export default {
    mixins : [global],
    name : "cadTabelaSimpro",
    computed : {
        ...mapGetters({
            registroSelecionado : "tabelaSimpro/getRegistroSelecionado"
        })
    },
    data() {
        return {
            sim_nao : constantes.sim_nao
        }
    },
    methods : {
        ...mapActions({
            setRegistroSelecionado : "tabelaSimpro/setRegistroSelecionado"
        }),
        retornar() {
            this.setRegistroSelecionado({});
            this.$router.go(-1);
        }
    },
    created() {
        this.showMenu(false);

        this.registroSelecionado.vigencia = funcoes.mySqlToJSDateFormat(this.registroSelecionado.vigencia, "pt-br");
        this.registroSelecionado.datCadastro = funcoes.mySqlToJSDateFormat(this.registroSelecionado.datCadastro, "pt-br");
        this.registroSelecionado.datAlteracao = funcoes.mySqlToJSDateFormat(this.registroSelecionado.datAlteracao, "pt-br");
    }
}
</script>

<style scoped>
    label {
      font-weight: 100%;
      font-size: 0.8rem;
      margin-top: 3px;
      margin-bottom: 1px;
    }
</style>