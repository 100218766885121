<template>
  <div id="CadOperadoraObservacoes">
    <div class="container-fluid">
      <div class="row">
        <div class="form-group col-lg-5">
          <label for="ObservacaoGeral" class="control-label">Gerais:</label>
          <textarea
            rows="5"
            cols="100"
            class="form-control"
            :disabled="!liberarCampos"
            id="ObservacaoGeral"
            name="ObservacaoGeral"
            v-model="operadoraSelecionada.observacaoGeral"
          />
        </div>

        <div class="form-group col-lg-5">
          <label for="Observacao" class="control-label">Recepção:</label>
          <textarea
            rows="5"
            cols="100"
            class="form-control"
            :disabled="!liberarCampos"
            id="Observacao"
            name="Observacao"
            v-model="operadoraSelecionada.observacao"
          />
        </div>
      </div>

      <div class="row">
        <div class="form-group col-lg-5">
          <label for="ObservacaoFaturamento" class="control-label">Faturamento:</label>
          <textarea
            rows="5"
            cols="100"
            class="form-control"
            :disabled="!liberarCampos"
            id="ObservacaoFaturamento"
            name="ObservacaoFaturamento"
            v-model="operadoraSelecionada.observacaoFaturamento"
          />
        </div>
        <div class="form-group col-lg-5">
          <label for="ObservacaoFinanceiro" class="control-label">Financeiro:</label>
          <textarea
            rows="5"
            cols="100"
            class="form-control"
            :disabled="!liberarCampos"
            id="ObservacaoFinanceiro"
            name="ObservacaoFinanceiro"
            v-model="operadoraSelecionada.observacaoFinanceiro"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      modo: "operadoras/getModo",
      operadoraSelecionada: "operadoras/getOperadoraSelecionada",
    }),
    liberarCampos() {
      return this.modo == "Incluir" || this.modo == "Alterar" ? true : false;
    }
  }
}
</script>

<style scoped>
    label {
      font-weight: 100%;
      font-size: 0.8rem;
      margin-top: 3px;
      margin-bottom: 1px;
    }
</style>