import errors from "@/utils/errors";

export default {
    data() {
        return {
            filtrar: "",
            count : 0,
            limit : 0,
            conselhos : [],
        }
    },
    methods: {
        carregarConselhos(filtrar, pagina) {
          this.loadingConselho = true;
          this.loadedConselho = false;

          if (filtrar == "") {
            this.$http
            .get(`/v1.0/conselhos/pagina/${pagina}`)
            .then(resp => {
              this.conselhos = resp.data.registros;
              this.count = resp.data.count;
              this.limit = resp.data.limit;

              this.erro = "";
              this.loadedConselho = true;
            })
            .catch(error => (this.erro = errors.tratarErros(error)))
            .finally(() => this.loadingConselho = false)
          } else {
            this.$http
              .get(`/v1.0/conselhos/${filtrar}/${pagina}`)
              .then(resp => {
                this.conselhos = resp.data.registros;
                this.count = resp.data.count;
                this.limit = resp.data.limit;
             
                this.erro = "";
                this.loading = false;
              })
              .catch(error => (this.erro = errors.tratarErros(error)))
              .finally(() => this.loadingConselho = false)
            }
      },
      carregarConselho(conselhoId) {
        this.loadingConselho = true;
        this.loadedConselho = false;

        this.$http
          .get(`/v1.0/conselhos/${conselhoId}`)
          .then(resp => {
            this.conselho = resp.data;
            this.erro = "";

            this.loadedConselho = true;
          })
          .catch(error => (this.erro = errors.tratarErros(error)))
          .finally(() => this.loadingConselho = false)
        }
    }
}
