<template>
  <div class="formulario">
    <div class="cabecalho">
      <div class="coluna1">
        <button @click="retornar" class="btn btn-sm btn-primary mr-10"><i class="fa fa-arrow-left"/></button>
      </div>
      <div class="coluna2">
        <span>{{ operadoraSelecionada.fantasia }} - Plano: {{planoSaudeSelecionado.plano}} - Kits</span>
      </div>
      <div class="coluna3"/>
    </div>  

    <div class="tituloPagina">
      <span>{{kitSelecionado.descricao}}</span>
      <hr />
    </div>

    <div class="container-fluid">
      <div class="registro">
        <div class="row">
          <div class="form-group col-lg-1">
            <label for="Id" class="control-label">Id</label>
            <input
              type="text"
              class="form-control"
              disabled
              id="Id"
              name="Id"
              :value="kitItemSelecionado.id"
            />
          </div>

          <div class="form-group col-lg-1">
            <label for="Tipo" class="control-label">Tipo</label>
            <input
              type="text"
              class="form-control"
              disabled
              id="Tipo"
              name="Tipo"
              v-model="kitItemSelecionado.tipo"
            />
          </div>
          <div class="form-group col-lg-5">
            <label for="Descricao" class="control-label">Descrição</label>
            <input
              type="text"
              class="form-control"
              :disabled="!liberarCampos"
              id="Descricao"
              name="Descricao"
              v-model="kitItemSelecionado.descricao"
            />
          </div>
        </div>

        <div class="row">
          <div class="form-group col-lg-1"></div>

          <div class="form-group col-lg-1">
            <label for="Ativo" class="control-label">Ativo</label>
            <select
              id="Ativo"
              class="form-control"
              :disabled="!liberarCampos"
              selected
              v-model="kitItemSelecionado.ativo"
            >
              <option
                v-for="resposta in sim_nao"
                :key="resposta.opcao"
                :value="resposta.opcao"
              >{{resposta.valor}}</option>
            </select>
          </div>

          <div class="form-group col-lg-1">
            <label for="Qtde" class="control-label">Qtde</label>
            <input
              type="number"
              class="form-control"
              :disabled="!liberarCampos"
              id="Qtde"
              name="Qtde"
              v-model.number="kitItemSelecionado.qtde"
            />
          </div>

          <div class="col-3 ml-2">
            <div class="form-group">
              <label for="unidade" class="control-label">Unidade: {{kitSelecionado.unidade}}</label>
              <select
                class="form-control"
                :disabled="!liberarCampos"
                id="unidade"
                v-model="kitItemSelecionado.unidade"
                selected
              >
                <option
                  v-for="unidademedida in unidadesmedidas"
                  :key="unidademedida.id"
                  :value="unidademedida.termo"
                >{{unidademedida.descricao}} ({{unidademedida.termo}})</option>
              </select>
            </div>
          </div>

          <!-- <div class="form-group col-lg-1"> -->
            <!-- <label for="Unidade" class="control-label">Unidade</label> -->
            <!-- <input -->
              <!-- type="text" -->
              <!-- class="form-control" -->
              <!-- :disabled="!liberarCampos" -->
              <!-- id="Unidade" -->
              <!-- name="Unidade" -->
              <!-- v-model="kitItemSelecionado.unidade" -->
            <!-- /> -->
          <!-- </div> -->
          <div class="form-group col-lg-1">
            <label for="Valor" class="control-label">Valor</label>
            <input
              type="number"
              class="form-control"
              :disabled="!liberarCampos"
              id="Valor"
              name="Valor"
              v-model.number="kitItemSelecionado.valor"
            />
          </div>
          <div class="form-group col-lg-2">
            <label for="CodReferencia" class="control-label">Cod.Refer</label>
            <input
              type="text"
              class="form-control"
              :disabled="!liberarCampos"
              id="CodReferencia"
              name="CodReferencia"
              v-model.number="kitItemSelecionado.codReferencia"
            />
          </div>
        </div>

        <hr />
        <div class="barraopcoescadastro">
          <button
            id="Excluir"
            :disabled="modo=='Alterar'"
            @click="excluirRegistro()"
            class="btn btn-sm btn-danger mr-2"
          >Excluir</button>
          <button
            id="Alterar"
            :disabled="modo=='Alterar'"
            @click="alterarRegistro()"
            class="btn btn-sm btn-secondary mr-2"
          >Alterar</button>
          <button
            id="Cancelar"
            @click="cancelarOperacao()"
            class="btn btn-sm btn-secondary mr-2"
          >Cancelar</button>
          <button
            id="Gravar"
            :disabled="!liberarCampos"
            @click="gravarRegistro()"
            class="btn btn-sm btn-secondary"
          >Gravar</button>
        </div>
      </div>

      <template v-if="this.erro!=''">
        <p class="msgError">Erro durante Operação: {{ this.erro }}</p>
      </template>

    </div>
    <div class="Mensagem">
      <b-modal id="modal-mensagem" centered title="DoctorOn">
        <p class="my-4">{{mensagem}}</p>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import constantes from "@/utils/constantes";
import unidadesMedidas from "@/mixins/unidadesMedidas";
import global from "@/mixins/global";
import errors from "@/utils/errors";

export default {
  mixins : [unidadesMedidas, global],
  computed: {
    ...mapGetters({
      operadoraSelecionada: "operadoras/getOperadoraSelecionada",
      planoSaudeSelecionado: "planosSaude/getPlanoSaudeSelecionado",
      kitSelecionado: "kits/getKitSelecionado",
      kitItemSelecionado: "kitItens/getKitItemSelecionado",
      usuarioLogadoDados : "global/getUsuarioLogadoDados"
    }),
    liberarCampos() {
      return this.modo == "Alterar" ? true : false;
    }
  },
  data() {
    return {
      erro: "",
      selecionouAlterar: false,
      sim_nao: constantes.sim_nao,
      retornando: false,
      modo : "",
      mensagem : "",
    }
  },
  methods: {
    ...mapActions({
      limparRegistroSelecionado: "kitItens/limparRegistroSelecionado",
      formLeave: "kitItens/formLeave"
    }),
    retornar() {
      this.retornando = true;
      this.$router.go(-1);
    },
    gravarRegistro() {
      if (!this.verificarCamposPreenchidos()) {
        return;
      }

      this.kitItemSelecionado.operadorAlteracaoId = this.usuarioLogadoDados.profissionalId;
      this.kitItemSelecionado.procedimentoId = parseInt(this.kitItemSelecionado.procedimentoId);
      this.kitItemSelecionado.despesaId = parseInt(this.kitItemSelecionado.despesaId);

      this.$http
        .put(
          `/v1.0/kititens/${this.usuarioLogadoDados.tenant}/${this.kitItemSelecionado.id}`,
          this.kitItemSelecionado
        )
        .then(() => {
          this.erro = "";
          this.limparCampos();
          this.$toasted.global.defaultSuccess();

          this.retornar();
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        })
        .finally(() => {});
    },
    alterarRegistro() {
      if (!this.verificarPermissao('Alterar')) {
        return
      }

      this.modo = 'Alterar';
    },
    excluirRegistro() {
      if (!this.verificarPermissao('Excluir')) {
        return
      }

      this.$bvModal
        .msgBoxConfirm("Excluir Registro Selecionado?", {
          title: "Atenção",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            this.$http
              .delete(`/v1.0/kititens/${this.usuarioLogadoDados.tenant}/${this.kitItemSelecionado.id}/${this.usuarioLogadoDados.profissionalId}`)
              .then(() => {
                this.erro = "";

                this.limparCampos();
                this.$toasted.global.defaultSuccess();
                this.retornar();
              })
              .catch(error => {
                this.erro = errors.tratarErros(error);
              })
              .finally(() => {});
          }
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        });
    },
    cancelarOperacao() {
      this.limparCampos();
      this.retornar();
    },
    limparCampos() {
      this.limparRegistroSelecionado();
    },
    verificarCamposPreenchidos() {
      if (this.kitItemSelecionado.descricao == "" || this.kitItemSelecionado.descricao == undefined) {
        return false;
      }

      return true;
    },
    verificarPermissao(operacao) {
      if (!errors.verificarPermissao(this.usuarioLogadoDados, this.$store.state.global.grauPermissaoMinimo, operacao)) {
        let descricaoOperacao = operacao == "Incluir" ? "Inclusão" : operacao == "Alterar" ? "Alteração" : "Exclusão";

        this.mensagem = `${this.usuarioLogadoDados.profissional.nome}, você não possui permissão para realizar ${descricaoOperacao} com esse Perfil.`;
        this.$bvModal.show("modal-mensagem");

        return false;
      }

      return true;
    }    
  },
  created() {
    this.showMenu(false);
    this.carregarUnidadesMedidas(this.usuarioLogadoDados.tenant);
  },
  beforeRouteLeave(to, from, next) {
    if (this.retornando) {
      this.formLeave();
    }

    next(true);
  }
}
</script>

<style scoped>
  label {
    font-weight: 100%;
    font-size: 0.8rem;
    margin-top: 3px;
    margin-bottom: 1px;
  }
</style>
