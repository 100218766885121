<template>
  <div>
    <div class="cabecalho">
      <div class="coluna1">
        <button @click="retornar" class="btn btn-sm btn-primary mr-10"><i class="fa fa-arrow-left"/></button>
      </div>
      <div class="coluna2">
        <span>Centros de Armazenamento de Despesas</span>
      </div>
      <div class="coluna3"/>
    </div>  
    
    <div class="container-fluid">
      <div class="row" v-if="modo==''">
        <div class="col-12">
          <button
            class="btn btn-sm btn-info"
            @click="incluirRegistro()"
          >Incluir Novo Centro de Armazenamento</button>
          <hr />
        </div>
      </div>

      <div class="registro" v-if="modo!=''">
        <div class="row">
          <div class="form-group col-lg-1">
            <label for="Id" class="control-label">Id:</label>
            <input
              type="text"
              class="form-control"
              disabled
              id="Id"
              name="Id"
              :value="despesaCentro.id"
            />
          </div>

          <div class="form-group col-lg-5">
            <label for="Descricao" class="control-label">Descrição:</label>
            <input
              type="text"
              class="form-control"
              :disabled="!liberarCampos"
              id="Descricao"
              name="Descricao"
              v-model="despesaCentro.descricao"
            />
          </div>
        </div>

        <hr />
        <div class="barraopcoescadastro">
          <button
            id="Excluir"
            :disabled="modo=='Incluir'"
            @click="excluirRegistro()"
            class="btn btn-sm btn-danger mr-2"
          >Excluir</button>
          <button
            id="Alterar"
            :disabled="modo=='Incluir'"
            @click="modo='Alterar'"
            class="btn btn-sm btn-secondary mr-2"
          >Alterar</button>
          <button id="Cancelar" 
            @click="cancelarOperacao()" 
            class="btn btn-sm btn-secondary mr-2"
          >Cancelar</button>
          <button
            id="Gravar"
            :disabled="!liberarCampos"
            @click="gravarRegistro()"
            class="btn btn-sm btn-secondary"
          >Gravar</button>
        </div>
      </div>
    </div>

    <div id="erro">
      <p v-if="erro" style="background-color:red; color:white; text-align:center">{{erro}}</p>
    </div>

    <div style="display:flex; flex-direction:row; justify-content:center">
      <b-spinner v-if="loadingDespesaCentros" label="carregando..."/>
      <b-table v-if="loadedDespesaCentros" class="col-lg-6" hover striped small sort-icon-left :items="despesaCentros" :fields="camposTabela">
        <template v-slot:cell(actions)="row">
          <b-button variant="warning" size="sm" @click="selecionouDespesaCentro(row.item)">
            <i class="fa fa-pencil"/>
          </b-button>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import global from "@/mixins/global"
import despesaCentros from "@/mixins/despesaCentros"
import errors from "@/utils/errors"

export default {
  mixins : [despesaCentros, global],
  computed: {
    ...mapGetters({
      usuarioLogadoDados : "global/getUsuarioLogadoDados"
    }),
    liberarCampos() {
      return this.modo == "Incluir" || this.modo == "Alterar" ? true : false;
    }
  },
  data() {
    return {
      loadingDespesaCentros: false,
      loadedDespesaCentros: false,
      erro: "",
      modo: "",
      despesaCentro : {},
      camposTabela: [
        {key : "id", label : "Id"},
        {key : "descricao", label : "Descrição", sortable : true},
        {key : "actions", label : "Ações"},
      ]
    }
  },
  methods: {
    retornar() {
      this.$router.go(-1);
    },
    selecionouDespesaCentro(despesaCentro) {
      this.despesaCentro = {...despesaCentro}
      this.modo = "Registro";
    },
    incluirRegistro() {
      this.despesaCentro = {}
      this.modo = this.modo == "" ? "Incluir" : "";
    },
    cancelarOperacao() {
      this.despesaCentro = {}
      this.modo = "";
      this.erro = "";
    },
    limparCampos() {
      this.despesaCentro = {}

      if (this.modo!="Incluir") {
        this.modo = "";
      }
    },
    gravarRegistro() {
      if (!this.verificarCamposPreenchidos()) {
        return;
      }

      if (this.modo=="Alterar") {
        this.despesaCentro.operadorAlteracaoId = this.usuarioLogadoDados.profissionalId;

        this.$http
          .put(
            `/v1.0/despesacentros/${this.usuarioLogadoDados.tenant}/${this.despesaCentro.id}`,
            this.despesaCentro
          )
          .then(() => {
            this.erro = ""
            this.limparCampos();
            this.carregarDespesaCentros(this.usuarioLogadoDados.tenant);
            this.$toasted.global.defaultSuccess();
          })
          .catch((error) => {
            this.erro = errors.tratarErros(error);
          })
          .finally(() => {
          });
      } else {
        this.despesaCentro.operadorCadastroId = this.usuarioLogadoDados.profissionalId;
        this.despesaCentro.id = 0;

        this.$http
          .post(`/v1.0/despesacentros/${this.usuarioLogadoDados.tenant}`, this.despesaCentro)
          .then(() => {
            this.erro = "";
            this.limparCampos();
            this.carregarDespesaCentros(this.usuarioLogadoDados.tenant);
            this.$toasted.global.defaultSuccess();
          })
          .catch((error) => {
            this.erro = errors.tratarErros(error);
          })
          .finally(() => {
         });
      }
    },
    excluirRegistro() {
      this.$bvModal.msgBoxConfirm("Excluir Registro Selecionado?",{
          title: "Atenção",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true

      })
      .then(value => {
        if (value) {
          this.$http
            .delete(`/v1.0/despesacentros/${this.usuarioLogadoDados.tenant}/${this.despesaCentro.id}/${this.usuarioLogadoDados.profissionalId}`)
            .then(() => {
              this.erro = "";
  
              this.limparCampos();
              this.carregarDespesaCentros(this.usuarioLogadoDados.tenant);
              this.$toasted.global.defaultSuccess();
            })
            .catch((error) => {
              this.erro = errors.tratarErros(error);
            })
            .finally(() => {
  
            });
        }
      })
      .catch(error => {
        this.erro = errors.tratarErros(error);
      })
    },
    verificarCamposPreenchidos() {
      if (this.despesaCentro.descricao == "" || this.despesaCentro.descricao == undefined) {
        this.$toasted.show("Digite a Descrição", {type : 'info', icon : 'times'});
        return false;
      }

      return true;
    }
  },
  created() {
    this.showMenu(false);
    this.carregarDespesaCentros(this.usuarioLogadoDados.tenant);
  },
  beforeRouteLeave(to, from, next) {
    if (this.modo=="Incluir" || this.modo=="Alterar") {
      this.$bvModal
        .msgBoxConfirm("Tem certeza que deseja sair sem Gravar os Dados?", {
          title: "Atenção",
          size: "sm",
          buttonSize: "sm",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            next(true);
          } else {
            next(false);
          }
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        });
    } else {
      next(true);
    }
  }
}
</script>

<style scoped>
</style>
