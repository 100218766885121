const estruturaTabela = {
    id : 0, descricao : "", codTabTuss : 0
}

const state = {
    all : [],
    tabelaSelecionada : Object,
}

const getters = {
    getTabelaSelecionada(state) {
        return state.tabelaSelecionada
    },

    getAllTabelas(state) {
        return state.all
    },
}

const mutations = {
    setTabelas(state, listaTabelas) {
        state.all = listaTabelas;
    },

    setTabelaSelecionada(state, tabela) {
        state.tabelaSelecionada = tabela;
        state.statusNovoRegistro = false;
    },

    limparRegistroSelecionado(state) {
        state.tabelaSelecionada = {...estruturaTabela}
    },

    limparLista(state) {
        state.all = [];
    }
}

const actions = {
    setTabelaSelecionada({commit}, tabela) {
        commit("setTabelaSelecionada", tabela)
    },

    setTabelas({commit}, listaTabelas) {
        commit("setTabelas", listaTabelas)
    },

    limparRegistroSelecionado({commit}) {
        commit("limparRegistroSelecionado")
    },

    limparLista({commit}) {
        commit("limparLista")
    },

    formLeave({commit}) {
        commit('limparRegistroSelecionado');
        commit('limparLista');
    }
}

export default {
    namespaced : true,    
    state,
    getters,
    mutations,
    actions
}