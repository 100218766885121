<template>
  <div>
    <div class="cabecalho">
      <div class="coluna1">
        <button @click="retornar" class="btn btn-sm btn-primary mr-2"><i class="fa fa-arrow-left"/></button>
      </div>
      <div class="coluna2">
        <span>Guia de Honorários - {{guiaSelecionada.paciente}}</span>
      </div>
      <div class="coluna3" />
    </div>

    <div class="container-fluid">
      <!-- Exibição de Erros -->
      <template v-if="this.erro!=''">
        <p class="msgError">Erro durante Operação: {{ this.erro }}</p>
      </template>

      <!-- <div class="row justify-content-end"> -->
      <!-- </div> -->
      <div class="row">
        <div class="col-lg-1 flex-center">
            <label for="registroANS" class="control-label"><strong>1</strong>- Registro ANS</label><br>
            <span id="registroANS" class="control-label">{{guiaSelecionada.registroAns}}</span>
        </div>
        <div class="col-lg-2 flex-center">
            <label for="guiaPrestador" class="control-label"><strong>2</strong>- Nº Guia no Prestador</label>
            <input type="text" id="guiaPrestador" class="form-control" :disabled="!liberarCampos" v-model="guiaSelecionada.guiaPrestador"/>
        </div>
        <div class="col-lg-2 flex-center">
            <label for="guiaPrincipal" class="control-label"><strong>3</strong>- Nº Guia Solic Internação</label>
            <input type="text" id="guiaPrincipal" class="form-control" :disabled="!liberarCampos" v-model="guiaSelecionada.guiaSolicInternacao"/>
        </div>
        <div class="col-lg-2 flex-center">
            <label for="senhaAutorizacao" class="control-label"><strong>4</strong>- Senha</label><br>
            <input type="text" id="senhaAutorizacao" class="form-control" :disabled="!liberarCampos" v-model="guiaSelecionada.senhaAutorizacao"/>
        </div>
        <div class="col-lg-2 flex-center">
            <label for="guiaOperadora" class="control-label"><strong>5</strong>- Nº Guia atribuído pela Operadora</label>
            <input type="text" id="guiaOperadora" class="form-control" :disabled="!liberarCampos" v-model="guiaSelecionada.guiaOperadora"/>
        </div>
      </div>

      <!---------------------------------------- Dados do Beneficiário  -------------------------------->
      <p class="msgGrupo">Dados do Beneficiário</p>

      <div class="row">
        <div class="col-lg-2 flex-center">
            <label for="carteirinha" class="control-label"><strong>6</strong>- Nº da Carteirinha</label>
            <input type="text" id="carteirinha" class="form-control" :disabled="!liberarCampos" v-model="guiaSelecionada.carteirinha"/>
        </div>
        <div class="col-lg-3 flex-center">
            <label for="nomePaciente" class="control-label"><strong>7</strong>- Nome</label><br>
            <span id="nomePaciente" class="control-label">{{guiaSelecionada.paciente}}</span>
        </div>
        <div class="col-lg-2">
            <label for="atendimentoRN" class="control-label"><strong>8</strong>- Atendimento RN</label>
            <select id="atendimentoRN" class="form-control" :disabled="!liberarCampos" v-model="guiaSelecionada.atendimentoRn" selected>
                <option v-for="resposta in sim_nao" :key="resposta.opcao" :value="resposta.opcao">
                  {{resposta.valor}}
                </option>
            </select>
        </div>
      </div>
      <!---------------------------------------- Dados do Contratado Executante (Local) --------------------------->
      <p class="msgGrupo">Dados do Contratado Executante (onde foi executado o Procedimento)</p>

      <div class="row">
        <div class="col-lg-2 flex-center">
            <label for="COP" class="control-label"><strong>9</strong>- Código na Operadora</label><br>
            <span id="COP" class="control-label">{{contratadoOperadoraLocal.numero}}</span>
        </div>

        <div class="col-lg-3">
            <label for="contratadoLocal" class="control-label"><strong>10</strong>- Nome do Hospital/Local</label>
            <select id="contratadoLocal" class="form-control" 
              :disabled="!liberarCampos" v-model="guiaSelecionada.contratadoLocalId" selected @change="selecionouContratadoLocal()">
                <option v-for="contratado in contratados" :key="contratado.id" :value="contratado.id">
                    {{contratado.nome}}
                </option>
            </select>
        </div>

        <div class="col-lg-1 flex-center">
            <label for="CNES" class="control-label"><strong>11</strong>- Código CNES</label><br>
            <span id="CNES" class="control-label">{{contratadoLocal.cnes}}</span>
        </div>
      </div>
      <!---------------------------------------- Dados do Contratado Executante  -------------------------------->
      <p class="msgGrupo">Dados do Contratado Executante</p>

      <div class="row">
        <div class="col-lg-2 flex-center">
            <label for="COP" class="control-label"><strong>12</strong>- Código na Operadora</label><br>
            <span id="COP" class="control-label">{{contratadoOperadoraExecutante.numero}}</span>
        </div>

        <div class="col-lg-3">
            <label for="contratadoExecutante" class="control-label"><strong>13</strong>- Nome do Contratado</label>
            <select id="contratadoExecutante" class="form-control" 
              :disabled="!liberarCampos" v-model="guiaSelecionada.contratadoExecutanteId" selected @change="selecionouContratadoExecutante()">
                <option v-for="contratado in contratados" :key="contratado.id" :value="contratado.id">
                    {{contratado.nome}}
                </option>
            </select>
        </div>

        <div class="col-lg-1 flex-center">
            <label for="CNES" class="control-label"><strong>14</strong>- Código CNES</label><br>
            <span id="CNES" class="control-label">{{contratadoExecutante.cnes}}</span>
        </div>
      </div>
      <!---------------------------------------- Dados da Internação  -------------------------------->
      <p class="msgGrupo">Dados da Internação</p>

      <div class="row">
        <div class="col-lg-2 flex-center">
            <label for="datInicioFaturamento" class="control-label"><strong>15</strong>- Data Início Faturamento</label><br>
            <input type="date" id="datInicioFaturamento" class="form-control" :disabled="!liberarCampos" v-model="guiaSelecionada.datInicioFaturamento"/>
        </div>
        <div class="col-lg-2 flex-center">
            <label for="datFimFaturamento" class="control-label"><strong>16</strong>- Data Fim Faturamento</label><br>
            <input type="date" id="datFimFaturamento" class="form-control" :disabled="!liberarCampos" v-model="guiaSelecionada.datFimFaturamento"/>
        </div>
      </div>

      <!-- ************************ Incluir aqui os procedimentos e Participações -->

      <div class="row">
        <div class="col-lg-12">
            <label for="observacao" class="control-label"><strong>37</strong>- Observação / Justificativa</label>
            <textarea id="observacao" class="form-control" rows="2" cols="180" :disabled="!liberarCampos" v-model="guiaSelecionada.tissObservacao"/>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-2 flex-center">
            <label for="datEmissao" class="control-label"><strong>16</strong>- Data Emissão</label><br>
            <input type="date" id="datEmissao" class="form-control" :disabled="!liberarCampos" v-model="guiaSelecionada.datEmissao"/>
        </div>
      </div>
      <!---------------------------------------- Botões Cancelar / Confirmar  -------------------------------->
      <br>
      <template v-if="liberarCampos">
          <div class="row justify-content-center">
            <button class="btn btn-sm btn-danger" style="margin-bottom:10px;"
              @click="cancelarOperacao()">Cancelar
            </button>
            <button class="btn btn-sm btn-info ml-1" style="margin-bottom:10px;"
              @click="gravarGuiaFaturamento()">Confirmar
            </button>
          </div>
      </template>
      <template v-else>
          <button class="btn btn-sm btn-info ml-1" style="margin-bottom:10px;"
            @click="cancelarOperacao()">Fechar
          </button>
      </template>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import profissionais from "@/mixins/profissionais"
import contratados from "@/mixins/contratados"
import contratadoOperadoras from "@/mixins/contratadoOperadoras"
import guias from "@/mixins/guias";

import global from "@/mixins/global";
import constantes from "@/utils/constantes";
import tiss from "@/utils/tiss";

export default {
    mixins : [profissionais, contratados, contratadoOperadoras, guias, global],
    computed: {
        ...mapGetters({
            guiaSelecionada : "guias/getGuiaSelecionada",
            loteSelecionado : "lotes/getLoteSelecionado",
            usuarioLogadoDados : "global/getUsuarioLogadoDados",
        }),
        liberarCampos() {
            return this.loteSelecionado.datFechamento == null;
        }
    },
    data() {
        return {
            erro : "",
            sim_nao : constantes.sim_nao,
            indicadorAcidente : tiss.indicadorAcidente,
            caraterAtendimento : tiss.caraterAtendimento,
            codAtendimento : tiss.codAtendimento,
            contratadoOperadoraSolicitante : {},
            contratadoOperadoraExecutante : {},
            contratadoOperadoraLocal : {},
            contratadoSolicitante : {},
            contratadoExecutante : {},
            contratadoLocal : {},
            profissionalExecutante : {},
            profissionalSolicitante : {},
        }
    },
    methods: {
        ...mapActions({
            adicionarAlteracoes : "faturamentos/adicionarAlteracoes",
            setUltimaGuiaSalva : "guias/setUltimaGuiaSalva",
        }),
        retornar() {
            this.$router.go(-1);
        },
        cancelarOperacao() {
            this.retornar();
        },
        selecionouContratadoSolicitante() {
            this.contratadoSolicitante = this.contratados.filter(c => c.id == this.guiaSelecionada.contratadoSolicitanteId)[0];
            this.carregarContratadoOperadora(this.usuarioLogadoDados.tenant, 'S', this.contratadoSolicitante.id, this.guiaSelecionada.operadoraId);
        },
        selecionouContratadoExecutante() {
            this.contratadoExecutante = this.contratados.filter(c => c.id == this.guiaSelecionada.contratadoExecutanteId)[0];
            this.carregarContratadoOperadora(this.usuarioLogadoDados.tenant, 'E',this.contratadoExecutante.id, this.guiaSelecionada.operadoraId);
        },
        selecionouContratadoLocal() {
            this.contratadoLocal = this.contratados.filter(c => c.id == this.guiaSelecionada.contratadoLocalId)[0];
            this.carregarContratadoOperadora(this.usuarioLogadoDados.tenant, 'L',this.contratadoLocal.id, this.guiaSelecionada.operadoraId);
        },
        selecionouProfissionalExecutante() {
            this.profissionalExecutante = this.profissionais.filter(p => p.id == this.guiaSelecionada.profissionalExecutanteId)[0];
        },
        selecionouProfissionalSolicitante() {
            this.profissionalSolicitante = this.profissionais.filter(p => p.id == this.guiaSelecionada.profissionalSolicitanteId)[0];
        },
        gravar() {
            this.guiaSelecionada.operadorAlteracaoId = this.usuarioLogadoDados.profissionalId;
            this.gravarGuiaFaturamento();
        },
        verificarCampos() {
            if (this.guiaSelecionada.guiaPrestador==null) {
                this.$toasted.show("Informe o Número da Guia no Prestador", {type : "info", icon: "times"});
                return false;
            }
            
            if (this.guiaSelecionada.guiaSolicInternacao==null) {
                this.$toasted.show("Informe o Número da Guia de Solicitação de Internação", {type : "info", icon: "times"});
                return false;
            }

            if (this.guiaSelecionada.guiaOperadora==null) {
                this.$toasted.show("Informe o Número da Guia na Operadora", {type : "info", icon: "times"});
                return false;
            }

            if (this.guiaSelecionada.carteirinha==null) {
                this.$toasted.show("Informe o Número da Carteirinha", {type : "info", icon: "times"});
                return false;
            }

            if (this.guiaSelecionada.atendimentoRn==null) {
                this.$toasted.show("Informe Atendimento RN", {type : "info", icon: "times"});
                return false;
            }

            if (this.guiaSelecionada.contratadoLocalId==null) {
                this.$toasted.show("Informe o Hospital/Local", {type : "info", icon: "times"});
                return false;
            }

            if (this.guiaSelecionada.contratadoExecutanteId==null) {
                this.$toasted.show("Informe o Contratado Executante", {type : "info", icon: "times"});
                return false;
            }

            if (this.guiaSelecionada.datInicioFaturamento==null) {
                this.$toasted.show("Informe Data Início Faturamento", {type : "info", icon: "times"});
                return false;
            }

            if (this.guiaSelecionada.datFimFaturamento==null) {
                this.$toasted.show("Informe Data Fim Faturamento", {type : "info", icon: "times"});
                return false;
            }

            if (this.guiaSelecionada.datEmissao==null) {
                this.$toasted.show("Informe Data Emissão", {type : "info", icon: "times"});
                return false;
            }

            return true;
        }
    },
    async created() {
        this.showMenu(false);
        await this.carregarProfissionaisExecutantes(this.usuarioLogadoDados.tenant, 0);

        this.profissionaisExecutantes = [...this.profissionais];
        this.profissionais = [];

        await this.carregarProfissionaisSolicitantes(this.usuarioLogadoDados.tenant, 0);

        this.profissionaisSolicitantes = [...this.profissionais];
        this.profissionais = [];

        this.carregarContratados(this.usuarioLogadoDados.tenant);
    },
    beforeRouteLeave (to, from, next) {
        next(true);
    }
}
</script>

<style scoped>
    .msgGrupo {
      background-color:green;
      color: black;
      text-align: left;
      padding-left: 5px;
      margin-top: 3px;
      margin-bottom: 3px;
    }

    label {
      font-weight: 100%;
      font-size: 0.8rem;
      margin-top: 3px;
      margin-bottom: 1px;
    }
</style>