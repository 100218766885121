<template>
  <div id="CadOperadoraDados">
    <div class="container-fluid">
      <div class="row">
        <div class="form-group col-lg-2">
          <label for="bloquearProntuariosApos" class="control-label">Bloq. Pront. Após (dias):</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="bloquearProntuariosApos"
            name="bloquearProntuariosApos"
            v-model="unidadeSelecionada.bloquearProntuariosApos"
          />
        </div>

        <div class="form-group col-lg-2">
          <label for="loginSelecionarSala" class="control-label">Informar Sala no Login:</label>
          <select
            id="loginSelecionarSala"
            class="form-control"
            :disabled="!liberarCampos"
            selected
            v-model="unidadeSelecionada.loginSelecionarSala"
          >
            <option
              v-for="resposta in sim_nao"
              :key="resposta.opcao"
              :value="resposta.opcao"
            >{{resposta.valor}}</option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import constantes from "@/utils/constantes";

export default {
  computed: {
    ...mapGetters({
      unidadeSelecionada: "unidades/getUnidadeSelecionada",
      modo : "unidades/getModo",
    }),
    liberarCampos() {
      return this.modo == "Incluir" || this.modo == "Alterar" ? true : false;
    }
  },
  data() {
    return {
      sim_nao: constantes.sim_nao,
      ufs: constantes.uf
    }
  },
  methods: {}
}
</script>

<style scoped>
    label {
      font-weight: 100%;
      font-size: 0.8rem;
      margin-top: 3px;
      margin-bottom: 1px;
    }
</style>