import errors from "@/utils/errors";

export default {
    data() {
        return {
            limit : 0,
            count : 0,
            feriados : []
        }
    },
    methods : {
        carregarFeriados(tenant, pagina) {
            this.$http
              .get(`/v1.0/feriados/${tenant}/pagina/${pagina}`)
              .then(resp => {
                this.feriados = resp.data.registros;
                this.count = resp.data.count;
                this.limit = resp.data.limit;

                this.erro = "";
                this.loading = false;
              })
              .catch(error => (this.erro = errors.tratarErros(error)))
          },
    }
}
