<template>
  <div>
    <div class="cabecalho">
      <div class="coluna1">
        <button @click="retornar" class="btn btn-sm btn-primary mr-10"><i class="fa fa-arrow-left"/></button>
      </div>
      <div class="coluna2">
        <span>Empresas Cadastradas</span>
      </div>
      <div class="coluna3"/>
    </div>    

    <div class="container-fluid">
      <div class="row" v-if="modo==''">
        <div class="col-12">
          <button
            class="btn btn-sm btn-info"
            @click="incluirRegistro()"
          >Incluir Nova Empresa</button>
          <hr />
        </div>
      </div>

      <div class="registro" v-if="modo!=''">
        <div class="row">
          <div class="form-group col-lg-1">
            <label for="Id" class="control-label">Id:</label>
            <input type="text" class="form-control" disabled id="Id" name="Id" :value="empresa.id" />
          </div>

          <div class="form-group col-lg-10">
            <label for="Empresa" class="control-label">Empresa:</label>
            <input
              type="text"
              class="form-control"
              :disabled="!liberarCampos"
              id="Empresa"
              name="Empresa"
              v-model="empresa.descricao"
              aria-describedby="empresaHelp"
            />
            <small id="empresaHelp" class="form-text text-muted">Nome completo da Empresa.</small>
          </div>
        </div>

        <hr />
        <div class="barraopcoescadastro">
          <button
            id="Excluir"
            :disabled="modo=='Incluir'"
            @click="excluirRegistro()"
            class="btn btn-sm btn-danger ml-2"
          >Excluir</button>
          <button
            id="Alterar"
            :disabled="modo=='Incluir' || modo=='Alterar'"
            @click="alterarRegistro()"
            class="btn btn-sm btn-secondary ml-2"
          >Alterar</button>
          <button
            id="Cancelar"
            @click="cancelarOperacao()"
            class="btn btn-sm btn-secondary ml-2"
          >Cancelar</button>
          <button
            id="Gravar"
            :disabled="!liberarCampos"
            @click="gravarRegistro()"
            class="btn btn-sm btn-secondary ml-2"
          >Gravar</button>
        </div>
      </div>

      <div id="filtrarRegistros" class="row">
        <div class="form-group col-sm-2 col-md-3 col-lg-4">
          <label for="campoFiltro" class="control-label">Buscar pela Descrição da Empresa:</label>
          <input
            type="text"
            class="form-control"
            id="campoFiltro"
            @input="filtrar=$event.target.value"
            placeholder="digite o que deseja localizar..."
          />
        </div>
        <div class="form-group col-sm-1 col-md-1 col-lg-1" style="padding-top:32px">
          <button id="btnFiltrar" class="form-control" @click="IniciarCarregarEmpresas()">
            <i class="fa fa-search"/> 
          </button>
        </div>
      </div>
    </div>

    <div id="erro">
      <p v-if="erro" style="background-color:red; color:white; text-align:center">{{erro}}</p>
    </div>

    <hr />
    
    <div style="display:flex; flex-direction:row; justify-content:center;height:450px">
      <b-spinner v-if="loading" label="carregando..."/>
      <b-table v-if="loaded" class="col-lg-6" hover striped small sort-icon-left :items="empresas" :fields="camposTabela">
        <template v-slot:cell(actions)="row">
          <b-button variant="warning" size="sm" @click="selecionouRegistro(row.item)" class="mr-2">
            <i class="fa fa-pencil"/> 
          </b-button>  
        </template>
      </b-table>
    </div>
    <b-pagination v-if="loaded" class="col-lg-12 justify-content-center" size="md" v-model="page" :total-rows="count" :per-page="limit"/>

    <div class="Mensagem">
      <b-modal id="modal-mensagem" centered title="DoctorOn">
        <p class="my-4">{{mensagem}}</p>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import empresas from "@/mixins/empresas";
import global from "@/mixins/global";
import errors from "@/utils/errors";

export default {
  mixins: [empresas, global],
  computed: {
    ...mapGetters({
      usuarioLogadoDados : "global/getUsuarioLogadoDados",
    }),
    liberarCampos() {
      return this.modo == 'Incluir' || this.modo == 'Alterar' ? true : false;
    }
  },
  data() {
    return {
      page : 1,
      empresa: {},
      camposTabela: [
        { key: 'id', label: 'Código', sortable: false },
        { key: 'descricao', label: 'Empresa', sortable: true },
        { key: 'actions', label: 'Ações' }
      ],
      erro: "",
      modo: "",
      loading: false,
      loaded: false,
      menuItem: {},
      mensagem: "",
    }
  },
  watch : {
    page() {
      this.IniciarCarregarEmpresas();
    }
  },
  methods: {
    retornar() {
      this.$router.go(-1);
    },
    IniciarCarregarEmpresas() {
      this.loading = true;
      this.loaded = false;
      this.carregarEmpresas(this.usuarioLogadoDados.tenant, this.page);
    },
    incluirRegistro() {
      if (!this.verificarPermissao('Incluir')) {
        return
      }

      this.empresa = {}
      this.modo = this.modo == "" ? "Incluir" : "";
    },
    alterarRegistro() {
      if (!this.verificarPermissao('Alterar')) {
        return
      }

      this.modo = 'Alterar';
    },
    selecionouRegistro(empresa) {
      this.empresa = { ...empresa }
      this.modo = 'Registro';
    },
    gravarRegistro() {
      if (!this.verificarCamposPreenchidos()) {
        return;
      }

      if (this.modo=="Alterar") {
        this.empresa.operadorAlteracaoId = this.usuarioLogadoDados.profissionalId;
        
        this.$http
          .put(`/v1.0/empresas/${this.usuarioLogadoDados.tenant}/${this.empresa.id}`, this.empresa)
          .then(() => {
            this.erro = "";
            this.limparCampos();
            this.IniciarCarregarEmpresas(this.page);
            this.$toasted.global.defaultSuccess();
        })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          })
          .finally(() => {});
      } else {
        this.empresa.operadorCadastroId = this.usuarioLogadoDados.profissionalId;
        this.empresa.id = 0;
        this.$http
          .post(`/v1.0/empresas/${this.usuarioLogadoDados.tenant}`, this.empresa)
          .then(() => {
            this.erro = "";
            this.limparCampos();
            this.IniciarCarregarEmpresas(this.page);
            this.$toasted.global.defaultSuccess();
          })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          })
          .finally(() => {});
      }
    },
    excluirRegistro() {
      if (!this.verificarPermissao('Excluir')) {
        return
      }

      //if (!errors.verificarPermissao(this.usuarioLogadoDados, this.$store.state.global.grauPermissaoMinimo, 'Exclusao')) {
      //  this.mensagem = `${this.usuarioLogadoDados.profissional.nome}, Você não possui permissão para realizar essa operação.`;
      //  this.$bvModal.show("modal-mensagem");
//
      //  return;
      //}

      this.$bvModal
        .msgBoxConfirm("Excluir Registro Selecionado?", {
          title: "Atenção",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            this.$http
              .delete(`v1.0/empresas/${this.usuarioLogadoDados.tenant}/${this.empresa.id}/${this.usuarioLogadoDados.profissionalId}`)
              .then(() => {
                this.erro = "";

                this.limparCampos();
                this.IniciarCarregarEmpresas(this.page);
                this.$toasted.global.defaultSuccess();
              })
              .catch(error => {
                this.erro = errors.tratarErros(error);
              })
              .finally(() => {});
          }
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        });
    },
    cancelarOperacao() {
      this.empresa = {}
      this.modo = "";
      this.erro = "";
    },
    verificarCamposPreenchidos() {
      if (this.empresa.descricao === "" || this.empresa.descricao === undefined) {
        this.$toasted.show("Digite o Nome da Empresa", {type : 'info', icon : 'times'});

        return false;
      } else {
        return true;
      }
    },
    limparCampos() {
      this.empresa = {}

      if (this.modo!='Incluir') {
        this.modo = "";
      }
    },
    verificarPermissao(operacao) {
      if (!errors.verificarPermissao(this.usuarioLogadoDados, this.$store.state.global.grauPermissaoMinimo, operacao)) {
        let descricaoOperacao = operacao == "Incluir" ? "Inclusão" : operacao == "Alterar" ? "Alteração" : "Exclusão";

        this.mensagem = `${this.usuarioLogadoDados.profissional.nome}, você não possui permissão para realizar ${descricaoOperacao} com esse Perfil.`;
        this.$bvModal.show("modal-mensagem");

        return false;
      }

      return true;
    }
  },
  created() {
    this.showMenu(false);
  },
  beforeRouteLeave(to, from, next) {
    if (this.modo=="Incluir" || this.modo=="Alterar") {
      this.$bvModal
        .msgBoxConfirm("Tem certeza que deseja sair sem Gravar os Dados?", {
          title: "Atenção",
          size: "sm",
          buttonSize: "sm",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            next(true);
          } else {
            next(false);
          }
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        });
    } else {
      next(true);
    }
  }
}
</script>

<style scoped>
</style>
