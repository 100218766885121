<template>
    <div style="max-height:200px;overflow:auto">
      <p>Componente A</p>  
      <div style="display:flex; flex-direction:row; justify-content:center;">
        <b-spinner v-if="loading" label="carregando..."/>
        <b-table
          v-if="!loading"
          class="col-lg-6"
          hover
          striped
          small
          sort-icon-left
          :items="profissoes"
          :fields="camposTabela"
        >
          <!-- <template v-slot:cell(actions)="row"> -->
            <!-- <b-button variant="warning" size="sm" class="mr-2"> -->
              <!-- <i class="fa fa-pencil" /> -->
            <!-- </b-button> -->
          <!-- </template> -->
        </b-table>
      </div>
    </div>
</template>

<script>
import profissoes from "@/mixins/profissoes";

export default {
    name : "componenteA",
    mixins : [profissoes],
    data() {
        return {
          loading : true,
          camposTabela: [
            { key: "id", label: "Código" },
            { key: "descricao", label: "Profissão", sortable: true },
            { key: "actions", label: "Ações" }
          ]
        }
    },
    methods : {

    },
    created() {
        this.carregarProfissoes(0);
    }
}
</script>

<style scoped>

</style>