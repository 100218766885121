import errors from "@/utils/errors";

export default {
    data() {
        return {
            registrosSimpro : []
        }
    },
    methods : {
        async carregarDespesasSimpro(tenant, despesaId) {
            this.loadingDespesasSimpro = true;
            this.loadedDespesasSimpro = false;

            await this.$http.get(`/v1.0/despesasSimpro/${tenant}/Despesa/${despesaId}`)
            .then(resp => {
                this.registrosSimpro = resp.data;
                this.error = "";

                this.loadingDespesasSimpro = false;
                this.loadedDespesasSimpro = true;
            })
            .catch(error => {
                this.error = errors.tratarErros(error);
                this.loadingDespesasSimpro = false;
            })
        }
    }
}
