import errors from "@/utils/errors";

export default {
    data() {
        return {
            contas : [],
        }
    },
    methods : {
        carregarContasByFluxo(tenant, fluxoId, excetoConta) {
            this.$http.get(`/v1.0/contas/${tenant}/Fluxo/${fluxoId}`)
            .then(resp => {
                if (excetoConta == 0) {
                    this.contas = resp.data;
                } else {
                    this.contas = resp.data.filter(conta => conta.id != excetoConta)
                }
                this.erro = "",
                this.loading = false;

            })
            .catch(error => this.erro = errors.tratarErros(error))
        }
    }
}
