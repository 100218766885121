<template>
  <div>
    <div class="cabecalho">
      <div class="coluna1">
        <button @click="retornar" class="btn btn-sm btn-primary mr-10"><i class="fa fa-arrow-left"/></button>
      </div>
      <div class="coluna2">
        <span>CIDs cadastrados</span>
      </div>
      <div class="coluna3"/>
    </div>  

    <div class="container-fluid">
      <div class="row" v-if="modo==''">
        <div class="col-12">
          <button
            class="btn btn-sm btn-info"
            @click="incluirRegistro()"
          >Incluir Novo CID</button>
          <hr />
        </div>
      </div>

      <div class="registro" v-if="modo!=''">
        <div class="row">
          <div class="form-group col-lg-1">
            <label for="Id" class="control-label">Id:</label>
            <input type="text" class="form-control" disabled id="Id" name="Id" :value="cid.id" />
          </div>

          <div class="form-group col-lg-2">
            <label for="SubCategora" class="control-label">Sub-Categoria:</label>
            <input
              type="text"
              class="form-control"
              :disabled="!liberarCampos"
              id="SubCategora"
              name="SubCategora"
              v-model="cid.subCategoria"
            />
          </div>

          <div class="form-group col-lg-1">
            <label for="Classificacao" class="control-label">Classificação:</label>
            <input
              type="text"
              class="form-control"
              :disabled="!liberarCampos"
              id="Classificacao"
              name="Classificacao"
              v-model="cid.classificacao"
            />
          </div>

          <div class="form-group col-lg-2">
            <label for="RestritoSexo" class="control-label">Restrito Sexo:</label>
            <select
              class="form-control"
              :disabled="!liberarCampos"
              id="RestritoSexo"
              name="RestritoSexo"
              v-model="cid.restritoSexo"
            >
              <option
                v-for="resposta in sim_nao"
                :key="resposta.opcao"
                :value="resposta.opcao"
              >{{resposta.valor}}</option>
            </select>
          </div>

          <div class="form-group col-lg-2">
            <label for="CausaObito" class="control-label">Causa Óbito:</label>
            <select
              class="form-control"
              id="CausaObito"
              name="CausaObito"
              :disabled="!liberarCampos"
              v-model="cid.causaObito"
              selected
            >
              <option
                v-for="resposta in sim_nao"
                :key="resposta.opcao"
                :value="resposta.opcao"
              >{{resposta.valor}}</option>
            </select>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-lg-1"></div>

          <div class="form-group col-lg-2">
            <label for="Excluido" class="control-label">Excluído:</label>
            <input
              type="text"
              class="form-control"
              :disabled="!liberarCampos"
              id="Excluido"
              name="Excluido"
              v-model="cid.excluidos"
            />
          </div>

          <div class="form-group col-lg-2">
            <label for="Refer" class="control-label">Referência:</label>
            <input
              type="text"
              class="form-control"
              :disabled="!liberarCampos"
              id="Refer"
              name="Refer"
              v-model="cid.refer"
            />
          </div>
        </div>

        <div class="row">
          <div class="form-group col-lg-1"></div>

          <div class="form-group col-lg-10">
            <label for="Descricao" class="control-label">Descrição:</label>
            <input
              type="text"
              class="form-control"
              :disabled="!liberarCampos"
              id="Descricao"
              name="Descricao"
              v-model="cid.descricao"
            />
          </div>
        </div>

        <div class="row">
          <div class="form-group col-lg-1"></div>

          <div class="form-group col-lg-10">
            <label for="DescricaoAbreviada" class="control-label">Descrição Abreviada:</label>
            <input
              type="text"
              class="form-control"
              :disabled="!liberarCampos"
              id="DescricaoAbreviada"
              name="DescricaoAbreviada"
              v-model="cid.descricaoAbreviada"
            />
          </div>
        </div>

        <hr />
        <div class="barraopcoescadastro">
          <button
            id="Alterar"
            :disabled="modo=='Incluir'"
            @click="alterarRegistro()"
            class="btn btn-sm btn-secondary mr-2"
          >Alterar</button>
          <button
            id="Cancelar"
            @click="cancelarOperacao()"
            class="btn btn-sm btn-secondary mr-2"
          >Cancelar</button>
          <button
            id="Gravar"
            :disabled="!liberarCampos"
            @click="gravarRegistro()"
            class="btn btn-sm btn-secondary"
          >Gravar</button>
        </div>
      </div>

      <div class="container-fluid">
        <div id="carregarCids" class="row">
          <div class="form-group ml-4 col-lg-4">
            <label for="campoFiltro" class="control-label">Buscar pela Descrição:</label>
            <input
              type="text"
              class="form-control"
              id="campoFiltro"
              @input="filtrar=$event.target.value"
              placeholder="digite o que deseja localizar..."
            />
          </div>
          <div class="form-group col-sm-1 col-md-1 col-lg-1" style="padding-top:32px">
            <button id="btnFiltrar" class="form-control" @click="carregarCids">
              <i class="fa fa-search"/>
            </button>
          </div>
        </div>

        <div id="erro">
          <p v-if="erro" style="background-color:red; color:white; text-align:center">{{erro}}</p>
        </div>

        <hr />
        <div style="display:flex; flex-direction:row; justify-content:center;max-height:500px;overflow:auto">
          <b-spinner v-if="loading" label="carregando..."/>
          <b-table
            v-if="loaded"
            hover
            striped
            small
            sort-icon-left
            :items="cids"
            :fields="camposTabela"
          >
            <template v-slot:cell(actions)="row">
              <b-button variant="warning" size="sm" @click="selecionouCid(row.item)">
                <i class="fa fa-pencil" />
              </b-button>
            </template>
          </b-table>
        </div>
        <b-pagination v-if="loaded" class="col-lg-12 justify-content-center" size="md" v-model="page" :total-rows="count" :per-page="limit"/>
      </div>
    </div>
    <div class="Mensagem">
      <b-modal id="modal-mensagem" centered title="DoctorOn">
        <p class="my-4">{{mensagem}}</p>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import global from "@/mixins/global";
import constantes from "@/utils/constantes";
import errors from "@/utils/errors";

export default {
  mixins: [global],
  computed: {
    ...mapGetters({
      usuarioLogadoDados : "global/getUsuarioLogadoDados"
    }),
    liberarCampos() {
      return this.modo == "Incluir" || this.modo == "Alterar" ? true : false;
    },
  },
  data() {
    return {
      page : 1,
      count : 0,
      limit : 0,
      loading: false,
      loaded: false,
      erro: "",
      modo: "",
      mensagem : "",
      cid: {},
      cids: [],
      filtrar: "",
      camposTabela: [
        { key: "subCategoria", label: "subCategoria", sortable: true },
        { key: "classificacao", label: "Classificação", sortable: true },
        { key: "restritoSexo", label: "Restrito Sexo" },
        { key: "causaObito", label: "Causa Óbito" },
        { key: "descricao", label: "Descrição", sortable: true },
        {
          key: "descricaoAbreviada",
          label: "Descrição Abreviada",
          sortable: true
        },
        { key: "excluidos", label: "Excluídos" },
        { key: "Refer", label: "Refer" },
        { key: "actions", label: "Ações" }
      ],
      sim_nao : constantes.sim_nao
    }
  },
  watch : {
    page() {
      this.carregarCids();
    }
  },
  methods: {
    retornar() {
      this.$router.go(-1);
    },
    selecionouCid(cid) {
      this.cid = { ...cid }
      this.modo = "Registro";
    },
    incluirRegistro() {
      if (this.modo=="" && !this.verificarPermissao('Incluir')) {
        return
      }

      this.cid = {}
      this.modo = this.modo=="" ? "Incluir" : "";
    },
    alterarRegistro() {
      if (!this.verificarPermissao('Alterar')) {
        return
      }

      this.modo = 'Alterar';
    },
    carregarCids() {
      if (this.filtrar == "") {
        this.$toasted.show("Favor informar o que deseja localizar", {type: "info", icon : "times"});
      } else {
        this.loading = true;
        this.loaded = false;

        this.$http
          .get(`/v1.0/cids/descricao/${this.filtrar}/${this.page}`)
          .then(resp => {
            this.cids = resp.data.registros;
            this.count = resp.data.count;
            this.limit = resp.data.limit;
            this.erro = "";
          })
          .catch(error => this.erro = errors.tratarErros(error))
          .finally(() => {
            this.loading = false;
            this.loaded = true;
          });
      }
    },
    gravarRegistro() {
      if (!this.verificarCamposPreenchidos()) {
        return;
      }

      if (this.modo == "Alterar") {
        this.cid.operadorAlteracaoId = this.usuarioLogadoDados.profissionalId;

        this.$http
          .put(`/v1.0/cids/${this.cid.id}`, this.cid)
          .then(() => {
            this.erro = "";
            this.limparCampos();
            if (this.cids.length>0) {
              this.carregarCids();
            }
            this.$toasted.global.defaultSuccess();
          })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          })
          .finally(() => {});
      } else {
        this.cid.operadorCadastroId = this.usuarioLogadoDados.profissionalId;
        this.cid.id = 0;
        
        this.$http
          .post(`/v1.0/cids`, this.cid)
          .then(() => {
            this.erro = "";
            this.limparCampos();
            this.$toasted.global.defaultSuccess();
          })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          })
          .finally(() => {});
      }
    },
    cancelarOperacao() {
      this.cid = {}
      this.modo = "";
      this.erro = "";
    },
    limparCampos() {
      this.cid = {}

      if (this.modo != "Incluir") {
        this.modo = "";
      }
    },
    verificarCamposPreenchidos() {
      if (this.cid.descricao == "" || this.cid.descricao == undefined) {
        this.$toasted.show("Digite a Descrição", {
          type: "info",
          icon: "times"
        });
        return false;
      } else if (this.cid.subCategoria == "" || this.cid.subCategoria == undefined) {
        this.$toasted.show("Digite a SubCategoria", {
          type: "info",
          icon: "times"
        });
        return false;
      } else if (this.cid.restritoSexo == "" || this.cid.restritoSexo == undefined) {
        this.$toasted.show("Selecione Restrito Sexo", {
          type: "info",
          icon: "times"
        });
        return false;
      } else if (this.cid.causaObito == "" || this.cid.causaObito == undefined) {
        this.$toasted.show("Selecione Causa Óbito", {
          type: "info",
          icon: "times"
        });
        return false;
      } else if (this.cid.descricaoAbreviada == "" || this.cid.descricaoAbreviada == undefined) {
        this.$toasted.show("Digite a Descrição Abreviada", {
          type: "info",
          icon: "times"
        });
        return false; 
      } else {
        return true;
      }
    },
    verificarPermissao(operacao) {
      if (!errors.verificarPermissao(this.usuarioLogadoDados, this.$store.state.global.grauPermissaoMinimo, operacao)) {
        let descricaoOperacao = operacao == "Incluir" ? "Inclusão" : operacao == "Alterar" ? "Alteração" : "Exclusão";

        this.mensagem = `${this.usuarioLogadoDados.profissional.nome}, você não possui permissão para realizar ${descricaoOperacao} com esse Perfil.`;
        this.$bvModal.show("modal-mensagem");

        return false;
      }

      return true;
    }    
  },
  created() {
    this.showMenu(false);
  },
  beforeRouteLeave(to, from, next) {
    if (this.modo == "Incluir" || this.modo == "Alterar") {
      this.$bvModal
        .msgBoxConfirm("Tem certeza que deseja sair sem Gravar os Dados?", {
          title: "Atenção",
          size: "sm",
          buttonSize: "sm",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            next(true);
          } else {
            next(false);
          }
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        });
    } else {
      next(true);
    }
  }
}
</script>

<style scoped>
</style>
