const state = {
    registroSelecionado : {},
    ultimaBusca : null,
    modulo : "",
}

const getters = {
    getRegistroSelecionado(state) {
        return state.registroSelecionado;
    },
    getUltimaBusca(state) {
        return state.ultimaBusca;
    },
    getModulo(state) {
        return state.modulo;
    }
}

const mutations = {
    setRegistroSelecionado(state, registro) {
        state.registroSelecionado = {...registro}
    },
    setUltimaBusca(state, ultimaBusca) {
        state.ultimaBusca = ultimaBusca != null ? {...ultimaBusca} : null
    },
    setModulo(state, modulo) {
        state.modulo = modulo;
    }
}

const actions = {
    setRegistroSelecionado({commit}, registro) {
        commit("setRegistroSelecionado", registro)
    },
    setUltimaBusca({commit}, ultimaBusca) {
        commit("setUltimaBusca", ultimaBusca)
    },
    setModulo({commit}, modulo) {
        commit("setModulo", modulo);
    }
}

export default {
    namespaced : true,
    state,
    getters,
    mutations,
    actions
}