<template>
  <div id="frmAcompanhamentos">
    <div class="cabecalho">
      <div class="coluna1">
        <button @click="retornar" class="btn btn-sm btn-primary mr-10">
          <i class="fa fa-arrow-left" />
        </button>
      </div>
      <div class="coluna2">
        <span>Acompanhamentos de Pacientes</span>
      </div>
      <div class="coluna3" />
    </div>

    <div class="container-fluid">
      <div class="row">
          <div class="form-group col-lg-2">
              <label for="datInicial" class="control-label">Data Inicial</label>
              <input id="datInicial" type="date" class="form-control" v-model="datInicial">
          </div>
          <div class="form-group col-lg-2">
              <label for="datFinal" class="control-label">Data Final</label>
              <input id="datFinal" type="date" class="form-control" v-model="datFinal">
          </div>
          <div class="form-group col-lg-1">
              <b-check id="visualizados" class="mt-4" v-model="visualizados">Visualizados</b-check>
          </div>
          <div class="form-group col-lg-1">
            <button id="btnFiltrar" style="margin-top:25px" class="form-control" @click="buscarPeloProfissional()">
              <i class="fa fa-search"/> 
            </button>
          </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <hr/>
          <div class="row barraBotoes">
            <template v-if="acompanhamentoSelecionado.profissionalId != undefined">
              <Strong><span style="margin-left:20px">{{acompanhamentoSelecionado.paciente}} - {{acompanhamentoSelecionado.pacienteId}} - Prevista: {{acompanhamentoSelecionado.datPrevista | formatDate}}</span></Strong>
              <span style="margin-left:20px">Ações --></span>
              <b-button id="btnProntuario" variant="info" 
                  v-b-tooltip.hover title="Prontuário do(a) Paciente" size="sm" class="ml-3" @click="prontuarioPaciente()">
                  <i class="fa fa-stethoscope"/>
              </b-button>
              <template v-if="modo==''">
                <b-button v-if="acompanhamentoSelecionado.datContato==null"
                   variant="warning"
                   v-b-tooltip.hover title="Editar Registro"
                   size="sm"
                   @click="alterarRegistro(acompanhamentoSelecionado)"
                   class="ml-1"
                 >
                   <i class="fa fa-pencil" />
                </b-button>
                <b-button v-if="acompanhamentoSelecionado.datContato==null"
                  variant="danger"
                  v-b-tooltip.hover title="Excluir Registro"
                  size="sm"
                  @click="excluirRegistro(acompanhamentoSelecionado)"
                  class="ml-1"
                >
                  <i class="fa fa-trash" />
                </b-button>
                <b-button v-if="acompanhamentoSelecionado.datContato!=null && acompanhamentoSelecionado.datVisualizacao==null"
                  variant="info"
                  v-b-tooltip.hover title="Marcar como Visualizado"
                  size="sm"
                  @click="registrarComoVisualizada(acompanhamentoSelecionado)"
                  class="ml-1"
                >
                  <i class="fa fa-eye" />
                </b-button>
              </template>
            </template>
          </div>
        </div>
      </div>

      <template v-if="modo=='Alterar'">
        <div class="row">
          <div class="form-group col-lg-2">
            <label for="datPrevista" class="control-label">Prevista</label>
            <input type="date" id="datPrevista" class="form-control" v-model="acompanhamentoSelecionado.datPrevista"/>
          </div>
          <div class="form-group col-lg-8">
            <label for="pergunta" class="control-label">Pergunta</label>
            <input type="text" id="pergunta" class="form-control" v-model="acompanhamentoSelecionado.pergunta"/>
          </div>
          <div v-if="acompanhamentoSelecionado.datVisualizacao==null" class="form-group col-lg-2">
            <b-button style="margin-top:27px" variant="warning" size="sm" @click="cancelar()">Cancelar</b-button>
            <b-button style="margin-top:27px;margin-left:5px" variant="info" size="sm" @click="gravar()">Confirmar</b-button>
          </div>
        </div>
        <hr>
      </template>

      <template v-if="this.erro!=''">
        <p class="msgError">Erro durante Operação: {{ this.erro }}</p>
      </template>

      <div style="display:flex; flex-direction:row; justify-content:center;height:520px">
        <b-spinner v-if="loadingAcompanhamentos" label="carregando..."/>
        <b-table v-if="loadedAcompanhamentos" class="col-lg-12" hover striped small sort-icon-left :items="acompanhamentos" :fields="camposTabela" @row-clicked="selecionouRegistro">
          <template v-slot:cell(datPrevista)="datPrevista">
            {{ datPrevista.value | formatDate }}
          </template>
          <template v-slot:cell(datContato)="datContato">
            {{ datContato.value | formatDate }}
          </template>
          <template v-slot:cell(datVisualizacao)="datVisualizacao">
            {{ datVisualizacao.value | formatDate }}
          </template>
        </b-table>
      </div>

      <b-pagination v-if="loadedAcompanhamentos" class="col-lg-12 justify-content-center" size="md" v-model="page" :total-rows="count" :per-page="limit"/>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import global from "@/mixins/global";
import errors from "@/utils/errors";
import funcoes from "@/utils/funcoes";
import acompanhamentos from "@/mixins/acompanhamentos";

export default {
  mixins : [acompanhamentos, global],
  computed: {
    ...mapGetters({
      usuarioLogadoDados: "global/getUsuarioLogadoDados",
      ultimaBusca: "acompanhamentos/getUltimaBusca"
    }),
  },
  watch : {
    page() {
      this.buscarPeloProfissional();
    }
  },
  data() {
      return {
          loadedAcompanhamentos : false,
          loadingAcompanhamentos : false,
          page : 1,
          count : 0,
          limit : 0,
          erro : "",
          modo : "",
          visualizados : false,
          datInicial : null,
          datFinal : null,
          acompanhamentoSelecionado : {},
          perguntaInicial : "",
          camposTabela : [
            {key : "paciente", label : "Paciente", sortable : true},
            {key : "datPrevista", label : "Prevista", sortable : true},
            {key : "pergunta", label : "Pergunta"},
            {key : "datContato", label : "Contato", sortable : true},
            {key : "resposta", label : "Resposta"},
            {key : "datVisualizacao", label : "Visualizada", sortable : true},
          ],
          retornando : false
      }
  },
  methods: {
    ...mapActions({
      setPacienteModulo : "pacientes/setModulo",
      setPacienteSelecionado : "pacientes/setPacienteSelecionado",
      setUltimaBusca : "acompanhamentos/setUltimaBusca",
      formLeave : "acompanhamentos/formLeave",
    }),
    retornar() {
      this.retornando = true;
      this.$router.go(-1);
    },
    buscarPeloProfissional() {
      let visualizado = this.visualizados ? "S" : "N";
      this.carregarPeloProfissionalVisualizados(this.usuarioLogadoDados.tenant, this.usuarioLogadoDados.profissionalId, this.datInicial, this.datFinal, visualizado, this.page);
    },
    selecionouRegistro(registroSelecionado) {
      this.modo = "";
      this.acompanhamentoSelecionado = {...registroSelecionado};
    },
    alterarRegistro() {
        if (!this.verificarPermissao('Alterar')) {
          return
        }

        this.modo = "Alterar";
        //this.acompanhamentoSelecionado = {...registroSelecionado};
        this.acompanhamentoSelecionado.datPrevista = funcoes.mySqlToJSDateFormat(this.acompanhamentoSelecionado.datPrevista);
    },
    registrarComoVisualizada() {
        if (!this.verificarPermissao('Alterar')) {
          return
        }

        this.$bvModal.msgBoxConfirm("Marcar esse registro como Visualizado?", {
            title: "Atenção",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "Sim",
            cancelTitle: "Não",
            footerClass: "p-2",
            hideHeaderClose: true,
            centered: true,
          })
          .then(value => {
            if (value) {
              this.modo = "RegistrarVisualizada";
              this.gravar();
            }
          })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          });
    },
    excluirRegistro() {
        if (!this.verificarPermissao('Excluir')) {
          return
        }

        this.$bvModal.msgBoxConfirm("Excluir Registro Selecionado?", {
            title: "Atenção",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "Sim",
            cancelTitle: "Não",
            footerClass: "p-2",
            hideHeaderClose: true,
            centered: true,
          })
          .then(value => {
            if (value) {
             this.$http
               .delete(`acompanhamentos/${this.usuarioLogadoDados.tenant}/${this.acompanhamentoSelecionado.id}/${this.usuarioLogadoDados.profissionalId}`)
               .then(() => {
                 this.erro = "";
                 this.modo = "";
                 this.limparRegistroSelecionado();
                 this.$toasted.global.defaultSuccess();
                 this.buscarPeloProfissional();
               })
               .catch(error => {
                 this.erro = errors.tratarErros(error);
               })
               .finally(() => {});
            }
          })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          });
    },
    cancelar() {
      this.modo = "";
      this.limparRegistroSelecionado();
    },
    limparRegistroSelecionado() {
      this.acompanhamentoSelecionado = {};
      this.acompanhamentoSelecionado.datPrevista = null;
      this.acompanhamentoSelecionado.datContato = null; 
      this.acompanhamentoSelecionado.datVisualizacao = null;
    },
    gravar() {
        if (this.modo=="Alterar" && !this.verificarCamposPreenchidos()) {
            return;
        }

        this.acompanhamentoSelecionado.operadorId = this.usuarioLogadoDados.profissionalId;

        if (this.modo=="RegistrarVisualizada") {
            this.acompanhamentoSelecionado.datVisualizacao =  funcoes.mySqlToJSDateFormat(Date());
        }

        this.$http.put(`/v1.0/acompanhamentos/${this.usuarioLogadoDados.tenant}/${this.acompanhamentoSelecionado.id}`, this.acompanhamentoSelecionado)
        .then(() => {
            this.erro = "";
            this.modo = "";
            this.$toasted.global.defaultSuccess();
            this.buscarPeloProfissional();
        })
        .catch(error => this.erro = errors.tratarErros(error))
    },
    verificarCamposPreenchidos() {
      if (this.acompanhamentoSelecionado.datPrevista == null || this.acompanhamentoSelecionado.datPrevista == "") {
        this.$toasted.show("Digite Data para que seja feito Contato com o(a) Paciente.");
        return false;
      }

      if (this.acompanhamentoSelecionado.pergunta == "") {
        this.$toasted.show("Digite a Pergunta que deve ser feita a(o) Paciente.");
        return false;
      }

      let datAtual = funcoes.mySqlToJSDateFormat(Date());

      if (this.acompanhamentoSelecionado.datPrevista <= datAtual) {
        this.$toasted.show("A Data Prevista não pode ser menor ou igual a Data Atual");
        return false;
      }

      return true;
    },
    prontuarioPaciente() {
        this.$http.get(`/v1.0/pacientes/${this.usuarioLogadoDados.tenant}/${this.acompanhamentoSelecionado.pacienteId}`)
        .then(resp => {
            this.setPacienteSelecionado(resp.data);
            this.erro = "";
            this.setPacienteModulo("");

            this.$router.push('/frmProntuario');
        })
        .catch(error => {
            this.erro = errors.tratarErros(error);
        })
    },
    verificarPermissao(operacao) {
      if (!errors.verificarPermissao(this.usuarioLogadoDados, this.$store.state.global.grauPermissaoMinimo, operacao)) {
        let descricaoOperacao = operacao == "Incluir" ? "Inclusão" : operacao == "Alterar" ? "Alteração" : "Exclusão";

        this.mensagem = `${this.usuarioLogadoDados.profissional.nome}, você não possui permissão para realizar ${descricaoOperacao} com esse Perfil.`;
        this.$bvModal.show("modal-mensagem");

        return false;
      }

      return true;
    }    
  },
  created() {
    this.showMenu(false);
    this.datInicial = funcoes.mySqlToJSDateFormat(Date());
    this.datFinal = funcoes.mySqlToJSDateFormat(Date());
    this.limparRegistroSelecionado();

    if (this.ultimaBusca.datInicial != undefined) {
      this.datInicial = this.ultimaBusca.datInicial; 
      this.datFinal = this.ultimaBusca.datFinal; 
      this.visualizado = this.ultimaBusca.visualizados 
      this.page = this.ultimaBusca.page;
      this.count = this.ultimaBusca.count;
      this.limit = this.ultimaBusca.limit;

      this.buscarPeloProfissional();
    }
  },
  beforeDestroy() {
      if (this.retornando) {
          this.formLeave();
      }

      let registroUltimaBusca = {datInicial : this.datInicial, datFinal : this.datFinal, 
                                page : this.page, count : this.count, limit : this.limit,
                                visualizados : this.visualizados}
      this.setUltimaBusca(registroUltimaBusca);
  },
};
</script>

<style scoped>
    label {
      font-weight: 100%;
      font-size: 0.8rem;
      margin-top: 3px;
      margin-bottom: 1px;
    }
</style>
