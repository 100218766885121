import errors from "@/utils/errors";

export default {
    data() {
        return {
            count : 0,
            limit : 0,
            tabelaSimpros : []
        }
    },
    methods : {
        async carregarSimproPeloCodigoTiss(codigoTiss) {
            await this.$http.get(`/v1.0/tabelaSimpro/codigoTiss/${codigoTiss}`)
            .then(resp => {
                this.tabelaSimpro = resp.data;

                this.loaded = true;
                this.erro = "";
            })
            .catch(error => this.erro = errors.tratarErros(error))
            .finally(() => {
                this.loading = false;
            })
        },
        carregarListaSimproPeloCodigoTiss(codigoTiss, ativo, pagina) {
            this.$http.get(`/v1.0/tabelaSimpro/codigoTiss/${codigoTiss}/${ativo}/${pagina}`)
            .then(resp => {
                this.tabelaSimpros = resp.data.registros;
                this.count = resp.data.count;
                this.limit = resp.data.limit;

                this.loaded = true;
                this.erro = "";
            })
            .catch(error => this.erro = errors.tratarErros(error))
            .finally(() => {
                this.loading = false;
            })
        },
        carregarSimproPeloDescricao(descricao, ativo, pagina) {
            this.$http.get(`/v1.0/tabelaSimpro/descricao/${descricao}/${ativo}/${pagina}`)
            .then(resp => {
                this.tabelaSimpros = resp.data.registros;
                this.count = resp.data.count;
                this.limit = resp.data.limit;

                this.loaded = true;
                this.erro = "";
            })
            .catch(error => this.erro = errors.tratarErros(error))
            .finally(() => {
                this.loading = false;
            })
        },
        carregarSimproPeloFabrica(fabrica, ativo, pagina) {
            this.$http.get(`/v1.0/tabelaSimpro/fabrica/${fabrica}/${ativo}/${pagina}`)
            .then(resp => {
                this.tabelaSimpros = resp.data.registros;
                this.count = resp.data.count;
                this.limit = resp.data.limit;

                this.loaded = true;
                this.erro = "";
            })
            .catch(error => this.erro = errors.tratarErros(error))
            .finally(() => {
                this.loading = false;
            })
        }
    }
}
