const state = {
    perfilSelecionado : {}
}

const getters = {
    getPerfilSelecionado(state) {
        return state.perfilSelecionado;
    }
}

const mutations = {
    setPerfilSelecionado(state, perfil) {
        state.perfilSelecionado = {...perfil};
    }
}

const actions = {
    setPerfilSelecionado({commit}, perfil) {
        commit("setPerfilSelecionado", perfil);
    }
}

export default {
    namespaced : true,
    state,
    getters,
    mutations,
    actions
}
