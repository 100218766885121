<template>
  <div class="formulario">
    <div class="cabecalho">
      <button @click="retornar" class="btn btn-sm btn-primary mr-10"><i class="fa fa-arrow-left"/></button>
    </div>

    <div class="tituloPagina">
        <span>{{operadoraSelecionada.fantasia}} - Plano: {{planoSaudeSelecionado.plano}} - Procedimento Coberto</span>
        <hr/>
    </div>

    <div class="container-fluid">
      <div class="registro">
        <div class="row">
          <div class="form-group col-lg-1">
            <label for="Id" class="control-label">Id</label>
            <input
              type="text"
              class="form-control"
              disabled
              id="Id"
              name="Id"
              :value="procedimentoCobertoSelecionado.id"
            />
          </div>

          <div class="form-group col-lg-1">
            <label for="CodPro" class="control-label">Código</label>
            <input
              type="text"
              class="form-control"
              :disabled="modo!='Incluir'"
              id="CodPro"
              name="CodPro"
              v-model="procedimentoCobertoSelecionado.codigoProcedimento"
            />
          </div>

          <div class="form-group col-lg-7">
            <label for="Descricao" class="control-label">Descrição</label>
            <input
              type="text"
              class="form-control"
              :disabled="!liberarCampos"
              id="Descricao"
              name="Descricao"
              v-model="procedimentoCobertoSelecionado.descricao"
            />
          </div>

          <div class="form-group col-lg-2">
            <label for="servico" class="control-label mr-2">Serviço</label>
            <select
              id="servico"
              name="servico"
              class="form-control"
              selected
              :disabled="!liberarCampos"
              v-model="procedimentoCobertoSelecionado.siglaServico"
            >
              <option
                v-for="servico in servicos"
                :key="servico.id"
                :value="servico.siglaServico"
              >{{ servico.descricao }}</option>
            </select>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-lg-1"></div>

          <div class="form-group col-lg-1">
            <label for="CodTuss" class="control-label">Cód.TUSS</label>
            <input
              type="text"
              class="form-control"
              :disabled="!liberarCampos"
              id="CodTuss"
              name="CodTuss"
              v-model="procedimentoCobertoSelecionado.codigoTuss"
            />
          </div>

          <div class="form-group col-lg-7">
            <label for="DescricaoTuss" class="control-label">Descrição TUSS</label>
            <input
              type="text"
              class="form-control"
              :disabled="!liberarCampos"
              id="DescricaoTuss"
              name="DescricaoTuss"
              v-model="procedimentoCobertoSelecionado.descricaoTuss"
            />
          </div>

          <div class="form-group col-lg-2">
            <label for="NecessitaAutorizacao" class="control-label">Necessita Autorização</label>
            <select
              id="NecessitaAutorizacao"
              class="form-control"
              :disabled="!liberarCampos"
              selected
              v-model="procedimentoCobertoSelecionado.necessitaAutorizacao"
            >
              <option
                v-for="resposta in sim_nao"
                :key="resposta.opcao"
                :value="resposta.opcao"
              >{{resposta.valor}}</option>
            </select>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-lg-1"></div>

          <div class="form-group col-lg-1">
            <label for="ValorCobrado" class="control-label">Cobrado R$</label>
            <input
              type="text"
              class="form-control"
              :disabled="!liberarCampos"
              id="ValorCobrado"
              name="ValorCobrado"
              v-model.number="procedimentoCobertoSelecionado.valorCobrado"
            />
          </div>
          <div class="form-group col-lg-1">
            <label for="valCh" class="control-label">CH R$</label>
            <input
              type="text"
              class="form-control"
              :disabled="!liberarCampos"
              id="valCh"
              name="valCh"
              v-model.number="procedimentoCobertoSelecionado.valCH"
            />
          </div>
          <div class="form-group col-lg-1">
            <label for="valFilmeRadiologico" class="control-label">Filme R$</label>
            <input
              type="text"
              class="form-control"
              :disabled="!liberarCampos"
              id="valFilmeRadiologico"
              name="valFilmeRadiologico"
              v-model.number="procedimentoCobertoSelecionado.valFilmeRadiologico"
            />
          </div>
        </div>

        <div class="row">
          <div class="form-group col-lg-1"></div>

          <div class="form-group col-lg-1">
            <label for="ch" class="control-label">CHs</label>
            <input
              type="text"
              class="form-control"
              :disabled="!liberarCampos"
              id="ch"
              name="ch"
              v-model.number="procedimentoCobertoSelecionado.ch"
            />
          </div>
          <div class="form-group col-lg-1">
            <label for="co" class="control-label">CO</label>
            <input
              type="text"
              class="form-control"
              :disabled="!liberarCampos"
              id="co"
              name="co"
              v-model.number="procedimentoCobertoSelecionado.co"
            />
          </div>
          <div class="form-group col-lg-1">
            <label for="filme" class="control-label">Filme (m2)</label>
            <input
              type="text"
              class="form-control"
              :disabled="!liberarCampos"
              id="filme"
              name="filme"
              v-model.number="procedimentoCobertoSelecionado.filme"
            />
          </div>

          <div class="form-group col-lg-1">
            <label for="porte" class="control-label">Porte</label>
            <input
              type="text"
              class="form-control"
              :disabled="!liberarCampos"
              id="porte"
              name="porte"
              v-model="procedimentoCobertoSelecionado.porte"
            />
          </div>
          <div class="form-group col-lg-1">
            <label for="portePorcentagem" class="control-label">% Porte</label>
            <input
              type="text"
              class="form-control"
              :disabled="!liberarCampos"
              id="portePorcentagem"
              name="portePorcentagem"
              v-model.number="procedimentoCobertoSelecionado.portePorcentagem"
            />
          </div>
          <div class="form-group col-lg-1">
            <label for="porteAnestesico" class="control-label">Porte Anest</label>
            <input
              type="text"
              class="form-control"
              :disabled="!liberarCampos"
              id="porteAnestesico"
              name="porteAnestesico"
              v-model.number="procedimentoCobertoSelecionado.porteAnestesico"
            />
          </div>

          <div class="form-group col-lg-1">
            <label for="incidencia" class="control-label">Incidências</label>
            <input
              type="number"
              class="form-control"
              :disabled="!liberarCampos"
              id="incidencia"
              name="incidencia"
              v-model.Number="procedimentoCobertoSelecionado.incidencias"
            />
          </div>
          <div class="form-group col-lg-1">
            <label for="Auxiliares" class="control-label">Auxiliares</label>
            <input
              type="text"
              class="form-control"
              :disabled="!liberarCampos"
              id="Auxiliares"
              name="Auxiliares"
              v-model.number="procedimentoCobertoSelecionado.auxiliares"
            />
          </div>

        </div>

        <div class="row">
          <div class="form-group col-lg-1"></div>

          <div class="form-group col-lg-9">
            <label for="Observacao" class="control-label">Observação</label>
            <input
              type="text"
              class="form-control"
              :disabled="!liberarCampos"
              id="Observacao"
              name="Observacao"
              v-model.number="procedimentoCobertoSelecionado.observacao"
            />
          </div>
        </div>
      </div>

      <template v-if="this.erro!=''">
        <p class="msgError">Erro durante Operação: {{ this.erro }}</p>
      </template>

      <hr />
      <div class="barraopcoescadastro">
        <button
          id="Excluir"
          :disabled="modo!='Registro'"
          @click="excluirRegistro()"
          class="btn btn-sm btn-danger mr-2"
        >Remover</button>
        <button
          id="Alterar"
          :disabled="modo!='Registro'"
          @click="alterarRegistro()"
          class="btn btn-sm btn-secondary mr-2"
        >Alterar</button>
        <button
          id="Cancelar"
          @click="cancelarOperacao()"
          class="btn btn-sm btn-secondary mr-2"
        >Cancelar</button>
        <button
          id="Gravar"
          :disabled="!liberarCampos"
          @click="gravarRegistro()"
          class="btn btn-sm btn-secondary"
        >Gravar</button>
      </div>
    </div>
    <div class="Mensagem">
      <b-modal id="modal-mensagem" centered title="DoctorOn">
        <p class="my-4">{{mensagem}}</p>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import constantes from "@/utils/constantes";
import servicos from "@/mixins/servicos";
import global from "@/mixins/global";
import errors from "@/utils/errors";

export default {
  mixins: [servicos, global],
  computed: {
    ...mapGetters({
      operadoraSelecionada: "operadoras/getOperadoraSelecionada",
      planoSaudeSelecionado: "planosSaude/getPlanoSaudeSelecionado",
      procedimentoCobertoSelecionado:
        "procedimentosCobertos/getProcedimentoCobertoSelecionado",
      usuarioLogadoDados : "global/getUsuarioLogadoDados"
    }),
    liberarCampos() {
      return this.modo == "Alterar" || this.modo == "Incluir" ? true : false;
    }
  },
  data() {
    return {
      
      erro: "",
      mensagem : "",
      sim_nao: constantes.sim_nao,
      retornando: false,
      modo : "Registro",
    }
  },
  methods: {
    ...mapActions({
      limparRegistroSelecionado:
        "procedimentosCobertos/limparRegistroSelecionado",
      formLeave: "procedimentosCobertos/formLeave"
    }),
    retornar() {
      this.retornando = true;
      this.$router.go(-1);
    },
    gravarRegistro() {
      if (!this.verificarCamposPreenchidos()) {
        return;
      }

      if (this.modo=="Alterar") {
        this.procedimentoCobertoSelecionado.operadorAlteracaoId = this.usuarioLogadoDados.profissionalId;

        this.$http
          .put(
            `/v1.0/procedimentoscobertos/${this.usuarioLogadoDados.tenant}/${this.procedimentoCobertoSelecionado.id}`,
            this.procedimentoCobertoSelecionado
          )
          .then(() => {
            this.erro = "";
            this.limparCampos();
            this.$toasted.global.defaultSuccess();
            this.retornar();
          })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          })
          .finally(() => {});
      } else {
        this.procedimentoCobertoSelecionado.tabela = "Própria";
        this.procedimentoCobertoSelecionado.laboratorial = 'N';
        this.procedimentoCobertoSelecionado.operadorCadastroId = this.usuarioLogadoDados.profissionalId;
        this.procedimentoCobertoSelecionado.planoSaudeId = this.planoSaudeSelecionado.id;
        this.procedimentoCobertoSelecionado.id = 0;

        let lista = [this.procedimentoCobertoSelecionado];

        this.$http
          .post(`/v1.0/procedimentoscobertos/${this.usuarioLogadoDados.tenant}`, lista)
          .then(() => {
            this.erro = "";
            this.limparCampos();
            this.$toasted.global.defaultSuccess();
            this.retornar();
          })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          })
          .finally(() => {});
      }
    },
    alterarRegistro() {
      if (!this.verificarPermissao('Alterar')) {
        return
      }

      this.modo = 'Alterar';
    },    
    excluirRegistro() {
      if (!this.verificarPermissao('Excluir')) {
        return
      }

      this.$bvModal
        .msgBoxConfirm("Remover Registro da Lista de Procedimentos Cobertos?", {
          title: "Atenção",
          size: "md",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            this.$http
              .delete(
                `/v1.0/procedimentoscobertos/${this.usuarioLogadoDados.tenant}/${this.procedimentoCobertoSelecionado.id}/${this.usuarioLogadoDados.profissionalId}`
              )
              .then(() => {
                this.erro = "";

                this.limparCampos();
                this.$toasted.global.defaultSuccess();
                this.retornar();
              })
              .catch(error => {
                this.erro = errors.tratarErros(error);
              })
              .finally(() => {});
          }
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        });
    },
    cancelarOperacao() {
      this.limparCampos();
      this.retornar();
    },
    limparCampos() {
      this.limparRegistroSelecionado();
    },
    verificarCamposPreenchidos() {
      if (this.procedimentoCobertoSelecionado.codigoProcedimento == "" || this.procedimentoCobertoSelecionado.codigoProcedimento == null) {
        this.$toasted.show("Digite o Código do Procedimento", {type: "info", icon: "times"});
        return false;
      }

      if (this.procedimentoCobertoSelecionado.descricao == "" || this.procedimentoCobertoSelecionado.descricao == null) {
        this.$toasted.show("Digite a Descrição", {type: "info", icon: "times"});
        return false;
      }

      if (this.procedimentoCobertoSelecionado.siglaServico == "" || this.procedimentoCobertoSelecionado.siglaServico == null) {
        this.$toasted.show("Selecione o Serviço", {type: "info", icon: "times"});
        return false;
      }

      if (this.procedimentoCobertoSelecionado.necessitaAutorizacao == "" || this.procedimentoCobertoSelecionado.necessitaAutorizacao == null) {
        this.$toasted.show("Selecione se o Procedimento Necessita de Autorização", {type: "info", icon: "times"});
        return false;
      }

      return true;
    },
    verificarPermissao(operacao) {
      if (!errors.verificarPermissao(this.usuarioLogadoDados, this.$store.state.global.grauPermissaoMinimo, operacao)) {
        let descricaoOperacao = operacao == "Incluir" ? "Inclusão" : operacao == "Alterar" ? "Alteração" : "Exclusão";

        this.mensagem = `${this.usuarioLogadoDados.profissional.nome}, você não possui permissão para realizar ${descricaoOperacao} com esse Perfil.`;
        this.$bvModal.show("modal-mensagem");

        return false;
      }

      return true;
    }
  },
  async created() {
    this.carregarServicos(this.usuarioLogadoDados.tenant);

    if (this.procedimentoCobertoSelecionado.descricao == undefined) {
      this.modo = "Incluir"
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.retornando) {
      this.formLeave();
    }

    next(true);
  }
}
</script>

<style scoped>
  label {
    font-weight: 100%;
    font-size: 0.8rem;
    margin-top: 3px;
    margin-bottom: 1px;
  }
</style>
