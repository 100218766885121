<template>
  <div id="frmDefinirSenha">
    <div class="cabecalho">
      <button @click="retornar" class="btn btn-sm btn-primary mr-10"><i class="fa fa-arrow-left"/></button>
    </div>
    <div class="container-fluid">
      <div class="corpo">
        <div class="tituloPagina">
          <span>{{definirSenha.nome}} - Definir Senha de Acesso ao Sistema</span>
          <hr/>
        </div>

        <div class="senha">
            <div class="senhaOpcoes" v-if="possuiConta && usuarioLogadoDados.perfilAcesso.perfil.funcao=='ADM'"> 
                <div>
                  <b-button variant="danger" size="sm" @click="removerSenha()">
                    <i class="fa fa-trash"> Zerar Senha</i>
                  </b-button>
                </div>
            </div>
            <div class="senhaCampos">
                <div class="possuiSenha" v-if="possuiConta">
                  <label for="senhaAnterior">Senha Atual:</label>
                  <b-form-input type="password" id="senhaAnterior" v-model="senhaAtual" placeholder="Digite sua Senha Atual..."/>
                </div>
                <div class="naoPossuiEmail" v-if="!possuiConta">
                  <label for="email">E-Mail (Login):</label>
                  <b-form-input id="email" v-model="contaDoUsuario.email"/>
                </div>

                <label for="novaSenha">Senha (entre 6 e 15 dígitos):</label>
                <b-form-input type="password" id="novaSenha" v-model="contaDoUsuario.senha" placeholder="Digite sua nova Senha..."/>

                <label for="confirmarSenha">Confirmar Senha:</label>
                <b-form-input type="password" id="confirmarSenha" v-model="confirmarSenha" placeholder="Confirme sua nova Senha..."/>

                <div v-if="senhasNaoConferem">
                  <label id="conferenciaSenha">** Senhas não Conferem</label>
                </div>

                <div class="ConfirmarSenha" v-if="senhasConferem && senhaTamanhoSolicitado">
                  <b-button id="btnConfirmarSenha" variant="info" size="sm" @click="confirmarSenhaDigitada()">Confirmar Senha</b-button>
                </div>
            </div>
        </div>

        <template v-if="this.erro!=''">
          <p class="msgError">Erro durante Operação: {{ this.erro }}</p>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import errors from "@/utils/errors";

export default {
  name: "frmDefinirSenha",
  computed: {
    ...mapGetters({
      usuarioLogadoDados : "global/getUsuarioLogadoDados",
      definirSenha : "usuarios/getDefinirSenha"
    }),
    senhasNaoConferem() {
      return this.contaDoUsuario.senha!='' && this.confirmarSenha!='' && this.contaDoUsuario.senha!=this.confirmarSenha ? true : false
    },
    senhasConferem() {
      return this.contaDoUsuario.email!='' && this.contaDoUsuario.senha!='' && this.confirmarSenha!='' && this.contaDoUsuario.senha==this.confirmarSenha ? true : false
    },
    senhaTamanhoSolicitado() {
      return this.contaDoUsuario.senha.length >= 6 && this.contaDoUsuario.senha.length <= 15 ? true : false;
    } 
  },
  data() {
    return {
      erro : "",
      confirmarSenha: "",
      senhaAtual: "",
      possuiConta : false,
      contaDoUsuario: {password : "", email : "", profissionalId : 0, operadorCadastroId : 0},
      identity : {},
      usuario : {},
    }
  },
  methods: {
    ...mapActions({
      setUsuarioSelecionado : "usuarios/setUsuarioSelecionado",
    }),
    retornar() {
      this.$router.go(-1);
    },
    confirmarSenhaDigitada() {
      if (!this.possuiConta) {
        this.novoUsuario();
      } else {
        this.usuarioExistente();
      }
    },
    usuarioExistente() {
      if (this.possuiConta && this.senhaAtual=="") {
        this.$toasted.show("Senha Atual deve ser Informada.", {type: "info", icon : "time"});
        return;
      }

      if (this.senhaAtual == this.contaDoUsuario.senha) {
        this.$toasted.show("Senha Atual e Nova Senha são iguais.", {type: "info", icon: "time"});
        return;
      }

      if (this.possuiConta) {
        var changePassword = {
                              id : this.contaDoUsuario.id, 
                              email : this.contaDoUsuario.email,
                              senhaAtual : this.senhaAtual,
                              novaSenha : this.contaDoUsuario.senha,
                              profissionalId : this.contaDoUsuario.profissionalId,
                              operadorAlteracaoId : this.usuarioLogadoDados.profissionalId
                            }
  
          this.$http.put(`/v1.0/usuarios/${this.usuarioLogadoDados.tenant}/TrocarSenha/${changePassword.id}`, changePassword)
          .then(() => {
            this.erro = "";
            this.limparCampos();
            this.$toasted.global.defaultSuccess();
          })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          })
      } else {
        var addPassword = {id : this.definirSenha.identityId, 
                           password : this.contaDoUsuario.senha}
  
        this.$http.post(`/v1.0/usuarios/${this.usuarioLogadoDados.tenant}/AdicionarSenha`, addPassword)
          .then(() => {
            this.erro = "";
            this.limparCampos();
            this.$toasted.global.defaultSuccess();
          })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          })
      }
    },
    novoUsuario() {
      if (this.contaDoUsuario.email=="") {
        this.$toasted.show("Informe um e-mail para o Usuário.", {type: "info", icon : "time"});
        return;
      }

      if (this.contaDoUsuario.senha=="") {
        this.$toasted.show("Informe uma senha para o Usuário.", {type: "info", icon : "time"});
        return;
      }

      if (this.contaDoUsuario.senha!=this.confirmarSenha) {
        this.$toasted.show("Senha não confirmada corretamente.", {type: "info", icon : "time"});
        return;
      }

      this.contaDoUsuario.profissionalId = this.definirSenha.profissionalId;
      this.contaDoUsuario.operadorCadastroId = this.usuarioLogadoDados.profissionalId

      this.$http.post(`/v1.0/usuarios/${this.usuarioLogadoDados.tenant}`, this.contaDoUsuario)
        .then(() => {
          this.erro = "";
          this.$toasted.global.defaultSuccess();
          this.retornar();
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        })
    },
    removerSenha() {
      this.$bvModal
        .msgBoxConfirm("Zerar a Senha do Usuário Selecionado?", {
          title: "Atenção",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            var removePassword = {id : this.definirSenha.identityId}

            this.$http
              .put(`/v1.0/usuarios/${this.usuarioLogadoDados.tenant}/RemoverSenha/${this.definirSenha.usuarioId}/${this.usuarioLogadoDados.profissionalId}`, removePassword)
              .then(() => {
                this.erro = "";

                this.limparCampos();
                this.$toasted.global.defaultSuccess();
              })
              .catch(error => {
                this.erro = errors.tratarErros(error);
              })
              .finally(() => {});
          }
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        });
    },
    /*
    autenticarUsuario() {
      var auth = {"UserName" : this.contaDoUsuario.email, "Password" : this.contaDoUsuario.senha}

      this.$http.post(`/v1.0/auth/${this.usuarioLogadoDados.tenant}/login`, auth)
        .then(resp => {
          this.identity = resp.data;
          this.gravarIdentityId();
          this.erro = "";
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
          return
        })

    },
    gravarIdentityId() {
      let usuario = {
        profissionalId : this.definirSenha.profissionalId,
        perfilId : this.definirSenha.perfilId,
        identityId : this.identity.id,
        operadorCadastroId : this.usuarioLogadoDados.profissionalId
      }

      this.$http.post(`/v1.0/usuarios/${this.usuarioLogadoDados.tenant}`, usuario)
        .then(resp => {
          this.usuario = resp.data;
          this.setUsuarioSelecionado(this.usuario);
          this.erro = "";
          this.limparCampos();
          this.$toasted.global.defaultSuccess();
          this.$router.go(-1);
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        })
    },
    */
    limparCampos() {
      this.confirmarSenha = "";
      this.contaDoUsuario.senha= "";
      this.senhaAtual = "";
    }
  },
  created() {
    this.contaDoUsuario.id = this.definirSenha.usuarioId;
    this.contaDoUsuario.email = this.definirSenha.email;
    this.contaDoUsuario.profissionalId = this.definirSenha.profissionalId;

    this.possuiConta = this.definirSenha.modo != "Incluir";

    /*
        chamadoPor -> userDropDown / cadProfissionalGeral / 

        let definirSenha = {
            profissionalId : this.usuarioLogadoDados.profissionalId,
            usuarioId : this.usuarioLogadoDados.id,
            nome : this.usuarioLogadoDados.profissional.nome,
            email : this.usuarioLogadoDados.email,
            perfilId : this.usuarioLogadoDados.profissional.perfilRaizId,
            identity : null,
            chamadoPor : "userDropDown",
            modo : "Alterar"
        }
    */
  },
}
</script>

<style scoped>
.senha {
  margin-top: 0px;
  padding: 0px;
  height: 500px;

  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.senhaOpcoes {
  width: 25%;
  
  margin-top: 0px;
  margin-bottom: 20px;
  padding: 0px;
  
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.senhaCampos {
  width: 25%;
  height: 50%;
}

.possuiSenha {
  margin-bottom: 20px;
}

.naoPossuiIdentity {
  margin-bottom: 20px;
}

.naoPossuiEmail {
  margin-bottom: 20px;
}

.ConfirmarSenha {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}

#conferenciaSenha {
  color: red;
  font-size: 1.2rem;
}

</style>
