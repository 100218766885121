const estruturaTabela = {
    id : 0, descricao : "", codCentroCusto : "",
    despesaCentroEntradaId : 0, despesaCentroSaidaId : 0,
    codMatTiss : 0, controlePorLote : "", calculoCoeficientes : ""
}

const state = {
    all : [],
    despesaTipoSelecionado : Object,
}

const getters = {
    getAllDespesaTipos(state) {
        return state.all;
    },

    getDespesaTipoSelecionado(state) {
        return state.despesaTipoSelecionado;
    },

    getStatusCadastro(state) {
        return state.statusCadastro;
    },

    getStatusNovoRegistro(state) {
        return state.statusNovoRegistro;
    }
}

const mutations = {
    setDespesaTipos(state, listaDespesaTipos) {
        state.all = listaDespesaTipos;
    },

    setDespesaTipoSelecionado(state, despesaTipo) {
        state.despesaTipoSelecionado = despesaTipo;
    },

    limparRegistroSelecionado(state) {
        state.despesaTipoSelecionado = {...estruturaTabela}
    },

    limparLista(state) {
        state.all = [];
    }
}

const actions = {
    setDespesaTipos({commit}, listaDespesaTipos) {
        commit("setDespesaTipos", listaDespesaTipos)
    },

    setDespesaTipoSelecionado({commit}, despesaTipo) {
        commit("setDespesaTipoSelecionado", despesaTipo)
    },

    limparRegistroSelecionado({commit}) {
        commit("limparRegistroSelecionado");
    },

    limparLista({commit}) {
        commit("limparLista");
    },

    formLeave({commit}) {
        commit('limparRegistroSelecionado');
        commit('limparLista');
    }
}

export default {
    namespaced : true,    
    state,
    getters,
    mutations,
    actions
}