<template>
  <div id="CadDespesaSimpro">
    <div class="cabecalho">
      <div class="coluna1">
        <button @click="retornar()" class="btn btn-sm btn-primary mr-10">
          <i class="fa fa-arrow-left" />
        </button>
      </div>
      <div class="coluna2">
        <span>Apresentação Simpro</span>
      </div>
      <div class="coluna3" />
    </div>

    <div class="container-fluid">
      <div class="row">
        <div class="form-group col-lg-3">
          <label for="Operadoras" class="control-label">Operadoras</label>
          <select
            id="Operadoras"
            class="form-control"
            selected
            :disabled="!liberarCampos"
            v-model="despesaSimproSelecionada.operadoraId"
          >
            <option
              v-for="operadora in operadoras"
              :key="operadora.id"
              :value="operadora.id"
            >
              {{ operadora.fantasia }}
            </option>
          </select>
        </div>
        <div class="form-group col-lg-2">
          <b-checkbox
            style="margin-top: 32px"
            :disabled="!liberarCampos"
            v-model="despesaSimproSelecionada.precoCongelado"
            >Preço Congelado</b-checkbox
          >
        </div>
      </div>
      <hr />

      <div class="row">
        <div class="form-group col-lg-7">
          <label for="apresentacaoSimpro" class="control-label"
            >Apresentação</label
          >
          <input
            id="apresentacaoSimpro"
            type="text"
            class="form-control"
            :disabled="!liberarCamposNaoAssinantes"
            v-model="despesaSimproSelecionada.apresentacao"
          />
        </div>
      </div>

      <div class="row">
        <div class="form-group col-lg-1">
          <label for="codTissSimpro" class="control-label">TISS</label>
          <input
            id="codTissSimpro"
            type="text"
            class="form-control"
            :disabled="!liberarCamposNaoAssinantes"
            v-model="despesaSimproSelecionada.codigoTiss"
          />
        </div>
        <div class="form-group col-lg-1">
          <label for="codTussSimpro" class="control-label">TUSS</label>
          <input
            id="codTussSimpro"
            type="text"
            class="form-control"
            :disabled="!liberarCamposNaoAssinantes"
            v-model="despesaSimproSelecionada.codigoTuss"
          />
        </div>
        <div class="form-group col-lg-1">
          <label for="ignorarCodigoTuss" class="control-label">Ignorar Tuss</label>
          <select
            id="ignorarCodigoTuss"
            class="form-control"
            :disabled="!liberarCamposNaoAssinantes"
            selected
            v-model="despesaSimproSelecionada.ignorarCodigoTuss"
          >
            <option
              v-for="resposta in sim_nao"
              :key="resposta.opcao"
              :value="resposta.opcao"
            >
              {{ resposta.valor }}
            </option>
          </select>
        </div>
        <div class="form-group col-lg-1">
          <label for="codMercado" class="control-label">Mercado</label>
          <select
            id="codMercado"
            class="form-control"
            selected :disabled="!liberarCamposNaoAssinantes"
            v-model="despesaSimproSelecionada.codigoMercado"
          >
            <option value="20">20-Material Hospitalar</option>
            <option value="50">50-Medicamento</option>
            <option value="90">90-Reagentes</option>
            <option value="30">30-Saneantes</option>
          </select>
        </div>
        <div class="form-group col-lg-1">
          <label for="produtoFracionado" class="control-label"
            >Fracionado</label
          >
          <select
            id="produtoFracionado"
            class="form-control"
            :disabled="!liberarCamposNaoAssinantes"
            v-model="despesaSimproSelecionada.produtoFracionado"
            selected
          >
            <option
              v-for="resposta in sim_nao"
              :key="resposta.opcao"
              :value="resposta.opcao"
            >
              {{ resposta.valor }}
            </option>
          </select>
        </div>
        <div class="form-group col-lg-1">
          <label for="identif" class="control-label">Identificação</label>
          <select id="identif" class="form-control" :disabled="!liberarCamposNaoAssinantes" selected v-model="despesaSimproSelecionada.identif">
            <option value="A">A</option>
            <option value="D">D</option>
            <option value="F">F</option>
            <option value="L">L</option>
            <option value="S">S</option>
            <option value="V">V</option>
          </select>
        </div>
        <div class="form-group col-lg-1">
          <label for="restritoHospitalar" class="control-label">RH</label>
          <select
            id="restritoHospitalar"
            class="form-control"
            :disabled="!liberarCamposNaoAssinantes"
            selected
            v-model="despesaSimproSelecionada.restritoHospitalar"
          >
            <option
              v-for="resposta in sim_nao"
              :key="resposta.opcao"
              :value="resposta.opcao"
            >
              {{ resposta.valor }}
            </option>
          </select>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-lg-1">
          <label for="precoSimpro" class="control-label">R$ Embalagem</label>
          <input
            id="precoSimpro"
            type="text"
            class="form-control"
            :disabled="!liberarCamposNaoAssinantes"
            v-model.number="precoEmbalagem"
          />
        </div>
        <div class="form-group col-lg-1">
          <label for="unidadeEmbalagemSimpro" class="control-label"
            >Unidade</label
          >
          <input
            id="unidadeEmbalagemSimpro"
            type="text"
            class="form-control"
            :disabled="!liberarCamposNaoAssinantes"
            v-model="despesaSimproSelecionada.unidadeEmbalagem"
          />
        </div>
        <div class="form-group col-lg-1">
          <label for="qtdeEmbalagemSimpro" class="control-label">Com</label>
          <input
            id="qtdeEmbalagemSimpro"
            type="text"
            class="form-control"
            :disabled="!liberarCamposNaoAssinantes"
            v-model.number="qtdeEmbalagem"
          />
        </div>
        <div class="form-group col-lg-1">
          <label for="precoSimproUnitario" class="control-label"
            >R$ Fração</label
          >
          <input
            id="precoSimproUnitario"
            type="text"
            class="form-control"
            disabled
            v-model.number="despesaSimproSelecionada.precoFracao"
          />
        </div>
        <div class="form-group col-lg-1">
          <label for="unidadeFracaoSimpro" class="control-label">Unidade</label>
          <input
            id="unidadeFracaoSimpro"
            type="text"
            class="form-control"
            :disabled="!liberarCamposNaoAssinantes"
            v-model="despesaSimproSelecionada.unidadeFracao"
          />
        </div>
        <div class="form-group">
          <label style="margin-top: 32px">X</label>
        </div>
        <div class="form-group col-lg-1">
          <label for="coefSimpro" class="control-label">Coeficiente</label>
          <input
            id="coefSimpro"
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            v-model.number="coef"
          />
        </div>
        <div class="form-group">
          <label style="margin-top: 32px">=</label>
        </div>
        <div class="form-group col-lg-1">
          <label for="precoSimproFat" class="control-label">R$ Final</label>
          <input
            id="precoSimproFat"
            type="text"
            class="form-control"
            disabled
            v-model.number="despesaSimproSelecionada.precoFaturado"
          />
        </div>

        <div class="form-group col-lg-1">
          <label for="unidadeConsumoSimpro" class="control-label"
            >Unidade</label
          >
          <input
            id="unidadeConsumoSimpro"
            type="text"
            class="form-control"
            :disabled="!liberarCamposNaoAssinantes"
            v-model="despesaSimproSelecionada.unidadeConsumo"
          />
        </div>
      </div>

      <hr />
      <p
        v-if="erro"
        style="background-color: red; color: white; text-align: center"
      >
        {{ erro }}
      </p>
      <div class="barraopcoescadastro">
        <button
          id="Alterar"
          :disabled="modo == 'Incluir' || modo == 'Alterar'"
          @click="alterarRegistro()"
          class="btn btn-sm btn-secondary mr-2"
        >
          Alterar
        </button>
        <button
          id="Cancelar"
          @click="cancelarOperacao()"
          class="btn btn-sm btn-secondary mr-2"
        >
          Cancelar
        </button>
        <button
          id="Gravar"
          :disabled="!liberarCampos"
          @click="gravarRegistro()"
          class="btn btn-sm btn-secondary mr-5"
        >
          Gravar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import errors from "@/utils/errors";
import constantes from "@/utils/constantes";
import operadoras from "@/mixins/operadoras";
import global from "@/mixins/global";

export default {
  mixins: [operadoras, global],
  computed: {
    ...mapGetters({
      despesaSelecionada: "despesas/getDespesaSelecionada",
      despesaSimproSelecionada: "despesasSimpro/getDespesaSimproSelecionada",
      usuarioLogadoDados: "global/getUsuarioLogadoDados",
      assinanteSimpro: "global/getAssinanteSimpro",
    }),
    liberarCampos() {
      return this.modo == "Incluir" || this.modo == "Alterar" ? true : false;
    },
    liberarCamposNaoAssinantes() {
      return this.assinanteSimpro != "S" && this.liberarCampos ? true : false;
    },
  },
  data() {
    return {
      modo: "",
      erro: "",

      coef: 0,
      precoEmbalagem: 0,
      qtdeEmbalagem: 0,
      precoFracao: 0,

      sim_nao: constantes.sim_nao,
    };
  },
  watch: {
    coef() {
      this.despesaSimproSelecionada.coef = this.coef;
      this.despesaSimproSelecionada.precoFaturado =
        this.despesaSimproSelecionada.precoFracao *
        this.despesaSimproSelecionada.coef;
    },
    precoEmbalagem() {
      this.despesaSimproSelecionada.precoEmbalagem = this.precoEmbalagem;
      this.precoFracao =
        this.despesaSimproSelecionada.precoEmbalagem /
        this.despesaSimproSelecionada.qtdeEmbalagem;
    },
    qtdeEmbalagem() {
      this.despesaSimproSelecionada.qtdeEmbalagem = this.qtdeEmbalagem;
      this.precoFracao =
        this.despesaSimproSelecionada.precoEmbalagem /
        this.despesaSimproSelecionada.qtdeEmbalagem;
    },
    precoFracao() {
      this.despesaSimproSelecionada.precoFracao = this.precoFracao;
      this.despesaSimproSelecionada.precoFaturado =
        this.despesaSimproSelecionada.precoFracao *
        this.despesaSimproSelecionada.coef;
    },
  },
  methods: {
    ...mapActions({
      formLeave: "despesasSimpro/formLeave",
    }),
    retornar() {
      this.$router.go(-1);
    },
    alterarRegistro() {
      this.modo = "Alterar";
    },
    incluirRegistro() {
      this.modo = "Incluir";
    },
    gravarRegistro() {
      if (!this.verificarCamposPreenchidos()) {
        return;
      }

      if (
        this.despesaSimproSelecionada.operadoraId == undefined ||
        this.despesaSimproSelecionada.operadoraId == 0
      ) {
        this.despesaSimproSelecionada.operadora = "Todos";
      } else {
        this.despesaSimproSelecionada.operadora = this.operadoras.find(
          (operadora) =>
            operadora.id == this.despesaSimproSelecionada.operadoraId
        ).fantasia;
      }

      if (this.modo == "Alterar") {
        this.despesaSimproSelecionada.operadorAlteracaoId = this.usuarioLogadoDados.profissionalId;

        this.$http
          .put(
            `/v1.0/despesasSimpro/${this.usuarioLogadoDados.tenant}/${this.despesaSimproSelecionada.id}`,
            this.despesaSimproSelecionada
          )
          .then(() => {
            this.erro = "";
            this.modo = "";
            this.$toasted.global.defaultSuccess();
          })
          .catch((error) => (this.erro = errors.tratarErros(error)));
      } else {
        this.despesaSimproSelecionada.despesaId = this.despesaSelecionada.id;
        this.despesaSimproSelecionada.operadorCadastroId = this.usuarioLogadoDados.profissionalId;

        this.$http
          .post(
            `/v1.0/despesasSimpro/${this.usuarioLogadoDados.tenant}`,
            this.despesaSimproSelecionada
          )
          .then(() => {
            this.erro = "";
            this.modo = "";
            this.$toasted.global.defaultSuccess();

            this.retornar();
          })
          .catch((error) => (this.erro = errors.tratarErros(error)));
      }
    },
    cancelarOperacao() {
      this.retornar();
    },
    verificarCamposPreenchidos() {
      if (this.despesaSimproSelecionada.apresentacao == null) {
        this.$toasted.show("Apresentação deve ser informado.", {
          type: "info",
          icon: "times",
        });

        return false;
      }

      if (this.despesaSimproSelecionada.codigoTiss == null) {
        this.$toasted.show("Código TISS deve ser informado.", {
          type: "info",
          icon: "times",
        });

        return false;
      }

      if (this.despesaSimproSelecionada.codigoMercado == null) {
        this.$toasted.show("Código Mercado deve ser informado.", {
          type: "info",
          icon: "times",
        });

        return false;
      }

      if (this.despesaSimproSelecionada.produtoFracionado == null) {
        this.$toasted.show("Fracionado deve ser selecionado.", {
          type: "info",
          icon: "times",
        });

        return false;
      }

      if (this.despesaSimproSelecionada.identif == null) {
        this.$toasted.show("Identif deve ser selecionado.", {
          type: "info",
          icon: "times",
        });

        return false;
      }

      if (this.despesaSimproSelecionada.precoEmbalagem <= 0) {
        this.$toasted.show("O preço R$ Embalagem deve ser informado.", {
          type: "info",
          icon: "times",
        });

        return false;
      }

      if (this.despesaSimproSelecionada.qtdeEmbalagem <= 0) {
        this.$toasted.show(
          "Campo 'Com' deve ser informado com a quantidade na Embalagem.",
          { type: "info", icon: "times" }
        );

        return false;
      }

      if (this.despesaSimproSelecionada.coef <= 0) {
        this.$toasted.show(
          "Campo 'Coef' deve ser informado para cálculo do preço Faturado.",
          { type: "info", icon: "times" }
        );

        return false;
      }

      return true;
    },
  },
  created() {
    this.showMenu(false);
    this.carregarOperadoras(this.usuarioLogadoDados.tenant, "S", 0);

    if (this.despesaSimproSelecionada.id == undefined) {
      this.despesaSimproSelecionada.operadoraId = 0;
      this.despesaSimproSelecionada.operadora = "";
      this.despesaSimproSelecionada.apresentacao = null;
      this.despesaSimproSelecionada.codigoTiss = null;
      this.despesaSimproSelecionada.codigoTuss = null;
      this.despesaSimproSelecionada.codigoMercaro = null;
      this.despesaSimproSelecionada.produtoFracionado = null;

      this.despesaSimproSelecionada.precoCongelado = "N";
      this.despesaSimproSelecionada.identif = null;
      this.despesaSimproSelecionada.restritoHospitalar = "N";
      this.despesaSimproSelecionada.coef = 0;
      this.despesaSimproSelecionada.precoEmbalagem = 0;
      this.despesaSimproSelecionada.qtdeEmbalagem = 0;
      this.despesaSimproSelecionada.precoFracao = 0;

      this.incluirRegistro();
    }

    this.coef = this.despesaSimproSelecionada.coef;
    this.precoEmbalagem = this.despesaSimproSelecionada.precoEmbalagem;
    this.qtdeEmbalagem = this.despesaSimproSelecionada.qtdeEmbalagem;
    this.precoFracao = this.despesaSimproSelecionada.precoFracao;
  },
};
</script>

<style scoped>
label {
  font-weight: 100%;
  font-size: 0.8rem;
  margin-top: 3px;
  margin-bottom: 1px;
}
</style>
