import errors from "@/utils/errors";

export default {
    data() {
        return {
        }
    },
    methods : {
        async carregarRecebimentoParcelas(tenant, recebimentoId) {
            this.loadingRecebimentoParcelas = true;
            this.loadedRecebimentoParcelas = false;
            
            await this.$http.get(`/v1.0/recebimentoParcelas/${tenant}/Recebimento/${recebimentoId}`)
            .then(resp => {
                this.recebimentoParcelas = resp.data;
                this.erro = "";
            })
            .catch(error => this.erro = errors.tratarErros(error)) 
            .finally(() => {
                this.loadingRecebimentoParcelas = false;
                this.loadedRecebimentoParcelas = true;
            })
        }
    }
}
