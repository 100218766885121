<template>
  <div>
    <div class="cabecalho">
      <div class="coluna1">
        <button @click="retornar" class="btn btn-sm btn-primary mr-10"><i class="fa fa-arrow-left"/></button>
      </div>
      <div class="coluna2">
        <span>Agenda de Atendimentos</span>
      </div>
      <div class="coluna3"/>
    </div>

    <div class="container-fluid">
        <div class="row" v-if="pacienteSelecionado">
            <div class="col-lg-12">
                <div class="row">
                    <div class="col-lg-12">
                        <p class="agendamentoPendente">Agendamento Pendente para: {{pacienteSelecionado.paciente}}</p>
                    </div>
                </div>
                <div class="row" v-if="pacienteModulo=='Agenda'">
                    <div class="col-lg-12">
                        <hr style="margin:0px"/>
                        <div class="row">
                          <div class="form-group col-lg-2">
                            <label for="OperadoraAgendamento" class="control-label">Operadora</label>
                            <select
                              class="form-control"
                              id="OperadoraAgendamento"
                              name="Ativo"
                              selected
                              v-model="operadoraId"
                            >
                              <option
                                v-for="operadora in operadoras"
                                :key="operadora.id"
                                :value="operadora.id"
                              >{{ operadora.fantasia }}</option>
                            </select>
                          </div>
                          <div class="form-group col-lg-2">
                            <label for="PlanoSaudeAgendamento" class="control-label">Plano</label>
                            <select
                              class="form-control"
                              :disabled="operadoraId==0"
                              id="PlanoSaudeAgendamento"
                              name="Ativo"
                              selected
                              v-model="planoSaudeId"
                              @change="selecionouPlano()"
                            >
                              <option
                                v-for="planosaude in planosSaude"
                                :key="planosaude.id"
                                :value="planosaude.id"
                              >{{ planosaude.plano }}</option>
                            </select>
                          </div>
                          <template v-if="pacienteOperadora.pacienteId!=undefined">
                            <div class="form-group col-lg-3">
                              <label for="OperadoraTitular" class="control-label">Titular</label>
                              <input type="text" id="OperadoraTitular" readonly v-model="pacienteOperadora.titular" class="form-control"/>
                            </div>
                            <div class="form-group col-lg-2">
                              <label for="OperadoraCarteirinha" class="control-label">Carteirinha</label>
                              <input type="text" id="OperadoraCarteirinha" readonly v-model="pacienteOperadora.carteirinha" class="form-control"/>
                            </div>
                            <div class="form-group col-lg-2">
                              <label for="OperadoraDatValidade" class="control-label">Validade</label>
                              <input type="date" id="OperadoraDatValidade" readonly v-model="pacienteOperadora.datValidade" class="form-control"/>
                            </div>
                          </template>
                        </div>
                        <div v-if="pOProfissionais.length>0" style="display:flex; flex-direction:row; justify-content:center">
                          <b-spinner v-if="loading" label="carregando..."/>
                          
                          <b-table v-if="!loading" hover striped small sort-icon-left 
                            :items="pOProfissionais" :fields="camposTabelaPOProfissionais"
                            >
                            <template v-slot:cell(datPriVisita)="datPriVisita">
                                {{ datPriVisita.value | formatDate}}
                            </template>    
                            <template v-slot:cell(datUltVisita)="datUltVisita">
                                {{ datUltVisita.value | formatDate}}
                            </template>    
                            <template v-slot:cell(datUltConsulta)="datUltConsulta">
                                {{ datUltConsulta.value | formatDate}}
                            </template>    
                            <template v-slot:cell(datRetorno)="datRetorno">
                                {{ datRetorno.value | formatDate}}
                            </template>    
                            <template v-slot:cell(faltouUltimaVisita)="faltouUltimaVisita">
                                {{faltouUltimaVisita.value = faltouUltimaVisita.value == 'S' ? 'Sim' : ''}}
                            </template>
                          </b-table>
                        </div>
                        <hr style="margin:0px"/>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-2 flex-center">
                <label for="selecionarData" class="control-label" style="margin-bottom: 0px">Data</label>
                <input type="date" id="selecionarData" class="form-control" v-model="datInicial" @dblclick="dataAtual()"/>
            </div>
            <div class="col-lg-2">
                <label for="selecionarProfissional" class="control-label" style="margin-bottom: 0px">Profissional</label>
                <select id="selecionarProfissional" class="form-control" v-model="profissionalId">
                    <option v-for="profissional in profissionais" :key="profissional.id" :value="profissional.id">
                        {{profissional.nome}}
                    </option>
                </select>
            </div>
            <div class="col-lg-2">
                <label for="selecionarUnidade" class="control-label" style="margin-bottom: 0px">Unidade</label>
                <select id="selecionarUnidade" class="form-control" v-model="unidadeId" selected>
                    <option v-for="unidade in unidades" :key="unidade.id" :value="unidade.id">
                        {{unidade.descricao}}
                    </option>
                </select>
            </div>
            <div class="col-lg-2">
                <label for="FiltrarDados" id="labelFiltrarDados" class="control-label" style="margin-bottom: 0px">Filtrar</label>
                <input id="FiltrarDados" type="search" class="form-control" placeholder="Digite para Filtrar..." v-model="filter"/>
            </div>
            <div class="col-lg-3">
                <!-- opções de Horário e Agendamento -->
                <b-button id="btnImprimir" class="mr-1" @click="imprimirAgenda()" variant="info" 
                    v-b-tooltip.hover title="Imprimir Agenda" size="sm" style="margin-top:27px;">
                    <i class="fa fa-print"/>
                </b-button>
                <b-button id="btnRefresh" class="mr-1" @click="refresh()" variant="info" 
                    v-b-tooltip.hover title="Atualizar Agenda (Refresh)" size="sm" style="margin-top:27px;">
                    <i class="fa fa-refresh"/>
                </b-button>
                <b-button id="btnAgendar" class="mr-1" :disabled="true" @click="buscarPacienteParaAgendamento()" variant="warning" 
                    v-b-tooltip.hover title="Agendar Paciente" size="sm" style="margin-top:27px;">
                    <i class="fa fa-table"/>
                </b-button>
                <b-button id="btnIncluirHorario" v-if="selecionouProfissional && !incluirHorario" @click="incluirHorario=true" 
                    v-b-tooltip.hover title="Incluir Novo Horário na Agenda" variant="warning" size="sm" class="mr-1" style="margin-top:27px;">
                    <i class="fa fa-plus-square"/>
                </b-button>
                <b-button id="btnExcluirHorario" v-if="liberar_excluirHorario" @click="excluirHorario()" 
                    v-b-tooltip.hover title="Excluir Horário Livre da Agenda" variant="danger" size="sm" class="mr-1" style="margin-top:27px;">
                    <i class="fa fa-minus-square"/>
                </b-button>
                <b-button id="btnCancelarOperacao" v-if="operacao!=''" @click="cancelarOperacao()" 
                    v-b-tooltip.hover title="Cancelar Operação em Andamento" variant="danger" size="sm" class="mr-1" style="margin-top:27px;">
                    <i class="fa fa-times"/>
                </b-button>
                <template v-if="operacao==''">
                    <b-button id="btnBloqueioAgenda"  @click="prepararBloqueio('b')" 
                        v-b-tooltip.hover title="Bloqueio Horários" variant="danger" size="sm" class="mr-1" style="margin-top:27px;">
                        <i class="fa fa-lock"/>
                    </b-button>
                    <b-button id="btnDesbloqueioAgenda" @click="prepararBloqueio('d')" 
                        v-b-tooltip.hover title="Desbloqueio Horários" variant="success" size="sm" class="mr-1" style="margin-top:27px;">
                        <i class="fa fa-unlock"/>
                    </b-button>
                    <template v-if="reagendamentos.length==0">
                        <b-button id="btnReagendamentos" @click="carregarReagendamentos()" 
                            v-b-tooltip.hover title="Exibir Reagendamentos" variant="info" size="sm" class="mr-1" style="margin-top:27px;">
                            <i class="fa fa-pencil"/>
                        </b-button>
                    </template>
                    <template v-else>
                        <b-button id="btnOcultarReagendamentos" @click="ocultarReagendamentos()" 
                            v-b-tooltip.hover title="Ocultar Reagendamentos" variant="warning" size="sm" class="mr-1" style="margin-top:27px;">
                            <i class="fa fa-pencil"/>
                        </b-button>
                    </template>
                </template>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-12">
                <hr/>
                <p class="barraInformacoes">{{informacao}}</p>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-12">
                <!-- <hr style="margin-top:2px;margin-bottom:2px;"/> -->
                <hr/>
                <div class="row barraBotoes">
                    <!-- opções de Paciente -->
                    <span style="margin-right:10px">Registros -></span>
                    <b-button id="btnPaciente" v-if="liberar_existePaciente" variant="info" 
                        v-b-tooltip.hover title="Cadastro do(a) Paciente" size="sm" @click="cadastroPaciente()">
                        <i class="fa fa-user"/>
                    </b-button>
                    <b-button id="btnProntuario" v-if="liberar_existePaciente" variant="info" 
                        v-b-tooltip.hover title="Prontuário do(a) Paciente" size="sm" class="ml-1" @click="prontuarioPaciente()">
                        <i class="fa fa-stethoscope"/>
                    </b-button>
                    <b-button v-if="liberar_existePaciente" variant="info" 
                       v-b-tooltip.hover title="Atendimentos do(a) Paciente" class='ml-1' size="sm" @click="atendimentosPaciente()">
                      <i class="fa fa-calendar-check-o"/>
                    </b-button>
                    <b-button v-if="liberar_existePaciente" variant="info" 
                       v-b-tooltip.hover title="Controle de Atendimentos" class='ml-1' size="sm" @click="controleAtendimentosPaciente()">
                      <i class="fa fa-cog"/>
                    </b-button>
                    <b-button v-if="liberar_existePaciente" variant="info" 
                       v-b-tooltip.hover title="Lançamentos do Paciente" class='ml-1' size="sm" @click="lancamentosPaciente()">
                      <i class="fa fa-list-ul"/>
                    </b-button>

                    <b-button v-if="liberar_existePaciente" variant="info" 
                       v-b-tooltip.hover title="Recebimentos do Paciente" class='ml-1' size="sm" @click="recebimentosPaciente()">
                      <i class="fa fa-money"/>
                    </b-button>

                    <!-- opções de Status -->
                    <span style="margin-left:10px;margin-right:10px">Status -></span>
                    <b-button id="btnStatusEspera" v-if="liberar_btnStatusEspera" 
                        v-b-tooltip.hover title="Colocar Paciente em Espera" variant="danger" size="sm" @click="alterarStatus('E')">
                        E
                    </b-button>
                    <b-button id="btnStatusCancelarEspera" v-if="liberar_btnStatusCancelarEspera" 
                        v-b-tooltip.hover title="Cancelar Espera" size="sm" class="ml-1" @click="alterarStatus(null)">
                        CE
                    </b-button>
                    <b-button id="btnStatusLimpar" v-if="liberar_btnStatusLimpar" 
                        v-b-tooltip.hover title="Limpar Status" size="sm" class="ml-1" @click="alterarStatus('LS')">
                        LS
                    </b-button>
                    <b-button id="btnStatusHabilitado" v-if="liberar_btnStatusHabilitado" 
                        v-b-tooltip.hover title="Habilitado para Atendimento" variant="primary" size="sm" class="ml-1" @click="alterarStatus('H')">
                        H
                    </b-button>
                    <b-button id="btnStatusConsultorio" v-if="liberar_btnStatusConsultorio" 
                        v-b-tooltip.hover title="Paciente em Atendimento" variant="success" size="sm" class="ml-1" @click="alterarStatus('C')">
                        C
                    </b-button>
                    <b-button id="btnStatusAtendido"  v-if="liberar_btnStatusAtendido" 
                        v-b-tooltip.hover title="Paciente saiu do Atendimento" variant="dark" size="sm" class="ml-1" @click="alterarStatus('A')">
                        A
                    </b-button>
                    <!-- <b-button id="btnStatusFoiEmbora" v-if="liberar_btnStatusFoiEmbora" 
                        v-b-tooltip.hover title="Paciente foi Embora" variant="dark" size="sm" class="ml-1" @click="alterarStatus('*')">
                        *
                    </b-button> -->
                    <b-button id="btnStatusDesistencia" v-if="liberar_btnStatusDesistencia" 
                        v-b-tooltip.hover title="Paciente Desistiu do Atendimento" variant="info" size="sm" class="ml-1" @click="alterarStatus('S')">
                        S
                    </b-button>
                    <b-button id="btnStatusFaltou" v-if="liberar_btnStatusFaltou" 
                        v-b-tooltip.hover title="Paciente Faltou" variant="info" size="sm" class="ml-1" @click="alterarStatus('F')">
                        F
                    </b-button>
                    <b-button id="btnStatusDesmarcou" v-if="liberar_btnStatusDesmarcou" 
                        v-b-tooltip.hover title="Paciente Desmarcou Atendimento" variant="info" size="sm" class="ml-1" @click="alterarStatus('D')">
                        D
                    </b-button>
                    <b-button id="btnStatusProfDesmarcou" v-if="liberar_btnStatusProfDesmarcou" 
                        v-b-tooltip.hover title="Profissional Desmarcou Atendimento" variant="info" size="sm" class="ml-1" @click="alterarStatus('#')">
                        #
                    </b-button>
                    <b-button id="btnStatusFaltouAvisou" v-if="liberar_btnStatusFaltouAvisou" 
                        v-b-tooltip.hover title="Paciente Faltou Avisando" variant="info" size="sm" class="ml-1" @click="alterarStatus('X')">
                        X
                    </b-button>
                    <b-button id="btnStatusFaltouNC" v-if="liberar_btnStatusFaltouNC" 
                        v-b-tooltip.hover title="Falta Não Cobrada" variant="info" size="sm" class="ml-1" @click="alterarStatus('Ø')">
                        Ø
                    </b-button>
                    <b-button id="btnStatusProcedimento" v-if="liberar_btnStatusProcedimento" 
                        v-b-tooltip.hover title="Paciente em Procedimento" size="sm" class="ml-1" @click="alterarStatus('P')">
                        P
                    </b-button>
                    <b-button id="btnStatusRX" v-if="liberar_btnStatusRX" 
                        v-b-tooltip.hover title="Paciente em RX" size="sm" class="ml-1" @click="alterarStatus('R')">
                        R
                    </b-button>
                    <!-- Opções de Operações Diversas  -->
                    <span style="margin-left:10px;margin-right:10px">Operações -></span>
                    <b-button id="btnEditarAgendamento" variant="warning" 
                        v-b-tooltip.hover title="Editar Agendamento" size="sm" @click="editarAgendamento()" class="mr-1">
                        <i class="fa fa-pencil"/>
                    </b-button>
                    <b-button id="btnObservacao" v-if="agendaAtendimentoSelecionada.id!=null" variant="primary" 
                        v-b-tooltip.hover title="Observação de Agenda" size="sm" @click="alterarObservacaoAgenda()" class="mr-1">
                        <i class="fa fa-comments"/>
                    </b-button>
                    <b-button id="btnCancelarAgendamento" v-if="liberar_btnStatusEspera" variant="danger" 
                        v-b-tooltip.hover title="Cancelar Agendamento" size="sm" @click="cancelarAgendamentoConfirmacao()">
                        CA
                    </b-button>
                    <b-button id="btnAlterarDataHora" v-if="liberar_btnStatusEspera" variant="info" 
                        v-b-tooltip.hover title="Alterar Data e Hora do Atendimento" size="sm" class="ml-1" @click="alterarDataHoraPreparar()">
                        DH
                    </b-button>
                    <b-button id="btnAlterarReagendar" v-if="!liberar_btnStatusEspera && agendaAtendimentoSelecionada.pacienteId!=null" variant="info" 
                        v-b-tooltip.hover title="Reagendar Paciente" size="sm" class="ml-1" @click="reagendarPreparar()">
                        RA
                    </b-button>
                    <b-button id="btnConfirmar" v-if="liberar_btnStatusEspera" variant="info" 
                        v-b-tooltip.hover title="Confirmação de Presença" size="sm" class="ml-1" @click="confirmarPresenca()">
                        <i v-if="agendaAtendimentoSelecionada.confirmado!='S'" class="fa fa-thumbs-up"/>
                        <i v-else class="fa fa-thumbs-down"/>
                    </b-button>
                    <b-button id="btnCadeadoFechado" v-if="liberar_btnCadeadoFechado" variant="primary" 
                        v-b-tooltip.hover title="Início Replica Agendamento Sessão" size="sm" class="ml-1" @click="fecharCadeadoSessao()">
                        <i class="fa fa-lock"/>
                    </b-button>
                    <b-button id="btnCadeadoAberto" v-if="liberar_btnCadeadoAberto" variant="primary" 
                        v-b-tooltip.hover title="Fim Replica Agendamento Sessão" size="sm" class="ml-1" @click="abrirCadeadoSessao()">
                        <i class="fa fa-unlock"/>
                    </b-button>
                </div>
            </div>
        </div>
        <!-- Bloqueio de Horario -->
        <div class="registro" style="margin-top:10px; margin-bottom:10px;" v-if="bloqueioOperacao != ''">
            <div class="row">
                <div class="form-group col-lg-2 ml-2">
                    <label for="datInicial" class="control-label" style="margin-bottom: 0px">Data Inicial</label>
                    <input type="date" id="datInicial" class="form-control" v-model="bloqueioRegistro.datInicial"/>
                </div>
                <div class="form-group col-lg-2 ml-2">
                    <label for="datFinal" class="control-label" style="margin-bottom: 0px">Data Final</label>
                    <input type="date" id="datFinal" class="form-control" v-model="bloqueioRegistro.datFinal"/>
                </div>
                <div class="form-group col-lg-1 ml-2">
                    <label for="horaInicial" class="control-label" style="margin-bottom: 0px">Hora Inicial</label>
                    <input type="time" id="horaInicial" class="form-control" v-model="bloqueioRegistro.horaInicial"/>
                </div>
                <div class="form-group col-lg-1 ml-2">
                    <label for="horaFinal" class="control-label" style="margin-bottom: 0px">Hora Final</label>
                    <input type="time" id="horaFinal" class="form-control" v-model="bloqueioRegistro.horaFinal"/>
                </div>
                <div v-if="bloqueioOperacao=='b'" class="form-group col-lg-2 ml-2">
                    <label for="bloqueio" class="control-label" style="margin-bottom: 0px">Bloqueio</label>
                    <input type="text" id="bloqueio" class="form-control" v-model="bloqueioRegistro.bloqueio"/>
                </div>
            </div>
            <div class="row" style="justify-content:center;" >
                <button class="btn btn-warning btn-sm mr-2" @click="bloqueioOperacao=''">Cancelar</button>
                <button class="btn btn-info btn-sm" @click="bloqueioAgenda()">Confirmar</button>
            </div>
        </div>
        <!-- Adicionar Horários - Início -->
        <div class="registro" style="margin-top:10px; margin-bottom:10px;" v-if="incluirHorario">
           <div class="incluirNovosHorarios">
                <div class="form-group col-lg-2 ml-2">
                  <label for="destinado" class="control-label">Tipo Atendimento</label>
                  <select id="destinado" class="form-control" selected v-model="novosHorarios.destinado">
                    <option
                      v-for="resposta in opcoesDestinados"
                      :key="resposta.opcao"
                      :value="resposta.opcao"
                    >{{resposta.valor}}</option>
                  </select>
                </div>
                <div class="col-lg-2">
                    <label for="HoraInicial" class="control-label">Horário Inicial</label>
                    <input id="HoraInicial" type="time" v-model="novosHorarios.horarioInicial" class="form-control"/>
                </div>
                <div class="col-lg-2">
                    <label for="duracao" class="control-label">Duração</label>
                    <input id="duracao" type="time" v-model="novosHorarios.duracao" class="form-control"/>
                </div>
                <div class="col-lg-2">
                    <label for="HoraFinal" class="control-label">Horário Final</label>
                    <input id="HoraFinal" type="time" v-model="novosHorarios.horarioFinal" class="form-control"/>
                </div>
                <div class="form-group col-lg-1">
                  <label for="Encaixe" class="control-label">Encaixe</label>
                  <select
                    class="form-control"
                    id="Encaixe"
                    name="Encaixe"
                    selected
                    v-model="novosHorarios.encaixe"
                  >
                    <option
                      v-for="confirma in sim_nao"
                      :key="confirma.opcao"
                      :value="confirma.opcao"
                    >{{ confirma.valor }}</option>
                  </select>
                </div>
            </div>
            <div class="incluirNovosHorarios">
                <div class="form-group col-lg-2 ml-2">
                  <label for="Preferencial" class="control-label">1º Prefer.</label>
                  <select
                    class="form-control"
                    id="Preferencial"
                    name="Preferencial"
                    selected
                    v-model="novosHorarios.preferencial"
                  >
                    <option
                      v-for="servico in servicos"
                      :key="servico.siglaServico"
                      :value="servico.siglaServico"
                    >{{ servico.siglaServico }} - {{ servico.descricao }}</option>
                  </select>
                </div>
                <div class="form-group col-lg-2">
                  <label for="Preferencial2" class="control-label">2º Prefer.</label>
                  <select
                    class="form-control"
                    id="Preferencial2"
                    name="Preferencial2"
                    selected
                    v-model="novosHorarios.preferencial2"
                  >
                    <option
                      v-for="servico in servicos"
                      :key="servico.siglaServico"
                      :value="servico.siglaServico"
                    >{{ servico.siglaServico }} - {{ servico.descricao }}</option>
                  </select>
                </div>
                <div class="form-group col-lg-2">
                  <label for="Preferencial3" class="control-label">3º Prefer.</label>
                  <select
                    class="form-control"
                    id="Preferencial3"
                    name="Preferencial3"
                    selected
                    v-model="novosHorarios.preferencial3"
                  >
                    <option
                      v-for="servico in servicos"
                      :key="servico.siglaServico"
                      :value="servico.siglaServico"
                    >{{ servico.siglaServico }} - {{ servico.descricao }}</option>
                  </select>
                </div>
            </div>
            <div class="row" style="justify-content:center;" >
                <button class="btn btn-warning btn-sm mr-2" @click="cancelarGerarHorarios()">Cancelar</button>
                <button class="btn btn-info btn-sm" @click="gerarHorarios()">Gerar Horários</button>
            </div>
        </div>
        <!-- Adicionar Horários - Fim -->

        <template v-if="this.erro!=''">
          <p class="msgError">Erro durante Operação: {{ this.erro }}</p>
        </template>

        <!-- Reagendamentos - Inicio -->
        <div v-if="reagendamentos.length>0" style="display:flex; flex-direction:row; justify-content:center">
            <b-spinner v-if="loadingReagendamentos" label="carregando..."/>

            <b-table v-if="loadedReagendamentos" hover striped small sort-icon-left
                :items="reagendamentos" :fields="camposTabelaReagendamentos" >
                <template v-slot:cell(actions)="row">
                  <b-button variant="warning" size="sm" class='mr-1' @click="reagendarPaciente(row.item)">
                    <i class="fa fa-table"/>
                  </b-button>
                  <b-button variant="danger" size="sm" class='mr-1' @click="excluirReagendamento(row.item)">
                    <i class="fa fa-trash"/>
                  </b-button>
                </template>

                <template v-slot:cell(data)="data">
                    {{ data.value | formatDate}}
                </template>    
            </b-table>
        </div>
        <!-- Reagendamentos - Fim -->

        <div style="display:flex; flex-direction:row; justify-content:center; max-height: 450px; max-width: 100%; overflow: auto">
          <b-spinner v-if="loading" label="carregando..."/>
          
          <template v-if="mobile">
            <b-table v-if="loaded" responsive=true hover striped small sort-icon-left 
              :items="agendaAtendimentos" :fields="camposTabelaMobile"
              :filter="filter" :filterIncludedFields="filterOn" @filtered="onFiltered"
              @row-clicked="selecionouAgendaAtendimento"
              @row-dblclicked="duploClique"
              >
              <template v-slot:cell(destinado)="destinado">
                  {{ destinado.value == "C" ? "Cons" : destinado.value == "S" ? "Sessão" : "Mensal"}}
              </template> 
              <template v-slot:cell(status)="status">
                  {{ status.value = status.value != "" ? status.value : "" }}
              </template>
            </b-table>
          </template>
          <template v-else>
            <template v-if="corpoMedico">
                <b-table v-if="loaded" responsive=true hover striped small sort-icon-left 
                  :items="agendaAtendimentos" :fields="camposTabelaCorpoMedico"
                  :filter="filter" :filterIncludedFields="filterOn" @filtered="onFiltered"
                  @row-clicked="selecionouAgendaAtendimento"
                  @row-dblclicked="duploClique"
                  >
                  <template v-slot:cell(destinado)="destinado">
                      {{ destinado.value == "C" ? "Cons" : destinado.value == "S" ? "Sessão" : "Mensal"}}
                  </template> 
                  <template v-slot:cell(status)="status">
                      {{ status.value = status.value != "" ? status.value : "" }}
                  </template>
                  <template v-slot:cell(pendente)="pendente">
                      {{ pendente.value | formatValor }}
                  </template>
                  <template v-slot:cell(recebido)="recebido">
                      {{ recebido.value | formatValor }}
                  </template>
                </b-table>
            </template>
            <template v-else>
                <b-table v-if="loaded" responsive=true hover striped small sort-icon-left 
                  :items="agendaAtendimentos" :fields="camposTabela"
                  :filter="filter" :filterIncludedFields="filterOn" @filtered="onFiltered"
                  @row-clicked="selecionouAgendaAtendimento"
                  @row-dblclicked="duploClique"
                  >
                  <template v-slot:cell(destinado)="destinado">
                      {{ destinado.value == "C" ? "Cons" : destinado.value == "S" ? "Sessão" : "Mensal"}}
                  </template> 
                  <template v-slot:cell(status)="status">
                      {{ status.value = status.value != "" ? status.value : "" }}
                  </template>
                  <template v-slot:cell(pendente)="pendente">
                      {{ pendente.value | formatValor }}
                  </template>
                  <template v-slot:cell(recebido)="recebido">
                      {{ recebido.value | formatValor }}
                  </template>
                </b-table>
            </template>
          </template>
        </div>  
    </div>
    <div class="Mensagem">
      <b-modal id="modal-mensagem" centered title="DoctorOn">
        <p class="my-4">{{mensagem}}</p>
      </b-modal>
    </div>
  </div>
</template>

<script>
import configs from '@/mixins/configs';
import agendaAtendimentos from "@/mixins/agendaAtendimentos";
import profissionais from "@/mixins/profissionais";
import servicos from "@/mixins/servicos";
import unidades from "@/mixins/unidades";
import operadoras from "@/mixins/operadoras";
import planosSaude from "@/mixins/planosSaude";
import pacienteOperadoras from "@/mixins/pacienteOperadoras"
import pOProfissionais from "@/mixins/pOProfissionais"
import global from "@/mixins/global";
import pacientes from "@/mixins/pacientes";
import reagendar from "@/mixins/reagendar";
import constantes from "@/utils/constantes";
import { mapGetters, mapActions } from "vuex";
import funcoes from "@/utils/funcoes";
import errors from "@/utils/errors";

export default {
    mixins : [configs, profissionais, unidades, global, servicos, agendaAtendimentos, reagendar, 
              operadoras, planosSaude, pacienteOperadoras, pOProfissionais, pacientes],
    data() {
        return {
            mensagem : "",

            datInicial: null,
            datFinal: null,
            selecionouProfissional : false,
            profissionalId : 0,
            unidadeId : 0,

            operadoraId : 0,
            planoSaudeId : 0,

            erro : "",
            cadeadoFechado : false,

            retornando : false,
            editandoAgendamento : false,

            bloqueioOperacao : "",
            bloqueioRegistro : {profissionalId : 0, datInicial : null, datFinal : null, horaInicial : "", horaFinal : "", bloqueio : ""},

            filter : "",
            filterOn : [],
            //filterOn : ['horario', 'preferencial', 'paciente.nome'], -- especificar os campos mas não funcionou com paciente.nome

            loading : false,
            loaded : false,

            loadingReagendamentos : false,
            loadedReagendamentos : false,

            mobile : false,
            corpoMedico : false,
            incluirHorario: false,
            novosHorarios : {horarioInicial : "", horarioFinal : "", duracao : "", encaixe : "", 
                             preferencial : "", preferencial2 : "", preferencial3 : "", destinado : ""},
            informacao: "Barra de Informações",
            sim_nao : constantes.sim_nao,
            pacienteOperadora : {},
            pOProfissional : {},
            agendaAtendimentoSelecionada : {},
            agendaAnterior : {},

            pacienteOperadoras : [],

            camposTabela : [
                //{key : 'id', label : 'Id', sortable : true},
                {key : 'horario', label : 'Horário', sortable : true},
                {key : 'destinado', label : 'Para', sortable : true},
                {key : 'preferencial', label : 'Pref', sortable : true},
                //{key : 'preferencial2', label : 'Pref2', sortable : true},
                //{key : 'preferencial3', label : 'Pref3', sortable : true},
                {key : 'atendimento', label : 'Tipo', sortable : true},
                {key : 'procedimento', label : 'Proc', sortable : true},
                {key : 'paciente', label: 'Paciente', sortable : true},
                {key : 'pacienteId', label: 'Pront', sortable : true},
                {key : 'celularDdd', label: 'DDD'},
                {key : 'celular', label: 'Celular'},
                {key : 'observacao', label: 'Observação', sortable : true},
                {key : 'bloqueioAgenda', label: 'Bloqueio', sortable : true},
                {key : 'confirmado', label: 'Conf', sortable : true},
                {key : 'status', label : 'Status'},
                {key : 'horaChegada', label : 'Chegada', sortable: true},
                {key : 'horaAtendimento', label : 'Atend'},
                {key : 'horaSaida', label : 'Saída'},
                {key : 'horaChegada', label : 'Chegada'},
                {key : 'operadora', label: 'Operadora', sortable : true},
                //{key : 'planoSaude.plano', label: 'Plano', sortable : true},
                {key : 'pendente', label : 'Pendente'},
                {key : 'recebido', label : 'Recebido'},
            ],
            
            camposTabelaMobile : [
                {key : 'horario', label : 'Horário', sortable : true},
                {key : 'destinado', label : 'Para', sortable : true},
                {key : 'preferencial', label : 'Pref', sortable : true},
                {key : 'atendimento', label : 'Tipo', sortable : true},
                {key : 'paciente', label: 'Paciente', sortable : true},
                {key : 'pacienteId', label: 'Pront', sortable : true},
                {key : 'celularDdd', label: 'DDD'},
                {key : 'celular', label: 'Celular'},
                {key : 'confirmado', label: 'Conf', sortable : true},
                {key : 'status', label : 'Status'},
                {key : 'operadora', label: 'Operadora', sortable : true},
            ],

            camposTabelaCorpoMedico : [
                {key : 'horario', label : 'Horário', sortable : true},
                {key : 'destinado', label : 'Para', sortable : true},
                {key : 'preferencial', label : 'Pref', sortable : true},
                {key : 'atendimento', label : 'Tipo', sortable : true},
                {key : 'paciente', label: 'Paciente', sortable : true},
                {key : 'pacienteId', label: 'Pront', sortable : true},
                {key : 'celularDdd', label: 'DDD'},
                {key : 'celular', label: 'Celular'},
                {key : 'bloqueioAgenda', label: 'Bloqueio', sortable : true},
                {key : 'confirmado', label: 'Conf', sortable : true},
                {key : 'status', label : 'Status'},
                {key : 'horaChegada', label : 'Chegada', sortable: true},
                {key : 'horaAtendimento', label : 'Atend'},
                {key : 'horaSaida', label : 'Saída'},
                {key : 'operadora', label: 'Operadora', sortable : true},
            ],

            camposTabelaPOProfissionais : [
                {key : 'profissional', label : 'Profissional', sortable : true},
                {key : 'cbos', label : 'CBOS', sortable : true},
                {key : 'atendimento', label : 'Tipo', sortable : true},
                {key : 'datPriVisita', label : 'Prim. Visita', sortable : true},
                {key : 'datUltVisita', label : 'Últ. Visita', sortable : true},
                {key : 'datUltConsulta', label : 'Últ. Consulta', sortable : true},
                {key : 'datRetorno', label : 'Retorno até', sortable : true},
                {key : 'faltouUltimaVisita', label : 'Faltou'},
            ],

            camposTabelaReagendamentos : [
                {key : 'data', label : 'Data'},
                {key : 'horario', label : 'Horário'},
                {key : 'paciente', label : 'Paciente'},
                {key : 'operadora', label : 'Operadora'},
                {key : 'atendimento', label : 'Atendimento'},
                {key : 'observacao', label : 'Observação'},
                {key : 'actions', label: 'Ações'}
            ]
        }
    },
    watch: {
        unidadeId() {
            this.reagendamentos = [];
            this.buscarAgenda()
        },
        profissionalId() {
            this.reagendamentos = [];
            this.buscarAgenda()
        },
        datInicial() {
            this.buscarAgenda()
        },
        operadoraId() {
          this.carregarPlanosSaude(this.usuarioLogadoDados.tenant,this.operadoraId, 'S');
        },
        async pacienteOperadora() {
          if (this.pacienteOperadora.id != undefined) {
              await this.carregarPOProfissionais(this.usuarioLogadoDados.tenant, this.pacienteOperadora.id);
          }
        }
    },
    computed : {
        ...mapGetters({
            usuarioLogadoDados : "global/getUsuarioLogadoDados",
            pacienteSelecionado : "pacientes/getPacienteSelecionado",
            pacienteModulo : "pacientes/getModulo",
            operacao : "agendaAtendimentos/getOperacao",
            agenda : "agendaAtendimentos/getAgenda",
            ultimaBusca : "agendaAtendimentos/getUltimaBusca",
        }),
        opcoesDestinados: function() {
          return constantes.destinados.filter(function(u) {
            return u.opcao != "T";
          });
        },
        liberar_existePaciente() {
            return this.agendaAtendimentoSelecionada.pacienteId != null ? true : false;
        },
        liberar_excluirHorario() {
            return !this.liberar_existePaciente &&
                   this.agendaAtendimentoSelecionada.id != null ? true : false;
        },
        liberar_btnStatusEspera() {
            return this.liberar_existePaciente && 
                   this.agendaAtendimentoSelecionada.status == null ? true : false;
        },
        liberar_btnStatusCancelarEspera() {
            return this.liberar_existePaciente &&
                   this.agendaAtendimentoSelecionada.status == "E" ? true : false;
        },
        liberar_btnStatusLimpar() {
            return this.liberar_existePaciente &&
                   (this.agendaAtendimentoSelecionada.status != null && 
                   this.agendaAtendimentoSelecionada.status != "E") ? true : false;
        },
        liberar_btnStatusHabilitado() {
            return this.liberar_existePaciente &&
                   this.agendaAtendimentoSelecionada.status == "E" ? true : false;
        },
        liberar_btnStatusConsultorio() {
            return this.liberar_existePaciente && 
                   (this.agendaAtendimentoSelecionada.status == "E" ||
                   this.agendaAtendimentoSelecionada.status == "H" ||
                   this.agendaAtendimentoSelecionada.status == "P" ||
                   this.agendaAtendimentoSelecionada.status == "R") ? true : false;
        },
        liberar_btnStatusAtendido() {
            return this.liberar_existePaciente &&
                   this.agendaAtendimentoSelecionada.status == "C" ? true : false;
        },
        // liberar_btnStatusFoiEmbora() {
        //     return this.liberar_existePaciente &&
        //            this.agendaAtendimentoSelecionada.status == "A" ? true : false;
        // },
        liberar_btnStatusDesistencia() {
            return this.liberar_existePaciente &&
                   (this.agendaAtendimentoSelecionada.status == "E" || this.agendaAtendimentoSelecionada.status == "H") ? true : false;
        },
        liberar_btnStatusFaltou() {
            return this.liberar_existePaciente &&
                   this.agendaAtendimentoSelecionada.status == null ? true : false;
        },
        liberar_btnStatusDesmarcou() {
            return this.liberar_existePaciente && 
                   this.agendaAtendimentoSelecionada.status == null ? true : false;
        },
        liberar_btnStatusProfDesmarcou() {
            return this.liberar_existePaciente && 
                   this.agendaAtendimentoSelecionada.status == null ? true : false;
        },
        liberar_btnStatusFaltouAvisou() {
            return this.liberar_existePaciente && 
                   this.agendaAtendimentoSelecionada.status == null ? true : false;
        },
        liberar_btnStatusFaltouNC() {
            return this.liberar_existePaciente &&
                   this.agendaAtendimentoSelecionada.status == null ? true : false;
        },
        liberar_btnStatusProcedimento() {
            return this.liberar_existePaciente && 
                   (this.agendaAtendimentoSelecionada.status == "E" || 
                   this.agendaAtendimentoSelecionada.status == "H" || 
                   this.agendaAtendimentoSelecionada.status == "C" ||
                   this.agendaAtendimentoSelecionada.status == "R") ? true : false;
        },
        liberar_btnStatusRX() {
            return this.liberar_existePaciente &&
                   (this.agendaAtendimentoSelecionada.status == "E" || 
                   this.agendaAtendimentoSelecionada.status == "H" || 
                   this.agendaAtendimentoSelecionada.status == "C" ||
                   this.agendaAtendimentoSelecionada.status == "P") ? true : false;
        },
        liberar_btnCadeadoFechado() {
            return this.agendaAtendimentoSelecionada.destinado=="S" &&
                   this.agendaAtendimentoSelecionada.pacienteId!=null &&
                   this.cadeadoFechado ==false ? true : false;
        },
        liberar_btnCadeadoAberto() {
            return this.agendaAtendimentoSelecionada.destinado=="S" &&
                   this.agendaAtendimentoSelecionada.pacienteId!=null &&
                   this.cadeadoFechado ==true ? true : false;
        },
    },
    methods: {
        ...mapActions({
            setPacienteModulo : "pacientes/setModulo",
            setPacienteSelecionado : "pacientes/setPacienteSelecionado",
            setOperacao : "agendaAtendimentos/setOperacao",
            setAgenda : "agendaAtendimentos/setAgenda",
            setUltimaBusca : "agendaAtendimentos/setUltimaBusca",
            formLeavePacientes : "pacientes/formLeave",
            nullRegistroSelecionadoPaciente : "pacientes/nullRegistroSelecionado",
            setParametrosLanctoGuia : "guias/setParametrosLanctoGuia",
            setGuiaChamadoPor : "guias/setChamadoPor",
            setOrigemRecebimento : "recebimentos/setOrigemRecebimento",
        }),
        retornar() {
            this.retornando = true;
            this.$router.go(-1);
        },
        async imprimirAgenda() {
            await this.imprimirAgenda01(this.usuarioLogadoDados.tenant, this.profissionalId, this.unidadeId, this.datInicial, this.datInicial);
        },
        refresh() {
            this.iniciarCarregarAgenda();
        },
        async selecionouPlano() {
            await this.carregarPacienteOperadora(this.usuarioLogadoDados.tenant, this.pacienteSelecionado.id, this.operadoraId, this.planoSaudeId, this.usuarioLogadoDados.profissionalId, 'S');
            this.pacienteOperadora.datValidade = funcoes.mySqlToJSDateFormat(this.pacienteOperadora.datValidade);
        },
        buscarAgenda() {
            if (this.unidadeId == 0 || this.profissionalId == 0 || this.datInicial == null) {
                return
            }

            this.selecionouProfissional = true;
            this.iniciarCarregarAgenda();
        },
        iniciarCarregarAgenda() {
            this.carregarAgendaAtendimentosPorData(this.usuarioLogadoDados.tenant, this.profissionalId, this.unidadeId, this.datInicial);
        },
        editarAgendamento() {
            this.editandoAgendamento = true;
            this.setAgenda(this.agendaAtendimentoSelecionada);

            if (this.agendaAtendimentoSelecionada.pacienteId != null) {
                let pacienteSelecionado = {id : this.agendaAtendimentoSelecionada.pacienteId,
                                           nome : this.agendaAtendimentoSelecionada.paciente}
                this.setPacienteSelecionado(pacienteSelecionado);
            } else {
                this.setPacienteSelecionado(null);
            }
            
            this.$router.push('/cadAgendamento');
        },
        async selecionouAgendaAtendimento(agendaAtendimento) {
            this.agendaAtendimentoSelecionada = agendaAtendimento;

            if (this.agendaAtendimentoSelecionada.pacienteId==null) {
                if (this.agendaAtendimentoSelecionada.bloqueioAgenda == null || this.agendaAtendimentoSelecionada.bloqueioAgenda == '') {
                    this.informacao = this.agendaAtendimentoSelecionada.horario + " - Horário Livre";
                } else {
                    this.informacao = this.agendaAtendimentoSelecionada.horario + " - Horário Bloqueado";
                }

                if (this.pacienteSelecionado) {
                    if (this.agendaAtendimentoSelecionada.bloqueioAgenda != '' && this.agendaAtendimentoSelecionada.bloqueioAgenda != null) {
                        this.$toasted.show("O horário selecionado está Bloqueado.", {type : "warning", icon : "times"});
                        return
                    }

                    if (this.operacao=="AlterarDataHora") {
                        await this.$bvModal
                          .msgBoxConfirm("Alterar Agendamento do Paciente "+this.pacienteSelecionado.nome+
                            " para "+this.datInicial+" às "+this.agendaAtendimentoSelecionada.horario+ "?", {
                            title: "Atenção",
                            size: "lg",
                            buttonSize: "md",
                            okVariant: "info",
                            okTitle: "Sim",
                            cancelTitle: "Não",
                            footerClass: "p-2",
                            hideHeaderClose: true,
                            centered: true
                          })
                          .then(value => {
                            if (!value) {
                                this.setOperacao("");
                                return;
                            }
                          })
                          .catch(error => {
                              this.erro = errors.tratarErros(error);
                            return;
                          });

                        this.agendaAtendimentoSelecionada.pacienteId   = this.pacienteSelecionado.id;
                        this.agendaAtendimentoSelecionada.planoSaudeId = this.agendaAnterior.planoSaudeId;
                        this.agendaAtendimentoSelecionada.observacao = this.agendaAnterior.observacao;
                        this.agendaAtendimentoSelecionada.confirmado = this.agendaAnterior.confirmado;

                        this.agendaAtendimentoSelecionada.atendimento = await this.gerarTipoAtendimento();

                        this.alterarDataHoraExecutar();
                    } else if (this.operacao=="ReagendarPaciente") {
                        await this.$bvModal
                          .msgBoxConfirm("Reagenda o(a) Paciente "+this.pacienteSelecionado.nome+
                            " para "+this.datInicial+" às "+this.agendaAtendimentoSelecionada.horario+ "?", {
                            title: "Atenção",
                            size: "lg",
                            buttonSize: "md",
                            okVariant: "info",
                            okTitle: "Sim",
                            cancelTitle: "Não",
                            footerClass: "p-2",
                            hideHeaderClose: true,
                            centered: true
                          })
                          .then(value => {
                            if (!value) {
                              this.setOperacao("");
                              return;
                            }
                          })
                          .catch(error => {
                              this.erro = errors.tratarErros(error);
                          });

                        this.agendaAtendimentoSelecionada.pacienteId   = this.pacienteSelecionado.id;
                        this.agendaAtendimentoSelecionada.planoSaudeId = this.agendaAnterior.planoSaudeId;
                        this.agendaAtendimentoSelecionada.observacao = this.agendaAnterior.observacao;
                        this.agendaAtendimentoSelecionada.atendimento = await this.gerarTipoAtendimento();

                        this.reagendarExecutar();
                    } else if (this.operacao=="ReplicarSessao") {
                        if (this.agendaAtendimentoSelecionada.destinado!="S") {
                            this.$toasted.show("Operação Permitida Apenas para Sessões", {type:"info", icon: "times"});
                            return;
                        }

                        this.agendaAtendimentoSelecionada.pacienteId = this.pacienteSelecionado.id;
                        this.agendaAtendimentoSelecionada.pacienteId   = this.pacienteSelecionado.id;
                        this.agendaAtendimentoSelecionada.planoSaudeId = this.agendaAnterior.planoSaudeId;
                        this.agendaAtendimentoSelecionada.observacao = this.agendaAnterior.observacao;
                        this.agendaAtendimentoSelecionada.atendimento = this.agendaAnterior.atendimento;

                        this.replicarSessao();
                    } else {
                        if (this.operadoraId==0) {
                            this.$toasted.show("Selecione a Operadora de Convênio Médico do(a) Paciente", {type: "info", icon: "times"})
                            return;
                        }

                        if (this.planoSaudeId==0) {
                            this.$toasted.show("Selecione o Plano Médico do(a) Paciente", {type: "info", icon: "times"})
                            return;
                        }

                        let mensagemConfirmacao = ""

                        if (this.agendaAtendimentoSelecionada.observacao != null && this.agendaAtendimentoSelecionada.observacao != '') {
                            mensagemConfirmacao = `Atenção: Verifique se já não existe um Paciente de 1ª Consulta agendado nesse horário. `;
                        }

                        mensagemConfirmacao += "Agendar Paciente "+this.pacienteSelecionado.paciente+
                            " para "+funcoes.mySqlToJSDateFormat(this.datInicial)+" às "+this.agendaAtendimentoSelecionada.horario+ "?";

                        this.$bvModal
                          .msgBoxConfirm(mensagemConfirmacao, {
                            title: "Atenção",
                            size: "lg",
                            buttonSize: "md",
                            okVariant: "info",
                            okTitle: "Sim",
                            cancelTitle: "Não",
                            footerClass: "p-2",
                            hideHeaderClose: true,
                            centered: true
                          })
                          .then(value => {
                            if (value) {
                                this.agendaAtendimentoSelecionada.pacienteId = this.pacienteSelecionado.id;
                                this.agendaAtendimentoSelecionada.planoSaudeId = this.planoSaudeId; 
    
                                this.agendamentoPacientePreparar();
                            }
                          })
                          .catch(error => {
                            this.erro = errors.tratarErros(error);
                          });
                    }
                }
            } else {
                if (this.operacao == "Agenda" || this.operacao=="AlterarDataHora" || this.operacao=="ReplicarSessao" || this.operacao=="ReagendarPaciente") {
                    this.$toasted.show("Selecione um horário livre", {type: "info", icon: "times"});
                    return;
                }

                this.informacao = this.agendaAtendimentoSelecionada.horario + " - Paciente: " + 
                                  this.agendaAtendimentoSelecionada.paciente; 
            }
        },
        async carregarPOProfissional() {
            if (this.pOProfissionais.length==0) {
              await this.carregarPOProfissionais(this.usuarioLogadoDados.tenant, this.pacienteOperadora.id);
            }

            if (this.pOProfissionais.filter(p => p.profissionalId==this.profissionalId).length==0) {
                let pOProfissional = {pacienteOperadoraId : this.pacienteOperadora.id, 
                                      profissionalId : this.profissionalId, 
                                      atendimento : "??",
                                      operadorCadastroId : this.usuarioLogadoDados.profissionalId}

                await this.$http.post(`/v1.0/poprofissionais/${this.usuarioLogadoDados.tenant}`, pOProfissional)
                .then(resp => {
                    this.erro = "";
                    this.pOProfissional = resp.data;
                })
                .catch(error => {
                    this.erro = errors.tratarErros(error);
                })
            } else {
                this.pOProfissional = this.pOProfissionais.find(p => p.profissionalId==this.profissionalId);
            }
        },
        async agendamentoPacientePreparar() {
            await this.carregarPOProfissional();

            let atendimento = "";

            if (this.agendaAtendimentoSelecionada.destinado == "C") {
                atendimento = await this.gerarTipoAtendimento();
            } else if (this.agendaAtendimentoSelecionada.destinado == "S") {
                atendimento = this.pOProfissional.datPrivisita==null ? "AV" : "SS"
            } else {
                atendimento = this.pOProfissional.datPrivisita==null ? "AV" : "MS"
            }

            this.agendaAtendimentoSelecionada.atendimento = atendimento;
            this.agendamentoPaciente();
        },
        agendamentoPaciente() {
           var agendarPaciente = {
               id : this.agendaAtendimentoSelecionada.id,
               pacienteId : this.agendaAtendimentoSelecionada.pacienteId,
               planoSaudeId : this.agendaAtendimentoSelecionada.planoSaudeId,
               observacao : this.agendaAtendimentoSelecionada.observacao,
               procedimento : this.agendaAtendimentoSelecionada.procedimento,
               atendimento : this.agendaAtendimentoSelecionada.atendimento,
               operadorCadastroId : this.usuarioLogadoDados.profissionalId,
               reagendarId : this.pacienteSelecionado.reagendarId
           }

           this.$http.put(`/v1.0/agendaatendimentos/${this.usuarioLogadoDados.tenant}/AgendarPaciente/${agendarPaciente.id}`, agendarPaciente)
           .then(() => {
               this.erro = "";
               this.iniciarCarregarAgenda();
               this.nullRegistroSelecionadoPaciente();
               this.setPacienteModulo("");
               this.setOperacao("");
           })
           .catch(error => {
               this.erro = errors.tratarErros(error);
           })
        },
        async gerarTipoAtendimento() {
            let atendimento = "";

            if (this.pOProfissional.id == undefined) {
                this.planoSaudeId = this.agendaAnterior.planoSaudeId;
                this.operadoraId = this.agendaAnterior.operadoraId;

                await this.selecionouPlano();
                await this.carregarPOProfissional();
            }

            let outrosProfissionais = this.pOProfissionais.filter(p => 
                p.profissionalId!=this.profissionalId && 
                p.profissional.cbosId == this.pOProfissional.profissional.cbosId && 
                p.datUltVisita!=null
            )
            
            let operadoraSelecionada;

            if (this.operadoraId==0 || this.operadoraId == undefined) {
                if (this.operacao=="AlterarDataHora" || this.operacao=="ReagendarPaciente")  {
                    await this.carregarPlanoSaudePeloId(this.usuarioLogadoDados.tenant, this.agendaAnterior.planoSaudeId) ;
                    this.operadoraId = this.planoSaude.operadoraId;
                } else {
                    this.$toasted.show("Erro com o Plano de Saúde", {type : "info", icon : "times"});
                    return "";
                }
            }

            if (this.operadoras.length==0) {
                await this.carregarOperadoras(this.usuarioLogadoDados.tenant, 'S', 0);
            }

            operadoraSelecionada = this.operadoras.find(o => o.id == this.operadoraId);
            let retornoPorCbos = operadoraSelecionada.retornoPorCbos; 

            if (outrosProfissionais.length>0 && retornoPorCbos) {
                let {datRetorno : maximoRetorno}  = outrosProfissionais.sort((d1, d2) => d1.datRetorno < d2.datRetorno ? 1 : -1)[0];
                
                maximoRetorno = maximoRetorno > this.pOProfissional.datRetorno ? maximoRetorno : this.pOProfissional.datRetorno;
                atendimento = maximoRetorno < new Date(this.datInicial) ? "NC" : "RT";
            } else {
                if (this.pOProfissional.datPriVisita==null) {
                    if (outrosProfissionais.length>0)  {
                        atendimento = "NC"
                    } else {
                        atendimento = "1C";
                    }
                } else {
                    let datVerificacao = new Date(this.datInicial + " 00:00");
                    datVerificacao = datVerificacao.getFullYear()+'-'+
                                     ('00' + (datVerificacao.getMonth()+1)).slice(-2)+'-'+
                                     ('00' + datVerificacao.getDate()).slice(-2)+'T'+
                                     '00:00';
                    atendimento = this.pOProfissional.datRetorno >= datVerificacao ? "RT" : "NC";
                }
            }

            return atendimento;
        },
        cancelarAgendamentoConfirmacao() {
            if (this.agendaAtendimentoSelecionada.pacienteId!=null) {
                this.$bvModal
                .msgBoxConfirm("Cancelar Agendamento do(a) Paciente "+this.agendaAtendimentoSelecionada.paciente+
                  " do dia "+this.datInicial+" às "+this.agendaAtendimentoSelecionada.horario+ "?", {
                  title: "Atenção",
                  size: "lg",
                  buttonSize: "md",
                  okVariant: "info",
                  okTitle: "Sim",
                  cancelTitle: "Não",
                  footerClass: "p-2",
                  hideHeaderClose: true,
                  centered: true
                })
                .then(value => {
                  if (value) {
                    this.cancelarAgendamento();
                  }  
                })
                .catch(error => {
                    this.erro = errors.tratarErros(error);
                });
            } else {
                this.informacao = "Barra de Informações"; 
            }
        },
        cancelarAgendamento() {
            let cancelar = {id : this.agendaAtendimentoSelecionada.id, operadorAlteracaoId : this.usuarioLogadoDados.profissionalId};

            this.$http.put(`/v1.0/agendaatendimentos/${this.usuarioLogadoDados.tenant}/CancelarAgendaPaciente/${cancelar.id}`, cancelar)
            .then(() => {
                this.erro = "";
                this.iniciarCarregarAgenda();
                this.nullRegistroSelecionadoPaciente();
            })
            .catch(error => {
                this.erro = errors.tratarErros(error);
            })
        },
        async cadastroPaciente() {
            await this.carregarPacienteRegistrarAcesso(this.usuarioLogadoDados.tenant, this.agendaAtendimentoSelecionada.pacienteId, this.usuarioLogadoDados.profissionalId);

            this.paciente.datNascimento = funcoes.mySqlToJSDateFormat(this.paciente.datNascimento);
            this.paciente.profissionalAgendaId = this.profissionalId;
            
            this.setPacienteSelecionado(this.paciente);
            this.erro = "";
            this.setPacienteModulo("");
            this.$router.push('/cadPaciente');
        },
        atendimentosPaciente() {
            if (this.agendaAtendimentoSelecionada.id == 0 || this.agendaAtendimentoSelecionada.id == undefined)  {
                this.$toasted.show("Selecione o(a) Paciente na Agenda antes de utilizar essa opção.", {type: "info", icon: "time"});
                return;
            }

            let pacienteSelecionado = {id : this.agendaAtendimentoSelecionada.pacienteId,
                                       nome : this.agendaAtendimentoSelecionada.paciente}

            this.setPacienteSelecionado(pacienteSelecionado);
            this.setPacienteModulo("Agenda");
            this.$router.push('/frmAgendaPaciente');
        },
        controleAtendimentosPaciente() {
            if (this.agendaAtendimentoSelecionada.pacienteId == 0 || this.agendaAtendimentoSelecionada.pacienteId == undefined)  {
                this.$toasted.show("Selecione o(a) Paciente na Agenda antes de utilizar essa opção.", {type: "info", icon: "time"});
                return;
            }

            let pacienteSelecionado = {id : this.agendaAtendimentoSelecionada.pacienteId,
                                       nome : this.agendaAtendimentoSelecionada.paciente}

            this.setPacienteSelecionado(pacienteSelecionado);
            this.setPacienteModulo("Agenda");
            this.$router.push('/frmControleAtendimentos');
        },
        lancamentosPaciente() {
            if (this.agendaAtendimentoSelecionada.pacienteId == 0 || this.agendaAtendimentoSelecionada.pacienteId == undefined)  {
                this.$toasted.show("Selecione o(a) Paciente na Agenda antes de utilizar essa opção.", {type: "info", icon: "time"});
                return;
            }

            let parametros = {
                              paciente : {id : this.agendaAtendimentoSelecionada.pacienteId, nome : this.agendaAtendimentoSelecionada.paciente},
                              profissionalExecutanteId : this.agendaAtendimentoSelecionada.profissionalId, 
                              profissionalSolicitanteId : this.agendaAtendimentoSelecionada.profissionalId, 
                              operadoraId : this.agendaAtendimentoSelecionada.operadoraId,
                              planoSaudeId : this.agendaAtendimentoSelecionada.planoSaudeId,
                              tipoLote : -1, guiaOperadora : null,
                              datAutorizacao : null, senhaAutorizacao : null, datValidadeSenha : null,
                              agendaAtendimentoId : this.agendaAtendimentoSelecionada.id,

                              datAtendimento : funcoes.mySqlToJSDateFormat(this.agendaAtendimentoSelecionada.data)
                             }

            this.setParametrosLanctoGuia(parametros);
            this.setGuiaChamadoPor("Agenda");
            this.$router.push('/frmLanctoGuia');
        },
        recebimentosPaciente() {
            if (this.agendaAtendimentoSelecionada.particular != 'S') {
                this.mensagem = `Não é possível realizar Recebimento para Atendimentos com Convênio.`;
                this.$bvModal.show("modal-mensagem");
                return;
            }

            let origem = {pacienteId : this.agendaAtendimentoSelecionada.pacienteId, pacienteNome : this.agendaAtendimentoSelecionada.paciente, 
                            pacienteCPF : this.agendaAtendimentoSelecionada.pacienteCpfNumero, datAtendimento : null, chamadoPor : "AgendaAtendimentos"};
            this.setOrigemRecebimento(origem);
            this.$router.push('/frmRecebimentos');
        },
        alterarDataHoraPreparar() {
            this.$http.get(`/v1.0/pacientes/${this.usuarioLogadoDados.tenant}/${this.agendaAtendimentoSelecionada.pacienteId}`)
            .then(resp => {
                this.setPacienteSelecionado(resp.data);
                this.setOperacao("AlterarDataHora");
                this.agendaAnterior = {...this.agendaAtendimentoSelecionada}

                this.erro = "";

                this.mensagem = `Selecione uma data/horário para agendar o(a) paciente ${this.pacienteSelecionado.nome}`;
                this.$bvModal.show("modal-mensagem");
            })
            .catch(error => {
                this.erro = errors.tratarErros(error);
            })
        },
        alterarDataHoraExecutar() {
            var agendarPaciente = {
                id : this.agendaAtendimentoSelecionada.id,
                pacienteId : this.agendaAtendimentoSelecionada.pacienteId,
                planoSaudeId : this.agendaAtendimentoSelecionada.planoSaudeId,
                observacao : this.agendaAtendimentoSelecionada.observacao,
                procedimento : this.agendaAtendimentoSelecionada.procedimento,
                atendimento : this.agendaAtendimentoSelecionada.atendimento,
                operadorCadastroId : this.usuarioLogadoDados.profissionalId,
                confirmado : this.agendaAtendimentoSelecionada.confirmado
            }

            this.$http.put(`/v1.0/agendaatendimentos/${this.usuarioLogadoDados.tenant}/AgendarPaciente/${agendarPaciente.id}`, agendarPaciente)
            .then(() => {
                this.erro = "";
                this.iniciarCarregarAgenda()
                this.nullRegistroSelecionadoPaciente();
                
                this.$http.get(`/v1.0/agendaatendimentos/${this.usuarioLogadoDados.tenant}/${this.agendaAnterior.id}`)
                .then((resp) => {
                    this.agendaAtendimentoSelecionada = resp.data;
                    this.cancelarAgendamento();
                })
                .then(() => {
                    this.agendaAnterior = {};
                    this.pacienteOperadora = {};
                    this.pOProfissional = {};

                    this.$toasted.global.defaultSuccess();
                })
                .catch(error => {
                    this.erro = errors.tratarErros(error);
                    this.$toasted.show("Não foi possível Remover o Agendamento Anterior do(a) Paciente.", {type: "danger", icon: "time"});
                })
                .finally(() => {
                    this.setOperacao("");
                })
            })
            .catch(error => {
                this.setOperacao("");
                this.erro = errors.tratarErros(error);
            })
        },
        reagendarPreparar() {
            this.$http.get(`/v1.0/pacientes/${this.usuarioLogadoDados.tenant}/${this.agendaAtendimentoSelecionada.pacienteId}`)
            .then(resp => {
                this.setPacienteSelecionado(resp.data);
                this.setOperacao("ReagendarPaciente");
                this.agendaAnterior = {...this.agendaAtendimentoSelecionada}
                this.erro = "";

                this.mensagem = `Selecione uma data/horário para reagendar o(a) paciente ${this.pacienteSelecionado.paciente}`;
                this.$bvModal.show("modal-mensagem");
            })
            .catch(error => {
                this.erro = errors.tratarErros(error);
            })
        },
        reagendarExecutar() {
            var agendarPaciente = {
                id : this.agendaAtendimentoSelecionada.id,
                pacienteId : this.agendaAtendimentoSelecionada.pacienteId,
                planoSaudeId : this.agendaAtendimentoSelecionada.planoSaudeId,
                observacao : this.agendaAtendimentoSelecionada.observacao,
                procedimento : this.agendaAtendimentoSelecionada.procedimento,
                atendimento : this.agendaAtendimentoSelecionada.atendimento,
                operadorCadastroId : this.usuarioLogadoDados.profissionalId
            }

            this.$http.put(`/v1.0/agendaatendimentos/${this.usuarioLogadoDados.tenant}/AgendarPaciente/${agendarPaciente.id}`, agendarPaciente)
            .then(() => {
                this.erro = "";
                this.iniciarCarregarAgenda()
                this.nullRegistroSelecionadoPaciente();
                this.pacienteOperadora = {};
                this.pOProfissional = {};
                this.agendaAnterior = {};
            })
            .catch(error => {
                this.erro = errors.tratarErros(error);
            })
            .finally(() => {
                this.setOperacao("");
            })
        },
        fecharCadeadoSessao() {
            this.$http.get(`/v1.0/pacientes/${this.usuarioLogadoDados.tenant}/${this.agendaAtendimentoSelecionada.pacienteId}`)
            .then(resp => {
                this.setPacienteSelecionado(resp.data);
                this.agendaAnterior = {...this.agendaAtendimentoSelecionada}
                this.setOperacao("ReplicarSessao");
                this.cadeadoFechado=true;
                this.erro = "";
            })
            .catch(error => {
                this.setOperacao("");
                this.erro = errors.tratarErros(error);
            })
        },
        abrirCadeadoSessao() {
            this.nullRegistroSelecionadoPaciente();
            this.agendaAnterior = {}
            this.setOperacao("");
            this.cadeadoFechado=false;
        },
        replicarSessao() {
            var agendarPaciente = {
                id : this.agendaAtendimentoSelecionada.id,
                pacienteId : this.agendaAtendimentoSelecionada.pacienteId,
                planoSaudeId : this.agendaAtendimentoSelecionada.planoSaudeId,
                observacao : this.agendaAtendimentoSelecionada.observacao,
                procedimento : this.agendaAtendimentoSelecionada.procedimento,
                atendimento : this.agendaAtendimentoSelecionada.atendimento,
                operadorCadastroId : this.usuarioLogadoDados.profissionalId
            }

            this.$http.put(`/v1.0/agendaatendimentos/${this.usuarioLogadoDados.tenant}/AgendarPaciente/${agendarPaciente.id}`, agendarPaciente)
            .then(() => {
                this.erro = "";
                this.iniciarCarregarAgenda();
            })
            .catch(error => {
                this.setOperacao("");
                this.erro = errors.tratarErros(error);
            })
        },
        buscarPacienteParaAgendamento() {
            this.setPacienteModulo("Agenda");
            this.$router.push('/frmPacientes');
        },
        duploClique(agendaAtendimento) {
            if (agendaAtendimento.pacienteId != null) {
                if (this.usuarioLogadoDados.perfilAcesso.perfil.realizaAtendimento == "S") {
                    if (agendaAtendimento.status == "H") {
                        this.alterarStatus("C");
                    }
                } else {
                    if (agendaAtendimento.status == null) {
                        this.alterarStatus("E");
                    } else if (agendaAtendimento.status == "E") {
                        this.alterarStatus("H");
                    }
                }
            } else {
                this.buscarPacienteParaAgendamento();
            }
        },
        confirmarPresenca() {
            if (this.agendaAtendimentoSelecionada.id == 0 || this.agendaAtendimentoSelecionada.id == undefined)  {
                this.$toasted.show("Selecione o Paciente na Agenda antes de Confirmar ou Não Presença.", {type: "info", icon: "time"});
                return;
            }

            var confirmadoAnterior = this.agendaAtendimentoSelecionada.confirmado;

            this.agendaAtendimentoSelecionada.confirmado = this.agendaAtendimentoSelecionada.confirmado=='S' ? 'N' : 'S';

            var agendaConfirmarPresenca  = {id : this.agendaAtendimentoSelecionada.id,
                                           confirmado : this.agendaAtendimentoSelecionada.confirmado,
                                           operadorAlteracaoId : this.usuarioLogadoDados.profissionalId}

            this.$http.put(`/v1.0/agendaAtendimentos/${this.usuarioLogadoDados.tenant}/ConfirmarPresenca/${agendaConfirmarPresenca.id}`, agendaConfirmarPresenca)
            .then(() => {
                this.erro = "";
                this.agendaAtendimentoSelecionada = {}
                this.informacao = "Barra de Informações";

                this.destacarLinhasAgenda();
            })
            .catch(error => {
                this.erro = errors.tratarErros(error);
                this.agendaAtendimentoSelecionada.confirmado = confirmadoAnterior;
            })
        },
        alterarObservacaoAgenda() {
            var observacaoAtual = this.agendaAtendimentoSelecionada.observacao == null ? "" : this.agendaAtendimentoSelecionada.observacao;
            var observacao = prompt("Digite a Observação", observacaoAtual);
            this.agendaAtendimentoSelecionada.observacao = observacao;

            var agendaObservacao = {id : this.agendaAtendimentoSelecionada.id,
                                    observacao : this.agendaAtendimentoSelecionada.observacao,
                                    operadorAlteracaoId : this.usuarioLogadoDados.profissionalId}

            this.$http.put(`/v1.0/agendaAtendimentos/${this.usuarioLogadoDados.tenant}/AlterarObservacaoAgenda/${agendaObservacao.id}`, agendaObservacao)
            .then(() => {
                this.erro = "";
                this.agendaAtendimentoSelecionada = {}
                this.informacao = "Barra de Informações";

                this.destacarLinhasAgenda();
            })
            .catch(error => {
                this.erro = errors.tratarErros(error);
            })
        },
        async alterarStatus(status) {
            if (this.agendaAtendimentoSelecionada.id == 0 || this.agendaAtendimentoSelecionada.id == undefined)  {
                this.$toasted.show("Selecione o Paciente na Agenda antes de Alterar Status.", {type: "info", icon: "time"});
                return;
            }

            if (new Date(this.datInicial) > new Date()) {
                this.$toasted.show("Não é possível Alterar Status de um Agendamento Futuro");
                return;
            }

            //var statusAnterior = this.agendaAtendimentoSelecionada.status; 
            //this.agendaAtendimentoSelecionada.status = status;

            let espera = "N";

            if (status == "E") {
                if (!await this.confirmarAlteracaoStatus("Colocar Paciente em Espera?")) {
                    return;
                }

                espera = "S";
            } else if (status == null) {
                if (!await this.confirmarAlteracaoStatus("Cancelar Espera do Paciente?")) {
                    return;
                }
            } else if (status == "S") {
                if (!await this.confirmarAlteracaoStatus("Paciente Desistiu do Atendimento?")) {
                    return;
                }
            } else if (status == "C") {
                if (!await this.confirmarAlteracaoStatus("Paciente está em Atendimento?")) {
                    return;
                }
            } else if (status == "H") {
                if (!await this.confirmarAlteracaoStatus("Habilitar (liberar) o Atendimento do Paciente?")) {
                    return;
                }
            } else if (status == "A") {
                if (!await this.confirmarAlteracaoStatus("Finalizar o Atendimento do Paciente?")) {
                    return;
                }
            }  

            status = status == "LS" ? "E" : status;

            var agendaStatus = {id : this.agendaAtendimentoSelecionada.id,
                                pacienteId : this.agendaAtendimentoSelecionada.pacienteId,
                                planoSaudeId : this.agendaAtendimentoSelecionada.planoSaudeId,
                                status : status, //this.agendaAtendimentoSelecionada.status,
                                operadorAlteracaoId : this.usuarioLogadoDados.profissionalId}

            await this.$http.put(`/v1.0/agendaAtendimentos/${this.usuarioLogadoDados.tenant}/AlterarStatus/${agendaStatus.id}`, agendaStatus)
            .then(() => {
                this.erro = "";
                this.informacao = "Barra de Informações";

                this.iniciarCarregarAgenda();
                this.destacarLinhasAgenda();
            })
            .catch(error => {
                this.erro = errors.tratarErros(error);
            })

            if (espera=="S") {
                let registroAgenda = this.agendaAtendimentos.find(registro => registro.id == agendaStatus.id);

                if (registroAgenda != null) {
                    this.selecionouAgendaAtendimento(registroAgenda);
                    this.cadastroPaciente();
                }
            } else if (status=="C") {
                this.prontuarioPaciente();
            }
            this.agendaAtendimentoSelecionada = {}
        },
        async confirmarAlteracaoStatus(mensagem) {
            let escolha = false;

            await this.$bvModal
              .msgBoxConfirm(mensagem, {
                title: "Atenção",
                size: "md",
                buttonSize: "sm",
                okVariant: "danger",
                okTitle: "Sim",
                cancelTitle: "Não",
                footerClass: "p-2",
                hideHeaderClose: true,
                centered: true
              })
              .then(value => {
                if (value) {
                    escolha = true;
                } else {
                    escolha = false;
                }
              })
              .catch(error => {
                this.erro = errors.tratarErros(error);
                escolha = false
              });

            return escolha;
        },
        excluirHorario() {
            if (this.agendaAtendimentoSelecionada.id == 0 || 
                this.agendaAtendimentoSelecionada.id == undefined || 
                (this.agendaAtendimentoSelecionada.pacienteId != null)) {
                this.$toasted.show("Selecione um Horário vazio para Excluir.", {type: "info", icon: "time"});
                return;
            }

            this.$bvModal
              .msgBoxConfirm("Excluir Horário Selecionado?", {
                title: "Atenção",
                size: "md",
                buttonSize: "sm",
                okVariant: "danger",
                okTitle: "Sim",
                cancelTitle: "Não",
                footerClass: "p-2",
                hideHeaderClose: true,
                centered: true
              })
              .then(value => {
                if (value) {
                  this.$http
                    .delete(`/v1.0/agendaAtendimentos/${this.usuarioLogadoDados.tenant}/${this.agendaAtendimentoSelecionada.id}/${this.usuarioLogadoDados.profissionalId}`)
                    .then(() => {
                        this.erro = "";
                        this.agendaAtendimentoSelecionada = {}
                        this.informacao = "Barra de Informações";
                        this.iniciarCarregarAgenda();
                    })
                    .catch(error => {
                      this.erro = errors.tratarErros(error);
                    })
                    .finally(() => {});
                }
              })
              .catch(error => {
                this.erro = errors.tratarErros(error);
              });
        },
        cancelarOperacao() {
            this.$bvModal
              .msgBoxConfirm("Cancelar Operação em Andamento?", {
                title: "Atenção",
                size: "md",
                buttonSize: "sm",
                okVariant: "danger",
                okTitle: "Sim",
                cancelTitle: "Não",
                footerClass: "p-2",
                hideHeaderClose: true,
                centered: true
              })
              .then(value => {
                if (value) {
                    this.erro = "";
                    this.setOperacao("");
                    this.nullRegistroSelecionadoPaciente();
                    this.cadeado = false;
                }
              })
              .catch(error => {
                this.erro = errors.tratarErros(error);
              });
        },
        gerarHorarios() {
            let mensagemHorarios = "";

            if (this.novosHorarios.destinado.trim() == "") {
                mensagemHorarios = "Destinado";
            }

            if (this.novosHorarios.horarioInicial.trim() == "") {
                if (mensagemHorarios == "") {
                    mensagemHorarios = "Horario Inicial"
                } else {
                    mensagemHorarios += ", Horario Inicial";
                }
            }

            if (this.novosHorarios.duracao.trim() == "") {
                if (mensagemHorarios == "") {
                    mensagemHorarios = "Duração"
                } else {
                    mensagemHorarios += ", Duração";
                }
            }

            if (this.novosHorarios.horarioFinal.trim() == "") {
                if (mensagemHorarios == "") {
                    mensagemHorarios = "Horario Final"
                } else {
                    mensagemHorarios += ", Horario Final";
                }
            }

            if (this.novosHorarios.encaixe.trim() == "") {
                if (mensagemHorarios == "") {
                    mensagemHorarios = "Encaixe"
                } else {
                    mensagemHorarios += ", Encaixe";
                }
            }

            if (this.novosHorarios.preferencial.trim() == "") {
                if (mensagemHorarios == "") {
                    mensagemHorarios = "Preferencial"
                } else {
                    mensagemHorarios += ", Preferencial";
                }
            }

            if (mensagemHorarios != "") {
                this.mensagem = "Favor preencher o(s) campo(s) " + mensagemHorarios;
                this.$bvModal.show("modal-mensagem");

                return;
            }

            var novasDisponibilidades = new Array();

            var arrHoraInicial = this.novosHorarios.horarioInicial.split(":");
            var horaInicial = new Date();
            horaInicial.setHours(parseInt(arrHoraInicial[0]));
            horaInicial.setMinutes(parseInt(arrHoraInicial[1]));
            horaInicial.setSeconds(0);
            horaInicial.setMilliseconds(0);

            var arrHoraFinal = this.novosHorarios.horarioFinal.split(":");
            var horaFinal = new Date();
            horaFinal.setHours(parseInt(arrHoraFinal[0]));
            horaFinal.setMinutes(parseInt(arrHoraFinal[1]));
            horaFinal.setSeconds(0);
            horaFinal.setMilliseconds(0);

            var arrDuracao = this.novosHorarios.duracao.split(":");
            var tempoDuracao = new Date();
            tempoDuracao.setHours(parseInt(arrDuracao[0]));
            tempoDuracao.setMinutes(parseInt(arrDuracao[1]));
            tempoDuracao.setSeconds(0);
            tempoDuracao.setMilliseconds(0);

            var Disponibilidade = {unidadeId : 0, profissionalId : 0, data : null, 
                                   destinado : "", horario : "", encaixe : "",  reposicao : "",
                                   preferencial : "", preferencial2 : "", preferencial3 : ""}

            var zeros = new String();
            var horas = new String();
            var minutos = new String();

            zeros = "0";
            horas = "";
            minutos = "";

            while (horaInicial <= horaFinal) {
              horas = horaInicial.getHours().toString();
              minutos = horaInicial.getMinutes().toString();

              horas = zeros.repeat(2 - horas.length) + horas;
              minutos = minutos + zeros.repeat(2 - minutos.length);

              Disponibilidade.operadorCadastroId = this.usuarioLogadoDados.profissionalId;

              Disponibilidade.unidadeId = this.unidadeId;
              Disponibilidade.profissionalId = this.profissionalId;
              Disponibilidade.data = new Date(this.datInicial);
              
              Disponibilidade.destinado = this.novosHorarios.destinado;
              Disponibilidade.horario = horas + ":" + minutos;
              Disponibilidade.preferencial = this.novosHorarios.preferencial;
              Disponibilidade.preferencial2 = this.novosHorarios.preferencial2;
              Disponibilidade.preferencial3 = this.novosHorarios.preferencial3;
              Disponibilidade.encaixe = this.novosHorarios.encaixe;
              Disponibilidade.reposicao = "N";

              novasDisponibilidades.push({
                ...Disponibilidade
              });

              horaInicial.setMinutes(horaInicial.getMinutes() + tempoDuracao.getMinutes());
            }

            if (novasDisponibilidades.length>0) {
                this.$http.post(`/v1.0/agendaatendimentos/${this.usuarioLogadoDados.tenant}/Lista`, novasDisponibilidades)
                    .then(() => {
                        this.erro = "";
                        this.incluirHorario = false;
                        this.$toasted.global.defaultSuccess();
                        this.iniciarCarregarAgenda();
                    })
                    .catch(error => {
                        this.erro = errors.tratarErros(error);
                    })
                    .finally(() => {
                        novasDisponibilidades = [];
                    })
            }
        },
        cancelarGerarHorarios() {
            this.incluirHorario=false;
            this.erro = "";
        },
        onFiltered() {

        },
        prontuarioPaciente() {
            this.$http.get(`/v1.0/pacientes/${this.usuarioLogadoDados.tenant}/${this.agendaAtendimentoSelecionada.pacienteId}`)
            .then(resp => {
                let pacienteSelecionado = {...resp.data}
                pacienteSelecionado.profissionalAgendaId = this.profissionalId;
                pacienteSelecionado.datAgenda;  

                this.setPacienteSelecionado(pacienteSelecionado);
                this.erro = "";
                this.setPacienteModulo("");
                this.$router.push('/frmProntuario');
            })
            .catch(error => {
                this.erro = errors.tratarErros(error);
            })
        },
        dataAtual() {
            var data = new Date();
            this.datInicial = funcoes.mySqlToJSDateFormat(data);
        },
        prepararBloqueio(operacao) {
            this.bloqueioOperacao = operacao;
            this.bloqueioRegistro.datInicial = this.datInicial;
            this.bloqueioRegistro.datFinal = this.datInicial;
        },
        bloqueioAgenda() {
            if (this.bloqueioOperacao == 'b' && this.bloqueioRegistro.bloqueio == '') {
                this.$toasted.show("Digite o motivo do Bloqueio", {type : "info", icon : "times"});
                return;
            }

            if (this.bloqueioRegistro.horaInicial == '' || this.bloqueioRegistro.horaFinal == '') {
                this.$toasted.show("Digite o Horário Inicial e o Horário Final", {type : "info", icon : "times"});
                return;
            }

            this.bloqueioRegistro.profissionalId = this.profissionalId;
            this.$http.put(`/v1.0/agendaatendimentos/${this.usuarioLogadoDados.tenant}/bloqueioHorarios/${this.profissionalId}/${this.usuarioLogadoDados.profissionalId}`, this.bloqueioRegistro)
            .then(resp => {
                let registros = resp.data;
                this.$toasted.show(`${registros}`, {type : "info", icon : "times"});
                this.refresh();
            })
            .catch(error => {
                this.erro = errors.tratarErros(error)
            })
            .finally(() => {
                this.bloqueioOperacao = "";
            })
        },
        async carregarReagendamentos() {
            await this.carregarReagendamentosPeloProfissional(this.usuarioLogadoDados.tenant, this.profissionalId);

            if (this.reagendamentos.length == 0) {
                this.$toasted.show("Não existem Reagendamentos para o Profissional selecionado", {type : "info", icon : "times"});
            }
        },
        ocultarReagendamentos() {
            this.reagendamentos = [];
        },
        reagendarPaciente(reagendamentoSelecionado) {
            let pacienteAgendar = {id : reagendamentoSelecionado.pacienteId, paciente : reagendamentoSelecionado.paciente, reagendarId : reagendamentoSelecionado.id};
            this.setPacienteSelecionado(pacienteAgendar);

            this.carregarOperadoras(this.usuarioLogadoDados.tenant, 'S', 0);
            this.setOperacao("Agenda");
            this.setPacienteModulo("Agenda");

            this.reagendamentos = [];
        },
        excluirReagendamento(reagendamentoSelecionado) {
            this.$bvModal
              .msgBoxConfirm("Excluir Reagendamento Selecionado?", {
                title: "Atenção",
                size: "md",
                buttonSize: "sm",
                okVariant: "danger",
                okTitle: "Sim",
                cancelTitle: "Não",
                footerClass: "p-2",
                hideHeaderClose: true,
                centered: true
              })
              .then(value => {
                if (value) {
                  this.$http
                    .delete(`/v1.0/reagendar/${this.usuarioLogadoDados.tenant}/${reagendamentoSelecionado.id}/${this.usuarioLogadoDados.profissionalId}`)
                    .then(() => {
                        this.erro = "";
                        this.carregarReagendamentos();
                    })
                    .catch(error => {
                      this.erro = errors.tratarErros(error);
                    })
                    .finally(() => {});
                }
              })
              .catch(error => {
                this.erro = errors.tratarErros(error);
              });
        }
    },
    async created() {
        this.showMenu(false);
        //this.carregarProfissionais(1, 'S', 0);
        this.carregarProfissionaisComAgendaPropria(this.usuarioLogadoDados.tenant, 0);
        this.carregarServicos(this.usuarioLogadoDados.tenant);
        await this.carregarUnidades(this.usuarioLogadoDados.tenant);

        if (this.ultimaBusca.data!=null) {
            this.datInicial = this.ultimaBusca.data;
            this.profissionalId = this.ultimaBusca.profissionalId;
            this.unidadeId = this.ultimaBusca.unidadeId;
        } else {
            if (this.unidades.length==1) {
                var data = new Date();

                this.datInicial = funcoes.mySqlToJSDateFormat(data);
                this.unidadeId = this.unidades[0].id;
            }
        }
        
        if (this.pacienteModulo=='Agenda') {
           this.carregarOperadoras(this.usuarioLogadoDados.tenant, 'S', 0);
           this.setOperacao("Agenda");
        }

        this.carregarConfigs(this.usuarioLogadoDados.tenant);
        this.mobile = funcoes.detectar_mobile();
        this.corpoMedico = this.usuarioLogadoDados.perfilAcesso.perfilId == 1 || this.usuarioLogadoDados.perfilAcesso == 10;
    },
    mounted() {
    },
    beforeDestroy() {
        if (this.retornando) {
            this.formLeavePacientes();
        }

        var registroUltimaBusca = {data : this.datInicial, profissionalId : this.profissionalId, unidadeId : this.unidadeId}
        this.setUltimaBusca(registroUltimaBusca);
    },
}
</script>

<style scoped>
.barraBotoes {
    display:flex;
    justify-content:center;
}

.barraInformacoes {
    margin:0px;
    text-align:center;
}

.barraInformacoes, p {
    color: navy;
    font-size: 1.2rem;
}

.agendamentoPendente {
    margin:0px;
    text-align:center;
}

.agendamentoPendente, p {
    color: green;
    font-size: 1.2rem;
}

hr {
   margin-top:2px;
   margin-bottom:2px;
}

.incluirNovosHorarios {
    display: flex;
    flex-direction: row;
    justify-content : center; 
}

label {
  font-weight: 100%;
  font-size: 0.8rem;
  margin-top: 3px;
  margin-bottom: 1px;
}

</style>
