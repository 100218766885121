<template>
    <div>
        <div class="cabecalho">
          <div class="coluna1">
            <button @click="retornar" class="btn btn-sm btn-primary mr-10"><i class="fa fa-arrow-left"/></button>
          </div>
          <div class="coluna2">
            <span>{{pacienteSelecionado.nome}} - Prontuário: {{pacienteSelecionado.id}}</span>
          </div>
          <div class="coluna3"/>
        </div>  

        <div class="container-fluid"  >
            <div class="row">
                <div class="form-group col-lg-3">
                  <label for="Consultorio" class="control-label">Solicitar:</label>
                  <select
                    class="form-control"
                    id="Consultorio"
                    name="Consultorio"
                    selected
                    v-model="consultorioId"
                  >
                    <option
                      v-for="consultorio in consultorios"
                      :key="consultorio.id"
                      :value="consultorio.id"
                    >{{ consultorio.descricao }}</option>
                  </select>
                </div>

                <div class="form-group col-lg-4">
                  <label for="ConsultorioItem" class="control-label">Item:</label>
                  <select
                    class="form-control"
                    :disabled="consultorioId==0"
                    id="ConsultorioItem"
                    name="ConsultorioItem"
                    selected
                    v-model="consultorioItemId"
                  >
                    <option
                      v-for="consultorioItem in consultorioItensParcial"
                      :key="consultorioItem.id"
                      :value="consultorioItem.id"
                    >{{ consultorioItem.descricao }}</option>
                  </select>
                </div>
            </div>
            <template v-if="consultorioItemId>0">
              <div class="row">
                <div class="form-group col-lg-12" style="height:180px;max-height:190px;overflow:auto">
                    <VueEditor id="modelo" v-model="consultorioItem.memo" :editor-toolbar="noToolBar" disabled/>
                </div>
              </div>
              <template v-if="consultorioItem.memo!='' && consultorioItem.memo!=null">
                <div class="row justify-content-end">
                  <div class="col-lg-1 mt-1">
                    <button id="adicionar" class="btn btn-sm btn-info mb-2" @click="adicionar()">Adicionar</button>
                  </div>
                </div> 
              </template>
            </template>

            <div class="row">
              <div class="form-group col-lg-12" style="height:240px;max-height:250px;overflow:auto">
                  <VueEditor id="solicitacao" v-model="solicitacao.ficha"/>
              </div>
            </div>
            <div class="row justify-content-end">
              <div class="col-lg-2 mt-1">
                <button id="Cancelar" class="btn btn-sm btn-secondary" @click="cancelar()">Cancelar</button>
                <button id="Confirmar" :disabled="solicitacao.ficha==''" class="btn btn-sm btn-info ml-1" @click="gravarSolicitacao()">Confirmar</button>
              </div>
            </div> 
        </div>
    </div>    
</template>

<script>
import { mapGetters } from "vuex";
import consultorios from "@/mixins/consultorios"
import consultorioItens from "@/mixins/consultorioItens"
import variaveis from "@/mixins/variaveis"
import funcoes from "@/utils/funcoes"
import {VueEditor} from "vue2-editor"
import errors from "@/utils/errors";

export default {
  mixins : [consultorios, consultorioItens, variaveis],
  components : {VueEditor},
  computed: {
    ...mapGetters({
      pacienteSelecionado: "pacientes/getPacienteSelecionado",
      usuarioLogadoDados : "global/getUsuarioLogadoDados"
    }),
  },
  watch : {
    consultorioId() {
      this.consultorioItemId = 0;
      this.carregarConsultorioItensParcial(this.usuarioLogadoDados.tenant, this.consultorioId);
    },
    consultorioItemId() {
      if (this.consultorioItemId>0) {
        this.carregarConsultorioItem(this.usuarioLogadoDados.tenant, this.consultorioItemId);
      }
    }
  },
  data() {
    return {
      consultorioId : 0,
      consultorioItemId : 0,
      consultorioItem : {},
      
      loadingVariaveis : false,
      loadedVariaveis : false,
      loadingConsultorios : false,
      loadedConsultorios : false,

      solicitacao : {ficha : ''},
      noToolBar : [[]]
    }
  },
  methods : {
    retornar() {
      this.$router.go(-1);
    },
    confirmar() {
      this.$router.go(-1);
    },
    cancelar() {
      this.retornar();
    },
    gravarSolicitacao() {
      let DataAtual = new Date();

      this.solicitacao.descricao = this.consultorios.find(c => c.id == this.consultorioId).descricao;
      this.solicitacao.pacienteId = this.pacienteSelecionado.id;
      this.solicitacao.datCadastro = DataAtual;
      this.solicitacao.horaCadastro = DataAtual.getHours()+":"+DataAtual.getMinutes();
      this.solicitacao.profissionalId = this.usuarioLogadoDados.profissionalId;
      this.solicitacao.operadorCadastroId = this.usuarioLogadoDados.profissionalId;
      this.solicitacao.readOnly = "N";

      this.$http.post(`/v1.0/solicitacoes/${this.usuarioLogadoDados.tenant}`, this.solicitacao)
      .then(() => {
        this.erro = "";
        this.$toasted.global.defaultSuccess();
        this.retornar();
      })
      .catch(error => this.erro = errors.tratarErros(error))
    },
    adicionar() {
      //let dados = {...this.pacienteSelecionado}

      let informacoes = {
          paciente : {dados : {...this.pacienteSelecionado}},
          profissional : {dados : {...this.usuarioLogadoDados}}
      }
    
      this.solicitacao.ficha += funcoes.replaceCampos(this.consultorioItem.memo, informacoes, this.variaveis);
    }
  },
  created() {
    this.carregarConsultorios(this.usuarioLogadoDados.tenant);
    this.carregarVariaveis(this.usuarioLogadoDados.tenant);
  }
}
</script>

<style scoped>
  label {
    margin-bottom: 2px;
  }

</style>
