const state = {
    operacao : "",
    agenda : {},
    ultimaBusca : {data : null, profissionalId : 0, unidadeId : 0},
    datasSelecionadas : [],
}

const getters = {
    getOperacao(state) {
        return state.operacao;
    },

    getAgenda(state) {
        return state.agenda;
    },

    getUltimaBusca(state) {
        return state.ultimaBusca;
    },

    getDatasSelecionadas(state) {
        return state.datasSelecionadas;
    }
}

const mutations = {
    setOperacao(state, operacao) {
        state.operacao = operacao;
    },

    setAgenda(state, agenda) {
        state.agenda = {...agenda}
    },

    setUltimaBusca(state, ultimaBusca) {
        state.ultimaBusca = {...ultimaBusca}
    },

    setDatasSelecionadas(state, datas) {
        state.datasSelecionadas = [...datas]
    }
}

const actions = {
    setOperacao({commit}, operacao) {
        commit("setOperacao", operacao);
    },

    setAgenda({commit}, agenda) {
        commit("setAgenda", agenda);
    },

    setUltimaBusca({commit}, ultimaBusca) {
        commit("setUltimaBusca", ultimaBusca);
    },

    setDatasSelecionadas({commit}, datas) {
        commit("setDatasSelecionadas", datas)
    },

    formLeave({commit}) {
        commit("setOperacao", "");
        commit("setIdAnterior", 0);
        commit("setAgenda", {});
    }
}

export default {
    namespaced : true,
    state,
    getters,
    mutations,
    actions
}