const state = {
    consultorioSelecionado : {}
}

const getters = {
    getConsultorioSelecionado(state) {
        return state.consultorioSelecionado;
    }
}

const mutations = {
    setConsultorioSelecionado(state, consultorio) {
        state.consultorioSelecionado = {...consultorio}
    }
}

const actions = {
    setConsultorioSelecionado({commit}, consultorio) {
        commit("setConsultorioSelecionado", consultorio)
    }
}

export default {
    namespaced : true,
    state,
    getters,
    mutations,
    actions
}