import errors from "@/utils/errors";

export default {
    data() {
        return {
            faturas : [],
            faturasAbertas : [],
            faturasFechadas : [],
        }
    },
    methods : {
        carregarFatura(tenant, id) {
            this.$http.get(`/v1.0/faturas/${tenant}/${id}`)
            .then(resp => {
                this.faturaSelecionada = resp.data[0];
                this.erro = "";
            })
            .catch(error => {
                this.erro = errors.tratarErros(error);
            })
        },
        carregarFaturasPelaOperadora(tenant, operadoraId) {
            this.$http.get(`/v1.0/faturas/${tenant}/operadora/${operadoraId}`)
            .then(resp => {
                this.faturas = resp.data;
                this.erro = "";
                this.loading = false;
            })
            .catch(error => {
                this.erro = errors.tratarErros(error);
            })
        },
        carregarFaturasPelaOperadoraParaTransferencia(tenant, operadoraId, faturaId) {
            this.$http.get(`/v1.0/faturas/${tenant}/operadoraId_ParaTransferencia/${operadoraId}/${faturaId}`)
            .then(resp => {
                this.faturasAbertas = resp.data;
                this.erro = "";
                this.loading = false;
            })
            .catch(error => {
                this.erro = errors.tratarErros(error);
            })
        },
        carregarFaturasPelaOperadoraAbertas(tenant, operadoraId) {
            this.$http.get(`/v1.0/faturas/${tenant}/operadoraId_abertas/${operadoraId}`)
            .then(resp => {
                this.faturasAbertas = resp.data;
                this.erro = "";
                this.loading = false;
            })
            .catch(error => {
                this.erro = errors.tratarErros(error);
            })
        },
        carregarFaturasPelaOperadoraFechadas(tenant, operadoraId) {
            this.$http.get(`/v1.0/faturas/${tenant}/operadoraId_fechadas/${operadoraId}`)
            .then(resp => {
                this.faturasFechadas = resp.data;
                this.erro = "";
                this.loading = false;
            })
            .catch(error => {
                this.erro = errors.tratarErros(error);
            })
        }
    }
}
