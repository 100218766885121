<template>
  <div>
    <div class="cabecalho">
      <div class="coluna1">
        <button @click="retornar" class="btn btn-sm btn-primary mr-10">
          <i class="fa fa-arrow-left" />
        </button>
      </div>
      <div class="coluna2">
        <span>{{pacienteSelecionado.nome}} - Prontuário: {{pacienteSelecionado.id}} - Sessões</span>
      </div>
      <div class="coluna3" />
    </div>

    <div class="container-fluid">
      <!-- Barra de opções para Guias -->
      <div class="row">
        <div class="col-lg-2">
          <button class="btn btn-sm btn-info form-control" :disabled="modo!=''" @click="incluirNovaGuia()">Incluir Nova Guia</button>
        </div>
        <div class="col-lg-2 mt-1">
          <span>Operações Guia -> </span>
        </div>
        <div class="col-lg-3">
          <b-button v-if="(guiaSelecionada.loteId==null && guiaSelecionada.liberadoFaturamento=='N') || chamadoPor=='Faturamento'" 
            variant="warning" size="sm" class="mr-1" 
            v-b-tooltip.hover title="Editar a Guia Selecionada"
            @click="alterarGuiaSelecionada()">
            <i class="fa fa-pencil"/>
          </b-button>
          <b-button variant="info" size="sm" class="mr-1" 
            v-b-tooltip.hover title="Clonar a Guia Selecionada"
            @click="confirmarClonarGuiaSelecionada()">
            <i class="fa fa-copy"/>
          </b-button>
          <b-button v-if="(guiaSelecionada.loteId==null && guiaSelecionada.liberadoFaturamento=='N') || chamadoPor=='Faturamento'" 
            variant="danger" size="sm" class="mr-1" 
            v-b-tooltip.hover title="Excluir a Guia Selecionada"
            @click="excluirGuiaSelecionada()">
            <i class="fa fa-trash"/>
          </b-button>
          <b-button v-if="guiaSelecionada.loteId==null && guiaSelecionada.liberadoFaturamento=='N'" 
            variant="info" size="sm" class="mr-1" 
            v-b-tooltip.hover title="Incluir Sessões na Guia Selecionada"
            @click="incluirSessoes()">
            <i class="fa fa-clock-o"/>
          </b-button>
          <b-button v-if="guiaSelecionada.loteId==null && guiaSelecionada.liberadoFaturamento=='N'" 
            variant="danger" size="sm" class="mr-1" 
            v-b-tooltip.hover title="Remover Sessões da Guia Selecionada"
            @click="desatarRemoverPeriodo()">
            <i class="fa fa-minus"/>
          </b-button>
          <b-button v-if="guiaSelecionada.loteId==null && guiaSelecionada.liberadoFaturamento=='N'" 
            variant="info" size="sm" class="mr-1" 
            v-b-tooltip.hover title="Incluir Atendimentos da Agenda"
            @click="buscarNaAgenda()">
            <i class="fa fa-plus"/>
          </b-button>
          <b-button v-if="guiaSelecionada.loteId==null && guiaSelecionada.liberadoFaturamento=='N'" 
            variant="info" size="sm" class="mr-1" 
            v-b-tooltip.hover title="Transferir Sessões da Guia Selecionada para outra Guia"
            @click="transferirSessoes()">
            <i class="fa fa-random"/>
          </b-button>
          <b-button v-if="guiaSelecionada.loteId==null && guiaSelecionada.liberadoFaturamento=='N'" 
            variant="info" size="sm" class="mr-1" 
            v-b-tooltip.hover title="Liberar a Guia Selecionada para Faturamento"
            @click="liberarFaturamento()">
            <i class="fa fa-share"/>
          </b-button>
          <b-button v-if="guiaSelecionada.loteId==null && guiaSelecionada.liberadoFaturamento=='S'" 
            variant="info" size="sm" class="mr-1" 
            v-b-tooltip.hover title="Remover a Guia Selecionada do Faturamento"
            @click="confirmarRemoverFaturamento()">
            <i class="fa fa-reply"/>
          </b-button>
        </div>
      </div>
      <div class="registro" v-if="modo!=''">
        <div class="row">
          <div class="col-lg-2 flex-center">
              <label for="selecionarData" class="control-label">Data Atendimento</label>
              <input type="date" id="selecionarData" class="form-control" v-model="guiaSelecionada.datAtendimento"/>
          </div>
          <div class="form-group col-lg-1">
            <label for="servico" class="control-label">Serviço</label>
            <select
              class="form-control"
              id="servico"
              name="servico"
              selected
              v-model="guiaSelecionada.siglaServico"
            >
              <option
                v-for="servico in servicosSeriados"
                :key="servico.id"
                :value="servico.siglaServico"
              >{{ servico.descricao }}</option>
            </select>
          </div>
          <div class="col-lg-2">
              <label for="profissionalSolicitante" class="control-label">Profissional Solicitante</label>
              <select id="profissionalSolicitante" class="form-control" v-model="guiaSelecionada.profissionalSolicitanteId" selected>
                  <option v-for="profissional in profissionaisSolicitantes" :key="profissional.id" :value="profissional.id">
                      {{profissional.nome}}
                  </option>
              </select>
          </div>
          <div class="col-lg-2">
              <label for="profissionalExecutante" class="control-label">Profissional Executante</label>
              <select id="profissionalExecutante" class="form-control" v-model="guiaSelecionada.profissionalExecutanteId" selected>
                  <option v-for="profissional in profissionaisExecutantes" :key="profissional.id" :value="profissional.id">
                      {{profissional.nome}}
                  </option>
              </select>
          </div>
          <div class="form-group col-lg-2">
            <label for="OperadorasConta" class="control-label">Operadora</label>
            <select
              class="form-control"
              id="OperadorasConta"
              name="OperadorasConta"
              selected
              v-model="guiaSelecionada.operadoraId"
              @click="selecionouOperadora()"
            >
              <option
                v-for="operadora in operadoras"
                :key="operadora.id"
                :value="operadora.id"
              >{{ operadora.fantasia }}</option>
            </select>
          </div>
          <div class="form-group col-lg-2">
            <label for="PlanoSaudeConta" class="control-label">Plano</label>
            <select
              class="form-control"
              :disabled="guiaSelecionada.operadoraId==0"
              id="PlanoSaudeConta"
              name="PlanoSaudeConta"
              selected
              v-model="guiaSelecionada.planoSaudeId"
            >
              <option
                v-for="planosaude in planosSaude"
                :key="planosaude.id"
                :value="planosaude.id"
              >{{ planosaude.plano }}</option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-lg-1">
            <label for="guiaOperadora" class="control-label">Guia Operad</label>
            <input type="text" id="guiaOperadora" class="form-control" v-model="guiaSelecionada.guiaOperadora" 
            :disabled="guiaSelecionada.tipoLote==-1">
          </div>
          <div class="form-group col-lg-1">
            <label for="guiaPrestador" class="control-label">Guia Prest</label>
            <input type="text" id="guiaPrestador" class="form-control" v-model="guiaSelecionada.guiaPrestador" 
            :disabled="guiaSelecionada.tipoLote==-1">
          </div>
          <div class="col-lg-1">
              <label for="sessoesSolicitadas" class="control-label">Solicitadas</label>
              <input type="number" id="sessoesSolicitadas" class="form-control" v-model.number="guiaSelecionada.sessoesSolicitadas"/>
          </div>
          <div class="col-lg-1">
              <label for="sessoesRestantes" class="control-label">Restantes</label>
              <input type="number" id="sessoesRestantes" class="form-control" v-model.number="guiaSelecionada.sessoesRestantes"/>
          </div>
          <div class="col-lg-2 flex-center">
              <label for="datAutorizacao" class="control-label">Data Autorização</label>
              <input type="date" id="datAutorizacao" class="form-control" v-model="guiaSelecionada.datAutorizacao"
              :disabled="guiaSelecionada.guiaOperadora==null"/>
          </div>
          <div class="form-group col-lg-2">
            <label for="senhaAutorizacao" class="control-label">Senha Autorização</label>
            <input type="text" id="senhaAutorizacao" class="form-control" v-model="guiaSelecionada.senhaAutorizacao"
            :disabled="guiaSelecionada.guiaOperadora==null">
          </div>
          <div class="col-lg-2 flex-center">
              <label for="datValidadeSenha" class="control-label">Validade</label>
              <input type="date" id="datValidadeSenha" class="form-control" v-model="guiaSelecionada.datValidadeSenha" 
              :disabled="guiaSelecionada.senhaAutorizacao==null"/>
          </div>
        </div>
        <div class="row justify-content-center">
          <button class="btn btn-sm btn-secondary" style="margin-bottom:10px;"
            @click="guiaCancelarOperacao()">Cancelar
          </button>
          <button class="btn btn-sm btn-info ml-1" style="margin-bottom:10px;"
            @click="gravarGuia()">Confirmar
          </button>
        </div>
      </div>

      <div id="erro">
        <p v-if="erro" style="background-color:red; color:white; text-align:center">{{erro}}</p>
      </div>

      <hr />
      <!-- Tabela de Guias -->
      <div style="height:180px;overflow:auto">
        <b-spinner v-if="loading" label="carregando..."/>
        <b-table v-if="!loading" class="col-lg-12 mb-1" hover striped small sort-icon-left :items="guias" :fields="camposTabela" @row-clicked="exibirSessoes">
            <template v-slot:cell(datAtendimento)="datAtendimento">
                {{ datAtendimento.value | formatDate}}
            </template>    
            <template v-slot:cell(datAutorizacao)="datAutorizacao">
                {{ datAutorizacao.value | formatDate}}
            </template>    
            <template v-slot:cell(datValidadeSenha)="datValidadeSenha">
                {{ datValidadeSenha.value | formatDate}}
            </template>    
            <template v-slot:cell(datCadastro)="datCadastro">
                {{ datCadastro.value | formatDate}}
            </template>    
            <template v-slot:cell(tipoLote)="tipoLote">
                {{ tipoLote.value == 1 ? "Cons" : tipoLote.value == 2 ? "SADT" : "Hon."}}
            </template>    
            <template v-slot:cell(particular)="particular">
                {{ particular.value == "S" ? "Sim" : ""}}
            </template>    
            <template v-slot:cell(atendimentoSeriado)="atendimentoSeriado">
              {{atendimentoSeriado.value == "S" ? "Sim" : null}}
            </template>
        </b-table>
      </div>
      <!-- Tabela de Sessões -->
      <div style="height:200px;overflow:auto">
        <b-table v-if="!loading" class="col-lg-12 mb-1" hover striped small sort-icon-left :items="agendaAtendimentos" :fields="camposTabelaSessoes">
            <template v-slot:cell(data)="data">
                {{ data.value | formatDate}}
            </template>    
            <template v-slot:cell(datGuia)="datGuia">
                {{ datGuia.value | formatDate}}
            </template>    

            <template v-slot:cell(actions)="row">
              <b-button v-if="guiaSelecionada.liberadoFaturamento=='N'" variant="danger" size="sm" class="mr-1" @click="confirmarDesatarSessao(row.item)">
                <i class="fa fa-minus"/>
              </b-button>
              <b-button v-if="guiaSelecionada.liberadoFaturamento=='N'" variant="danger" size="sm" class="mr-1" @click="confirmarRemoverSessao(row.item)">
                <i class="fa fa-trash"/>
              </b-button>
            </template>
        </b-table>
      </div>
      <!-- Barra de opções para Sessões -->
      <!-- <div class="row">
        <div class="col-lg-12">
          <button class="btn btn-sm btn-info" :disabled="modo!=''" @click="incluirSessoes()">Incluir Sessões</button>
          <hr />
        </div>
      </div> -->
    </div>
    <div class="Mensagem">
      <b-modal id="modal-mensagem" centered title="DoctorOn">
        <p class="my-4">{{mensagem}}</p>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex"
import guias from "@/mixins/guias"
import operadoras from "@/mixins/operadoras"
import planosSaude from "@/mixins/planosSaude"
import profissionais from "@/mixins/profissionais"
import servicos from "@/mixins/servicos"
import agendaAtendimentos from "@/mixins/agendaAtendimentos"
import global from "@/mixins/global"
import constantes from "@/utils/constantes"
import funcoes from "@/utils/funcoes"
import errors from "@/utils/errors"

export default {
    mixins : [guias, operadoras, planosSaude, profissionais, servicos, agendaAtendimentos, global],
    computed : {
        ...mapGetters({
            pacienteSelecionado : "pacientes/getPacienteSelecionado",
            usuarioLogadoDados : "global/getUsuarioLogadoDados",
        }),
        servicosSeriados() {
          return this.servicos.filter(servico => servico.atendimentoSeriado == "S");
        }
    },
    data() {
      return {
        loading : false,
        erro : "",
        modo : "",
        mensagem : "",
        chamadoPor : "",
        guiaSelecionada : {operadoraId : 0},
        operadoraSelecionada : {},
        camposTabela : [
          {key : "id", label : "Id"},
          {key : "datAtendimento", label : "Atendimento", sortable : true},
          {key : "siglaServico", label : "Serviço", sortable : true},
          {key : "profissionalExecutante", label : "Profissional Executante", sortable : true},
          {key : "guiaOperadora", label : "Guia Operadora"},
          {key : "guiaPrestador", label : "Guia Prestador"},
          {key : "sessoesSolicitadas", label : "Sessões"},
          {key : "operadora", label : "Operadora", sortable : true},
          {key : "planoSaude", label : "Plano de Saúde", sortable : true},
          {key : "particular", label : "Particular"},
          {key : "loteId", label : "Lote"},
          //{key : "actions", label : "Ações"}
        ],
        camposTabelaSessoes : [
          {key : "data", label : "Atend.", sortable : true},
          {key : "horario", label : "Horário"},
          {key : "datGuia", label : "Data Guia", sortable : true},
          {key : "status", label : "Status"},
          {key : "avaliacao", label : "Avaliação"},
          {key : "experimental", label : "Experimental"},
          {key : "reposicao", label : "Reposição"},
          {key : "livroFisioterapiaId", label : "Livro"},
          {key : "horaChegada", label : "Chegada"},
          {key : "horaAtendimento", label : "Atend."},
          {key : "horaSaida", label : "Saída"},
          {key : "actions", label : "Ações"}
        ],
        sim_nao : constantes.sim_nao,
        profissionaisSolicitantes : [],
        profissionaisExecutantes : [],
      }
    },
    methods : {
        ...mapActions({
          setGuiaSelecionada : "guias/setGuiaSelecionada",
          setGuiasDoPaciente : "guias/setGuiasDoPaciente",
          setDatasSelecionadas : "agendaAtendimentos/setDatasSelecionadas"
        }),
        retornar() {
            this.$router.go(-1);
        },
        adicionarAlteracoes() {

        },
        selecionouOperadora() {
          this.carregarPlanosSaude(this.usuarioLogadoDados.tenant, this.guiaSelecionada.operadoraId, 'S');
          this.operadoraSelecionada = this.operadoras.find(operadora => operadora.id == this.guiaSelecionada.operadoraId);
        },
        async exibirSessoes(guia) {
          await this.carregarDadosNaGuiaSelecionada(guia);
          this.carregarAgendaSessoes(this.usuarioLogadoDados.tenant, this.guiaSelecionada.id);
        },
        async carregarDadosNaGuiaSelecionada(guia) {
          this.guiaSelecionada = {
            id : guia.id,
            datAtendimento : funcoes.mySqlToJSDateFormat(guia.datAtendimento), 
            datAutorizacao : funcoes.mySqlToJSDateFormat(guia.datAutorizacao), 
            datValidadeSenha : funcoes.mySqlToJSDateFormat(guia.datValidadeSenha), 
            pacienteId : guia.pacienteId,
            profissionalSolicitanteId : guia.profissionalSolicitanteId, 
            profissionalExecutanteId : guia.profissionalExecutanteId, 
            guiaOperadora : guia.guiaOperadora,
            guiaPrestador : guia.guiaPrestador,
            planoSaudeId : guia.planoSaudeId, 
            planoSaudeLanctoId : guia.planoSaudeLanctoId,
            tipoLote : guia.tipoLote, 
            senhaAutorizacao : guia.senhaAutorizacao, 
            atendimentoSeriado : guia.atendimentoSeriado,
            liberadoFaturamento : guia.liberadoFaturamento,
            siglaServico : guia.siglaServico,
            sessoesSolicitadas : guia.sessoesSolicitadas,
            sessoesRestantes : guia.sessoesRestantes,
            loteId : guia.loteId,
            
            operadoraId : guia.operadoraId //não possui registro no backend
          }
        },
        async incluirNovaGuia() {
          if (!this.verificarPermissao('Incluir')) {
            return
          }

          this.modo = "Incluir";
          this.guiaSelecionada = {
               operadoraId : 0,
          }

          if (this.operadoras.length==0) {
            this.carregarOperadoras(this.usuarioLogadoDados.tenant, 'S', 0);
          }
          if (this.profissionaisSolicitantes.length==0) {
            this.loading = true;
            await this.carregarProfissionaisSolicitantes(this.usuarioLogadoDados.tenant, 0);

            this.profissionaisSolicitantes = [...this.profissionais];
            this.profissionais = [];
            this.loading = false;
          }
          if (this.profissionaisExecutantes.length==0) {
            this.loading = true;
            await this.carregarProfissionaisExecutantes(this.usuarioLogadoDados.tenant, 0);

            this.profissionaisExecutantes = [...this.profissionais];
            this.profissionais = [];
            this.loading = false;
          }
          if (this.servicos.length==0) {
            this.carregarServicos(this.usuarioLogadoDados.tenant);
          }
        },
        confirmarClonarGuiaSelecionada() {
          if (!this.verificarPermissao('Incluir')) {
            return
          }

          this.$bvModal
            .msgBoxConfirm("Clonar a Guia Selecionada?", {
              title: "Atenção",
              size: "sm",
              buttonSize: "sm",
              okVariant: "danger",
              okTitle: "Sim",
              cancelTitle: "Não",
              footerClass: "p-2",
              hideHeaderClose: true,
              centered: true
            })
            .then(value => {
              if (value) {
                this.clonarGuiaSelecionada();
              }
            })
            .catch(error => {
              this.erro = errors.tratarErros(error);
            });
        },
        clonarGuiaSelecionada() {
          //delete guia.id;

          let guia = {
            atendimentoSeriado : this.guiaSelecionada.atendimentoSeriado,
            datAtendimento : this.guiaSelecionada.datAtendimento,
            datValidadeSenha : this.guiaSelecionada.datValidadeSenha,
            operadoraId : this.guiaSelecionada.operadoraId,
            planoSaudeId : this.guiaSelecionada.planoSaudeId,
            planoSaudeLanctoId : this.guiaSelecionada.planoSaudeLanctoId,
            pacienteId : this.guiaSelecionada.pacienteId,
            particular : this.guiaSelecionada.particular,
            profissionalExecutanteId : this.guiaSelecionada.profissionalExecutanteId,
            profissionalSolicitanteId : this.guiaSelecionada.profissionalSolicitanteId,
            sessoesSolicitadas : this.guiaSelecionada.sessoesSolicitadas,
            sessoesRestantes : this.guiaSelecionada.sessoesRestantes,
            siglaServico : this.guiaSelecionada.siglaServico,
            tipoLote : this.guiaSelecionada.tipoLote,
            operadorCadastroId : this.usuarioLogadoDados.profissionalId,
            liberadoFaturamento : "N",
            senhaAutorizacao : null,
          }

          this.incluirGuia(this.usuarioLogadoDados.tenant, guia, "S", "PacienteSessoes");
        },
        async alterarGuiaSelecionada() {
          if (!this.verificarPermissao('Alterar')) {
            return
          }

          if (this.operadoras.length==0) {
            this.carregarOperadoras(this.usuarioLogadoDados.tenant, 'S', 0);
          }

          if (this.planosSaude.length==0) {
            this.carregarPlanosSaude(this.usuarioLogadoDados.tenant, this.guiaSelecionada.operadoraId, 'S')
          }

          if (this.profissionaisSolicitantes.length==0) {
            await this.carregarProfissionaisSolicitantes(this.usuarioLogadoDados.tenant, 0);
            this.profissionaisSolicitantes = [...this.profissionais];
            this.profissionais = [];
          }
          if (this.profissionaisExecutantes.length==0) {
            await this.carregarProfissionaisFisioterapeutas(this.usuarioLogadoDados.tenant, 0);
            this.profissionaisExecutantes = [...this.profissionais];
            this.profissionais = [];
          }
          if (this.servicos.length==0) {
            this.carregarServicos(this.usuarioLogadoDados.tenant);
          }

          this.modo = "Alterar";
          this.carregarAgendaSessoes(this.usuarioLogadoDados.tenant, this.guiaSelecionada.id);
        },
        liberarFaturamento() {
          if (!this.verificarPermissao('Alterar')) {
            return
          }

          this.$bvModal
            .msgBoxConfirm("Liberar Guia para o Faturamento?", {
              title: "Atenção",
              size: "sm",
              buttonSize: "sm",
              okVariant: "danger",
              okTitle: "Sim",
              cancelTitle: "Não",
              footerClass: "p-2",
              hideHeaderClose: true,
              centered: true
            })
            .then(value => {
              if (value) {
                this.enviarParaFaturamento();
              }
            })
            .catch(error => {
              this.erro = errors.tratarErros(error);
            });
        },
        confirmarRemoverFaturamento() {
          if (!this.verificarPermissao('Alterar')) {
            return
          }

          this.$bvModal
            .msgBoxConfirm("Remover Guia do Faturamento?", {
              title: "Atenção",
              size: "sm",
              buttonSize: "sm",
              okVariant: "danger",
              okTitle: "Sim",
              cancelTitle: "Não",
              footerClass: "p-2",
              hideHeaderClose: true,
              centered: true
            })
            .then(value => {
              if (value) {
                this.removerDoFaturamento();
              }
            })
            .catch(error => {
              this.erro = errors.tratarErros(error);
            });
        },
        async enviarParaFaturamento() {
          await this.liberadoFaturamento(this.usuarioLogadoDados.tenant, this.guiaSelecionada.id, "S", this.usuarioLogadoDados.profissionalId, "PacienteSessoes");

          //if (this.erro = "") {
          //  this.guiaSelecionada.liberadoFaturamento = "S"
          //}

          //this.carregarDadosNaGuiaSelecionada(guia);
        },
        async removerDoFaturamento() {
          await this.liberadoFaturamento(this.usuarioLogadoDados.tenant, this.guiaSelecionada.id, "N", this.usuarioLogadoDados.profissionalId, "PacienteSessoes");

          //if (this.erro = "") {
          //  this.guiaSelecionada.liberadoFaturamento = "N"
          //}

          //this.carregarDadosNaGuiaSelecionada(guia);
        },
        excluirGuiaSelecionada() {
          if (!this.verificarPermissao('Excluir')) {
            return
          }

          if (this.guiaSelecionada.loteId!=null) {
            this.$toasted.show("A Guia Selecionada já foi enviada para o Faturamento.", {type : "info", icon : "times"});
            return;
          }

          this.$bvModal
            .msgBoxConfirm("Excluir Guia Selecionada?", {
              title: "Atenção",
              size: "sm",
              buttonSize: "sm",
              okVariant: "danger",
              okTitle: "Sim",
              cancelTitle: "Não",
              footerClass: "p-2",
              hideHeaderClose: true,
              centered: true
            })
            .then(value => {
              if (value) {
                this.excluirGuia(this.guiaSelecionada, "T", this.usuarioLogadoDados.profissionalId, "PacienteSessoes");
              }
            })
            .catch(error => {
              this.erro = errors.tratarErros(error);
            });
        },
        guiaCancelarOperacao() {
          this.modo = "";
          this.erro = "";
        },
        gravarGuia() {
          if (!this.verificarCamposPreenchidos()) {
            return;
          }

          this.guiaSelecionada.particular = this.operadoraSelecionada.particular;

          if (this.modo=="Alterar") {
            this.guiaSelecionada.operadorAlteracaoId = this.usuarioLogadoDados.profissionalId;

            this.alterarGuia(this.usuarioLogadoDados.tenant, this.guiaSelecionada, "S", "PacienteSessoes");
          } else {
            let planoPadrao = this.planosSaude.filter(p => p.padrao == "S");

            if (planoPadrao!=null) {
              this.guiaSelecionada.planoSaudeLanctoId = planoPadrao[0].id;
            } else {
              this.guiaSelecionada.planoSaudeLanctoId = this.guiaSelecionada.planoSaudeId
            }

            this.guiaSelecionada.operadorCadastroId = this.usuarioLogadoDados.profissionalId;

            this.guiaSelecionada.pacienteId = this.pacienteSelecionado.id;
            this.guiaSelecionada.atendimentoSeriado = "S";
            this.guiaSelecionada.liberadoFaturamento = "N";
            this.guiaSelecionada.tipoLote = 2; // SADT

            this.incluirGuia(this.usuarioLogadoDados.tenant, this.guiaSelecionada, "S", "PacienteSessoes");
          }
        },
        incluirSessoes() {
          if (!this.verificarPermissao('Incluir')) {
            return
          }

          this.setGuiaSelecionada(this.guiaSelecionada);
          this.setDatasSelecionadas(this.agendaAtendimentos);
          this.$router.push("/frmAdicionarHorarios");
        },
        desatarRemoverPeriodo() {
          if (!this.verificarPermissao('Alterar')) {
            return
          }

          this.setGuiaSelecionada(this.guiaSelecionada);
          this.setDatasSelecionadas(this.agendaAtendimentos);
          this.$router.push("/frmDesatarRemover");
        },
        transferirSessoes() {
          if (!this.verificarPermissao('Alterar')) {
            return
          }

          this.setGuiaSelecionada(this.guiaSelecionada);
          this.setDatasSelecionadas(this.agendaAtendimentos);
          this.setGuiasDoPaciente(this.guias.filter(guiaPaciente => guiaPaciente.id != this.guiaSelecionada.id && guiaPaciente.liberadoFaturamento == "N" && guiaPaciente.loteId == null));
          this.$router.push("/frmTransferirSessoes");
        },
        buscarNaAgenda() {
          this.setGuiaSelecionada(this.guiaSelecionada);
          this.$router.push("/frmBuscarNaAgenda");
        },
        confirmarDesatarSessao(sessao) {
          if (!this.verificarPermissao('Alterar')) {
            return
          }

          this.$bvModal
            .msgBoxConfirm("Desatar Sessão da Guia (Mantendo na Agenda)?", {
              title: "Atenção",
              size: "sm",
              buttonSize: "sm",
              okVariant: "danger",
              okTitle: "Sim",
              cancelTitle: "Não",
              footerClass: "p-2",
              hideHeaderClose: true,
              centered: true
            })
            .then(value => {
              if (value) {
                this.desatarSessao(sessao);
              }
            })
            .catch(error => {
              this.erro = errors.tratarErros(error);
            });
        },
        async desatarSessao(sessao) {
          let guiaId = sessao.guiaId;
          let datasSessoes = [];
          datasSessoes.push(sessao);

          await this.desatarGuiaSessoes(this.usuarioLogadoDados.tenant, datasSessoes);
          this.carregarAgendaSessoes(this.usuarioLogadoDados.tenant, guiaId);
        },
        confirmarRemoverSessao(sessao) {
          if (!this.verificarPermissao('Excluir')) {
            return
          }

          this.$bvModal
            .msgBoxConfirm("Remover Sessão da Guia (Removendo também da Agenda)?", {
              title: "Atenção",
              size: "sm",
              buttonSize: "sm",
              okVariant: "danger",
              okTitle: "Sim",
              cancelTitle: "Não",
              footerClass: "p-2",
              hideHeaderClose: true,
              centered: true
            })
            .then(value => {
              if (value) {
                this.removerSessao(sessao);
              }
            })
            .catch(error => {
              this.erro = errors.tratarErros(error);
            });
        },
        async removerSessao(sessao) {
          let guiaId = sessao.guiaId;
          let datasSessoes = [];
          datasSessoes.push(sessao);

          await this.removerPacienteGuiaSessoes(this.usuarioLogadoDados.tenant, datasSessoes);
          this.carregarAgendaSessoes(this.usuarioLogadoDados.tenant, guiaId);
        },
        verificarCamposPreenchidos() {
          if (this.guiaSelecionada.datAtendimento==null) {
            this.$toasted.show("Digite a Data do Atendimento.", {type : "info", icon : "times"});
            return false;
          }

          if (this.guiaSelecionada.profissionalSolicitanteId==0) {
            this.$toasted.show("Selecione o Profissional Solicitante.", {type : "info", icon : "times"});
            return false;
          }

          if (this.guiaSelecionada.profissionalExecutanteId==0) {
            this.$toasted.show("Selecione o Profissional Solicitante.", {type : "info", icon : "times"});
            return false;
          }

          if (this.guiaSelecionada.planoSaudeId==0) {
            this.$toasted.show("Selecione o Plano de Saúde.", {type : "info", icon : "times"});
            return false;
          }

          if (this.guiaSelecionada.sessoesSolicitadas== undefined || this.guiaSelecionada.sessoesSolicitadas==0) {
            this.$toasted.show("Informe o Número de Sessões Solicitadas.", {type : "info", icon : "times"});
            return false;
          }

          if (this.guiaSelecionada.sessoesRestantes== undefined || this.guiaSelecionada.sessoesRestantes==0) {
            this.$toasted.show("Informe o Número de Sessões Restantes.", {type : "info", icon : "times"});
            return false;
          }

          if (this.guiaSelecionada.datValidadeSenha==null) {
            this.$toasted.show("Digite a Data de Validade da Senha.", {type : "info", icon : "times"});
            return false;
          }

          return true;
        },
        verificarPermissao(operacao) {
          if (!errors.verificarPermissao(this.usuarioLogadoDados, this.$store.state.global.grauPermissaoMinimo, operacao)) {
            let descricaoOperacao = operacao == "Incluir" ? "Inclusão" : operacao == "Alterar" ? "Alteração" : "Exclusão";

            this.mensagem = `${this.usuarioLogadoDados.profissional.nome}, você não possui permissão para realizar ${descricaoOperacao} com esse Perfil.`;
            this.$bvModal.show("modal-mensagem");

            return false;
          }

          return true;
        }        
    },
    async created() {
      this.showMenu(false);

      await this.carregarGuiasDoPaciente(this.usuarioLogadoDados.tenant, this.pacienteSelecionado.id, "S", "PacienteSessoes");
      if (this.guias.length > 0) {
        this.exibirSessoes(this.guias[0]);
      }

      await this.carregarProfissionaisSolicitantes(this.usuarioLogadoDados.tenant, 0);
      this.profissionaisSolicitantes = [...this.profissionais];

      await this.carregarProfissionaisFisioterapeutas(this.usuarioLogadoDados.tenant, 0);
      this.profissionaisExecutantes = [...this.profissionais];

      this.profissionais = [];
    },
    mounted() {
    }
}
</script>

<style scoped>
    label {
      font-weight: 100%;
      font-size: 0.8rem;
      margin-top: 3px;
      margin-bottom: 1px;
    }
</style>
