<template>
  <div id="CadUnidade">
    <div class="cabecalho">
      <button @click="retornar" class="btn btn-sm btn-primary mr-10"><i class="fa fa-arrow-left"/></button>
    </div>

    <div class="tituloPagina">
        <span v-if="unidadeSelecionada.id>0">{{unidadeSelecionada.descricao}}</span>
        <span v-else>Cadastro de Nova Unidade</span>
        <hr/>
    </div>

    <div class="container-fluid">
      <div class="corpo">
        <b-card no-body style="padding:0px">
          <b-tabs card>
            <b-tab title="Dados Gerais" active>
                <cadUnidadeDados/>
            </b-tab>
            <b-tab title="Datas Operações">
                <cadUnidadeDatas/>
            </b-tab>
            <b-tab title="Financeiro">
                <cadUnidadeFinanceiro/>
            </b-tab>
            <b-tab title="Faturamento">
                <cadUnidadeFaturamento/>
            </b-tab>
            <b-tab title="Médicos">
                <cadUnidadeMedicos/>
            </b-tab>
            <b-tab title="Recepção">
                <cadUnidadeRecepcao/>
            </b-tab>
          </b-tabs>
        </b-card>

        <p v-if="erro!=''" style="background-color:red; color:white; text-align:center">{{erro}}</p>

        <div class="barraopcoescadastro">
          <button
            id="Alterar"
            :disabled="modo=='Incluir' || modo=='Alterar'"
            @click="alterarRegistro()"
            class="btn btn-sm btn-secondary mr-2"
          >Alterar</button>
          <button
            id="Cancelar"
            @click="cancelarOperacao()"
            class="btn btn-sm btn-secondary mr-2"
          >Cancelar</button>
          <button
            id="Gravar"
            :disabled="!liberarCampos"
            @click="gravarRegistro()"
            class="btn btn-sm btn-secondary mr-2"
          >Gravar</button>
        </div>
      </div>
    </div>
    <div class="Mensagem">
      <b-modal id="modal-mensagem" centered title="DoctorOn">
        <p class="my-4">{{mensagem}}</p>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import cadUnidadeDados from './cadUnidadeDados.vue'
import cadUnidadeDatas from './cadUnidadeDatas.vue'
import cadUnidadeFinanceiro from './cadUnidadeFinanceiro.vue'
import cadUnidadeFaturamento from './cadUnidadeFaturamento.vue'
import cadUnidadeMedicos from './cadUnidadeMedicos.vue'
import cadUnidadeRecepcao from './cadUnidadeRecepcao.vue'
import global from "@/mixins/global"
import errors from "@/utils/errors"

export default {
  mixins : [global],
  components: {
    cadUnidadeDados, cadUnidadeDatas, 
    cadUnidadeFinanceiro, cadUnidadeFaturamento, cadUnidadeMedicos, cadUnidadeRecepcao
    // cadUnidadeFaturamento, cadUnidadeFinanceiro,
    // cadUnidadeObservacoes, cadUnidadeRecebimento, cadUnidadeTiss
  },
  computed: {
    ...mapGetters({
      modo: "unidades/getModo",
      unidadeSelecionada : "unidades/getUnidadeSelecionada",
      usuarioLogadoDados : "global/getUsuarioLogadoDados"
    }),
    liberarCampos() {
      return this.modo == "Incluir" || this.modo == "Alterar" ? true : false;
    }
  },
  data() {
    return {
      componente: "cadUnidadeDados",
      erro: "",
      mensagem : "",
    }
  },
  methods: {
    ...mapActions({
      setModo : "unidades/setModo",
      limparRegistroSelecionado : "unidades/limparRegistroSelecionado",
      formLeave : "unidades/formLeave"
    }),
    retornar() {
      this.$router.go(-1);
    },
    gravarRegistro() {
      if (!this.verificarCamposPreenchidos()) {
        return;
      }

      if (this.modo=="Alterar") {
        this.unidadeSelecionada.operadorAlteracaoId = this.usuarioLogadoDados.profissionalId;

        this.$http
          .put(
            `/v1.0/unidades/${this.usuarioLogadoDados.tenant}/${this.unidadeSelecionada.id}`,
            this.unidadeSelecionada
          )
          .then(() => {
            this.erro = "";
            this.setModo("Registro");
            this.$toasted.global.defaultSuccess();
          })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          })
          .finally(() => {});
      } else {
        this.unidadeSelecionada.operadorCadastroId = this.usuarioLogadoDados.profissionalId;

        this.$http
          .post(`/v1.0/unidades/${this.usuarioLogadoDados.tenant}`, this.unidadeSelecionada)
          .then(() => {
            this.erro = "";
            this.setModo("Registro");
            this.$toasted.global.defaultSuccess();

            this.retornar();
          })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          })
          .finally(() => {});
      }
    },
    alterarRegistro() {
      if (!this.verificarPermissao('Alterar')) {
        return
      }
      
      this.setModo("Alterar");
    },
    cancelarOperacao() {
      this.setModo("Registro");
    },
    limparCampos() {
      this.limparRegistroSelecionado();
    },
    verificarCamposPreenchidos() {
      if (this.unidadeSelecionada.descricao == "" || this.unidadeSelecionada.descricao == undefined) {
        this.$toasted.show("Digite a Unidade", {type: 'info', icon: 'times'});
        return false;
      }

      if (this.unidadeSelecionada.ativa == "" || this.unidadeSelecionada.ativa == undefined) {
        this.$toasted.show("Selecione se a Unidade está Ativa", {type: 'info', icon: 'times'});
        return false;
      }

      if (this.unidadeSelecionada.logradouro == "" || this.unidadeSelecionada.logradouro == undefined) {
        this.$toasted.show("Digite o Logradouro da Unidade", {type: 'info', icon: 'times'});
        return false;
      }

      if (this.unidadeSelecionada.numero == 0 || this.unidadeSelecionada.numero == undefined) {
        this.$toasted.show("Digite o Número do Logradouro da Unidade", {type: 'info', icon: 'times'});
        return false;
      }

      if (this.unidadeSelecionada.bairro == "" || this.unidadeSelecionada.bairro == undefined) {
        this.$toasted.show("Digite o Bairro da Unidade", {type: 'info', icon: 'times'});
        return false;
      }

      if (this.unidadeSelecionada.cidade == "" || this.unidadeSelecionada.cidade == undefined) {
        this.$toasted.show("Digite a Cidade da Unidade", {type: 'info', icon: 'times'});
        return false;
      }

      if (this.unidadeSelecionada.uf == "" || this.unidadeSelecionada.uf == undefined) {
        this.$toasted.show("Selecione a UF da Unidade", {type: 'info', icon: 'times'});
        return false;
      }

      if (this.unidadeSelecionada.fone == "" || this.unidadeSelecionada.fone == undefined) {
        this.$toasted.show("Digite o Fone da Unidade", {type: 'info', icon: 'times'});
        return false;
      }

      if (this.unidadeSelecionada.email == "" || this.unidadeSelecionada.email == undefined) {
        this.$toasted.show("Digite o E-mail da Unidade", {type: 'info', icon: 'times'});
        return false;
      }

      if (this.unidadeSelecionada.fonesConfirmacao == "" || this.unidadeSelecionada.fonesConfirmacao == undefined) {
        this.$toasted.show("Digite o Fones de Confirmação da Unidade", {type: 'info', icon: 'times'});
        return false;
      }

      if (this.unidadeSelecionada.emailConfirmacao == "" || this.unidadeSelecionada.emailConfirmacao == undefined) {
        this.$toasted.show("Digite o E-mail de Confirmação da Unidade", {type: 'info', icon: 'times'});
        return false;
      }
      //--------------------------------------- Faturamento
      if (this.unidadeSelecionada.excluirLancto == "" || this.unidadeSelecionada.excluirLancto == undefined) {
        this.$toasted.show("Selecione se 'Excluir após Lancto'", {type: 'info', icon: 'times'});
        return false;
      }

      if (this.unidadeSelecionada.excluirLanctoVerificado == "" || this.unidadeSelecionada.excluirLanctoVerificado == undefined) {
        this.$toasted.show("Selecione se 'Excluir após Verificado'", {type: 'info', icon: 'times'});
        return false;
      }

      if (this.unidadeSelecionada.excluirLanctoFaturaFechada == "" || this.unidadeSelecionada.excluirLanctoFaturaFechada == undefined) {
        this.$toasted.show("Selecione se 'Excluir após Fatura Fechada'", {type: 'info', icon: 'times'});
        return false;
      }

      if (this.unidadeSelecionada.excluirLanctoLoteFechado == "" || this.unidadeSelecionada.excluirLanctoLoteFechado == undefined) {
        this.$toasted.show("Selecione se 'Excluir após Lote Fechado'", {type: 'info', icon: 'times'});
        return false;
      }

      if (this.unidadeSelecionada.atendimentoSeriado == "" || this.unidadeSelecionada.atendimentoSeriado == undefined) {
        this.$toasted.show("Selecione se 'Realiza Atend.Seriado'", {type: 'info', icon: 'times'});
        return false;
      }

      if (this.unidadeSelecionada.exibirAlertaMaterial == "" || this.unidadeSelecionada.exibirAlertaMaterial == undefined) {
        this.$toasted.show("Selecione se 'Exibir Alerta Materiais'", {type: 'info', icon: 'times'});
        return false;
      }

      if (this.unidadeSelecionada.utilizaAgendaCirurgias == "" || this.unidadeSelecionada.utilizaAgendaCirurgias == undefined) {
        this.$toasted.show("Selecione se 'Utiliz. Agenda Cirurgias'", {type: 'info', icon: 'times'});
        return false;
      }

      if (this.unidadeSelecionada.lancarConsultaAutomatica == "" || this.unidadeSelecionada.lancarConsultaAutomatica == undefined) {
        this.$toasted.show("Selecione se 'Lançar Consulta Automática'", {type: 'info', icon: 'times'});
        return false;
      }

      if (this.unidadeSelecionada.codProMatMedIsolados == "" || this.unidadeSelecionada.codProMatMedIsolados == undefined) {
        this.$toasted.show("Digite Cód. Despesas Isoladas", {type: 'info', icon: 'times'});
        return false;
      }
      
      if (this.unidadeSelecionada.codProPagtoDividas == "" || this.unidadeSelecionada.codProPagtoDividas == undefined) {
        this.$toasted.show("Digite Cód. Pagto Dívidas Pacientes ", {type: 'info', icon: 'times'});
        return false;
      }

      //--------------------------------------- Médicos
      if (this.unidadeSelecionada.bloquearProntuariosApos == undefined) {
        this.$toasted.show("Digite Bloq. Pront. Após (dias)", {type: 'info', icon: 'times'});
        return false;
      }
      
      if (this.unidadeSelecionada.loginSelecionarSala == "" || this.unidadeSelecionada.loginSelecionarSala == undefined) {
        this.$toasted.show("Selecione se 'Informar Sala no Login'", {type: 'info', icon: 'times'});
        return false;
      }
      //--------------------------------------- Recepção

      if (this.unidadeSelecionada.loginAbrirCaixa == "" || this.unidadeSelecionada.loginAbrirCaixa == undefined) {
        this.$toasted.show("Selecione se 'Abrir Caixa após Login'", {type: 'info', icon: 'times'});
        return false;
      }
      
      if (this.unidadeSelecionada.removerAgendamentoDuplicado == "" || this.unidadeSelecionada.removerAgendamentoDuplicado == undefined) {
        this.$toasted.show("Selecione se 'Remover Agend. Duplicado'", {type: 'info', icon: 'times'});
        return false;
      }
      
      if (this.unidadeSelecionada.statusEsperaExibirPendencias == "" || this.unidadeSelecionada.statusEsperaExibirPendencias == undefined) {
        this.$toasted.show("Selecione se 'Exibir Pendência em Espera'", {type: 'info', icon: 'times'});
        return false;
      }
      
      if (this.unidadeSelecionada.permiteCancelarBloqueioAgenda == "" || this.unidadeSelecionada.permiteCancelarBloqueioAgenda == undefined) {
        this.$toasted.show("Selecione se 'Cancelar Bloqueio na Agenda'", {type: 'info', icon: 'times'});
        return false;
      }
      
      if (this.unidadeSelecionada.agendarAposLimite == "" || this.unidadeSelecionada.agendarAposLimite == undefined) {
        this.$toasted.show("Selecione se 'Permitir Agendar Após Limite'", {type: 'info', icon: 'times'});
        return false;
      }
      
      if (this.unidadeSelecionada.nivelSegurancaAgenda == "" || this.unidadeSelecionada.nivelSegurancaAgenda == undefined) {
        this.$toasted.show("Selecione o Nível Segurança", {type: 'info', icon: 'times'});
        return false;
      }
      
      return true;
    },
    verificarPermissao(operacao) {
      if (!errors.verificarPermissao(this.usuarioLogadoDados, this.$store.state.global.grauPermissaoMinimo, operacao)) {
        let descricaoOperacao = operacao == "Incluir" ? "Inclusão" : operacao == "Alterar" ? "Alteração" : "Exclusão";

        this.mensagem = `${this.usuarioLogadoDados.profissional.nome}, você não possui permissão para realizar ${descricaoOperacao} com esse Perfil.`;
        this.$bvModal.show("modal-mensagem");

        return false;
      }

      return true;
    }    
  },
  created() {
    this.showMenu(false);
  },
  beforeRouteLeave(to, from, next) {
    if (this.modo == "Alterar" || this.modo == "Incluir") {
      this.$bvModal
        .msgBoxConfirm("Tem certeza que deseja sair sem Gravar os Dados?", {
          title: "Atenção",
          size: "sm",
          buttonSize: "sm",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            this.formLeave();
            next(true);
          } else {
            next(false);
          }
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        });
    } else {
      this.formLeave();
      next(true);
    }
  }
}
</script>

<style scoped>
</style>
