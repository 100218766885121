import {mapActions} from 'vuex'

export default {
    methods: {
        ...mapActions({
            setMenu : "global/menu",
            setToggleMenu : "global/toggleMenu",
            setUserDropDown : "global/userDropDown"
        }),
        showMenu(exibir) {
            this.setMenu(exibir);
        },
        showToggleMenu(exibir) {
            this.setToggleMenu(exibir);
        },
        showUserDropDown(exibir) {
            this.setUserDropDown(exibir);
        },
    }
}