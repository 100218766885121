import errors from "@/utils/errors";

export default {
    data() {
        return {
            filtrar: "",
            modelos: []
        }
    },
    methods: {
        carregarModelos(tenant) {
            if (this.filtrar == "") {
              this.$http
                .get(`/v1.0/modelos/${tenant}`)
                .then(resp => {
                  this.modelos = resp.data;
                  this.erro = "";
                })
                .catch(error => (this.erro = errors.tratarErros(error)))
                .finally(() => {
                  this.loading = false;
                });
            } else {
              this.$http
                .get(`/v1.0/modelos/${tenant}/Descricao/${this.filtrar}`)
                .then(resp => {
                  this.modelos = resp.data;
                  this.erro = "";
                })
                .catch(error => (this.erro = errors.tratarErros(error)))
                .finally(() => {
                  this.loading = false;
                });
            }
        }
    }
}
