const state = {
    centroSelecionado : {},
}

const getters = {
    getCentroSelecionado(state) {
        return state.centroSelecionado;
    },
}

const mutations = {
    setCentroSelecionado(state, centro) {
        state.centroSelecionado = {...centro}
    },
}

const actions = {
    setCentroSelecionado({commit}, centro) {
        commit("setCentroSelecionado", centro)
    }
}

export default {
    namespaced : true,
    state,
    getters,
    mutations,
    actions
}