<template>
    <div>
        <div class="cabecalho">
          <div class="coluna1">
            <button @click="retornar" class="btn btn-sm btn-primary mr-10"><i class="fa fa-arrow-left"/></button>
          </div>
          <div class="coluna2">
            <span>Tratamento Id: {{guiaTratamentoSelecionado.id}} - Particular</span>
          </div>
          <div class="coluna3"/>
        </div>

        <div class="container-fluid">
            <!-- Identificação -->
            <div class="row">
                <div class="form-group col-lg-1">
                    <label for="procedimentoCobertoId" class="control-label">Proc.Id</label>
                    <input type="text" id="procedimentoCobertoId" disabled class="form-control" v-model="guiaTratamentoSelecionado.procedimentoCobertoId"/>
                </div>
                <div class="form-group col-lg-1">
                    <label for="codpro" class="control-label">Código</label>
                    <input type="text" id="codpro" disabled class="form-control" v-model="guiaTratamentoSelecionado.codigoProcedimento"/>
                </div>
                <div class="form-group col-lg-1">
                    <label for="codTuss" class="control-label">TUSS</label>
                    <input type="text" id="codTuss" disabled class="form-control" v-model="guiaTratamentoSelecionado.codigoTuss"/>
                </div>
                <div class="form-group col-lg-7">
                    <label for="descricao" class="control-label">Descrição</label>
                    <input type="text" id="descricao" disabled class="form-control" v-model="guiaTratamentoSelecionado.descricao"/>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-lg-2">
                    <label for="siglaServico" class="control-label">Serviço</label>
                    <select
                      class="form-control"
                      id="servico"
                      name="servico"
                      selected
                      v-model="guiaTratamentoSelecionado.siglaServico"
                    >
                      <option
                        v-for="servico in servicos"
                        :key="servico.id"
                        :value="servico.siglaServico"
                      >{{ servico.descricao }}</option>
                    </select>
                </div>
                <div class="form-group col-lg-5">
                    <label for="observacao" class="control-label">Observação</label>
                    <input type="text" id="observacao" class="form-control" v-model="guiaTratamentoSelecionado.observacao"/>
                </div>
            </div>
            <!-- Valores Calculados -->
            <hr/>
            <div class="row">
                <div class="form-group col-lg-1">
                    <label for="valProcedimentoUnitario" class="control-label">Proced Unit. R$</label>
                    <input type="number" id="valProcedimentoUnitario" class="form-control" v-model.number="guiaTratamentoSelecionado.valProcedimentoUnitario"/>
                </div>
                <div class="form-group col-lg-1">
                    <label for="qtdpro" class="control-label">Qtde</label>
                    <input type="number" id="qtdpro" class="form-control" v-model.number="guiaTratamentoSelecionado.qtdPro"/>
                </div>
                <div class="form-group col-lg-1">
                    <label for="valProcedimentoTotal" class="control-label">Proced Total R$</label>
                    <input type="text" id="valProcedimentoTotal" disabled class="form-control" v-model.number="valProcedimentoTotal"/>
                </div>
                <div class="form-group col-lg-1">
                    <label for="filme" class="control-label">Filme Unit. R$</label>
                    <input type="number" id="filme" class="form-control" v-model.number="guiaTratamentoSelecionado.filme"/>
                </div>
                <div class="form-group col-lg-1">
                    <label for="valDesconto" class="control-label">Desconto R$</label>
                    <input type="number" id="valDesconto" class="form-control" v-model.number="valDesconto"/>
                </div>
                <div class="form-group col-lg-2">
                    <label for="reterDescontoDe" class="control-label">Desconto Concedido Por</label>
                    <select id="reterDescontoDe" class="form-control" selected v-model="reterDescontoDe">
                      <option
                        v-for="resposta in reterDe"
                        :key="resposta.opcao"
                        :value="resposta.opcao"
                      >{{resposta.valor}}</option>
                    </select>
                </div>
                <div class="form-group col-lg-1">
                    <label for="valTratamento" class="control-label">Tratamento R$</label>
                    <input type="text" id="valTratamento" disabled class="form-control" v-model.number="valTratamento"/>
                </div>
                <template v-if="guiaSelecionada.particular=='S'">
                    <div class="form-group col-lg-1">
                        <label for="valReceber" class="control-label">Receber R$</label>
                        <input type="text" id="valReceber" disabled class="form-control" v-model.number="valReceber"/>
                    </div>
                </template>
            </div>
            <hr/>
            <div class="row">
                <!-- Impostos e Taxas sobre o Procedimento -->
                <div class="form-group col-lg-1">
                    <label for="valTaxaCartao" class="control-label">Taxa Cartão R$</label>
                    <input type="number" id="valTaxaCartao" disabled class="form-control" v-model.number="guiaTratamentoSelecionado.valTaxaCartao"/>
                </div>
                <div class="form-group col-lg-2">
                    <label for="ReterTaxaDe" class="control-label">Reter Taxa De</label>
                    <select id="ReterTaxaDe" class="form-control" disabled selected v-model="guiaTratamentoSelecionado.reterTaxaDe">
                      <option
                        v-for="resposta in reterDe"
                        :key="resposta.opcao"
                        :value="resposta.opcao"
                      >{{resposta.valor}}</option>
                    </select>
                </div>
                <div class="form-group col-lg-1">
                    <label for="valImpostos" class="control-label">Imposto R$</label>
                    <input type="number" id="valImpostos" disabled class="form-control" v-model.number="guiaTratamentoSelecionado.valImpostos"/>
                </div>
                <!-- Comissão -->
                <div class="form-group col-lg-1">
                    <label for="comissao" class="control-label">Comissão</label>
                    <input type="number" id="comissao" disabled class="form-control" v-model.number="guiaTratamentoSelecionado.comissao"/>
                </div>
                <div class="form-group col-lg-1">
                    <label for="reterTaxaCartao" class="control-label">Reter Taxa</label>
                    <input type="number" id="reterTaxaCartao" disabled class="form-control" v-model.number="guiaTratamentoSelecionado.reterTaxaCartao"/>
                </div>
                <div class="form-group col-lg-1">
                    <label for="reterImpostos" class="control-label">Reter Impostos</label>
                    <input type="number" id="reterImpostos" disabled class="form-control" v-model.number="guiaTratamentoSelecionado.reterImpostos"/>
                </div>
                <div class="form-group col-lg-1">
                    <label for="reterDesconto" class="control-label">Reter Desconto</label>
                    <input type="number" id="reterDesconto" :disabled="reterDescontoDe != '3'" class="form-control" v-model.number="reterDesconto"/>
                </div>

            </div>
            <!-- Sessões -->
            <hr/>
            <p><h4>Atendimentos Seriados</h4>
            <div class="row">
                <div class="form-group col-lg-2">
                    <label for="aplicarATodasAsSessoes" class="control-label">Aplicar à Todas Sessões</label>
                    <select id="aplicarATodasAsSessoes" class="form-control" selected v-model="guiaTratamentoSelecionado.aplicarATodasAsSessoes">
                      <option
                        v-for="resposta in sim_nao"
                        :key="resposta.opcao"
                        :value="resposta.opcao"
                      >{{resposta.valor}}</option>
                    </select>
                </div>
                <div class="form-group col-lg-2">
                    <label for="aplicarSessoesDatInicial" class="control-label">Sessão Inicial</label>
                    <input type="date" id="aplicarSessoesDatInicial" class="form-control" v-model="guiaTratamentoSelecionado.aplicarSessoesDatInicial"/>
                </div>
                <div class="form-group col-lg-2">
                    <label for="aplicarSessoesDatFinal" class="control-label">Sessão Final</label>
                    <input type="date" id="aplicarSessoesDatFinal" class="form-control" v-model="guiaTratamentoSelecionado.aplicarSessoesDatFinal"/>
                </div>
            </div>
            <hr/>
            <div class="row justify-content-center">
              <button class="btn btn-sm btn-danger" style="margin-bottom:10px;"
                @click="retornar()">Cancelar
              </button>
              <button class="btn btn-sm btn-info ml-1" style="margin-bottom:10px;"
                @click="gravarAlteracoes()">Confirmar
              </button>
            </div>
            <!-- Exibição de Erros -->
            <template v-if="this.erro!=''">
              <p class="msgError">Erro durante Operação: {{ this.erro }}</p>
            </template>
        </div>
    </div>    
</template>

<script>
import {mapGetters} from "vuex";
import constantes from "@/utils/constantes";
import servicos from "@/mixins/servicos"
import global from "@/mixins/global"
import errors from "@/utils/errors"

export default {
    mixins : [servicos, global],
    computed: {
        ...mapGetters({
            guiaSelecionada : "guias/getGuiaSelecionada",
            guiaTratamentoSelecionado : "guiaTratamentos/getGuiaTratamentoSelecionado",
            usuarioLogadoDados : "global/getUsuarioLogadoDados",
        }),
        valProcedimentoTotal()  {
            return this.guiaTratamentoSelecionado.valProcedimentoUnitario * this.guiaTratamentoSelecionado.qtdPro;
        },
        valTratamento() {
            return this.valProcedimentoTotal +
                   (this.guiaTratamentoSelecionado.filme * this.guiaTratamentoSelecionado.qtdPro);
        },
        valReceber() {
            return this.valProcedimentoTotal +
                   (this.guiaTratamentoSelecionado.filme * this.guiaTratamentoSelecionado.qtdPro)-this.valDesconto;
        },
    },
    watch: {
        reterDescontoDe()  {
            if (this.reterDescontoDe == '1') {
                //----------------------- Desconto Concedido pelo Profissional
                this.reterDesconto = this.valDesconto;
            } else {
                if (this.reterDescontoDe == '3') {
                    //----------------------- Desconto Concedido por Profissional e Clínica
                    if (!this.reterDescontoJaGravado) {
                        let proporcional = (this.guiaTratamentoSelecionado.comissao) / (this.guiaTratamentoSelecionado.valProcedimentoTotal);
                        this.reterDesconto = this.valDesconto * proporcional;
                    }
                } else { 
                    //----------------------- Desconto Concedido pela Clínica
                    this.reterDesconto = 0;
                }
            }
        },
        valDesconto() {
            if (this.valDesconto > this.valTratamento) {
                this.valDesconto = this.valTratamento
            }

            if (this.reterDescontoDe == '1') {
                //----------------------- Desconto Concedido pelo Profissional
                this.reterDesconto = this.valDesconto;
            } else {
                if (this.reterDescontoDe == '3') {
                    //----------------------- Desconto Concedido por Profissional e Clínica
                    if (!this.reterDescontoJaGravado) {
                        let proporcional = (this.guiaTratamentoSelecionado.comissao) / (this.guiaTratamentoSelecionado.valProcedimentoTotal);
                        this.reterDesconto = this.valDesconto * proporcional;
                    }
                } else { 
                    //----------------------- Desconto Concedido pela Clínica
                    this.reterDesconto = 0;
                }
            }
        },
        reterDesconto() {
            if (this.reterDesconto > this.valDesconto) {
                this.reterDesconto = this.valDesconto
            }
        }
    },
    data() {
        return {
            reterDe : constantes.taxaPagaPor,
            sim_nao : constantes.sim_nao,
            erro : "",
            reterDescontoDe : '1',
            valDesconto : 0,
            reterDesconto : 0,
            reterDescontoJaGravado : false
        }
    },
    methods: {
        retornar() {
            this.$router.go(-1);
        },
        gravarAlteracoes() {
            this.guiaTratamentoSelecionado.reterDescontoDe = this.reterDescontoDe;
            this.guiaTratamentoSelecionado.valDesconto = this.valDesconto;
            this.guiaTratamentoSelecionado.reterDesconto = this.reterDesconto;

            if (this.guiaTratamentoSelecionado.valDesconto > 0) {
                if (this.guiaTratamentoSelecionado.reterDescontoDe==undefined) {
                    this.$toasted.show(`Informe quem está concedendo o Desconto`, {type: "info", icon: "times"});
                    return;
                }

                if (this.valReceber < 0) {
                    this.$toasted.show(`Valor de Desconto Inválido`, {type: "info", icon: "times"});
                    return;
                }

                if (this.guiaTratamentoSelecionado.reterDescontoDe == '3') {
                    if (this.guiaTratamentoSelecionado.reterDesconto <= 0) {
                       this.$toasted.show(`Informe o valor de Desconto concedido pelo Profissional`, {type: "info", icon: "times"});
                       return;
                    } else if (this.guiaTratamentoSelecionado.reterDesconto >=this.guiaTratamentoSelecionado.valDesconto) {
                       this.$toasted.show(`Valor a Reter de Desconto não pode ser maior ou igual ao Desconto Concedido`, {type: "info", icon: "times"});
                       return;
                    }
                }
            } else {
               this.guiaTratamentoSelecionado.valDesconto = 0;
               this.guiaTratamentoSelecionado.reterDesconto = 0; 
               this.guiaTratamentoSelecionado.reterDescontoDe = undefined;
            }

            this.guiaTratamentoSelecionado.valTratamento = this.valTratamento;
            this.guiaTratamentoSelecionado.valProcedimentoTotal = this.valProcedimentoTotal;
            this.guiaTratamentoSelecionado.honorario = this.honorario;
            this.guiaTratamentoSelecionado.custoOperacional = this.custoOperacional;

            this.guiaTratamentoSelecionado.operadorAlteracaoId = this.usuarioLogadoDados.profissionalId;

            this.$http.put(`/v1.0/guiatratamentos/${this.usuarioLogadoDados.tenant}/${this.guiaTratamentoSelecionado.id}`, this.guiaTratamentoSelecionado)
            .then(() => {
                this.$toasted.global.defaultSuccess();
                this.$router.go(-1);
            })
            .catch(error => {
                this.erro = errors.tratarErros(error);
            })
        }
    },
    created() {
        this.showMenu(false);
        this.carregarServicos(this.usuarioLogadoDados.tenant);

        this.reterDescontoJaGravado = this.guiaTratamentoSelecionado.reterDesconto > 0 && this.guiaTratamentoSelecionado.reterDescontoDe == '3';
        this.reterDesconto = this.guiaTratamentoSelecionado.reterDesconto;
        this.reterDescontoDe = this.guiaTratamentoSelecionado.reterDescontoDe;
        this.valDesconto = this.guiaTratamentoSelecionado.valDesconto;
    }
}
</script>

<style scoped>
  label {
    font-weight: 100%;
    font-size: 0.8rem;
    margin-top: 3px;
    margin-bottom: 1px;
  }
</style>
