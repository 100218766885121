<template>
  <div>
    <div class="cabecalho">
      <div class="coluna1">
        <button @click="retornar" class="btn btn-sm btn-primary mr-10"><i class="fa fa-arrow-left"/></button>
      </div>
      <div class="coluna2">
      <span>Paciente {{parametrosLanctoGuia.paciente.nome}} - Prontuário {{parametrosLanctoGuia.paciente.id}}</span>
      </div>
      <div class="coluna3"/>
    </div>  

    <div class="tituloPagina">
        <span>Tipo {{ guiaSelecionada.tipoLote == 1 ? "Consulta" : guiaSelecionada.tipoLote == 2 ? "SADT" : "Honorários"}}
              - Guia no Prestador: {{guiaSelecionada.guiaPrestador}}</span>
      <hr />
    </div>

    <div class="container-fluid">
      <div class="row" v-if="modo==''">
        <div class="col-12">
          <button
            class="btn btn-sm btn-info"
            @click="incluirRegistro()"
          >Adicionar Novo Ítem no Carrinho</button>
          <hr/>
        </div>
      </div>

      <div v-if="modo!=''" class="registro">
        <div class="row">
          <div class="col-lg-12">
            <h4 v-if="modo=='Incluir'" style="text-align:left">Adicionar Novo Ítem no Carrinho</h4>
            <h4 v-else style="text-align:left">{{itemSelecionado.descricao}}</h4>
            <hr />
          </div>
        </div>

        <div class="row" v-if="modo=='Incluir'">
          <!-- Radio Group para Selecionar o que deseja Incluir -->
          <div class="col-lg-4">
            <label class="control-label">Deseja Adicionar</label>
            <div class="form-group">
              <input
                type="radio"
                name="opcao"
                id="procedimento"
                value="Procedimento"
                v-model="opcaoAdicionar"
              />
              <label for="procedimento" class="control-label ml-2 mr-2">Procedimentos</label>

              <input
                type="radio"
                name="opcao"
                id="medicamento"
                value="Medicamento"
                v-model="opcaoAdicionar"
              />
              <label for="medicamento" class="control-label ml-2 mr-2">Medicamentos</label>

              <input
                type="radio"
                name="opcao"
                id="material"
                value="Material"
                v-model="opcaoAdicionar"
              />
              <label for="material" class="control-label ml-2 mr-2">Materiais</label>

              <input type="radio" name="opcao" id="taxa" value="Taxa" v-model="opcaoAdicionar" />
              <label for="taxa" class="control-label ml-2 mr-2">Taxas/Diárias</label>

              <input type="radio" name="opcao" id="kit" value="Kit" v-model="opcaoAdicionar" />
              <label for="kit" class="control-label ml-2 mr-2">Kits</label>
            </div>
          </div>
          <!-- Radio Group para Selecionar por qual Campo Buscar as informações -->
          <div class="col-lg-4">
            <!-- Procedimento -->
            <template v-if="opcaoAdicionar=='Procedimento'">
              <label class="control-label">Buscar Procedimento por:</label>
              <div class="form-group">
                <input
                  type="radio"
                  name="opcaoBusca"
                  id="codPro"
                  value="codigoProcedimento"
                  v-model="campoBusca"
                />
                <label for="codPro" class="control-label ml-2 mr-2">Código</label>

                <input
                  type="radio"
                  name="opcaoBusca"
                  id="codTussProcedimento"
                  value="codigoTuss"
                  v-model="campoBusca"
                />
                <label for="codTussProcedimento" class="control-label ml-2 mr-2">Cód. TUSS</label>

                <input
                  type="radio"
                  id="descricaoProcedimento"
                  value="Descricao"
                  v-model="campoBusca"
                />
                <label for="descricaoProcedimento" class="control-label ml-2 mr-2">Descrição</label>

                <input type="radio" name="opcaoBusca" id="descricaoTussProcedimento" value="DescricaoTuss" v-model="campoBusca" />
                <label for="descricaoTussProcedimento" class="control-label ml-2 mr-2">Descr. TUSS</label>
              </div>
            </template>
            <!-- Medicamento/Material -->
            <template v-else-if="opcaoAdicionar=='Medicamento' || opcaoAdicionar=='Material'">
              <label v-if="opcaoAdicionar=='Medicamento'" class="control-label">Buscar Medicamento por:</label>
              <label v-else class="control-label">Buscar Material por:</label>
              <div class="form-group">
                <input
                  type="radio"
                  name="opcaoBusca"
                  disabled
                  id="codBarras"
                  value="CodBarras"
                  v-model="campoBusca"
                />
                <label for="codBarras" class="control-label ml-2 mr-2">Barras</label>

                <input
                  type="radio"
                  name="opcaoBusca"
                  id="descricaoMaterial"
                  value="Descricao"
                  v-model="campoBusca"
                />
                <label for="descricaoMaterial" class="control-label ml-2 mr-2">Descrição</label>

                <input
                  type="radio"
                  name="opcaoBusca"
                  disabled
                  id="descricaoSimpro"
                  value="ApresentacaoSimpro"
                  v-model="campoBusca"
                />
                <label for="descricaoSimpro" class="control-label ml-2 mr-2">Simpro</label>

                <input
                  type="radio"
                  name="opcaoBusca"
                  disabled
                  id="descricaoBrasindice"
                  value="ApresentacaoBrasindice"
                  v-model="campoBusca"
                />
                <label for="descricaoBrasindice" class="control-label ml-2 mr-2">Brasindice</label>
              </div>
            </template>
            <!-- Taxa -->
            <template v-else-if="opcaoAdicionar=='Taxa'">
              <label class="control-label">Buscar Taxa por:</label>
              <div class="form-group">
                <input
                  type="radio"
                  name="opcaoBusca"
                  id="codTISSTaxa"
                  value="Codigo"
                  v-model="campoBusca"
                />
                <label for="codTISSTaxa" class="control-label ml-2 mr-2">Código</label>

                <input
                  type="radio"
                  name="opcaoBusca"
                  id="codTUSSTaxa"
                  value="CodTuss"
                  v-model="campoBusca"
                />
                <label for="codTUSSTaxa" class="control-label ml-2 mr-2">TUSS</label>

                <input
                  type="radio"
                  name="opcaoBusca"
                  id="descricaoTaxa"
                  value="Descricao"
                  v-model="campoBusca"
                />
                <label for="descricaoTaxa" class="control-label ml-2 mr-2">Descrição</label>
              </div>
            </template>
            <!-- Kit -->
            <template v-else-if="opcaoAdicionar=='Kit'">
              <label class="control-label">Buscar Kit por:</label>
              <div class="form-group">
                <input
                  type="radio"
                  name="opcaoBusca"
                  id="codKit"
                  value="Codigo"
                  v-model="campoBusca"
                />
                <label for="codKit" class="control-label ml-2 mr-2">Código</label>

                <input
                  type="radio"
                  name="opcaoBusca"
                  id="codPacote"
                  value="codPacote"
                  v-model="campoBusca"
                />
                <label for="codPacote" class="control-label ml-2 mr-2">Código Pacote</label>

                <input
                  type="radio"
                  name="opcaoBusca"
                  id="descricaoKit"
                  value="Descricao"
                  v-model="campoBusca"
                />
                <label for="descricaoKit" class="control-label ml-2 mr-2">Descrição</label>
              </div>
            </template>
          </div>
          <!-- Campo para digitação do que se deseja Buscar e Botão para realizar a busca -->
          <template v-if="campoBusca!=''" >
            <div class="col-lg-2">
              <div class="form-group" style="padding-top:16px">
                <input type="text" class="form-control" id="buscar" v-model="filtrar" placeholder="Digite o que deseja localizar..."/>
              </div>
            </div>
            <div class="col-lg-1" >
              <div class="form-group" style="width:80px;margin-left:0px;padding-top:16px">
                <button id="btnFiltrar" class="form-control" @click="carregarRegistros()">
                  <i class="fa fa-search"/>
                </button>
              </div>
            </div>
          </template>
        </div>

        <!-- Select com o resultado da Busca após clicar no botão de Busca -->
        <div class="row" v-if="modo=='Incluir' && opcaoAdicionar!=''">
          <div class="col-lg-8 ml-2">
            <div class="form-group">
              <!-- Select Procedimentos -->
              <label for="selecionarItem" class="control-label">{{opcaoAdicionar}}</label>
              <select
                v-if="opcaoAdicionar=='Procedimento'"
                id="selecionarItem"
                class="form-control"
                v-model="itemSelecionado.descricao"
                @change="selecionouItemDoList()"
              >
                <option
                  v-for="procedimento in procedimentosCobertosDaOperadora"
                  :key="procedimento.id"
                  :value="procedimento.id+'-'+procedimento.codigoProcedimento+'*'+procedimento.descricao"
                >{{procedimento.descricao}}</option>
              </select>
              <!-- Select Materiais -->
              <select
                v-else-if="opcaoAdicionar=='Material'"
                id="selecionarItem"
                class="form-control"
                v-model="itemSelecionado.descricao"
                @change="selecionouItemDoList()"
              >
                <option
                  v-for="material in listaMateriais"
                  :key="material.id"
                  :value="material.id+'-'+material.descricao"
                >{{material.descricao}}</option>
              </select>
              <!-- Select Medicamentos -->
              <select
                v-else-if="opcaoAdicionar=='Medicamento'"
                id="selecionarItem"
                class="form-control"
                v-model="itemSelecionado.descricao"
                @change="selecionouItemDoList()"
              >
                <option
                  v-for="medicamento in listaMedicamentos"
                  :key="medicamento.id"
                  :value="medicamento.id+'-'+medicamento.descricao"
                >{{medicamento.descricao}}</option>
              </select>
              <!-- Select Taxas -->
              <select
                v-else-if="opcaoAdicionar=='Taxa'"
                id="selecionarItem"
                class="form-control"
                v-model="itemSelecionado.descricao"
                @change="selecionouItemDoList()"
              >
                <option
                  v-for="despesaCoberta in despesasCobertas"
                  :key="despesaCoberta.id"
                  :value="despesaCoberta.id+'-'+despesaCoberta.descricao"
                >{{despesaCoberta.descricao}}</option>
              </select>
              <!-- Select Kits -->
              <select
                v-else-if="opcaoAdicionar=='Kit'"
                id="selecionarItem"
                class="form-control"
                v-model="itemSelecionado.descricao"
                @change="selecionouItemDoList()"
              >
                <option
                  v-for="kit in kits"
                  :key="kit.id"
                  :value="kit.id+'-'+kit.descricao"
                >{{kit.descricao}}</option>
              </select>
            </div>
          </div>

          <!-- Campos para informar a Quantidade a ser lançada e Valor (no caso de particulares) -->
          <div class="col-1 ml-2">
            <div class="form-group">
              <label for="qtde" class="control-label">Qtde</label>
              <input type="number" class="form-control" id="qtde" v-model.number="qtde" />
            </div>
          </div>
          <div class="col-1" v-if="operadora.particular=='S'">
            <div class="form-group">
              <label for="valor" class="control-label">Valor Unit. R$</label>
              <input type="number" class="form-control" id="valor" v-model.number="valorUnitario" />
            </div>
          </div>
        </div>

        <div class="row justify-content-center" v-if="modo=='Alterar'">
          <!-- Campos para informar a Quantidade a ser lançada e Valor (no caso de particulares) -->
          <div class="col-1 ml-2">
            <div class="form-group">
              <label for="qtde" class="control-label">Qtde</label>
              <input type="number" class="form-control" id="qtde" v-model="qtde" />
            </div>
          </div>
          <div class="col-1">
            <div class="form-group">
              <label for="valor" class="control-label">Valor Unit. R$</label>
              <input type="number" class="form-control" id="valor" v-model="valorUnitario" />
            </div>
          </div>
        </div>

        <!-- Botões para Confirmar (Adicionar) ou Cancelar -->
        <div class="row justify-content-center" v-if="opcaoAdicionar!=''">
          <div class="col-3" v-if="opcaoAdicionar!=''">
            <div class="form-group">
              <button class="btn btn-sm btn-warning mr-2" @click="cancelarOperacao">Cancelar</button>
              <button v-if="modo=='Incluir'" class="btn btn-sm  btn-info" @click="adicionarItem">Adicionar {{opcaoAdicionar}}</button>
              <button v-else class="btn btn-sm  btn-info" @click="alterarItem">Confirmar</button>
            </div>
          </div>
        </div>
      </div>

      <!-- Mensagens de Erro -->
      <template v-if="this.erro!=''">
        <p class="msgError">Erro durante Operação: {{ this.erro }}</p>
      </template>

      <!-- Tabela com ítens do Carrinho -->
      <div style="display:flex; flex-direction:row; justify-content:center;max-height:400px;overflow:auto">
        <b-spinner v-if="loading" label="carregando..."/>
        <b-table v-if="!loading" hover striped small sort-icon-left 
          :items="itensCarrinho" :fields="camposTabela">
          <template v-slot:cell(actions)="row">
            <b-button variant="warning" size="sm" class="mr-2" @click="editarItem(row.item)">
              <i class="fa fa-pencil"/>
            </b-button>
            <b-button variant="danger" size="sm" @click="removerItem(row.item)">
              <i class="fa fa-minus"/>
            </b-button>
          </template>

          <template v-slot:cell(tipo)="tipo">
            {{tipo.value==1 ? "Proc" : tipo.value==2 ? "Med" : tipo.value==3 ? "Mat" : tipo.value == 7 ? "Taxa" : "Kit"}}
          </template>
        </b-table>
      </div>

      <span><Strong>Valor Total Carrinho: R$ {{valorTotalCarrinho}}</Strong></span>

      <!-- Botões para Confirmar (Adicionar) ou Cancelar Gravação Itens do Carrinho-->
      <div class="registro" v-if="itensCarrinho.length>0">
        <div class="row justify-content-center mt-2">
          <div class="col-3">
            <div class="form-group">
              <button class="btn btn-sm btn-danger mt-2 mr-2" @click="limparCarrinho()">Esvaziar Carrinho</button>
              <button class="btn btn-sm  btn-info mt-2" @click="confirmarGravarRegistro()">Gravar Itens do Carrinho</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="Mensagem">
      <b-modal id="modal-mensagem" centered title="DoctorOn">
        <p class="my-4">{{mensagem}}</p>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import procedimentosCobertos from "@/mixins/procedimentosCobertos";
import despesas from "@/mixins/despesas";
import despesasCobertas from "@/mixins/despesasCobertas";
import operadoras from "@/mixins/operadoras";
import kits from "@/mixins/kits";
import global from "@/mixins/global";
import errors from "@/utils/errors";

export default {
  mixins : [procedimentosCobertos, despesas, despesasCobertas, operadoras, kits, global],
  computed: {
    ...mapGetters({
      guiaSelecionada : "guias/getGuiaSelecionada",
      planoSaudeSelecionado: "planosSaude/getPlanoSaudeSelecionado",
      parametrosLanctoGuia : "guias/getParametrosLanctoGuia",
      usuarioLogadoDados : "global/getUsuarioLogadoDados"
    })
  },
  data() {
    return {
      filtrar : "",
      campoBusca : "",
      mensagem : "",

      loading: false,
      loadedProcedimentosCobertos : false,      
      erro: "",
      modo: "Incluir",
      retornando: false,
      opcaoAdicionar: "",

      listaMateriais: null,
      listaMedicamentos: null,

      exibirAdicaoItem: false,

      itensCarrinho : [],
      itemSelecionado : {},

      resultadoGravacao : [],

      operadoraId : 0,
      operadora : {},

      camposTabela : [
        {key : "grupo", label : "Grupo", sortable : true},
        {key : "tipo", label : "Tipo", sortable : true},
        {key : "id", label : "Id"},
        {key : "codigo", label : "Codigo", sortable : true},
        {key : "codTuss", label : "Tuss", sortable : true},
        {key : "descricao", label : "Descrição", sortable : true},
        {key : "qtde", label : "Qtde"},
        {key : "unidade", label : "Unidade"},
        {key : "valorUnitario", label : "Valor Unit. R$"},
        {key : "valorTotal", label : "Valor Total R$"},
        {key : "resultadoLancamento", label : "Resultado"},
        {key : "actions", label : "Ações"},
      ],

      qtde : 1,
      valorUnitario: 0,
      valorTotalCarrinho: 0,

      despesaCobertaId: 0,
      depesaId: 0,
      procedimentoCobertoId: 0,
      kitId: 0
    }
  },
  watch: {
    opcaoAdicionar() {
      if (this.opcaoAdicionar=="Procedimento") {
        this.campoBusca="Descricao"
      } else if (this.opcaoAdicionar=="Material") {
        this.campoBusca="Descricao"
      } else if (this.opcaoAdicionar=="Medicamento") {
        this.campoBusca="Descricao"
      } else if (this.opcaoAdicionar=="Taxa") {
        this.campoBusca="Descricao"
      } else if (this.opcaoAdicionar=="Kit") {
        this.campoBusca="Descricao"
      }
    }
  },
  methods: {
    ...mapActions({
    }),
    retornar() {
      this.retornando = true;
      this.$router.go(-1);
    },
    incluirRegistro() {
      this.itemSelecionado = {},
      this.modo = this.modo == "" ? "Incluir" : "";
    },
    editarItem(item) {
      this.modo = "Alterar";
      this.itemSelecionado = {...item}
      this.qtde = this.itemSelecionado.qtde;
      this.valorUnitario = this.itemSelecionado.valorUnitario;
    },
    removerItem(item) {
      this.$bvModal.msgBoxConfirm(`Remover o ítem ${item.descricao} do Carrinho?`, {
          title: "Atenção",
          size: "lg",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            let indice = this.itensCarrinho.findIndex(i => i.descricao == item.descricao);

            this.valorTotalCarrinho -= this.itensCarrinho[indice].valorTotal;
            this.itensCarrinho.splice(indice, 1);
          }
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
      });
    },
    adicionarItem() {
      if (this.qtde <= 0) {
        this.$toasted.show("Quantidade deve ser maior que zero.", {type: "info", icon: "times"})
        return;
      }

      //------------------ Tipos (Despesa.CodMatTiss)
      // 1 = Procedimento
      // 2 = Medicamentos
      // 3 = Materiais
      // 7 = Taxas ------------- Era 4 na versão Desktop
      // 8 = kits -------------- teste
      //------------------------

      var posicao = this.itemSelecionado.descricao.indexOf("-");
      var id = this.itemSelecionado.descricao.substring(0, posicao);

      let item = null;
      let itemCarrinho = {}

      if (this.itemSelecionado.tipo == 1) { // Procedimento
        item = this.procedimentosCobertosDaOperadora.find(i => i.id == id);

        itemCarrinho = {
          tipo : this.itemSelecionado.tipo,
          grupo: 'Proced',
          id : item.id,
          codigo : item.codigoProcedimento,
          codTuss: item.codigoTuss,
          descricao: item.descricao,
          procedimentoCobertoId : item.id,
          despesaId : 0,
          despesaCobertaId : 0,
          kitId : 0,
          qtde : this.qtde,
          valorUnitario : this.valorUnitario,
          valorTotal : this.qtde * this.valorUnitario
        }
      } else if (this.itemSelecionado.tipo == 2) { // Medicamento
        item = this.listaMedicamentos.find(i => i.id == id);

        itemCarrinho = {
          tipo : this.itemSelecionado.tipo,
          grupo: 'Despesas',
          id : item.id,
          codigo : item.codMat,
          codTuss: item.codTuss,
          descricao: item.descricao,
          procedimentoCobertoId : 0,
          despesaId : item.id,
          despesaCobertaId : 0,
          kitId : 0,
          qtde : this.qtde,
          valorUnitario : this.valorUnitario,
          valorTotal : this.qtde * this.valorUnitario
        }
      } else if (this.itemSelecionado.tipo == 3) { // Material
        item = this.listaMateriais.find(i => i.id == id);

        itemCarrinho = {
          tipo : this.itemSelecionado.tipo,
          grupo: 'Despesas',
          id : item.id,
          codigo : item.codMat,
          codTuss: item.codTuss,
          descricao: item.descricao,
          procedimentoCobertoId : 0,
          despesaId : item.id,
          despesaCobertaId : 0,
          kitId : 0,
          qtde : this.qtde,
          valorUnitario : this.valorUnitario,
          valorTotal : this.qtde * this.valorUnitario
        }
      } else if (this.itemSelecionado.tipo == 7) { // Taxa (despesaCoberta)
        item = this.despesasCobertas.find(i => i.id == id);

        itemCarrinho = {
          tipo : this.itemSelecionado.tipo,
          grupo: 'Despesas',
          id : item.id,
          codigo : item.codigo,
          codTuss: item.codTuss,
          descricao: item.descricao,
          procedimentoCobertoId : 0,
          despesaId : item.despesaId,
          despesaCobertaId : item.id,
          kitId : 0,
          qtde : this.qtde,
          valorUnitario : this.valorUnitario,
          valorTotal : this.qtde * this.valorUnitario
        }
      } else if (this.itemSelecionado.tipo == 8) { // Kit
        item = this.kits.find(i => i.id == id);

        itemCarrinho = {
          tipo : this.itemSelecionado.tipo,
          grupo: 'Kit',
          id : item.id,
          codigo : item.codPacote,
          codTuss: item.codTuss,
          descricao: item.descricao,
          procedimentoCobertoId : 0,
          despesaId : 0,
          despesaCobertaId : 0,
          kitId : item.id,
          qtde : this.qtde,
          valorUnitario : this.valorUnitario,
          valorTotal : this.qtde * this.valorUnitario
        }
      } else {
        this.$toasted.show("Tipo Inválido. Aviso ao Administrador do Sistema.", {type : "danger", icon : "times"})
        return;
      }

      let indice = -1;

      if (this.itensCarrinho.length>0) {
        indice = this.itensCarrinho.findIndex(i => i.descricao == itemCarrinho.descricao);        
      }

      if (indice>=0) {
        this.valorTotalCarrinho -= this.itensCarrinho[indice].valorTotal; 

        this.itensCarrinho[indice].qtde += this.qtde; 
        this.itensCarrinho[indice].valorTotal = this.itensCarrinho[indice].valorUnitario * this.itensCarrinho[indice].qtde; 

        this.valorTotalCarrinho += this.itensCarrinho[indice].valorTotal; 
      } else {
        this.itensCarrinho.push({...itemCarrinho})
        this.valorTotalCarrinho += itemCarrinho.valorTotal; 
      }

      this.qtde = 1;
      this.valorUnitario = 0;
      this.valorTotal = 0;
    },
    alterarItem() {
      if (this.qtde <= 0) {
        this.$toasted.show("Quantidade deve ser maior que zero.", {type: "info", icon: "times"})
        return;
      }

      this.modo = "";

      let indice = -1;

      if (this.itensCarrinho.length>0) {
        indice = this.itensCarrinho.findIndex(i => i.descricao == this.itemSelecionado.descricao);
      } else {
        this.$toasted.show("Erro: Carrinho está vazio.", {type: "error", icon : "times"})
        return;        
      }

      if (indice>=0) {
        this.valorTotalCarrinho -= this.itensCarrinho[indice].valorTotal;

        this.itensCarrinho[indice].qtde = this.qtde; 
        this.itensCarrinho[indice].valorUnitario = this.valorUnitario;
        this.itensCarrinho[indice].valorTotal = this.valorUnitario * this.qtde; 

        this.valorTotalCarrinho += this.itensCarrinho[indice].valorTotal;
      } else {
        this.$toasted.show("Erro: Item não localizado no Carrinho.", {type: "error", icon : "times"})
        return;
      }
    },
    limparCarrinho() {
      this.$bvModal.msgBoxConfirm(`Tem certeza que deseja remover todos os itens do Carrinho?`, {
          title: "Atenção",
          size: "lg",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            this.itensCarrinho = [];
          }
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
      });
    },
    confirmarGravarRegistro() {
      this.$bvModal.msgBoxConfirm(`Gravar itens do Carrinho?`, {
          title: "Atenção",
          size: "md",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            this.gravarRegistro();
          }
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
      });
    },
    async gravarRegistro() {
      this.itensCarrinho.forEach(element => {
        element.guiaId = this.guiaSelecionada.id;
        element.planoSaudeLanctoId = this.guiaSelecionada.planoSaudeLanctoId;
        element.operadorCadastroId = this.usuarioLogadoDados.profissionalId;
        element.siglaServico = "";
      });

      this.loading = true;
      let errosLancamento = 0;

      await this.$http.post(`/v1.0/guias/${this.usuarioLogadoDados.tenant}/incluirLancamentos`, this.itensCarrinho)
            .then(resp => {
              this.resultadoGravacao = resp.data;
              this.erro = ""

              let indice = 0;

              this.resultadoGravacao.forEach(resultadoItem => {
                if (resultadoItem.tipo == 1) {
                  indice = this.itensCarrinho.findIndex(elemento => elemento.tipo == resultadoItem.tipo && elemento.id == resultadoItem.id);
                } else if (resultadoItem.tipo == 2 || resultadoItem.tipo == 3) {
                  indice = this.itensCarrinho.findIndex(elemento => elemento.tipo == resultadoItem.tipo && elemento.id == resultadoItem.id);
                } else if (resultadoItem.tipo == 7) {
                  indice = this.itensCarrinho.findIndex(elemento => elemento.tipo == resultadoItem.tipo && elemento.id == resultadoItem.id);
                } else {
                  indice = this.itensCarrinho.findIndex(elemento => elemento.tipo == resultadoItem.tipo && elemento.id == resultadoItem.id);
                }
              
                if (indice >= 0) {
                  this.itensCarrinho[indice].resultadoLancamento = resultadoItem.resultado;
                }
                if (resultadoItem.resultado.substring(0, 4) == "Erro") errosLancamento++;
              })
            })
            .catch(error => {
              this.erro = errors.tratarErros(error);
              return;
            })
            .finally(() => {
              this.loading = false;
            })

            if (errosLancamento==0) {
              //this.mensagem = `${this.usuarioLogadoDados.profissional.nome}, todos os itens foram lançados na guia do Paciente.`;
              //this.itensCarrinho = [];

              this.retornar();
            } else {
              this.mensagem = `${this.usuarioLogadoDados.profissional.nome}, ${errosLancamento} itens não puderam ser lançados na guia do Paciente.`;
              this.$bvModal.show("modal-mensagem");

              this.itensCarrinho = this.itensCarrinho.filter(elemento => elemento.resultadoGravacao.substring(0, 4) == "Erro");
            }
    },
    limparCampos() {
      this.itemSelecionado = {}

      if (this.modo!="Incluir") {
        this.modo = "";
      }
    },
    cancelarOperacao() {
      this.itemSelecionado = {}
      this.modo = "";
      this.erro = "";
    },
    async carregarRegistros() {
      if (this.opcaoAdicionar == "Procedimento") {
        this.itemSelecionado.tipo = 1;
        if (this.filtrar=="") {
          this.$toasted.show("Digite o que deseja localizar.", {type: "info", icon: "times"})
          return;
        }

        await this.carregarProcedimentosCobertos(this.usuarioLogadoDados.tenant, this.guiaSelecionada.planoSaudeLanctoId, this.campoBusca, this.filtrar, "S", this.operadoraId);

        if (this.procedimentosCobertosDaOperadora.length==0) {
          this.$toasted.show("Nenhum Procedimento Coberto localizado", {type: "info", icon : "times"});
        }
      } else if (this.opcaoAdicionar == "Material") {
        this.itemSelecionado.tipo = 3;

        this.loading = true;
        await this.carregarMateriaisPeloCodMatTiss(this.usuarioLogadoDados.tenant, this.itemSelecionado.tipo, this.campoBusca, this.filtrar, 0, "S", this.operadoraId);
        this.loading = false;

        if (this.listaMateriais.length==0) {
          this.$toasted.show("Nenhum Material localizado", {type: "info", icon : "times"});
        }
      } else if (this.opcaoAdicionar == "Medicamento") {
        this.itemSelecionado.tipo = 2;

        this.loading = true;
        await this.carregarMateriaisPeloCodMatTiss(this.usuarioLogadoDados.tenant, this.itemSelecionado.tipo, this.campoBusca, this.filtrar, 0, "S", this.operadoraId);
        this.loading = false;

        if (this.listaMedicamentos.length==0) {
          this.$toasted.show("Nenhum Medicamento localizado", {type: "info", icon : "times"});
        }
      } else if (this.opcaoAdicionar == "Taxa") {
        this.itemSelecionado.tipo = 7;

        this.loading = true;
        await this.carregarDespesasCobertas(this.usuarioLogadoDados.tenant, this.parametrosLanctoGuia.operadoraId, this.campoBusca, this.filtrar, 0);
        this.loading = false;

        if (this.despesasCobertas.length==0) {
          this.$toasted.show("Nenhuma Taxa localizada", {type: "info", icon : "times"});
        }
      } else if (this.opcaoAdicionar == "Kit") {
        this.itemSelecionado.tipo = 8;
        if (this.filtrar=="") {
          this.$toasted.show("Digite o que deseja localizar.", {type: "info", icon: "times"})
          return;
        }

        this.loading = true;
        await this.carregarKits(this.usuarioLogadoDados.tenant, this.guiaSelecionada.planoSaudeLanctoId, this.campoBusca, this.filtrar, "S");
        this.loading = false;
        
        if (this.kits.length==0) {
          this.$toasted.show("Nenhum Kit localizado", {type: "info", icon : "times"});
        }
      }
    },
    selecionouItemDoList() {
      if (this.opcaoAdicionar=="Procedimento") {
        this.campoBusca="Descricao"
      } else if (this.opcaoAdicionar=="Material") {
        this.campoBusca="Descricao"
      } else if (this.opcaoAdicionar=="Medicamento") {
        this.campoBusca="Descricao"
      } else if (this.opcaoAdicionar=="Taxa") {
        this.campoBusca="Descricao"
      } else if (this.opcaoAdicionar=="Kit") {
        this.campoBusca="Descricao"
      }
    }
  },
  async created() {
    this.showMenu(false);

    this.operadoraId = this.guiaSelecionada.operadoraId; //this.guiaSelecionada.planoSaude.operadoraId;

    //this.loading = true;
    await this.carregarOperadora(this.usuarioLogadoDados.tenant, this.operadoraId);
    this.loading = false;
  },
  beforeRouteLeave(to, from, next) {
    if (this.retornando) {
      this.listaProcedimentos = [];
      this.listaMateriais = [];
      this.listaMedicamentos = [];
      this.despesasCobertas = [];
      this.kits = []
    }
    next(true);
  }
}
</script>

<style scoped>
  label {
    font-weight: 100%;
    font-size: 0.8rem;
    margin-top: 3px;
    margin-bottom: 1px;
  }
</style>
