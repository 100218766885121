<template>
  <div id="CadOperadoraRecebimento">
    <div class="container-fluid">
      <div class="row">
        <div class="form-group col-lg-1">
          <label for="Particular" class="control-label">Particular:</label>
          <select
            id="Particular"
            class="form-control"
            :disabled="!liberarCampos"
            selected
            v-model="operadoraSelecionada.particular"
          >
            <option
              v-for="resposta in sim_nao"
              :key="resposta.opcao"
              :value="resposta.opcao"
            >{{resposta.valor}}</option>
          </select>
        </div>
        <div class="form-group col-lg-1">
          <label for="DiasFatura" class="control-label">Dia Fecto:</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="DiasFatura"
            name="DiasFatura"
            v-model.number="operadoraSelecionada.diasFatura"
          />
        </div>
        <div class="form-group col-lg-1">
          <label for="DiaEntrega" class="control-label">1ª Entrega:</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="DiaEntrega"
            name="DiaEntrega"
            v-model.number="operadoraSelecionada.diaEntregaFatura"
          />
        </div>
        <div class="form-group col-lg-1">
          <label for="DiaEntrega2" class="control-label">2ª Entrega:</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="DiaEntrega2"
            name="DiaEntrega2"
            v-model.number="operadoraSelecionada.diaEntregaFatura2"
          />
        </div>
        <div class="form-group col-lg-1">
          <label for="DiaRecebto" class="control-label">Dia Recebto:</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="DiaRecebto"
            name="DiaRecebto"
            v-model.number="operadoraSelecionada.diaRecebtoFatura"
          />
        </div>
        <div class="form-group col-lg-2">
          <label for="VenctoFatura" class="control-label">Recebimento:</label>
          <select
            id="VenctoFatura"
            class="form-control"
            :disabled="!liberarCampos"
            selected
            v-model="operadoraSelecionada.venctoFatura"
          >
            <option value=1>Mesmo Mês</option>
            <option value=2>Mês Subsequente</option>
            <option value=3>Dias Após</option>
          </select>
        </div>
        <div class="form-group col-lg-2">
          <label for="LancarZerado" class="control-label">Lançar Zerado:</label>
          <select
            id="LancarZerado"
            class="form-control"
            :disabled="!liberarCampos"
            selected
            v-model="operadoraSelecionada.lancarZerado"
          >
            <option
              v-for="resposta in sim_nao"
              :key="resposta.opcao"
              :value="resposta.opcao"
            >{{resposta.valor}}</option>
          </select>
        </div>
        <div class="form-group col-lg-2">
          <label for="GuiaUmaSessao" class="control-label">Guia 1 Sessão:</label>
          <select
            id="GuiaUmaSessao"
            class="form-control"
            :disabled="!liberarCampos"
            selected
            v-model="operadoraSelecionada.guiaUmaSessao"
          >
            <option
              v-for="resposta in sim_nao"
              :key="resposta.opcao"
              :value="resposta.opcao"
            >{{resposta.valor}}</option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import constantes from "@/utils/constantes";

export default {
  computed: {
    ...mapGetters({
      modo: "operadoras/getModo",
      operadoraSelecionada: "operadoras/getOperadoraSelecionada",
    }),
    liberarCampos() {
      return this.modo == "Incluir" || this.modo == "Alterar" ? true : false;
    }
  },
  data() {
    return {
      sim_nao: constantes.sim_nao
    }
  }
}
</script>

<style scoped>
    label {
      font-weight: 100%;
      font-size: 0.8rem;
      margin-top: 3px;
      margin-bottom: 1px;
    }
</style>