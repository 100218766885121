import errors from "@/utils/errors";

export default {
    data() {
        return {
            profissionalMemed : {}
        }
    },
    methods : {
        async carregarCidades() {
            this.loadingMemedCidades = true;
            this.loadedMemedCidades = false;

            this.$http.get(`https://api.memed.com.br/v1/cidades`)
            .then(resp => {
                this.cidades = resp.data.data;

                this.erro = "";
                this.loadedMemedCidades = true;
            })
            .catch(error => this.erro = errors.tratarErros(error))
            .finally(() => this.loadingMemedCidades = false);
        },
        async buscarCidades(cidade) {
            this.loadingMemedCidades = true;
            this.loadedMemedCidades = false;

            await this.$http.get(`https://api.memed.com.br/v1/cidades?filter[q]=${cidade}`)
            .then(resp => {
                this.cidades = resp.data.data;

                this.erro = "";
                this.loadedMemedCidades = true;
            })
            .catch(error => this.erro = errors.tratarErros(error))
            .finally(() => this.loadingMemedCidades = false);
        },
        async carregarEspecialidades() {
            this.loadingMemedEspecialidades = true;
            this.loadedMemedEspecialidades = false;

            this.$http.get(`https://api.memed.com.br/v1/especialidades`)
            .then(resp => {
                this.especialidades = resp.data.data;

                this.erro = "";
                this.loadedMemedEspecialidades = true;
            })
            .catch(error => this.erro = errors.tratarErros(error))
            .finally(() => this.loadingMemedEspecialidades = false);
        },
        async buscarProfissionalMemed(externalId) {
            //https://sandbox.api.memed.com.br/v1/sinapse-prescricao/usuarios/246?api-key=iJGiB4kjDGOLeDFPWMG3no9VnN7Abpqe3w1jEFm6olkhkZD6oSfSmYCm&secret-key=Xe8M5GvBGCr4FStKfxXKisRo3SfYKI7KrTMkJpCAstzu2yXVN4av5nmL
            this.loadingMemedProfissional = true;
            this.loadedMemedProfissional = false;

            let dominio_api_memed = 'https://sandbox.api.memed.com.br';
            let api_key = 'iJGiB4kjDGOLeDFPWMG3no9VnN7Abpqe3w1jEFm6olkhkZD6oSfSmYCm';
            let secret_key = 'Xe8M5GvBGCr4FStKfxXKisRo3SfYKI7KrTMkJpCAstzu2yXVN4av5nmL';

            await this.$http.get(`${dominio_api_memed}/v1/sinapse-prescricao/usuarios/${externalId}?api-key=${api_key}&secret-key=${secret_key}`)
            .then(resp => {
                this.profissionalMemed = resp.data.data;

                this.erro = "";
                this.loadedMemedProfissional = true;
            })
            .catch(error => this.erro = errors.tratarErros(error))
            .finally(() => this.loadingMemedProfissional = false);
        }
    }
}
