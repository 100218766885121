import errors from "@/utils/errors";

export default {
    data() {
        return {
            limitDespesas : 0,
            countDespesas : 0,
            limitMateriais : 0,
            countMateriais : 0,
            despesaTipoId : 0,
            
            filtrar : "",
            despesas: [],
        }
    },
    methods : {
        async carregarDespesasPeloTipo(tenant, despesaTipoId, campoBusca, filtrar, pagina) {
            if (despesaTipoId==0) {
              this.$toasted.show("Selecione o Tipo de Despesa", {type: "info", icon : "times"});
              return;
            }

            if (campoBusca == "Id" && filtrar.length < 1) {
              this.$toasted.show("Favor informar o que deseja localizar (pelo menos 1 dígito)", {type: "info", icon : "times"});
              return
            }

            if (campoBusca != "Id" && filtrar.length < 3) {
              this.$toasted.show("Favor informar o que deseja localizar (pelo menos 3 dígitos)", {type: "info", icon : "times"});
              return
            }

            this.loadingDespesas = true;
            this.loadedDespesas = false;

            await this.$http
              .get(`/v1.0/despesas/${tenant}/despesaTipo/${despesaTipoId}/${campoBusca}/${filtrar}/${pagina}`)
              .then(resp => {
                this.despesas = resp.data.registros;
                this.limitDespesas = resp.data.limit;
                this.countDespesas = resp.data.count;

                this.erro = "";
                this.loadedDespesas = true;
            })
            .catch(error => {
              this.erro = errors.tratarErros(error);
            })
            .finally(() => {
              this.loadingDespesas = false;
            });
          },
          async carregarMateriaisPeloCodMatTiss(tenant, codMatTiss, campoBusca, filtrar, pagina) {
            this.loadingDespesas = true;
            this.loadedDespesas = false;
            
            await this.$http
              .get(`/v1.0/despesas/${tenant}/CodMatTiss/${codMatTiss}/${campoBusca}/${filtrar}/${pagina}`)
              .then(resp => {
                if (codMatTiss==2) {
                  this.listaMedicamentos = resp.data.registros;
                  this.limitDespesas = resp.data.limit;
                  this.countDespesas = resp.data.count;
                } else {
                  this.listaMateriais = resp.data.registros;
                  this.limitMateriais = resp.data.limit;
                  this.countMateriais = resp.data.count;
                }

                this.loadedDespesas = true;
                this.erro = "";
              })
              .catch(error => {
                this.erro = errors.tratarErros(error);
              })
              .finally(() => {
                this.loadingDespesas = false;
              });
          },
    }
}
