<template>
  <div id="frmProfissionalComissoes">
    <div class="cabecalho">
      <div class="coluna1">
        <button @click="retornar" class="btn btn-sm btn-primary mr-10"><i class="fa fa-arrow-left"/></button>
      </div>
      <div class="coluna2">
        <span>{{profissionalSelecionado.nome}} - Comissões por Serviço sobre Lançamentos</span>
      </div>
      <div class="coluna3"/>
    </div>  

    <div class="container-fluid">
      <div class="row" v-if="modo==''">
        <div class="col-12">
          <button
            class="btn btn-sm btn-info"
            @click="incluirRegistro()"
          >Adicionar Nova Comissão</button>
          <hr />
        </div>
      </div>

      <div class="registro" v-if="modo!=''">
        <div class="row" v-if="modo=='Incluir'">
          <div class="col ml-2 mr-2">
            <h4 style="text-align:left">Adicionar Nova Comissão</h4>
            <hr/>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-lg-2 ml-2">
            <label for="Servico" class="control-label">Serviço</label>
            <select
              class="form-control"
              id="Servico"
              name="Servico"
              selected
              v-model="profissionalComissao.servicoId"
            >
              <option
                v-for="servico in servicos"
                :key="servico.id"
                :value="servico.id"
              >{{ servico.siglaServico }} - {{ servico.descricao }}</option>
            </select>
          </div>

          <div class="form-group col-lg-1">
            <label for="PorcenConvenio" class="control-label">% Operadora</label>
            <input
              type="number"
              class="form-control"
              id="PorcenConvenio"
              name="PorcenConvenio"
              v-model.number="profissionalComissao.porcenConvenio"
            />
          </div>

          <div class="form-group col-lg-1">
            <label for="PorcenParticular" class="control-label">% Particular</label>
            <input
              type="number"
              class="form-control"
              id="PorcenParticular"
              name="PorcenParticular"
              v-model.number="profissionalComissao.porcenParticular"
            />
          </div>

          <div class="form-group col-lg-2">
            <label for="ComissaoSobre" class="control-label">Comissão Sobre</label>
            <select id="ComissaoSobre" :disabled="!liberarCampos" selected class="form-control" v-model="profissionalComissao.comissaoSobre">
              <option value="1">Honorário</option>
              <option value="2">Total (Honorario + Custo Operacional)</option>
            </select>
          </div>

          <div class="form-group col-lg-1">
            <label for="ValorConvenio" class="control-label">R$ Operadora</label>
            <input
              type="number"
              class="form-control"
              id="ValorConvenio"
              name="ValorConvenio"
              v-model.number="profissionalComissao.valorConvenio"
            />
          </div>

          <div class="form-group col-lg-1">
            <label for="ValorParticular" class="control-label">R$ Particular</label>
            <input
              type="number"
              class="form-control"
              id="ValorParticular"
              name="ValorParticular"
              v-model.number="profissionalComissao.valorParticular"
            />
          </div>
        </div>

        <hr />
        <div class="barraopcoescadastro">
          <button
            id="Alterar"
            :disabled="modo=='Incluir' || modo=='Alterar'"
            @click="alterarRegistro()"
            class="btn btn-sm btn-secondary mr-2"
          >Alterar</button>
          <button
            id="Cancelar"
            @click="cancelarOperacao()"
            class="btn btn-sm btn-secondary mr-2"
          >Cancelar</button>
          <button
            id="Gravar"
            :disabled="!liberarCampos"
            @click="gravarRegistro()"
            class="btn btn-sm btn-secondary"
          >Gravar</button>
        </div>
      </div>
    </div>
    <hr />

    <template v-if="this.erro!=''">
      <p class="msgError">Erro durante Operação: {{ this.erro }}</p>
    </template>

    <div style="display:flex; flex-direction:row; justify-content:center">
      <b-spinner v-if="loadingProfissionalComissoes || loadingServicos" label="carregando..."/>
      <table class="table table-sm table-hover col-lg-10" v-if="loadedProfissionalComissoes">
        <thead class="thead-light">
          <th>Serviço</th>
          <th>% Operadora</th>
          <th>% Particular</th>
          <th>Comissão Sobre</th>
          <th>R$ Operadora</th>
          <th>R$ Particular</th>
          <th>Ações</th>
        </thead>
        <tbody>
          <tr v-for="profissionalComissao in profissionalComissoes" :key="profissionalComissao.id">
            <td>{{profissionalComissao.servico}}</td>
            <td>{{profissionalComissao.porcenConvenio}}</td>
            <td>{{profissionalComissao.porcenParticular}}</td>
            <td>{{profissionalComissao.comissaoSobre}}</td>
            <td>{{profissionalComissao.valorConvenio}}</td>
            <td>{{profissionalComissao.valorParticular}}</td>
            <td>
              <b-button
                variant="warning"
                size="sm"
                class="mr-2"
                @click="selecionouProfissionalComissao(profissionalComissao)"
              >
                <i class="fa fa-pencil" />
              </b-button>
              <b-button
                variant="danger"
                size="sm"
                @click="ExcluirRegistroSelecionado(profissionalComissao)"
              >
                <i class="fa fa-trash" />
              </b-button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="Mensagem">
      <b-modal id="modal-mensagem" centered title="DoctorOn">
        <p class="my-4">{{mensagem}}</p>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import servicos from "@/mixins/servicos";
import profissionalComissoes from "@/mixins/profissionalComissoes";
import errors from "@/utils/errors";

export default {
  mixins: [profissionalComissoes, servicos],
  computed: {
    ...mapGetters({
      profissionalSelecionado: "profissionais/getProfissionalSelecionado",
      usuarioLogadoDados : "global/getUsuarioLogadoDados",
    }),
    liberarCampos() {
      return this.modo == "Incluir" || this.modo == "Alterar" ? true : false;
    }
  },
  data() {
    return {
      loadingServicos : false,
      loadedServicos : false,
      loadingProfissionalComissoes: false,
      loadedProfissionalComissoes : false,
      erro: "",
      mensagem : "",
      retornando: false,
      exibirAdicaoComissao: false,
      modo: "",
      profissionalComissao: {}
    }
  },
  methods: {
    incluirRegistro() {
      if (this.modo == "" && !this.verificarPermissao('Incluir')) {
        return
      }

      this.profissionalComissao = {}
      this.modo = this.modo == "" ? "Incluir" : "";
    },
    alterarRegistro() {
      if (!this.verificarPermissao('Alterar')) {
        return
      }

      this.modo = 'Alterar';
    },
    selecionouProfissionalComissao(profissionalComissao) {
      this.profissionalComissao = { ...profissionalComissao }
      this.modo = "Registro";
    },
    retornar() {
      this.retornando = true;
      this.$router.go(-1);
    },
    gravarRegistro() {
      if (!this.verificarCamposPreenchidos()) {
        return;
      }

      if (this.modo == "Alterar") {
        this.profissionalComissao.operadorAlteracaoId = this.usuarioLogadoDados.profissionalId;

        this.$http
          .put(
            `/v1.0/profissionalcomissoes/${this.usuarioLogadoDados.tenant}/${this.profissionalComissao.id}`,
            this.profissionalComissao
          )
          .then(() => {
            this.erro = "";
            this.limparCampos();
            this.$toasted.global.defaultSuccess();
            this.carregarProfissionalComissoesPeloProfissional(this.usuarioLogadoDados.tenant, this.profissionalSelecionado.id);
          })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          })
          .finally(() => {});
      } else {
        this.profissionalComissao.profissionalId = this.profissionalSelecionado.id;
        this.profissionalComissao.operadorCadastroId = this.usuarioLogadoDados.profissionalId;

        this.$http
          .post(`/v1.0/profissionalcomissoes/${this.usuarioLogadoDados.tenant}`, this.profissionalComissao)
          .then(() => {
            this.erro = "";
            this.limparCampos();
            this.$toasted.global.defaultSuccess();
            this.carregarProfissionalComissoesPeloProfissional(this.usuarioLogadoDados.tenant, this.profissionalSelecionado.id);
          })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          })
          .finally(() => {});
      }
    },
    verificarCamposPreenchidos() {
      if (this.profissionalComissao.servicoId == 0) {
        this.$toasted.show("Selecione o Serviço para Comissão", {type: "info", icon: "times"});
        return false;
      }

      if (this.profissionalComissao.comissaoSobre == null) {
        this.$toasted.show("Selecione uma opção para 'Comissão Sobre'", {type: "info", icon: "times"});
        return false;
      }

      return true;
    },
    ExcluirRegistroSelecionado(profissionalComissao) {
      if (!this.verificarPermissao('Alterar')) {
        return
      }

      this.$bvModal
        .msgBoxConfirm("Excluir Registro Selecionado?", {
          title: "Atenção",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            this.$http
              .delete(`v1.0/profissionalcomissoes/${this.usuarioLogadoDados.tenant}/${profissionalComissao.id}/${this.usuarioLogadoDados.profissionalId}`)
              .then(() => {
                this.erro = "";
                this.$toasted.global.defaultSuccess();
                this.carregarProfissionalComissoesPeloProfissional(this.usuarioLogadoDados.tenant, 
                  this.profissionalSelecionado.id
                );
              })
              .catch(error => {
                this.erro = errors.tratarErros(error);
              })
              .finally(() => {});
          }
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        });
    },
    cancelarOperacao() {
      this.profissionalComissao = {}
      this.modo = "";
      this.erro = "";
    },
    limparCampos() {
      this.profissionalComissao = {}

      if (this.modo != "Incluir") {
        this.modo = "";
      }
    },
    verificarPermissao(operacao) {
      if (!errors.verificarPermissao(this.usuarioLogadoDados, this.$store.state.global.grauPermissaoMinimo, operacao)) {
        let descricaoOperacao = operacao == "Incluir" ? "Inclusão" : operacao == "Alterar" ? "Alteração" : "Exclusão";

        this.mensagem = `${this.usuarioLogadoDados.profissional.nome}, você não possui permissão para realizar ${descricaoOperacao} com esse Perfil.`;
        this.$bvModal.show("modal-mensagem");

        return false;
      }

      return true;
    }
  },
  created() {
    this.carregarProfissionalComissoesPeloProfissional(this.usuarioLogadoDados.tenant, this.profissionalSelecionado.id);
    this.carregarServicos(this.usuarioLogadoDados.tenant);
  },
  beforeRouteLeave(to, from, next) {
    if (this.modo=="Incluir" || this.modo=="Alterar") {
      this.$bvModal
        .msgBoxConfirm("Tem certeza que deseja sair sem Gravar os Dados?", {
          title: "Atenção",
          size: "sm",
          buttonSize: "sm",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            next(true);
          } else {
            next(false);
          }
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        });
    } else {
      next(true);
    }
  }
}
</script>

<style scoped>
  label {
    font-weight: 100%;
    font-size: 0.8rem;
    margin-top: 3px;
    margin-bottom: 1px;
  }
</style>
