<template>
    <div class="user-drop-down">
        <div class="user-button">
            <div class="col align-items-center">
                <template v-if="usuarioLogadoDados.profissional!=undefined">
                    <span class="d-none d-sm-block"> {{usuarioLogadoDados.profissional.nome}}</span>
                    <span class="d-none d-sm-block"> Perfil {{usuarioLogadoDados.perfilAcesso.perfil.tipo}}</span>
                </template>
            </div>
            <div class="user-drop-down-img" v-if="usuarioLogadoDados.email">
                <Gravatar :email="usuarioLogadoDados.email" alt="User"/>
            </div>
            <i class="fa fa-angle-down"/>
        </div>

        <div class="user-drop-down-content">
            <a href @click.prevent="Perfil()"><i class="fa fa-user"/> Perfil</a>
            <a href @click.prevent="SenhaAcesso()"><i class="fa fa-check-square-o"/> Senha de Acesso </a>
            <a href @click.prevent="Configuracoes()"><i class="fa fa-cogs"/> Configurações </a>
            <a href @click.prevent="logout()"><i class="fa fa-sign-out"/> Sair</a>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import Gravatar from 'vue-gravatar'
import usuarios from '@/mixins/usuarios'
import errors from "@/utils/errors"

export default {
  name : 'userDropDown',
  mixins : [usuarios],
  components : {Gravatar},
  computed: {
      ...mapGetters({
          usuarioLogadoDados : "global/getUsuarioLogadoDados",
          isUserDropDownVisible : "global/getUserDropDown"
      }),
  },
  methods: {
    ...mapActions({
      setProfissionalSelecionado: "profissionais/setProfissionalSelecionado",
      setDefinirSenha : "usuarios/setDefinirSenha"
    }),
    logout() {
      this.$bvModal
        .msgBoxConfirm("Desconectar Usuário?", {
          title: "Atenção",
          size: "sm",
          buttonSize: "sm",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            this.ConfirmouSaida();
          }
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        });
    },
    async ConfirmouSaida() {
        await this.registrarSaida(this.usuarioLogadoDados.tenant);
        this.$router.push('/');
    },
    SenhaAcesso() {
        let definirSenha = {
            profissionalId : this.usuarioLogadoDados.profissionalId,
            usuarioId : this.usuarioLogadoDados.id,
            nome : this.usuarioLogadoDados.profissional.nome,
            email : this.usuarioLogadoDados.email,
            perfilId : this.usuarioLogadoDados.profissional.perfilRaizId,
            identity : null,
            chamadoPor : "userDropDown",
            modo : "Alterar"
        }

        this.setDefinirSenha(definirSenha);
        this.$router.push("/frmDefinirSenha");
    },
    Perfil() {
        this.$toasted.show("Opção ainda não disponível", {type : "info", icon : "times"});
    },
    Configuracoes() {
        this.$toasted.show("Opção ainda não disponível", {type : "info", icon : "times"});
    }
  },
}
</script>

<style scoped>
    .user-drop-down {
        height: 100%;
        position: relative;
    }

    .user-drop-down:hover {
        background-color: rgb(0, 0, 0, 0.05);
    }

    .user-button {
        display: flex;
        align-items: center;
        color: #fff;
        font-weight: 100;
        font-size: 0.7rem;
        height: 100%;
        padding : 0px 20px;
    }

    .user-drop-down-img {
        margin : 0px 10px;   
    }

    .user-drop-down-img > img {
        max-height: 37px;
        border-radius: 5px;
    }

    .user-drop-down-content {
        position: absolute;
        right: 5px;
        background-color: #f9f9f9;
        min-width: 170px;
        box-shadow: 0px 8px 16px 0px rgb(0, 0, 0, 0.2);
        padding: 10px;
        z-index: 1;

        display: flex;
        flex-direction: column;
        flex-wrap : wrap;

        visibility: hidden;
        opacity: 0;
        transition: visibility 0s, opacity 0.5s linear;
    }

    .user-drop-down-content a {
        text-decoration: none;
        color: #000;
        padding: 5px;
    }

    .user-drop-down-content a:hover {
        background-color: #EDEDED;
    }

    .user-drop-down:hover .user-drop-down-content {
        visibility : visible;
        opacity: 1;
    }
</style>
