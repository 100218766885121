const estruturaTabela = {
    id : 0, 
    nome : "", profissionalTipoId : 0, ativo:'S', 
    externo:'N', sessao : 'N', sessoesFixas : 'N', agendaCompartilhada : 'N', bloqueado : 'N',
    utilizandoSistema : 'N', idadeInicial : 0, idadeFinal : 130,
    identityId : ""
}

const state = {
    all : [],
    profissionalSelecionado : null,
    profissionalTipoId : null,
    ativo : null,
    modo : "",
    ultimaBusca : null,
}

const getters = {
    getAllProfissionais(state) {
        return state.all;
    },

    getProfissionalSelecionado(state) {
        return state.profissionalSelecionado;
    },

    getProfissionalTipoId(state) {
        return state.profissionalTipoId;
    },

    getAtivo(state) {
        return state.ativo;
    },

    getModo(state) {
        return state.modo;
    },

    getUltimaBusca(state) {
        return state.ultimaBusca;
    }
}

const mutations = {
    setProfissionais(state, listaProfissionais) {
        state.all = listaProfissionais;
    },

    setProfissionalSelecionado(state, profissional) {
        state.profissionalSelecionado = profissional;
    },

    setProntuarioPermitidos(state, listaProntuarioPermitidos) {
        state.allProntuarioPermitidos = listaProntuarioPermitidos;
    },

    setProfissionalTipoId(state, valor) {
        state.profissionalTipoId = valor;
    },

    setAtivo(state, valor) {
        state.Ativo = valor;
    },

    setModo(state, modo) {
        state.modo = modo;
    },

    setUltimaBusca(state, registro) {
        state.ultimaBusca = {...registro}
    },

    limparRegistroSelecionado(state) {
        state.profissionalSelecionado = {...estruturaTabela}
    },

    limparLista(state) {
        state.all = [];
    },
}

const actions = {
    setProfissionais({commit}, listaProfissionais) {
        commit("setProfissionais", listaProfissionais);
    },

    setProfissionalSelecionado({commit}, profissional) {
        commit("setProfissionalSelecionado", profissional)
    },

    setProfissionalTipoId({commit}, valor) {
        commit('setProfissionalTipoId', valor);
    },

    setAtivo({commit}, valor) {
        commit('setAtivo', valor);
    },

    setModo({commit}, modo) {
        commit("setModo", modo);
    },

    setUltimaBusca({commit}, registro) {
        commit("setUltimaBusca", registro)
    },

    limparRegistroSelecionado({commit}) {
        commit("limparRegistroSelecionado")
    },

    limparLista({commit}) {
        commit("limparLista")
    },

    formLeave({commit}) {
        commit('limparRegistroSelecionado');
        commit('limparLista');
        commit("setModo", "");
    }
}

export default {
    namespaced : true,
    state,
    getters,
    mutations,
    actions
}
