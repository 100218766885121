<template>
  <div>
    <div class="cabecalho">
      <div class="coluna1">
        <button @click="retornar" class="btn btn-sm btn-primary mr-10"><i class="fa fa-arrow-left"/></button>
      </div>
      <div class="coluna2">
        <span>Despesas cadastradas - Tipo: {{ despesaTipo.descricao }}</span>
      </div>
      <div class="coluna3"/>
    </div>  

    <div class="container-fluid">
      <div id="carregarDespesas" class="row">
        <div class="form-group ml-4 col-lg-2">
          <label for="campoBuscar" class="control-label">Selecione o Campo para Busca:</label>
          <br />
          <input type="radio" id="codigoDespesa" value="Id" v-model="campoBusca" />
          <label for="codigoDespesa" class="control-label ml-1 mr-2">Id</label>
          <input type="radio" id="descricao" value="Descricao" v-model="campoBusca" />
          <label for="descricao" class="control-label ml-1 mr-2">Descrição</label>
          <input type="radio" id="descricaoGenerico" value="DescricaoGenerico" v-model="campoBusca" />
          <label for="descricaoGenerico" class="control-label ml-1">Genérico</label>
        </div>

        <div class="form-group ml-4 col-lg-3" style="margin-top:15px">
          <input
            type="text"
            class="form-control"
            id="filter"
            @input="filtrar=$event.target.value"
            placeholder="digite o que deseja localizar..."
          />
        </div>
        <div class="form-group col-sm-1 col-md-1 col-lg-1" style="padding-top:15px">
          <button id="btnFiltrar" class="form-control" @click="dadosDespesas()">
            <i class="fa fa-search"/> 
          </button>
        </div>
        <div class="form-group col-sm-1 col-md-1 col-lg-2" style="padding-top:20px">
          <button
            id="Incluir"
            @click="incluirRegistro()"
            class="btn btn-sm btn-info mr-2"
          >Incluir Nova Despesa</button>
        </div>
      </div>

      <div id="erro">
        <p v-if="erro" style="background-color:red; color:white; text-align:center">{{erro}}</p>
      </div>

      <div style="display:flex; flex-direction:row; justify-content:center;height:520px">
        <b-spinner v-if="loadingDespesas" label="carregando..."/>
        <b-table v-if="loadedDespesas" class="col-lg-12" hover striped small sort-icon-left :items="despesas" :fields="camposTabela">
          <template v-slot:cell(actions)="row">
            <b-button variant="warning" size="sm" @click="selecionouDespesa(row.item)">
              <i class="fa fa-pencil"/>
            </b-button>
          </template>
        </b-table>
      </div>
      <b-pagination v-if="loadedDespesas" class="col-lg-12 justify-content-center" v-model="page" :total-rows="countDespesas" :per-page="limitDespesas"/>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import global from "@/mixins/global"
import despesas from "@/mixins/despesas"

export default {
  mixins: [despesas, global],
  computed: {
    ...mapGetters({
      despesaTipo: "despesaTipos/getDespesaTipoSelecionado",
      usuarioLogadoDados : "global/getUsuarioLogadoDados"
    })
  },
  data() {
    return {
      page : 1,
      campoBusca: "Descricao",
      loadingDespesas: false,
      loadedDespesas : false,
      erro: "",
      filtrar: "",
      despesa: {},
      //despesas: [],
      camposTabela: [
        {key : "id", label : "Id"},
        {key : "descricao", label : "Descrição", sortable: true},
        {key : "descricaoGenerico", label : "Genérico", sortable: true},
        {key : "actions", label : "Ações"},
      ],
    }
  },
  watch : {
    page() {
      this.dadosDespesas();
    }
  },
  methods: {
    ...mapActions({
      setDespesaSelecionada: "despesas/setDespesaSelecionada",
      limparRegistroSelecionado : "despesas/limparRegistroSelecionado",
      formLeave: "despesas/formLeave",
    }),
    retornar() {
      this.$router.go(-1);
    },
    dadosDespesas() {
      this.carregarDespesasPeloTipo(this.usuarioLogadoDados.tenant, this.despesaTipo.id, this.campoBusca, this.filtrar, this.page);
    },
    selecionouDespesa(despesa) {
      this.setDespesaSelecionada(despesa);
      this.$router.push("/cadDespesa");
    },
    incluirRegistro() {
      this.limparRegistroSelecionado();
      this.$router.push('/cadDespesa');
    },
  },
  created() {
    this.showMenu(false);
  },
  mounted() {
    this.despesaTipoId = this.despesaTipo.id; //------- para chamar carregarDespesas através do mixin
  }
}
</script>

<style scoped>
  label {
    font-weight: 100%;
    font-size: 0.8rem;
    margin-top: 3px;
    margin-bottom: 1px;
  }
</style>
