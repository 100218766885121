const state = {
    consultorioItemSelecionado : {}
}

const getters = {
    getConsultorioItemSelecionado(state) {
        return state.consultorioItemSelecionado;
    }
}

const mutations = {
    setConsultorioItemSelecionado(state, consultorioItem) {
        state.consultorioItemSelecionado = {...consultorioItem}
    }
}

const actions = {
    setConsultorioItemSelecionado({commit}, consultorioItem) {
        commit("setConsultorioItemSelecionado", consultorioItem)
    }
}

export default {
    namespaced : true,
    state,
    getters,
    mutations,
    actions
}