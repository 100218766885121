<template>
  <div id="CadOperadoraDados">
    <div class="container-fluid">
      <div class="row">
        <div class="form-group col-lg-1">
          <label for="Id" class="control-label">Id:</label>
          <input
            type="text"
            class="form-control"
            disabled
            id="Id"
            name="Id"
            :value="unidadeSelecionada.id"
          />
        </div>

        <div class="form-group col-lg-4">
          <label for="Descricao" class="control-label">Unidade:</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="Descricao"
            name="Descricao"
            v-model="unidadeSelecionada.descricao"
          />
        </div>

        <div class="form-group col-lg-1">
          <label for="Ativa" class="control-label">Ativa:</label>
          <select
            id="Ativa"
            class="form-control"
            :disabled="!liberarCampos"
            selected
            v-model="unidadeSelecionada.ativa"
          >
            <option
              v-for="resposta in sim_nao"
              :key="resposta.opcao"
              :value="resposta.opcao"
            >{{resposta.valor}}</option>
          </select>
        </div>
      </div>

      <hr/>

      <div class="row">
        <div class="form-group col-lg-1">
          <label for="Cep" class="control-label">Cep:</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="Cep"
            name="Cep"
            v-model="unidadeSelecionada.cep"
          />
        </div>
        <div class="form-group col-lg-5">
          <label for="Logradouro" class="control-label">Logradouro:</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="Logradouro"
            name="Logradouro"
            v-model="unidadeSelecionada.logradouro"
          />
        </div>
        <div class="form-group col-lg-1">
          <label for="Numero" class="control-label">Número:</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="Numero"
            name="Numero"
            v-model.number="unidadeSelecionada.numero"
          />
        </div>
        <div class="form-group col-lg-3">
          <label for="Complemento" class="control-label">Complemento:</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="Complemento"
            name="Complemento"
            v-model="unidadeSelecionada.complemento"
          />
        </div>
      </div>

      <div class="row">
        <div class="form-group col-lg-2">
          <label for="Bairro" class="control-label">Bairro:</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="Bairro"
            name="Bairro"
            v-model.number="unidadeSelecionada.bairro"
          />
        </div>
        <div class="form-group col-lg-2">
          <label for="Cidade" class="control-label">Cidade:</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="Cidade"
            name="Cidade"
            v-model="unidadeSelecionada.cidade"
          />
        </div>
        <div class="form-group col-lg-1">
          <label for="UF" class="control-label">UF:</label>
          <select
            id="UF"
            class="form-control"
            :disabled="!liberarCampos"
            selected
            v-model="unidadeSelecionada.uf"
          >
            <option v-for="uf in ufs" :key="uf.opcao" :value="uf.opcao">{{uf.valor}}</option>
          </select>
        </div>
      </div>
      <hr />

      <div class="row">
        <div class="form-group col-lg-1">
          <label for="FoneDDD" class="control-label">DDD:</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="FoneDDD"
            name="FoneDDD"
            v-model="unidadeSelecionada.foneDdd"
          />
        </div>
        <div class="form-group col-lg-2">
          <label for="Fone" class="control-label">Fone:</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="Fone"
            name="Fone"
            v-model="unidadeSelecionada.fone"
          />
        </div>
        <div class="form-group col-lg-1">
          <label for="celularDDD" class="control-label">DDD:</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="celularDDD"
            name="celularDDD"
            v-model="unidadeSelecionada.celularDdd"
          />
        </div>
        <div class="form-group col-lg-2">
          <label for="celular" class="control-label">Celular:</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="celular"
            name="celular"
            v-model="unidadeSelecionada.celular"
          />
        </div>
        <div class="form-group col-lg-2">
          <label for="WhatsApp" class="control-label">WhatsApp:</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="WhatsApp"
            name="WhatsApp"
            v-model="unidadeSelecionada.whatsapp"
          />
        </div>
        <div class="form-group col-lg-5">
          <label for="Email" class="control-label">E-mail:</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="Email"
            name="Email"
            v-model="unidadeSelecionada.email"
          />
        </div>
      </div>
      <hr/>
      <div class="row">
        <div class="form-group col-lg-2">
          <label for="Fone" class="control-label">Fones para Confirmação</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="Fone"
            name="Fone"
            v-model="unidadeSelecionada.fonesConfirmacao"
          />
        </div>
        <div class="form-group col-lg-5">
          <label for="Email" class="control-label">E-mail para Confirmação</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="Email"
            name="Email"
            v-model="unidadeSelecionada.emailConfirmacao"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import constantes from "@/utils/constantes";

export default {
  computed: {
    ...mapGetters({
      unidadeSelecionada: "unidades/getUnidadeSelecionada",
      modo : "unidades/getModo",
    }),
    liberarCampos() {
      return this.modo == "Incluir" || this.modo == "Alterar" ? true : false;
    }
  },
  data() {
    return {
      sim_nao: constantes.sim_nao,
      ufs: constantes.uf
    }
  },
  methods: {}
}
</script>

<style scoped>
    label {
      font-weight: 100%;
      font-size: 0.8rem;
      margin-top: 3px;
      margin-bottom: 1px;
    }
</style>
