<template>
  <div class="formulario">
    <div class="cabecalho">
      <button @click="retornar" class="btn btn-sm btn-primary mr-10"><i class="fa fa-arrow-left"/></button>
    </div>
    <div class="container-fluid">
      <div class="corpo">
        <h1
          style="text-align:center"
        >{{profissionalSelecionado.nome}}</h1>
        <h2 style="text-align:center">Disponibilidade de Horário</h2>
        <br />

        <div class="row">
          <div class="form-group col-lg-2 ml-2">
            <label for="destinado" class="control-label">Tipo Atendimento</label>
            <select
              id="destinado"
              class="form-control"
              selected
              :disabled="!liberarCampos"
              v-model="profissionalDisponibilidade.destinado"
            >
              <option
                v-for="resposta in opcoesDestinados"
                :key="resposta.opcao"
                :value="resposta.opcao"
              >{{resposta.valor}}</option>
            </select>
          </div>
          <div class="form-group col-lg-2">
            <label for="Inicial" class="control-label">Inicial</label>
            <input
              type="time"
              class="form-control"
              id="Inicial"
              name="Inicial"
              :disabled="!liberarCampos"
              v-model="profissionalDisponibilidade.inicial"
            />
          </div>
        </div>  

        <div class="row">
          <div class="form-group col-lg-2 ml-2">
            <label for="Preferencial" class="control-label">1º Prefer.</label>
              <select
                class="form-control"
                id="Preferencial"
                name="Preferencial"
                selected
               :disabled="!liberarCampos"
                v-model="profissionalDisponibilidade.preferencial"
              >
                <option
                  v-for="servico in servicos"
                  :key="servico.siglaServico"
                  :value="servico.siglaServico"
                >{{ servico.siglaServico }} - {{ servico.descricao }}</option>
              </select>
          </div>
         <div class="form-group col-lg-2">
            <label for="Preferencial2" class="control-label">2º Prefer.</label>
              <select
                class="form-control"
                id="Preferencial2"
                name="Preferencial2"
                selected
                :disabled="!liberarCampos"
                v-model="profissionalDisponibilidade.preferencial2"
              >
                <option
                  v-for="servico in servicos"
                  :key="servico.siglaServico"
                  :value="servico.siglaServico"
                >{{ servico.siglaServico }} - {{ servico.descricao }}</option>
              </select>
          </div>
         <div class="form-group col-lg-2">
            <label for="Preferencial3" class="control-label">3º Prefer.</label>
              <select
                class="form-control"
                id="Preferencial3"
                name="Preferencial3"
                selected
                :disabled="!liberarCampos"
                v-model="profissionalDisponibilidade.preferencial3"
              >
                <option
                  v-for="servico in servicos"
                  :key="servico.siglaServico"
                  :value="servico.siglaServico"
                >{{ servico.siglaServico }} - {{ servico.descricao }}</option>
              </select>
          </div>
         <div class="form-group col-lg-1">
            <label for="PorVez" class="control-label">Por vez</label>
            <input
              type="number"
              class="form-control"
              id="PorVez"
              name="PorVez"
              :disabled="!liberarCampos"
              v-model.number="profissionalDisponibilidade.porVez"
            />
          </div>
          <div class="form-group col-lg-1">
            <label for="Livres" class="control-label">Livres</label>
            <input
              type="number"
              class="form-control"
              id="Livres"
              name="Livres"
              :disabled="!liberarCampos"
              v-model.number="profissionalDisponibilidade.livres"
            />
          </div>
          <div class="form-group col-lg-2">
            <label for="Bloqueio" class="control-label">Bloqueio</label>
            <input
              type="text"
              class="form-control"
              id="Bloqueio"
              name="Bloqueio"
              :disabled="!liberarCampos"
              v-model="profissionalDisponibilidade.bloqueio"
            />
          </div>
        </div>
      </div>

      <template v-if="this.erro!=''">
        <p class="msgError">Erro durante Operação: {{ this.erro }}</p>
      </template>

      <hr />
      <div class="barraopcoescadastro">
        <button
          id="Alterar"
          @click="alterarRegistro()"
          class="btn btn-sm btn-secondary mr-2"
        >Alterar</button>
        <button
          id="Cancelar"
          @click="cancelarOperacao()"
          class="btn btn-sm btn-secondary mr-2"
        >Cancelar</button>
        <button
          id="Gravar"
          :disabled="!liberarCampos"
          @click="gravarRegistro()"
          class="btn btn-sm btn-secondary"
        >Gravar</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import constantes from "@/utils/constantes";
import errors from "@/utils/errors";

export default {
  //props : ['tabelaProcedimentoId'],
  computed: {
    ...mapGetters({
      profissionalDisponibilidades:
        "profissionaldisponibilidades/getAllProfissionalDisponibilidades",
      profissionalDisponibilidade: 
        "profissionaldisponibilidades/getProfissionalDisponibilidadeSelecionada",
      profissionalSelecionado: "profissionais/getProfissionalSelecionado",
      servicos: "servicos/getAllServicos",
      unidades: "unidades/getAllUnidades"
    }),
    opcoesDestinados: function() {
      return constantes.destinados.filter(function(u){ return u.opcao != 'T'})
    },
    liberarCampos() {
      return this.selecionouAlterar ? true : false;
    }
  },
  data() {
    return {
      erro: "",
      selecionouAlterar: false,
      sim_nao: constantes.sim_nao,
      retornando: false
    }
  },
  methods: {
    ...mapActions({
      setStatusCadastro: "profissionaldisponibilidades/setStatusCadastro",
      limparRegistroSelecionado: "profissionaldisponibilidades/limparRegistroSelecionado",
      formLeave: "profissionaldisponibilidades/formLeave"
    }),
    retornar() {
      this.retornando = true;
      this.$router.go(-1);
    },
    gravarRegistro() {
      if (!this.verificarCamposPreenchidos()) {
        return;
      }

      this.$http
        .put(`/v1.0/profissionaldisponibilidades/${this.profissionalDisponibilidade.id}`, this.profissionalDisponibilidade)
        .then(() => {
          this.erro = "";
          this.limparCampos();
          this.setStatusCadastro(false);
          this.retornar();
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        })
        .finally(() => {});
    },
    alterarRegistro() {
      this.selecionouAlterar = true;
    },
    cancelarOperacao() {
      this.limparCampos();
      this.setStatusCadastro(false);
      this.retornar();
    },
    limparCampos() {
      this.limparRegistroSelecionado();
    },
    verificarCamposPreenchidos() {
      return true;
    }
  },
  created() {},
  beforeRouteLeave(to, from, next) {
    if (this.retornando) {
      this.formLeave();
    }

    next(true);
  }
}
</script>

<style scoped>
    label {
      font-weight: 100%;
      font-size: 0.8rem;
      margin-top: 3px;
      margin-bottom: 1px;
    }
</style>
