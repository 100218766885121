<template>
  <div>
    <div class="cabecalho">
      <div class="coluna1">
        <button @click="retornar" class="btn btn-sm btn-primary mr-10">
          <i class="fa fa-arrow-left" />
        </button>
      </div>
      <div class="coluna2">
        <span>{{pacienteSelecionado.nome}} - Desatar/Remover - Sessões {{guiaSelecionada.siglaServico}}</span>
      </div>
      <div class="coluna3" />
    </div>

    <div class="container-fluid">
      <div class="row justify-content-center">
          <div class="col-lg-9">
            <div class="row justify-content-center">
                <div class="col-lg-2">
                    <label for="datInicial" class="control-label">Data Inicial</label>
                    <input type="date" id="datInicial" class="form-control" v-model="datInicial">
                </div>
                <div class="col-lg-2">
                    <label for="datFinal" class="control-label">Data Final</label>
                    <input type="date" id="datFinal" class="form-control" v-model="datFinal">
                </div>
                <div class="col-lg-2">
                    <button id="Selecionar" class="btn btn-small btn-info form-control mt-4" @click="selecionar()">Selecionar Período</button>
                </div>
                <div class="col-lg-2">
                    <button id="RemoverSelecao" class="btn btn-small btn-info form-control mt-4" @click="removerSelecao()">Remover Seleção</button>
                </div>
            </div>

            <div id="erro">
              <p v-if="erro" style="background-color:red; color:white; text-align:center">{{erro}}</p>
            </div>

            <div class="row justify-content-center mt-5">
              <div class="col-lg-8">
                <div style="height:450px;overflow:auto">
                  <b-table class="mt-2 mb-1" hover striped small sort-icon-left :items="sessoesDoPaciente" :fields="camposTabela">
                      <template v-slot:cell(data)="data">
                          {{ data.value | formatDate}}
                      </template>    
                      <template v-slot:cell(datGuia)="datGuia">
                          {{ datGuia.value | formatDate}}
                      </template>    
                      <template v-slot:cell(selecionado)="selecionado">
                          {{selecionado.value = selecionado.value == "S" ? "Sim": ""}}
                      </template>
                  </b-table>
                </div>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-lg-4">
                <button id="confirmarRemover" class="btn btn-small btn-danger form-control" @click="confirmarExecutarRemover()">Desatar da Guia e Remover da Agenda</button>
              </div>
              <div class="col-lg-4">
                <button id="confirmarDesatar" class="btn btn-small btn-warning form-control" @click="confirmarExecutarDesatar()">Desatar apenas da Guia (Mantendo Agenda)</button>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex"
import global from "@/mixins/global"
import agendaAtendimentos from "@/mixins/agendaAtendimentos"
import funcoes from "@/utils/funcoes"
import errors from "@/utils/errors"

export default {
    mixins : [agendaAtendimentos, global],
    computed : {
        ...mapGetters({
            pacienteSelecionado : "pacientes/getPacienteSelecionado",
            guiaSelecionada : "guias/getGuiaSelecionada",
            pacienteDatasAgendadas : "agendaAtendimentos/getDatasSelecionadas",
            usuarioLogadoDados : "global/getUsuarioLogadoDados",
        }),
    },
    data() {
        return {
            erro : "",
            datInicial : null,
            datFinal : null,
            camposTabela : [
              {key : "data", label : "Atend.", sortable : true},
              {key : "diaSemanaAbrev", label : "Dia", sortable : true},
              {key : "horario", label : "Horário", sortable : true},
              {key : "preferencial", label : "Pref"},
              {key : "preferencial2", label : "Pref 2"},
              {key : "preferencial3", label : "Pref 3"},
              {key : "status", label : "Status"},
              {key : "selecionado", label : "Selecionado"}
            ],
            sessoesDoPaciente : [],
        }
    },
    methods : {
        retornar() {
            this.$router.go(-1);
        },
        selecionar() {
            let datInicial = new Date(this.datInicial+"T00:00:00");
            let datFinal = new Date(this.datFinal+"T00:00:00");

            this.sessoesDoPaciente.forEach(sessao => {
                let datSessao = new Date(sessao.data);

                if (datSessao >= datInicial && datSessao <= datFinal) {
                    sessao.selecionado = "S";
                }
            });
        },
        removerSelecao() {
            let datInicial = new Date(this.datInicial+"T00:00:00");
            let datFinal = new Date(this.datFinal+"T00:00:00");

            this.sessoesDoPaciente.forEach(sessao => {
                let datSessao = new Date(sessao.data)

                if (datSessao >= datInicial && datSessao <= datFinal) {
                    sessao.selecionado = "N";
                }
            });
        },
        confirmarExecutarRemover() {
          this.$bvModal
            .msgBoxConfirm("Desatar Sessões do Período da Guia e Remover da Agenda?", {
              title: "Atenção",
              size: "sm",
              buttonSize: "sm",
              okVariant: "danger",
              okTitle: "Sim",
              cancelTitle: "Não",
              footerClass: "p-2",
              hideHeaderClose: true,
              centered: true
            })
            .then(value => {
              if (value) {
                this.executarRemover();
              }
            })
            .catch(error => {
              this.erro = errors.tratarErros(error);
            });
        },
        async executarRemover() {
          let selecionados = this.sessoesDoPaciente.filter(sessao => sessao.selecionado == "S");

          if (selecionados.length == 0) {
              this.$toasted.show("Não foram selecionadas Sessões para realizar a operação.", {type : "info", icon : "times"});
              return;
          }

          await this.removerPacienteGuiaSessoes(selecionados);
          this.retornar();
        },
        confirmarExecutarDesatar() {
          this.$bvModal
            .msgBoxConfirm("Desatar Sessões do Período Apenas da Guia (Mantendo Agenda)?", {
              title: "Atenção",
              size: "sm",
              buttonSize: "sm",
              okVariant: "danger",
              okTitle: "Sim",
              cancelTitle: "Não",
              footerClass: "p-2",
              hideHeaderClose: true,
              centered: true
            })
            .then(value => {
              if (value) {
                this.executarDesatar();
              }
            })
            .catch(error => {
              this.erro = errors.tratarErros(error);
            });
        },
        async executarDesatar() {
          let selecionados = this.sessoesDoPaciente.filter(sessao => sessao.selecionado == "S");

          if (selecionados.length == 0) {
              this.$toasted.show("Não foram selecionadas Sessões para realizar a operação.", {type : "info", icon : "times"});
              return;
          }

          await this.desatarGuiaSessoes(selecionados);
          this.retornar();
        }
    },
    created() {
      this.showMenu(false);
      this.datInicial = funcoes.mySqlToJSDateFormat(new Date());
      this.datFinal = funcoes.mySqlToJSDateFormat(this.guiaSelecionada.datValidadeSenha);


      this.sessoesDoPaciente = this.pacienteDatasAgendadas.map(sessao => {
          let selecionado = "N";
          return {...sessao, selecionado}
      });
    }
}
</script>

<style scoped>
    label {
      font-weight: 100%;
      font-size: 0.8rem;
      margin-top: 3px;
      margin-bottom: 1px;
    }
</style>