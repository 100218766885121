import errors from "@/utils/errors";

export default {
    data() {
        return {
            cIModelos : []
        }
    },
    methods : {
        carregarCIModelos(tenant, consultorioItemId) {
            this.$http.get(`/v1.0/CIModelos/${tenant}/ConsultorioItem/${consultorioItemId}`)
            .then(resp => {
                this.cIModelos = resp.data;
                this.loading = false;
                this.erro= ""
            })
            .catch(error => this.erro = errors.tratarErros(error))
        }
    }
}
