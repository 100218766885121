import errors from "@/utils/errors";

export default {
    data() {
        return {
            registrosBrasindice : []
        }
    },
    methods : {
        async carregarDespesasBrasindice(tenant, despesaId) {
            this.loadingDespesasBrasindice = true;
            this.loadedDespesasBrasindice = false;

            await this.$http.get(`/v1.0/despesasBrasindice/${tenant}/Despesa/${despesaId}`)
            .then(resp => {
                this.registrosBrasindice = resp.data;
                this.error = "";

                this.loadingDespesasBrasindice = false;
                this.loadedDespesasBrasindice = true;
            })
            .catch(error => {
                this.error = errors.tratarErros(error);
                this.loadingDespesasBrasindice = false;
            })
        }
    }
}
