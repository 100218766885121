const estruturaTabela = {
    id : 0, codAnt : 0, descricao : "", ativo : 'S', tipo : 1,
    qtde : 0, unidade : "", codReferencia : "", valor : 0,
    despesaId : 0, procedimentoId : 0, kitId : 0
}

const state = {
    kitItemSelecionado : Object,
}

const getters = {
    getKitItemSelecionado(state) {
        return state.kitItemSelecionado
    },
}

const mutations = {
    setKitItemSelecionado(state, kitItem) {
        state.kitItemSelecionado = {...kitItem}
    },

    limparRegistroSelecionado(state) {
        state.kitItemSelecionado = {...estruturaTabela}
    },
}

const actions = {
    setKitItemSelecionado({commit}, kitItem) {
        commit("setKitItemSelecionado", kitItem)
    },

    limparRegistroSelecionado({commit}) {
        commit("limparRegistroSelecionado")
    },

    formLeave({commit}) {
        commit('limparRegistroSelecionado');
    }
}

export default {
    namespaced : true,    
    state,
    getters,
    mutations,
    actions
}