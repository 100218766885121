const estruturaTabela = {
    id : 0, 
    codProMatMedIsolados : "*00000000*", codProPagtoDividas : "#00000000#", executarSysRotinas : "N",

    // ativa : 'S', 
    // descricao : "",  logradouro : "", numero : 0, complemento : "", bairro : "", cidade : "", cep : "", uf : 0,
    // email : "", site : "", ddd : "", fone : "", cnpjNumero : "", whatsapp : "",
    // fonesConfirmacao : "", emailConfirmacao : "", 
    // pastaExames : "", pastaFotos : "", pastaLogos : "", pastaTissXml : "", 
    // pastaSimpro : "", pastaBrasindice : "", pastaFotosProfissionais : "",
    // formatoFotos : 0, formatoExames : 0, datRegistro : null, 
    // datAgendaCompactacao : null, datUltimaAtualizacao : null, datArquivoAgenda : null, 
    // datArquivoFaturas : null, datArquivoOcorrencias : null, datPrimeiroLogin : null,
    // datViProntuarios : null, datViReceituario : null, datViPedidosExames : null, datViRelatorios : null,
    // datViDietas : null, datViSolicitacoes : null, datViEnfermagem : null, datViFisioterapia : null, datViAtestados : null,
    // bloquearProntuariosApos : 0, agendarAposLimite : 'S', lancarConsultaAutomatica : 'S',
    // talk : 'N', talkServerIp : "", talkServerPort : 0, talkSinalSonoro : "N", 
    // forcarPacienteSexo : "N", forcarPacienteDatNas: "N", forcarPacienteCor : "N", forcarPacienteEstCivil : "N",
    // forcarPacienteRgNumero : "N", forcarPacienteCpfNumero : "N", forcarPacienteCnsNumero : "N", 
    // forcarPacienteFone : "N", forcarPacienteCelular : "N", forcarPacienteEmail : "N", 
    // ocultarPacienteCertidao : "N", ocultarPacienteCnsNumero : "N", ocultarPacienteRgEmissao : "N", 
    // bancoHorasLei : "", bancoHorasMaxSemana : 0, bancoHorasMaxSabado : 0,
    // exibirAlertaMaterial : "N", excluirLancto : "S", excluirLanctoVerificado : "N", excluirLanctoFaturaFechada : "N",
    // excluirLanctoLoteFechado : "N", utilizaAgendaCirurgias : "N", permiteAumentarValor : "N", 
    // smsUser : "", smsPassWord : "", smsRespostaAceite: "", smsRespostaRecusa : "", smsMensagem : "", smsMaxSize : null,
    // smsClinica : "", smsPacienteSize : null, livroFisioMax : null, livroRxMax : null,
    // faturaIr : 0, faturaPis : 0, faturaCofins : 0, faturaCsll : 0, faturaIss : 0, irpfPorcentagem : 0,
    // notaFiscalDescricao : "", notaFiscalItem : "", modeloFaturamento : "1", 
    // loginAbrirCaixa : "S", loginSelecionarSala : "S", relacionarMaterialAoTratamento : "N", exigir2Fones : "N",
    // exigirTipoGuia : "N", estoqueModoInventario : "S", atendimentoSeriado : "N", agendaUtilizaNotificacao : "N",
    // statusEsperaExibirPendencias : "S", removerAgendamentoDuplicado : "S", permiteCancelarBloqueioAgenda : "S",
    // nivelSegurancaAgenda : 5, rxCortePadrao : null, processoComprasSimples : "S", 
    // operadorPrimeiroLoginId : null, consultorioItemRXId : null, 
    // materialCentroEntradaId : null, materialCentroSaidaId : null
}

const state = {
    unidadeSelecionada : null,
    modo : "",
}

const getters = {
    getUnidadeSelecionada(state) {
        return state.unidadeSelecionada;
    },

    getModo(state) {
        return state.modo;
    }
}

const mutations = {
    setUnidadeSelecionada(state, unidade) {
        state.unidadeSelecionada = unidade;
    },

    setModo(state, modo) {
        state.modo = modo;
    },

    limparRegistroSelecionado(state) {
        state.unidadeSelecionada = {...estruturaTabela}
    },
}

const actions = {
    setUnidadeSelecionada({commit}, unidade) {
        commit("setUnidadeSelecionada", unidade)
    },

    setModo({commit}, modo) {
        commit("setModo", modo);
    },

    limparRegistroSelecionado({commit}) {
        commit("limparRegistroSelecionado")
    },

    formLeave({commit}) {
        commit('limparRegistroSelecionado');
    }
}

export default {
    namespaced : true,
    state,
    getters,
    mutations,
    actions
}