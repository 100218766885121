<template>
  <div>
    <div class="cabecalho">
      <div class="coluna1">
        <button @click="retornar" class="btn btn-sm btn-primary mr-10">
          <i class="fa fa-arrow-left" />
        </button>
      </div>
      <div class="coluna2">
        <span>{{pacienteSelecionado.nome}} - Buscar na Agenda - Sessões {{guiaSelecionada.siglaServico}}</span>
      </div>
      <div class="coluna3" />
    </div>

    <div class="container-fluid">
      <div class="row justify-content-center">
          <div class="col-lg-12">
            <div class="row justify-content-center">
                <div class="col-lg-2">
                    <label for="selecionarProfissional" class="control-label">Profissional/Agenda</label>
                    <select id="selecionarProfissional" class="form-control" v-model="profissionalId">
                        <option v-for="profissional in profissionais" :key="profissional.id" :value="profissional.id">
                            {{profissional.nome}}
                        </option>
                    </select>
                </div>
                <div class="col-lg-2">
                    <label for="selecionarUnidade" class="control-label" style="margin-bottom: 0px">Unidade:</label>
                    <select id="selecionarUnidade" class="form-control" v-model="unidadeId">
                        <option v-for="unidade in unidades" :key="unidade.id" :value="unidade.id">
                            {{unidade.descricao}}
                        </option>
                    </select>
                </div>
                <div class="col-lg-2">
                    <label for="datInicial" class="control-label">Data Inicial</label>
                    <input type="date" id="datInicial" class="form-control" v-model="datInicial">
                </div>
                <div class="col-lg-2">
                    <label for="datFinal" class="control-label">Data Final</label>
                    <input type="date" id="datFinal" class="form-control" v-model="datFinal">
                </div>
                <div class="col-lg-2">
                    <button id="Selecionar" class="btn btn-small btn-info form-control mt-4" @click="buscarAgenda()">Buscar na Agenda</button>
                </div>
            </div>

            <div id="erro">
              <p v-if="erro" style="background-color:red; color:white; text-align:center">{{erro}}</p>
            </div>

            <div class="row justify-content-center mt-5">
              <div class="col-lg-8">
                <div style="height:450px;overflow:auto">
                  <b-table class="mt-2 mb-1" hover striped small sort-icon-left :items="agendaPaciente" :fields="camposTabela">
                      <template v-slot:cell(data)="data">
                          {{ data.value | formatDate}}
                      </template>    
                      <template v-slot:cell(datGuia)="datGuia">
                          {{ datGuia.value | formatDate}}
                      </template>    
                      <template v-slot:cell(selecionado)="selecionado">
                          {{selecionado.value = selecionado.value == "S" ? "Sim": ""}}
                      </template>
                  </b-table>
                </div>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-lg-8">
                <button id="RemoverSelecao" class="btn btn-small btn-info form-control" @click="confirmarAdicionar()">Adicionar à Guia de Sessões</button>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex"
import global from "@/mixins/global"
import agendaAtendimentos from "@/mixins/agendaAtendimentos"
import unidades from "@/mixins/unidades"
import profissionais from "@/mixins/profissionais"
import funcoes from "@/utils/funcoes"
import errors from "@/utils/errors"

export default {
    mixins : [agendaAtendimentos, unidades, profissionais,global],
    computed : {
        ...mapGetters({
            pacienteSelecionado : "pacientes/getPacienteSelecionado",
            guiaSelecionada : "guias/getGuiaSelecionada",
            pacienteDatasAgendadas : "agendaAtendimentos/getDatasSelecionadas",
            usuarioLogadoDados : "global/getUsuarioLogadoDados",
        }),
    },
    data() {
        return {
            erro : "",
            datInicial : null,
            datFinal : null,
            unidadeId : 0,
            profissionalId : 0,
            camposTabela : [
              {key : "data", label : "Atend.", sortable : true},
              {key : "diaSemanaAbrev", label : "Dia", sortable : true},
              {key : "horario", label : "Horário", sortable : true},
              {key : "preferencial", label : "Pref"},
              {key : "preferencial2", label : "Pref 2"},
              {key : "preferencial3", label : "Pref 3"},
              {key : "status", label : "Status"},
            ],
            agendaPaciente : []
        }
    },
    methods : {
        retornar() {
            this.$router.go(-1);
        },
        async buscarAgenda() {
          if (this.profissionalId==0) {
            this.$toasted.show("Selecione o Profissional.", {type: "info", icon : "times"});
            return;
          }

          if (this.unidadeId==0) {
            this.$toasted.show("Selecione a Unidade.", {type: "info", icon : "times"});
            return;
          }

          if (this.datInicial==null) {
            this.$toasted.show("Digite a Data Inicial.", {type: "info", icon : "times"});
            return;
          }

          if (this.datFinal==null) {
            this.$toasted.show("Digite a Data Final.", {type: "info", icon : "times"});
            return;
          }

          await this.buscarNaAgendaParaSessoes(this.usuarioLogadoDados.tenant, this.guiaSelecionada.pacienteId, this.profissionalId, this.unidadeId, this.datInicial, this.datFinal, true);
          this.agendaPaciente = this.agendaAtendimentos.filter(data => data.guiaId==null && data.destinado == "S");
        },
        confirmarAdicionar() {
          this.$bvModal
            .msgBoxConfirm("Adicionar as Datas à Guia do Paciente?", {
              title: "Atenção",
              size: "md",
              buttonSize: "sm",
              okVariant: "danger",
              okTitle: "Sim",
              cancelTitle: "Não",
              footerClass: "p-2",
              hideHeaderClose: true,
              centered: true
            })
            .then(value => {
              if (value) {
                this.adicionar();
              }
            })
            .catch(error => {
              this.erro = errors.tratarErros(error);
            });
        },
        async adicionar() {
          if (this.agendaPaciente.length == 0) {
              this.$toasted.show("Não foram selecionadas Datas na Agenda para adicionar à Guia.", {type : "info", icon : "times"});
              return;
          }

          await this.relacionarGuiaSessoes(this.usuarioLogadoDados.tenant, this.agendaPaciente);
          if (this.erro == "") {
            this.retornar();
          }
        },
    },
    created() {
      this.showMenu(false);
      this.carregarUnidades(this.usuarioLogadoDados.tenant);
      this.carregarProfissionaisComAgendaPropria(this.usuarioLogadoDados.tenant, 0);

      this.datInicial = funcoes.mySqlToJSDateFormat(new Date());
      this.datFinal = funcoes.mySqlToJSDateFormat(this.guiaSelecionada.datValidadeSenha);
    }
}
</script>

<style scoped>
    label {
      font-weight: 100%;
      font-size: 0.8rem;
      margin-top: 3px;
      margin-bottom: 1px;
    }
</style>