import errors from "@/utils/errors";

export default {
    data() {
        return {
            despesaCentros : [],
        }
    },
    methods : {
        carregarDespesaCentros(tenant) {
            this.loadingDespesaCentros = true;
            this.loadedDespesaCentros = false;

            this.$http
              .get(`/v1.0/despesacentros/${tenant}`)
              .then(resp => {
                this.despesaCentros = resp.data;
                this.erro = "";
                this.loadedDespesaCentros = true;
              })
              .catch(error => (this.erro = errors.tratarErros(error)))
              .finally(() => {
                this.loadingDespesaCentros = false;
              });
          }
      
    }
}
