<template>
  <div>
    <div class="cabecalho">
      <div class="coluna1">
        <button @click="retornar" class="btn btn-sm btn-primary mr-10"><i class="fa fa-arrow-left"/></button>
      </div>
      <div class="coluna2">
        <span>Conselhos Profissionais</span>
      </div>
      <div class="coluna3"/>
    </div>  

    <div id="erro">
      <p v-if="erro" style="background-color:red; color:white; text-align:center">{{erro}}</p>
    </div>

    <hr />
    <div style="display:flex; flex-direction:row; justify-content:center;height:430px;overflow:auto">
      <b-spinner v-if="loadingConselho" label="carregando..."/>
      <b-table v-if="loadedConselho" class="col-lg-6" hover striped small sort-icon-left :items="conselhos" :fields="camposTabela">
      </b-table>
    </div>
    <b-pagination v-if="!loadingConselho" class="col-lg-12 justify-content-center" size="md" v-model="page" :total-rows="count" :per-page="limit"/>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import conselhos from "@/mixins/conselhos"
import global from "@/mixins/global"

export default {
  mixins : [conselhos, global],
  computed: {
    ...mapGetters({
      usuarioLogadoDados : "global/getUsuarioLogadoDados"
    }),
    liberarCampos() {
      return this.modo == "Incluir" || this.modo == "Alterar" ? true : false;
    }
  },
  data() {
    return {
      page : 1,
      erro : "",
      modo : "",
      conselho : {},
      loadingConselho : false,
      loadedConselho : false,
      camposTabela : [
        {key: "codTermo", label : "Código", sortable : true},
        {key: "termo", label : "Termo", sortable : true},
      ],
    }
  },
  watch : {
    page() {
      this.carregarConselhos(this.filtrar, this.page);
    }
  },
  methods: {
    retornar() {
      this.$router.go(-1);
    },
  },
  created() {
    this.showMenu(false);
    this.carregarConselhos(this.filtrar, this.page);
  },
}
</script>

<style scoped>
</style>
