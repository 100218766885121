<template>
  <div>
    <div class="cabecalho">
      <div class="coluna1">
        <button @click="retornar" class="btn btn-sm btn-primary mr-10">
          <i class="fa fa-arrow-left" />
        </button>
      </div>
      <div class="coluna2">
        <span>{{pacienteSelecionado.nome}} - Adicionar Horários - Sessões {{guiaSelecionada.siglaServico}} </span>
      </div>
      <div class="coluna3" />
    </div>

    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-lg-2">
                <label for="selecionarProfissional" class="control-label">Profissional/Agenda</label>
                <select id="selecionarProfissional" class="form-control" v-model="profissionalId">
                    <option v-for="profissional in profissionais" :key="profissional.id" :value="profissional.id">
                        {{profissional.nome}}
                    </option>
                </select>
            </div>
            <div class="col-lg-2">
                <label for="selecionarUnidade" class="control-label">Unidade</label>
                <select id="selecionarUnidade" class="form-control" v-model="unidadeId">
                    <option v-for="unidade in unidades" :key="unidade.id" :value="unidade.id">
                        {{unidade.descricao}}
                    </option>
                </select>
            </div>
            <div class="col-lg-2">
                <label for="datInicial" class="control-label">Data Inicial</label>
                <input type="date" id="datInicial" class="form-control" v-model="datInicial">
            </div>
            <div class="col-lg-2">
                <label for="datFinal" class="control-label">Data Final</label>
                <input type="date" id="datFinal" class="form-control" v-model="datFinal">
            </div>
            <div class="col-lg-1">
                <label for="horaInicial" class="control-label">Inicial</label>
                <input type="time" id="horaInicial" class="form-control" v-model="horaInicial">
            </div>
            <div class="col-lg-1">
                <label for="horaFinal" class="control-label">Final</label>
                <input type="time" id="horaFinal" class="form-control" v-model="horaFinal">
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-1">
                <label for="diaSemana1" class="control-label">Dia Semana</label>
                <select id="diaSemana1" class="form-control" v-model="diaSemana1">
                    <option value="" disabled selected>Todos</option>
                    <option v-for="diaSemana in diasSemana" :key="diaSemana.opcao" :value="diaSemana.opcao">
                        {{diaSemana.abrev}}
                    </option>
                </select>
            </div>
            <div class="col-lg-1">
                <label for="diaSemana2" class="control-label">Dia Semana</label>
                <select id="diaSemana2" class="form-control" v-model="diaSemana2" :disabled="!liberarDiaSemana2">
                    <option value="" disabled selected>Todos</option>
                    <option v-for="diaSemana in diasSemana" :key="diaSemana.opcao" :value="diaSemana.opcao">
                        {{diaSemana.abrev}}
                    </option>
                </select>
            </div>
            <div class="col-lg-1">
                <label for="diaSemana3" class="control-label">Dia Semana</label>
                <select id="diaSemana3" class="form-control" v-model="diaSemana3" :disabled="!liberarDiaSemana3">
                    <option value="" disabled selected>Todos</option>
                    <option v-for="diaSemana in diasSemana" :key="diaSemana.opcao" :value="diaSemana.opcao">
                        {{diaSemana.abrev}}
                    </option>
                </select>
            </div>
            <div class="col-lg-1">
                <label for="diaSemana4" class="control-label">Dia Semana</label>
                <select id="diaSemana4" class="form-control" v-model="diaSemana4" :disabled="!liberarDiaSemana4">
                    <option value="" disabled selected>Todos</option>
                    <option v-for="diaSemana in diasSemana" :key="diaSemana.opcao" :value="diaSemana.opcao">
                        {{diaSemana.abrev}}
                    </option>
                </select>
            </div>
            <div class="col-lg-2">
                <label for="ignorarPreferenciais" class="control-label">Ignorar Preferenciais</label>
                <select id="ignorarPreferenciais" class="form-control" v-model="ignorarPreferenciais" selected>
                    <option v-for="resposta in sim_nao" :key="resposta.opcao" :value="resposta.opcao">
                        {{resposta.valor}}
                    </option>
                </select>
            </div>
            <div class="col-lg-2">
                <label for="permiteAgendamentoMesmoDia" class="control-label">Permite mesmo Dia</label>
                <select id="permiteAgendamentoMesmoDia" class="form-control" v-model="permiteAgendamentoMesmoDia" selected>
                    <option v-for="resposta in sim_nao" :key="resposta.opcao" :value="resposta.opcao">
                        {{resposta.valor}}
                    </option>
                </select>
            </div>
            <div class="col-lg-2">
                <button id="Filtrar" class="btn btn-small btn-info form-control mt-4" @click="filtrar()">Buscar Horários Livres</button>
            </div>
        </div>

        <div id="erro">
          <p v-if="erro" style="background-color:red; color:white; text-align:center">{{erro}}</p>
        </div>

        <hr/>
        <!-- Tabela de Datas/Horários -->
        <b-spinner v-if="loading" label="carregando..."/>
        <template v-if="!loading">
            <div class="row justify-content-center">
              <div class="col-lg-4">
                <p class="tituloTabela">Datas/Horários Livres</p>
                <div style="height:418px;overflow:auto" >
                  <!-- Tabela de Datas/Horários Livres -->
                  <b-table class="mt-2 mb-1" hover striped small sort-icon-left :items="agendaAtendimentos" :fields="camposTabela">
                      <template v-slot:cell(data)="data">
                          {{ data.value | formatDate}}
                      </template>    
                      <template v-slot:cell(datGuia)="datGuia">
                          {{ datGuia.value | formatDate}}
                      </template>    
                      <template v-slot:cell(actions)="row">
                        <b-button variant="info" size="sm" class="mr-1" @click="adicionarData(row.item)">
                          <i class="fa fa-plus"/>
                        </b-button>
                      </template>
                  </b-table>
                </div>
              </div>

              <div class="col-lg-1">
              </div>
              
              <div class="col-lg-4">
                <p class="tituloTabela">Pode Adicionar {{guiaSelecionada.sessoesSolicitadas - datasSelecionadas.length}} Sessão(ões)</p>
                <div style="height:418px;overflow:auto">
                  <!-- Tabela de Datas/Horários Selecionados -->
                  <b-table class="mt-2 mb-1" hover striped small sort-icon-left :items="datasSelecionadas" :fields="camposTabela">
                      <template v-slot:cell(data)="data">
                          {{ data.value | formatDate}}
                      </template>    
                      <template v-slot:cell(datGuia)="datGuia">
                          {{ datGuia.value | formatDate}}
                      </template>    
                      <template v-slot:cell(actions)="row">
                        <b-button v-if="row.item.guiaId==0" variant="danger" size="sm" class="mr-1" @click="removerData(row.item)">
                          <i class="fa fa-minus"/>
                        </b-button>
                      </template>
                  </b-table>
                </div>
              </div>
            </div>
        </template>
        <div class="row justify-content-center mt-2">
            <div class="col-lg-10">
                <button id="gravarSessoes" class="btn btn-sm btn-info form-control" @click="gravarSessoes()">Gravar Sessões</button>
            </div>
        </div>
      </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex"
import global from "@/mixins/global"
import agendaAtendimentos from "@/mixins/agendaAtendimentos"
import profissionais from "@/mixins/profissionais"
import unidades from "@/mixins/unidades"
import constantes from "@/utils/constantes"
import funcoes from "@/utils/funcoes"

export default {
    mixins : [profissionais, unidades, agendaAtendimentos, global],
    computed : {
        ...mapGetters({
            pacienteSelecionado : "pacientes/getPacienteSelecionado",
            guiaSelecionada : "guias/getGuiaSelecionada",
            pacienteDatasAgendadas : "agendaAtendimentos/getDatasSelecionadas",
            usuarioLogadoDados : "global/getUsuarioLogadoDados",
        }),
    },
    data() {
        return {
          loading : true,
          erro : "",
          modo : "",

          profissionalId : 0,
          unidadeId : 0,
          datInicial : null,
          datFinal : null,
          horaInicial : null,
          horaFinal : null,
          diaSemana1 : "",
          diaSemana2 : "",
          diaSemana3 : "",
          diaSemana4 : "",

          liberarDiaSemana2 : false,
          liberarDiaSemana3 : false,
          liberarDiaSemana4 : false,

          ignorarPreferenciais : "N",
          permiteAgendamentoMesmoDia : "N",

          diasSemana : constantes.diasSemana.filter(dia => dia.opcao > 0),
          sim_nao : constantes.sim_nao,

          camposTabela : [
            {key : "data", label : "Atend.", sortable : true},
            {key : "diaSemanaAbrev", label : "Dia", sortable : true},
            {key : "horario", label : "Horário", sortable : true},
            {key : "preferencial", label : "Pref"},
            {key : "preferencial2", label : "Pref 2"},
            {key : "preferencial3", label : "Pref 3"},
            {key : "actions", label : "Ações"},
          ],

          datasSelecionadas : [],
        }
    },
    watch : {
        diaSemana1() {
            this.liberarDiaSemana2 = true;
        },
        diaSemana2() {
            this.liberarDiaSemana3 = true;
        },
        diaSemana3() {
            this.liberarDiaSemana4 = true;
        }
    },
    methods : {
        retornar() {
            this.$router.go(-1);
        },
        filtrar() {
            if (!this.verificarCamposPreenchidos()) {
                return;
            }

            let preferencial = this.ignorarPreferenciais=="S" ? "" : this.guiaSelecionada.siglaServico;

            // [HttpGet("{tenant}/livres/{profissionalId}/{unidadeId}/{datInicial}/{datFinal}/{diaSemana1}/{diaSemana2}/{diaSemana3}/{diaSemana4}/{horarioInicial}/{horarioFinal}/{destinado}/{preferencial}")]

            this.carregarAgendaAtendimentosHorariosLivres(this.usuarioLogadoDados.tenant, this.profissionalId, this.unidadeId, this.datInicial, this.datFinal, 
                                                          this.diaSemana1, this.diaSemana2, this.diaSemana3, this.diaSemana4, 
                                                          this.horaInicial, this.horaFinal, "S", preferencial, true); //Tipo = Sessões, Preferencial, Adicionar dia da Semana ao Resultado, 
        },
        verificarCamposPreenchidos() {
            if (this.profissionalId==0) {
                this.$toasted.show("Selecione o Profissional/Agenda", {type : "info", icon : "times"});
                return false;
            }

            if (this.unidadeId==0) {
                this.$toasted.show("Selecione a Unidade", {type : "info", icon : "times"});
                return false;
            }

            if (this.horaInicial==null) {
                this.$toasted.show("Preencher o Horário Inicial", {type : "info", icon : "times"});
                return false;
            }

            if (this.horaFinal==null) {
                this.$toasted.show("Preencher o Horário Final", {type : "info", icon : "times"});
                return false;
            }

            return true;
        },
        adicionarData(dataSelecionada) {
            if (this.guiaSelecionada.sessoesSolicitadas - this.datasSelecionadas.length <= 0) {
                this.$toasted.show("Já foi atingido o número de sessões liberadas para o Paciente", {type: "info", icon: "times"});
                return;
            }

            if (this.permiteAgendamentoMesmoDia == "N") {
                if (this.datasSelecionadas.filter(dia => dia.data == dataSelecionada.data).length > 0) {
                    this.$toasted.show("Essa Data já foi adicionada para o Paciente.", {type: "info", icon: "times"});
                    return;
                }
            }

            //------------------------------------------- Adicionar
            this.datasSelecionadas.push(dataSelecionada);

            //------------------------------------------- Remover
            let indice = this.agendaAtendimentos.findIndex(data => data.id == dataSelecionada.id);
            this.agendaAtendimentos.splice(indice, 1);
        },
        removerData(dataSelecionada) {
            //------------------------------------------- Remover
            let indice = this.datasSelecionadas.findIndex(data => data.id == dataSelecionada.id);
            this.datasSelecionadas.splice(indice, 1);

            //------------------------------------------- Adicionar
            this.agendaAtendimentos.push(dataSelecionada);
        },
        async gravarSessoes() {
            if (this.datasSelecionadas.length==0) {
                this.$toasted.show("Ainda não foram selecionadas datas para as sessões do paciente.", {type: "info", icon: "times"})
                return;
            }
            await this.incluirPacienteGuiaSessoes(this.usuarioLogadoDados.tenant, this.datasSelecionadas);

            if (this.erro == "") {
                this.datasSelecionadas.forEach(data => {
                    data.guiaId = this.guiaSelecionada.id;
                });
            }
        }
    },
    created() {
      this.showMenu(false);
      this.carregarProfissionaisComAgendaPropria(this.usuarioLogadoDados.tenant, 0);
      this.carregarUnidades(this.usuarioLogadoDados.tenant);

      this.datInicial = funcoes.mySqlToJSDateFormat(new Date());
      this.datFinal = funcoes.mySqlToJSDateFormat(this.guiaSelecionada.datValidadeSenha);

      if (this.pacienteDatasAgendadas.length>0) {
          this.datasSelecionadas = [...this.pacienteDatasAgendadas]
      }
    },
    mounted() {
      this.loading = true;
    }
}
</script>

<style scoped>
    label {
      font-weight: 100%;
      font-size: 0.8rem;
      margin-top: 3px;
      margin-bottom: 1px;
    }

    .tituloTabela {
        text-align: center;
        font-weight: 100%;
        font-size: 1.2rem;
        margin-top: 3px;
        margin-bottom: 1px;
    }
</style>