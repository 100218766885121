const state = {
    despesaSimproSelecionada : null,
}

const getters = {
    getDespesaSimproSelecionada(state) {
        return state.despesaSimproSelecionada;
    },
}

const mutations = {
    setDespesaSimproSelecionada(state, registro) {
        state.despesaSimproSelecionada = {...registro};
    },
}

const actions = {
    setDespesaSimproSelecionada({commit}, registro) {
        commit("setDespesaSimproSelecionada", registro)
    },

    formLeave({commit}) {
        commit("setDespesaSimproSelecionada", null);
    }
}

export default {
    namespaced : true,
    state, 
    getters,
    mutations,
    actions
}