const estruturaTabela = {
    id : 0, idCliente : "R", usuarioPlanoDigital : "", senhaPlanoDigital : "", 
    datTrava : null, hashTrava : "", habilitados : 99, tipo : "", dBversion : "",
    fantasia : "", razaoSocial : "", cnpjNumero : "",
    securityMpp : 365, securityMal : 3, securityMisp : 30, securityTbrp : 180,
}

const state = {
    configSelecionado : null,
}

const getters = {
    getConfigSelecionado(state) {
        return state.configSelecionado;
    },
}

const mutations = {
    setConfigSelecionado(state, config) {
        state.configSelecionado = config;
    },

    limparRegistroSelecionado(state) {
        state.configSelecionado = {...estruturaTabela}
    },
}

const actions = {
    setConfigSelecionado({commit}, config) {
        commit("setConfigSelecionado", config)
    },

    limparRegistroSelecionado({commit}) {
        commit("limparRegistroSelecionado")
    },

    formLeave({commit}) {
        commit('limparRegistroSelecionado');
    }
}

export default {
    namespaced : true,
    state,
    getters,
    mutations,
    actions
}
