<template>
  <div id="CadOperadoraDados">
    <div class="container-fluid">
      <div class="row">
        <div class="form-group col-lg-1">
          <label for="Id" class="control-label">Id:</label>
          <input
            type="text"
            class="form-control"
            disabled
            id="Id"
            name="Id"
            :value="operadoraSelecionada.id"
          />
        </div>

        <div class="form-group col-lg-4">
          <label for="Fantasia" class="control-label">Nome Fantasia:</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="Fantasia"
            name="Fantasia"
            v-model="operadoraSelecionada.fantasia"
          />
        </div>

        <div class="form-group col-lg-5">
          <label for="RazaoSocial" class="control-label">Razão Social:</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="RazaoSocial"
            name="RazaoSocial"
            v-model="operadoraSelecionada.razaoSocial"
          />
        </div>

        <div class="form-group col-lg-1">
          <label for="Ativo" class="control-label">Ativo:</label>
          <select
            id="Ativo"
            class="form-control"
            :disabled="!liberarCampos"
            selected
            v-model="operadoraSelecionada.ativa"
          >
            <option
              v-for="resposta in sim_nao"
              :key="resposta.opcao"
              :value="resposta.opcao"
            >{{resposta.valor}}</option>
          </select>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-lg-2">
          <label for="CNPJ" class="control-label">CNPJ:</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="CNPJ"
            name="CNPJ"
            v-model="operadoraSelecionada.cnpjNumero"
          />
        </div>
        <div class="form-group col-lg-2">
          <label for="InscricaoEstadual" class="control-label">Inscrição Estadual:</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="InscricaoEstadual"
            name="InscricaoEstadual"
            v-model="operadoraSelecionada.inscricaoEstadual"
          />
        </div>
        <div class="form-group col-lg-2">
          <label for="DatInicioVigencia" class="control-label">Início Vigência:</label>
          <input
            type="date"
            class="form-control"
            :disabled="!liberarCampos"
            id="DatInicioVigencia"
            name="DatInicioVigencia"
            v-model="operadoraSelecionada.datInicioVigencia"
          />
        </div>
        <div class="form-group col-lg-2">
          <label for="DatFinalVigencia" class="control-label">Final Vigência:</label>
          <input
            type="date"
            class="form-control"
            :disabled="!liberarCampos"
            id="DatFinalVigencia"
            name="DatFinalVigencia"
            v-model="operadoraSelecionada.datFinalVigencia"
          />
        </div>
        <div class="form-group col-lg-1">
          <label for="TipoEmpresa" class="control-label">Empresa:</label>
          <select
            id="TipoEmpresa"
            class="form-control"
            :disabled="!liberarCampos"
            v-model="operadoraSelecionada.tipoEmpresa"
            selected
          >
            <option
              v-for="resposta in sim_nao"
              :key="resposta.opcao"
              :value="resposta.opcao"
            >{{resposta.valor}}</option>
          </select>
        </div>
        <div class="form-group col-lg-2">
          <label for="LogoEmpresa" class="control-label">Logo:</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="LogoEmpresa"
            name="LogoEmpresa"
            v-model="operadoraSelecionada.logoEmpresa"
          />
        </div>
      </div>

      <div class="row">
        <div class="form-group col-lg-2">
          <label for="Cep" class="control-label">Cep:</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="Cep"
            name="Cep"
            v-model="operadoraSelecionada.cep"
          />
        </div>
        <div class="form-group col-lg-5">
          <label for="Logradouro" class="control-label">Logradouro:</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="Logradouro"
            name="Logradouro"
            v-model="operadoraSelecionada.logradouro"
          />
        </div>
        <div class="form-group col-lg-1">
          <label for="Numero" class="control-label">Número:</label>
          <input
            type="number"
            class="form-control"
            :disabled="!liberarCampos"
            id="Numero"
            name="Numero"
            v-model.number="operadoraSelecionada.numero"
          />
        </div>
        <div class="form-group col-lg-3">
          <label for="Complemento" class="control-label">Complemento:</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="Complemento"
            name="Complemento"
            v-model="operadoraSelecionada.complemento"
          />
        </div>
      </div>

      <div class="row">
        <div class="form-group col-lg-2">
          <label for="Bairro" class="control-label">Bairro:</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="Bairro"
            name="Bairro"
            v-model.number="operadoraSelecionada.bairro"
          />
        </div>
        <div class="form-group col-lg-2">
          <label for="Cidade" class="control-label">Cidade:</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="Cidade"
            name="Cidade"
            v-model="operadoraSelecionada.cidade"
          />
        </div>
        <div class="form-group col-lg-1">
          <label for="UF" class="control-label">UF:</label>
          <select
            id="UF"
            class="form-control"
            :disabled="!liberarCampos"
            selected
            v-model="operadoraSelecionada.uf"
          >
            <option v-for="uf in ufs" :key="uf.opcao" :value="uf.opcao">{{uf.valor}}</option>
          </select>
        </div>
        <div class="form-group col-lg-1">
          <label for="DDD" class="control-label">DDD:</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="DDD"
            name="DDD"
            v-model="operadoraSelecionada.foneDdd"
          />
        </div>
        <div class="form-group col-lg-2">
          <label for="Fone" class="control-label">Fone:</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="Fone"
            name="Fone"
            v-model="operadoraSelecionada.fone"
          />
        </div>
      </div>
      <hr />

      <div class="row">
        <div class="form-group col-lg-5">
          <label for="Site" class="control-label">Site:</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="Site"
            name="Site"
            v-model="operadoraSelecionada.site"
          />
        </div>
        <div class="form-group col-lg-5">
          <label for="Email" class="control-label">E-mail:</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="Email"
            name="Email"
            v-model="operadoraSelecionada.email"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import constantes from "@/utils/constantes";

export default {
  computed: {
    ...mapGetters({
      modo: "operadoras/getModo",
      operadoraSelecionada: "operadoras/getOperadoraSelecionada",
    }),
    liberarCampos() {
      return this.modo == "Incluir" || this.modo == "Alterar" ? true : false;
    }
  },
  data() {
    return {
      sim_nao: constantes.sim_nao,
      ufs: constantes.uf
    }
  },
  methods: {}
}
</script>

<style scoped>
    label {
      font-weight: 100%;
      font-size: 0.8rem;
      margin-top: 3px;
      margin-bottom: 1px;
    }
</style>
