<template>
  <div>
    <div class="cabecalho">
      <div class="coluna1">
        <button @click="retornar" class="btn btn-sm btn-primary mr-2"><i class="fa fa-arrow-left"/></button>
      </div>
      <div class="coluna2">
      </div>
      <div class="coluna3"/>
    </div>      

    <div class="container-fluid">
      <div id="filtrarRegistros" class="row">
        <div class="form-group col-lg-2">
          <label for="tabelaProcedimentos" class="control-label">Tabela</label>
          <select
            class="form-control"
            id="tabelaProcedimentos"
            name="tabelaProcedimentos"
            selected
            v-model="tabelaProcedimento"
          >
            <option
              v-for="tabela in tabelaProcedimentos"
              :key="tabela.id"
              :value="tabela.tabela"
            >{{ tabela.descricao }}</option>
          </select>
        </div>

        <div class="form-group ml-3 col-lg-3">
          <label for="campoBuscar" class="control-label">Selecione o Campo para Busca:</label>
          <br />
          <input type="radio" id="descricao" value="Descricao" v-model="campoBusca" />
          <label for="descricao" class="control-label ml-1 mr-2">Descrição</label>
          <input type="radio" id="codpro" value="CodPro" v-model="campoBusca" />
          <label for="codpro" class="control-label ml-1 mr-2">Código Procedimento</label>
        </div>

        <div class="form-group ml-2 col-lg-2">
          <label for="Filter" class="control-label">Buscar:</label>
          <input
            type="text"
            class="form-control"
            id="filter"
            @input="filtrarProcedimento=$event.target.value"
            placeholder="digite o que deseja localizar..."
          />
        </div>
        <div class="form-group col-sm-1 col-md-1 col-lg-1" style="padding-top:25px">
          <button id="btnFiltrar" class="form-control" @click="buscarProcedimentos">
            <i class="fa fa-search"/> 
          </button>
        </div>
        <!-- <div class="form-group col-sm-1 col-md-1 col-lg-2" style="padding-top:32px"> -->
          <!-- <button -->
            <!-- id="Incluir" -->
            <!-- :disabled="novoRegistro" -->
            <!-- @click="incluirRegistro()" -->
            <!-- class="btn btn-md btn-info mr-2" -->
          <!-- >Incluir Novo Procedimento</button> -->
        <!-- </div> -->
      </div>

      <template v-if="this.erro!=''">
        <p class="msgError">Erro durante Operação: {{ this.erro }}</p>
      </template>

      <div class="col-lg-12" style="display:flex; flex-direction:row; justify-content:center ;height:450px;overflow:auto">
        <b-spinner v-if="loading" label="carregando..."/>
        <b-table v-if="loaded" class="col-lg-12" hover striped small sort-icon-left :items="procedimentos" :fields="camposTabela">
          <template v-slot:cell(actions)="row">
            <b-button variant="warning" size="sm" @click="selecionouProcedimento(row.item)">
              <i class="fa fa-pencil"/>
            </b-button>
          </template>
        </b-table>
      </div>
      <b-pagination v-if="loaded" class="col-lg-12 justify-content-center" size="md" v-model="page" :total-rows="countProcedimento" :per-page="limitProcedimento"/>
    </div>
    <div class="Mensagem">
      <b-modal id="modal-mensagem" centered title="DoctorOn">
        <p class="my-4">{{mensagem}}</p>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import tabelaProcedimentos from "@/mixins/tabelaProcedimentos";
import procedimentos from "@/mixins/procedimentos";
import global from "@/mixins/global";
import errors from "@/utils/errors";

export default {
  mixins: [tabelaProcedimentos, procedimentos, global],
  computed: {
    ...mapGetters({
      usuarioLogadoDados : "global/getUsuarioLogadoDados"
    })
  },
  data() {
    return {
      campoBusca: "Descricao",
      loading: false,
      loaded: false,
      erro: "",
      mensagem : "",
      tabelaProcedimento : "",
      procedimento: {},
      camposTabela: [
        {key : "codigo", label : "Código", sortable: true},
        {key : "descricao", label : "Procedimento", sortable: true},
        {key : "porte", label : "Porte"},
        {key : "co", label : "CO"},
        {key : "auxiliares", label : "Auxil."},
        {key : "porteAnestesico", label : "Porte Anest"},
        {key : "actions", label : "Ações"},
      ],
      page : 1
    }
  },
  watch : {
    page() {
      this.buscarProcedimentos();
    }
  },
  methods: {
    ...mapActions({
      limparRegistroSelecionado: "procedimentos/limparRegistroSelecionado",
      setProcedimentoSelecionado: "procedimentos/setProcedimentoSelecionado",
      setProcedimentos : "procedimentos/setProcedimentos"
    }),
    retornar() {
        this.$router.go(-1);
    },
    selecionouProcedimento(procedimento) {
      this.setProcedimentoSelecionado(procedimento);
      this.$router.push('/conProcedimento');
    },
    incluirRegistro() {
      if (!this.verificarPermissao('Incluir')) {
        return
      }

      this.limparRegistroSelecionado();
      this.$router.push('/conProcedimento');
    },
    async buscarProcedimentos() {
      if (this.filtrarProcedimento.length < 2) {
        this.$toasted.show("Digite pelo menos 2 caracteres para realizar a Busca.", {type : "info", icon : "times"});
        return;
      }

      this.loading = true;
      this.loaded = false;

      if (this.campoBusca == 'Descricao') {
        await this.carregarProcedimentosPelaDescricao(this.tabelaProcedimento, this.filtrarProcedimento, this.page);
      } else {
        await this.carregarProcedimentosPeloCodigo(this.tabelaProcedimento, this.filtrarProcedimento, this.page);
      }
      this.loading = false;
      this.loaded = true;
    },
    verificarPermissao(operacao) {
      if (!errors.verificarPermissao(this.usuarioLogadoDados, this.$store.state.global.grauPermissaoMinimo, operacao)) {
        let descricaoOperacao = operacao == "Incluir" ? "Inclusão" : operacao == "Alterar" ? "Alteração" : "Exclusão";

        this.mensagem = `${this.usuarioLogadoDados.profissional.nome}, você não possui permissão para realizar ${descricaoOperacao} com esse Perfil.`;
        this.$bvModal.show("modal-mensagem");

        return false;
      }

      return true;
    }  
  },
  created() {
    this.showMenu(false);
    this.carregarTabelasProcedimentos(0);
  }
}
</script>

<style scoped>
    label {
      font-weight: 100%;
      font-size: 0.8rem;
      margin-top: 3px;
      margin-bottom: 1px;
    }
</style>
