import errors from "@/utils/errors";

export default {
    data() {
        return {
          count : 0,
          limit : 0,
          despesasCobertas : [],
        }
    },
    methods : {
      async carregarDespesasCobertasPelaOperadora(tenant, operadoraSelecionadaId, pagina) {
        this.loadingDespesasCobertas = true;
        this.loadedDespesasCobertas = false;

        await this.$http
          .get(`/v1.0/despesascobertas/${tenant}/operadora/${operadoraSelecionadaId}/${pagina}`)
          .then(resp => {
            this.despesasCobertas = resp.data.registros;
            this.count = resp.data.count;
            this.limit = resp.data.limit;
            this.loadedDespesasCobertas = true;

            this.erro = "";
          })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          })
          .finally(() => this.loadingDespesasCobertas = false)
      },
      async carregarDespesasCobertas(tenant, operadoraSelecionadaId, campoFiltro, filtro, pagina) {
            this.loadingDespesasCobertas = true;
            this.loadedDespesasCobertas = false;

            await this.$http
              .get(`/v1.0/despesascobertas/${tenant}/operadora/${operadoraSelecionadaId}/${campoFiltro}/${filtro}/${pagina}`)
              .then(resp => {
                this.despesasCobertas = resp.data.registros;
                this.count = resp.data.count;
                this.limit = resp.data.limit;
                this.loadedDespesasCobertas = true;

                this.erro = "";
              })
              .catch(error => {
                this.erro = errors.tratarErros(error);
              })
              .finally(() => this.loadingDespesasCobertas = false)
          },
    }
}
