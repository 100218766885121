import errors from "@/utils/errors";

export default {
    data() {
      return {
        servicos : []
      }
    },
    methods: {
        async carregarServicos(tenant) {
            this.loadingServicos = true;
            this.loadedServicos = false;

            await this.$http.get(`/v1.0/servicos/${tenant}`)
              .then(resp => {
                this.servicos = resp.data;
                this.erro = "";
                this.loadedServicos = true;
              })
              .catch(error => (this.erro = errors.tratarErros(error)))
              .finally(() => {
                this.loadingServicos = false;
              });
          },
    }
}
