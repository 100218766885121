<template>
  <div>
    <template v-if="this.erro!=''">
      <p class="msgError">Erro durante Operação: {{ this.erro }}</p>
    </template>
    <div class="row">
      <div class="form-group col-lg-4">
        <label for="Solicitacao" class="control-label">Solicitações:</label>
        <select
          class="form-control"
          id="Solicitacao"
          name="Solicitacao"
          selected
          v-model="solicitacaoId"
          @change="selecionouSolicitacao()"
        >
          <option
            v-for="solicitacao in solicitacoesParcial"
            :key="solicitacao.id"
            :value="solicitacao.id"
          >{{ solicitacao.datCadastro | formatDate }} - {{ solicitacao.descricao }} - {{solicitacao.profissional}}</option>
        </select>
      </div>
      <div class="form-group col-lg-2" style="margin-top:28px">
        <button :disabled="true || !medicoDoPaciente" id="incluirSolicitacao" class="btn btn-sm btn-info" @click="incluirSolicitacao()">Incluir Nova Solicitação</button>
      </div>
    </div>

    <template v-if="solicitacaoSelecionada.id!=undefined">
      <div class="row">
        <div class="form-group col-lg-12">
            <VueEditor id="solicitacao" v-model="solicitacaoSelecionada.ficha" :disabled="solicitacaoSelecionada.readOnly=='S'" />
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-12 mt-1">
          <template v-if="solicitacaoSelecionada.id!=undefined">
            <button id="alterarSolicitacao"  :disabled="liberarCampos || solicitacaoSelecionada.ficha=='' || solicitacaoSelecionada.readOnly=='S'" class="btn btn-sm btn-info ml-1" @click="alterarSolicitacao()">Alterar</button>
            <button id="cancelarSolicitacao" :disabled="!liberarCampos" class="btn btn-sm btn-secondary ml-1" @click="cancelarSolicitacao()">Cancelar</button>
            <button id="gravarSolicitacao" :disabled="!liberarCampos" class="btn btn-sm btn-primary ml-1" @click="gravarSolicitacao('N')">Gravar</button>
            <button id="gravarFecharSolicitacao" :disabled="!liberarCampos" class="btn btn-sm btn-primary ml-1" @click="fechar()">Gravar e Fechar</button>
            <b-button id="btnImprimir" :disabled="solicitacaoSelecionada.ficha==''" class="ml-1" @click="imprimirSolicitacao()" variant="info" 
                v-b-tooltip.hover title="Imprimir Solicitação" size="sm" style="margin-top:0px;">
                <i class="fa fa-print"/>
            </b-button>
            <button id="excluirSolicitacao" :disabled="liberarCampos || solicitacaoSelecionada.ficha=='' || solicitacaoSelecionada.readOnly=='S'" class="btn btn-sm btn-danger ml-5" @click="excluirSolicitacao()">Excluir</button>
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import {mapGetters} from "vuex"
import {VueEditor} from "vue2-editor"
import solicitacoes from "@/mixins/solicitacoes"
import relatoriosAtendimentos from "@/mixins/relatoriosAtendimentos"
import errors from "@/utils/errors"

export default {
  mixins : [solicitacoes, relatoriosAtendimentos],
  components : {VueEditor},
  computed : {
    ...mapGetters({
      pacienteSelecionado : "pacientes/getPacienteSelecionado",
      usuarioLogadoDados : "global/getUsuarioLogadoDados",
    }),
    liberarCampos() {
      return this.modo == "Incluir" || this.modo == "Alterar" ? true : false;
    },
    medicoDoPaciente() {
      return this.pacienteSelecionado.profissionalAgendaId == this.usuarioLogadoDados.profissionalId ? true : false;
    }
  },
  data() {
    return {
      modo : "",
      erro : "",

      loadingSolicitacoes : false,
      loadedSolicitacoes : false,
      loadingConsultorios : false,
      loadedConsultorios : false,

      solicitacaoId : 0,
      solicitacaoSelecionada : {},
      backupSolicitacao : "",
    }
  },
  methods : {
    selecionouSolicitacao() {
      this.carregarSolicitacao(this.usuarioLogadoDados.tenant, this.solicitacaoId);
    },
    incluirSolicitacao() {
      this.$router.push('/cadSolicitacao');
    },
    alterarSolicitacao() {
      this.modo = "Alterar";
      this.backupSolicitacao = this.solicitacaoSelecionada.ficha;
    },
    cancelarSolicitacao() {
      this.solicitacaoSelecionada.ficha = this.backupSolicitacao;
      this.modo = "";
    },
    excluirSolicitacao() {
        this.$bvModal
        .msgBoxConfirm("Excluir Solicitação?", {
          title: "Atenção",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            this.$http.delete(`v1.0/solicitacoes/${this.usuarioLogadoDados.tenant}/${this.solicitacaoSelecionada.id}/${this.usuarioLogadoDados.profissionalId}`)
            .then(() => {
              this.erro = "";
              this.solicitacaoSelecionada = {}

              let indice = this.solicitacoesParcial.findIndex(s => s.id == this.solicitacaoSelecionada.id);
              this.solicitacoesParcial.splice(indice, 1);
              
              this.$toasted.global.defaultSuccess();
            })
            .catch(error => this.erro = errors.tratarErros(error))
          }
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        });

    },
    fechar() {
      this.$bvModal
      .msgBoxConfirm("Após fechada a Solicição não poderá mais ser Alterada, Continua?", {
        title: "Atenção",
        size: "sm",
        buttonSize: "sm",
        okVariant: "danger",
        okTitle: "Sim",
        cancelTitle: "Não",
        footerClass: "p-2",
        hideHeaderClose: true,
        centered: true
      })
      .then(value => {
        if (value) {
          this.gravarSolicitacao('S');
        }
      })
      .catch(error => {
        this.erro = errors.tratarErros(error);
        return
      });
    },
    gravarSolicitacao(fechar) {
      let DataAtual = new Date();

      this.solicitacaoSelecionada.datAlteracao = DataAtual;
      this.solicitacaoSelecionada.horaAletracao = DataAtual.getHours()+":"+DataAtual.getMinutes();
      this.solicitacaoSelecionada.operadorAlteracaoId = this.usuarioLogadoDados.profissionalId;

      if (fechar=='S') {
        this.solicitacaoSelecionada.readOnly = 'S'
      } else {
        this.solicitacaoSelecionada.readOnly = 'N'
      }

      this.$http.put(`/v1.0/solicitacoes/${this.usuarioLogadoDados.tenant}/${this.solicitacaoSelecionada.id}`, this.solicitacaoSelecionada)
      .then(() => {
        this.erro = "";
        this.modo = "";
        this.$toasted.global.defaultSuccess();
      })
      .catch(error => {
        this.erro = errors.tratarErros(error);
      })
    },
    imprimirSolicitacao() {
      this.carregarRelatorioSolicitacao(this.usuarioLogadoDados.tenant, this.solicitacaoSelecionada.id);
    }
  },
  created() {
    this.carregarSolicitacoesParcial(this.usuarioLogadoDados.tenant, this.pacienteSelecionado.id, this.usuarioLogadoDados.profissionalId);
  }
}
</script>

<style scoped>
  label {
    margin-bottom: 2px;
  }
</style>
