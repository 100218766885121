import errors from "@/utils/errors";

export default {
    data() {
        return {
            contratadoOperadoras : []
        }
    },
    methods: {
        carregarContratadoOperadoras(tenant, contratadoId) {
            this.$http.get(`/v1.0/contratadosOperadoras/${tenant}/Contratado/${contratadoId}`)
            .then(resp => {
                this.contratadoOperadoras = resp.data;
                this.erro = "";
            })
            .catch(error => {
                this.erro = errors.tratarErros(error);
            })
        },
        carregarContratadoOperadora(tenant, tipo, contratadoId, operadoraId) {
            this.$http.get(`/v1.0/contratadosOperadoras/${tenant}/${contratadoId}/${operadoraId}`)
            .then(resp => {
                if (tipo=='S') {
                   this.contratadoOperadoraSolicitante = resp.data;
                } else if (tipo=='E') {
                    this.contratadoOperadoraExecutante = resp.data;
                } else {
                    this.contratadoOperadoraLocal = resp.data;
                }
                this.erro = "";
            })
            .catch(error => {
                this.erro = errors.tratarErros(error);
            })
        }

    }
}
