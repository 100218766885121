import { render, staticRenderFns } from "./frmAgendaAtendimentos.vue?vue&type=template&id=4af801c7&scoped=true&"
import script from "./frmAgendaAtendimentos.vue?vue&type=script&lang=js&"
export * from "./frmAgendaAtendimentos.vue?vue&type=script&lang=js&"
import style0 from "./frmAgendaAtendimentos.vue?vue&type=style&index=0&id=4af801c7&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4af801c7",
  null
  
)

export default component.exports