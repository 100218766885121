import errors from "@/utils/errors";
import {saveAs} from 'file-saver';

export default {
    data() {
        return {
        }
    },
    methods: {
      carregarRelatorioProntuario(tenant, pacienteId, profissionalImpressaoId) {
        this.loadingRelatorio = true;

        this.$http.get(`/v1.0/relatoriosatendimentos/${tenant}/paciente/${pacienteId}/${profissionalImpressaoId}`, {responseType: 'blob'})
          .then(response => {
            saveAs(response.data, 'ProntuarioPaciente.pdf');
            this.erro = "";
          })
          .catch(error => errors.tratarErros(error))
          .finally(() => this.loadingRelatorio = false)
      },
      carregarRelatorioSolicitacao(tenant, solicitacaoId) {
        this.loadingRelatorio = true;

        this.$http.get(`/v1.0/relatoriosatendimentos/${tenant}/solicitacao/${solicitacaoId}`, {responseType: 'blob'})
          .then(response => {
            saveAs(response.data, 'SolicitacaoParaPaciente.pdf');
            this.erro = "";
          })
          .catch(error => errors.tratarErros(error))
          .finally(() => this.loadingRelatorio = false)
      },
      carregarRelatorioSolicitacaoInterna(tenant, pacienteId, profissionalId, data) {
        this.loadingRelatorio = true;

        this.$http.get(`/v1.0/relatoriosatendimentos/${tenant}/solicitacaoInterna/${pacienteId}/${profissionalId}/${data}`, {responseType: 'blob'})
          .then(response => {
            saveAs(response.data, 'SolicitacaoInternaParaPaciente.pdf');
            this.erro = "";
          })
          .catch(error => errors.tratarErros(error))
          .finally(() => this.loadingRelatorio = false)
      },
      carregarFichaDoPaciente01(tenant, pacienteId, profissionalId) {
        this.loadingRelatorio = true;

        this.$http.get(`/v1.0/relatoriosatendimentos/${tenant}/fichapaciente01/${pacienteId}/${profissionalId}`, {responseType: 'blob'})
          .then(response => {
            saveAs(response.data, 'FichaDoPaciente.pdf');
            this.erro = "";
          })
          .catch(error => errors.tratarErros(error))
          .finally(() => this.loadingRelatorio = false)
      },
      carregarLitagemAcompanhamentos01(tenant, profissionalId, datInicial, datFinal) {
        this.loadingRelatorio = true;

        this.$http.get(`/v1.0/relatoriosatendimentos/${tenant}/acompanhamentos01/${profissionalId}/${datInicial}/${datFinal}`, {responseType: 'blob'})
          .then(response => {
            saveAs(response.data, 'ListagemAcompanhamentos.pdf');
            this.erro = "";
          })
          .catch(error => errors.tratarErros(error))
          .finally(() => this.loadingRelatorio = false)
      }
    }
}
