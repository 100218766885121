<template>
  <div id="frmAcompanhamentos">
    <div class="cabecalho">
      <div class="coluna1">
        <button @click="retornar" class="btn btn-sm btn-primary mr-10">
          <i class="fa fa-arrow-left" />
        </button>
      </div>
      <div class="coluna2">
        <span>Acompanhamentos de Pacientes</span>
      </div>
      <div class="coluna3" />
    </div>

    <div class="container-fluid">
      <template v-if="modo=='Alterar'">
        <h4 style="text-align:center">Paciente: {{acompanhamentoSelecionado.paciente}} - Prevista: {{acompanhamentoSelecionado.datPrevista | formatDate}}</h4>

        <div class="row">
          <div class="form-group col-lg-10">
            <label for="resposta" class="control-label">Resposta</label>
            <input type="text" id="resposta" class="form-control" v-model="acompanhamentoSelecionado.resposta"/>
          </div>
          <div v-if="acompanhamentoSelecionado.datVisualizacao==null" class="form-group col-lg-1">
            <b-button style="margin-top:27px" variant="info" size="sm" @click="gravar()">Confirmar</b-button>
          </div>
        </div>
        <hr>
      </template>

      <div class="row">
          <div class="form-group col-lg-3">
              <label for="profissional" class="control-label">Profissional</label>
              <select id="profissional" class="form-control" v-model="profissionalId" selected>
                  <option v-for="profissional in profissionais" :key="profissional.id" :value="profissional.id">
                      {{profissional.nome}}
                  </option>
              </select>
          </div>
          <div class="form-group col-lg-2">
              <label for="datInicial" class="control-label">Data Inicial</label>
              <input id="datInicial" type="date" class="form-control" v-model="datInicial">
          </div>
          <div class="form-group col-lg-2">
              <label for="datFinal" class="control-label">Data Final</label>
              <input id="datFinal" type="date" class="form-control" v-model="datFinal">
          </div>
          <div class="form-group col-lg-1">
              <b-check id="contatados" class="mt-4" v-model="contatados">Contatados</b-check>
          </div>
          <div class="form-group col-lg-1">
            <button id="btnFiltrar" style="margin-top:25px" class="form-control" @click="buscarPeloProfissional()">
              <i class="fa fa-search"/> 
            </button>
          </div>
          <div class="form-group col-lg-1">
            <b-button id="btnImprimir" class="mr-1" @click="imprimirAcompanhamentos()" variant="info" 
                v-b-tooltip.hover title="Imprimir Acompanhamentos" size="sm" style="margin-top:27px;">
                <i class="fa fa-print"/>
            </b-button>
          </div>
      </div>

      <template v-if="this.erro!=''">
        <p class="msgError">Erro durante Operação: {{ this.erro }}</p>
      </template>

      <div style="display:flex; flex-direction:row; justify-content:center;height:370px">
        <b-spinner v-if="loadingAcompanhamentos" label="carregando..."/>
        <b-table v-if="loadedAcompanhamentos" class="col-lg-12" hover striped small sort-icon-left :items="acompanhamentos" :fields="camposTabela" @row-clicked="selecionouRegistro">
          <template v-slot:cell(datPrevista)="datPrevista">
            {{ datPrevista.value | formatDate }}
          </template>
          <template v-slot:cell(datContato)="datContato">
            {{ datContato.value | formatDate }}
          </template>
          <template v-slot:cell(datVisualizacao)="datVisualizacao">
            {{ datVisualizacao.value | formatDate }}
          </template>
        </b-table>
      </div>

      <b-pagination v-if="loadedAcompanhamentos" class="col-lg-12 justify-content-center" size="md" v-model="page" :total-rows="count" :per-page="limit"/>
    </div>

    <div class="Mensagem">
      <b-modal id="modal-mensagem" centered title="DoctorOn">
        <p class="my-4">{{mensagem}}</p>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import global from "@/mixins/global";
import errors from "@/utils/errors";
import funcoes from "@/utils/funcoes";
import profissionais from "@/mixins/profissionais";
import acompanhamentos from "@/mixins/acompanhamentos";
import relatoriosAtendimentos from "@/mixins/relatoriosAtendimentos";

export default {
  mixins : [acompanhamentos, profissionais, relatoriosAtendimentos, global],
  computed: {
    ...mapGetters({
      usuarioLogadoDados: "global/getUsuarioLogadoDados",
    }),
  },
  methods: {
    retornar() {
      this.$router.go(-1);
    },
    imprimirAcompanhamentos() {
        if (this.profissionalId == 0 || this.datInicial == null || this.datFinal == null) {
          this.mensagem = `Preencha os campos Profissional, Data Inicial e Data Final.`;
          this.$bvModal.show("modal-mensagem");        

          return
        }

        this.carregarLitagemAcompanhamentos01(this.usuarioLogadoDados.tenant, this.profissionalId, this.datInicial, this.datFinal);
    },
    buscarPeloProfissional() {
      let contatado = this.contatados ? "S" : "N";
      this.carregarPeloProfissionalContatados(this.usuarioLogadoDados.tenant, this.profissionalId, this.datInicial, this.datFinal, contatado, this.page);
    },
    selecionouRegistro(acompanhamentoSelecionado) {
      this.modo = 'Alterar';
      this.acompanhamentoSelecionado = {...acompanhamentoSelecionado};
      this.respostaInicial = this.acompanhamentoSelecionado.resposta;
    },
    async gravar() {
      if (this.respostaInicial == this.acompanhamentoSelecionado.resposta) {
        this.modo = "";
        return;
      }

      this.acompanhamentoSelecionado.operadorId = this.usuarioLogadoDados.profissionalId;

      if (this.acompanhamentoSelecionado.datContato == null) {
        this.acompanhamentoSelecionado.datContato = funcoes.mySqlToJSDateFormat(Date());
      }

      this.$http.put(`/v1.0/acompanhamentos/${this.usuarioLogadoDados.tenant}/${this.acompanhamentoSelecionado.id}`, this.acompanhamentoSelecionado)
      .then(() => {
        this.erro = "";
        this.$toasted.global.defaultSuccess();
        this.modo = "";

        let indice = this.acompanhamentos.findIndex(p => p.id == this.acompanhamentoSelecionado.id);
        this.acompanhamentos[indice].resposta = this.acompanhamentoSelecionado.resposta;
        this.acompanhamentos[indice].datContato = this.acompanhamentoSelecionado.datContato;
      })
      .catch(error => {
        this.erro = errors.tratarErros(error);
      })
    }
  },
  watch : {
    page() {
      this.buscarPeloProfissional();
    }
  },
  data() {
      return {
          loadingAcompanhamentos : false,
          loadedAcompanhamentos : false,

          page : 1,
          count : 0,
          limit : 0,
          erro : "",
          modo : "",
          profissionalId : 0,
          contatados : false,
          datInicial : null,
          datFinal : null,
          acompanhamentoSelecionado : null,
          mensagem : "",
          respostaInicial : "",
          camposTabela : [
            {key : "paciente", label : "Paciente", sortable : true},
            {key : "pergunta", label : "Pergunta"},
            {key : "celularDDD", label : "DDD"},
            {key : "celular", label : "Celular"},
            {key : "foneDDD", label : "DDD"},
            {key : "fone", label : "Fone"},
            {key : "datPrevista", label : "Prevista", sortable : true},
            {key : "datContato", label : "Contato", sortable : true},
            {key : "datVisualizacao", label : "Visualizada", sortable : true},
          ]
      }
  },
  created() {
    this.showMenu(false);
    this.carregarProfissionaisComAgendaPropria(this.usuarioLogadoDados.tenant, 0);
    this.datInicial = funcoes.mySqlToJSDateFormat(Date());
    this.datFinal = funcoes.mySqlToJSDateFormat(Date());
  },
};
</script>

<style scoped>
    label {
      font-weight: 100%;
      font-size: 0.8rem;
      margin-top: 3px;
      margin-bottom: 1px;
    }
</style>
