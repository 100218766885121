<template>
    <div>
      <div class="row">
        <div class="form-group col-lg-4">
          <label for="Nome" class="control-label">Nome:</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="Nome"
            name="Nome"
            v-model="pacienteSelecionado.nome"
          />
        </div>

        <div class="form-group col-lg-4">
          <label
            for="NomeCertidao"
            class="control-label"
          >Nome na Certidão (Apenas qdo houver troca):</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="NomeCertidao"
            name="NomeCertidao"
            v-model="pacienteSelecionado.nomeCertidao"
          />
        </div>

        <div class="form-group col-lg-2">
          <label for="datNascimento" class="control-label">Data Nascimento:</label>
          <input
            type="date"
            class="form-control"
            :disabled="!liberarCampos"
            id="datNascimento"
            name="datNascimento"
            v-model="pacienteSelecionado.datNascimento"
          />
        </div>

        <div class="form-group col-lg-1">
          <label for="Sexo" class="control-label">Sexo:</label>
          <select
            class="form-control"
            :disabled="!liberarCampos"
            id="Sexo"
            name="Sexo"
            selected
            v-model="pacienteSelecionado.sexo"
          >
            <option
              v-for="confirma in sexo"
              :key="confirma.opcao"
              :value="confirma.opcao"
            >{{ confirma.valor }}</option>
          </select>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-lg-1">
          <label for="EstadoCivil" class="control-label">Estado Civil:</label>
          <select
            class="form-control"
            :disabled="!liberarCampos"
            id="EstadoCivil"
            name="EstadoCivil"
            selected
            v-model="pacienteSelecionado.estadoCivil"
          >
            <option
              v-for="confirma in estadoCivil"
              :key="confirma.opcao"
              :value="confirma.opcao"
            >{{ confirma.valor }}</option>
          </select>
        </div>
        <div class="form-group col-lg-2">
          <label
            for="Naturalidade"
            class="control-label"
          >Naturalidade:</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="Naturalidade"
            name="Naturalidade"
            v-model="pacienteSelecionado.naturalidade"
          />
        </div>
        <div class="form-group col-lg-3">
          <label for="Profissao" class="control-label">Profissao:</label>
          <select
            class="form-control"
            :disabled="!liberarCampos"
            id="Profissao"
            name="Profissao"
            selected
            v-model="pacienteSelecionado.profissaoId"
          >
            <option
              v-for="profissao in profissoes"
              :key="profissao.id"
              :value="profissao.id"
            >{{ profissao.descricao }}</option>
          </select>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-lg-3">
          <label for="indicacao" class="control-label">Indicação:</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="indicacao"
            name="indicacao"
            v-model="pacienteSelecionado.indicacao"
          />
        </div>
        <div class="form-group col-lg-3">
          <label for="resultadoExame" class="control-label">Resultado Exames:</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="resultadoExame"
            name="resultadoExame"
            v-model="pacienteSelecionado.resultadoExame"
          />
        </div>
        <div class="form-group col-lg-3">
          <label for="observacao" class="control-label">Observação:</label>
          <input
            type="text"
            class="form-control"
            disabled
            id="observacao"
            name="observacao"
            v-model="pacienteSelecionado.observacao"
          />
        </div>
        <div class="form-group col-lg-3">
          <label for="observacaoMedico" class="control-label">Observação Médico:</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="observacaoMedico"
            name="observacaoMedico"
            v-model="pacienteSelecionado.observacaoMedico"
          />
        </div>
      </div>

      <div class="row">
        <div class="form-group col-lg-6">
          <label for="alergia" class="control-label">Alergias:</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="alergia"
            name="alergia"
            v-model="pacienteSelecionado.alergia"
          />
        </div>
        <div class="form-group col-lg-1">
          <label for="gestante" class="control-label">Gestante:</label>
          <select
            class="form-control"
            :disabled="!liberarCampos"
            id="gestante"
            name="gestante"
            selected
            v-model="pacienteSelecionado.gestante"
          >
            <option
              v-for="confirma in sim_nao"
              :key="confirma.opcao"
              :value="confirma.opcao"
            >{{ confirma.valor }}</option>
          </select>
        </div>
      </div>
      <hr style="margin:0px"/>
      <div class="row">
        <div class="form-group col-lg-6">
          <label for="vacinaReacao" class="control-label">Vacina Reação:</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="vacinaReacao"
            name="vacinaReacao"
            v-model="pacienteSelecionado.vacinaReacao"
          />
        </div>
        <div class="form-group col-lg-6">
          <label for="vacinaObservacao" class="control-label">Vacina Observações:</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="vacinaObservacao"
            name="vacinaObservacao"
            v-model="pacienteSelecionado.vacinaObservacao"
          />
        </div>
      </div>
      <div class="row">
        <div class="form-group col-lg-12">
          <label for="medicacaoDiaria" class="control-label">Medicação Diária:</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="medicacaoDiaria"
            name="medicacaoDiaria"
            v-model="pacienteSelecionado.medicacaoDiaria"
          />
        </div>
      </div>

      <hr style="margin:0px"/>

      <p v-if="erro" style="background-color:red; color:white; text-align:center">{{erro}}</p>

      <div class="container-fluid">
        <div class="barraopcoescadastro">
          <button
            id="Alterar"
            :disabled="true || (!liberarCampos && !medicoDoPaciente)"
            @click="alterarRegistro()"
            class="btn btn-sm btn-secondary mr-2"
          >Alterar</button>
          <button
            id="Cancelar"
            :disabled="!liberarCampos && !medicoDoPaciente"
            @click="cancelarOperacao()"
            class="btn btn-sm btn-secondary mr-2"
          >Cancelar</button>
          <button
            id="Gravar"
            :disabled="!liberarCampos && !medicoDoPaciente"
            @click="gravarRegistro()"
            class="btn btn-sm btn-secondary mr-2"
          >Gravar</button>
        </div>
      </div>
    </div>    
</template>

<script>
import { mapGetters } from "vuex";
import constantes from "@/utils/constantes";
import profissoes from "@/mixins/profissoes";
import funcoes from "@/utils/funcoes";
import errors from "@/utils/errors";

export default {
  mixins : [profissoes],
  computed : {
    ...mapGetters({
      pacienteSelecionado: "pacientes/getPacienteSelecionado",
      usuarioLogadoDados : "global/getUsuarioLogadoDados"
    }),
    liberarCampos() {
      return this.modo == "Alterar";
    },
    medicoDoPaciente() {
      return this.pacienteSelecionado.profissionalAgendaId == this.usuarioLogadoDados.profissionalId ? true : false;
    }
  },
  data() {
    return {
      modo : "",
      loading : true,
      erro : "",
      sim_nao: constantes.sim_nao,
      sexo: constantes.sexo,
      estadoCivil: constantes.estadoCivil,
    }
  },
  methods : {
    gravarRegistro() {
      if (!this.verificarCamposPreenchidos()) {
        return;
      }

      this.pacienteSelecionado.datAlteracao = new Date();
      this.pacienteSelecionado.operadorAlteracaoId = this.usuarioLogadoDados.profissionalId;

      this.$http
        .put(
          `/v1.0/pacientes/${this.usuarioLogadoDados.tenant}/${this.pacienteSelecionado.id}`,
          this.pacienteSelecionado
        )
        .then(() => {
          this.erro = "";
          this.modo = "";
         this.$toasted.global.defaultSuccess();
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        })
        .finally(() => {});
    },
    alterarRegistro() {
      this.modo = "Alterar";
    },
    cancelarOperacao() {
      this.modo = "";
      this.erro = "";
    },
    limparCampos() {
      this.limparRegistroSelecionado();
    },
    verificarCamposPreenchidos() {
      if (this.pacienteSelecionado.nome == "") {
        this.$toasted.show("Favor preencher o Nome do(a) Paciente.", {type : "info", icon : "times"});
        return false;
      }

      return true;
    }
  },
  created() {
    this.carregarProfissoes(this.usuarioLogadoDados.tenant, 0);
    this.pacienteSelecionado.datNascimento = funcoes.mySqlToJSDateFormat(this.pacienteSelecionado.datNascimento);
  }
}
</script>

<style scoped>
  label {
    font-weight: 100%;
    font-size: 0.8rem;
    margin-top: 3px;
    margin-bottom: 1px;
  }
</style>
