<template>
    <aside class="menu" v-show="isMenuVisible">
       <tree :data="treeData" ref="tree"/>
    </aside>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    name : 'Menu',
    props : ['opcoes'],
    data() {
        return {
            firstUpdate : true,
            treeData : [],
            menu : [],
            firstBeforeUpdate : true
        }
    },
    computed: {
        ...mapGetters({
            isMenuVisible : "global/getMenu",
            usuarioLogadoDados : "global/getUsuarioLogadoDados",
            menuOptions : "global/getMenuOptions"
        }),
    },
    methods : {
        onNodeSelected(node) {
            if (node.data.rota !== undefined) {
                this.$router.push(node.data.rota);
            }
        },
    },
    created() {
        //this.treeData = [...this.menuOptions];
    },
    mounted() {
        this.$refs.tree.$on('node:selected', this.onNodeSelected);

        //let opcoes = [...this.menuOptions];
        //opcoes.forEach(element => {
        //    this.$refs.tree.append(element)
        //})
    },
    beforeUpdate() {
        if (this.firstBeforeUpdate && this.menuOptions.length > 0) {
        
            this.firstBeforeUpdate = false;
            let opcoes = [...this.menuOptions];
            opcoes.forEach(element => {
                this.$refs.tree.append(element)
            })
        }
    },
}
</script>

<style scoped>
    .menu {
        grid-area: menu;
        /* background: linear-gradient(to right, #232526, #414345); */
        display : flex;
        flex-direction: column;
        flex-wrap: wrap;
    }
    .menu a,
    .menu a:hover {
        color: #fff;
        text-decoration: none;
    }
    .tree {
        color: #fff;
        text-decoration: none;
    }
    .menu .tree-node.selected > .tree-content,
    .menu .tree-node .tree-content:hover {
        background-color: rgba(255, 255, 255, 0.2);
    }
</style>