<template>
  <div id="CadDespesaFaturamento">
    <br />
    <div class="container-fluid">
      <div class="row">
        <div class="form-group col-lg-2">
          <label for="CustoContabil" class="control-label">Custo Contábil:</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="CustoContabil"
            name="CustoContabil"
            v-model="despesaSelecionada.custoContabil"
          />
        </div>

        <div class="form-group col-lg-2">
          <label for="CustoReposicao" class="control-label">Custo Reposição:</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="CustoReposicao"
            name="CustoReposicao"
            v-model="despesaSelecionada.custoReposicao"
          />
        </div>

        <div class="form-group col-lg-2">
          <label for="CustoConsumo" class="control-label">Custo Consumo:</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="CustoConsumo"
            name="CustoConsumo"
            v-model="despesaSelecionada.custoConsumo"
          />
        </div>

        <div class="form-group col-lg-2">
          <label for="FatorLucratividade" class="control-label">Fator Lucratividade:</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="FatorLucratividade"
            name="FatorLucratividade"
            v-model="despesaSelecionada.fatorLucratividade"
          />
        </div>

        <div class="form-group col-lg-2">
          <label for="PrecoParticular" class="control-label">Preço Particular:</label>
          <input
            type="text"
            class="form-control"
            disabled
            id="PrecoParticular"
            name="PrecoParticular"
            v-model="despesaSelecionada.precoParticular"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      modo: state => state.despesas.modo,
      despesaSelecionada: state => state.despesas.despesaSelecionada,
    }),
    liberarCampos() {
      return this.modo == "Incluir" || this.modo == "Alterar" ? true : false;
    },
  }
}
</script>

<style scoped>
</style>