<template>
  <div id="app" :class="{'hideMenu' : !isMenuVisible}">
      <!-- <Header title="brDoctor - Sistema de Gestão de Clínicas Médicas" :isToggleVisible="isToggleVisible" :isUserDropDownVisible="isUserDropDownVisible"/> -->
      <Header title="DoctorOn - Sistema de Gestão de Clínicas Médicas"/>
      <Menu :key="renderMenuNumber"/>
      <Content/>
      <Footer/>
  </div>
</template>

<script>
import Header from "@/components/template/Header.vue"
import Menu from "@/components/template/Menu.vue"
import Content from "@/components/template/Content.vue"
import Footer from "@/components/template/Footer.vue"

import {mapGetters} from "vuex"

export default {
  name: 'app',
  components: {Header, Menu, Content, Footer},
  computed: {
    ...mapGetters({
      isMenuVisible : "global/getMenu",
      isToggleVisible : "global/getToggleMenu",
      isUserDropDownVisible : "global/getUserDropDown",
      renderMenuNumber : "global/getRenderMenuNumber",
    })
  },
  data() {
    return {
    }
  },
  created() {
  },
}
</script>

<style>
body {
  margin: 0;
}

#app {
  font-family: 'Lato', 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  height: 100vh;
  display: grid;

  grid-template-rows: 60px 1fr 40px;
  grid-template-columns: 300px 1fr;
  grid-template-areas: 
                "header header"
                "menu content"
                "menu footer";
}

#app.hideMenu {
  grid-template-areas: 
                "header header"
                "content content"
                "footer footer";
}

.cabecalho {
  height: 40px;
  margin : 0px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  background-color: #DDD;
  color: #333;
  
  padding : 0px 15px;
  margin-bottom: 20px;
}

.cabecalho .coluna1 {
  align-items: center;
  padding: 0px;
  margin-left: 3px;
  margin-right: 0px;
}

.cabecalho .coluna2 {
  align-items: center;
  text-align: center;
  font-weight: 100;
  font-size: 1.5rem;
  padding: 0px;
  margin-left: 0px;
  margin-right: 0px;
}

.cabecalho .coluna3 {
  align-items: center;
  text-align: center;
  font-weight: 100;
  font-size: 1.5rem;
  padding: 0px;
  margin-left: 0px;
  margin-right: 3px;
}

.cabecalho .login input{
  border-radius: 5px;
}

.corpo {
  background-color: white;
}

.tituloPagina {
  margin-top: 5px;
  font-weight: 100;
  font-size: 1.5rem;
  text-align: center;
  background-color: #efefef;
}

.tituloPagina > hr {
  margin-top:0px;
}

.barraopcoes  {
  margin-top : 10px;
  margin-bottom: 10px;

  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-around;
}

.barraopcoescadastro {
  margin-top : 5px;
  margin-bottom: 10px;
  padding-bottom: 5px;
}

.btn:hover{
  cursor: pointer;
}

.msgError {
  background-color: red;
  color: white;
  text-align: center;
}

input[radio] {
  cursor : pointer
}

select {
  cursor : pointer
}

.registro {
  background-color : #DDD;
  border-radius:5px;
  padding-left: 10px;
}

.registroTransparente {
  border-radius:5px;
  padding-left: 10px;
}

hr {
  margin-top : 3px;
  margin-bottom : 3px;
}

</style>
