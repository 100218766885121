import errors from "@/utils/errors"

export default {
    data() {
        return {
            paciente : {},
        }
    },
    methods : {
      async carregarPacienteRegistrarAcesso(tenant, pacienteId, operadorId) {
        this.loading = true;
        this.loaded = false;    

        await this.$http.get(`/v1.0/pacientes/${tenant}/registrarAcesso/${pacienteId}/${operadorId}`)
          .then(resp => {
              this.paciente = resp.data;
              this.erro = "";

              this.loaded = true;
          })
          .catch(error => this.erro = errors.tratarErros(error))
          .finally(() => this.loading = false)
      },
      async carregarPacientePeloId(tenant, pacienteId, paraLista) {
        this.loading = true;
        this.loaded = false;    

        await this.$http.get(`/v1.0/pacientes/${tenant}/${pacienteId}`)
          .then(resp => {
              if (paraLista) {
                this.pacientes = [];
                this.pacientes.push(resp.data);
              } else {
                this.paciente = resp.data;
              }

              this.erro = "";

              this.loaded = true;
          })
          .catch(error => this.erro = errors.tratarErros(error))
          .finally(() => this.loading = false)
      },
      async carregarPacientesPeloNome(tenant, nome, page) {
          if (nome.length < 4) {
            this.$toasted.show("Favor informar pelo menos 4 caracteres do nome para realizar a busca.");
            return;
          }

          this.loading = true;
          this.loaded = false;    

          await this.$http.get(`/v1.0/pacientes/${tenant}/nome/${nome}/${page}`)
            .then(resp => {
              this.pacientes = resp.data.registros;
              this.limit = resp.data.limit;
              this.count = resp.data.count;

              this.erro = "";
              this.loaded = true;
            })
            .catch(error => this.erro = errors.tratarErros(error))
            .finally(() => this.loading = false)
        },
        async carregarPacientesPeloSobreNome(tenant, sobrenome, page) {
          if (sobrenome.length < 4) {
            this.$toasted.show("Favor informar pelo menos 4 caracteres do sobrenome para realizar a busca.");
            return;
          }

          this.loading = true;
          this.loaded = false;    

          await this.$http.get(`/v1.0/pacientes/${tenant}/sobrenome/${sobrenome}/${page}`)
            .then(resp => {
              this.pacientes = resp.data.registros;
              this.limit = resp.data.limit;
              this.count = resp.data.count;

              this.erro = "";
              this.loaded = true;
            })
            .catch(error => this.erro = errors.tratarErros(error))
            .finally(() => this.loading = false)
        },
        async carregarPacientesPeloCpf(tenant, cpfnumero, page) {
          if (cpfnumero.length < 5) {
            this.$toasted.show("Favor informar pelo menos 5 caracteres do cpfnumero para realizar a busca.");
            return;
          }
          
          this.loading = true;
          this.loaded = false;    

          await this.$http.get(`/v1.0/pacientes/${tenant}/cpfnumero/${cpfnumero}/${page}`)
            .then(resp => {
              this.pacientes = resp.data.registros;
              this.limit = resp.data.limit;
              this.count = resp.data.count;

              this.erro = "";
              this.loaded = true;
            })
            .catch(error => this.erro = errors.tratarErros(error))
            .finally(() => this.loading = false)
        },
        async carregarPacientesPelaDataNascimento(tenant, datinicial, datfinal, page) {
          if (datinicial == null) {
              this.$toasted.show("Favor informar a data para realizar a busca.");
              return;
          }

          this.loading = true;
          this.loaded = false;    

          datfinal = datfinal ?? datinicial;

          await this.$http.get(`/v1.0/pacientes/${tenant}/periodo/${datinicial}/${datfinal}/${page}`)
            .then(resp => {
              this.pacientes = resp.data.registros;
              this.limit = resp.data.limit;
              this.count = resp.data.count;

              this.erro = "";
              this.loaded = true;
            })
            .catch(error => this.erro = errors.tratarErros(error))
            .finally(() => this.loading = false)
        },
    }
}
