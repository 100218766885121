import Vue from 'vue'
import Vuex from 'vuex'
import global from '@/store/modules/global'
import configuracao from '@/store/modules/configuracao'
//import usuariologado from '@/store/modules/usuariologado'
import tabelaProcedimentos from '@/store/modules/tabelaProcedimentos'
import procedimentos from '@/store/modules/procedimentos'
import despesaTipos from '@/store/modules/despesaTipos'
import despesas from '@/store/modules/despesas'
import despesasSimpro from '@/store/modules/despesasSimpro'
import despesasBrasindice from '@/store/modules/despesasBrasindice'
import operadoras from '@/store/modules/operadoras'
import planosSaude from '@/store/modules/planosSaude'
import despesasCobertas from '@/store/modules/despesasCobertas'
import procedimentosCobertos from '@/store/modules/procedimentosCobertos'
import kits from '@/store/modules/kits'
import kitItens from '@/store/modules/kitItens'
import pacientes from '@/store/modules/pacientes'
import configs from '@/store/modules/configs'
import unidades from '@/store/modules/unidades'
import profissionais from '@/store/modules/profissionais'
import profissionalComplementos from '@/store/modules/profissionalComplementos'
import perfis from '@/store/modules/perfis'
import agendaContatos from '@/store/modules/agendaContatos'
import agendaAtendimentos from "@/store/modules/agendaAtendimentos"
import guias from "@/store/modules/guias"
import guiaTratamentos from "@/store/modules/guiaTratamentos"
import guiaDespesas from '@/store/modules/guiaDespesas'
import faturas from "@/store/modules/faturas"
import lotes from "@/store/modules/lotes"
import contratados from "@/store/modules/contratados"
import faturamentos from "@/store/modules/faturamentos"

import consultorios from "@/store/modules/consultorios"
import consultorioItens from "@/store/modules/consultorioItens"
import cIModelos from "@/store/modules/cIModelos"

import tabelaSimpro from "@/store/modules/tabelaSimpro"
import tabelaBrasindice from "@/store/modules/tabelaBrasindice"

import fluxosCaixa from "@/store/modules/fluxosCaixa"
import contas from "@/store/modules/contas"
import centros from "@/store/modules/centros"
import lancamentos from "@/store/modules/lancamentos"
import recebimentos from "@/store/modules/recebimentos"

import usuarios from "@/store/modules/usuarios"

import relatorios from "@/store/modules/relatorios"
import acompanhamentos from "@/store/modules/acompanhamentos"
import mensagens from "@/store/modules/mensagens"

Vue.use(Vuex)

export default new Vuex.Store({
    modules : {
        global,
        configuracao,
        tabelaProcedimentos,
        procedimentos,
        despesaTipos,
        despesas,
        operadoras,
        planosSaude,
        despesasCobertas,
        despesasSimpro,
        despesasBrasindice,
        procedimentosCobertos,
        kits,
        kitItens,
        pacientes,
        configs,
        unidades,
        profissionais,
        profissionalComplementos,
        perfis,
        agendaContatos,
        agendaAtendimentos,
        guias,
        guiaTratamentos,
        guiaDespesas,
        faturas,
        lotes,
        contratados,
        faturamentos,
        consultorios,
        consultorioItens,
        cIModelos,
        tabelaSimpro,
        tabelaBrasindice,
        fluxosCaixa,
        contas,
        centros,
        lancamentos,
        recebimentos,
        usuarios,
        relatorios,
        acompanhamentos,
        mensagens
    }
})
