<template>
  <div class="formulario">
    <div class="cabecalho">
      <div class="coluna1">
        <button @click="retornar" class="btn btn-sm btn-primary mr-10"><i class="fa fa-arrow-left"/></button>
      </div>
      <div class="coluna2">
        <span>{{ operadoraSelecionada.fantasia }} - Cadastro de Taxa</span>
      </div>
      <div class="coluna3"/>
    </div>  

    <div class="container-fluid">
      <div class="registro">
        <div class="row">
          <div class="form-group col-lg-1">
            <label for="Id" class="control-label">Id</label>
            <input
              type="text"
              class="form-control"
              disabled
              id="Id"
              name="Id"
              :value="despesaCobertaSelecionada.id"
            />
          </div>

          <div class="form-group col-lg-4">
            <label for="Despesa" class="control-label">Despesa</label>
            <input
              type="text"
              class="form-control"
              :disabled="!liberarCampos"
              id="Despesa"
              name="Despesa"
              v-model="despesaCobertaSelecionada.descricao"
            />
          </div>
          <div class="form-group col-lg-1">
            <label for="Valor" class="control-label">Valor R$</label>
            <input
              type="number"
              class="form-control"
              :disabled="!liberarCampos"
              id="Valor"
              name="Valor"
              v-model.number="despesaCobertaSelecionada.valor"
            />
          </div>
        </div>

        <div class="row">
          <div class="form-group col-lg-1"></div>

          <div class="form-group col-lg-3">
            <label for="UnidadeConsumo" class="control-label">Unidade Consumo (Estoque):</label>
            <select
              id="UnidadeConsumo"
              :disabled="!liberarCampos"
              class="form-control"
              selected
              v-model="despesaCobertaSelecionada.unidadeConsumo"
            >
              <option
                v-for="unidademedida in unidadesmedidas"
                :key="unidademedida.id"
                :value="unidademedida.termo"
              >{{unidademedida.descricao}} ({{unidademedida.termo}})</option>
            </select>
          </div>

          <div class="form-group col-lg-3">
            <label for="UnidadeTuss" class="control-label">Unidade Faturamento (TUSS):</label>
            <select
              id="UnidadeTuss"
              :disabled="!liberarCampos"
              class="form-control"
              selected
              v-model="despesaCobertaSelecionada.unidadeTuss"
            >
              <option
                v-for="unidademedida in unidadesmedidas"
                :key="unidademedida.id"
                :value="unidademedida.termo"
              >{{unidademedida.descricao}} ({{unidademedida.termo}})</option>
            </select>
          </div>

          <div class="form-group col-lg-2">
            <label for="Codigo" class="control-label">Código</label>
            <input
              type="text"
              class="form-control"
              :disabled="!liberarCampos"
              id="Codigo"
              name="Codigo"
              v-model="despesaCobertaSelecionada.codigo"
            />
          </div>

          <div class="form-group col-lg-2">
            <label for="CodTuss" class="control-label">Cód. TUSS</label>
            <input
              type="text"
              class="form-control"
              :disabled="!liberarCampos"
              id="CodTuss"
              name="CodTuss"
              v-model="despesaCobertaSelecionada.codigoTuss"
            />
          </div>
        </div>
        <hr/>
        <div class="barraopcoescadastro">
          <button
            id="Alterar"
            :disabled="modo=='Alterar'"
            @click="alterarRegistro()"
            class="btn btn-sm btn-secondary mr-2"
          >Alterar</button>
          <button
            id="Cancelar"
            @click="cancelarOperacao()"
            class="btn btn-sm btn-secondary mr-2"
          >Cancelar</button>
          <button
            id="Gravar"
            :disabled="!liberarCampos"
            @click="gravarRegistro()"
            class="btn btn-sm btn-secondary"
          >Gravar</button>
        </div>
      </div>
      <template v-if="this.erro!=''">
        <p class="msgError">Erro durante Operação: {{ this.erro }}</p>
      </template>
    </div>
    <div class="Mensagem">
      <b-modal id="modal-mensagem" centered title="DoctorOn">
        <p class="my-4">{{mensagem}}</p>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import unidadesmedidas from "@/mixins/unidadesMedidas";
import constantes from "@/utils/constantes";
import errors from "@/utils/errors";

export default {
  mixins : [unidadesmedidas],
  computed: {
    ...mapGetters({
      operadoraSelecionada: "operadoras/getOperadoraSelecionada",
      modo: "despesasCobertas/getModo",
      despesaCobertaSelecionada: "despesasCobertas/getDespesaCobertaSelecionada",
      usuarioLogadoDados : "global/getUsuarioLogadoDados"
    }),
    liberarCampos() {
      return this.modo == "Incluir" || this.modo == "Alterar" ? true : false;
    }
  },
  data() {
    return {
      erro: "",
      sim_nao: constantes.sim_nao,
      retornando: false,
      mensagem : "",
    }
  },
  methods: {
    ...mapActions({
      setModo: "despesasCobertas/setModo",
      limparRegistroSelecionado:
        "despesasCobertas/limparRegistroSelecionado",
      formLeave: "despesasCobertas/formLeave"
    }),
    retornar() {
      this.retornando = true;
      this.$router.go(-1);
    },
    gravarRegistro() {
      if (!this.verificarCamposPreenchidos()) {
        return false;
      }

      if (this.modo == "Alterar") {
        this.despesaCobertaSelecionada.operadorAlteracaoId = this.usuarioLogadoDados.profissionalId;

        this.$http
          .put(
            `/v1.0/despesascobertas/${this.usuarioLogadoDados.tenant}/${this.despesaCobertaSelecionada.id}`,
            this.despesaCobertaSelecionada
          )
          .then(() => {
            this.erro = "";
            this.setModo("Registro");
            this.$toasted.global.defaultSuccess();
            this.retornar();
          })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          })
          .finally(() => {});
      }
    },
    alterarRegistro() {
      if (!this.verificarPermissao('Alterar')) {
        return
      }

      this.setModo("Alterar");
    },
    cancelarOperacao() {
      this.limparCampos();
      this.retornar();
    },
    limparCampos() {
      this.limparRegistroSelecionado();
    },
    verificarCamposPreenchidos() {
      if (this.despesaCobertaSelecionada.descricao == "" || this.despesaCobertaSelecionada.descricao == undefined) {
        this.$toasted.show("Digite a Despesa");
        return false;
      }

      if (this.despesaCobertaSelecionada.valor == 0 || this.despesaCobertaSelecionada.valor == undefined) {
        this.$toasted.show("Digite o Valor");
        return false;
      }

      if (this.despesaCobertaSelecionada.unidadeTuss == "" || this.despesaCobertaSelecionada.unidadeTuss == undefined) {
        this.$toasted.show("Digite a Unidade");
        return false;
      }

      return true;
    },
    verificarPermissao(operacao) {
      if (!errors.verificarPermissao(this.usuarioLogadoDados, this.$store.state.global.grauPermissaoMinimo, operacao)) {
        let descricaoOperacao = operacao == "Incluir" ? "Inclusão" : operacao == "Alterar" ? "Alteração" : "Exclusão";

        this.mensagem = `${this.usuarioLogadoDados.profissional.nome}, você não possui permissão para realizar ${descricaoOperacao} com esse Perfil.`;
        this.$bvModal.show("modal-mensagem");

        return false;
      }

      return true;
    }
  },
  created() {
    this.carregarUnidadesMedidas(this.usuarioLogadoDados.tenant);
  },
  beforeRouteLeave(to, from, next) {
    if (this.retornando) {
      this.formLeave();
    }

    next(true);
  }
}
</script>

<style scoped>
</style>
