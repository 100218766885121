<template>
  <div>
    <div class="cabecalho">
      <div class="coluna1">
        <button @click="retornar" class="btn btn-sm btn-primary mr-10"><i class="fa fa-arrow-left"/></button>
      </div>
      <div class="coluna2">
        <span>Serviços</span>
      </div>
      <div class="coluna3"/>
    </div>  

    <div class="container-fluid">
      <div class="row" v-if="modo==''">
        <div class="col-12">
          <button
            class="btn btn-sm btn-info"
            @click="incluirRegistro()"
          >Incluir Novo Serviço</button>
          <hr>
        </div>
      </div>
      <div class="registro" v-if="modo!=''">
        <div class="row">
          <div class="form-group col-lg-1">
            <label for="Id" class="control-label">Id:</label>
            <input
              type="text"
              class="form-control"
              disabled
              id="Id"
              name="Id"
              :value="servico.id"
            />
          </div>

          <div class="form-group col-lg-1">
            <label for="SiglaServico" class="control-label">Sigla:</label>
            <input
              type="text"
              class="form-control"
              :disabled="!liberarCampos"
              id="SiglaServico"
              name="SiglaServico"
              v-model="servico.siglaServico"
            />
          </div>

          <div class="form-group col-lg-5">
            <label for="Descricao" class="control-label">Descrição:</label>
            <input
              type="text"
              class="form-control"
              :disabled="!liberarCampos"
              id="Descricao"
              name="Descricao"
              v-model="servico.descricao"
            />
          </div>

          <div class="form-group col-lg-1">
            <label for="AtendimentoSeriado" class="control-label">Seriado:</label>
            <select
              class="form-control"
              :disabled="!liberarCampos"
              id="AtendimentoSeriado"
              name="AtendimentoSeriado"
              selected
              v-model="servico.atendimentoSeriado"
            >
              <option
                v-for="confirma in sim_nao"
                :key="confirma.opcao"
                :value="confirma.opcao"
              >{{ confirma.valor }}</option>
            </select>
          </div>

          <div class="form-group col-lg-2">
            <label for="GerarLivro" class="control-label">Gera Livro:</label>
            <select
              class="form-control"
              :disabled="!liberarCampos"
              id="GerarLivro"
              name="GerarLivro"
              selected
              v-model="servico.geraLivroFisio"
            >
              <option
                v-for="confirma in sim_nao"
                :key="confirma.opcao"
                :value="confirma.opcao"
              >{{ confirma.valor }}</option>
            </select>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-lg-1"></div>

          <div class="form-group col-lg-1">
            <label for="PacientesPorVez" class="control-label">Por vez:</label>
            <input
              type="number"
              class="form-control"
              :disabled="!liberarCampos"
              id="PacientesPorVez"
              name="PacientesPorVez"
              v-model.number="servico.pacientesPorVez"
            />
          </div>

          <div class="form-group col-lg-2">
            <label for="Plano" class="control-label">Plano:</label>
            <input
              type="text"
              class="form-control"
              :disabled="!liberarCampos"
              id="Plano"
              name="Plano"
              v-model="servico.plano"
            />
          </div>

          <div class="form-group col-lg-1">
            <label for="Tolerancia" class="control-label">Tolerância:</label>
            <input
              type="text"
              class="form-control"
              :disabled="!liberarCampos"
              id="Tolerancia"
              name="Tolerancia"
              v-model="servico.tolerancia"
            />
          </div>
        </div>
    
        <hr />
        <div class="barraopcoescadastro">
          <button
            id="Excluir"
            :disabled="modo=='Incluir'"
            @click="excluirRegistro()"
            class="btn btn-sm btn-danger mr-2"
          >Excluir</button>
          <button
            id="Alterar"
            :disabled="modo=='Incluir'"
            @click="alterarRegistro()"
            class="btn btn-sm btn-secondary mr-2"
          >Alterar</button>
          <button id="Cancelar" 
            @click="cancelarOperacao()" 
            class="btn btn-sm btn-secondary mr-2"
          >Cancelar</button>
          <button
            id="Gravar"
            :disabled="!liberarCampos"
            @click="gravarRegistro()"
            class="btn btn-sm btn-secondary"
          >Gravar</button>
        </div>
      </div>
    </div>

    <template v-if="this.erro!=''">
      <p class="msgError">Erro durante Operação: {{ this.erro }}</p>
    </template>

    <div class="container-fluid">
      <div style="display:flex; flex-direction:row; justify-content:center; max-height:575px; overflow:auto">
        <b-spinner v-if="loadingServicos" label="carregando..."/>
        <b-table v-if="loadedServicos" class="col-lg-12" hover striped small sort-icon-left :items="servicos" :fields="camposTabela">
          <template v-slot:cell(actions)="row">
            <b-button variant="warning" size="sm" @click="selecionouServico(row.item)">
              <i class="fa fa-pencil"/>
            </b-button>
          </template>
        </b-table>
      </div>
    </div>
    <div class="Mensagem">
      <b-modal id="modal-mensagem" centered title="DoctorOn">
        <p class="my-4">{{mensagem}}</p>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex"
import constantes from '@/utils/constantes'
import servicos from "@/mixins/servicos"
import global from "@/mixins/global"
import errors from "@/utils/errors"

export default {
  mixins : [servicos, global],
  computed: {
    ...mapGetters({
      usuarioLogadoDados : "global/getUsuarioLogadoDados"
    }),
    liberarCampos() {
      return this.modo == "Incluir" || this.modo == "Alterar" ? true : false;
    }
  },
  data() {
    return {
      loadingServicos: false,
      loadedServicos: false,
      erro: "",
      modo : "",
      mensagem : "",
      servico : {},
      camposTabela: [
        {key: "id", label : "Código"},
        {key: "siglaServico", label : "Sigla", sortable : true},
        {key: "descricao", label : "Descrição", sortable : true},
        {key: "atendimentoSeriado", label : "Seriado"},
        {key: "geraLivroFisio", label : "Gera Livro"},
        {key: "pacientesPorVez", label : "Pacientes por vez"},
        {key: "plano", label : "Plano"},
        {key: "tolerancia", label : "Tolerância Atraso"},
        {key: "actions", label : "Ações"},
      ],
      sim_nao : constantes.sim_nao
    }
  },
  methods: {
    retornar() {
      this.$router.go(-1);
    },
    selecionouServico(servico) {
      this.servico = {...servico}
      this.modo = "Registro";
    },
    incluirRegistro() {
      if (this.modo == "" && !this.verificarPermissao('Incluir')) {
        return
      }

      this.servico = {}
      this.modo = this.modo == "" ? "Incluir" : "";
    },
    alterarRegistro() {
      if (!this.verificarPermissao('Alterar')) {
        return
      }

      this.modo = 'Alterar';
    },
    cancelarOperacao() {
      this.servico = {}
      this.modo = "";
      this.erro = "";
    },
    gravarRegistro() {
      if (!this.verificarCamposPreenchidos()) {
        return;
      }
      
      if (this.modo=="Alterar") {
        this.servico.operadorAlteracaoId = this.usuarioLogadoDados.profissionalId;

        this.$http
          .put(
            `/v1.0/servicos/${this.usuarioLogadoDados.tenant}/${this.servico.id}`,
            this.servico
          )
          .then(() => {
            this.erro = "";
            this.limparCampos();
            this.carregarServicos(this.usuarioLogadoDados.tenant);
            this.$toasted.global.defaultSuccess();
          })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          })
          .finally(() => {});
      } else {
        this.servico.operadorCadastroId = this.usuarioLogadoDados.profissionalId;

        this.$http
          .post(`/v1.0/servicos/${this.usuarioLogadoDados.tenant}`, this.servico)
          .then(() => {
            this.erro = "";
            this.limparCampos();
            this.carregarServicos(this.usuarioLogadoDados.tenant);
            this.$toasted.global.defaultSuccess();
          })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          })
          .finally(() => {});
      }
    },
    excluirRegistro() {
      if (!this.verificarPermissao('Excluir')) {
        return
      }

      this.$bvModal.msgBoxConfirm("Excluir Registro Selecionado?",{
          title: "Atenção",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true
      })
      .then(value => {
        if (value) {
          this.$http
            .delete(`/v1.0/servicos/${this.usuarioLogadoDados.tenant}/${this.servico.id}/${this.usuarioLogadoDados.profissionalId}`)
            .then(() => {
              this.erro = "";
  
              this.limparCampos();
              this.carregarServicos(this.usuarioLogadoDados.tenant);
              this.$toasted.global.defaultSuccess();
            })
            .catch(error => {
              this.erro = errors.tratarErros(error);
            })
            .finally(() => {});
        }
      })
      .catch(error => {
        this.erro = errors.tratarErros(error);
    })
    },
    limparCampos() {
      this.servico = {}

      if (this.modo!="Incluir") {
        this.modo = "";
      }
    },
    verificarCamposPreenchidos() {
      if (this.servico.siglaServico == "" || this.servico.siglaServico == undefined) {
        this.$toasted.show("Digite a Sigla do Serviço", {type : 'info', icon : 'times'});
        return false;
      }

      if (this.servico.descricao == "" || this.servico.descricao == undefined) {
        this.$toasted.show("Digite a Descrição do Serviço", {type : 'info', icon : 'times'});
        return false;
      }

      if (this.servico.atendimentoSeriado == "" || this.servico.atendimentoSeriado == undefined) {
        this.$toasted.show("Informe se o Serviço é Seriado", {type : 'info', icon : 'times'});
        return false;
      }

      if (this.servico.geraLivroFisio == "" || this.servico.geraLivroFisio == undefined) {
        this.$toasted.show("Informe se o Serviço Gerar Livro", {type : 'info', icon : 'times'});
        return false;
      }

      if (this.servico.pacientesPorVez == "" || this.servico.pacientesPorVez == undefined) {
        this.$toasted.show("Informe o número de pacientes atendidos por vez", {type : 'info', icon : 'times'});
        return false;
      }

      return true;
    },
    verificarPermissao(operacao) {
      if (!errors.verificarPermissao(this.usuarioLogadoDados, this.$store.state.global.grauPermissaoMinimo, operacao)) {
        let descricaoOperacao = operacao == "Incluir" ? "Inclusão" : operacao == "Alterar" ? "Alteração" : "Exclusão";

        this.mensagem = `${this.usuarioLogadoDados.profissional.nome}, você não possui permissão para realizar ${descricaoOperacao} com esse Perfil.`;
        this.$bvModal.show("modal-mensagem");

        return false;
      }

      return true;
    }    
  },
  created() {
    this.showMenu(false);
    this.carregarServicos(this.usuarioLogadoDados.tenant);
  },
  beforeRouteLeave(to, from, next) {
    if (this.modo=="Incluir" || this.modo=="Alterar") {
      this.$bvModal
        .msgBoxConfirm("Tem certeza que deseja sair sem Gravar os Dados?", {
          title: "Atenção",
          size: "sm",
          buttonSize: "sm",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            next(true);
          } else {
            next(false);
          }
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        });
    } else {
      next(true);
    }
  }
}
</script>

<style scoped>
</style>
