<template>
  <div id="CadProfissionalAtendimento">
    <div class="cabecalho">
      <div class="coluna1">
        <button @click="retornar" class="btn btn-sm btn-primary mr-10"><i class="fa fa-arrow-left"/></button>
      </div>
      <div class="coluna2">
        <span>{{profissionalSelecionado.nome}}</span>
      </div>
      <div class="coluna3"/>
    </div>  

    <div class="container-fluid">
      <div class="row">
        <div class="form-group col-lg-1">
          <label for="preNome" class="control-label">Pré-Nome</label>
          <select
            id="preNome"
            class="form-control"
            :disabled="!liberarCampos"
            selected
            v-model="profissionalAtendimentoComplemento.preNome"
          >
            <option v-for="preNome in preNomes" :key="preNome.opcao" :value="preNome.opcao">{{preNome.valor}}</option>
          </select>
        </div>

        <div class="form-group col-lg-1">
          <label for="tipoAgenda" class="control-label">Tipo de Agenda</label>
          <select
            class="form-control"
            :disabled="!liberarCampos"
            id="tipoAgenda"
            name="tipoAgenda"
            selected
            v-model="profissionalAtendimentoComplemento.tipoAgenda"
          >
            <option
              v-for="tipoAgena in tiposAgendas"
              :key="tipoAgena.opcao"
              :value="tipoAgena.opcao"
            >{{ tipoAgena.valor }}</option>
          </select>
        </div>

        <div class="form-group col-lg-2">
          <label for="especialidadeId" class="control-label">Especialidade</label>
          <select
            id="especialidadeId"
            class="form-control"
            :disabled="!liberarCampos"
            selected
            v-model="profissionalAtendimentoComplemento.especialidadeId"
          >
            <option
              v-for="especialidade in especialidades"
              :key="especialidade.id"
              :value="especialidade.id"
            >{{especialidade.descricao}}</option>
          </select>
        </div>

        <div class="form-group col-lg-5">
          <label for="qualificacao" class="control-label">Qualificação</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="qualificacao"
            name="qualificacao"
            v-model="profissionalAtendimentoComplemento.qualificacao"
          />
        </div>
      </div>

      <div class="row">
        <div class="form-group col-lg-1">
          <label for="idadeInicial" class="control-label">Idade Inicial</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="idadeInicial"
            name="idadeInicial"
            v-model.number="profissionalAtendimentoComplemento.idadeInicial"
          />
        </div>

        <div class="form-group col-lg-1">
          <label for="idadeFinal" class="control-label">Idade Final</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="idadeFinal"
            name="idadeFinal"
            v-model.number="profissionalAtendimentoComplemento.idadeFinal"
          />
        </div>

        <div class="form-group col-lg-1">
          <label for="bloquearRetorno" class="control-label">Bloq Retorno</label>
          <select
            class="form-control"
            :disabled="!liberarCampos"
            id="bloquearRetorno"
            name="bloquearRetorno"
            selected
            v-model="profissionalAtendimentoComplemento.bloquearRetorno"
          >
            <option
              v-for="confirma in sim_nao"
              :key="confirma.opcao"
              :value="confirma.opcao"
            >{{ confirma.valor }}</option>
          </select>
        </div>

        <div class="form-group col-lg-1">
          <label for="permiteEncaixe" class="control-label">Permite Encaixe</label>
          <select
            class="form-control"
            :disabled="!liberarCampos"
            id="permiteEncaixe"
            name="permiteEncaixe"
            selected
            v-model="profissionalAtendimentoComplemento.permiteEncaixe"
          >
            <option
              v-for="confirma in sim_nao"
              :key="confirma.opcao"
              :value="confirma.opcao"
            >{{ confirma.valor }}</option>
          </select>
        </div>

        <div class="form-group col-lg-2">
          <label for="pc2Horarios" class="control-label">1ª Atend. 2 Horários</label>
          <select
            class="form-control"
            :disabled="!liberarCampos"
            id="pc2Horarios"
            name="pc2Horarios"
            selected
            v-model="profissionalAtendimentoComplemento.pc2Horarios"
          >
            <option
              v-for="confirma in sim_nao"
              :key="confirma.opcao"
              :value="confirma.opcao"
            >{{ confirma.valor }}</option>
          </select>
        </div>

        <div class="form-group col-lg-2">
          <label for="selecionaProfExame" class="control-label">Selecionar Prof. Exame</label>
          <select
            class="form-control"
            :disabled="!liberarCampos"
            id="selecionaProfExame"
            name="selecionaProfExame"
            selected
            v-model="profissionalAtendimentoComplemento.selecionaProfExame"
          >
            <option
              v-for="confirma in sim_nao"
              :key="confirma.opcao"
              :value="confirma.opcao"
            >{{ confirma.valor }}</option>
          </select>
        </div>

        <div class="form-group col-lg-1">
          <label for="apenasAtendimento" class="control-label">Apenas Agenda</label>
          <select
            class="form-control"
            :disabled="!liberarCampos"
            id="apenasAtendimento"
            name="apenasAtendimento"
            selected
            v-model="profissionalAtendimentoComplemento.apenasAtendimento"
          >
            <option
              v-for="confirma in sim_nao"
              :key="confirma.opcao"
              :value="confirma.opcao"
            >{{ confirma.valor }}</option>
          </select>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-lg-2">
          <label for="SalaAtendimento" class="control-label">Sala de Atendimento</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="SalaAtendimento"
            name="SalaAtendimento"
            v-model="profissionalAtendimentoComplemento.salaAtendimento"
          />
        </div>

        <div class="form-group col-lg-2">
          <label for="datViProntuario" class="control-label">Verificado Anamneses</label>
          <input
            type="date"
            class="form-control"
            disabled
            id="datViProntuario"
            name="datViProntuario"
            v-model="profissionalAtendimentoComplemento.datViProntuario"
          />
        </div>

        <div class="form-group col-lg-2">
          <label for="datViSolicitacao" class="control-label">Verificado Solicitacoes</label>
          <input
            type="date"
            class="form-control"
            disabled
            id="datViSolicitacao"
            name="datViSolicitacao"
            v-model="profissionalAtendimentoComplemento.datViSolicitacao"
          />
        </div>

        <div class="form-group col-lg-1">
          <label for="utilizaCertificado" class="control-label">Certif.Dig.</label>
          <select
            class="form-control"
            :disabled="!liberarCampos"
            id="utilizaCertificado"
            name="utilizaCertificado"
            selected
            v-model="profissionalAtendimentoComplemento.utilizaCertificado"
          >
            <option
              v-for="confirma in sim_nao"
              :key="confirma.opcao"
              :value="confirma.opcao"
            >{{ confirma.valor }}</option>
          </select>
        </div>

        <div class="form-group col-lg-2">
          <label for="DatInstrucao" class="control-label">Data Instrução</label>
          <input
            type="date"
            class="form-control"
            disabled
            id="DatInstrucao"
            name="DatInstrucao"
            v-model="profissionalAtendimentoComplemento.datInstrucao"
          />
        </div>
      </div>

      <div class="row">
        <div class="form-group col-lg-10">
          <label for="Instrucao" class="control-label">Instrução</label>
          <textarea rows="2" cols="80" class="form-control" :disabled="!liberarCampos" id="instrucao" name="instrucao" v-model="profissionalAtendimentoComplemento.instrucoes"/>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-lg-2">
          <label for="reciboPelaClinica" class="control-label">Recibo em nome da Clínica</label>
          <select
            class="form-control"
            :disabled="!liberarCampos"
            id="reciboPelaClinica"
            name="reciboPelaClinica"
            selected
            v-model="profissionalAtendimentoComplemento.reciboPelaClinica"
          >
            <option
              v-for="confirma in sim_nao"
              :key="confirma.opcao"
              :value="confirma.opcao"
            >{{ confirma.valor }}</option>
          </select>
        </div>

        <div class="form-group col-lg-2">
          <label for="DescontarTaxaCartao" class="control-label">Descontar Taxa Cartão</label>
          <select
            class="form-control"
            :disabled="!liberarCampos"
            id="DescontarTaxaCartao"
            name="DescontarTaxaCartao"
            selected
            v-model="profissionalAtendimentoComplemento.descontarTaxaCartao"
          >
            <option
              v-for="confirma in sim_nao"
              :key="confirma.opcao"
              :value="confirma.opcao"
            >{{ confirma.valor }}</option>
          </select>
        </div>
      </div>

      <hr />
      <p v-if="erro!=''" style="background-color:red; color:white; text-align:center">{{erro}}</p>

      <div class="barraopcoescadastro">
        <button
          id="Alterar"
          :disabled="modo=='Alterar' || modo=='Incluir'"
          @click="alterarRegistro()"
          class="btn btn-sm btn-secondary mr-2"
        >Alterar</button>
        <button
          id="Cancelar"
          @click="cancelarOperacao()"
          class="btn btn-sm btn-secondary mr-2"
        >Cancelar</button>
        <button
          id="Gravar"
          :disabled="!liberarCampos"
          @click="gravarRegistro()"
          class="btn btn-sm btn-secondary mr-2"
        >Gravar</button>

        <router-link v-if="perfilProfissionalSelecionado.realizaAtendimento=='S'" class="btn btn-sm btn-info mr-2" :disabled="liberarCampos" tag="button" to="/frmProfissionalDisponibilidades">Disponibilidades de Horários</router-link>
        <router-link v-if="perfilProfissionalSelecionado.realizaAtendimento=='S'" class="btn btn-sm btn-info mr-2" :disabled="liberarCampos" tag="button" to="/frmProfissionalComissoes">Comissões Por Serviço</router-link>
        <router-link v-if="perfilProfissionalSelecionado.realizaAtendimento=='S'" class="btn btn-sm btn-info mr-2" :disabled="liberarCampos" tag="button" to="/frmProcedimentoComissoes">Comissões Por Procedimento</router-link>
        <router-link v-if="perfilProfissionalSelecionado.realizaAtendimento=='S'" class="btn btn-sm btn-info mr-2" :disabled="liberarCampos" tag="button" to="/frmProfissionalImpostos">Impostos</router-link>
        <router-link v-if="perfilProfissionalSelecionado.realizaAtendimento=='S'" class="btn btn-sm btn-info mr-2" :disabled="liberarCampos" tag="button" to="/frmAgendaPermitidos">Podem ver Minha Agenda</router-link>
        <router-link v-if="perfilProfissionalSelecionado.realizaAtendimento=='S'" class="btn btn-sm btn-info mr-2" :disabled="liberarCampos" tag="button" to="/frmProntuarioPermitidos">Podem ver Meus Prontuários</router-link>
        <!-- <button class="btn btn-sm btn-info mr-2" disabled @click="configurarMemed()">Configurar Receituário Digital</button> -->
      </div>
    </div>

    <div class="Mensagem">
      <b-modal id="modal-mensagem" centered title="DoctorOn">
        <p class="my-4">{{mensagem}}</p>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import global from "@/mixins/global";
import profissionalComplementos from "@/mixins/profissionalComplementos";
import especialidades from "@/mixins/especialidades";
import errors from "@/utils/errors";
import constantes from "@/utils/constantes";
import funcoes from "@/utils/funcoes";

export default {
  mixins : [profissionalComplementos, especialidades, global],
  computed: {
    ...mapGetters({
      profissionalSelecionado: "profissionais/getProfissionalSelecionado",
      usuarioLogadoDados : "global/getUsuarioLogadoDados",
      perfilProfissionalSelecionado : "perfis/getPerfilSelecionado"
    }),
    liberarCampos() {
      return this.modo == "Incluir" || this.modo == "Alterar" ? true : false;
    },
  },
  data() {
    return {
      erro : "",
      modo : "",
      mensagem : "",
      loadingProfissionalGeralComplemento : false,
      loadedProfissionalGeralComplemento : false,

      sim_nao: constantes.sim_nao,
      preNomes : constantes.preNome,
      tiposAgendas : constantes.tipoAgenda,
      profissionalAtendimentoComplemento : {},
    };
  },
  methods: {
    ...mapActions({
      setProfissionalAtendimentoComplementoSelecionado : "profissionalComplementos/setProfissionalAtendimentoComplementoSelecionado"
    }),
    retornar() {
      this.$router.go(-1);
    },
    alterarRegistro() {
      if (!this.verificarPermissao('Alterar')) {
        return
      }

      this.modo = "Alterar";
    },
    //async configurarMemed() {
    //  this.setProfissionalAtendimentoComplementoSelecionado(this.profissionalAtendimentoComplemento);
    //  this.$router.push('/cadProfissionalMemed');
    //},
    cancelarOperacao() {
      this.modo = "";
    },
    gravarRegistro() {
      if (!this.verificarCamposPreenchidos()) {
        return;
      }

      if (this.modo=="Alterar") {
        this.profissionalAtendimentoComplemento.operadorAlteracaoId = this.usuarioLogadoDados.profissionalId;

        this.$http
          .put(
            `/v1.0/profissionalAtendimentoComplementos/${this.usuarioLogadoDados.tenant}/${this.profissionalAtendimentoComplemento.id}`,
            this.profissionalAtendimentoComplemento)
          .then(() => {
            this.erro = "";
            this.modo = "";
            this.$toasted.global.defaultSuccess();
          })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          })
          .finally(() => {});
      } else {
        this.profissionalAtendimentoComplemento.profissionalId = this.profissionalSelecionado.id;
        this.profissionalAtendimentoComplemento.operadorCadastroId = this.usuarioLogadoDados.profissionalId;
        this.profissionalAtendimentoComplemento.bloqueado = "N";

        this.$http
          .post(`/v1.0/profissionalAtendimentoComplementos/${this.usuarioLogadoDados.tenant}`, this.profissionalAtendimentoComplemento)
          .then(() => {
            this.erro = "";
            this.modo = "";
            this.$toasted.global.defaultSuccess();
          })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          })
          .finally(() => {});
      }
    },
    verificarCamposPreenchidos() {
      if (this.profissionalAtendimentoComplemento.tipoAgenda == null) {
        this.$toasted.show("Selecione o Tipo de Agenda representada pelo Profissional.", {type : 'info', icon : 'times'});
        return false;
      }

      if (this.profissionalAtendimentoComplemento.especialidadeId == null) {
        this.$toasted.show("Selecione a Especialidade do Profissional.", {type : 'info', icon : 'times'});
        return false;
      }
     
      if (this.profissionalAtendimentoComplemento.bloquearRetorno == null) {
        this.$toasted.show("Selecione se o profissional Bloqueia o Agendamento de Retorno.", {type : 'info', icon : 'times'});
        return false;
      }

      if (this.profissionalAtendimentoComplemento.permiteEncaixe == null) {
        this.$toasted.show("Selecione se o profissional Permite Encaixe de horários.", {type : 'info', icon : 'times'});
        return false;
      }

      if (this.profissionalAtendimentoComplemento.pc2Horarios == null) {
        this.$toasted.show("Selecione se o Agendamento de 1ª Consulta ocupará dois horários.", {type : 'info', icon : 'times'});
        return false;
      }

      if (this.profissionalAtendimentoComplemento.selecionaProfExame == null) {
        this.$toasted.show("Selecione se o Profissional deverá ser informado em Agenda de Exames.", {type : 'info', icon : 'times'});
        return false;
      }

      if (this.profissionalAtendimentoComplemento.apenasAtendimento == null) {
        this.$toasted.show("Selecione se o Profissional terá permissão restrita aos atendimentos.", {type : 'info', icon : 'times'});
        return false;
      }

      if (this.profissionalAtendimentoComplemento.reciboPelaClinica == null) {
        this.$toasted.show("Selecione se os Recibos deverão sair em nome da Clínica.", {type : 'info', icon : 'times'});
        return false;
      }

      if (this.profissionalAtendimentoComplemento.utilizaCertificado == null) {
        this.$toasted.show("Selecione se o Profissional Utiliza Certificado Digital.", {type : 'info', icon : 'times'});
        return false;
      }

      if (this.profissionalAtendimentoComplemento.descontarTaxaCartao == null) {
        this.$toasted.show("Selecione se será descontada a Taxa de Cartão da comissão do Profissional.", {type : 'info', icon : 'times'});
        return false;
      }

      return true;
    },
    verificarPermissao(operacao) {
      if (!errors.verificarPermissao(this.usuarioLogadoDados, this.$store.state.global.grauPermissaoMinimo, operacao)) {
        let descricaoOperacao = operacao == "Incluir" ? "Inclusão" : operacao == "Alterar" ? "Alteração" : "Exclusão";

        this.mensagem = `${this.usuarioLogadoDados.profissional.nome}, você não possui permissão para realizar ${descricaoOperacao} com esse Perfil.`;
        this.$bvModal.show("modal-mensagem");

        return false;
      }

      return true;
    },
  },
  async created() {
    this.showMenu(false);
    await this.carregarAtendimentoPeloProfissional(this.usuarioLogadoDados.tenant ,this.profissionalSelecionado.id);
    await this.carregarEspecialidades(this.usuarioLogadoDados.tenant, 0);

    if (this.profissionalAtendimentoComplemento.id == undefined) {
      this.modo = "Incluir";

      //------------------------ Atendimento --------------------
      this.profissionalAtendimentoComplemento.bloquearRetorno = "N";
      this.profissionalAtendimentoComplemento.permiteEncaixe = "S";
      this.profissionalAtendimentoComplemento.reciboPelaClinica = "S";
      this.profissionalAtendimentoComplemento.pc2Horarios = "N";
      this.profissionalAtendimentoComplemento.utilizaCertificado = "N";
      this.profissionalAtendimentoComplemento.apenasAtendimento = "N";
      this.profissionalAtendimentoComplemento.idadeInicial = 0;
      this.profissionalAtendimentoComplemento.idadeFinal = 120;
      this.profissionalAtendimentoComplemento.descontarTaxaCartao = 'S';
      this.profissionalAtendimentoComplemento.tipoAgenda = 'AT';
    } else {
      this.modo = ""  ;

      this.profissionalAtendimentoComplemento.datViSolicitacao = funcoes.mySqlToJSDateFormat(this.profissionalAtendimentoComplemento.datViSolicitacao);
      this.profissionalAtendimentoComplemento.datViProntuario = funcoes.mySqlToJSDateFormat(this.profissionalAtendimentoComplemento.datViProntuario);
      this.profissionalAtendimentoComplemento.datInstrucao = funcoes.mySqlToJSDateFormat(this.profissionalAtendimentoComplemento.datInstrucao);
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.modo=="Incluir" || this.modo=="Alterar") {
      this.$bvModal
        .msgBoxConfirm("Tem certeza que deseja sair sem Gravar os Dados?", {
          title: "Atenção",
          size: "sm",
          buttonSize: "sm",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            next(true);
          } else {
            next(false);
          }
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        });
    } else {
      next(true);
    }
  }
};
</script>

<style scoped>
    label {
      font-weight: 100%;
      font-size: 0.8rem;
      margin-top: 3px;
      margin-bottom: 1px;
    }
</style>
