const estruturaTabela = {
    id : 0, codPro : "", codTuss : "", descricao : "",
    honorario : "N", chsPro : 0, chsCO : 0, valCO : 0,
    valHonorario : 0, valorTotal : 0, valTotFilme : 0,
    filmeAMB90 : 0, tipoLote : 2, servicoId : 0,
    porte1 : 0, porte2 : null, porte3 : null,
    porteAnestesicoValor : 0, porteSala : 0, porteSalaValor : 0,
    auxiliares : 0, porteAnestesico : 0, incidencia : 0, uniRadioFarmaco : null,
    ai : null, ah : null, necessitaAprovacao : "N", datCadastro : null, datAlteracao : null,
    tabelaProcedimentoId : 0, operadorCadastroId : 0, operadorAlteracaoId : 0
}

const state = {
    all : [],
    procedimentoSelecionado : Object,
}

const getters = {
    getAllProcedimentos(state) {
        return state.all;
    },

    getProcedimentoSelecionado(state) {
        return state.procedimentoSelecionado
    },
}

const mutations = {
    setProcedimentos(state, listaprocedimentos) {
        state.all = listaprocedimentos;
    },

    setProcedimentoSelecionado(state, procedimento) {
        state.procedimentoSelecionado = procedimento;
    },

    limparRegistroSelecionado(state) {
        state.procedimentoSelecionado = {...estruturaTabela}
    },

    limparLista(state) {
        state.all = [];
    }
}

const actions = {
    setProcedimentos({commit}, listaprocedimentos) {
        commit("setProcedimentos", listaprocedimentos)
    },

    setProcedimentoSelecionado({commit}, procedimento) {
        commit("setProcedimentoSelecionado", procedimento)
    },

    limparRegistroSelecionado({commit}) {
        commit("limparRegistroSelecionado")
    },

    limparLista({commit}) {
        commit("limparLista")
    },

    formLeave({commit}) {
        commit('limparRegistroSelecionado');
        commit('limparLista');
    }
}

export default {
    namespaced : true,    
    state,
    getters,
    mutations,
    actions
}