import { render, staticRenderFns } from "./frmAcompanhamentosPaciente.vue?vue&type=template&id=424d69dd&scoped=true&"
import script from "./frmAcompanhamentosPaciente.vue?vue&type=script&lang=js&"
export * from "./frmAcompanhamentosPaciente.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "424d69dd",
  null
  
)

export default component.exports