<template>
  <div id="CadDespesaBrasindice">
    <div class="cabecalho">
      <div class="coluna1">
        <button @click="retornar()" class="btn btn-sm btn-primary mr-10">
          <i class="fa fa-arrow-left" />
        </button>
      </div>
      <div class="coluna2">
        <span>Apresentação Brasindice</span>
      </div>
      <div class="coluna3" />
    </div>

    <div class="container-fluid">
      <div class="row">
        <div class="form-group col-lg-3">
          <label for="Operadoras" class="control-label">Operadoras</label>
          <select
            id="Operadoras"
            class="form-control"
            selected
            :disabled="!liberarCampos"
            v-model="despesaBrasindiceSelecionada.operadoraId"
          >
            <option
              v-for="operadora in operadoras"
              :key="operadora.id"
              :value="operadora.id"
            >
              {{ operadora.fantasia }}
            </option>
          </select>
        </div>
        <div class="form-group col-lg-2">
          <b-checkbox
            style="margin-top: 32px"
            :disabled="!liberarCampos"
            v-model="despesaBrasindiceSelecionada.precoCongelado"
            >Preço Congelado</b-checkbox
          >
        </div>
        <div class="form-group col-lg-2">
          <label for="datExclusao" class="control-label">Data Exclusão</label>
          <input
            id="datExclusao"
            type="date"
            class="form-control"
            :disabled="!liberarCamposNaoAssinantes"
            v-model="despesaBrasindiceSelecionada.datExclusao"
          />
        </div>
      </div>
      <hr />

      <div class="row">
        <div class="form-group col-lg-1">
          <label for="codigoMedicamento" class="control-label">Código</label>
          <input
            id="codigoMedicamento"
            type="text"
            class="form-control"
            :disabled="!liberarCamposNaoAssinantes"
            v-model="despesaBrasindiceSelecionada.codigoMedicamento"
          />
        </div>
        <div class="form-group col-lg-7">
          <label for="medicamento" class="control-label">Medicamento</label>
          <input
            id="medicamento"
            type="text"
            class="form-control"
            :disabled="!liberarCamposNaoAssinantes"
            v-model="despesaBrasindiceSelecionada.medicamento"
          />
        </div>
      </div>
      <div class="row">
        <div class="form-group col-lg-1">
          <label for="codapresentacaoBrasindice" class="control-label"
            >Código</label
          >
          <input
            id="codapresentacaoBrasindice"
            type="text"
            class="form-control"
            :disabled="!liberarCamposNaoAssinantes"
            v-model="despesaBrasindiceSelecionada.codigoApresentacao"
          />
        </div>
        <div class="form-group col-lg-7">
          <label for="apresentacaoBrasindice" class="control-label"
            >Apresentação</label
          >
          <input
            id="apresentacaoBrasindice"
            type="text"
            class="form-control"
            :disabled="!liberarCamposNaoAssinantes"
            v-model="despesaBrasindiceSelecionada.apresentacao"
          />
        </div>
      </div>

      <div class="row">
        <div class="form-group col-lg-1">
          <label for="codTissBrasindice" class="control-label">TISS</label>
          <input
            id="codTissBrasindice"
            type="text"
            class="form-control"
            :disabled="!liberarCamposNaoAssinantes"
            v-model="despesaBrasindiceSelecionada.codigoTiss"
          />
        </div>
        <div class="form-group col-lg-1">
          <label for="codTussBrasindice" class="control-label">TUSS</label>
          <input
            id="codTussBrasindice"
            type="text"
            class="form-control"
            :disabled="!liberarCamposNaoAssinantes"
            v-model="despesaBrasindiceSelecionada.codigoTuss"
          />
        </div>
        <div class="form-group col-lg-1">
          <label for="ignorarCodigoTuss" class="control-label">Ignorar Tuss</label>
          <select
            id="ignorarCodigoTuss"
            class="form-control"
            :disabled="!liberarCamposNaoAssinantes"
            selected
            v-model="despesaBrasindiceSelecionada.ignorarCodigoTuss"
          >
            <option
              v-for="resposta in sim_nao"
              :key="resposta.opcao"
              :value="resposta.opcao"
            >
              {{ resposta.valor }}
            </option>
          </select>
        </div>
        <div class="form-group col-lg-1">
          <label for="tipoPrecoBrasindice" class="control-label"
            >Tipo Preço</label
          >
          <select
            id="tipoPrecoBrasindice"
            class="form-control"
            :disabled="!liberarCamposNaoAssinantes"
            selected
            v-model="despesaBrasindiceSelecionada.tipoPreco"
          >
            <option value="PMC">PMC</option>
            <option value="PFB">PFB</option>
          </select>
        </div>
        <div class="form-group col-lg-2">
          <label for="eAN13Brasindice" class="control-label">EAN13</label>
          <input
            id="eAN13Brasindice"
            type="text"
            class="form-control"
            :disabled="!liberarCamposNaoAssinantes"
            v-model="despesaBrasindiceSelecionada.eaN13"
          />
        </div>
        <div class="form-group col-lg-1">
          <label for="restritoHospitalar" class="control-label">RH</label>
          <select
            id="restritoHospitalar"
            class="form-control"
            :disabled="!liberarCamposNaoAssinantes"
            selected
            v-model="despesaBrasindiceSelecionada.restritoHospitalar"
          >
            <option
              v-for="resposta in sim_nao"
              :key="resposta.opcao"
              :value="resposta.opcao"
            >
              {{ resposta.valor }}
            </option>
          </select>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-lg-1">
          <label for="precoBrasindice" class="control-label">R$ PMC</label>
          <input
            id="precoBrasindice"
            type="text"
            class="form-control"
            :disabled="!liberarCamposNaoAssinantes"
            v-model.number="preco"
          />
        </div>
        <div class="form-group col-lg-1">
          <label for="unidadeEmbalagem" class="control-label">Unidade</label>
          <input
            id="unidadeEmbalagem"
            type="text"
            class="form-control"
            :disabled="!liberarCamposNaoAssinantes"
            v-model="despesaBrasindiceSelecionada.unidadeEmbalagem"
          />
        </div>
        <div class="form-group col-lg-1">
          <label for="qtdeBrasindice" class="control-label">Com</label>
          <input
            id="qtdeBrasindice"
            type="text"
            class="form-control"
            :disabled="!liberarCamposNaoAssinantes"
            v-model.number="qtde"
          />
        </div>
        <div class="form-group col-lg-1">
          <label for="precoBrasindiceUnitario" class="control-label"
            >R$ PMC Unit</label
          >
          <input
            id="precoBrasindiceUnitario"
            type="text"
            class="form-control"
            disabled
            v-model.number="precoUnitario"
          />
        </div>
        <div class="form-group col-lg-1">
          <label for="unidadeFracao" class="control-label">Unidade</label>
          <input
            id="unidadeFracao"
            type="text"
            class="form-control"
            :disabled="!liberarCamposNaoAssinantes"
            v-model="despesaBrasindiceSelecionada.unidadeFracao"
          />
        </div>
        <div class="form-group">
          <label style="margin-top: 32px">X</label>
        </div>
        <div class="form-group col-lg-1">
          <label for="coefBrasindice" class="control-label">Coeficiente</label>
          <input
            id="coefBrasindice"
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            v-model.number="coef"
          />
        </div>
        <div class="form-group">
          <label style="margin-top: 32px">=</label>
        </div>
        <div class="form-group col-lg-1">
          <label for="precoBrasindiceFat" class="control-label"
            >R$ PMC Fat</label
          >
          <input
            id="precoBrasindiceFat"
            type="text"
            class="form-control"
            disabled
            v-model.number="despesaBrasindiceSelecionada.precoFaturado"
          />
        </div>
        <div class="form-group col-lg-1">
            <label for="unidadeConsumoBrasindice" class="control-label">Unid Consumo</label>
            <input id="unidadeConsumoBrasindice" type="text" class="form-control" disabled v-model="despesaBrasindiceSelecionada.unidadeConsumo"/>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-lg-1">
          <label for="precoBrasindiceFab" class="control-label">R$ PFB</label>
          <input
            id="precoBrasindiceFab"
            type="text"
            class="form-control"
            :disabled="!liberarCamposNaoAssinantes"
            v-model.number="precoFabrica"
          />
        </div>
        <div class="form-group col-lg-1"></div>
        <div class="form-group col-lg-1"></div>
        <div class="form-group col-lg-1">
          <label for="precoBrasindiceUnitarioFab" class="control-label"
            >R$ PFB Unit</label
          >
          <input
            id="precoBrasindiceUnitarioFab"
            type="text"
            class="form-control"
            disabled
            v-model.number="precoUnitarioFabrica"
          />
        </div>
        <div class="form-group col-lg-1"></div>
        <div class="form-group col-lg-1"></div>
        <div class="form-group">
          <label style="margin-top: 32px; margin-left: 8px">=</label>
        </div>
        <div class="form-group col-lg-1">
          <label for="precoBrasindiceFatFab" class="control-label"
            >R$ PFB Fat</label
          >
          <input
            id="precoBrasindiceFatFab"
            type="text"
            class="form-control"
            disabled
            v-model.number="despesaBrasindiceSelecionada.precoFaturadoFabrica"
          />
        </div>
      </div>

      <hr />
      <p
        v-if="erro"
        style="background-color: red; color: white; text-align: center"
      >
        {{ erro }}
      </p>
      <div class="barraopcoescadastro">
        <button
          id="Alterar"
          :disabled="modo == 'Incluir' || modo == 'Alterar'"
          @click="alterarRegistro()"
          class="btn btn-sm btn-secondary mr-2"
        >
          Alterar
        </button>
        <button
          id="Cancelar"
          @click="cancelarOperacao()"
          class="btn btn-sm btn-secondary mr-2"
        >
          Cancelar
        </button>
        <button
          id="Gravar"
          :disabled="!liberarCampos"
          @click="gravarRegistro()"
          class="btn btn-sm btn-secondary mr-5"
        >
          Gravar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import errors from "@/utils/errors";
import constantes from "@/utils/constantes";
import global from "@/mixins/global";
import operadoras from "@/mixins/operadoras";

export default {
  mixins: [operadoras, global],
  computed: {
    ...mapGetters({
      despesaSelecionada: "despesas/getDespesaSelecionada",
      despesaBrasindiceSelecionada:
        "despesasBrasindice/getDespesaBrasindiceSelecionada",
      usuarioLogadoDados: "global/getUsuarioLogadoDados",
      assinanteBrasindice: "global/getAssinanteBrasindice",
    }),
    liberarCampos() {
      return this.modo == "Incluir" || this.modo == "Alterar" ? true : false;
    },
    liberarCamposNaoAssinantes() {
      return this.assinanteBrasindice != "S" && this.liberarCampos
        ? true
        : false;
    },
  },
  data() {
    return {
      modo: "",
      erro: "",
      sim_nao: constantes.sim_nao,
      qtde: 0,
      preco: 0,
      precoFabrica: 0,

      coef: 0,
      precoUnitario: 0,
      precoUnitarioFabrica: 0,
    };
  },
  watch: {
    qtde() {
      this.despesaBrasindiceSelecionada.qtde = this.qtde;
      this.precoUnitario = this.preco / this.despesaBrasindiceSelecionada.qtde;
      this.precoUnitarioFabrica =
        this.precoFabrica / this.despesaBrasindiceSelecionada.qtde;
    },
    preco() {
      this.despesaBrasindiceSelecionada.preco = this.preco;
      this.precoUnitario =
        this.despesaBrasindiceSelecionada.preco /
        this.despesaBrasindiceSelecionada.qtde;
    },
    precoFabrica() {
      this.despesaBrasindiceSelecionada.precoFabrica = this.precoFabrica;
      this.precoUnitarioFabrica =
        this.despesaBrasindiceSelecionada.precoFabrica /
        this.despesaBrasindiceSelecionada.qtde;
    },
    coef() {
      this.despesaBrasindiceSelecionada.coef = this.coef;
      this.despesaBrasindiceSelecionada.precoFaturado =
        this.despesaBrasindiceSelecionada.precoUnitario *
        this.despesaBrasindiceSelecionada.coef;
      this.despesaBrasindiceSelecionada.precoFaturadoFabrica =
        this.despesaBrasindiceSelecionada.precoUnitarioFabrica *
        this.despesaBrasindiceSelecionada.coef;
    },
    precoUnitario() {
      this.despesaBrasindiceSelecionada.precoUnitario = this.precoUnitario;
      this.despesaBrasindiceSelecionada.precoFaturado =
        this.despesaBrasindiceSelecionada.precoUnitario *
        this.despesaBrasindiceSelecionada.coef;
    },
    precoUnitarioFabrica() {
      this.despesaBrasindiceSelecionada.precoUnitarioFabrica = this.precoUnitarioFabrica;
      this.despesaBrasindiceSelecionada.precoFaturadoFabrica =
        this.despesaBrasindiceSelecionada.precoUnitarioFabrica *
        this.despesaBrasindiceSelecionada.coef;
    },
  },
  methods: {
    ...mapActions({
      formLeave: "despesasBrasindice/formLeave",
    }),
    retornar() {
      this.$router.go(-1);
    },
    alterarRegistro() {
      this.modo = "Alterar";
    },
    incluirRegistro() {
      this.modo = "Incluir";
    },
    gravarRegistro() {
      if (!this.verificarCamposPreenchidos()) {
        return;
      }

      if (
        this.despesaBrasindiceSelecionada.operadoraId == undefined ||
        this.despesaBrasindiceSelecionada.operadoraId == 0
      ) {
        this.despesaBrasindiceSelecionada.operadora = "Todos";
      } else {
        this.despesaBrasindiceSelecionada.operadora = this.operadoras.find(
          (operadora) =>
            operadora.id == this.despesaBrasindiceSelecionada.operadoraId
        ).fantasia;
      }

      if (this.modo == "Alterar") {
        this.despesaBrasindiceSelecionada.operadorAlteracaoId = this.usuarioLogadoDados.profissionalId;

        this.$http
          .put(
            `/v1.0/despesasBrasindice/${this.usuarioLogadoDados.tenant}/${this.despesaBrasindiceSelecionada.id}`,
            this.despesaBrasindiceSelecionada
          )
          .then(() => {
            this.erro = "";
            this.modo = "";
            this.$toasted.global.defaultSuccess();
          })
          .catch((error) => (this.erro = errors.tratarErros(error)));
      } else {
        this.despesaBrasindiceSelecionada.despesaId = this.despesaSelecionada.id;
        this.despesaBrasindiceSelecionada.operadorCadastroId = this.usuarioLogadoDados.profissionalId;

        this.$http
          .post(
            `/v1.0/despesasBrasindice/${this.usuarioLogadoDados.tenant}`,
            this.despesaBrasindiceSelecionada
          )
          .then(() => {
            this.erro = "";
            this.modo = "";
            this.$toasted.global.defaultSuccess();

            this.retornar();
          })
          .catch((error) => (this.erro = errors.tratarErros(error)));
      }
    },
    cancelarOperacao() {
      this.retornar();
    },
    verificarCamposPreenchidos() {
      if (this.despesaBrasindiceSelecionada.medicamento == null) {
        this.$toasted.show("Medicamento deve ser informado.", {
          type: "info",
          icon: "times",
        });

        return false;
      }

      if (this.despesaBrasindiceSelecionada.codigoTiss == null) {
        this.$toasted.show("Código TISS deve ser informado.", {
          type: "info",
          icon: "times",
        });

        return false;
      }

      if (this.despesaBrasindiceSelecionada.tipoPreco == null) {
        this.$toasted.show("Tipo do Preço deve ser selecionado.", {
          type: "info",
          icon: "times",
        });

        return false;
      }

      if (
        this.despesaBrasindiceSelecionada.tipoPreco == "PMC" &&
        this.despesaBrasindiceSelecionada.preco <= 0
      ) {
        this.$toasted.show("Preço R$ PMC deve ser informado.", {
          type: "info",
          icon: "times",
        });

        return false;
      }

      if (this.despesaBrasindiceSelecionada.qtde <= 0) {
        this.$toasted.show(
          "Campo 'Com' deve ser informado com a quantidade na Embalagem.",
          { type: "info", icon: "times" }
        );

        return false;
      }

      if (this.despesaBrasindiceSelecionada.coef <= 0) {
        this.$toasted.show(
          "Campo 'Coef' deve ser informado para cálculo do preço Faturado.",
          { type: "info", icon: "times" }
        );

        return false;
      }

      if (
        this.despesaBrasindiceSelecionada.tipoPreco == "PFB" &&
        this.despesaBrasindiceSelecionada.precoFabrica <= 0
      ) {
        this.$toasted.show("Preço Fábrica deve ser informado.", {
          type: "info",
          icon: "times",
        });

        return false;
      }

      return true;
    },
  },
  created() {
    this.showMenu(false);
    this.carregarOperadoras(this.usuarioLogadoDados.tenant, "S", 0);

    if (this.despesaBrasindiceSelecionada.id == undefined) {
      this.despesaBrasindiceSelecionada.operadoraId = 0;
      this.despesaBrasindiceSelecionada.operadora = "";
      this.despesaBrasindiceSelecionada.precoCongelado = "N";
      this.despesaBrasindiceSelecionada.datExclusao = null;
      this.despesaBrasindiceSelecionada.codigoMedicamento = null;
      this.despesaBrasindiceSelecionada.medicamento = null;
      this.despesaBrasindiceSelecionada.codigoApresentacao = null;
      this.despesaBrasindiceSelecionada.apresentacao = null;
      this.despesaBrasindiceSelecionada.codigoTuss = null;
      this.despesaBrasindiceSelecionada.codigoTiss = null;
      this.despesaBrasindiceSelecionada.tipoPreco = null;
      this.despesaBrasindiceSelecionada.eaN13 = null;
      this.despesaBrasindiceSelecionada.restritoHospitalar = "N";
      this.despesaBrasindiceSelecionada.preco = 0;
      this.despesaBrasindiceSelecionada.qtde = 0;
      this.despesaBrasindiceSelecionada.precoUnitario = 0;
      this.despesaBrasindiceSelecionada.coef = 1;
      this.despesaBrasindiceSelecionada.precoFaturado = 0;
      this.despesaBrasindiceSelecionada.precoFabrica = 0;
      this.despesaBrasindiceSelecionada.precoUnitarioFabrica = 0;
      this.despesaBrasindiceSelecionada.precoFaturadoFabrica = 0;

      this.incluirRegistro();
    }

    //---------------------------------------------------------------------------------- armazenar valores para recalculos
    this.qtde = this.despesaBrasindiceSelecionada.qtde;
    this.preco = this.despesaBrasindiceSelecionada.preco;
    this.precoFabrica = this.despesaBrasindiceSelecionada.precoFabrica;

    this.precoUnitario = this.despesaBrasindiceSelecionada.precoUnitario;
    this.precoUnitarioFabrica = this.despesaBrasindiceSelecionada.precoUnitarioFabrica;
    this.coef = this.despesaBrasindiceSelecionada.coef;
  },
};
</script>

<style scoped>
label {
  font-weight: 100%;
  font-size: 0.8rem;
  margin-top: 3px;
  margin-bottom: 1px;
}
</style>
