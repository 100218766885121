<template>
  <div id="frmAgendaContatos">
    <div class="cabecalho">
      <div class="coluna1">
        <button @click="retornar" class="btn btn-sm btn-primary mr-10"><i class="fa fa-arrow-left"/></button>
      </div>
      <div class="coluna2">
        <span>Agenda de Contatos - Grupos</span>
      </div>
      <div class="coluna3"/>
    </div>    

    <div class="container-fluid">
      <div class="row"  v-if="modo==''">
        <div class="col-12">
          <button
            class="btn btn-sm btn-info"
            @click="incluirRegistro()"
          >Incluir Novo Grupo de Contatos</button>
          <hr />
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <div class="registro" v-if="modo!=''" id="adicionarItem">
        <div class="row" v-if="modo=='Incluir'">
          <div class="col">
            <h4 style="text-align:left">Incluir Novo Grupo de Contatos</h4>
            <hr />
          </div>
        </div>

        <div class="row">
          <div class="form-group col-lg-1">
            <label for="id" class="control-label">Id:</label>
            <input
              type="text"
              class="form-control"
              disabled
              id="id"
              name="id"
              v-model="agendaContato.id"
            />
          </div>

          <div class="form-group col-lg-5">
            <label for="descricao" class="control-label">Grupo:</label>
            <input
              type="text"
              class="form-control"
              id="descricao"
              name="descricao"
              v-model="agendaContato.descricao"
            />
          </div>
        </div>
        <hr />
        <div class="barraopcoescadastro">
          <button
            id="Alterar"
            :disabled="modo=='Incluir' || modo=='Alterar'"
            @click="alterarRegistro()"
            class="btn btn-sm btn-secondary mr-2"
          >Alterar</button>
          <button
            id="Cancelar"
            @click="cancelarOperacao()"
            class="btn btn-sm btn-secondary mr-2"
          >Cancelar</button>
          <button
            id="Gravar"
            :disabled="!liberarCampos"
            @click="gravarRegistro()"
            class="btn btn-sm btn-secondary"
          >Gravar</button>
        </div>
      </div>

      <template v-if="this.erro!=''">
        <p class="msgError">Erro durante Operação: {{ this.erro }}</p>
      </template>

      <div style="display:flex; flex-direction:row; justify-content:center">
        <b-spinner v-if="loading" label="carregando..."/>
        <table class="table table-sm table-hover col-lg-6" v-if="!loading">
          <thead class="thead-light">
            <th>Id</th>
            <th>Grupo</th>
            <th>Ações</th>
          </thead>
          <tbody>
            <tr 
              v-for="agendaContato in agendaContatos"
              :key="agendaContato.id"
            >
              <td>{{agendaContato.id}}</td>
              <td>{{agendaContato.descricao}}</td>
              <td>
                <b-button variant="warning" size="sm" class="mr-2" @click="selecionouAgendaContato(agendaContato)">
                  <i class="fa fa-pencil"/>
                </b-button>
                <b-button variant="danger" size="sm" class="mr-2" @click="excluirRegistroSelecionado(agendaContato)">
                  <i class="fa fa-trash"/>
                </b-button>
                <b-button variant="info" size="sm" @click="selecionouItens(agendaContato)">
                  Contatos
                </b-button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="Mensagem">
      <b-modal id="modal-mensagem" centered title="DoctorOn">
        <p class="my-4">{{mensagem}}</p>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import agendaContatos from "@/mixins/agendaContatos";
import global from "@/mixins/global";
import errors from "@/utils/errors";

export default {
  name : 'frmAgendaContatos',
  mixins : [agendaContatos, global],
  computed: {
    ...mapGetters({
      usuarioLogadoDados : "global/getUsuarioLogadoDados"
    }),
    liberarCampos() {
      return this.modo == "Incluir" || this.modo == "Alterar" ? true : false;
    }
  },
  data() {
    return {
      loading: true,
      erro: "",
      modo: "",
      agendaContato: {},
      mensagem : "",
    }
  },
  methods: {
    ...mapActions({
      setAgendaContatoSelecionada : "agendaContatos/setAgendaContatoSelecionada"
    }),
    retornar() {
      this.$router.go(-1);
    },
    selecionouAgendaContato(agendaContato) {
      this.agendaContato = {...agendaContato}
      this.modo = "Registro";
    },
    incluirRegistro() {
      if (this.modo == "" && !this.verificarPermissao('Incluir')) {
        return
      }

      this.agendaContato = {}
      this.modo = this.modo == "" ? "Incluir" : "";
    },
    alterarRegistro() {
      if (!this.verificarPermissao('Alterar')) {
        return
      }

      this.modo = 'Alterar';
    },
    selecionouItens(agendaContato) {
      this.setAgendaContatoSelecionada(agendaContato);
      this.$router.push('/frmAgendaContatosItens');
    },
    gravarRegistro() {
      if (!this.verificarCamposPreenchidos()) {
        return;
      }

      if (this.modo=="Alterar") {
        this.agendaContato.operadorAlteracaoId = this.usuarioLogadoDados.profissionalId;

        this.$http
          .put(`/v1.0/agendacontatos/${this.usuarioLogadoDados.tenant}/${this.agendaContato.id}`, this.agendaContato)
          .then(() => {
            this.erro = "";
            this.limparCampos();
            this.$toasted.global.defaultSuccess();
            this.carregarAgendaContatos(this.usuarioLogadoDados.tenant);
          })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          })
          .finally(() => {});
      } else {
        this.agendaContato.operadorCadastroId = this.usuarioLogadoDados.profissionalId;

        this.$http
          .post(`/v1.0/agendacontatos/${this.usuarioLogadoDados.tenant}`, this.agendaContato)
          .then(() => {
            this.erro = "";
            this.limparCampos();
            this.$toasted.global.defaultSuccess();
            this.carregarAgendaContatos(this.usuarioLogadoDados.tenant);
          })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          })
          .finally(() => {});
      }
      
    },
    excluirRegistroSelecionado(agendaContato) {
      if (!this.verificarPermissao('Excluir')) {
        return
      }

      this.$bvModal
        .msgBoxConfirm("Excluir Registro Selecionado?", {
          title: "Atenção",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            this.$http
              .delete(`v1.0/agendacontatos/${this.usuarioLogadoDados.tenant}/${agendaContato.id}/${this.usuarioLogadoDados.profissionalId}`)
              .then(() => {
                this.erro = "";
                this.$toasted.global.defaultSuccess();
                this.carregarAgendaContatos(this.usuarioLogadoDados.tenant);
              })
              .catch(error => {
                this.erro = errors.tratarErros(error);
              })
              .finally(() => {});
          }
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        });
    },
    cancelarOperacao() {
      this.agendaContato = {}
      this.modo = "";
      this.erro = "";
    },
    limparCampos() {
      this.agendaContato = {}

      if (this.modo!='Incluir') {
        this.modo = "";
      }
    },
    verificarCamposPreenchidos() {
      if (this.agendaContato.descricao == "" || this.agendaContato.descricao == undefined) {
          this.$toasted.show("Digite o Grupo de Contato", {type : "info", icon : "times"});
          return false;
      }

      return true;
    },
    verificarPermissao(operacao) {
      if (!errors.verificarPermissao(this.usuarioLogadoDados, this.$store.state.global.grauPermissaoMinimo, operacao)) {
        let descricaoOperacao = operacao == "Incluir" ? "Inclusão" : operacao == "Alterar" ? "Alteração" : "Exclusão";

        this.mensagem = `${this.usuarioLogadoDados.profissional.nome}, você não possui permissão para realizar ${descricaoOperacao} com esse Perfil.`;
        this.$bvModal.show("modal-mensagem");

        return false;
      }

      return true;
    }
  },
  created() {
    this.showMenu(false);
    this.carregarAgendaContatos(this.usuarioLogadoDados.tenant);
  },
  beforeRouteLeave(to, from, next) {
    if (this.modo=="Incluir" || this.modo=="Alterar") {
      this.$bvModal
        .msgBoxConfirm("Tem certeza que deseja sair sem Gravar os Dados?", {
          title: "Atenção",
          size: "sm",
          buttonSize: "sm",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            next(true);
          } else {
            next(false);
          }
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        });
    } else {
      next(true);
    }
  }
}
</script>

<style scoped>
</style>
