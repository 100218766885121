<template>
  <div id="CadPaciente">
    <div class="cabecalho">
      <button @click="retornar" class="btn btn-sm btn-primary mr-10"><i class="fa fa-arrow-left"/></button>
      <!-- <button
        @click="componente='cadPacienteDados'"
        class="btn btn-sm btn-info ml-5"
      >Dados Pessoais</button>
      <button
        @click="componente='cadPacienteSaude'"
        class="btn btn-sm btn-info ml-2"
      >Informações Saúde</button>
      <button
        @click="componente='cadPacienteInformacoes'"
        class="btn btn-sm btn-info ml-2"
      >Outras Informações</button>
      <button
        @click="componente='cadPacienteResponsavel'"
        class="btn btn-sm btn-info ml-2"
      >Responsável/Filiação</button> -->
    </div>
    
    <div class="corpo">
      <!-- <component :is="componente" /> -->
      <div class="tituloPagina">
          <span v-if="pacienteSelecionado.id>0">{{pacienteSelecionado.nome}}</span>
          <span v-else>Cadastro de Novo Paciente</span>
          <hr/>
      </div>

      <b-card no-body style="padding:0px">
        <b-tabs card>
          <b-tab title="Dados Pessoais" active>
              <cadPacienteDados/>
          </b-tab>
          <b-tab title="Informações Saúde">
              <cadPacienteSaude/>
          </b-tab>
          <b-tab title="Outras Informações">
              <cadPacienteInformacoes/>
          </b-tab>
          <b-tab title="Responsável/Filiação">
              <cadPacienteResponsavel/>
          </b-tab>
        </b-tabs>
      </b-card>

      <hr style="margin:0px"/>

      <p v-if="erro" style="background-color:red; color:white; text-align:center">{{erro}}</p>

      <div class="container-fluid">
        <div class="barraopcoescadastro">
          <button
            id="Alterar"
            :disabled="true || modo=='Incluir' || modo=='Alterar'"
            @click="alterarRegistro()"
            class="btn btn-sm btn-secondary mr-2"
          >Alterar</button>
          <button
            id="Cancelar"
            @click="cancelarOperacao()"
            class="btn btn-sm btn-secondary mr-2"
          >Cancelar</button>
          <button
            id="Gravar"
            :disabled="!liberarCampos"
            @click="gravarRegistro()"
            class="btn btn-sm btn-secondary mr-2"
          >Gravar</button>
          <button class="btn btn-sm btn-info mr-2" @click="imprimirFicha()">Imprimir Ficha</button>
          <button class="btn btn-sm btn-info mr-2" @click="capturarFotoPaciente()">Foto</button>
          <router-link class="btn btn-sm btn-info mr-2" tag="button" to="/frmAgendaPaciente">Atendimentos</router-link>
          <router-link class="btn btn-sm btn-info mr-2" tag="button" to="/frmControleAtendimentos">Controle de Atendimentos</router-link>
          <button
            class="btn btn-sm btn-info mr-2"
            @click="lancamentosPaciente()"
            :disabled="pacienteSelecionado.id==0"
          >Guias Atendimentos</button>
          <button class="btn btn-sm btn-info mr-2" @click="recebimentosDoPaciente()">Recebimentos</button>
          <router-link class="btn btn-sm btn-info mr-2" tag="button" to="/frmPendencias">Pendências</router-link>
          <router-link class="btn btn-sm btn-info mr-2" tag="button" to="/frmPacienteSessoes">Sessões</router-link>
          <button class="btn btn-sm btn-info mr-2" @click="PronturarioDoPaciente()">Prontuários</button>
          <button 
            class="btn btn-sm btn-info mr-2" 
            @click="acompanhamentosPaciente()" 
            :disabled="pacienteSelecionado.id==0"
            >Acompanhamentos</button>
        </div>
      </div>
    </div>
    <div class="Mensagem">
      <b-modal id="modal-mensagem" centered title="DoctorOn">
        <p class="my-4">{{mensagem}}</p>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import cadPacienteDados from "./cadPacienteDados.vue";
import cadPacienteSaude from "./cadPacienteSaude.vue";
import cadPacienteInformacoes from "./cadPacienteInformacoes.vue";
import cadPacienteResponsavel from "./cadPacienteResponsavel.vue";
import prontuarios from "@/mixins/prontuarios.js";
import relatoriosAtendimentos from "@/mixins/relatoriosAtendimentos.js";
import errors from "@/utils/errors";

export default {
  mixins : [prontuarios, relatoriosAtendimentos],
  components: {
    cadPacienteDados,
    cadPacienteSaude,
    cadPacienteInformacoes,
    cadPacienteResponsavel
  },
  computed: {
    ...mapGetters({
      pacienteSelecionado: "pacientes/getPacienteSelecionado",
      pacienteModulo : "pacientes/getModulo",
      usuarioLogadoDados : "global/getUsuarioLogadoDados",
    }),
    liberarCampos() {
      return this.modo == "Incluir" || this.modo == "Alterar" ? true : false;
    }
  },
  data() {
    return {
      erro: "",
      modo: "",
      mensagem : "",
      retornando : false
    }
  },
  methods: {
    ...mapActions({
      limparRegistroSelecionado: "pacientes/limparRegistroSelecionado",
      setModo: "pacientes/setModo",
      setGuiaChamadoPor : "guias/setChamadoPor",
      setParametrosLanctoGuia : "guias/setParametrosLanctoGuia",
      setOrigemRecebimento : "recebimentos/setOrigemRecebimento",
      formLeave: "pacientes/formLeave"
    }),
    retornar() {
      this.retornando = true;
      this.$router.go(-1);
    },
    gravarRegistro() {
      if (!this.verificarCamposPreenchidos()) {
        return;
      }

      if (this.pacienteSelecionado.cpfNumero == "") {
        this.pacienteSelecionado.cpfNumero = null
      }

      if (this.modo=="Alterar") {
        this.pacienteSelecionado.operadorAlteracaoId = this.usuarioLogadoDados.profissionalId;

        this.$http
          .put(
            `/v1.0/pacientes/${this.usuarioLogadoDados.tenant}/${this.pacienteSelecionado.id}`,
            this.pacienteSelecionado
          )
          .then(() => {
            this.erro = "";
            this.modo = "Registro";
            this.setModo(this.modo);

            this.$toasted.global.defaultSuccess();
          })
          .catch(error => this.erro = errors.tratarErros(error))
          .finally(() => {});
      } else {
        this.pacienteSelecionado.operadorCadastroId = this.usuarioLogadoDados.profissionalId;

        this.$http
          .post(`/v1.0/pacientes/${this.usuarioLogadoDados.tenant}`, this.pacienteSelecionado)
          .then(() => {
            this.erro = "";
            this.modo = "Incluir";
            this.setModo(this.modo);

            this.limparCampos();
            this.$toasted.global.defaultSuccess();
          })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          })
          .finally(() => {});
      }
    },
    alterarRegistro() {
      if (!this.verificarPermissao('Alterar')) {
        return
      }
      
      this.modo = "Alterar";
      this.setModo(this.modo);
    },
    cancelarOperacao() {
      if (this.modo=="Alterar") {
        this.modo = "Registro";
        this.setModo(this.modo); 
        this.erro = "";
      } else {
        this.modo = "";
        this.setModo(this.modo); 
        this.retornar();
      }
    },
    limparCampos() {
      this.limparRegistroSelecionado();
    },
    verificarCamposPreenchidos() {
      if (this.pacienteSelecionado.nome == "" || this.pacienteSelecionado.nome == undefined) {
        this.$toasted.show("Digite o Nome do Paciente", {type : 'info', icon : 'times'});
        return false;
      }

      if (this.pacienteSelecionado.ativo == "" || this.pacienteSelecionado.ativo == undefined) {
        this.$toasted.show("Selecione se o Paciente está Ativo no Sistema", {type : 'info', icon : 'times'});
        return false;
      }

      if (this.pacienteModulo!="Agenda") {
        if (this.pacienteSelecionado.bloqueado == "" || this.pacienteSelecionado.bloqueado == undefined) {
          this.$toasted.show("Selecione se o Paciente está Bloqueado no Sistema", {type : 'info', icon : 'times'});
          return false;
        }
  
        if (this.pacienteSelecionado.sexo == 0 || this.pacienteSelecionado.sexo == undefined) {
          this.$toasted.show("Selecione o Sexo do Paciente", {type : 'info', icon : 'times'});
          return false;
        }
  
        if (this.pacienteSelecionado.estadoCivil == 0 || this.pacienteSelecionado.estadoCivil == undefined) {
          this.$toasted.show("Selecione Estado Civil do Paciente", {type : 'info', icon : 'times'});
          return false;
        }
  
        if (this.pacienteSelecionado.uf == 0|| this.pacienteSelecionado.uf == undefined) {
          this.$toasted.show("Selecione o UF do Paciente", {type : 'info', icon : 'times'});
          return false;
        }
      }

      if (this.pacienteSelecionado.celularDdd == "" || this.pacienteSelecionado.celularDdd == undefined) {
        this.$toasted.show("Digite o DDD do Celular do Paciente", {type : 'info', icon : 'times'});
        return false;
      }

      if (this.pacienteSelecionado.celular == "" || this.pacienteSelecionado.celular == undefined) {
        this.$toasted.show("Digite o Celular do Paciente", {type : 'info', icon : 'times'});
        return false;
      }

      return true;
    },
    verificarPermissao(operacao) {
      if (!errors.verificarPermissao(this.usuarioLogadoDados, this.$store.state.global.grauPermissaoMinimo, operacao)) {
        let descricaoOperacao = operacao == "Incluir" ? "Inclusão" : operacao == "Alterar" ? "Alteração" : "Exclusão";

        this.mensagem = `${this.usuarioLogadoDados.profissional.nome}, você não possui permissão para realizar ${descricaoOperacao} com esse Perfil.`;
        this.$bvModal.show("modal-mensagem");

        return false;
      }

      return true;
    },
    acompanhamentosPaciente() {
      this.$router.push("/frmAcompanhamentosPaciente");
    },
    lancamentosPaciente() {
        let parametros = {
                          paciente : {id : this.pacienteSelecionado.id, nome : this.pacienteSelecionado.nome},
                          profissionalExecutanteId : 0, 
                          profissionalSolicitanteId : 0, 
                          operadoraId : 0,
                          planoSaudeId : 0,
                          tipoLote : -1, guiaOperadora : null,
                          datAutorizacao : null, senhaAutorizacao : null, datValidadeSenha : null,

                          datAtendimento : null
                         }

       this.setParametrosLanctoGuia(parametros);
       this.setGuiaChamadoPor("Agenda");
       this.$router.push('/frmLanctoGuia');
    },
    async PronturarioDoPaciente() {
      await this.verificarExistenciaProntuariosComProfissional(this.usuarioLogadoDados.tenant, this.usuarioLogadoDados.profissionalId, this.pacienteSelecionado.id, this.usuarioLogadoDados.profissionalId);

      this.pacienteSelecionado.profissionalAgendaId = this.possuiProntuariosComProfissional == 'Possui' ? this.usuarioLogadoDados.profissionalId : 0;
      this.$router.push("/frmProntuario");
    },
    recebimentosDoPaciente() {
      let origem = {pacienteId : this.pacienteSelecionado.id, pacienteNome : this.pacienteSelecionado.nome, 
                    pacienteCPF : this.pacienteSelecionado.cpf, datAtendimento : null, chamadoPor : "CadPaciente"};
      this.setOrigemRecebimento(origem);
      this.$router.push('/frmRecebimentos');
    },
    capturarFotoPaciente() {
      this.$router.push("/fotoPaciente");
    },
    imprimirFicha() {
      // fazer um if aqui verificando se o profissionalAgendaId está preenchido para o caso de o cadastro não ter sido chamado pela agenda de atendimentos

      this.carregarFichaDoPaciente01(this.usuarioLogadoDados.tenant, this.pacienteSelecionado.id, this.pacienteSelecionado.profissionalAgendaId); //this.profissionalId
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.modo=="Incluir" || this.modo=="Alterar") {
      this.$bvModal
        .msgBoxConfirm("Tem certeza que deseja sair sem Gravar os Dados?", {
          title: "Atenção",
          size: "sm",
          buttonSize: "sm",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            if (this.modo=="Alterar") {
              this.setModo("");
              this.formLeave();
            } else {
              this.setModo("");
            }
            next(true);
          } else {
            next(false);
          }
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        });
    } else {
      if (this.pacienteModulo!="Agenda" && this.retornando) {
        this.formLeave();
      }
      next(true);
    }
  },
  created() {
    if (this.pacienteSelecionado.id==0) {
      this.modo = "Incluir"
    } else {
      this.modo = "Registro"
    }

    this.setModo(this.modo);
  }
}
</script>

<style scoped>
</style>
