<template>
  <div id="CadProfissionalMemed">
    <div class="cabecalho">
      <div class="coluna1">
        <button @click="retornar" class="btn btn-sm btn-primary mr-10"><i class="fa fa-arrow-left"/></button>
      </div>
      <div class="coluna2">
        <span>{{profissionalSelecionado.nome}}</span>
      </div>
      <div class="coluna3"/>
    </div>

    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
                <button id="consultarCidades" class="btn btn-sm btn-info mr-2" @click="consultarCidades()">Consultar Cidades</button>
                <button id="consultarEspecialidades" class="btn btn-sm btn-info mr-2" @click="consultarEspecialidades()">Consultar Especialidades</button>
                <button id="ocultarConsultas" class="btn btn-sm btn-info" @click="ocultarConsultas()">Ocultar Consultas</button>
            </div>
        </div>

        <hr/>

        <div class="row">
            <template v-if="modo=='ConsultarCidades'">
                <div class="form-group col-lg-2" >
                    <label for="cidade" class="label-control">Cidade</label>
                    <input tpye="text" id="cidade" class="form-control" v-model="localizarCidade">
                </div>
                <div class="form-group col-sm-1 col-md-1 col-lg-1" style="padding-top:25px">
                  <button id="btnFiltrarCidades" class="form-control" @click="filtrarCidades()">
                    <i class="fa fa-search"/> 
                  </button>
                </div>

                <template v-if="cidades.length>0">
                <div class="form-group col-lg-3" >
                    <label for="cidades" class="label-control">Cidades</label>
                    <select id="cidades" class="form-control" v-model="cidadeId" selected>
                        <option v-for="cidade in cidades" :key="cidade.id" :value="cidade.id">
                            {{cidade.attributes.uf}} - {{cidade.attributes.nome}} - {{cidade.id}}
                        </option>
                    </select>
                </div>
                </template>

                <b-spinner ml-2 mt-5 v-if="loadingMemedCidades" label="carregando..."/>
            </template>
        
            <template v-if="modo=='ConsultarEspecialidades'">
                <div class="form-group col-lg-3" >
                    <label for="especialidades" class="label-control">Especialidade</label>
                    <select id="especialidades" class="form-control" v-model="especialidadeId" selected>
                        <option v-for="especialidade in especialidades" :key="especialidade.id" :value="especialidade.id">
                            {{especialidade.attributes.grupo}} - {{especialidade.attributes.nome}} - {{especialidade.id}}
                        </option>
                    </select>
                </div>
                <b-spinner v-if="loadingMemedEspecialidades" margin-top="2" label="carregando..."/>
            </template>
        </div>

        <hr/>

        <template v-if="profissionalAtendimentoComplementoSelecionado.memedProfissionalId == undefined">
            <div class="row">
                <div class="form-group col-lg-2">
                    <label for="nome" class="label-control">Nome</label>
                    <input type="text" id="nome" class="form-control" v-model="nome">
                </div>
                <div class="form-group col-lg-2">
                    <label for="sobrenome" class="label-control">Sobrenome</label>
                    <input type="text" id="sobrenome" class="form-control" v-model="sobrenome">
                </div>
                <div class="form-group col-lg-1">
                    <label for="cidadeId" class="label-control">Id Cidade</label>
                    <input type="text" id="cidadeId" class="form-control" v-model="cidadeId">
                </div>
                <div class="form-group col-lg-1">
                    <label for="especialidadeId" class="label-control">Id Especialidade</label>
                    <input type="text" id="especialidadeId" class="form-control" v-model="especialidadeId">
                </div>
            </div>
            <div class="row">
                <div class="form-group col-lg-1">
                    <label for="sexo" class="label-control">Sexo</label>
                    <select id="sexo" class="form-control" disabled v-model="profissionalGeralComplementoSelecionado.sexo" selected>
                        <option v-for="sexo in sexos" :key="sexo.opcao" :value="sexo.opcao">
                            {{sexo.valor}}
                        </option>
                    </select>
                </div>
                <div class="form-group col-lg-2">
                    <label for="datNascimento" class="label-control">Nascimento</label>
                    <input type="date" id="datNascimento" class="form-control" disabled v-model="profissionalGeralComplementoSelecionado.datNascimento">
                </div>
                <div class="form-group col-lg-3">
                    <label for="email" class="label-control">email</label>
                    <input type="text" id="email" class="form-control" disabled v-model="profissionalGeralComplementoSelecionado.email">
                </div>
                <div class="form-group col-lg-12">
                    <button class="btn btn-sm btn-info form-control" @click="criarProfissionalMemed()">Criar Registro Memed</button>
                    <button class="btn btn-sm btn-info form-control" @click="visualizarProfissionalMemed()"> Temp Visualizar Registro Memed</button>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="row">
                <div class="form-group col-lg-1">
                    <label for="memedExternalId" class="label-control">External Id</label>
                    <input type="text" id="memedExternalId" class="form-control" disabled v-model="profissionalAtendimentoComplementoSelecionado.memedExternalId"/>
                </div>
                <div class="form-group col-lg-1">
                    <label for="memedProfissionalId" class="label-control">Id</label>
                    <input type="text" id="memedProfissionalId" class="form-control" disabled v-model="profissionalAtendimentoComplementoSelecionado.memedProfissionalId"/>
                </div>
                <div class="form-group col-lg-1">
                    <label for="memedCidadeId" class="label-control">Cidade Id</label>
                    <input type="text" id="memedCidadeId" class="form-control" disabled v-model="profissionalAtendimentoComplementoSelecionado.memedCidadeId"/>
                </div>
                <div class="form-group col-lg-1">
                    <label for="memedEspecialidadeId" class="label-control">Especialid.Id</label>
                    <input type="text" id="memedEspecialidadeId" class="form-control" disabled v-model="profissionalAtendimentoComplementoSelecionado.memedEspecialidadeId"/>
                </div>
                <div class="form-group col-lg-1">
                    <label for="memedDatCadastro" class="label-control">Cadastro</label>
                    <input type="text" id="memedDatCadastro" class="form-control" disabled v-model="profissionalAtendimentoComplementoSelecionado.memedDatCadastro"/>
                </div>
                <div class="form-group col-lg-5">
                    <label for="memedToken" class="label-control">Token</label>
                    <input type="text" id="memedToken" class="form-control" disabled v-model="profissionalAtendimentoComplementoSelecionado.memedToken"/>
                </div>
                <div class="form-group col-lg-12">
                    <button class="btn btn-sm btn-info form-control" @click="visualizarProfissionalMemed()">Visualizar Registro Memed</button>
                </div>
            </div>
        </template>

        <p v-if="erro!=''" style="background-color:red; color:white; text-align:center">{{erro}}</p>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import memed from "@/mixins/memed";
import global from "@/mixins/global";
import profissionalComplementos from "@/mixins/profissionalComplementos";
import errors from "@/utils/errors";
import funcoes from "@/utils/funcoes";
import constantes from "@/utils/constantes";

export default {
    mixins : [memed, profissionalComplementos, global],
    computed : {
        ...mapGetters({
            profissionalSelecionado : "profissionais/getProfissionalSelecionado",
            profissionalAtendimentoComplementoSelecionado : "profissionalComplementos/getProfissionalAtendimentoComplementoSelecionado",
            profissionalGeralComplementoSelecionado : "profissionalComplementos/getProfissionalGeralComplementoSelecionado",
            usuarioLogadoDados : "global/getUsuarioLogadoDados",
        }),
    },
    data() {
        return {
            erro : "",
            modo : "",

            loadingMemedCidades : false,
            loadedMemedCidades : false,
            loadingMemedProfissional : false,
            loadedMemedProfissional : false,

            cidadeId : 0,
            especialidadeId : 0,
            cidades : [],
            especialidades : [],
            nome : "",
            sobrenome : "",
            localizarCidade : "",
            sexos : constantes.sexo
        }
    },
    methods : {
        retornar() {
            this.$router.go(-1);
        },
        consultarCidades() {
            this.modo = "ConsultarCidades";
        },
        consultarEspecialidades() {
            this.modo = "ConsultarEspecialidades";
            if (this.especialidades.length == 0) {
                this.carregarEspecialidades();
            }
        },
        ocultarConsultas() {
            this.modo = "";
        },
        filtrarCidades() {
            this.buscarCidades(this.localizarCidade);
        },
        criarProfissionalMemed() {
            let cadastroProfissionalMemed = {
                data : {
                    type : "usuarios",
                    attributes : {
                        external_id : this.profissionalSelecionado.id,
                        nome : this.nome,
                        sobrenome : this.sobrenome,
                        data_nascimento : funcoes.mySqlToJSDateFormat(this.profissionalGeralComplementoSelecionado.datNascimento),
                        email : this.profissionalGeralComplementoSelecionado.email,
                        sexo : this.profissionalGeralComplementoSelecionado.sexo == 1 ? "M" : "F"
                    },
                    relationships : {
                        cidade : {
                            data : {
                                type : "cidades",
                                id : this.cidadeId
                            }
                        },
                        especialidade : {
                            data : {
                                type : "especialidades",
                                id : this.especialidadeId
                            }
                        }
                    }
                }
            };

            let dominio_api_memed = 'https://sandbox.api.memed.com.br';
            let api_key = 'iJGiB4kjDGOLeDFPWMG3no9VnN7Abpqe3w1jEFm6olkhkZD6oSfSmYCm';
            let secret_key = 'Xe8M5GvBGCr4FStKfxXKisRo3SfYKI7KrTMkJpCAstzu2yXVN4av5nmL';

            this.$http.post(`${dominio_api_memed}/v1/sinapse-prescricao/usuarios?api-key=${api_key}&secret-key=${secret_key}`, cadastroProfissionalMemed)
            .then(resp => {
                this.profissionalMemed = resp.data.data;

                this.erro = ""
                this.$toasted.global.defaultSuccess();

                this.profissionalAtendimentoComplementoSelecionado.operadorAlteracao = this.usuarioLogadoDados.profissionalId;
                this.profissionalAtendimentoComplementoSelecionado.MemedExternalId = this.profissionalSelecionado.id;
                this.profissionalAtendimentoComplementoSelecionado.MemedProfissionalId = this.profissionalMemed.id;
                this.profissionalAtendimentoComplementoSelecionado.MemedToken = this.profissionalMemed.token;
                this.profissionalAtendimentoComplementoSelecionado.MemedCidadeId = this.cidadeId;
                this.profissionalAtendimentoComplementoSelecionado.MemedEspecialidadeId = this.especialidadeId;
                this.profissionalAtendimentoComplementoSelecionado.MemedDatCadastro = funcoes.mySqlToJSDateFormat(Date());

                this.atualizarTokenDoProfissionalAtendimento(this.usuarioLogadoDados.tenant, 
                                                             this.profissionalAtendimentoComplementoSelecionado.id, 
                                                             this.profissionalAtendimentoComplementoSelecionado);
            })
            .catch(error => {
                this.erro = errors.tratarErros(error);
            })
            .finally(() => {

            })
        },
        visualizarProfissionalMemed() {
           this.buscarProfissionalMemed(246) 
        },
    },
    created() {
        this.showMenu(false);

        if (this.profissionalAtendimentoComplementoSelecionado.memedProfissionalId == undefined) {
           this.nome = this.profissionalSelecionado.nome;
           this.sobrenome = this.profissionalSelecionado.nome;
           this.profissionalSelecionado.datNascimento = funcoes.mySqlToJSDateFormat(this.profissionalSelecionado.datNascimento);
        }
    }
}
</script>

<style scoped>
  label {
    font-weight: 100%;
    font-size: 0.8rem;
    margin-top: 3px;
    margin-bottom: 1px;
  }
</style>
