import errors from "@/utils/errors";

export default {
    data() {
        return {
            profissionalDisponibilidades : [],
        }
    },
    methods: {
        carregarProfissionalDisponibilidades(tenant, unidadeId, profissionalId, destinado, diaSemana) {
            this.loadingProfissionalDisponibilidades = true;
            this.loadedProfissionalDisponibilidades = false;

            this.$http
              .get(`/v1.0/profissionaldisponibilidades/${tenant}/${profissionalId}/${unidadeId}/${destinado}/${diaSemana}`)
              .then(resp => {
                this.profissionalDisponibilidades = resp.data;
                this.erro = "";
                this.loadedProfissionalDisponibilidades = true;
            })
            .catch(error => {
              this.erro = errors.tratarErros(error);
              })
              .finally(() => {
                this.loadingProfissionalDisponibilidades = false;
              });
          },
    }
}
