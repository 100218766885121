const state = {
    usuarioSelecionado : {},
    definirSenha : {},
    modo : ""
}

const getters = {
    getUsuarioSelecionado(state) {
        return state.usuarioSelecionado;
    },

    getDefinirSenha(state) {
        return state.definirSenha;
    },

    getModo(state) {
        return state.modo;
    }
}

const mutations = {
    setUsuarioSelecionado(state, usuario) {
        state.usuarioSelecionado = {...usuario}
    },

    setDefinirSenha(state, definirSenha) {
        state.definirSenha = {...definirSenha};
    },

    setModo(state, modo) {
        state.modo = modo;
    },
}

const actions = {
    setUsuarioSelecionado({commit}, usuario) {
        commit("setUsuarioSelecionado", usuario);
    },

    setDefinirSenha({commit}, definirSenha) {
        commit("setDefinirSenha", definirSenha);
    },

    setModo({commit}, modo) {
        commit("setModo", modo);
    },

    formLeave({commit}) {
        commit("setUsuarioSelecionado", null);
        commit("setDefinirSenha", null)
        commit("setModo", "");
    }
}

export default {
    namespaced : true,
    state,
    getters,
    mutations,
    actions
}