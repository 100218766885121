import { render, staticRenderFns } from "./opcRelatoriosFinanceiros.vue?vue&type=template&id=5dbdb494&scoped=true&"
import script from "./opcRelatoriosFinanceiros.vue?vue&type=script&lang=js&"
export * from "./opcRelatoriosFinanceiros.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5dbdb494",
  null
  
)

export default component.exports