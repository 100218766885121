const lerArquivo = function() {
    let retornar = "";

    const fs = require('fs');

    fs.readFile('./teste.txt', 'utf-8', (err, conteudo) => {
      if (err!=null) {
        this.$toasted.show("erro: "+err, {type: "info", icon : "times"})
        return ""; 
      }

      retornar = conteudo;
    })

    return retornar;
}

export default {
    lerArquivo
}