<template>
  <div>
    <div class="cabecalho">
      <div class="coluna1">
        <button @click="retornar" class="btn btn-sm btn-primary mr-10"><i class="fa fa-arrow-left"/></button>
      </div>
      <div class="coluna2">
        <span>{{fluxoSelecionado.descricao}} - Contas</span>
      </div>
      <div class="coluna3"/>
    </div>  

    <div class="container-fluid">
      <template v-if="modo==''">
        <button  class="btn btn-sm btn-info ml-2" @click="incluirRegistro()">Incluir Nova Conta</button>
      </template>
      <template v-else>
        <div class="registro">
          <div class="row">
            <div class="form-group col-lg-1">
              <label for="id" class="control-label">Id</label>
              <input type="text" id="id" disabled class="form-control" v-model="conta.id"/>
            </div>
            <div class="form-group col-lg-3">
              <label for="descricao" class="control-label">Descrição</label>
              <input type="text" id="descricao" :disabled="!liberarCampos" class="form-control" v-model="conta.descricao"/>
            </div>
            <div class="form-group col-lg-2">
              <label for="tipo" class="control-label">Tipo</label>
              <select id="tipo" class="form-control" :disabled="!liberarCampos" v-model="conta.tipo" selected>
                  <option value="CX">Caixa</option>
                  <option value="BC">Banco</option>
                  <option value="PD">Pré-Datados</option>
                  <option value="PF">Pessoa Física</option>
                  <option value="PJ">Pessoa Jurídica</option>
              </select>
            </div>
            <div class="form-group col-lg-1">
              <label for="saldoAbertura" class="control-label">R$ Abertura</label>
              <input type="number" id="saldoAbertura" :disabled="!liberarCampos || modo!='Incluir'" class="form-control" v-model.number="conta.saldoAbertura"/>
            </div>
            <!-- <div class="form-group col-lg-1">
              <label for="saldoAtual" class="control-label">R$ Atual</label>
              <input type="number" id="saldoAtual" :disabled="!liberarCampos || modo!='Incluir'" class="form-control" v-model="conta.saldoAtual"/>
            </div> -->
          </div>

          <hr />
          <div class="barraopcoescadastro">
            <button
              id="Excluir"
              :disabled="modo=='Incluir'"
              @click="excluirRegistro()"
              class="btn btn-sm btn-danger ml-2"
            >Excluir</button>
            <button
              id="Alterar"
              :disabled="modo=='Incluir' || modo=='Alterar'"
              @click="alterarRegistro()"
              class="btn btn-sm btn-secondary ml-2"
            >Alterar</button>
            <button
              id="Cancelar"
              @click="cancelarOperacao()"
              class="btn btn-sm btn-secondary ml-2"
            >Cancelar</button>
            <button
              id="Gravar"
              :disabled="!liberarCampos"
              @click="gravarRegistro()"
              class="btn btn-sm btn-secondary ml-2"
            >Gravar</button>
          </div>
        </div>
      </template>
    </div>

    <div class="container-fluid contas-grid">
        <cmpConta v-for="conta in contas" :key="conta.id" :conta="conta" @selecionouLancamentos="lancamentos(conta)" @selecionouConta="selecionouConta(conta)"/>
    </div>
    <div class="Mensagem">
      <b-modal id="modal-mensagem" centered title="DoctorOn">
        <p class="my-4">{{mensagem}}</p>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex"
import contas from "@/mixins/contas"
import global from "@/mixins/global"
import cmpConta from "./cmpConta.vue"
import errors from "@/utils/errors"

export default {
    components : {cmpConta},
    mixins : [contas, global],
    computed : {
      ...mapGetters({
        fluxoSelecionado : "fluxosCaixa/getFluxoSelecionado",
        usuarioLogadoDados : "global/getUsuarioLogadoDados"
      }),
      liberarCampos() {
        return this.modo == "Incluir" || this.modo == "Alterar" ? true : false;
      }
    },
    data() {
      return {
        conta : {},
        modo : "",
        mensagem : "",
      }
    },
    methods : {
      ...mapActions({
          setContaSelecionada : "contas/setContaSelecionada",
      }),
      retornar() {
          this.$router.go(-1);
      },
      incluirRegistro() {
        if (!this.verificarPermissao('Incluir')) {
          return
        }
        
        this.conta = {}
        this.modo = "Incluir";
      },
      alterarRegistro() {
        if (!this.verificarPermissao('Alterar')) {
          return
        }

        this.modo = 'Alterar';
      },
      selecionouConta(conta) {
          this.conta = {...conta}
          this.modo = "Registro";
      },
      excluirRegistro() {
        if (!this.verificarPermissao('Excluir')) {
          return
        }
        
        this.$bvModal
          .msgBoxConfirm("Excluir Conta Selecionada?", {
            title: "Atenção",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "Sim",
            cancelTitle: "Não",
            footerClass: "p-2",
            hideHeaderClose: true,
            centered: true
          })
          .then(value => {
            if (value) {
              this.$http
                .delete(`v1.0/contas/${this.usuarioLogadoDados.tenant}/${this.conta.id}/${this.usuarioLogadoDados.profissionalId}`)
                .then(() => {
                  this.erro = "";
                  this.limparCampos();
                  this.carregarContasByFluxo(this.usuarioLogadoDados.tenant, this.fluxoSelecionado.id, 0);
                  this.$toasted.global.defaultSuccess();
                })
                .catch(error => {
                  this.erro = errors.tratarErros(error);
                })
                .finally(() => {});
            }
          })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          });
      },
      cancelarOperacao() {
        this.conta = {}
        this.modo = "";
        this.erro = "";
      },

      gravarRegistro() {
          if (!this.verificarCamposPreenchidos()) {
              return
          }

          if (this.modo=="Alterar") {
              this.conta.operadorAlteracaoId = this.usuarioLogadoDados.profissionalId;
        
              this.$http.put(`/v1.0/contas/${this.usuarioLogadoDados.tenant}/${this.conta.id}`, this.conta)
              .then(() => {
                  this.modo = "";
                  this.carregarContasByFluxo(this.usuarioLogadoDados.tenant, this.fluxoSelecionado.id, 0);

                  this.$toasted.global.defaultSuccess();
                  this.erro = "";
              })
              .catch(error => this.erro = errors.tratarErros(error))
          } else {
              this.conta.fluxoId = this.fluxoSelecionado.id;
              this.conta.operadorCadastroId = this.usuarioLogadoDados.profissionalId;

              this.conta.saldoAtual = this.conta.saldoAbertura;
              this.conta.datPrimeiroLancamento = null;
              this.conta.datUltimoLancamento = null;
              this.conta.operadorAlteracaoId = null;

              this.$http.post(`/v1.0/contas/${this.usuarioLogadoDados.tenant}`, this.conta)
              .then(() => {
                  this.modo = "";
                  this.carregarContasByFluxo(this.usuarioLogadoDados.tenant, this.fluxoSelecionado.id, 0);
                    
                  this.$toasted.global.defaultSuccess();
                  this.erro = "";
              })
              .catch(error => this.erro = errors.tratarErros(error))
          }
        },
        verificarCamposPreenchidos() {
          if (this.conta.descricao === "" || this.conta.descricao === undefined) {
            this.$toasted.show("Digite a Descrição da Conta", {type : 'info', icon : 'times'});
            return false;
          }

          if (this.conta.tipo === "" || this.conta.tipo === undefined) {
            this.$toasted.show("Selecione o Tipo da Conta", {type : 'info', icon : 'times'});
            return false;
          }

          return true;
        },
        verificarPermissao(operacao) {
          if (!errors.verificarPermissao(this.usuarioLogadoDados, this.$store.state.global.grauPermissaoMinimo, operacao)) {
            let descricaoOperacao = operacao == "Incluir" ? "Inclusão" : operacao == "Alterar" ? "Alteração" : "Exclusão";

            this.mensagem = `${this.usuarioLogadoDados.profissional.nome}, você não possui permissão para realizar ${descricaoOperacao} com esse Perfil.`;
            this.$bvModal.show("modal-mensagem");

            return false;
          }

          return true;
        },
        limparCampos() {
          this.conta = {}

          if (this.modo!='Incluir') {
            this.modo = "";
          }
        },
        lancamentos(conta) {
          this.setContaSelecionada(conta);
          this.$router.push('/frmLancamentos')
        }
    },
    created() {
      this.showMenu(false);
      this.carregarContasByFluxo(this.usuarioLogadoDados.tenant, this.fluxoSelecionado.id, 0);
    }
}
</script>

<style scoped>
    .contas-grid {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
    }
    label {
      font-weight: 100%;
      font-size: 0.8rem;
      margin-top: 3px;
      margin-bottom: 1px;
    }
</style>
