<template>
    <div>
        <div class="cabecalho">
          <div class="coluna1">
            <button @click="retornar" class="btn btn-sm btn-primary mr-10"><i class="fa fa-arrow-left"/></button>
          </div>
          <div class="coluna2">
            <span>Recebimentos - {{origemRecebimento.pacienteNome}} - Prontuário {{origemRecebimento.pacienteId}}</span>
          </div>
          <div class="coluna3"/>
        </div>  

        <div class="container-fluid">
            <div id="erro">
              <p v-if="erro" style="background-color:red; color:white; text-align:center">{{erro}}</p>
            </div>

            <b-btn variant="info" size="sm" @click="incluirRegistroRecebimento()">Novo Recebimento</b-btn>
            <div style="display:flex; flex-direction:row; justify-content:center; height:520px">
              <b-spinner v-if="loadingRecebimentosDoPaciente" label="carregando..."/>
              <b-table
                v-if="loadedRecebimentosDoPaciente"
                class="col-lg-10"
                hover
                striped
                small
                sort-icon-left
                :items="recebimentosDoPaciente"
                :fields="camposTabela"
              >
                <template v-slot:cell(actions)="row">
                  <b-button variant="warning" size="sm" @click="selecionouRegistro(row.item)" class="mr-2">
                    <i class="fa fa-pencil"/>
                  </b-button>
                  <b-button v-if="row.item.datRecebimento==null" variant="danger" size="sm" @click="excluirRegistroSelecionado(row.item)" class="mr-2">
                    <i class="fa fa-trash"/>
                  </b-button>
                </template>

                <template v-slot:cell(datAtendimento)="datAtendimento">
                  {{datAtendimento.value | formatDate}}
                </template>

                <template v-slot:cell(datRecebimento)="datRecebimento">
                  {{datRecebimento.value | formatDate}}
                </template>

                <template v-slot:cell(valTratamentos)="valTratamentos">
                  {{valTratamentos.value | formatValor}}
                </template>

                <template v-slot:cell(valDespesas)="valDespesas">
                  {{valDespesas.value | formatValor}}
                </template>

                <template v-slot:cell(valPacotes)="valPacotes">
                  {{valPacotes.value | formatValor}}
                </template>

                <template v-slot:cell(valDescontos)="valDescontos">
                  {{valDescontos.value | formatValor}}
                </template>

                <template v-slot:cell(valRecebido)="valRecebido">
                  {{valRecebido.value | formatValor}}
                </template>
              </b-table>
            </div>
            <b-pagination v-if="loadedRecebimentosDoPaciente" class="justify-content-center" v-model="page" :total-rows="count" :per-page="limit"/>
        </div>
        <div class="Mensagem">
          <b-modal id="modal-mensagem" centered title="DoctorOn">
            <p class="my-4">{{mensagem}}</p>
          </b-modal>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import errors from "@/utils/errors";
import funcoes from "@/utils/funcoes";
import recebimentos from "@/mixins/recebimentos";
import global from "@/mixins/global";

export default {
    mixins : [recebimentos, global],
    computed : {
        ...mapGetters({
            origemRecebimento : "recebimentos/getOrigemRecebimento",
            usuarioLogadoDados : "global/getUsuarioLogadoDados",
        })
    },
    watch : {
      page() {
        this.carregarRecebimentos();
      }
    },
    data() {
        return {
            page : 1,
            loadingRecebimentosDoPaciente : false,
            loadedRecebimentosDoPaciente : false,
            mensagem : "",
            retornando : false,

            camposTabela : [
                { key: "id", label: "Id", sortable: true },
                { key: "datAtendimento", label: "Atendimento", sortable: true },
                { key: "datRecebimento", label: "Recebido em", sortable: true },
                { key: "valTratamentos", label: "Tratamentos R$" },
                { key: "valDespesas", label: "Despesas R$" },
                { key: "valPacotes", label: "Pacotes R$" },
                { key: "valDescontos", label: "Descontos R$"},
                { key: "valRecebido", label: "Recebido R$" },
                { key: "actions", label: "Ações" }
            ],

            erro : "",
        }
    },
    methods : {
        ...mapActions({
            setRecebimentoSelecionado : "recebimentos/setRecebimentoSelecionado",
            formLeave : "recebimentos/formLeave"
        }),
        retornar() {
            this.retornando = true;
            this.$router.go(-1);
        },
        selecionouRegistro(recebimento) {
            this.setRecebimentoSelecionado(recebimento);
            this.$router.push('/cadRecebimento');
        },
        incluirRegistroRecebimento() {
            let recebimento = {
              datAtendimento : funcoes.mySqlToJSDateFormat(Date()), 
              pacienteId : this.origemRecebimento.pacienteId, 
              operadorCadastroId : this.usuarioLogadoDados.profissionalId,
              caixaId : 1 // verficicar como será feito depois
            }

            this.$http.post(`/v1.0/recebimentos/${this.usuarioLogadoDados.tenant}`, recebimento)
            .then(registroCriado => {
              this.erro = "";

              let registro = registroCriado.data;

              this.selecionouRegistro(registro);
            })
            .catch(error => this.erro = errors.tratarErros(error))
        },
        excluirRegistroSelecionado(recebimento) {
        this.$bvModal
          .msgBoxConfirm("Excluir o Registro de Recebimento selecionado?", {
            title: "Atenção",
            size: "md",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "Sim",
            cancelTitle: "Não",
            footerClass: "p-2",
            hideHeaderClose: true,
            centered: true
          })
          .then(value => {
            if (value) {
              this.$http.delete(`/v1.0/recebimentos/${this.usuarioLogadoDados.tenant}/${recebimento.id}/${this.usuarioLogadoDados.profissionalId}`)
              .then(() => {
                this.erro = "";

                this.$toasted.show("Registro de Recebimento removido com Sucesso", {type : "success", icon : "info"});
                this.carregarRecebimentos();
              })
              .catch(error => this.erro = errors.tratarErros(error))
              }
          })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          });
        },
        async carregarRecebimentos() {
          await this.carregarRecebimentosDoPaciente(this.usuarioLogadoDados.tenant, this.origemRecebimento.pacienteId, this.page);
        },
    },
    created() {
        this.showMenu(false);
        this.carregarRecebimentos();
    },
    beforeRouteLeave(to, from, next) {
        if (this.retornando) {
            this.formLeave();
        }

        next(true);
    }
}
</script>

<style scoped>

</style>>
