<template>
    <div class="header">
      <a v-if="isToggleVisible" class="toggle" @click="toggleMenu">
          <i class="fa fa-lg" :class="icon"></i>
      </a>
      <h1 class="title">
          {{title}}
      </h1>
      <userDropDown v-if="isUserDropDownVisible"/>
    </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import userDropDown from './userDropDown.vue'

export default {
    name : 'Header',
    props: {title : String},
    components : {userDropDown},
    computed: {
        ...mapGetters({
            isMenuVisible : "global/getMenu",
            isToggleVisible : "global/getToggleMenu",
            isUserDropDownVisible : "global/getUserDropDown"
        }),
        icon() {
            return this.isMenuVisible ? "fa-angle-left" : "fa-angle-right";
        }
    },
    methods: {
        ...mapActions({
            setMenu : "global/menu",
        }),
        toggleMenu() {
            this.setMenu();
        }
    },
    created() {
    },
}
</script>

<style scoped>
    .header {
        grid-area: header;
        background: linear-gradient(to right, #1e469a, #49a7c1);

        display : flex;
        justify-content: center;
        align-items: center;
    }

    .title {
        font-size: 1.2rem;
        font-weight: 100;
        color: #fff;
        flex-grow : 1;
        text-align: center;
    }

    .title a {
        color: #FFF;
        text-decoration: none;
    }

    .header.header > a.toggle {
        width: 60px;
        height: 100%;
        color: #fff;
        justify-self: flex-start;
        text-decoration: none;

        display: flex;
        justify-content: center;
        align-items: center;
    }

    .header.header > a.toggle:hover {
        background-color: rgb(0, 0, 0, 0.2);
        cursor : pointer;
    }
</style>