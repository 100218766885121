<template>
  <div id="CadOperadoraTiss">
    <div class="container-fluid">
      <div class="row">
        <div class="form-group col-lg-2">
          <label for="TissVersao" class="control-label">Versão TISS</label>
          <select
            id="TissVersao"
            class="form-control"
            :disabled="!liberarCampos"
            selected
            v-model="operadoraSelecionada.tissVersaoXsd"
          >
            <option
              v-for="tissVersao in tissVersoes"
              :key="tissVersao.opcao"
              :value="tissVersao.valor"
            >{{tissVersao.valor}}</option>
          </select>
        </div>
        <div class="form-group col-lg-1">
          <label for="RegistroAns" class="control-label">Registro ANS</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="RegistroAns"
            name="RegistroAns"
            v-model="operadoraSelecionada.registroAns"
          />
        </div>
      </div>
      <div class="row">
        <div class="form-group col-lg-2">
          <label for="MaximoGuiasPorLote" class="control-label">Máximo Guias Lote</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="MaximoGuiasPorLote"
            name="MaximoGuiasPorLote"
            v-model.number="operadoraSelecionada.maximoGuiasPorLote"
          />
        </div>
        <div class="form-group col-lg-2">
          <label for="GrupoFaturamento" class="control-label">Grupo Faturamento</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="GrupoFaturamento"
            name="GrupoFaturamento"
            v-model="operadoraSelecionada.grupoFaturamento"
          />
        </div>
        <div class="form-group col-lg-2">
          <label for="CodLote" class="control-label">(Últ) Nº Lotes Faturas</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="CodLote"
            name="CodLote"
            v-model.number="operadoraSelecionada.codLote"
          />
        </div>
        <div class="form-group col-lg-2">
          <label for="SequenciaArqsEnviados" class="control-label">(Próx) Seq. Arqs. Enviados</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="SequenciaArqsEnviados"
            name="SequenciaArqsEnviados"
            v-model.number="operadoraSelecionada.sequenciaArqsEnviados"
          />
        </div>

        <div class="form-group col-lg-2">
          <label for="NumeroGuiaPrestador" class="control-label">(Últ) Seq. Guia Prestador</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="NumeroGuiaPrestador"
            name="NumeroGuiaPrestador"
            v-model.number="operadoraSelecionada.numeroGuiaPrestador"
          />
        </div>
      </div>

      <div class="row">
        <div class="form-group col-lg-4">
          <label for="TissEnviar" class="control-label">Enviar XML Para</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="TissEnviar"
            name="TissEnviar"
            v-model="operadoraSelecionada.tissEnviar"
          />
        </div>
        <div class="form-group col-lg-1">
          <label for="TissUsuario" class="control-label">Usuário</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="TissUsuario"
            name="TissUsuario"
            v-model="operadoraSelecionada.tissUsuario"
          />
        </div>
        <div class="form-group col-lg-1">
          <label for="TissSenha" class="control-label">Senha</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="TissSenha"
            name="TissSenha"
            v-model="operadoraSelecionada.tissSenha"
          />
        </div>
        <div class="form-group col-lg-2">
          <label for="TissContrato" class="control-label">Contrato</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="TissContrato"
            name="TissContrato"
            v-model="operadoraSelecionada.tissContrato"
          />
        </div>
      </div>

      <div class="row">
        <div class="form-group col-lg-4">
          <label for="TissSuporte" class="control-label">Suporte</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="TissSuporte"
            name="TissSuporte"
            v-model="operadoraSelecionada.tissSuporte"
          />
        </div>
        <div class="form-group col-lg-2">
          <label for="TissWebserviceUsuario" class="control-label">WebService Usuário</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="TissWebserviceUsuario"
            name="TissWebserviceUsuario"
            v-model="operadoraSelecionada.tissWebserviceUsuario"
          />
        </div>
        <div class="form-group col-lg-2">
          <label for="TissWebserviceSenha" class="control-label">WebService Senha</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="TissWebserviceSenha"
            name="TissWebserviceSenha"
            v-model="operadoraSelecionada.tissWebserviceSenha"
          />
        </div>
      </div>

      <div class="row">
        <div class="form-group col-lg-2">
          <label for="SequencialIgualCodLote" class="control-label">Seq. Arqs. Enviados = Nº Lotes</label>
          <select
            id="SequencialIgualCodLote"
            class="form-control"
            :disabled="!liberarCampos"
            selected
            v-model="operadoraSelecionada.sequencialIgualCodLote"
          >
            <option
              v-for="resposta in sim_nao"
              :key="resposta.opcao"
              :value="resposta.opcao"
            >{{resposta.valor}}</option>
          </select>
        </div>
        <div class="form-group col-lg-2">
          <label for="TissSolicitarEquipeSadt" class="control-label">Solicitar Equipe SADT</label>
          <select
            id="TissSolicitarEquipeSadt"
            class="form-control"
            :disabled="!liberarCampos"
            selected
            v-model="operadoraSelecionada.tissSolicitarEquipeSadt"
          >
            <option
              v-for="resposta in sim_nao"
              :key="resposta.opcao"
              :value="resposta.opcao"
            >{{resposta.valor}}</option>
          </select>
        </div>
        <div class="form-group col-lg-2">
          <label for="CertificadoDigital" class="control-label">XML Certificado Digital</label>
          <select
            id="CertificadoDigital"
            class="form-control"
            :disabled="!liberarCampos"
            selected
            v-model="operadoraSelecionada.certificadoDigital"
          >
            <option
              v-for="resposta in sim_nao"
              :key="resposta.opcao"
              :value="resposta.opcao"
            >{{resposta.valor}}</option>
          </select>
        </div>
        <div class="form-group col-lg-3">
          <label for="GerarNumeroGuia" class="control-label">Gerar Número de Guia ao digitar '0'</label>
          <select
            id="GerarNumeroGuia"
            class="form-control"
            :disabled="!liberarCampos"
            selected
            v-model="operadoraSelecionada.gerarNumeroGuia"
          >
            <option
              v-for="resposta in sim_nao"
              :key="resposta.opcao"
              :value="resposta.opcao"
            >{{resposta.valor}}</option>
          </select>
        </div>
        <div class="form-group col-lg-2">
          <label for="IncluirDefratorNoXml" class="control-label">Incluir Defrator no XML</label>
          <select
            id="IncluirDefratorNoXml"
            class="form-control"
            :disabled="!liberarCampos"
            selected
            v-model="operadoraSelecionada.incluirDefratorNoXml"
          >
            <option
              v-for="resposta in sim_nao"
              :key="resposta.opcao"
              :value="resposta.opcao"
            >{{resposta.valor}}</option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import constantes from "@/utils/constantes";

export default {
  //props : {sim_nao},
  computed: {
    ...mapGetters({
      modo: "operadoras/getModo",
      operadoraSelecionada: "operadoras/getOperadoraSelecionada",
    }),
    liberarCampos() {
      return this.modo == "Incluir" || this.modo == "Alterar" ? true : false;
    }
  },
  data() {
    return {
      tissVersoes: constantes.tissVersoes,
      sim_nao: constantes.sim_nao
    }
  }
}
</script>

<style scoped>
    label {
      font-weight: 100%;
      font-size: 0.8rem;
      margin-top: 3px;
      margin-bottom: 1px;
    }
</style>
