import errors from "@/utils/errors"

export default {
    data() {
        return {
            acompanhamentos : [],
        }
    },
    methods : {
        async carregarPeloProfissionalContatados(tenant, profissionalId, datInicial, datFinal, contatados, pagina) {
            this.loadingAcompanhamentos = true;
            this.loadedAcompanhamentos = false;

            await this.$http.get(`/v1.0/acompanhamentos/${tenant}/ProfissionalContatados/${profissionalId}/${contatados}/${datInicial}/${datFinal}/${pagina}`)
                  .then(resp => {
                    this.acompanhamentos = resp.data.registros;
                    this.count = resp.data.count;
                    this.limit = resp.data.limit;

                    this.loadedAcompanhamentos = true;

                    this.erro = "";
                  })
                  .catch(error => {
                    this.erro = errors.tratarErros(error);
                  })
                  .finally(() => this.loadingAcompanhamentos = false);
              },
        async carregarPeloProfissionalVisualizados(tenant, profissionalId, datInicial, datFinal, visualizados, pagina) {
          this.loadingAcompanhamentos = true;
          this.loadedAcompanhamentos = false;

          await this.$http.get(`/v1.0/acompanhamentos/${tenant}/ProfissionalVisualizados/${profissionalId}/${visualizados}/${datInicial}/${datFinal}/${pagina}`)
                .then(resp => {
                  this.acompanhamentos = resp.data.registros;
                  this.count = resp.data.count;
                  this.limit = resp.data.limit;

                  this.loadedAcompanhamentos = true;

                  this.erro = "";
                })
                .catch(error => {
                  this.erro = errors.tratarErros(error);
                })
                .finally(() => this.loadingAcompanhamentos = false);
            },
        async carregarContatados(tenant, datInicial, datFinal, contatados, pagina) {
          this.loadingAcompanhamentos = true;
          this.loadedAcompanhamentos = false;

          await this.$http.get(`/v1.0/acompanhamentos/${tenant}/contatados/${datInicial}/${datFinal}/${contatados}/${pagina}`)
                .then(resp => {
                  this.acompanhamentos = resp.data.registros;
                  this.count = resp.data.count;
                  this.limit = resp.data.limit;

                  this.loadedAcompanhamentos = true;

                  this.erro = "";
                })
                .catch(error => {
                  this.erro = errors.tratarErros(error);
                })
                .finally(() => this.loadingAcompanhamentos = false);
        },
        async carregarPeloPaciente(tenant, pacienteId, pagina) {
            this.loadingAcompanhamentos = true;
            this.loadedAcompanhamentos = false;

            await this.$http.get(`/v1.0/acompanhamentos/${tenant}/Paciente/${pacienteId}/${pagina}`)
                  .then(resp => {
                    this.acompanhamentos = resp.data.registros;
                    this.count = resp.data.count;
                    this.limit = resp.data.limit;

                    this.loadedAcompanhamentos = true;

                    this.erro = "";
                  })
                  .catch(error => {
                    this.erro = errors.tratarErros(error);
                  })
                  .finally(() => this.loadingAcompanhamentos = false);
                },
    }
}
