<template>
    <div>
        <div class="cabecalho">
          <div class="coluna1">
            <button @click="retornar" class="btn btn-sm btn-primary mr-10"><i class="fa fa-arrow-left"/></button>
          </div>
          <div class="coluna2">
            <span>Despesa Id: {{guiaDespesaSelecionada.id}} - Particular</span>
          </div>
          <div class="coluna3"/>
        </div>

        <div class="container-fluid">
            <!-- Identificação -->
            <div class="row">
                <div class="form-group col-lg-1">
                    <label for="procedimentoCobertoId" class="control-label">Desp.Id</label>
                    <input type="text" id="procedimentoCobertoId" disabled class="form-control" v-model="guiaDespesaSelecionada.despesaId"/>
                </div>
                <div class="form-group col-lg-1">
                    <label for="codpro" class="control-label">Código</label>
                    <input type="text" id="codpro" disabled class="form-control" v-model="guiaDespesaSelecionada.codigoXML"/>
                </div>
                <div class="form-group col-lg-1">
                    <label for="codTuss" class="control-label">TUSS</label>
                    <input type="text" id="codTuss" disabled class="form-control" v-model="guiaDespesaSelecionada.codigoTuss"/>
                </div>
                <div class="form-group col-lg-7">
                    <label for="descricao" class="control-label">Descrição</label>
                    <input type="text" id="descricao" disabled class="form-control" v-model="guiaDespesaSelecionada.descricao"/>
                </div>
            </div>

            <div class="row">
                <div class="form-group col-lg-3">
                    <label for="unidade" class="control-label">Unidade:</label>
                    <select
                        id="unidade"
                        class="form-control"
                        selected
                        v-model="guiaDespesaSelecionada.unidade"
                    >
                        <option
                        v-for="unidademedida in unidadesmedidas"
                        :key="unidademedida.id"
                        :value="unidademedida.termo"
                        >{{unidademedida.descricao}} ({{unidademedida.termo}})</option>
                    </select>
                </div>

                <div class="form-group col-lg-3">
                    <label for="unidadeTiss" class="control-label">Unidade Tiss:</label>
                    <select
                        id="unidadeTiss"
                        class="form-control"
                        selected
                        v-model="guiaDespesaSelecionada.unidadeTiss"
                    >
                        <option
                        v-for="unidademedida in unidadesmedidas"
                        :key="unidademedida.id"
                        :value="unidademedida.termo"
                        >{{unidademedida.descricao}} ({{unidademedida.termo}})</option>
                    </select>
                </div>
            </div>



            <!-- Valores Calculados -->
            <hr/>
            <div class="row">
                <div class="form-group col-lg-1">
                    <label for="valMat" class="control-label">Unit. R$</label>
                    <input type="number" id="valMat" class="form-control" v-model.number="guiaDespesaSelecionada.valMat"/>
                </div>
                <div class="form-group col-lg-1">
                    <label for="qtdMat" class="control-label">Qtde</label>
                    <input type="number" id="qtdMat" class="form-control" v-model.number="guiaDespesaSelecionada.qtdMat"/>
                </div>
                <div class="form-group col-lg-1">
                    <label for="valTotal" class="control-label">Total R$</label>
                    <input type="text" id="valTotal" disabled class="form-control" v-model.number="valTotal"/>
                </div>
                <div class="form-group col-lg-1">
                    <label for="valDesconto" class="control-label">Desconto R$</label>
                    <input type="number" id="valDesconto" class="form-control" v-model.number="valDesconto"/>
                </div>
                <div class="form-group col-lg-1">
                    <label for="valTotalFinal" class="control-label">Tratamento R$</label>
                    <input type="text" id="valTotalFinal" disabled class="form-control" v-model.number="valTotalFinal"/>
                </div>
            </div>
            <hr/>

            <div class="row justify-content-center">
              <button class="btn btn-sm btn-danger" style="margin-bottom:10px;"
                @click="retornar()">Cancelar
              </button>
              <button class="btn btn-sm btn-info ml-1" style="margin-bottom:10px;"
                @click="gravarAlteracoes()">Confirmar
              </button>
            </div>
            <!-- Exibição de Erros -->
            <template v-if="this.erro!=''">
              <p class="msgError">Erro durante Operação: {{ this.erro }}</p>
            </template>
        </div>
    </div>    
</template>

<script>
import {mapGetters} from "vuex";
import global from "@/mixins/global"
import errors from "@/utils/errors"
import unidadesMedidas from "@/mixins/unidadesMedidas"

export default {
    mixins : [global, unidadesMedidas],
    computed: {
        ...mapGetters({
            guiaSelecionada : "guias/getGuiaSelecionada",
            guiaDespesaSelecionada : "guiaDespesas/getGuiaDespesaSelecionada",
            usuarioLogadoDados : "global/getUsuarioLogadoDados",
        }),
        valTotal()  {
            return this.guiaDespesaSelecionada.valMat * this.guiaDespesaSelecionada.qtdMat;
        },
        valTotalFinal() {
            return (this.guiaDespesaSelecionada.valMat * this.guiaDespesaSelecionada.qtdMat) - this.valDesconto;
        },
    },
    watch: {
        valDesconto() {
            if (this.valDesconto > this.valTotal) {
                this.valDesconto = this.valTotal
            }
        },
    },
    data() {
        return {
            erro : "",
            valDesconto : 0,
        }
    },
    methods: {
        retornar() {
            this.$router.go(-1);
        },
        gravarAlteracoes() {
            this.guiaDespesaSelecionada.valDesconto = this.valDesconto;
            this.guiaDespesaSelecionada.valTotal = this.valTotalFinal;
            this.guiaDespesaSelecionada.operadorAlteracaoId = this.usuarioLogadoDados.profissionalId;

            this.$http.put(`/v1.0/guiadespesas/${this.usuarioLogadoDados.tenant}/${this.guiaDespesaSelecionada.id}`, this.guiaDespesaSelecionada)
            .then(() => {
                this.$toasted.global.defaultSuccess();
                this.$router.go(-1);
            })
            .catch(error => {
                this.erro = errors.tratarErros(error);
            })
        }
    },
    created() {
        this.showMenu(false);
        this.carregarUnidadesMedidas(this.usuarioLogadoDados.tenant);
        this.valDesconto = this.guiaDespesaSelecionada.valDesconto;
    }
}
</script>

<style scoped>
  label {
    font-weight: 100%;
    font-size: 0.8rem;
    margin-top: 3px;
    margin-bottom: 1px;
  }
</style>
