<template>
  <div id="frmProntuarioPermitidos">
    <div class="cabecalho">
      <div class="coluna1">
        <button @click="retornar" class="btn btn-sm btn-primary mr-10"><i class="fa fa-arrow-left"/></button>
      </div>
      <div class="coluna2">
        <span>{{profissionalSelecionado.nome}} - Acesso aos Prontuários do Profissional</span>
      </div>
      <div class="coluna3"/>
    </div>  

    <template v-if="this.erro!=''">
      <p class="msgError">Erro durante Operação: {{ this.erro }}</p>
    </template>

    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-lg-3">
          <p>Não Podem ver Meus Prontuários</p>
        </div>

        <div class="col-lg-3">
          <p>Podem ver Meus Prontuários</p>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-lg-3">
          <select id="naoPermitidos" v-model="naoPermitidoSelecionadoId" size="20" style="max-Width:95%">
            <option v-for="profissional in profissionaisExcetoPermitidos" 
                   :key="profissional.id" :value="profissional.id">
                   {{profissional.nome}}
            </option>
          </select>
        </div>

        <div class="col-lg-3">
          <select id="permitidos" v-model="permitidoSelecionadoId" size="20" style="max-Width:95%">
            <option v-for="permitido in permitidos" 
                   :key="permitido.id" :value="permitido.id">
                   {{permitido.profissional}}
            </option>
          </select>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-lg-3">
          <button class="btn btn-sm btn-info" @click="AdicionarItem">Adicionar à Lista</button>
        </div>

        <div class="col-lg-3">
          <button class="btn btn-sm btn-danger" @click="RemoverItem">Remover da Lista</button>
        </div>
      </div>
      <hr>
    </div>
    <div class="Mensagem">
      <b-modal id="modal-mensagem" centered title="DoctorOn">
        <p class="my-4">{{mensagem}}</p>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import errors from "@/utils/errors";
import profissionais from "@/mixins/profissionais";

export default {
  mixins : [profissionais],
  computed: {
    ...mapGetters({
      profissionalSelecionado : "profissionais/getProfissionalSelecionado",
      usuarioLogadoDados : "global/getUsuarioLogadoDados",
    })
  },
  data() {
    return {
      loadingProntuariosPermitidos: false,
      erro: "",
      permitidoSelecionado : {},
      permitidos : [],
      profissionaisExcetoPermitidos : [],
      mensagem : "",
      alterou : false,
      permitidoSelecionadoId: 0,
      naoPermitidoSelecionadoId: 0,
    }
  },
  methods: {
    retornar() {
      this.$router.go(-1);
    },
    carregarProfissionais() {
      this.$http
        .get(`/v1.0/profissionais/${this.usuarioLogadoDados.tenant}/ativos/S`)
        .then(resp => {
          this.setProfissionais([...resp.data]);
          this.error = false;
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    AdicionarItem() {
      if (!this.verificarPermissao('Incluir')) {
        return
      }
      
      if (this.naoPermitidoSelecionadoId==0) {
        this.$toasted.show("Selecione um Profissional na Lista para Adicionar!", {type: "info", icon : "times"});
        return;
      }

      var dados = {profissionalId : this.naoPermitidoSelecionadoId, 
                  profissionalProprietarioId : this.profissionalSelecionado.id,
                  operadorCadastroId : this.usuarioLogadoDados.profissionalId}

      this.$http
        .post(`/v1.0/prontuariopermitidos/${this.usuarioLogadoDados.tenant}`, dados)
        .then(() => {
          this.erro = "";
          this.prepararProfissionaisPermitidos();
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        })

      this.permitidoSelecionadoId = 0;
      this.naoPermitidoSelecionadoId = 0;
    },
    RemoverItem() {
      if (!this.verificarPermissao('Excluir')) {
        return
      }
      
      if (this.permitidoSelecionadoId==0) {
        this.$toasted.show("Selecione um Profissional na Lista para Remover!", {type: "info", icon : "times"});
        return;
      }

      let indice = this.permitidos.findIndex(p => p.id == this.permitidoSelecionadoId);
      let nomeProfissional = this.permitidos[indice].profissional;

      this.$bvModal.msgBoxConfirm(`Remover ${nomeProfissional} da Lista de Permitidos?`, {
          title: "Atenção",
          size: "md",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
           this.$http
             .delete(`/v1.0/prontuariopermitidos/${this.usuarioLogadoDados.tenant}/${this.permitidoSelecionadoId}/${this.usuarioLogadoDados.profissionalId}`)
             .then(() => {
               this.erro = "";
               this.permitidoSelecionadoId = 0;
               this.naoPermitidoSelecionadoId = 0;

               this.prepararProfissionaisPermitidos();
             })
             .catch(error => {
               this.erro = errors.tratarErros(error);
             })
             .finally(() => {});
          }
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        });
    },
    verificarPermissao(operacao) {
      if (!errors.verificarPermissao(this.usuarioLogadoDados, this.$store.state.global.grauPermissaoMinimo, operacao)) {
        let descricaoOperacao = operacao == "Incluir" ? "Adicão" : operacao == "Alterar" ? "Alteração" : "Remoção";

        this.mensagem = `${this.usuarioLogadoDados.profissional.nome}, você não possui permissão para realizar ${descricaoOperacao} com esse Perfil.`;
        this.$bvModal.show("modal-mensagem");

        return false;
      }

      return true;
    },
    async prepararProfissionaisPermitidos() {
      await this.carregarProntuariosPermitidos(this.usuarioLogadoDados.tenant);
      await this.filtrarProfissionaisExcetoPermitidos();
    },
    async filtrarProfissionaisExcetoPermitidos() {
      this.profissionaisExcetoPermitidos = [];

      if (this.permitidos.length > 0)  {
        let localizado = new Array;

        for (let profissional of this.profissionais) {
          if (profissional.id != this.profissionalSelecionado.id) {
            localizado = this.permitidos.filter((p) => {return p.profissionalId == profissional.id});

            if (localizado.length==0) {
              this.profissionaisExcetoPermitidos.push({...profissional});
            }
          }
        }
      } else {
        this.profissionaisExcetoPermitidos = this.profissionais.filter((p) => {return p.id != this.profissionalSelecionado.id})
      }
    },
  },
  async created() {
    await this.carregarProfissionaisAtivos(this.usuarioLogadoDados.tenant, 'S');
    await this.prepararProfissionaisPermitidos();
  },
}
</script>

<style scoped>
</style>
