<template>
  <div id="CadOperadoraDados">
    <div class="container-fluid">
      <div class="row">
        <div class="form-group col-lg-2">
          <label for="excluirLancto" class="control-label">Excluir após Lancto:</label>
          <select
            id="excluirLancto"
            class="form-control"
            :disabled="!liberarCampos"
            selected
            v-model="unidadeSelecionada.excluirLancto"
          >
            <option
              v-for="resposta in sim_nao"
              :key="resposta.opcao"
              :value="resposta.opcao"
            >{{resposta.valor}}</option>
          </select>
        </div>

        <div class="form-group col-lg-2">
          <label for="excluirLanctoVerificado" class="control-label">Excluir após Verificado:</label>
          <select
            id="excluirLanctoVerificado"
            class="form-control"
            :disabled="!liberarCampos"
            selected
            v-model="unidadeSelecionada.excluirLanctoVerificado"
          >
            <option
              v-for="resposta in sim_nao"
              :key="resposta.opcao"
              :value="resposta.opcao"
            >{{resposta.valor}}</option>
          </select>
        </div>

        <div class="form-group col-lg-2">
          <label for="excluirLanctoFaturaFechada" class="control-label">Excluir após Fatura Fechada:</label>
          <select
            id="excluirLanctoFaturaFechada"
            class="form-control"
            :disabled="!liberarCampos"
            selected
            v-model="unidadeSelecionada.excluirLanctoFaturaFechada"
          >
            <option
              v-for="resposta in sim_nao"
              :key="resposta.opcao"
              :value="resposta.opcao"
            >{{resposta.valor}}</option>
          </select>
        </div>

        <div class="form-group col-lg-2">
          <label for="excluirLanctoLoteFechado" class="control-label">Excluir após Lote Fechado:</label>
          <select
            id="excluirLanctoLoteFechado"
            class="form-control"
            :disabled="!liberarCampos"
            selected
            v-model="unidadeSelecionada.excluirLanctoLoteFechado"
          >
            <option
              v-for="resposta in sim_nao"
              :key="resposta.opcao"
              :value="resposta.opcao"
            >{{resposta.valor}}</option>
          </select>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-lg-2">
          <label for="atendimentoSeriado" class="control-label">Realiza Atend.Seriado:</label>
          <select
            id="atendimentoSeriado"
            class="form-control"
            :disabled="!liberarCampos"
            selected
            v-model="unidadeSelecionada.atendimentoSeriado"
          >
            <option
              v-for="resposta in sim_nao"
              :key="resposta.opcao"
              :value="resposta.opcao"
            >{{resposta.valor}}</option>
          </select>
        </div>

        <div class="form-group col-lg-2">
          <label for="exibirAlertaMaterial" class="control-label">Exibir Alerta Materiais:</label>
          <select
            id="exibirAlertaMaterial"
            class="form-control"
            :disabled="!liberarCampos"
            selected
            v-model="unidadeSelecionada.exibirAlertaMaterial"
          >
            <option
              v-for="resposta in sim_nao"
              :key="resposta.opcao"
              :value="resposta.opcao"
            >{{resposta.valor}}</option>
          </select>
        </div>

        <div class="form-group col-lg-2">
          <label for="utilizaAgendaCirurgias" class="control-label">Utiliz. Agenda Cirurgias:</label>
          <select
            id="utilizaAgendaCirurgias"
            class="form-control"
            :disabled="!liberarCampos"
            selected
            v-model="unidadeSelecionada.utilizaAgendaCirurgias"
          >
            <option
              v-for="resposta in sim_nao"
              :key="resposta.opcao"
              :value="resposta.opcao"
            >{{resposta.valor}}</option>
          </select>
        </div>

        <div class="form-group col-lg-2">
          <label for="lancarConsultaAutomatica" class="control-label">Lançar Consulta Automática:</label>
          <select
            id="lancarConsultaAutomatica"
            class="form-control"
            :disabled="!liberarCampos"
            selected
            v-model="unidadeSelecionada.lancarConsultaAutomatica"
          >
            <option
              v-for="resposta in sim_nao"
              :key="resposta.opcao"
              :value="resposta.opcao"
            >{{resposta.valor}}</option>
          </select>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-lg-2">
          <label for="codProMatMedIsolados" class="control-label">Cód. Despesas Isoladas:</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="codProMatMedIsolados"
            name="codProMatMedIsolados"
            v-model="unidadeSelecionada.codProMatMedIsolados"
          />
        </div>

        <div class="form-group col-lg-2">
          <label for="codProPagtoDividas" class="control-label">Cód. Pagto Dívidas Pacientes:</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="codProPagtoDividas"
            name="codProPagtoDividas"
            v-model="unidadeSelecionada.codProPagtoDividas"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import constantes from "@/utils/constantes";

export default {
  computed: {
    ...mapGetters({
      unidadeSelecionada: "unidades/getUnidadeSelecionada",
      modo : "unidades/getModo",
    }),
    liberarCampos() {
      return this.modo == "Incluir" || this.modo == "Alterar" ? true : false;
    }
  },
  data() {
    return {
      sim_nao: constantes.sim_nao,
      ufs: constantes.uf,
    }
  },
  methods: {}
}
</script>

<style scoped>
    label {
      font-weight: 100%;
      font-size: 0.8rem;
      margin-top: 3px;
      margin-bottom: 1px;
    }
</style>
