import errors from "@/utils/errors";

export default {
    data() {
        return {
            operadoraContatos : [],
        }
    },
    methods: {
        carregarOperadoraContatos(tenant, operadoraContatoId) {
            this.$http
              .get(`/v1.0/operadoracontatos/${tenant}/operadora/${operadoraContatoId}`)
              .then(resp => {
                this.operadoraContatos = resp.data;
                this.erro = "";
              })
              .catch(error => {
                this.erro = errors.tratarErros(error);
              })
              .finally(() => {
                this.loading = false;
              });
          },
    }
}
