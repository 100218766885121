function mySqlToJSDateFormat(data, cultura = "en-US") {
    if (data==null) return null;

    let datJSFormat = new Date(data);

    if (cultura=="en-US") {
        datJSFormat = datJSFormat.getFullYear()+'-'+
                      ('00' + (datJSFormat.getMonth()+1)).slice(-2)+'-'+
                      ('00' + datJSFormat.getDate()).slice(-2);
    } else {
        datJSFormat = ('00' + datJSFormat.getDate()).slice(-2)+'-'+
                      ('00' + (datJSFormat.getMonth()+1)).slice(-2)+'-'+
                      datJSFormat.getFullYear();
    }

    return datJSFormat;
}

function formatMonetario(valor) {
    const valorFormatado = parseFloat(valor).toFixed(2)
                                            .replace('.',',')
                                            //.replace(/(\d)(?=(\d{3})+\,)/g,'$1.')

    return valorFormatado;
}

function replaceCampos(texto, informacoes, variaveis) {
    const constantes = require('./constantes.js');
    const inserirCampo = constantes.default.inserirCampo;

    inserirCampo.forEach(campo => {
        while(texto.indexOf(campo.opcao) > 0) {
            let trocarPor = trocarPor = informacoes[campo.tabela]["dados"][campo.campo];
            trocarPor = trocarPor != null ? trocarPor : "";

            if (campo.funcao != null && trocarPor != null) {
                if (campo.funcao=="calcularIdade") {
                    trocarPor = calcularIdade(trocarPor, new Date());
                }
            } else if (campo.tipo=="date" && trocarPor != null) {
                trocarPor = mySqlToJSDateFormat(new Date(trocarPor), "pt-BR")
            }

            texto = texto.replace(campo.opcao, trocarPor);
        }
    });

    variaveis.forEach(variavel => {
        while(texto.indexOf("««"+variavel.nome+"»»")>0) {
            let entradaUsuario = "";

            do {
                let valorPadrao = "";

                if (variavel.descricao.toLowerCase().includes("data")) {
                    valorPadrao = new Date().toLocaleDateString();
                } else if (variavel.descricao.toLowerCase().includes("cidade")) {
                    valorPadrao = "Sorocaba/SP"
                }

                entradaUsuario = prompt(variavel.descricao, valorPadrao);
            } while(variavel.obrigatorio=="S" && entradaUsuario=="");

            texto = texto.replace("««"+variavel.nome+"»»", entradaUsuario);
        }
    })

    return texto;
}

function calcularIdade(datNascimento, datReferente) {
    if (typeof(datNascimento) == "string") {
        datNascimento = new Date(datNascimento)
    }

    let anoNascimento = datNascimento.getFullYear();
    let mesNascimento = datNascimento.getMonth()+1;
    let diaNascimento = datNascimento.getDate();

    let anoReferente = datReferente.getFullYear();
    let mesReferente = datReferente.getMonth()+1;
    let diaReferente = datReferente.getDate();

    let anos = anoReferente - anoNascimento;
    let meses = mesReferente - mesNascimento;
    let dias = diaReferente - diaNascimento;

    let diasNoMes = 0;
    let idadePorExtenso = "";

    if (meses < 0) {
        anos--;
        meses += 12;
    }

    if (dias < 0) { 
        if (meses == 0) { //Mês do aniversário
            anos--;
            meses = 11;
    
            diasNoMes = new Date(anoReferente, mesReferente, 0).getDate();
            dias += diasNoMes;
        } else {
            meses--;

            if (mesReferente >= mesNascimento) {
                diasNoMes = new Date(anoReferente, mesReferente, 0).getDate();
                dias += diasNoMes;
            } else {
                dias += 30;
            }
        }
    }

    if (anos > 0) {
        if (meses > 0) {
            if (dias > 0) {
                idadePorExtenso = anos.toString()+" ano(s), "+meses.toString()+" mes(es) e "+dias.toString()+" dia(s)"
            } else {
                idadePorExtenso = anos.toString()+" ano(s), "+meses.toString()+" mes(es)"
            }
        } else {
            if (dias > 0) {
                idadePorExtenso = anos.toString()+" ano(s) e "+dias.toString()+" dia(s)"
            } else {
                idadePorExtenso = anos.toString()+" ano(s)"
            }
        }
    } else {
        if (meses > 0) {
            if (dias > 0) {
                idadePorExtenso = meses.toString()+" mes(es) e "+dias.toString()+" dia(s)"
            } else {
                idadePorExtenso = meses.toString()+" mes(es)"
            }
        } else {
            idadePorExtenso = dias.toString()+" dia(s)"
        }
    }

    return idadePorExtenso;
}

function calcularDiaUtil(dia, mes, ano, feriados) {
    let diaUtil = 0;
    let contador = 0;

    do {
        diaUtil++;

        //--------------------------------------------- verificar se não é Sábado ou Domingo
        let data = new Date(ano, mes-1, diaUtil);
        let diaSemana = data.getDay();

        if (diaSemana == 6 || diaSemana == 0) { 
            continue;
        }

        //--------------------------------------------- verificar se não é Feriado
        let indice = feriados.findIndex(feriado => feriado.dia == this.diaUtil && feriado.mes == mes);

        if (indice > -1 ) {
            continue;
        }

        contador++;
    } while(contador < dia)

    return diaUtil;
}

function salvarArquivoTxt(texto, filename) {
    const salvar = require('file-saver');

    var blob = new Blob([texto], {type: "text/plain;charset=utf-8"});    
    salvar.saveAs(blob, filename);
}

function verificarHorarioPerfil(horarioInicial, horarioFinal) {
    if (horarioInicial == undefined || horarioFinal == undefined) {
        return false;
    }

    const dataAtual = new Date();
    const horarioAtual = dataAtual.getTime();

    const horaInicial = horarioInicial.slice(0,2);
    const minutoInicial = horarioInicial.slice(3,5);

    const horaFinal = horarioFinal.slice(0,2);
    const minutoFinal = horarioFinal.slice(3,5);

    const inicial = new Date(dataAtual.getFullYear(), dataAtual.getMonth(), dataAtual.getDate(),
                           horaInicial, minutoInicial);

    const final = new Date(dataAtual.getFullYear(), dataAtual.getMonth(), dataAtual.getDate(),
    horaFinal, minutoFinal);

    if (horarioAtual < inicial || horarioAtual > final) {
        return false;
    }

    return true;
}

function detectar_mobile() { 
    if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || 
        navigator.userAgent.match(/Windows Phone/i)) {
       return true;
     } else {
       return false;
    }
}

export default {
    mySqlToJSDateFormat,
    formatMonetario,
    replaceCampos,
    calcularIdade,
    calcularDiaUtil,
    salvarArquivoTxt,
    verificarHorarioPerfil,
    detectar_mobile
}
