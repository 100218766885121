import errors from "@/utils/errors";

export default {
    data() {
        return {
            count : 0,
            limit : 0,
            lancamentoItens : [],
        }
    },
    methods : {
        carregarLancamentoItens(tenant, lancamentoId, pagina) {
            this.$http.get(`/v1.0/lancamentoItens/${tenant}/Lancamento/${lancamentoId}/${pagina}`)
            .then(resp => {
                this.lancamentoItens = resp.data.registros;
                this.count = resp.data.count;
                this.limit = resp.data.limit;

                this.erro = "";
            })
            .catch(error => this.erro = errors.tratarErros(error))
            .finally(() => {
                this.loading = false;
                this.loaded = true;
            })
        }
    }
}
