<template>
  <div id="CadOperadoraDados">
    <div class="container-fluid">
      <div class="row">
        <div class="form-group col-lg-2">
          <label for="loginAbrirCaixa" class="control-label">Abrir Caixa após Login:</label>
          <select
            id="loginAbrirCaixa"
            class="form-control"
            :disabled="!liberarCampos"
            selected
            v-model="unidadeSelecionada.loginAbrirCaixa"
          >
            <option
              v-for="resposta in sim_nao"
              :key="resposta.opcao"
              :value="resposta.opcao"
            >{{resposta.valor}}</option>
          </select>
        </div>

        <div class="form-group col-lg-2">
          <label for="removerAgendamentoDuplicado" class="control-label">Remover Agend. Duplicado:</label>
          <select
            id="removerAgendamentoDuplicado"
            class="form-control"
            :disabled="!liberarCampos"
            selected
            v-model="unidadeSelecionada.removerAgendamentoDuplicado"
          >
            <option
              v-for="resposta in sim_nao"
              :key="resposta.opcao"
              :value="resposta.opcao"
            >{{resposta.valor}}</option>
          </select>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-lg-2">
          <label
            for="statusEsperaExibirPendencias"
            class="control-label"
          >Exibir Pendência em Espera:</label>
          <select
            id="statusEsperaExibirPendencias"
            class="form-control"
            :disabled="!liberarCampos"
            selected
            v-model="unidadeSelecionada.statusEsperaExibirPendencias"
          >
            <option
              v-for="resposta in sim_nao"
              :key="resposta.opcao"
              :value="resposta.opcao"
            >{{resposta.valor}}</option>
          </select>
        </div>

        <div class="form-group col-lg-2">
          <label
            for="permiteCancelarBloqueioAgenda"
            class="control-label"
          >Cancelar Bloqueio na Agenda:</label>
          <select
            id="permiteCancelarBloqueioAgenda"
            class="form-control"
            :disabled="!liberarCampos"
            selected
            v-model="unidadeSelecionada.permiteCancelarBloqueioAgenda"
          >
            <option
              v-for="resposta in sim_nao"
              :key="resposta.opcao"
              :value="resposta.opcao"
            >{{resposta.valor}}</option>
          </select>
        </div>

        <div class="form-group col-lg-2">
          <label for="agendarAposLimite" class="control-label">Permitir Agendar Após Limite:</label>
          <select
            id="agendarAposLimite"
            class="form-control"
            :disabled="!liberarCampos"
            selected
            v-model="unidadeSelecionada.agendarAposLimite"
          >
            <option
              v-for="resposta in sim_nao"
              :key="resposta.opcao"
              :value="resposta.opcao"
            >{{resposta.valor}}</option>
          </select>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-lg-6">
          <label for="nivelSegurancaAgenda" class="control-label">Nível Segurança:</label>
          <select
            id="nivelSegurancaAgenda"
            class="form-control"
            :disabled="!liberarCampos"
            selected
            v-model="unidadeSelecionada.nivelSegurancaAgenda"
          >
            <option
              v-for="resposta in niveisSeguranca"
              :key="resposta.opcao"
              :value="resposta.opcao"
            >{{resposta.valor}}</option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import constantes from "@/utils/constantes";

export default {
  computed: {
    ...mapGetters({
      unidadeSelecionada: "unidades/getUnidadeSelecionada",
      modo : "unidades/getModo",
    }),
    liberarCampos() {
      return this.modo == "Incluir" || this.modo == "Alterar" ? true : false;
    }
  },
  data() {
    return {
      sim_nao: constantes.sim_nao,
      ufs: constantes.uf,
      niveisSeguranca: [
        { opcao: "0", valor: "Não pede código de Segurança" },
        {
          opcao: "1",
          valor: "Pede Código de Segurança apenas para Recepcionistas"
        },
        {
          opcao: "2",
          valor:
            "Pede Código de Segurança apenas para Profissionais (Médicos, Fisioterapeutas, Enfermeiros, Técnicos de Raio-X)"
        },
        {
          opcao: "3",
          valor:
            "Pede Código de Segurança apenas para Usuários Administrativos (Gerentes, Administradores, Faturistas, Prest. de SErviço, Financeiro, Auxiliar Adm)"
        },
        {
          opcao: "4",
          valor:
            "Pede Código de Segurança apenas para Recepcionistas e Usuários Administrativos"
        },
        { opcao: "5", valor: "Pede Código de Segurança para Todos os usuários" }
      ]
    }
  },
  methods: {}
}
</script>

<style scoped>
    label {
      font-weight: 100%;
      font-size: 0.8rem;
      margin-top: 3px;
      margin-bottom: 1px;
    }
</style>
