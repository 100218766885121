const state = {
    cIModeloSelecionado : {}
}

const getters = {
    getCIModeloSelecionado(state) {
        return state.cIModeloSelecionado;
    }
}

const mutations = {
    setCIModeloSelecionado(state, cIModelo) {
        state.cIModeloSelecionado = {...cIModelo}
    }
}

const actions = {
    setCIModeloSelecionado({commit}, cIModelo) {
        commit("setCIModeloSelecionado", cIModelo)
    }
}

export default {
    namespaced : true,
    state,
    getters,
    mutations,
    actions
}