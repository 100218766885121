<template>
  <div id="frmProfissionalComissoes">
    <div class="cabecalho">
      <div class="coluna1">
        <button @click="retornar" class="btn btn-sm btn-primary mr-10"><i class="fa fa-arrow-left"/></button>
      </div>
      <div class="coluna2">
        <span>{{profissionalSelecionado.nome}} - Comissões por Procedimento sobre Lançamentos</span>
      </div>
      <div class="coluna3"/>
    </div>  

    <div class="container-fluid">
      <div class="row" v-if="modo==''">
        <div class="col-12">
          <button
            class="btn btn-sm btn-info"
            @click="incluirRegistro()"
          >Adicionar Nova Comissão</button>
          <hr />
        </div>
      </div>

      <div class="registro" v-if="modo!=''">
        <div class="row" v-if="modo=='Incluir'">
          <div class="col ml-2 mr-2">
            <h4 style="text-align:left">Adicionar Nova Comissão</h4>
            <hr/>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-lg-2">
            <label for="codigoProcedimento" class="control-label">Código</label>
            <input
              type="text"
              class="form-control"
              id="codigoProcedimento"
              name="codigoProcedimento"
              v-model="procedimentoComissao.codigoProcedimento"
            />
          </div>

          <div class="form-group col-lg-2">
            <label for="porcenExecutanteConvenio" class="control-label">% Executante Convênio</label>
            <input
              type="number"
              class="form-control"
              id="porcenExecutanteConvenio"
              name="porcenExecutanteConvenio"
              v-model.number="procedimentoComissao.porcenExecutanteConvenio"
            />
          </div>

          <div class="form-group col-lg-2">
            <label for="porcenExecutanteParticular" class="control-label">% Executante Particular</label>
            <input
              type="number"
              class="form-control"
              id="porcenExecutanteParticular"
              name="porcenExecutanteParticular"
              v-model.number="procedimentoComissao.porcenExecutanteParticular"
            />
          </div>

          <div class="form-group col-lg-2">
            <label for="porcenParticipacaoConvenio" class="control-label">% Participação Convênio</label>
            <input
              type="number"
              class="form-control"
              id="porcenParticipacaoConvenio"
              name="porcenParticipacaoConvenio"
              v-model.number="procedimentoComissao.porcenParticipacaoConvenio"
            />
          </div>

          <div class="form-group col-lg-2">
            <label for="porcenParticipacaoParticular" class="control-label">% Participação Particular</label>
            <input
              type="number"
              class="form-control"
              id="porcenParticipacaoParticular"
              name="porcenParticipacaoParticular"
              v-model.number="procedimentoComissao.porcenParticipacaoParticular"
            />
          </div>
        </div>

        <hr />
        <div class="barraopcoescadastro">
          <button
            id="Alterar"
            :disabled="modo=='Incluir' || modo=='Alterar'"
            @click="alterarRegistro()"
            class="btn btn-sm btn-secondary mr-2"
          >Alterar</button>
          <button
            id="Cancelar"
            @click="cancelarOperacao()"
            class="btn btn-sm btn-secondary mr-2"
          >Cancelar</button>
          <button
            id="Gravar"
            :disabled="!liberarCampos"
            @click="gravarRegistro()"
            class="btn btn-sm btn-secondary"
          >Gravar</button>
        </div>
      </div>
    </div>
    <hr />

    <template v-if="this.erro!=''">
      <p class="msgError">Erro durante Operação: {{ this.erro }}</p>
    </template>

    <div style="display:flex; flex-direction:row; justify-content:center">
      <b-spinner v-if="loadingProcedimentoComissoes" label="carregando..."/>
      <table class="table table-sm table-hover col-lg-10" v-if="loadedProcedimentoComissoes">
        <thead class="thead-light">
          <th>Codigo</th>
          <th>% Executante Convênio</th>
          <th>% Executante Particular</th>
          <th>% Participação Convênio</th>
          <th>% Participação Particular</th>
          <th>Ações</th>
        </thead>
        <tbody>
          <tr v-for="procedimentoComissao in procedimentoComissoes" :key="procedimentoComissao.id">
            <td>{{procedimentoComissao.codigoProcedimento}}</td>
            <td>{{procedimentoComissao.porcenExecutanteConvenio}}</td>
            <td>{{procedimentoComissao.porcenExecutanteParticular}}</td>
            <td>{{procedimentoComissao.porcenParticipacaoConvenio}}</td>
            <td>{{procedimentoComissao.porcenParticipacaoParticular}}</td>
            <td>
              <b-button
                variant="warning"
                size="sm"
                class="mr-2"
                @click="selecionouProcedimentoComissao(procedimentoComissao)"
              >
                <i class="fa fa-pencil" />
              </b-button>
              <b-button
                variant="danger"
                size="sm"
                @click="ExcluirRegistroSelecionado(procedimentoComissao)"
              >
                <i class="fa fa-trash" />
              </b-button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="Mensagem">
      <b-modal id="modal-mensagem" centered title="DoctorOn">
        <p class="my-4">{{mensagem}}</p>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import servicos from "@/mixins/servicos";
import procedimentoComissoes from "@/mixins/procedimentoComissoes";
import errors from "@/utils/errors";

export default {
  mixins: [procedimentoComissoes, servicos],
  computed: {
    ...mapGetters({
      profissionalSelecionado: "profissionais/getProfissionalSelecionado",
      usuarioLogadoDados : "global/getUsuarioLogadoDados",
    }),
    liberarCampos() {
      return this.modo == "Incluir" || this.modo == "Alterar" ? true : false;
    }
  },
  data() {
    return {
      loadingServicos : false,
      loadedServicos : false,
      loadingProcedimentoComissoes: false,
      loadedProcedimentoComissoes : false,
      erro: "",
      mensagem : "",
      retornando: false,
      exibirAdicaoComissao: false,
      modo: "",
      procedimentoComissao: {}
    }
  },
  methods: {
    incluirRegistro() {
      if (this.modo == "" && !this.verificarPermissao('Incluir')) {
        return
      }

      this.procedimentoComissao = {}
      this.modo = this.modo == "" ? "Incluir" : "";
    },
    alterarRegistro() {
      if (!this.verificarPermissao('Alterar')) {
        return
      }

      this.modo = 'Alterar';
    },
    selecionouProcedimentoComissao(procedimentoComissao) {
      this.procedimentoComissao = { ...procedimentoComissao }
      this.modo = "Registro";
    },
    retornar() {
      this.retornando = true;
      this.$router.go(-1);
    },
    gravarRegistro() {
      if (!this.verificarCamposPreenchidos()) {
        return;
      }

      if (this.modo == "Alterar") {
        this.procedimentoComissao.operadorAlteracaoId = this.usuarioLogadoDados.profissionalId;

        this.$http
          .put(
            `/v1.0/procedimentoComissoes/${this.usuarioLogadoDados.tenant}/${this.procedimentoComissao.id}`,
            this.procedimentoComissao
          )
          .then(() => {
            this.erro = "";
            this.limparCampos();
            this.$toasted.global.defaultSuccess();
            this.carregarProcedimentoComissoesPeloProfissional(this.usuarioLogadoDados.tenant, this.profissionalSelecionado.id);
          })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          })
          .finally(() => {});
      } else {
        this.procedimentoComissao.profissionalId = this.profissionalSelecionado.id;
        this.procedimentoComissao.operadorCadastroId = this.usuarioLogadoDados.profissionalId;

        this.$http
          .post(`/v1.0/procedimentoComissoes/${this.usuarioLogadoDados.tenant}`, this.procedimentoComissao)
          .then(() => {
            this.erro = "";
            this.limparCampos();
            this.$toasted.global.defaultSuccess();
            this.carregarProcedimentoComissoesPeloProfissional(this.usuarioLogadoDados.tenant, this.profissionalSelecionado.id);
          })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          })
          .finally(() => {});
      }
    },
    verificarCamposPreenchidos() {
      if (this.procedimentoComissao.codigoProcedimento == null) {
        this.$toasted.show("Informe o Código do Procedimento", {type: "info", icon: "times"});
        return false;
      }

      return true;
    },
    ExcluirRegistroSelecionado(procedimentoComissao) {
      if (!this.verificarPermissao('Alterar')) {
        return
      }

      this.$bvModal
        .msgBoxConfirm("Excluir Registro Selecionado?", {
          title: "Atenção",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            this.$http
              .delete(`v1.0/procedimentoComissoes/${this.usuarioLogadoDados.tenant}/${procedimentoComissao.id}/${this.usuarioLogadoDados.profissionalId}`)
              .then(() => {
                this.erro = "";
                this.$toasted.global.defaultSuccess();
                this.carregarProcedimentoComissoesPeloProfissional(this.usuarioLogadoDados.tenant, 
                  this.profissionalSelecionado.id
                );
              })
              .catch(error => {
                this.erro = errors.tratarErros(error);
              })
              .finally(() => {});
          }
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        });
    },
    cancelarOperacao() {
      this.procedimentoComissao = {}
      this.modo = "";
      this.erro = "";
    },
    limparCampos() {
      this.procedimentoComissao = {}

      if (this.modo != "Incluir") {
        this.modo = "";
      }
    },
    verificarPermissao(operacao) {
      if (!errors.verificarPermissao(this.usuarioLogadoDados, this.$store.state.global.grauPermissaoMinimo, operacao)) {
        let descricaoOperacao = operacao == "Incluir" ? "Inclusão" : operacao == "Alterar" ? "Alteração" : "Exclusão";

        this.mensagem = `${this.usuarioLogadoDados.profissional.nome}, você não possui permissão para realizar ${descricaoOperacao} com esse Perfil.`;
        this.$bvModal.show("modal-mensagem");

        return false;
      }

      return true;
    }
  },
  created() {
    this.carregarProcedimentoComissoesPeloProfissional(this.usuarioLogadoDados.tenant, this.profissionalSelecionado.id);
  },
  beforeRouteLeave(to, from, next) {
    if (this.modo=="Incluir" || this.modo=="Alterar") {
      this.$bvModal
        .msgBoxConfirm("Tem certeza que deseja sair sem Gravar os Dados?", {
          title: "Atenção",
          size: "sm",
          buttonSize: "sm",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            next(true);
          } else {
            next(false);
          }
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        });
    } else {
      next(true);
    }
  }
}
</script>

<style scoped>
  label {
    font-weight: 100%;
    font-size: 0.8rem;
    margin-top: 3px;
    margin-bottom: 1px;
  }
</style>
