<template>
  <div>
    <div class="formulario">
      <div class="cabecalho">
        <button @click="retornar" class="btn btn-sm btn-primary mr-2"><i class="fa fa-arrow-left"/></button>
      </div>
    </div>

    <div class="tituloPagina">
        <span>Configuração do Sistema</span>
        <hr/>
    </div>

    <div class="container-fluid">
      <div class="row">
        <div class="form-group col-lg-1">
          <label for="Id" class="control-label">Id:</label>
          <input
            type="text"
            class="form-control"
            disabled
            id="Id"
            name="Id"
            :value="configSelecionado.id"
          />
        </div>

        <div class="form-group col-lg-4">
          <label for="Fantasia" class="control-label">Nome Fantasia:</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="Fantasia"
            name="Fantasia"
            v-model="configSelecionado.fantasia"
          />
        </div>
      </div>

      <div class="row">
        <div class="form-group col-lg-5">
          <label for="RazaoSocial" class="control-label">Razão Social:</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="RazaoSocial"
            name="RazaoSocial"
            v-model="configSelecionado.razaoSocial"
          />
        </div>

        <div class="form-group col-lg-2">
          <label for="CNPJ" class="control-label">CNPJ:</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="CNPJ"
            name="CNPJ"
            v-model="configSelecionado.cnpjNumero"
          />
        </div>
      </div>

      <div class="row">
        <div class="form-group col-lg-2">
          <label for="apenasConsulta" class="control-label">Apenas Consulta</label>
          <select id="apenasConsulta" class="form-control" selected :disabled="!liberarCampos" v-model="configSelecionado.apenasConsulta">
            <option v-for="resposta in sim_nao" :key="resposta.opcao" :value="resposta.opcao">
              {{ resposta.valor }}
            </option>
          </select>
        </div>
        <!--
        <div class="form-group col-lg-1">
          <label for="IdCliente" class="control-label">Id PD:</label>
          <input
            type="text"
            class="form-control"
            disabled
            id="IdCliente"
            name="IdCliente"
            :value="configSelecionado.idCliente"
          />
        </div>

        <div class="form-group col-lg-2">
          <label for="datTrava" class="control-label">Data Trava:</label>
          <input
            type="date"
            class="form-control"
            disabled
            id="datTrava"
            name="datTrava"
            v-model="configSelecionado.datTrava"
          />
        </div>

        <div class="form-group col-lg-2">
          <label for="usuarioPlanoDigital" class="control-label">Usuário PD:</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="usuarioPlanoDigital"
            name="usuarioPlanoDigital"
            v-model="configSelecionado.usuarioPlanoDigital"
          />
        </div>

        <div class="form-group col-lg-2">
          <label for="senhaPlanoDigital" class="control-label">Senha PD:</label>
          <input
            type="password"
            class="form-control"
            :disabled="!liberarCampos"
            id="senhaPlanoDigital"
            name="senhaPlanoDigital"
            v-model="configSelecionado.senhaPlanoDigital"
          />
        </div>
        -->

        <div class="form-group col-lg-1">
          <label for="Habilitados" class="control-label">Licenças:</label>
          <input
            type="text"
            class="form-control"
            disabled
            id="Habilitados"
            name="Habilitados"
            v-model.number="configSelecionado.habilitados"
          />
        </div>
      </div>

      <hr />
      <div class="row">
        <div class="form-group col-lg-2">
          <label for="securityMpp" class="control-label">Alterar Senha Após (dias):</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="securityMpp"
            name="securityMpp"
            v-model.number="configSelecionado.securityMpp"
          />
        </div>

        <div class="form-group col-lg-2">
          <label for="securityTbrp" class="control-label">Repetir Senha Após (dias):</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="securityTbrp"
            name="securityTbrp"
            v-model.number="configSelecionado.securityTbrp"
          />
        </div>

        <div class="form-group col-lg-2">
          <label for="securityMal" class="control-label">Máx. Tentativas Logon:</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="securityMal"
            name="securityMal"
            v-model.number="configSelecionado.securityMal"
          />
        </div>

        <div class="form-group col-lg-2">
          <label for="securityMisp" class="control-label">Período Máx. Inativo (min.)</label>
          <input
            type="text"
            class="form-control"
            :disabled="!liberarCampos"
            id="securityMisp"
            name="securityMisp"
            v-model.number="configSelecionado.securityMisp"
          />
        </div>
      </div>

      <div class="row">
        <div class="form-group col-lg-2">
          <label for="assinanteBrasindice" class="control-label">Assinante Brasindice</label>
          <select id="assinanteBrasindice" class="form-control" selected :disabled="!liberarCampos" v-model="configSelecionado.assinanteBrasindice">
            <option v-for="resposta in sim_nao" :key="resposta.opcao" :value="resposta.opcao">
              {{ resposta.valor }}
            </option>
          </select>
        </div>
        <div class="form-group col-lg-2">
          <label for="assinanteSimpro" class="control-label">Assinante Simpro</label>
          <select id="assinanteSimpro" class="form-control" selected :disabled="!liberarCampos" v-model="configSelecionado.assinanteSimpro">
            <option v-for="resposta in sim_nao" :key="resposta.opcao" :value="resposta.opcao">
              {{ resposta.valor }}
            </option>
          </select>
        </div>
      </div>

      <template v-if="this.erro!=''">
        <p class="msgError">Erro durante Operação: {{ this.erro }}</p>
      </template>

      <hr />
      <div class="barraopcoescadastro">
        <button
          id="Alterar"
          :disabled="liberarCampos"
          @click="alterarRegistro()"
          class="btn btn-sm btn-secondary mr-2"
        >Alterar</button>
        <button
          id="Cancelar"
          @click="cancelarOperacao()"
          class="btn btn-sm btn-secondary mr-2"
        >Cancelar</button>
        <button
          id="Gravar"
          :disabled="!liberarCampos"
          @click="gravarRegistro()"
          class="btn btn-sm btn-secondary mr-2"
        >Gravar</button>
        <button class="btn btn-sm btn-info" @click="unidades()">Unidades</button>
      </div>
    </div>
    <div class="Mensagem">
      <b-modal id="modal-mensagem" centered title="DoctorOn">
        <p class="my-4">{{mensagem}}</p>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import configs from '@/mixins/configs';
import global from "@/mixins/global";
import errors from "@/utils/errors";
import constantes from "@/utils/constantes";

export default {
  mixins : [configs, global],
  computed: {
    ...mapGetters({
      usuarioLogadoDados : "global/getUsuarioLogadoDados"
    }),
    liberarCampos() {
      return this.modo == "Alterar" ? true : false;
    }
  },
  data() {
    return {
      erro: "",
      loading: true,
      modo: "Registro",
      mensagem : "",
      filtrar: "",
      configSelecionado : {},
      sim_nao : constantes.sim_nao
    }
  },
  methods: {
    ...mapActions({
      setConfigSelecionado: "configs/setConfigSelecionado",
      limparCampos: "configs/limparRegistroSelecionado"
    }),
    retornar() {
      this.$router.go(-1);
    },
    unidades() {
      this.setConfigSelecionado(this.configSelecionado);
      this.$router.push("/frmUnidades");
    },
    alterarRegistro() {
      if (!this.verificarPermissao('Alterar')) {
        return
      }

      this.modo = 'Alterar';
    },
    gravarRegistro() {
      if (!this.verificarCamposPreenchidos()) {
        return;
      }

      this.configSelecionado.datAlteracao = new Date();
      this.configSelecionado.operadorAlteracaoId = this.usuarioLogadoDados.profissionalId;

      this.$http
        .put(`/v1.0/configs/${this.usuarioLogadoDados.tenant}/${this.configSelecionado.id}`, this.configSelecionado)
        .then(() => {
          this.erro = "";
          this.modo = "Registro";
          this.$toasted.global.defaultSuccess();
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        })
        .finally(() => {});
    },
    cancelarOperacao() {
      this.modo = "Registro";
      this.retornar();
    },
    verificarCamposPreenchidos() {
      if (this.configSelecionado.razaoSocial == "" || this.configSelecionado.razaoSocial == undefined) {
        this.$toasted.show("Digite a Razão Social", {type: 'info', icon: 'times'});
        return false;
      }

      if (this.configSelecionado.fantasia == "" || this.configSelecionado.fantasia == undefined) {
        this.$toasted.show("Digite o Nome Fantasia", {type: 'info', icon: 'times'});
        return false;
      }

      if (this.configSelecionado.usuarioPlanoDigital == "" || this.configSelecionado.usuarioPlanoDigital == undefined) {
        this.$toasted.show("Digite o Usuário Plano Digital", {type: 'info', icon: 'times'});
        return false;
      }

      if (this.configSelecionado.senhaPlanoDigital == "" || this.configSelecionado.senhaPlanoDigital == undefined) {
        this.$toasted.show("Digite a Senha Plano Digital", {type: 'info', icon: 'times'});
        return false;
      }

      if (this.configSelecionado.securityMpp == "" || this.configSelecionado.securityMpp == undefined) {
        this.$toasted.show("Digite 'Alterar Senha Após (dias)'", {type: 'info', icon: 'times'});
        return false;
      }

      if (this.configSelecionado.securityTbrp == "" || this.configSelecionado.securityTbrp == undefined) {
        this.$toasted.show("Digite 'Repetir Senha Após (dias)'", {type: 'info', icon: 'times'});
        return false;
      }

      if (this.configSelecionado.securityMal == "" || this.configSelecionado.securityMal == undefined) {
        this.$toasted.show("Digite 'Máx. Tentativas Logon'", {type: 'info', icon: 'times'});
        return false;
      }

      if (this.configSelecionado.securityMisp == "" || this.configSelecionado.securityMisp == undefined) {
        this.$toasted.show("Digite 'Período Máx. Inativo (min.)'", {type: 'info', icon: 'times'});
        return false;
      }

      return true;
    },
    verificarPermissao(operacao) {
      if (!errors.verificarPermissao(this.usuarioLogadoDados, this.$store.state.global.grauPermissaoMinimo, operacao)) {
        let descricaoOperacao = operacao == "Incluir" ? "Inclusão" : operacao == "Alterar" ? "Alteração" : "Exclusão";

        this.mensagem = `${this.usuarioLogadoDados.profissional.nome}, você não possui permissão para realizar ${descricaoOperacao} com esse Perfil.`;
        this.$bvModal.show("modal-mensagem");

        return false;
      }

      return true;
    }    
  },
  created() {
    this.showMenu(false);
    this.carregarConfigs(this.usuarioLogadoDados.tenant);
  },
  beforeRouteLeave(to, from, next) {
    if (this.selecionouAlterar) {
      this.$bvModal
        .msgBoxConfirm("Tem certeza que deseja sair sem Gravar os Dados?", {
          title: "Atenção",
          size: "sm",
          buttonSize: "sm",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            next(true);
          } else {
            next(false);
          }
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        });
    } else {
      next(true);
    }
  }
}
</script>

<style scoped>
</style>
