<template>
  <div id="frmAcompanhamentosPaciente">
    <div class="container-fluid">
      <div class="row" v-if="modo==''">
        <div class="col-10">
          <button
            class="btn btn-sm btn-info"
            :disabled="!medicoDoPaciente"
            @click="incluirRegistro()"
          >Incluir Acompanhamento</button>
          <hr />
        </div>
      </div>

      <template v-if="this.erro!=''">
        <p class="msgError">Erro durante Operação: {{ this.erro }}</p>
      </template>

      <template v-if="modo=='Incluir' || modo=='Alterar'">
        <div class="row">
          <div class="form-group col-lg-2">
            <label for="datPrevista" class="control-label">Prevista</label>
            <input type="date" id="datPrevista" class="form-control" v-model="acompanhamentoSelecionado.datPrevista"/>
          </div>
          <div class="form-group col-lg-8">
            <label for="pergunta" class="control-label">Pergunta</label>
            <input type="text" id="pergunta" class="form-control" v-model="acompanhamentoSelecionado.pergunta"/>
          </div>

          <div class="form-group col-lg-1">
            <b-button style="margin-top:27px" variant="info" size="sm" @click="gravar()">Confirmar</b-button>
          </div>
        </div>
        <hr>
      </template>

      <div style="display:flex; flex-direction:row; justify-content:center;height:370px">
        <b-spinner v-if="loadingAcompahamentos" label="carregando..."/>
        <b-table v-if="loadedAcompanhamentos" class="col-lg-12" hover striped small sort-icon-left :items="acompanhamentos" :fields="camposTabela">
          <template v-slot:cell(actions)="row">
            <b-button v-if="row.item.profissionalId == usuarioLogadoDados.profissionalId && row.item.datContato==null"
              variant="warning"
              size="sm"
              :disabled="!medicoDoPaciente"
              @click="alterarRegistro(row.item)"
              class="mr-2"
            >
              <i class="fa fa-pencil" />
            </b-button>
            <b-button v-if="row.item.profissionalId == usuarioLogadoDados.profissionalId && row.item.datContato==null"
              variant="danger"
              size="sm"
              :disabled="!medicoDoPaciente"
              @click="excluirRegistro(row.item)"
              class="mr-2"
            >
              <i class="fa fa-trash" />
            </b-button>
            <b-button v-if="row.item.profissionalId == usuarioLogadoDados.profissionalId && row.item.datContato!=null && row.item.datVisualizacao==null"
              variant="info"
              size="sm"
              :disabled="!medicoDoPaciente"
              @click="registrarComoVisualizada(row.item)"
            >
              <i class="fa fa-eye" />
            </b-button>
          </template>
          <template v-slot:cell(datPrevista)="datPrevista">
            {{ datPrevista.value | formatDate }}
          </template>
          <template v-slot:cell(datContato)="datContato">
            {{ datContato.value | formatDate }}
          </template>
          <template v-slot:cell(datVisualizacao)="datVisualizacao">
            {{ datVisualizacao.value | formatDate }}
          </template>
        </b-table>
      </div>

      <b-pagination v-if="loadedAcompanhamentos" class="col-lg-12 justify-content-center" size="md" v-model="page" :total-rows="count" :per-page="limit"/>
    </div>
    <div class="Mensagem">
      <b-modal id="modal-mensagem" centered title="DoctorOn">
        <p class="my-4">{{ mensagem }}</p>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import acompanhamentos from "@/mixins/acompanhamentos";
import global from "@/mixins/global";
import errors from "@/utils/errors";
import funcoes from "@/utils/funcoes";

export default {
    mixins : [acompanhamentos, global],
    computed : {
        ...mapGetters({
          pacienteSelecionado : "pacientes/getPacienteSelecionado",
          usuarioLogadoDados: "global/getUsuarioLogadoDados",
    }),
    medicoDoPaciente() {
      return this.pacienteSelecionado.profissionalAgendaId == this.usuarioLogadoDados.profissionalId ? true : false;
    }

    },
    watch : {
        page() {
            this.carregar();
        }
    },
    data() {
        return {
          loadedAcompanhamentos : false,
          loadingAcompahamentos : false,
          page : 1,
          count : 0,
          limit : 0,
          erro : "",
          modo : "",
          acompanhamentoSelecionado : null,
          camposTabela : [
            {key : "profissional", label : "Profissional", sortable : true},
            {key : "datPrevista", label : "Prevista", sortable : true},
            {key : "pergunta", label : "Pergunta"},
            {key : "datContato", label : "Contato", sortable : true},
            {key : "resposta", label : "Resposta"},
            {key : "datVisualizacao", label : "Visualizada", sortable : true},
            {key : "actions", label : "Ações"}
          ],
          mensagem : ""
        }
    },
    methods : {
        retornar() {
            this.$router.go(-1);
        },
        carregar() {
            this.carregarPeloPaciente(this.usuarioLogadoDados.tenant, this.pacienteSelecionado.id, this.page);
        },
        incluirRegistro() {
            this.modo = "Incluir";

            this.acompanhamentoSelecionado = {};
            this.acompanhamentoSelecionado.id = 0;
            this.acompanhamentoSelecionado.datPrevista = null;
            this.acompanhamentoSelecionado.pergunta = null;
            this.acompanhamentoSelecionado.pacienteId = this.pacienteSelecionado.id;
            this.acompanhamentoSelecionado.profissionalId = this.usuarioLogadoDados.profissionalId;
        },
        alterarRegistro(registroSelecionado) {
            this.modo = "Alterar";
            this.acompanhamentoSelecionado = {...registroSelecionado};
            this.acompanhamentoSelecionado.datPrevista = funcoes.mySqlToJSDateFormat(this.acompanhamentoSelecionado.datPrevista);
        },
        registrarComoVisualizada(registroSelecionado) {
            this.acompanhamentoSelecionado = {...registroSelecionado};

            this.$bvModal.msgBoxConfirm("Marcar esse registro como Visualizado?", {
                title: "Atenção",
                size: "sm",
                buttonSize: "sm",
                okVariant: "danger",
                okTitle: "Sim",
                cancelTitle: "Não",
                footerClass: "p-2",
                hideHeaderClose: true,
                centered: true,
              })
              .then(value => {
                if (value) {
                  this.modo = "RegistrarVisualizada";
                  this.gravar();
                }
              })
              .catch(error => {
                this.erro = errors.tratarErros(error);
              });
        },
        excluirRegistro(registroSelecionado) {
            if (!this.verificarPermissao('Excluir')) {
              return
            }

            this.$bvModal.msgBoxConfirm("Excluir Registro Selecionado?", {
                title: "Atenção",
                size: "sm",
                buttonSize: "sm",
                okVariant: "danger",
                okTitle: "Sim",
                cancelTitle: "Não",
                footerClass: "p-2",
                hideHeaderClose: true,
                centered: true,
              })
              .then(value => {
                if (value) {
                 this.$http
                   .delete(`acompanhamentos/${this.usuarioLogadoDados.tenant}/${registroSelecionado.id}/${this.usuarioLogadoDados.profissionalId}`)
                   .then(() => {
                     this.erro = "";
                     this.modo = "";
                     this.$toasted.global.defaultSuccess();
                     this.carregar();
                   })
                   .catch(error => {
                     this.erro = errors.tratarErros(error);
                   })
                   .finally(() => {});
                }
              })
              .catch(error => {
                this.erro = errors.tratarErros(error);
              });
        },
        gravar() {
            if (this.modo!="RegistrarVisualizada" && !this.verificarCamposPreenchidos()) {
                return;
            }

            this.acompanhamentoSelecionado.operadorId = this.usuarioLogadoDados.profissionalId;

            if (this.modo=="RegistrarVisualizada") {
                this.acompanhamentoSelecionado.datVisualizacao =  funcoes.mySqlToJSDateFormat(Date());
            }

            if (this.modo == "Alterar" || this.modo=="RegistrarVisualizada") {
                this.$http.put(`/v1.0/acompanhamentos/${this.usuarioLogadoDados.tenant}/${this.acompanhamentoSelecionado.id}`, this.acompanhamentoSelecionado)
                .then(() => {
                    this.erro = "";
                    this.modo = "";
                    this.$toasted.global.defaultSuccess();
                    this.carregar();
                })
                .catch(error => this.erro = errors.tratarErros(error))
            } else {
                this.$http.post(`/v1.0/acompanhamentos/${this.usuarioLogadoDados.tenant}`, this.acompanhamentoSelecionado)
                .then(() => {
                    this.erro = "";
                    this.modo = "";
                    this.$toasted.global.defaultSuccess();
                    this.carregar();
                })
                .catch(error => this.erro = errors.tratarErros(error))
            }
        },
        verificarCamposPreenchidos() {
          if (this.acompanhamentoSelecionado.datPrevista == null || this.acompanhamentoSelecionado.datPrevista == "") {
            this.$toasted.show("Digite Data para que seja feito Contato com o(a) Paciente.");
            return false;
          }

          if (this.acompanhamentoSelecionado.pergunta == "") {
            this.$toasted.show("Digite a Pergunta que deve ser feita a(o) Paciente.");
            return false;
          }

          let datAtual = funcoes.mySqlToJSDateFormat(Date());

          if (this.acompanhamentoSelecionado.datPrevista <= datAtual) {
            this.$toasted.show("A Data Prevista não pode ser menor ou igual a Data Atual");
            return false;
          }

          return true;
        },
        verificarPermissao(operacao) {
          if (!errors.verificarPermissao(this.usuarioLogadoDados, this.$store.state.global.grauPermissaoMinimo, operacao)) {
            let descricaoOperacao = operacao == "Incluir" ? "Inclusão" : operacao == "Alterar" ? "Alteração" : "Exclusão";

            this.mensagem = `${this.usuarioLogadoDados.profissional.nome}, você não possui permissão para realizar ${descricaoOperacao} com esse Perfil.`;
            this.$bvModal.show("modal-mensagem");

            return false;
          }

          return true;
        }    
    },
    created() {
        this.showMenu(false);
        this.carregar();
    }
}
</script>

<style scoped>
    label {
      font-weight: 100%;
      font-size: 0.8rem;
      margin-top: 3px;
      margin-bottom: 1px;
    }
</style>
