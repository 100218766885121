import errors from "@/utils/errors";

export default {
    data() {
        return {
            agendaContatos : [],
            agendaContatoItens : [],
            limit : 0,
            count : 0,
        }
    },
    methods: {
        carregarAgendaContatos(tenant) {
          this.$http
            .get(`/v1.0/agendacontatos/${tenant}`)
            .then(resp => {
              this.agendaContatos = resp.data;
              this.erro = "";
              this.loading = false;
            })
            .catch(error => {
              this.erro = errors.tratarErros(error);
            })
            .finally(() => {
            });
        },
        carregarAgendaContatoItens(tenant, agendaContatoId) {
          this.loadingItens = true;
          this.loadedItens = false;

          this.$http
            .get(`/v1.0/agendacontatoitens/${tenant}/AgendaContatoId/${agendaContatoId}/${this.page}`)
            .then(resp => {
              this.agendaContatoItens = resp.data.registros;
              this.count = resp.data.count;
              this.limit = resp.data.limit;
              this.erro = "";
              this.loadedItens = true;
            })
            .catch(error => {
              this.erro = errors.tratarErros(error);
            })
            .finally(() => {
              this.loadingItens = false;
            });
        }
    }
}
