import errors from "@/utils/errors";

export default {
    data() {
        return {
            profissionalComissoes : [],
        }
    },
    methods: {
        carregarProfissionalComissoesPeloProfissional(tenant, profissionalSelecionadoId) {
            this.loadingProfissionalComissoes = true;
            this.loadedProfissionalComissoes = false;

            this.$http
              .get(`/v1.0/profissionalcomissoes/${tenant}/Profissional/${profissionalSelecionadoId}`)
              .then(resp => {
                this.profissionalComissoes = resp.data;
                this.erro = "";
                this.loadedProfissionalComissoes = true;
              })
              .catch(error => {
                this.erro = errors.tratarErros(error);
              })
              .finally(() => {
                this.loadingProfissionalComissoes = false;
              });
          },
          carregarProfissionalComissoesPeloServico(tenant, servicoSelecionadoId) {
            this.loadingProfissionalComissoes = true;
            this.loadedProfissionalComissoes = false;

            this.$http
              .get(`/v1.0/profissionalcomissoes/${tenant}/Servico/${servicoSelecionadoId}`)
              .then(resp => {
                this.profissionalComissoes = resp.data;
                this.erro = "";
                this.loadedProfissionalComissoes = true;
              })
              .catch(error => {
                this.erro = errors.tratarErros(error);
              })
              .finally(() => {
                this.loadingProfissionalComissoes = false;
              });
          },
    }
}
