<template>
  <div id="frmProfissionalImpostos">
    <div class="cabecalho">
      <div class="coluna1">
        <button @click="retornar" class="btn btn-sm btn-primary mr-10"><i class="fa fa-arrow-left"/></button>
      </div>
      <div class="coluna2">
        <span>{{profissionalSelecionado.nome}} - Impostos sobre Comissões</span>
      </div>
      <div class="coluna3"/>
    </div>  

    <div class="container-fluid">
      <div class="row" v-if="modo==''">
        <div class="col-12">
          <button
            class="btn btn-sm btn-info"
            @click="incluirRegistro()"
          >Adicionar Nova Imposto</button>
          <hr />
        </div>
      </div>

      <div class="registro" v-if="modo!=''">
        <div class="row" v-if="modo=='Incluir'">
          <div class="col ml-2 mr-2">
            <h4 style="text-align:left">Adicionar Novo Imposto</h4>
            <hr/>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-lg-2 ml-2">
            <label for="Servico" class="control-label">Serviço</label>
            <select
              class="form-control"
              id="Servico"
              name="Servico"
              selected
              v-model="profissionalImposto.servicoId"
            >
              <option
                v-for="servico in servicos"
                :key="servico.id"
                :value="servico.id"
              >{{ servico.siglaServico }} - {{ servico.descricao }}</option>
            </select>
          </div>

          <div class="form-group col-lg-1">
            <label for="ImpostoConvenios" class="control-label">% Operadora</label>
            <input
              type="number"
              class="form-control"
              id="ImpostoConvenios"
              name="ImpostoConvenios"
              v-model.number="profissionalImposto.impostoConvenios"
            />
          </div>

          <div class="form-group col-lg-1">
            <label for="ImpostoParticulares" class="control-label">% Particular</label>
            <input
              type="number"
              class="form-control"
              id="ImpostoParticulares"
              name="ImpostoParticulares"
              v-model.number="profissionalImposto.impostoParticulares"
            />
          </div>
        </div>

        <hr />
        <div class="barraopcoescadastro">
          <button
            id="Alterar"
            :disabled="modo=='Incluir' || modo=='Alterar'"
            @click="alterarRegistro()"
            class="btn btn-sm btn-secondary mr-2"
          >Alterar</button>
          <button
            id="Cancelar"
            @click="cancelarOperacao()"
            class="btn btn-sm btn-secondary mr-2"
          >Cancelar</button>
          <button
            id="Gravar"
            :disabled="!liberarCampos"
            @click="gravarRegistro()"
            class="btn btn-sm btn-secondary"
          >Gravar</button>
        </div>
      </div>
    </div>
    <hr />

    <template v-if="this.erro!=''">
      <p class="msgError">Erro durante Operação: {{ this.erro }}</p>
    </template>

    <div style="display:flex; flex-direction:row; justify-content:center">
      <b-spinner v-if="loadingProfissionalImpostos || loadingServicos" label="carregando..."/>
      <table class="table table-sm table-hover col-lg-10" v-if="loadedProfissionalImpostos">
        <thead class="thead-light">
          <th>Serviço</th>
          <th>% Operadoras</th>
          <th>% Particulares</th>
          <th>Ações</th>
        </thead>
        <tbody>
          <tr v-for="profissionalImposto in profissionalImpostos" :key="profissionalImposto.id">
            <td>{{profissionalImposto.servico}}</td>
            <td>{{profissionalImposto.impostoConvenios}}</td>
            <td>{{profissionalImposto.impostoParticulares}}</td>
            <td>
              <b-button
                variant="warning"
                size="sm"
                class="mr-2"
                @click="selecionouProfissionalImposto(profissionalImposto)"
              >
                <i class="fa fa-pencil" />
              </b-button>
              <b-button
                variant="danger"
                size="sm"
                @click="ExcluirRegistroSelecionado(profissionalImposto)"
              >
                <i class="fa fa-trash" />
              </b-button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="Mensagem">
      <b-modal id="modal-mensagem" centered title="DoctorOn">
        <p class="my-4">{{mensagem}}</p>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import servicos from "@/mixins/servicos";
import profissionalImpostos from "@/mixins/profissionalImpostos";
import errors from "@/utils/errors";

export default {
  mixins: [profissionalImpostos, servicos],
  computed: {
    ...mapGetters({
      profissionalSelecionado: "profissionais/getProfissionalSelecionado",
      usuarioLogadoDados : "global/getUsuarioLogadoDados",
    }),
    liberarCampos() {
      return this.modo == "Incluir" || this.modo == "Alterar" ? true : false;
    }
  },
  data() {
    return {
      loadingServicos : false,
      loadedServicos : false,
      loadingProfissionalImpostos: false,
      loadedProfissionalImpostos : false,
      erro: "",
      mensagem : "",
      retornando: false,
      exibirAdicaoImposto: false,
      modo: "",
      profissionalImposto: {}
    }
  },
  methods: {
    incluirRegistro() {
      if (this.modo == "" && !this.verificarPermissao('Incluir')) {
        return
      }

      this.profissionalImposto = {}
      this.modo = this.modo == "" ? "Incluir" : "";
    },
    alterarRegistro() {
      if (!this.verificarPermissao('Alterar')) {
        return
      }

      this.modo = 'Alterar';
    },
    selecionouProfissionalImposto(profissionalImposto) {
      this.profissionalImposto = { ...profissionalImposto }
      this.modo = "Registro";
    },
    retornar() {
      this.retornando = true;
      this.$router.go(-1);
    },
    gravarRegistro() {
      if (!this.verificarCamposPreenchidos()) {
        return;
      }

      if (this.modo == "Alterar") {
        this.profissionalImposto.operadorAlteracaoId = this.usuarioLogadoDados.profissionalId;

        this.$http
          .put(
            `/v1.0/profissionalImpostos/${this.usuarioLogadoDados.tenant}/${this.profissionalImposto.id}`,
            this.profissionalImposto
          )
          .then(() => {
            this.erro = "";
            this.limparCampos();
            this.$toasted.global.defaultSuccess();
            this.carregarProfissionalImpostosPeloProfissional(this.usuarioLogadoDados.tenant, this.profissionalSelecionado.id);
          })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          })
          .finally(() => {});
      } else {
        this.profissionalImposto.profissionalId = this.profissionalSelecionado.id;
        this.profissionalImposto.operadorCadastroId = this.usuarioLogadoDados.profissionalId;

        this.$http
          .post(`/v1.0/profissionalImpostos/${this.usuarioLogadoDados.tenant}`, this.profissionalImposto)
          .then(() => {
            this.erro = "";
            this.limparCampos();
            this.$toasted.global.defaultSuccess();
            this.carregarProfissionalImpostosPeloProfissional(this.usuarioLogadoDados.tenant, this.profissionalSelecionado.id);
          })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          })
          .finally(() => {});
      }
    },
    verificarCamposPreenchidos() {
      if (this.profissionalImposto.servicoId == 0) {
        this.$toasted.show("Selecione o Serviço para Imposto", {type: "info", icon: "times"});
        return false;
      }

      return true;
    },
    ExcluirRegistroSelecionado(profissionalImposto) {
      if (!this.verificarPermissao('Alterar')) {
        return
      }

      this.$bvModal
        .msgBoxConfirm("Excluir Registro Selecionado?", {
          title: "Atenção",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            this.$http
              .delete(`v1.0/profissionalImpostos/${this.usuarioLogadoDados.tenant}/${profissionalImposto.id}/${this.usuarioLogadoDados.profissionalId}`)
              .then(() => {
                this.erro = "";
                this.$toasted.global.defaultSuccess();
                this.carregarProfissionalImpostosPeloProfissional(this.usuarioLogadoDados.tenant, 
                  this.profissionalSelecionado.id
                );
              })
              .catch(error => {
                this.erro = errors.tratarErros(error);
              })
              .finally(() => {});
          }
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        });
    },
    cancelarOperacao() {
      this.profissionalImposto = {}
      this.modo = "";
      this.erro = "";
    },
    limparCampos() {
      this.profissionalImposto = {}

      if (this.modo != "Incluir") {
        this.modo = "";
      }
    },
    verificarPermissao(operacao) {
      if (!errors.verificarPermissao(this.usuarioLogadoDados, this.$store.state.global.grauPermissaoMinimo, operacao)) {
        let descricaoOperacao = operacao == "Incluir" ? "Inclusão" : operacao == "Alterar" ? "Alteração" : "Exclusão";

        this.mensagem = `${this.usuarioLogadoDados.profissional.nome}, você não possui permissão para realizar ${descricaoOperacao} com esse Perfil.`;
        this.$bvModal.show("modal-mensagem");

        return false;
      }

      return true;
    }
  },
  created() {
    this.carregarProfissionalImpostosPeloProfissional(this.usuarioLogadoDados.tenant, this.profissionalSelecionado.id);
    this.carregarServicos(this.usuarioLogadoDados.tenant);
  },
  beforeRouteLeave(to, from, next) {
    if (this.modo=="Incluir" || this.modo=="Alterar") {
      this.$bvModal
        .msgBoxConfirm("Tem certeza que deseja sair sem Gravar os Dados?", {
          title: "Atenção",
          size: "sm",
          buttonSize: "sm",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            next(true);
          } else {
            next(false);
          }
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        });
    } else {
      next(true);
    }
  }
}
</script>

<style scoped>
</style>
