import errors from "@/utils/errors";

export default {
    data() {
        return {
            limit : 0,
            count : 0,
            lancamentos : [],
        }
    },
    methods : {
        carregarLancamentosPelaContaNoPeriodo(tenant, contaId, datInicial, datFinal, pagina) {
            this.$http.get(`/v1.0/lancamentos/${tenant}/ContaPeriodo/${contaId}/${datInicial}/${datFinal}/${pagina}`)
            .then(resp => {
                this.lancamentos = resp.data.registros;
                this.count = resp.data.count;
                this.limit = resp.data.limit;

                this.erro = "";
            })
            .catch(error => this.erro = errors.tratarErros(error))
            .finally(() => {
                this.loading = false;
                this.loaded = true;
            })
        },
        async carregarLancamentosPelaContaNaData(tenant, contaId, data) {
            await this.$http.get(`/v1.0/lancamentos/${tenant}/ContaData/${contaId}/${data}`)
                .then(resp => {
                    this.lancamento = resp.data;
                
                    this.erro = "";
                })
                .catch(error => this.erro = errors.tratarErros(error))
                .finally(() => {
                    this.loading = false;
                    this.loaded = true;
                })
            }
    }
}
