const state = {
    faturaSelecionada : {}
}

const getters = {
    getFaturaSelecionada(state) {
        return state.faturaSelecionada;
    }
}

const mutations = {
    setFaturaSelecionada(state, fatura) {
        state.faturaSelecionada = {...fatura}
    }
}

const actions = {
    setFaturaSelecionada({commit}, fatura) {
        commit("setFaturaSelecionada", fatura);
    }
}

export default {
    namespaced : true,
    state,
    getters,
    mutations,
    actions
}