<template>
  <div>
    <div class="cabecalho">
      <div class="coluna1">
        <button @click="retornar" class="btn btn-sm btn-primary mr-10"><i class="fa fa-arrow-left"/></button>
      </div>
      <div class="coluna2">
        <span>Lotes da Fatura {{faturaSelecionada.id}} - {{faturaSelecionada.descricao}}</span>
      </div>
      <div class="coluna3"/>
    </div>  

    <div class="container-fluid">
      <div class="row" v-if="modo==''">
        <div class="col-12">
          <button
            class="btn btn-sm btn-info"
            @click="incluirNovoLote()"
          >Incluir Novo Lote</button>
          <hr/>
        </div>
      </div>
      <!-- Cadastro de Lote -->
      <div class="registro" v-if="modo!=''">
        <br />
        <div class="row">
          <div class="form-group col-lg-1">
            <label for="tipoLote" class="control-label">Tipo</label>
            <select id="tipoLote" class="form-control" v-model="loteSelecionado.tipoLote" selected>
              <option v-for="tipoLote in tiposLotes" :key="tipoLote.opcao" :value="tipoLote.opcao">
                {{tipoLote.valor}}
              </option>
            </select>
          </div>
          <div class="form-group col-lg-1">
            <label for="codLote" class="control-label">Lote XML</label>
            <input style="number" id="codlote" class="form-control" :disabled="modo=='Incluir'" v-model="loteSelecionado.codLote"/>
          </div>
          <div class="form-group col-lg-1">
            <label for="sequencia" class="control-label">Sequencia</label>
            <input type="number" id="sequencia" class="form-control" :disabled="modo=='Incluir'" v-model="loteSelecionado.sequencia"/>
          </div>
          <div class="form-group col-lg-1">
            <label for="descricao" class="control-label">Descrição</label>
            <input type="text" id="descricao" class="form-control" v-model="loteSelecionado.descricao"/>
          </div>
          <div class="form-group col-lg-1">
            <label for="protocolo" class="control-label">Protocolo</label>
            <input type="text" id="protocolo" class="form-control" v-model="loteSelecionado.protocolo"/>
          </div>
          <div class="form-group col-lg-3">
            <label for="contratados" class="control-label">Contratado</label>
            <select id="contratados" class="form-control" v-model="loteSelecionado.contratadoId" selected>
              <option v-for="contratado in contratados" :key="contratado.id" :value="contratado.id">
                {{contratado.nome}}
              </option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-lg-1">
            <label for="guiasCadastradas" class="control-label">Guias</label>
            <input type="number" id="guiasCadastradas" class="form-control" disabled v-model="loteSelecionado.guiasCadastradas"/>
          </div>
          <div class="form-group col-lg-1">
            <label for="totalEnviado" class="control-label">Total R$</label>
            <input type="number" id="totalEnviado" class="form-control" disabled v-model="loteSelecionado.totalEnviado"/>
          </div>
          <div class="form-group col-lg-2">
            <label for="datCadastro" class="control-label">Cadastro Em</label>
            <input type="date" id="datCadastro" class="form-control" disabled v-model="loteSelecionado.datCadastro"/>
          </div>
          <div class="form-group col-lg-2">
            <label for="datFechamento" class="control-label">Fechamento</label>
            <input type="date" id="datFechamento" class="form-control" disabled v-model="loteSelecionado.datFechamento"/>
          </div>
          <div class="form-group col-lg-2">
            <label for="datEnviado" class="control-label">Enviado à Operadora</label>
            <input type="date" id="datEnviado" class="form-control" :disabled="loteSelecionado.datFechamento==null" v-model="loteSelecionado.datEnviado"/>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-lg-8">
            <label for="xml" class="control-label">Arquivo XML</label>
            <input type="text" id="xml" class="form-control" disabled v-model="loteSelecionado.xml"/>
          </div>
        </div>
        <div class="row justify-content-center">
          <button class="btn btn-sm btn-warning" style="margin-bottom:5px"
            @click="cancelarOperacao()">Cancelar
          </button>
          <button class="btn btn-sm btn-info ml-1" style="margin-bottom:5px"
            @click="gravar()">Confirmar
          </button>
        </div>
      </div>
      <hr style="margin:0px"/>
      <!-- Exibição de Erros -->
      <template v-if="this.erro!=''">
        <p class="msgError">Erro durante Operação: {{ this.erro }}</p>
      </template>
      <!-- Tabela de Lotes -->
      <b-spinner v-if="loading" label="carregando..."/>
      <b-table v-if="!loading" striped hover small sort-icon-left :items="lotes" :fields="camposTabela">
          <template v-slot:cell(actions)="row">
              <b-button class="mr-1" variant="warning" size="sm" @click="alterarLoteSelecionado(row.item)">
                <i class="fa fa-pencil"/>
              </b-button>

              <b-button class="mr-1" variant="danger" size="sm" @click="excluirLoteSelecionado(row.item)">
                <i class="fa fa-trash"/>
              </b-button>

              <b-button v-if="row.item.datFechamento!=null" class="mr-1" variant="info" size="sm" @click="gerarXML(row.item)">
                <i class="fa fa-exchange"/>
              </b-button>
          </template>

          <template v-slot:cell(tipoLote)="tipoLote">
            {{tipoLote.value == 1 ? "Consulta" : tipoLote.value == 2 ? "SADT" : "Honorários"}}
          </template>

          <template v-slot:cell(datFechamento)="datFechamento">
            {{datFechamento.value | formatDate}}
          </template>

          <template v-slot:cell(datEnviado)="datEnviado">
            {{datEnviado.value | formatDate}}
          </template>
      </b-table>
    </div>
    <div class="Mensagem">
      <b-modal id="modal-mensagem" centered title="DoctorOn">
        <p class="my-4">{{mensagem}}</p>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex"
import lotes from "@/mixins/lotes"
import global from "@/mixins/global"
import contratados from "@/mixins/contratados"
import funcoes from "@/utils/funcoes"
import constantes from "@/utils/constantes"
import errors from "@/utils/errors"

export default {
  mixins: [lotes, contratados, global],
  data() {
    return {
      modo: "",
      erro: "",
      mensagem : "",
      loading : true,
      loteSelecionado : {},
      camposTabela : [
        {key : "id", label : "Lote"},
        {key : "tipoLote", label : "Tipo"},
        {key : "descricao", label : "Descrição"},
        {key : "codLote", label : "Lote XML"},
        {key : "sequencia", label : "Sequência"},
        {key : "guiasCadastradas", label : "Guias"},
        {key : "datFechamento", label : "Fechamento"},
        {key : "xml", label : "XML"},
        {key : "datEnviado", label : "Envio XML"},
        {key : "protocolo", label : "Protocolo"},
        {key : "totalEnviado", label : "Total R$"},
        {key : "actions", label : "Ações"}
      ],
      tiposLotes : constantes.tiposLotes,
      sim_nao : constantes.sim_nao,
    }
  },
  computed : {
    ...mapGetters({
      usuarioLogadoDados : "global/getUsuarioLogadoDados",
      faturaSelecionada : "faturas/getFaturaSelecionada"
    })
  },
  methods: {
    retornar() {
      this.$router.go(-1);
    },
    cancelarOperacao() {
      this.loteSelecionado = {}
      this.modo = "";
      this.erro = "";
    },
    incluirNovoLote() {
      if (this.modo == "" && !this.verificarPermissao('Incluir')) {
        return
      }

      this.modo = this.modo == "" ? "Incluir" : "";
      this.loteSelecionado = {}

      if (this.modo=="Incluir") {
          this.loteSelecionado.revisaoGlosa = "N";
          this.loteSelecionado.datCadastro = new Date();
          this.loteSelecionado.datCadastro = funcoes.mySqlToJSDateFormat(this.loteSelecionado.datCadastro);
      }

      this.chamarCarregarContratados();
    },
    alterarLoteSelecionado(lote) {
      if (!this.verificarPermissao('Alterar')) {
        return
      }

      this.modo = "Alterar";
      this.loteSelecionado = {...lote}
      this.loteSelecionado.datCadastro = funcoes.mySqlToJSDateFormat(this.loteSelecionado.datCadastro);

      this.chamarCarregarContratados();
    },
    async chamarCarregarContratados() {
      if (this.contratados.length == 0) {
        await this.carregarContratados(this.usuarioLogadoDados.tenant);
      }
    },
    gravar() {
        if (this.modo=="Alterar") {
            this.loteSelecionado.datAlteracao = new Date();
            this.loteSelecionado.operadorAlteracaoId = this.usuarioLogadoDados.profissionalId;

            this.$http.put(`/v1.0/lotes/${this.usuarioLogadoDados.tenant}/${this.loteSelecionado.id}`, this.loteSelecionado)
            .then(() => {
                this.erro = "";
                this.carregarLotesPelaFatura(this.usuarioLogadoDados.tenant, this.faturaSelecionada.id);
                this.loteSelecionado = {}
                this.modo = "";
                this.$toasted.global.defaultSuccess();
            })
            .catch(error => {
                this.erro = errors.tratarErros(error);
            }) 
        } else {
            this.loteSelecionado.faturaId = this.faturaSelecionada.id;
            this.loteSelecionado.operadorCadastroId = this.usuarioLogadoDados.profissionalId;

            this.$http.post(`/v1.0/lotes/${this.usuarioLogadoDados.tenant}`, this.loteSelecionado)
            .then(() => {
                this.erro = "";
                this.carregarLotesPelaFatura(this.usuarioLogadoDados.tenant, this.faturaSelecionada.id);
                this.loteSelecionado = {}
                this.modo = "";
                this.$toasted.global.defaultSuccess();
            })
            .catch(error => {
                this.erro = errors.tratarErros(error);
            }) 
        }
    },
    fecharReabrirLoteSelecionado(lote) {
      if (!this.verificarPermissao('Alterar')) {
        return
      }

      let mensagem;

      if (lote.datFechamento==null) {
        mensagem = "Fechar o Lote Selecionado?";
      } else {
        mensagem = "Reabrir o Lote Selecionado?";
      }

      this.$bvModal
        .msgBoxConfirm(mensagem, {
          title: "Atenção",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            let operacao;

            if (lote.datFechamento==null) {
              operacao = "F"
            } else {
              operacao = "R"
            }

            let fecharReabrirLote = {id : lote.id, operacao : operacao}
            this.$http
              .put(`/v1.0/lotes/${this.usuarioLogadoDados.tenant}/fecharReabrirLote/${fecharReabrirLote.id}`, fecharReabrirLote)
              .then(() => {
                  this.erro = "";
                  this.$toasted.global.defaultSuccess();
                  this.carregarLotesPelaFatura(this.usuarioLogadoDados.tenant, this.faturaSelecionada.id);
              })
              .catch(error => {
                this.erro = errors.tratarErros(error);
              })
              .finally(() => {});
          }
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        });
    },
    gerarXML(lote) {
      this.$bvModal
        .msgBoxConfirm("Gerar XML do Lote Selecionado?", {
          title: "Atenção",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            let gerarXML = {id : lote.id, xml : "48092385028508@73498759347593"}
            this.$http
              .put(`/v1.0/lotes/${this.usuarioLogadoDados.tenant}/gerarXML/${gerarXML.id}`, gerarXML)
              .then(() => {
                  this.erro = "";
                  this.$toasted.global.defaultSuccess();
                  this.carregarLotesPelaFatura(this.usuarioLogadoDados.tenant, this.faturaSelecionada.id);
              })
              .catch(error => {
                this.erro = errors.tratarErros(error);
              })
              .finally(() => {});
          }
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        });
    },
    excluirLoteSelecionado(lote) {
        if (!this.verificarPermissao('Excluir')) {
          return
        }

        this.$bvModal.msgBoxConfirm("Excluir Lote Selecionado?", {
            title: "Atenção",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "Sim",
            cancelTitle: "Não",
            footerClass: "p-2",
            hideHeaderClose: true,
            centered: true
          })
          .then(value => {
            if (value) {
              this.$http
                .delete(`v1.0/lotes/${this.usuarioLogadoDados.tenant}/${lote.id}/${this.usuarioLogadoDados.profissionalId}`)
                .then(() => {
                    this.erro = "";
                    this.carregarLotesPelaFatura(this.usuarioLogadoDados.tenant, this.faturaSelecionada.id);
                })
                .catch(error => {
                  this.erro = errors.tratarErros(error);
                })
                .finally(() => {});
            }
          })
          .catch(error => {
            this.erro = errors.tratarErros(error);
        });
    },
    verificarPermissao(operacao) {
      if (!errors.verificarPermissao(this.usuarioLogadoDados, this.$store.state.global.grauPermissaoMinimo, operacao)) {
        let descricaoOperacao = operacao == "Incluir" ? "Inclusão" : operacao == "Alterar" ? "Alteração" : "Exclusão";

        this.mensagem = `${this.usuarioLogadoDados.profissional.nome}, você não possui permissão para realizar ${descricaoOperacao} com esse Perfil.`;
        this.$bvModal.show("modal-mensagem");

        return false;
      }

      return true;
    }
  },
  created() {
    this.showMenu(false);
    this.carregarLotesPelaFatura(this.usuarioLogadoDados.tenant, this.faturaSelecionada.id);
  }
}
</script>

<style scoped>
    hr {
        margin-top:5px;
        margin-bottom:5px;
    }

    label {
        margin-bottom:0px;
    }
</style>
