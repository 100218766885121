const estruturaTabela = {
    id : 0, codAnt : 0, descricao : "", descricaoGenerico : "", 
    apresentacaoBrasindice : "", apresentacaoSimpro : "", 
    estoque : 0, minimo : 0, maximo : 0, valor : 0, codMatTiss : 0, codMatXml : "", 
    datUltCompra : null, qtdeUltCompra : 0, custoUltCompra : 0,
    referencia : "", fabricante : "", registroAnvisa : "", laboratorio : "",
    classeRisco : "", unidade : "", unidadeTiss : "", unidadeContabil : "",
    unidadeConsumo : "", custoContabil : 0, custoReposicao : 0, custoConsumo : 0,
    fatorLucratividade : 0, precoBrasindice : 0, precoFatBrasindice : 0, 
    precoSimpro : 0, precoFatSimpro : 0, precoUnitSimpro : 0, precoParticular : 0,
    precoApresentacao : 0, precoFatFabBrasindice : 0, qtdeInicial : 0, 
    codBrasindice : "", qtdeBrasinicice : 0, datUltSimpro : null, coefSimpro : 0,
    datUltBrasindice : null, coefBrasindice : 0, codTissSimpro : "", codTissBrasindice : "",
    codTussSimpro : "", codTussBrasindice : "", unidadeFracaoSimpro : "", 
    eaN13Brasindice : "", codRefFabricante : "", identifSimpro : "", identifBrasindice : "",
    restritoHospitalar : "N",  codTuss : "", autorizacaoFornecedor : "", datInatividade : null,
    datCadastro : null, datAlteracao : null, despesaTipoId : 0, operadorCadastroId : 0, operadorAlteracaoId : 0
}

const state = {
    all : [],
    despesaSelecionada : Object,
    modo: "",
    ultimaBusca : null
}

const getters = {
    getAllDespesas(state) {
        return state.all;
    },

    getDespesaSelecionada(state) {
        return state.despesaSelecionada;
    },

    getModo(state) {
        return state.modo;
    },

    getUltimaBusca(state) {
        return state.ultimaBusca;
    }
}

const mutations = {
    setDespesas(state, listadespesas) {
        state.all = listadespesas;
    },

    setDespesaSelecionada(state, despesa) {
        state.despesaSelecionada = despesa;
    },

    setModo(state, modo) {
        state.modo = modo;
    },

    setUltimaBusca(state, ultimaBusca) {
        state.ultimaBusca = {...ultimaBusca}
    },

    limparRegistroSelecionado(state) {
        state.despesaSelecionada = {...estruturaTabela}
    },

    limparLista(state) {
        state.all = [];
    }
}

const actions = {
    setDespesas({commit}, listadespesas) {
        commit("setDespesas", listadespesas)
    },

    setDespesaSelecionada({commit}, despesa) {
        commit("setDespesaSelecionada", despesa)
    },

    setModo({commit}, modo) {
        commit("setModo", modo);
    },

    setUltimaBusca({commit}, ultimaBusca) {
        commit("setUltimaBusca", ultimaBusca)
    },

    limparRegistroSelecionado({commit}) {
        commit("limparRegistroSelecionado")
    },

    limparLista({commit}) {
        commit("limparLista")
    },

    formLeave({commit}) {
        commit('limparRegistroSelecionado');
        commit('limparLista');
        commit('setModo', "");
    }
}

export default {
    namespaced : true,    
    state,
    getters,
    mutations,
    actions
}