import errors from "@/utils/errors";

export default {
    data() {
        return {
        }
    },
    methods : {
        async carregarPortesCBHPM(paraLista){
            if (paraLista == 'S') {
                await this.$http.get(`/v1.0/portesCBHPM/paraLista`)
                .then(resp => {
                    this.portesEdicoes = resp.data;
                    this.erro = "";
                    this.loading = "";
                })
                .catch(error => this.erro = errors.tratarErros(error))
            } else {
                await this.$http.get(`/v1.0/portesCBHPM`)
                .then(resp => {
                    this.portesEdicoes = resp.data;
                    this.erro = "";
                    this.loading = "";
                })
                .catch(error => this.erro = errors.tratarErros(error))
            }
        },

        async carregarPortesCBHPMPelaEdicao(edicao){
            await this.$http.get(`/v1.0/portesCBHPM/edicao/${edicao}`)
            .then(resp => {
                this.porteCBHPM = resp.data;

                this.erro = "";
                this.loading = "";
            })
            .catch(error => this.erro = errors.tratarErros(error))
        },

        async carregarPortesCBHPMPeloId(id){
            await this.$http.get(`/v1.0/portesCBHPM/${id}`)
            .then(resp => {
                this.porteCBHPM = resp.data;
                this.erro = "";
                this.loading = "";
            })
            .catch(error => this.erro = errors.tratarErros(error))
        }
    }
}
