import "font-awesome/css/font-awesome.css"
import './plug-ins/bootstrap.js'

import Vue from 'vue'
import App from '@/components/App.vue'

import router from './plug-ins/router'
import axios from './plug-ins/axios'
import store from './store'
import moment from 'moment'
import Tree from 'liquor-tree'

import './plug-ins/msgs'

Vue.config.productionTip = false

Vue.use(Tree);

//--------------------------------------- filter Global
Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY')
  }
})

Vue.filter('formatValor', function(value) {
  if (value) {
    const valorFormatado = parseFloat(value).toFixed(2)
                                            .replace('.',',')
                                            //.replace(/(\d)(?=(\d{3})+\,)/g,'$1.')

    return valorFormatado;
  }
})

//--------------------------------------- Mixin Global
Vue.mixin({
  data() {
    return {
      globalVersaoSistema : "2023-01-01-01",
      grauPermissaoMenu : 0,
      globalEmpresaDesenvolvedora : "Plano Digital"
    }
  }
})

new Vue({
  router,
  axios,
  store,
  render: h => h(App),
}).$mount('#app')
