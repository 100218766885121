<template>
    <div>
        <!-- 
            <div class="d-flex justify-content-start">...</div>
            <div class="d-flex justify-content-end">...</div>
            <div class="d-flex justify-content-center">...</div>
            <div class="d-flex justify-content-between">...</div>
            <div class="d-flex justify-content-around">...</div>
         -->

        <p>Componente C</p>  
        <div class="row justify-content-between">
            <div>
                <p style="text-align:center; margin-left:20px">D -> {{diretorio}} {{conteudo}}</p>
            </div>
            <div>
                <p style="text-align:center">{{valor1 % 5}}</p>
            </div>
            <div>
                <p style="text-align:center">Coluna3</p>
            </div>
        </div>

        <button class="btn btn-sm btn-info" @click="lerArquivo()">Ler Arquivo</button>
    </div>
</template>

<script>
import funcoes from "./funcoes"

export default {
    name : "componenteC",
    data() {
        return {
            valor1 : 15,
            diretorio : __dirname,
            conteudo : ""
        }
    },
    methods: {
        lerArquivo() {
            this.conteudo = funcoes.lerArquivo();    
        }
    }
}
</script>

<style scoped>

</style>