function tratarErros(error) {
    let erro = "";

    if (error.response) {
        if (error.response.status===400) {
            
            let retornoErros = error.response.data.errors;
            let alertMessage = ""  ;
            
            if (typeof retornoErros === "object" && retornoErros !== null) {
                for (let elementoObjeto in retornoErros) {
                    if (!retornoErros.hasOwnProperty(elementoObjeto)) continue;
                    
                    let objeto = retornoErros[elementoObjeto];
                    
                    for (var elementoErro in objeto) {
                        if (!objeto.hasOwnProperty(elementoErro)) continue;
                        
                        alertMessage += objeto[elementoErro] + "\n";
                    }
                }
            } else {
                retornoErros = error.response.data;
                
                if (retornoErros === "array") {
                    for (let elementoObjeto in retornoErros) {
                        if (!retornoErros.hasOwnProperty(elementoObjeto)) continue;
                        
                        let objeto = retornoErros[elementoObjeto];
                        
                        alertMessage += objeto[0] + "\n";
                    }
                } else {
                    alertMessage = retornoErros;
                }
            }
            
            if (alertMessage != "") alert(alertMessage);
            
            erro = `Erro ${error.response.status}: Alguns campos não foram preenchidos de forma correta. Favor verificar o preenchimento.`;
        } else if (error.response.status===304) {
            erro = `Erro ${error.response.status}: Não foi possível alterar o registro. Favor informar ao Administrador do Sistema!`;
        } else if (error.response.status===403) {
            erro = `Erro ${error.response.status}: Você não possui autorização para realizar essa operação.`;
        } else if (error.response.status===404) {
            if (error.response.data.title !== undefined) {
                erro = `Erro ${error.response.status}: Endpoint não localizado no Servidor. Avise o Administrador do Sistema.`;
            } else {
                erro = `Erro ${error.response.status}: ${error.response.data}`;
            }
        } else if (error.response.status===408) {
            erro = `Erro ${error.response.status}: Timeout. Tempo limite esgotado. Avise o Administrador do Sistema.`;
        } else if (error.response.status===423) {
            erro = `Erro ${error.response.status}: Esse registro está sendo utilizado por outro usuário. Tente novamente mais tarde!`;
        } else if (error.response.status===500) {
            erro = `Erro ${error.response.status}: Problema no Servidor BackEnd. Serviço pode estar indisponível.`;
        } else {
            erro = error;
        }
    } else {
        erro = error
    }

    return erro;
}

function verificarPermissao(usuarioLogadoDados, grauPermissaoMinimo, operacao) {
    let grauPermissao = usuarioLogadoDados.perfilAcesso.perfil.grauPermissao;
    //let grauPermissaoMinimo = usuarioLogadoDados.grauPermissaoMinimo;
    let nivelPermissao = usuarioLogadoDados.perfilAcesso.nivelPermissao;
    let nivelPermissaoCRUDInferiores = usuarioLogadoDados.perfilAcesso.nivelPermissaoCRUDInferiores ;

    let valOperacao = 10;

    if (operacao == "Incluir") {
        valOperacao = 1
    } else if (operacao == "Alterar") {
        valOperacao = 2
    } else if (operacao == "Excluir") {
        valOperacao = 3
    }

    if ((grauPermissao == grauPermissaoMinimo && nivelPermissao < valOperacao) ||
       (grauPermissao > grauPermissaoMinimo && nivelPermissaoCRUDInferiores < valOperacao)) {

        return false;
    }

    return true
}

export default {
    tratarErros,
    verificarPermissao
}
