import errors from "@/utils/errors"

export default {
    data() {
        return {
            mensagens : [],
        }
    },
    methods : {
        async carregarMensagens(tenant, profissionalId, nivel, page) {
            this.loading = true;
            this.loaded = false;

            await this.$http.get(`/v1.0/mensagens/${tenant}/${profissionalId}/${nivel}/${page}`)
            .then(resp => {
                this.mensagens = resp.data.registros;
                this.limit = resp.data.limit;
                this.count = resp.data.count;

                this.erro = "";

                this.loaded = true;
                this.loading = false;

                this.destacarLinhasAgenda();
            })
            .catch(error => {
                this.erro = errors.tratarErros(error);
                this.loading = false;
            })
        },
        destacarLinhasAgenda() {
            for (let i=0; i<this.mensagens.length; i++) {
                if (this.mensagens[i].lida=='S') {
                    this.mensagens[i]._rowVariant = 'success';
                } else {
                    this.mensagens[i]._rowVariant = 'danger';
                }
            }
        },
    }
}
