const sim_nao = [
    {opcao : 'S', valor : 'Sim'},
    {opcao : 'N', valor : 'Não'}
]

const preNome = [
    {opcao : 'Dr.', valor : 'Dr.'},
    {opcao : 'Dra.', valor : 'Dra.'},
]

const tipoAgenda = [
    {opcao : 'AC', valor : 'Agenda Compartilhada'},
    {opcao : 'AT', valor : 'Atendimento (Consultório / Sessão / Mensal)'},
    {opcao : 'SE', valor : 'Sala Espera (Atendimento Sem Hora Marcada)'},
    {opcao : 'EX', valor : 'Exames'}
]

const estadoCivil = [
    {opcao : 'C', valor : 'Casado' },
    {opcao : 'I', valor : 'Divorciado' },
    {opcao : 'E', valor : 'Separado' },
    {opcao : 'S', valor : 'Solteiro' },
    {opcao : 'U', valor : 'União Estável' },
    {opcao : 'V', valor : 'Viúvo' },
]

const cor = [
    {opcao : 1, valor : 'Branco' },
    {opcao : 2, valor : 'Preto' },
    {opcao : 3, valor : 'Pardo' },
    {opcao : 4, valor : 'Mulato' },
    {opcao : 5, valor : 'Amarelo' },
]

const sexo = [
    {opcao : 'M', valor : 'Masculino' },
    {opcao : 'F', valor : 'Feminino' },
    {opcao : 'O', valor : 'Outros' },
]

const formatoImagem = [
    {opcao : 1, valor : 'JPG' },
    {opcao : 2, valor : 'PNG' }
]

const taxaPagaPor = [
    {opcao : '1', valor : 'Profissional' },
    {opcao : '2', valor : 'Clinica' },
    {opcao : '3', valor : 'Ambos' },
]

const tiposLotes = [
    {opcao : 1, valor : 'Consultas' },
    {opcao : 2, valor : 'SADT' },
    {opcao : 6, valor : 'Honorarios' },
]

const uf = [
    {opcao : 'AC', valor : 'AC' },
    {opcao : 'AL', valor : 'AL' },
    {opcao : 'AP', valor : 'AP' },
    {opcao : 'AM', valor : 'AM' },
    {opcao : 'BA', valor : 'BA' },
    {opcao : 'CE', valor : 'CE' },
    {opcao : 'DF', valor : 'DF' },
    {opcao : 'ES', valor : 'ES' },
    {opcao : 'GO', valor : 'GO' },
    {opcao : 'MA', valor : 'MA' },
    {opcao : 'MT', valor : 'MT' },
    {opcao : 'MS', valor : 'MS' },
    {opcao : 'MG', valor : 'MG' },
    {opcao : 'PA', valor : 'PA' },
    {opcao : 'PB', valor : 'PB' },
    {opcao : 'PE', valor : 'PE' },
    {opcao : 'PI', valor : 'PI' },
    {opcao : 'PR', valor : 'PR' },
    {opcao : 'RJ', valor : 'RJ' },
    {opcao : 'RN', valor : 'RN' },
    {opcao : 'RO', valor : 'RO' },
    {opcao : 'RR', valor : 'RR' },
    {opcao : 'RS', valor : 'RS' },
    {opcao : 'SC', valor : 'SC' },
    {opcao : 'SE', valor : 'SE' },
    {opcao : 'SP', valor : 'SP' },
    {opcao : 'TO', valor : 'TO' },
    {opcao : 'EX', valor : 'EX' },
]

const tissCodigosDespesas = [
    {opcao : 1, valor : "Gases Medicinais"},
    {opcao : 2, valor : "Medicamentos"},
    {opcao : 3, valor : "Materiais"},
    {opcao : 5, valor : "Diárias"},
    {opcao : 7, valor : "Taxas e Aluguéis"},
    {opcao : 8, valor : "OPME"},
]

const grupo = [
    {opcao : 2, valor : "Medicamentos"},
    {opcao : 3, valor : "Materiais"},
    {opcao : 4, valor : "Taxas e Sala"},
    {opcao : 5, valor : "Outros"}
]

const tissVersoes = [
    {opcao : 1, valor : "3.03.01"},
    {opcao : 2, valor : "3.03.02"},
    {opcao : 3, valor : "3.03.03"},
    {opcao : 4, valor : "3.04.00"},
    {opcao : 5, valor : "3.05.00"},
]

const diasSemana = [
    {opcao : 0, valor : "Domingo", abrev : "Dom"},
    {opcao : 1, valor : "Segunda-Feira", abrev : "Seg"},
    {opcao : 2, valor : "Terça-Feira", abrev : "Ter"},
    {opcao : 3, valor : "Quarta-Feira", abrev : "Qua"},
    {opcao : 4, valor : "Quinta-Feira", abrev : "Qui"},
    {opcao : 5, valor : "Sexta-Feira", abrev : "Sex"},
    {opcao : 6, valor : "Sábado", abrev : "Sab"},
]

const destinados = [
    {opcao : 'T', valor : "Todos"},
    {opcao : 'C', valor : "Consultório"},
    {opcao : 'S', valor : "Sessões"},
    {opcao : 'M', valor : "Mensalistas"}
]

const tipoAtendimento = [
    {opcao : "1C", valor : "Primeira Consulta"},
    {opcao : "NC", valor : "Nova Consulta"},
    {opcao : "RT", valor : "Retorno"},
    {opcao : "AV", valor : "Avaliação"},
    {opcao : "SS", valor : "Sessão"},
    {opcao : "??", valor : "Atend. não Realizado"},
]

const contratadosDocumentos = [
    {opcao : "COP", valor : "Código Prestador na Operadora"},
    {opcao : "CNPJ", valor : "CNPJ do Contratado"},
    {opcao : "CPF", valor : "CPF do Contratado"}
]

const inserirCampo = [
    {opcao : "«Nome do Paciente»", valor : "Nome do Paciente", tabela : "paciente", campo : "nome", tipo : null, funcao : null},
    {opcao : "«Data Nascimento»", valor : "Data Nascimento", tabela : "paciente", campo : "datNascimento", tipo : "date", funcao : null},
    {opcao : "«Celular do Paciente»", valor : "Celular do Paciente", campo : "celular", tipo : null, funcao : null},
    {opcao : "«E-Mail»", valor : "E-Mail", tabela : "paciente", campo : "email", funcao : null},
    {opcao : "«RG do Paciente»", valor : "RG do Paciente", tabela : "paciente", campo : "rgNumero", tipo : null, funcao : null},
    {opcao : "«CPF do Paciente»", valor : "CPF do Paciente", tabela : "paciente", campo : "cpfNumero", tipo : null, funcao : null},
    {opcao : "«Idade do Paciente»", valor : "Idade do Paciente", tabela : "paciente", campo : "datNascimento", tipo : null, funcao : "calcularIdade"},
    {opcao : "«Operadora do Paciente»", valor : "Operadora do Paciente", tabela : "convenio", campo : "fantasia", tipo : null, funcao : null},
    {opcao : "«Nome do Médico Solicitante»", valor : "Nome do Médico Solicitante", tabela : "profissional", campo : "nome", tipo : null, funcao : null},
    {opcao : "«CRM do Médico Solicitante»", valor : "CRM do Médico Solicitante", tabela : "profissional", campo : "conselhoNumero", tipo : null, funcao : null},
    {opcao : "«Nome do Médico Executante»", valor : "Nome do Médico Executante", tabela : "profissional", campo : "nome", tipo : null, funcao : null},
    {opcao : "«CRM do Médico Executante»", valor : "CRM do Médico Executante", tabela : "profissional", campo : "conselhoNumero", tipo : null, funcao : null},
]

const nivelCRUD = [
    {opcao : 0, valor : "Nenhum"},
    {opcao : 1, valor : "Incluir"},
    {opcao : 2, valor : "Alterar"},
    {opcao : 3, valor : "Excluir"},
]

const nivelView = [
    {opcao : 0, valor : "Nenhum"},
    {opcao : 1, valor : "Baixo"},
    {opcao : 2, valor : "Médio"},
    {opcao : 3, valor : "Alto"},
]

const tabelasDespesas = [
    {opcao : 1, valor : "Particular"},
    {opcao : 2, valor : "Própria"},
    {opcao : 3, valor : "Brasindice"},
    {opcao : 4, valor : "Simpro"},
]

export default {
    sim_nao,
    preNome,
    tipoAgenda,
    cor,
    sexo,
    estadoCivil,
    tiposLotes,
    formatoImagem,
    taxaPagaPor,
    uf,
    tissCodigosDespesas,
    tissVersoes,
    grupo,
    diasSemana,
    destinados,
    tipoAtendimento,
    contratadosDocumentos,
    inserirCampo,
    nivelCRUD,
    nivelView,
    tabelasDespesas
}
