const estruturaTabela = {
    id : 0,
    codAnt : 0,
    fantasia : "",
    razaoSocial : "",
    datInicioVigencia : null,
    datFinalVigencia : null,
    logradouro : "",
    numero : 0,
    complemento : "",
    bairro : "",
    cidade : "",
    cep : "",
    uf : "",
    email : "",
    site : "",
    ddd : "",
    fone : "",
    logo : "",
    grupoFaturamento : "",
    particular : "N",
    lancarZerado : "N",
    registroAns : "",
    cnpj : "",
    cnae : "",
    inscricaoEstadual : "",
    incluirDespesaQtdeZero : "S",
    tissVersaoXsd : "",
    ativo : "S",
    gerarNumeroGuia : "S",
    certificadoDigital : "N",
    valorCobradoIncPortes : "S",
    valorCobradoIncFilmes : "S",
    consultaPorPlanoPadrao : "S",
    tipoEmpresa : "N",
    permiteRestritoHospitalar : "S",
    guiaUmaSessao : "N",
    fonteRemuneracao : "S",
    retornoPorCbos : "N",
    incluirDefratorNoXml : "N",
    impTotalMenosGlosa : "S",
    impSobreResidual : "S",
    gerarControleRecebimento : "S",
    utilizaCodigoTuss : "S",
    sequencialIgualCodLote : "N",
    grauRisco : 0,
    maximoGuiasPorLote : 100,
    sequenciaArqsEnviados : 0,
    codLote : 0,
    diaEntregaFatura : 0,
    diaEntregaFatura2 : 0,
    diaRecebtoFatura : 0,
    diasFatura : 30,
    venctoFatura : 0,
    valMensalidade : 0,
    numeroGuiaPrestador : 0,
    codTissXmlOperacoes : 0,
    lanctoCodTabelaMedicamentos : 0,
    lanctoCodTabelaMateriais : 0,
    faturarFilmeEm : 0,
    cbhpmEdicaoPorte : 0,
    porcReter : 0,
    coefMaterial : 0,
    coefMedicamento : 0,
    coefMedicamentoRh : 0,
    coefMedicamentoPfb : 0,
    tissEnviar : "",
    tissUsuario : "",
    tissSenha : "",
    tissSuporte : "",
    tissContrato : "",
    tissSolicitarEquipeSadt : "",
    tissWebserviceSenha : "",
    tissWebserviceUsuario : "",
    enviarFaturaPor : "",
    observacao : "",
    observacaoGeral : "",
    observacaoFinanceiro : "",
    observacaoFaturamento : "",
    recebimento : "",
    tipoGuiaAutoConsulta : 1,
    datCadastro : null,
    datAlteracao : null,
    operadorCadastroId : 0,
    operadorAlteracaoId : 0
}

const state = {
    operadoraSelecionada : Object,
    statusAtiva : "S",
    modo : "",
}

const getters = {
    getOperadoraSelecionada(state) {
        return state.operadoraSelecionada;
    },

    getStatusAtiva(state) {
        return state.statusAtiva;
    },

    getModo(state) {
        return state.modo;
    },
}

const mutations = {
    setOperadoraSelecionada(state, operadora) {
        state.operadoraSelecionada = {...operadora}
    },

    setModo(state, modo) {
        state.modo = modo
    },

    setStatusAtiva(state, valor) {
        state.statusAtiva = valor
    },

    limparRegistroSelecionado(state) {
        state.operadoraSelecionada = {...estruturaTabela}
    },
}

const actions = {
    setOperadoraSelecionada({commit}, operadora) {
        commit('setOperadoraSelecionada', operadora)
    },

    setStatusAtiva({commit}, valor) {
        commit('setStatusAtiva',valor)
    },

    setModo({commit}, modo) {
        commit('setModo', modo);
    },

    limparRegistroSelecionado({commit}) {
        commit("limparRegistroSelecionado")
    },

    formLeave({commit}) {
        commit('setModo', "");
        commit('limparRegistroSelecionado');
    }
}

export default {
    namespaced : true,    
    state,
    getters,
    mutations,
    actions
}