<template>
  <div>
    <div class="cabecalho">
      <div class="coluna1">
        <button @click="retornar" class="btn btn-sm btn-primary mr-10"><i class="fa fa-arrow-left"/></button>
      </div>
      <div class="coluna2">
        <span>{{operadoraSelecionada.fantasia}} - {{planoSaudeSelecionado.plano}} - Procedimentos Cobertos</span>
      </div>
      <div class="coluna3"/>
    </div>  
    
    <!--  Adicionar Procedimento - Início -->
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <button class="btn btn-sm btn-info" id="btnFiltrarProcedimentosCobertos" 
            v-b-tooltip.hover title="Pesquisar Procedimentos Cobertos" @click="ModalBuscaProcedimentosCobertos()">
            <i class="fa fa-search"/>
          </button>
          <b-button v-if="modo==''" size="sm" variant="info" class="ml-1"
            v-b-tooltip.hover title="Importar Procedimentos" @click="ImportarRegistros()"
          ><i class="fa fa-chevron-down"/></b-button>
          <b-button v-if="modo!=''" size="sm" variant="info" class="ml-1"
            v-b-tooltip.hover title="Fechar Importar" @click="ImportarRegistros()"
          ><i class="fa fa-chevron-up"/></b-button>
          <b-button size="sm" variant="info" class="ml-1"
            v-b-tooltip.hover title="Adicionar Procedimento Próprio" @click="AdicionarProcedimentoProprio()"
          ><i class="fa fa-plus"/>
          </b-button>
          <b-button size="sm" variant="danger" class="ml-1"
            v-b-tooltip.hover title="Remover Procedimentos" @click="ModalCodigosRemover()"
          ><i class="fa fa-minus"/></b-button>
          <b-button size="sm" variant="info" class="ml-1"
            v-b-tooltip.hover title="Alterar ValCH" @click="ModalAlterarValCH()"
          ><i class="fa fa-usd"/></b-button>
          <b-button size="sm" variant="info" class="ml-1"
            v-b-tooltip.hover title="Reajustar Valor Cobrado %" @click="ModalAlterarPorcentagemValorCobrado()"
          ><i class="fa fa-percent"/></b-button>
          <b-button size="sm" variant="info" class="ml-1"
            v-b-tooltip.hover title="Alterar Serviço" @click="ModalAlterarServico()"
          ><i class="fa fa-wheelchair"/></b-button>
          <hr />
        </div>
      </div>

      <div v-if="modo=='Incluir'">
        <div class="registro" style="height:450px">
          <div class="row">
            <div class="form-group col-lg-2">
              <span>
                <Strong>Importar Procedimento:</Strong>
              </span>
              <br />
            </div>
          </div>
          <div class="row">
            <div class="col-lg-3">
              <label for="selecionarTabela" class="control-label mr-2">Tabela:</label>
              <select
                id="selecionarTabela"
                name="selecionarTabela"
                class="form-control"
                v-model="tabelaProcedimento"
              >
                <option
                  v-for="tabela in tabelaProcedimentos"
                  :key="tabela.id"
                  :value="tabela.tabela"
                >{{ tabela.tabela }}</option>
              </select>
            </div>

            <div v-if="tabelaProcedimento!=''" class="col-lg-3">
              <div class="col" style="padding-left:0px">
                <label class="control-label">Buscar Por:</label>
              </div>

              <input type="radio" id="porFaixa" value="porFaixa" v-model="formaAdicionar" />
              <label for="porFaixa" class="control-label ml-1 mr-2">Por Faixa de Código</label>

              <input type="radio" id="porProcedimento" value="porProcedimento" v-model="formaAdicionar" />
              <label for="porProcedimento" class="control-label ml-1 mr-2">Por Procedimento</label>
            </div>

            <div class="col-lg-6">
                <div class="row">
                  <template v-if="formaAdicionar=='porFaixa'">
                      <div class="col-lg-2">
                        <label for="filterInicial" class="control-label">Cód.Inicial:</label>
                        <input
                          type="text"
                          class="form-control"
                          id="filterInicial"
                          @input="codigoInicial=$event.target.value"
                        />
                      </div>
                      <div class="col-lg-2">
                        <label for="filterFinal" class="control-label">Cód.Final:</label>
                        <input
                          type="text"
                          class="form-control"
                          id="filterFinal"
                          @input="codigoFinal=$event.target.value"
                        />
                      </div>
                      <div
                        class="form-group col-sm-1 col-md-1 col-lg-1"
                        style="padding-top:25px; padding-left:0px"
                      >
                        <button id="btnFiltrar" class="form-control" @click="buscarProcedimentosPorFaixaCodigos()">
                          <i class="fa fa-search"/>
                        </button>
                      </div>
                  </template>
                  <!-- <template v-else-if="formaAdicionar=='porCodigo'"> -->
                      <!-- <div class="col-lg-2"> -->
                        <!-- <label for="Filter" class="control-label">Código</label> -->
                        <!-- <input -->
                          <!-- type="text" -->
                          <!-- class="form-control" -->
                          <!-- id="filter" -->
                          <!-- @input="filtrarProcedimento=$event.target.value" -->
                          <!-- placeholder="digite o que deseja localizar..." -->
                        <!-- /> -->
                      <!-- </div> -->
                      <!-- <div -->
                        <!-- class="form-group col-sm-1 col-md-1 col-lg-1" -->
                        <!-- style="padding-top:25px; padding-left:0px" -->
                      <!-- > -->
                        <!-- <button id="btnFiltrar" class="form-control" @click="buscarProcedimentosPorCodigo()"> -->
                          <!-- <i class="fa fa-search"/> -->
                        <!-- </button> -->
                      <!-- </div> -->
                  <!-- </template> -->
                  <template v-else>
                      <div class="col-lg-4">
                        <label for="Filter" class="control-label">Procedimento</label>
                        <input
                          type="text"
                          class="form-control"
                          id="filter"
                          @input="filtrarProcedimento=$event.target.value"
                          placeholder="digite o que deseja localizar..."
                        />
                      </div>
                      <div
                        class="form-group col-sm-1 col-md-1 col-lg-1"
                        style="padding-top:25px; padding-left:0px"
                      >
                        <button id="btnFiltrar" class="form-control" @click="buscarProcedimentosPorProcedimento()">
                          <i class="fa fa-search"/>
                        </button>
                      </div>
                  </template>

                  <template v-if="procedimentos.length>0">
                    <div class="col-lg-3" style="padding-bottom:0px; padding-top:29px; padding-left:0px">
                      <button class="btn btn-sm btn-info" @click="adicionarTodosProcedimentos()">Importar Todos</button>
                    </div>
                  </template>
              </div>
            </div>
          </div>

          <div v-if="tabelaProcedimento!=''">
            <div class="row">
              <div class="col-lg-12">
                <div style="display:flex; flex-direction:row; justify-content:center;height:290px;overflow:auto">
                  <b-table
                    v-if="loadedProcedimento"
                    class="col-lg-11"
                    hover
                    striped
                    small
                    sort-icon-left
                    :items="procedimentos"
                    :fields="camposTabelaProcedimentos"
                  >
                    <template v-slot:cell(actions)="row">
                      <b-button variant="info" size="sm" @click="importarProcedimento(row.item)">
                        <i class="fa fa-plus" />
                      </b-button>
                    </template>
                  </b-table>
                </div>
                <b-pagination v-if="loadedProcedimento" class="col-lg-12 justify-content-center" size="md" v-model="pageProcedimento" :total-rows="countProcedimento" :per-page="limitProcedimento"/>
              </div>
            </div>
          </div>
        </div>
        <br />
      </div>
    </div>
    <!--  Adicionar Procedimento - Início -->

    <div class="container-fluid">
      <div id="filtrarRegistros" class="row">
        <!-- <div class="ml-3 form-group col-lg-1" style="margin-top:20px;padding-left:0px"> -->
          <!-- <button id="btnFiltrar" class="form-control" @click="ModalBuscaProcedimentosCobertos()"> -->
            <!-- Filtrar <i class="fa fa-search"/> -->
          <!-- </button> -->
        <!-- </div> -->
      </div>

      <div id="erro">
        <p v-if="erro" style="background-color:red; color:white; text-align:center">{{erro}}</p>
      </div>

      <div style="display:flex; flex-direction:row; justify-content:center">
        <b-spinner v-if="loading" label="carregando..."/>
        <b-table
          v-if="loadedProcedimentosCobertos"
          class="col-lg-12"
          hover
          striped
          small
          sort-icon-left
          :items="procedimentosCobertosDaOperadora"
          :fields="camposTabela"
        >
          <template v-slot:cell(actions)="row">
            <b-button
              class="mr-1"
              variant="warning"
              size="sm"
              @click="selecionouProcedimentoCoberto(row.item)"
            >
              <i class="fa fa-pencil" />
            </b-button>
            <b-button
              class="mr-1"
              variant="danger"
              size="sm"
              @click="removerProcedimentoCoberto(row.item)"
            >
              <i class="fa fa-minus" />
            </b-button>
          </template>

          <template v-slot:cell(valorCobrado)="valorCobrado">
              {{valorCobrado.value | formatValor}}
          </template>
        </b-table>
      </div>
      <b-pagination v-if="loadedProcedimentosCobertos" class="col-lg-12 justify-content-center" size="md" v-model="pageProcedimento" :total-rows="countProcedimentoCoberto" :per-page="limitProcedimentoCoberto"/>
    </div>
    <div class="Mensagem">
      <b-modal id="modal-mensagem" centered title="DoctorOn">
        <p class="my-4">{{mensagem}}</p>
      </b-modal>
    </div>

    <!-- Modal para Selecionar Serviço -->
    <div class="Servico">
      <b-modal
        id="modal-select-servico"
        ref="modal"
        title="Selecione o Serviço para relacionar ao(s) Procedimento(s)"
        centered
        hideHeaderClose
        okTitle = "Confirmar"
        cancelTitle="Cancelar"
        @show="resetModalServico"
        @hidden="resetModalServico"
        @ok="handleOkServico"
      >

        <form ref="form" @submit.stop.prevent="handleSubmitservico">
          <b-form-group
            :state="servicoState"
            label="Serviços disponíveis"
            label-for="servico"
            invalid-feedback="É necessário selecionar um Serviço para relacionar ao(s) Procedimento(s)"
          >
            <b-form-select id="servico" v-model="servicoSelecionado" :options="opcoesServicos" required></b-form-select>
          </b-form-group>
        </form>
      </b-modal>

      <!-- Modal para pegar Codigos Inicial e Final para busca -->
      <b-modal
        id="modal-select-codigosRemover"
        ref="modal"
        title="Códigos para Busca"
        centered
        hideHeaderClose
        okTitle = "Confirmar"
        cancelTitle="Cancelar"
        @show="resetModalCodigosRemover"
        @hidden="resetModalCodigosRemover"
        @ok="handleOkCodigosRemover"
      >
        <form ref="form" @submit.stop.prevent="handleSubmitCodigosRemover">
          <b-form-group
            :state="codigoInicialState"
            label="Código Inicial"
            label-for="codigoInicial-input"
            invalid-feedback="Código Inicial é requerido"
          >
            <b-form-input id="codigoInicial-input" v-model="codigoInicial" :state="codigoInicialState" required></b-form-input>
          </b-form-group>

          <b-form-group
            :state="codigoFinalState"
            label="Código Final"
            label-for="codigoFinal-input"
            invalid-feedback="Código Final é requerido"
          >
            <b-form-input id="codigoFinal-input" v-model="codigoFinal" :state="codigoFinalState" required></b-form-input>
          </b-form-group>
        </form>
      </b-modal>
    </div>

    <!-- Modal para Selecionar Busca Procedimentos Cobertos -->
    <div class="BuscaProcedimentoCoberto">
      <b-modal
        id="modal-select-buscaProcedimentoCoberto"
        ref="modal"
        title="Selecione o Campo para Busca dos Procedimento(s) Coberto(s)"
        centered
        hideHeaderClose
        okTitle = "Confirmar"
        cancelTitle="Cancelar"
        @show="resetModalBuscaProcedimentoCoberto"
        @hidden="resetModalBuscaProcedimentoCoberto"
        @ok="handleOkBuscaProcedimentoCoberto"
      >

        <form ref="form" @submit.stop.prevent="handleSubmitBuscaProcedimentoCoberto">
          <!-- Por Descricao -->
          <b-form-group
            :state="descricaoState"
            label="Descrição"
            label-for="descricao-input"
            invalid-feedback="Descrição é requerido"
          >
            <b-form-input id="descricao-input" v-model="descricao" :state="descricaoState"></b-form-input>
          </b-form-group>

          <!-- Por Descricao TUSS -->
          <b-form-group
            :state="descricaoTussState"
            label="ou Descrição TUSS"
            label-for="descricaoTuss-input"
            invalid-feedback="Descrição é requerido"
          >
            <b-form-input id="descricaoTuss-input" v-model="descricaoTuss" :state="descricaoTussState"></b-form-input>
          </b-form-group>

          <!-- Servico -->
          <b-form-group
            :state="servicoState"
            label="ou Serviços disponíveis"
            label-for="servico"
            invalid-feedback="É necessário selecionar um Serviço para relacionar ao(s) Procedimento(s)"
          >
            <b-form-select id="servico" v-model="servicoSelecionado" :options="opcoesServicos"></b-form-select>
          </b-form-group>

          <!-- Tabela -->
          <b-form-group
            :state="tabelaState"
            label="ou Tabela"
            label-for="tabela-input"
            invalid-feedback="Tabela é requerido"
          >
            <b-form-input id="tabela-input" v-model="tabela" :state="tabelaState"></b-form-input>
          </b-form-group>

          <!-- Por Faixa de Códigos -->
          <b-form-group
            :state="codigoInicialState"
            label="ou Código Inicial"
            label-for="codigoInicial-input"
            invalid-feedback="Código Inicial é requerido"
          >
            <b-form-input id="codigoInicial-input" v-model="codigoInicial" :state="codigoInicialState"></b-form-input>
          </b-form-group>

          <b-form-group
            :state="codigoFinalState"
            label="E Código Final"
            label-for="codigoFinal-input"
            invalid-feedback="Código Final é requerido"
          >
            <b-form-input id="codigoFinal-input" v-model="codigoFinal" :state="codigoFinalState"></b-form-input>
          </b-form-group>

        </form>
      </b-modal>
    </div>

    <!-- Modal para Alterar Valor CH -->
    <div class="AlterarValCH">
      <b-modal
        id="modal-select-alterarValCH"
        ref="modal"
        title="Alterar Valor do CH"
        centered
        hideHeaderClose
        okTitle = "Confirmar"
        cancelTitle="Cancelar"
        @show="resetModalAlterarValCH"
        @hidden="resetModalAlterarValCH"
        @ok="handleOkAlterarValCH"
      >

        <form ref="form" @submit.stop.prevent="handleSubmitAlterarValCH">
          <!-- ValCH -->
          <b-form-group
            :state="valchState"
            label="Valor CH"
            label-for="valch-input"
            invalid-feedback="Valor CH é requerido"
          >
            <b-form-input id="valch-input" v-model.number="valch" :state="valchState" required></b-form-input>
          </b-form-group>

          <!-- Por Faixa de Códigos -->
          <b-form-group
            :state="codigoInicialState"
            label="E Código Inicial"
            label-for="codigoInicial-input"
            invalid-feedback="Código Inicial é requerido"
          >
            <b-form-input id="codigoInicial-input" v-model="codigoInicial" :state="codigoInicialState" required></b-form-input>
          </b-form-group>

          <b-form-group
            :state="codigoFinalState"
            label="E Código Final"
            label-for="codigoFinal-input"
            invalid-feedback="Código Final é requerido"
          >
            <b-form-input id="codigoFinal-input" v-model="codigoFinal" :state="codigoFinalState" required></b-form-input>
          </b-form-group>
        </form>
      </b-modal>
    </div>

    <!-- Modal para ReajustarValorCobrado -->
    <div class="ReajustarValorCobrado">
      <b-modal
        id="modal-select-reajustarValorCobrado"
        ref="modal"
        title="Reajustar Valor Cobrado por %"
        centered
        hideHeaderClose
        okTitle = "Confirmar"
        cancelTitle="Cancelar"
        @show="resetModalAlterarPorcentagemValorCobrado"
        @hidden="resetModalAlterarPorcentagemValorCobrado"
        @ok="handleOkModalAlterarPorcentagemValorCobrado"
      >

        <form ref="form" @submit.stop.prevent="handleSubmitAlterarPorcentagemValorCobrado">
          <!-- Porcentagem -->
          <b-form-group
            :state="porcentagemState"
            label="Porcentagem Reajuste"
            label-for="porcentagem-input"
            invalid-feedback="Porcentagem Reajuste é requerida"
          >
            <b-form-input id="porcentagem-input" v-model.number="porcentagem" :state="porcentagemState" required></b-form-input>
          </b-form-group>

          <!-- Por Faixa de Códigos -->
          <b-form-group
            :state="codigoInicialState"
            label="E Código Inicial"
            label-for="codigoInicial-input"
            invalid-feedback="Código Inicial é requerido"
          >
            <b-form-input id="codigoInicial-input" v-model="codigoInicial" :state="codigoInicialState" required></b-form-input>
          </b-form-group>

          <b-form-group
            :state="codigoFinalState"
            label="E Código Final"
            label-for="codigoFinal-input"
            invalid-feedback="Código Final é requerido"
          >
            <b-form-input id="codigoFinal-input" v-model="codigoFinal" :state="codigoFinalState" required></b-form-input>
          </b-form-group>
        </form>
      </b-modal>
    </div>

    <!-- Modal para Alterar Servico -->
    <div class="AlterarServico">
      <b-modal
        id="modal-select-alterarServico"
        ref="modal"
        title="Alterar Serviço"
        centered
        hideHeaderClose
        okTitle = "Confirmar"
        cancelTitle="Cancelar"
        @show="resetModalAlterarServico"
        @hidden="resetModalAlterarServico"
        @ok="handleOkAlterarServico"
      >

        <form ref="form" @submit.stop.prevent="handleSubmitAlterarServico">
          <!-- Servico -->
          <b-form-group
            :state="servicoState"
            label="E Serviços disponíveis"
            label-for="servico"
            invalid-feedback="É necessário selecionar um Serviço para relacionar ao(s) Procedimento(s)"
          >
            <b-form-select id="servico" v-model="servicoSelecionado" :options="opcoesServicos"></b-form-select>
          </b-form-group>

          <!-- Por Faixa de Códigos -->
          <b-form-group
            :state="codigoInicialState"
            label="E Código Inicial"
            label-for="codigoInicial-input"
            invalid-feedback="Código Inicial é requerido"
          >
            <b-form-input id="codigoInicial-input" v-model="codigoInicial" :state="codigoInicialState" required></b-form-input>
          </b-form-group>

          <b-form-group
            :state="codigoFinalState"
            label="E Código Final"
            label-for="codigoFinal-input"
            invalid-feedback="Código Final é requerido"
          >
            <b-form-input id="codigoFinal-input" v-model="codigoFinal" :state="codigoFinalState" required></b-form-input>
          </b-form-group>
        </form>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import procedimentosCobertos from "@/mixins/procedimentosCobertos";
import tabelaProcedimentos from "@/mixins/tabelaProcedimentos";
import procedimentos from "@/mixins/procedimentos";
import servicos from "@/mixins/servicos";
import global from "@/mixins/global";
import errors from "@/utils/errors";

export default {
  mixins: [
    procedimentosCobertos,
    tabelaProcedimentos,
    procedimentos,
    servicos,
    global
  ],
  computed: {
    ...mapGetters({
      operadoraSelecionada: "operadoras/getOperadoraSelecionada",
      planoSaudeSelecionado: "planosSaude/getPlanoSaudeSelecionado",
      usuarioLogadoDados : "global/getUsuarioLogadoDados",
      ultimaBusca : "procedimentosCobertos/getUltimaBusca",
    })
  },
  watch: {
    ativo() {
      this.realizarBusca();
    },
    formaAdicionar() {
      this.procedimentos = [];
      this.filtrarProcedimento = "";
      this.codigoInicial = "";
      this.codigoFinal = "";
    },
    pageProcedimento() {
      this.realizarBusca();
    }
  },
  data() {
    return {
      filtrar: "",
      campoBusca: "Descricao",
      formaAdicionar: "selecionarProcedimento",
      
      codigoInicial: "",
      codigoFinal: "",
      tabela: "",
      descricao : "",
      descricaoTuss : "",
      valch : 0,
      porcentagem : 0,

      modo: "",
      loading: false,
      loaded: false,
      loadedProcedimentosCobertos : false,
      loadedProcedimento: false,
      pageProcedimento : 1,
      page : 1,
      erro: "",
      mensagem : "",
      retornando: false,
      camposTabela: [
        { key: "tabela", label: "Tabela", sortable: true },
        { key: "codigoProcedimento", label: "Código", sortable: true },
        { key: "codigoTuss", label: "Cod.Tuss", sortable: true },
        { key: "siglaServico", label: "Serviço", sortable: true },
        { key: "descricao", label: "Descrição", sortable: true },
        { key: "descricaoTuss", label: "Descrição Tuss", sortable: true },
        { key: "valorCobrado", label: "Valor Cobrado" },
        { key: "actions", label: "Ações" }
      ],
      camposTabelaProcedimentos: [
        { key: "grupo", label: "Grupo", sortable: true },
        { key: "codigo", label: "Código", sortable: true },
        { key: "descricao", label: "Procedimento", sortable: true },
        { key: "porte", label: "Porte", sortable: true },
        { key: "co", label: "CO", sortable: true },
        { key: "auxiliares", label: "Auxil.", sortable: true },
        { key: "porteAnestesico", label: "Porte Anest.", sortable: true },
        { key: "actions", label: "Ações" }
      ],

      tabelaProcedimento: "",

      campoBuscaProcedimento: "Descricao",
      procedimento: {},
      procedimentosAdicionar : [],
      servicoSelecionado : "",
      opcoesServicos : [],

      servicoState : null,
      codigoInicialState : null,
      codigoFinalState : null,
      descricaoState : null,
      descricaoTussState : null,
      tabelaState : null,
      valchState : null,
      porcentagemState : null
    }
  },
  methods: {
    ...mapActions({
      setProcedimentoCobertoSelecionado:
        "procedimentosCobertos/setProcedimentoCobertoSelecionado",
      setUltimaBusca : "procedimentosCobertos/setUltimaBusca",
      formLeave: "procedimentosCobertos/formLeave"
    }),
    retornar() {
      this.retornando = true;
      this.$router.go(-1);
    },
    AdicionarProcedimentoProprio() {
      this.setProcedimentoCobertoSelecionado(null);

      let busca = {planoSaudeId : this.planoSaudeSelecionado.id,
                   campoBusca : this.campoBusca,
                   filtrar : this.filtrar
      }
      this.setUltimaBusca(busca);

      this.$router.push("/cadProcedimentoCoberto");
    },
    async ImportarRegistros() {
      if (this.modo == "" && !this.verificarPermissao('Incluir')) {
        return
      }

      this.modo = this.modo == "" ? "Incluir" : "";

      if (this.modo == "Incluir") {
        if (this.tabelaProcedimentos.length == 0) {
          await this.carregarTabelasProcedimentos(0);
        }

        if (this.servicos.length == 0) {
          this.carregarServicos(this.usuarioLogadoDados.tenant);
        }
      }
    },
    selecionouProcedimentoCoberto(procedimentoCoberto) {
      this.setProcedimentoCobertoSelecionado(procedimentoCoberto);

      let busca = {planoSaudeId : this.planoSaudeSelecionado.id,
                   campoBusca : this.campoBusca,
                   filtrar : this.filtrar
      }
      this.setUltimaBusca(busca);

      this.$router.push("/cadProcedimentoCoberto");
    },
    importarProcedimento(procedimento) {
      this.$bvModal
        .msgBoxConfirm("Importar o Procedimento '" + procedimento.descricao + "' para a lista da Operadora?", {
          title: "Atenção",
          size: "md",
          buttonSize: "sm",
          okVariant: "info",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            this.procedimentosAdicionar.push(procedimento);
            this.selecionarServico();
          }
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        });
    },
    adicionarTodosProcedimentos() {
      this.$bvModal
        .msgBoxConfirm("Adicionar todos os Procedimentos à lista da Operadora?", {
          title: "Atenção",
          size: "md",
          buttonSize: "sm",
          okVariant: "info",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            this.procedimentosAdicionar = [...this.procedimentos];
            this.selecionarServico();
          }
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        });
    },
    selecionouProcedimento(procedimento) {
      this.procedimento = { ...procedimento }
    },
    async buscarProcedimentosPorProcedimento() {
      if (this.filtrarProcedimento.length<5) {
        this.$toasted.show("Digite pelo menos 5 dígitos para realizar busca", {type: "info", icon : "times"});
        return;
      }

      this.loading = true;
      this.loadedProcedimento = false;
      await this.carregarProcedimentosPelaDescricao(this.tabelaProcedimento, this.filtrarProcedimento, 0)
      this.loading = false;
      this.loadedProcedimento = true;
    },
    //async buscarProcedimentosPorCodigo() {
    //  if (this.filtrarProcedimento.length<5) {
    //    this.$toasted.show("Digite pelo menos 5 dígitos para realizar busca", {type: "info", icon : "times"});
    //    return;
    //  }
    //  this.loading = true;
    //  this.loadedProcedimento = false;
    //  await this.carregarProcedimentosPeloCodigo(this.tabelaProcedimento, this.filtrarProcedimento, 0)
    //  this.loading = false;
    //  this.loadedProcedimento = true;
    //},
    async buscarProcedimentosPorFaixaCodigos() {
      this.loading = true;
      this.loadedProcedimento = false;
      await this.carregarProcedimentosFaixaCodigos(this.tabelaProcedimento, this.codigoInicial, this.codigoFinal, 0);
      this.loading = false;
      this.loadedProcedimento = true;
    },
    removerProcedimentoCoberto(procedimentoCoberto) {
      if (!this.verificarPermissao('Excluir')) {
        return
      }

      this.$bvModal
        .msgBoxConfirm("Remover Procedimento da Lista de Procedimentos Cobertos?", {
          title: "Atenção",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            this.$http
              .delete(
                `v1.0/procedimentoscobertos/${this.usuarioLogadoDados.tenant}/${procedimentoCoberto.id}/${this.usuarioLogadoDados.profissionalId}`
              )
              .then(() => {
                this.erro = "";

                this.$toasted.global.defaultSuccess();
                this.realizarBusca();
              })
              .catch(error => {
                this.erro = errors.tratarErros(error);
              })
              .finally(() => {});
          }
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        });
    },
    verificarPermissao(operacao) {
      if (!errors.verificarPermissao(this.usuarioLogadoDados, this.$store.state.global.grauPermissaoMinimo, operacao)) {
        let descricaoOperacao = operacao == "Incluir" ? "Inclusão" : operacao == "Alterar" ? "Alteração" : "Exclusão";

        this.mensagem = `${this.usuarioLogadoDados.profissional.nome}, você não possui permissão para realizar ${descricaoOperacao} com esse Perfil.`;
        this.$bvModal.show("modal-mensagem");

        return false;
      }

      return true;
    },
    selecionarServico() {
      this.opcoesServicos = [];

      this.servicos.forEach(servico => {
        this.opcoesServicos.push(servico.descricao);
      });

      this.$bvModal.show("modal-select-servico");
    },
    resetModalServico() {
      this.servicoSelecionado = "";
    },
    handleOkServico() {
      //.preventDefault();
      this.handleSubmitServico();
    },
    handleSubmitServico() {
      let servico = this.servicos.find(servico => servico.descricao == this.servicoSelecionado);

      let procedimentos = [];
      let procedimento = {};

      this.procedimentosAdicionar.forEach(item => {
        procedimento.operadorCadastroId = this.usuarioLogadoDados.profissionalId;
        procedimento.id = 0;
        procedimento.planoSaudeId = this.planoSaudeSelecionado.id;
        procedimento.siglaServico = servico.siglaServico;
        procedimento.tabela = this.tabelaProcedimento;
        procedimento.codigoProcedimento = item.codigo;
        procedimento.descricao = item.descricao;
        procedimento.necessitaAutorizacao = "N";
        procedimento.porte = item.porte;
        procedimento.portePorcentagem = item.portePorcentagem;
        procedimento.porteAnestesico = item.porteAnestesico;
        procedimento.auxiliares = item.auxiliares;
        procedimento.co = item.co;
        procedimento.ch = item.ch;
        procedimento.filme = item.filme;
        procedimento.ur = item.ur;
        procedimento.incidencias = item.incidencias;

        procedimentos.push({...procedimento});
      })

      this.$http
        .post(`/v1.0/procedimentoscobertos/${this.usuarioLogadoDados.tenant}`, procedimentos)
        .then(() => {
          this.erro = "";
          this.$toasted.global.defaultSuccess();
          if (this.filtrar != "" ) {
            this.realizarBusca();
          }
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        })
        .finally(() => {
          this.procedimentosAdicionar = [];
        });
    },
    ModalCodigosRemover() {
      this.codigoInicial = "";
      this.codigoFinal = "";

      this.$bvModal.show("modal-select-codigosRemover");
    },
    resetModalCodigosRemover() {
      //this.codigoInicial = "";
      //this.codigoFinal = "";
    },
    handleOkCodigosRemover() {
      this.handleSubmitCodigosRemover();
    },
    handleSubmitCodigosRemover() {
      this.removerFaixaProcedimentosCobertos();
    },
    removerFaixaProcedimentosCobertos() {
      this.$bvModal
        .msgBoxConfirm(`Remover Procedimentos de ${this.codigoInicial} à ${this.codigoFinal} da Lista de Procedimentos Cobertos?`, {
          title: "Atenção",
          size: "lg",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Sim",
          cancelTitle: "Não",
          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            this.$http
              .delete(
                `v1.0/procedimentoscobertos/${this.usuarioLogadoDados.tenant}/faixaCodigos/${this.planoSaudeSelecionado.id}/${this.codigoInicial}/${this.codigoFinal}/${this.usuarioLogadoDados.profissionalId}`
              )
              .then(resp => {
                this.erro = "";

                this.$toasted.show(`${resp.data}`, {type : "info", icon : "times"});
                this.realizarBusca();
              })
              .catch(error => {
                this.erro = errors.tratarErros(error);
              })
              .finally(() => {});
          }
        })
        .catch(error => {
          this.erro = errors.tratarErros(error);
        });
    },
    async ModalBuscaProcedimentosCobertos() {
      this.codigoInicial = "";
      this.codigoFinal = "";
      this.descricao = "";
      this.descricaoTuss = "";
      this.tabela = "";
      this.servicoSelecionado = "";

      if (this.servicos.length == 0) {
        await this.carregarServicos(this.usuarioLogadoDados.tenant);
      }

      this.opcoesServicos = [];

      this.servicos.forEach(servico => {
        this.opcoesServicos.push(servico.descricao);
      });

      this.$bvModal.show("modal-select-buscaProcedimentoCoberto");
    },
    resetModalBuscaProcedimentoCoberto() {
      //this.codigoInicial = "";
      //this.codigoFinal = "";
    },
    handleOkBuscaProcedimentoCoberto() {
      this.handleSubmitBuscaProcedimentoCoberto();
    },
    async handleSubmitBuscaProcedimentoCoberto() {
      this.realizarBusca();
    },
    async realizarBusca() {
      if (this.descricao != "") {
        await this.carregarProcedimentosCobertos(this.usuarioLogadoDados.tenant, this.planoSaudeSelecionado.id, 'descricao', this.descricao, 'N', this.pageProcedimento);
      } else if (this.descricaoTuss != "") {
        await this.carregarProcedimentosCobertos(this.usuarioLogadoDados.tenant, this.planoSaudeSelecionado.id, 'descricaotuss', this.descricaoTuss, 'N', this.pageProcedimento);
      } else if (this.tabela != "") {
        await this.carregarProcedimentosCobertos(this.usuarioLogadoDados.tenant, this.planoSaudeSelecionado.id, 'tabela', this.tabela, 'N', this.pageProcedimento);
      } else if (this.servicoSelecionado != "") {
        if (this.servicos.length == 0) {
          await this.carregarServicos(this.usuarioLogadoDados.tenant);
        }

        let servico = this.servicos.find(servico => servico.descricao == this.servicoSelecionado);
        await this.carregarProcedimentosCobertos(this.usuarioLogadoDados.tenant, this.planoSaudeSelecionado.id, 'siglaservico', servico.siglaServico, 'N', this.pageProcedimento);
      } else if (this.codigoInicial != "" && this.codigoFinal != "") {
        await this.carregarProcedimentosCobertosPelaFaixaCodigos(this.usuarioLogadoDados.tenant, this.planoSaudeSelecionado.id, this.codigoInicial, this.codigoFinal, this.pageProcedimento);
      } else {
        this.$toasted.show("Erro para carregar Procedimentos Cobertos", {type : "warning", icon : "times"})
      }
    },
    async ModalAlterarValCH() {
      this.codigoInicial = "";
      this.codigoFinal = "";
      this.valch = 0;

      this.$bvModal.show("modal-select-alterarValCH");
    },
    resetModalAlterarValCH() {
      //this.codigoInicial = "";
      //this.codigoFinal = "";
    },
    handleOkAlterarValCH() {
      this.handleSubmitAlterarValCH();
    },
    async handleSubmitAlterarValCH() {
      if (this.codigoInicial == "" || this.codigoFinal == "") {
        this.$toasted.show("É necessário informar os Codigos Inicial e Final para realizar a Alteração.", {type : "info", icon : "times"});
        return;
      }

      if (this.valch <= 0) {
        this.$toasted.show("É necessário informar um valor positivo para ValCH.", {type : "info", icon : "times"});
        return;
      }

      this.loading = true;

      let procedimentoCobertoValCH = {
        planoSaudeId : this.planoSaudeSelecionado.id, codigoInicial : this.codigoInicial, 
        codigoFinal : this.codigoFinal, valCh : this.valch, operadorAlteracaoId : this.usuarioLogadoDados.profissionalId
      }

      await this.$http.put(`/v1.0/procedimentosCobertos/${this.usuarioLogadoDados.tenant}/valCH/${this.planoSaudeSelecionado.id}`, procedimentoCobertoValCH)
      .then(resp => {
        this.error = "";
        this.$toasted.show(resp.data, {type : "info", icon : "times"});
        this.carregarProcedimentosCobertosPelaFaixaCodigos(this.usuarioLogadoDados.tenant, this.planoSaudeSelecionado.id, this.codigoInicial, this.codigoFinal, this.page);
      })
      .catch(error => {
        this.erro = errors.tratarErros(error);
      })
      .finally(() => {
        this.loading = false;
        this.loaded = true;
      })
    },

    async ModalAlterarPorcentagemValorCobrado() {
      this.codigoInicial = "";
      this.codigoFinal = "";
      this.porcentagem = 0;

      this.$bvModal.show("modal-select-reajustarValorCobrado");
    },
    resetModalAlterarPorcentagemValorCobrado() {
      //this.codigoInicial = "";
      //this.codigoFinal = "";
    },
    handleOkModalAlterarPorcentagemValorCobrado() {
      this.handleSubmitAlterarPorcentagemValorCobrado();
    },
    async handleSubmitAlterarPorcentagemValorCobrado() {
      if (this.codigoInicial == "" || this.codigoFinal == "") {
        this.$toasted.show("É necessário informar os Codigos Inicial e Final para realizar a Alteração.", {type : "info", icon : "times"});
        return;
      }

      if (this.porcentagem <= 0) {
        this.$toasted.show("É necessário informar um valor positivo para Porcentagem.", {type : "info", icon : "times"});
        return;
      }

      this.loading = true;

      let procedimentoCobertoValorCobrado = {
        planoSaudeId : this.planoSaudeSelecionado.id, codigoInicial : this.codigoInicial, 
        codigoFinal : this.codigoFinal, porcentagem : this.porcentagem, operadorAlteracaoId : this.usuarioLogadoDados.profissionalId
      }

      await this.$http.put(`/v1.0/procedimentosCobertos/${this.usuarioLogadoDados.tenant}/valorCobrado/${this.planoSaudeSelecionado.id}`, procedimentoCobertoValorCobrado)
      .then(resp => {
        this.error = "";
        this.$toasted.show(resp.data, {type : "info", icon : "times"});
        this.carregarProcedimentosCobertosPelaFaixaCodigos(this.usuarioLogadoDados.tenant, this.planoSaudeSelecionado.id, this.codigoInicial, this.codigoFinal, this.page);
      })
      .catch(error => {
        this.erro = errors.tratarErros(error);
      })
      .finally(() => {
        this.loading = false;
        this.loaded = true;
      })
    },

    async ModalAlterarServico() {
      this.codigoInicial = "";
      this.codigoFinal = "";
      this.servicoSelecionado = "";

      if (this.servicos.length == 0) {
        await this.carregarServicos(this.usuarioLogadoDados.tenant);
      }

      this.opcoesServicos = [];

      this.servicos.forEach(servico => {
        this.opcoesServicos.push(servico.descricao);
      });

      this.$bvModal.show("modal-select-alterarServico");
    },
    resetModalAlterarServico() {
      //this.codigoInicial = "";
      //this.codigoFinal = "";
    },
    handleOkAlterarServico() {
      this.handleSubmitAlterarServico();
    },
    async handleSubmitAlterarServico() {
      if (this.codigoInicial == "" || this.codigoFinal == "") {
        this.$toasted.show("É necessário informar os Codigos Inicial e Final para realizar a Alteração.", {type : "info", icon : "times"});
        return;
      }

      if (this.servicoSelecionado == "") {
        this.$toasted.show("É necessário informar o Serviço para realizar a Alteração.", {type : "info", icon : "times"});
        return;
      }

      this.loading = true;

      let servico = this.servicos.find(servico => servico.descricao == this.servicoSelecionado);

      let procedimentoCobertoServico = {
        planoSaudeId : this.planoSaudeSelecionado.id, codigoInicial : this.codigoInicial, 
        codigoFinal : this.codigoFinal, siglaServico : servico.siglaServico, operadorAlteracaoId : this.usuarioLogadoDados.profissionalId
      }

      await this.$http.put(`/v1.0/procedimentosCobertos/${this.usuarioLogadoDados.tenant}/servico/${this.planoSaudeSelecionado.id}`, procedimentoCobertoServico)
      .then(resp => {
        this.error = "";
        this.$toasted.show(resp.data, {type : "info", icon : "times"});
        this.carregarProcedimentosCobertosPelaFaixaCodigos(this.usuarioLogadoDados.tenant, this.planoSaudeSelecionado.id, this.codigoInicial, this.codigoFinal, this.page);
      })
      .catch(error => {
        this.erro = errors.tratarErros(error)
      })
      .finally(() => {
        this.loading = false;
        this.loaded = true;
      })
    },
  },
  async created() {
    if (this.ultimaBusca.planoSaudeId != undefined && this.ultimaBusca.planoSaudeId!=0) {
      this.codigoInicial = this.ultimaBusca.codigoInicial;
      this.codigoFinal = this.ultimaBusca.codigoFinal;
      this.descricao = this.ultimaBusca.descricao;
      this.descricaoTuss = this.ultimaBusca.descricaoTuss;
      this.servicoSelecionado = this.ultimaBusca.siglaServico;
      this.tabela = this.ultimaBusca.tabela;
      this.pageProcedimento = this.ultimaBusca.pageProcedimento;

      this.realizarBusca();
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.retornando) {
      this.formLeave();
      this.setUltimaBusca(null);
    } else {
      let registroUltimaBusca = {planoSaudeId : this.planoSaudeSelecionado.id, codigoInicial : this.codigoInicial, codigoFinal : this.codigoFinal,
                                descricao : this.descricao, descricaoTuss : this.descricaoTuss, siglaServico : this.servicoSelecionado, tabela : this.tabela,
                                pageProcedimento : this.pageProcedimento};

      this.setUltimaBusca(registroUltimaBusca);
    }

    next(true);
  }
}
</script>

<style scoped>
  label {
    font-weight: 100%;
    font-size: 0.8rem;
    margin-top: 3px;
    margin-bottom: 1px;
  }
</style>
