import errors from "@/utils/errors";

export default {
    data() {
        return {
            count : 0,
            limit : 0,
            tabelaBrasindices : []
        }
    },
    methods : {
        async carregarBrasindicePeloCodigoTiss(codigoTiss) {
            await this.$http.get(`/v1.0/tabelaBrasindices/codigoTiss/${codigoTiss}`)
            .then(resp => {
                this.tabelaBrasindice = resp.data;

                this.loaded = true;
                this.erro = "";
            })
            .catch(error => this.erro = errors.tratarErros(error))
            .finally(() => {
                this.loading = false;
            })
        },
        carregarListaBrasindicePeloCodigoTiss(codigoTiss, ativo, pagina) {
            this.$http.get(`/v1.0/tabelaBrasindices/codigoTiss/${codigoTiss}/${ativo}/${pagina}`)
            .then(resp => {
                this.tabelaBrasindices = resp.data.registros;
                this.count = resp.data.count;
                this.limit = resp.data.limit;

                this.loaded = true;
                this.erro = "";
            })
            .catch(error => this.erro = errors.tratarErros(error))
            .finally(() => {
                this.loading = false;
            })
        },
        carregarBrasindicePeloMedicamento(medicamento, ativo, pagina) {
            this.$http.get(`/v1.0/tabelaBrasindices/medicamento/${medicamento}/${ativo}/${pagina}`)
            .then(resp => {
                this.tabelaBrasindices = resp.data.registros;
                this.count = resp.data.count;
                this.limit = resp.data.limit;

                this.loaded = true;
                this.erro = "";
            })
            .catch(error => this.erro = errors.tratarErros(error))
            .finally(() => {
                this.loading = false;
            })
        },
        carregarBrasindicePeloLaboratorio(laboratorio, ativo, pagina) {
            this.$http.get(`/v1.0/tabelaBrasindices/laboratorio/${laboratorio}/${ativo}/${pagina}`)
            .then(resp => {
                this.tabelaBrasindices = resp.data.registros;
                this.count = resp.data.count;
                this.limit = resp.data.limit;

                this.loaded = true;
                this.erro = "";
            })
            .catch(error => this.erro = errors.tratarErros(error))
            .finally(() => {
                this.loading = false;
            })
        }
    }
}
