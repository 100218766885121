<template>
  <div>
    <div class="cabecalho">
      <div class="coluna1">
        <button @click="retornar" class="btn btn-sm btn-primary mr-10"><i class="fa fa-arrow-left"/></button>
      </div>
      <div class="coluna2">
        <span>CBOS</span>
      </div>
      <div class="coluna3"/>
    </div>  

    <div class="container-fluid">
      <div id="filtrarRegistros" class="row">
        <div class="form-group col-sm-2 col-md-3 col-lg-4">
          <label for="campoFiltro" class="control-label">Buscar pelo Termo:</label>
          <input
            type="text"
            class="form-control"
            id="campoFiltro"
            @input="filtrar=$event.target.value"
            placeholder="digite o que deseja localizar..."
          />
        </div>
        <div class="form-group col-sm-1 col-md-1 col-lg-1" style="padding-top:32px">
          <button id="btnFiltrar" class="form-control" @click="iniciarCarregarCbos()">
            <i class="fa fa-search"/> 
          </button>
        </div>
      </div>
    </div>

    <div id="erro">
      <p v-if="erro" style="background-color:red; color:white; text-align:center">{{erro}}</p>
    </div>

    <hr />
    <div style="display:flex; flex-direction:row; justify-content:center;height:490px;overflow:auto">
      <b-spinner v-if="loadingCBOS" label="carregando..."/>
      <b-table v-if="loadedCBOS" class="col-lg-6" hover striped small sort-icon-left :items="cboss" :fields="camposTabela">
      </b-table>
    </div>
    <b-pagination v-if="loadedCBOS" class="col-lg-12 justify-content-center" size="md" v-model="page" :total-rows="count" :per-page="limit"/>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import cboss from "@/mixins/cboss";
import global from "@/mixins/global";

export default {
  mixins : [cboss, global],
  computed: {
    ...mapGetters({
      usuarioLogadoDados : "global/getUsuarioLogadoDados"
    }),
    liberarCampos() {
      return this.modo == "Incluir" || this.modo == "Alterar" ? true : false;
    }
  },
  data() {
    return {
      page : 1,
      erro : "",
      modo : "",
      loadingCBOS : false,
      loadedCBOS : false,
      cbos : {},
      camposTabela : [
        {key: "codTermo", label : "Código", sortable : true},
        {key: "termo", label : "Termo", sortable : true},
      ]
    }
  },
  watch : {
    page() {
      this.iniciarCarregarCbos();
    }
  },
  methods: {
    retornar() {
      this.$router.go(-1);
    },
    iniciarCarregarCbos() {
      this.carregarCbos(this.filtrar, this.page);
    },
  },
  created() {
    this.showMenu(false);
  },
}
</script>

<style scoped>
</style>
