import errors from "@/utils/errors";

export default {
    data() {
        return {
            lotes : [],
            lotesAbertos : [],
            lotesFechados: [],
        }
    },
    methods : {
        carregarLote(tenant, id) {
            this.$http.get(`/v1.0/lotes/${tenant}/${id}`)
            .then(resp => {
                this.lote = resp.data[0];
                this.erro = "";
            })
            .catch(error => {
                this.erro = errors.tratarErros(error);
            })
        },
        carregarLotesPelaFatura(tenant, faturaId) {
            this.$http.get(`/v1.0/lotes/${tenant}/fatura/${faturaId}`)
            .then(resp => {
                this.lotes = resp.data;
                this.erro = "";
                this.loading = false;
            })
            .catch(error => {
                this.erro = errors.tratarErros(error);
            })
        },
        carregarLotesAbertosByFatura(tenant, faturaId) {
            this.$http.get(`/v1.0/lotes/${tenant}/fatura_abertos/${faturaId}`)
            .then(resp => {
                this.lotesAbertos = resp.data;
                this.erro = "";
                this.loading = false;
            })
            .catch(error => {
                this.erro = errors.tratarErros(error);
            })
        },
        carregarLotesFechadosByFatura(tenant, faturaId) {
            this.$http.get(`/v1.0/lotes/${tenant}/fatura_fechados/${faturaId}`)
            .then(resp => {
                this.lotesFechados = resp.data;
                this.erro = "";
                this.loading = false;
            })
            .catch(error => {
                this.erro = errors.tratarErros(error);
            })
        },
    }
}
