import errors from "@/utils/errors";

export default {
    data() {
        return {
            count : 0,
            limit : 0,
            despesaTipos : []
        }
    },
    methods: {
      carregarDespesaTipos(tenant, pagina) {
        this.loadingDespesaTipos = true;
        this.loadedDespesaTipos = false;
  
        this.$http
          .get(`/v1.0/despesatipos/${tenant}/pagina/${pagina}`)
          .then(resp => {
            this.despesaTipos = resp.data.registros;
            this.count = resp.data.count;
            this.limit = resp.data.limit;

            this.erro = "";
            this.loadedDespesaTipos = true;
          })
          .catch(error => {
            this.erro = errors.tratarErros(error);
          })
          .finally(() => {
            this.loadingDespesaTipos = false;
          })
        }
    }
}
