import Vue from 'vue'
import Store from '@/store'
import Router from 'vue-router'
import frmLogin from '@/components/frmLogin'
import frmPrincipal from '@/components/frmPrincipal'
import frmEmpresas from '@/components/diversos/frmEmpresas'
import frmProfissoes from '@/components/diversos/frmProfissoes'
import frmModelos from '@/components/diversos/frmModelos'
import frmCids from '@/components/atendimento/frmCids'
import frmConsultorios from '@/components/atendimento/consultorios/frmConsultorios'
import frmConsultorioItens from '@/components/atendimento/consultorios/frmConsultorioItens'
import frmCIModelos from '@/components/atendimento/consultorios/frmCIModelos'
import frmCIModeloItens from '@/components/atendimento/consultorios/frmCIModeloItens'
import frmVariaveis from '@/components/atendimento/consultorios/frmVariaveis'
import frmCboss from '@/components/tiss/frmCboss'
import frmConselhos from '@/components/tiss/frmConselhos'
import frmFeriados from '@/components/agendamento/frmFeriados'
import frmGerarAgenda from '@/components/agendamento/frmGerarAgenda'
import frmAgendaAtendimentos from '@/components/agendamento/frmAgendaAtendimentos'
import cadAgendamento from '@/components/agendamento/cadAgendamento'
import frmServicos from '@/components/lancamentos/frmServicos'
import frmProcedimentos from '@/components/lancamentos/procedimentos/frmProcedimentos'
import conProcedimento from '@/components/lancamentos/procedimentos/conProcedimento'
import frmDespesaCentros from '@/components/lancamentos/despesas/frmDespesaCentros'
import frmDespesaTipos from '@/components/lancamentos/despesas/frmDespesaTipos'
import frmDespesas from '@/components/lancamentos/despesas/frmDespesas'
import frmDespesaApresentacoes from '@/components/lancamentos/despesas/frmDespesaApresentacoes'
import cadDespesa from '@/components/lancamentos/despesas/cadDespesa'
import cadDespesaSimpro from '@/components/lancamentos/despesas/cadDespesaSimpro'
import cadDespesaBrasindice from '@/components/lancamentos/despesas/cadDespesaBrasindice'
import frmOperadoras from '@/components/atores/operadoras/frmOperadoras'
import cadOperadora from '@/components/atores/operadoras/cadOperadora'
import frmPlanosSaude from '@/components/atores/frmPlanosSaude'
import frmOperadoraContatos from '@/components/diversos/frmOperadoraContatos'
import frmDespesasCobertas from '@/components/lancamentos/despesascobertas/frmDespesasCobertas'
import cadDespesaCoberta from '@/components/lancamentos/despesascobertas/cadDespesaCoberta'
import frmProcedimentosCobertos from '@/components/lancamentos/procedimentoscobertos/frmProcedimentosCobertos'
import cadProcedimentoCoberto from '@/components/lancamentos/procedimentoscobertos/cadProcedimentoCoberto'
import frmKits from '@/components/lancamentos/kits/frmKits'
import frmKitItens from '@/components/lancamentos/kits/frmKitItens'
import cadKitItem from '@/components/lancamentos/kits/cadKitItem'
import frmLanctoGuia from '@/components/lancamentos/guias/frmLanctoGuia'
import frmLanctoGuiaItens from '@/components/lancamentos/guias/frmLanctoGuiaItens'
import frmGuiaConsulta from '@/components/lancamentos/guias/frmGuiaConsulta'
import frmGuiaSADT from '@/components/lancamentos/guias/frmGuiaSADT'
import frmGuiaHonorario from '@/components/lancamentos/guias/frmGuiaHonorario'
import frmCarrinhoItens from '@/components/lancamentos/guias/frmCarrinhoItens'
import cadGuiaTratamentoParticular from '@/components/lancamentos/guias/cadGuiaTratamentoParticular'
import cadGuiaDespesaParticular from '@/components/lancamentos/guias/cadGuiaDespesaParticular'
import cadGuiaTratamentoConvenio from '@/components/lancamentos/guias/cadGuiaTratamentoConvenio'
import frmFaturas from '@/components/faturamento/frmFaturas'
import frmLotes from '@/components/faturamento/frmLotes'
import frmFaturamento from '@/components/faturamento/frmFaturamento'
import frmContratados from '@/components/faturamento/frmContratados'
import frmContratadoOperadoras from '@/components/tiss/frmContratadoOperadoras'
import frmOperadoraProfissionais from '@/components/tiss/frmOperadoraProfissionais'
import frmConsultasDiferenciadas from '@/components/lancamentos/frmConsultasDiferenciadas'
import frmProfissionalOperadoras from '@/components/faturamento/frmProfissionalOperadoras'
import frmPortesCBHPM from '@/components/faturamento/frmPortesCBHPM'
import frmTabelaBrasindice from "@/components/lancamentos/brasindice/frmTabelaBrasindice"
import conTabelaBrasindice from "@/components/lancamentos/brasindice/conTabelaBrasindice"
import frmTabelaSimpro from "@/components/lancamentos/simpro/frmTabelaSimpro"
import conTabelaSimpro from "@/components/lancamentos/simpro/conTabelaSimpro"
import frmPacientes from '@/components/atores/pacientes/frmPacientes'
import cadPaciente from '@/components/atores/pacientes/cadPaciente'
import cadPacienteDados from '@/components/atores/pacientes/cadPacienteDados'
import cadPacienteSaude from '@/components/atores/pacientes/cadPacienteSaude'
import cadPacienteInformacoes from '@/components/atores/pacientes/cadPacienteInformacoes'
import cadPacienteResponsavel from '@/components/atores/pacientes/cadPacienteResponsavel'
import frmAgendaPaciente from '@/components/atores/pacientes/frmAgendaPaciente'
import frmControleAtendimentos from '@/components/atores/pacientes/frmControleAtendimentos'
import frmPerfis from '@/components/acesso/frmPerfis'
import frmEspecialidades from '@/components/diversos/frmEspecialidades'
import frmUnidades from '@/components/sistema/unidades/frmUnidades'
import cadUnidade from '@/components/sistema/unidades/cadUnidade'
import cadConfig from '@/components/sistema/configs/cadConfig'
import frmProfissionais from '@/components/atores/profissionais/frmProfissionais'
import frmProfissionaisExecutantes from '@/components/atores/profissionais/frmProfissionaisExecutantes'
import frmProfissionaisSolicitantes from '@/components/atores/profissionais/frmProfissionaisSolicitantes'
import cadProfissionalGeral from '@/components/atores/profissionais/cadProfissionalGeral'
import cadProfissionalAtendimento from '@/components/atores/profissionais/cadProfissionalAtendimento'
import cadProfissionalMemed from "@/components/atores/profissionais/cadProfissionalMemed"
import cadUsuario from '@/components/acesso/cadUsuario'
import frmUsuarioPerfis from '@/components/acesso/frmUsuarioPerfis'
import frmProfissionalDisponibilidades from '@/components/atores/profissionais/frmProfissionalDisponibilidades'
import cadProfissionalDisponibilidade from '@/components/atores/profissionais/cadProfissionalDisponibilidade'
import frmProfissionalComissoes from '@/components/atores/profissionais/frmProfissionalComissoes'
import frmProfissionalImpostos from '@/components/atores/profissionais/frmProfissionalImpostos'
import frmProcedimentoComissoes from '@/components/atores/profissionais/frmProcedimentoComissoes'
import frmDefinirSenha from '@/components/acesso/frmDefinirSenha'
import frmAgendaPermitidos from '@/components/atores/profissionais/frmAgendaPermitidos'
import frmProntuarioPermitidos from '@/components/atores/profissionais/frmProntuarioPermitidos'
import frmProntuario from '@/components/atendimento/consultorios/prontuarios/frmProntuario'
import cadSolicitacao from '@/components/atendimento/consultorios/prontuarios/cadSolicitacao'
import frmPendencias from '@/components/diversos/frmPendencias'
import frmAgendaContatos from '@/components/diversos/frmAgendaContatos'
import frmAgendaContatosItens from '@/components/diversos/frmAgendaContatosItens'
import frmBancos from "@/components/financeiro/frmBancos"
import frmTipoLancamentos from "@/components/financeiro/frmTipoLancamentos"
import frmRecebimentos from "@/components/financeiro/frmRecebimentos"
import cadRecebimento from "@/components/financeiro/cadRecebimento"
import frmFluxosCaixa from "@/components/fluxoCaixa/frmFluxosCaixa"
import frmContas from "@/components/fluxoCaixa/frmContas"
import frmCentros from "@/components/fluxoCaixa/frmCentros"
import frmCentroOrigens from "@/components/fluxoCaixa/frmCentroOrigens"
import frmLancamentos from "@/components/fluxoCaixa/frmLancamentos"
import frmLancamentoItens from "@/components/fluxoCaixa/frmLancamentoItens"
import frmLancamentosFixos from "@/components/fluxoCaixa/frmLancamentosFixos"
import frmPacienteSessoes from "@/components/atendimento/seriados/frmPacienteSessoes"
import frmAdicionarHorarios from "@/components/atendimento/seriados/frmAdicionarHorarios"
import frmDesatarRemover from "@/components/atendimento/seriados/frmDesatarRemover"
import frmBuscarNaAgenda from "@/components/atendimento/seriados/frmBuscarNaAgenda"
import frmTransferirSessoes from "@/components/atendimento/seriados/frmTransferirSessoes"
import frmOpcoesGerais from "@/components/relatorios/frmOpcoesGerais"
import frmAcompanhamentos from "@/components/atendimento/acompanhamentos/frmAcompanhamentos"
import frmAcompanhamentosPaciente from "@/components/atendimento/acompanhamentos/frmAcompanhamentosPaciente"
import frmAcompanhamentosProfissional from "@/components/atendimento/acompanhamentos/frmAcompanhamentosProfissional"
import frmMensagens from "@/components/diversos/frmMensagens"
import cadMensagem from "@/components/diversos/cadMensagem"
import fotoPaciente from "@/components/atores/pacientes/fotoPaciente"

import opcRelatoriosFinanceiros from "@/components/relatorios/opcRelatoriosFinanceiros"
import opcRelatoriosFaturamentos from "@/components/relatorios/opcRelatoriosFaturamentos"
import opcRelatoriosFluxosDeCaixa from "@/components/relatorios/opcRelatoriosFluxosDeCaixa"
import opcRelatoriosAtendimentos from "@/components/relatorios/opcRelatoriosAtendimentos"

import teste from '@/components/testes/teste'

Vue.use(Router)

const router = new Router({
    mode : 'hash',
    routes : [
        {
            path : '/testes',
            component : teste
        },
        {
            path : '/',
            component : frmLogin
        },
        {
            path : '/frmPrincipal',
            component : frmPrincipal
        },
        {
            path : '/frmEmpresas',
            component : frmEmpresas,
            text : 'Empresas'
        },
        {
            path : '/frmProfissoes',
            component : frmProfissoes
        },
        {
            path : '/frmModelos',
            component : frmModelos
        },
        {
            path : '/frmCids',
            component : frmCids
        },
        {
            path : '/frmConsultorios',
            component : frmConsultorios
        },
        {
            path : '/frmConsultorioItens',
            component : frmConsultorioItens
        },
        {
            path : '/frmCIModelos',
            component : frmCIModelos
        },
        {
            path : '/frmCIModeloItens',
            component : frmCIModeloItens
        },
        {
            path : '/frmVariaveis',
            component : frmVariaveis
        },
        {
            path : '/frmFeriados',
            component : frmFeriados
        },
        {
            path : '/frmCboss',
            component : frmCboss
        },
        {
            path : '/frmConselhos',
            component : frmConselhos
        },
        {
            path : '/frmServicos',
            component : frmServicos
        },
        {
            //path : '/frmProcedimentos/:tabelaProcedimentoId',
            path : '/frmProcedimentos',
            component : frmProcedimentos,
            //props : true
        },
        {
            path : '/conProcedimento',
            component : conProcedimento
        },
        {
            path : '/frmDespesaCentros',
            component : frmDespesaCentros
        },
        {
            path : '/frmDespesaTipos',
            component : frmDespesaTipos
        },
        {
            path : '/frmDespesas',
            component : frmDespesas
        },
        {
            path : '/frmDespesaApresentacoes',
            component : frmDespesaApresentacoes
        },
        {
            path : '/cadDespesa',
            component : cadDespesa
        },
        {
            path : '/cadDespesaBrasindice',
            component : cadDespesaBrasindice
        },
        {
            path : '/cadDespesaSimpro',
            component : cadDespesaSimpro
        },
        {
            path : '/frmOperadoras',
            component : frmOperadoras
        },
        {
            path : '/cadOperadora',
            component : cadOperadora
        },
        {
            path : '/frmPlanosSaude',
            component : frmPlanosSaude
        },
        {
            path : '/frmOperadoraContatos',
            component : frmOperadoraContatos
        },
        {
            path : '/frmDespesasCobertas',
            component : frmDespesasCobertas
        },
        {
            path : '/cadDespesaCoberta',
            component : cadDespesaCoberta
        },
        {
            path : '/frmProcedimentosCobertos',
            component : frmProcedimentosCobertos
        },
        {
            path : '/cadProcedimentoCoberto',
            component : cadProcedimentoCoberto
        },
        {
            path : '/frmKits',
            component : frmKits
        },
        {
            path : '/frmKitItens',
            component : frmKitItens
        },
        {
            path : '/cadKitItem',
            component : cadKitItem
        },
        {
            path : '/frmLanctoGuia',
            component : frmLanctoGuia
        },
        {
            path : '/frmLanctoGuiaItens',
            component : frmLanctoGuiaItens
        },
        {
            path : '/frmGuiaConsulta',
            component : frmGuiaConsulta
        },
        {
            path : '/frmCarrinhoItens',
            component : frmCarrinhoItens
        },
        {
            path : '/cadGuiaTratamentoParticular',
            component : cadGuiaTratamentoParticular
        },
        {
            path : '/cadGuiaDespesaParticular',
            component : cadGuiaDespesaParticular
        },
        {
            path : '/cadGuiaTratamentoConvenio',
            component : cadGuiaTratamentoConvenio
        },
        {
            path : '/frmGuiaSADT',
            component : frmGuiaSADT
        },
        {
            path : '/frmGuiaHonorario',
            component : frmGuiaHonorario
        },
        {
            path : '/frmFaturas',
            component : frmFaturas
        },
        {
            path : '/frmFaturamento',
            component : frmFaturamento
        },
        {
            path : '/frmContratados',
            component : frmContratados
        },
        {
            path : '/frmContratadoOperadoras',
            component : frmContratadoOperadoras
        }, 
        {
            path : '/frmOperadoraProfissionais',
            component : frmOperadoraProfissionais
        }, 
        {
            path : '/frmConsultasDiferenciadas',
            component : frmConsultasDiferenciadas
        },
        {
            path : '/frmProfissionalOperadoras',
            component : frmProfissionalOperadoras
        }, 
        {
            path : '/frmPortesCBHPM',
            component : frmPortesCBHPM
        }, 
        {
            path : '/frmTabelaBrasindice',
            component : frmTabelaBrasindice
        }, 
        {
            path : '/conTabelaBrasindice',
            component : conTabelaBrasindice
        }, 
        {
            path : '/frmTabelaSimpro',
            component : frmTabelaSimpro
        }, 
        {
            path : '/conTabelaSimpro',
            component : conTabelaSimpro
        }, 
        {
            path : '/frmLotes',
            component : frmLotes
        },
        {
            path : '/frmPacientes',
            component : frmPacientes
        },
        {
            path : '/cadPaciente',
            component : cadPaciente
        },
        {
            path : '/cadPacienteDados',
            component : cadPacienteDados
        },
        {
            path : '/cadPacienteSaude',
            component : cadPacienteSaude
        },
        {
            path : '/cadPacienteInformacoes',
            component : cadPacienteInformacoes
        },
        {
            path : '/cadPacienteResponsavel',
            component : cadPacienteResponsavel
        },
        {
            path : '/frmPerfis',
            component : frmPerfis
        },
        {
            path : '/frmEspecialidades',
            component : frmEspecialidades
        },
        {
            path : '/frmUnidades',
            component : frmUnidades,
            meta : {isRequiredAdmin : true}
        },
        {
            path : '/cadUnidade',
            component : cadUnidade,
            meta : {isRequiredAdmin : true}
        },
        {
            path : '/cadConfig',
            component : cadConfig,
            meta : {isRequiredAdmin : true}
        },
        {
            path : '/frmProfissionais',
            component : frmProfissionais,
            meta : {isRequiredAdmin : true}
        },
        {
            path : '/frmProfissionaisExecutantes',
            component : frmProfissionaisExecutantes,
        },
        {
            path : '/frmProfissionaisSolicitantes',
            component : frmProfissionaisSolicitantes,
        },
        {
            path : '/cadProfissionalGeral',
            component : cadProfissionalGeral,
            meta : {isRequiredAdmin : true}
        },
        {
            path : '/cadProfissionalAtendimento',
            component : cadProfissionalAtendimento,
            meta : {isRequiredAdmin : true}
        },
        {
            path : '/cadProfissionalMemed',
            component : cadProfissionalMemed,
            meta : {isRequiredAdmin : true}
        },
        {
            path : '/cadUsuario',
            component : cadUsuario,
            meta : {isRequiredAdmin : true}
        },
        {
            path : '/frmUsuarioPerfis',
            component : frmUsuarioPerfis,
            meta : {isRequiredAdmin : true}
        },
        {          
            path : '/frmProfissionalDisponibilidades',
            component : frmProfissionalDisponibilidades
        },
        {
            path : '/cadProfissionalDisponibilidade',
            component : cadProfissionalDisponibilidade
        },
        {          
            path : '/frmProfissionalComissoes',
            component : frmProfissionalComissoes
        },
        {
            path : '/frmProfissionalImpostos',
            component : frmProfissionalImpostos
        },
        {          
            path : '/frmProcedimentoComissoes',
            component : frmProcedimentoComissoes
        },
        {
            path : '/frmAgendaPermitidos',
            component : frmAgendaPermitidos
        },
        {
            path : '/frmProntuarioPermitidos',
            component : frmProntuarioPermitidos
        },
        {
            path : '/frmProntuario',
            component : frmProntuario
        },
        {
            path : '/cadSolicitacao',
            component : cadSolicitacao
        },
        {
            path : '/frmPendencias',
            component : frmPendencias
        },
        {
            path : '/frmAgendaContatos',
            component : frmAgendaContatos
        },
        {
            path : '/frmAgendaContatosItens',
            component : frmAgendaContatosItens
        },
        {
            path : '/frmDefinirSenha',
            component : frmDefinirSenha
        },
        {
            path : '/frmGerarAgenda',
            component : frmGerarAgenda
        },
        {
            path : '/frmAgendaAtendimentos',
            component : frmAgendaAtendimentos
        },
        {
            path : '/cadAgendamento',
            component: cadAgendamento
        },
        {
            path : '/frmAgendaPaciente',
            component : frmAgendaPaciente
        },
        {
            path : '/frmControleAtendimentos',
            component : frmControleAtendimentos
        },
        {
            path : '/frmBancos',
            component : frmBancos
        },
        {
            path : '/frmTipoLancamentos',
            component : frmTipoLancamentos
        },
        {
            path : '/frmRecebimentos',
            component : frmRecebimentos
        },
        {
            path : '/cadRecebimento',
            component : cadRecebimento
        },
        {
            path : '/frmFluxosCaixa',
            component : frmFluxosCaixa
        },
        {
            path : '/frmContas',
            component : frmContas
        },
        {
            path : '/frmCentros',
            component : frmCentros
        },
        {
            path : '/frmCentroOrigens',
            component : frmCentroOrigens
        },
        {
            path : '/frmLancamentos',
            component : frmLancamentos
        },
        {
            path : '/frmLancamentoItens',
            component : frmLancamentoItens
        },
        {
            path : '/frmLancamentosFixos',
            component : frmLancamentosFixos
        },
        {
            path : '/frmPacienteSessoes',
            component : frmPacienteSessoes
        },
        {
            path : '/frmAdicionarHorarios',
            component : frmAdicionarHorarios
        },
        {
            path : '/frmDesatarRemover',
            component : frmDesatarRemover
        },
        {
            path : '/frmBuscarNaAgenda',
            component : frmBuscarNaAgenda
        },
        {
            path : '/frmTransferirSessoes',
            component : frmTransferirSessoes
        },
        {
            path : '/frmOpcoesGerais',
            component : frmOpcoesGerais
        },
        {
            path : '/frmAcompanhamentos',
            component : frmAcompanhamentos
        },
        {
            path : '/frmAcompanhamentosPaciente',
            component : frmAcompanhamentosPaciente
        },
        {
            path : '/frmAcompanhamentosProfissional',
            component : frmAcompanhamentosProfissional
        },
        {
            path : '/frmMensagens',
            component : frmMensagens
        },
        {
            path : '/cadMensagem',
            component : cadMensagem
        },
        {
            path : '/fotoPaciente',
            component : fotoPaciente
        },
        {
            path : '/opcRelatoriosFinanceiros',
            component : opcRelatoriosFinanceiros
        },
        {
            path : '/opcRelatoriosFaturamentos',
            component : opcRelatoriosFaturamentos
        },
        {
            path : '/opcRelatoriosFluxosDeCaixa',
            component : opcRelatoriosFluxosDeCaixa
        },
        {
            path : '/opcRelatoriosAtendimentos',
            component : opcRelatoriosAtendimentos
        },
        {
            path : '*',
            redirect : '/'
        },
    ]
})

router.beforeEach((to, from, next) => {
    if (to.fullPath == '/' || to.fullPath == '/frmLogin') {
        next();
    } else {
        if (Store.state.global.usuarioLogadoAutenticacao.id != null) {

            if (to.fullPath != '/frmPrincipal') {
                let menuOptions = [];

                menuOptions = Store.state.global.unformatedMenuOptions;
                let menuItem = menuOptions.find(item => item.rota == to.fullPath);

                if (menuItem != undefined) {
                    Store.state.global.grauPermissaoMinimo = menuItem.grauPermissaoMinimo;
                }
            }

            //-------------------------------------------------------------- Não permitir a navegação pelo Browser em Áreas não permitidas
            if (to.matched.some(rota => rota.meta.isRequiredAdmin)) {
                if (Store.state.global.usuarioLogadoDados.perfilAcesso.perfil.funcao!="ADM") {
                    alert("Operação Permitida apenas ao Administrador");
                    //this.$toasted.show("Operação Permitida apenas ao Administrador", {type: "info", icon : "times"});
                    next('/frmLogin');
                    return;
                } else {
                    next();
                }
            } else {
                next();
            }
            //--------------------------------------------------------------
        } else {
            alert("Usuário não Logado");
            //this.$toasted.show("Usuário não Logado", {type: "info", icon : "times"});
            next('/frmLogin');
        }  
    }
})

export default router;
